import React from 'react';

import clsx from 'clsx';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useArkaNavigation } from '~/services/navigation';

import { ReproductionCrReportFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrReport.graphql';
import { ReproductionCrReportsQueryVariables } from '~/entities/reproductionCrReports/gql/queries/reproductionCrReports.graphql';
import { RoleBadges } from '~/entities/roles';

import panelStyles from '~/styles/modules/panel.module.scss';

import { ReproductionCrReportCardMenu } from '../ReproductionCrReportCardMenu';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  reproductionCrReport: ReproductionCrReportFragment | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: ReproductionCrReportsQueryVariables;
}

export const ReproductionCrReportCard: React.FC<Props> = ({
  className,
  reproductionCrReport,
  queryVariables,
}) => {
  const { navigate, urlCompanyId } = useArkaNavigation();

  return (
    <div
      {...{
        className: clsx(styles.root, panelStyles.panel, className),
        onClick: () => {
          if (isSkeletonPlaceholder(reproductionCrReport)) return;

          navigate({
            to: '/$companyId/user/analytics/reproduction/cr/$reproductionCrReportId',
            params: {
              reproductionCrReportId: reproductionCrReport.id,
              companyId: urlCompanyId,
            },
          });
        },
      }}
    >
      <div className="flex items-start">
        <Typography
          {...{
            className: 'mr-12',
            variant: TypographyVariants.bodyMediumStrong,
          }}
        >
          {reproductionCrReport.name}
        </Typography>
        {!isSkeletonPlaceholder(reproductionCrReport) && (
          <ReproductionCrReportCardMenu
            {...{
              className: 'ml-a',
              reproductionCrReport,
              queryVariables,
            }}
          />
        )}
      </div>
      <div className="mt-a flex">
        <RoleBadges roles={reproductionCrReport.roles} />
      </div>
    </div>
  );
};
