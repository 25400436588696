import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateReproductionHdrAndPrReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateReproductionHdrAndPrReportInput;
}>;


export type UpdateReproductionHdrAndPrReportMutation = { __typename?: 'Mutation', updateReproductionHdrAndPrReport?: any | null };


export const UpdateReproductionHdrAndPrReportDocument = gql`
    mutation updateReproductionHdrAndPrReport($id: ID!, $input: UpdateReproductionHdrAndPrReportInput!) {
  updateReproductionHdrAndPrReport(id: $id, input: $input)
}
    `;
export type UpdateReproductionHdrAndPrReportMutationFn = Apollo.MutationFunction<UpdateReproductionHdrAndPrReportMutation, UpdateReproductionHdrAndPrReportMutationVariables>;

/**
 * __useUpdateReproductionHdrAndPrReportMutation__
 *
 * To run a mutation, you first call `useUpdateReproductionHdrAndPrReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReproductionHdrAndPrReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReproductionHdrAndPrReportMutation, { data, loading, error }] = useUpdateReproductionHdrAndPrReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReproductionHdrAndPrReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReproductionHdrAndPrReportMutation, UpdateReproductionHdrAndPrReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReproductionHdrAndPrReportMutation, UpdateReproductionHdrAndPrReportMutationVariables>(UpdateReproductionHdrAndPrReportDocument, options);
      }
export type UpdateReproductionHdrAndPrReportMutationHookResult = ReturnType<typeof useUpdateReproductionHdrAndPrReportMutation>;
export type UpdateReproductionHdrAndPrReportMutationResult = Apollo.MutationResult<UpdateReproductionHdrAndPrReportMutation>;
export type UpdateReproductionHdrAndPrReportMutationOptions = Apollo.BaseMutationOptions<UpdateReproductionHdrAndPrReportMutation, UpdateReproductionHdrAndPrReportMutationVariables>;