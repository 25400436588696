import React, { useEffect } from 'react';

import { createFileRoute } from '@tanstack/react-router';

import { useService } from '~/~legacy/hooks/useService';
import { useServiceInitialization } from '~/~legacy/hooks/useServiceInitialization';
import { HTTPTransport } from '~/~legacy/services/HttpTransport';
import { AppBlueprintsService } from '~/~legacy/services/implementations/AppBlueprintsService';
import { MaslovServices, StandardServices } from '~/~legacy/types/services';

import { RunType } from '~/shared/graphql';

import { WithSearchParamsValidation } from '~/services/navigation';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { BlueprintDetails } from './components/BlueprintDetails';

interface BlueprintDetailsSearchParams {
  runType: RunType;
}

export const Route = createFileRoute(
  '/$companyId/_layout/integrator/blueprints/$blueprintId'
)({
  wrapInSuspense: true,
  component: BlueprintDetailsPage,
  validateSearch: ({
    runType,
  }: WithSearchParamsValidation<BlueprintDetailsSearchParams>) => ({
    runType: runType ?? undefined,
  }),
});

function BlueprintDetailsPage() {
  const { blueprintId } = Route.useParams();
  const { runType } = Route.useSearch();

  const http = useService<HTTPTransport>(StandardServices.Http);

  const { initService, initialized } = useServiceInitialization();

  useEffect(() => {
    const bpSvc = new AppBlueprintsService(http);
    initService(MaslovServices.BlueprintsService, bpSvc);
  }, []);

  return (
    <div className={layoutStyles.limitedContainer}>
      {initialized && (
        <BlueprintDetails blueprintId={blueprintId} runType={runType} />
      )}
    </div>
  );
}
