import React from 'react';

import { ReproductionCrReportChartKind } from '@graphql-types';
import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  getSingleSkeletonPlaceholder,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { ReproductionCrCrossTableSubTableFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrCrossTableSubTable.graphql';

import panelStyles from '~/styles/modules/panel.module.scss';

import {
  isReproductionCrByEntityReportData,
  isReproductionCrComparisonReportData,
  isReproductionCrCrossTableReportData,
  isReproductionCrReportDataEmpty,
} from '../../helpers';
import {
  ReproductionCrReportDataType,
  ReproductionCrReportSettingsFormType,
} from '../../types';
import { ReproductionCrChart } from '../ReproductionCrChart';
import { ReproductionCrCrossChart } from './components/ReproductionCrCrossChart';
import { ReproductionCrCrossTable } from './components/ReproductionCrCrossTable';
import { ReproductionCrTable } from './components/ReproductionCrTable';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Chart data to render
   */
  reportData: ReproductionCrReportDataType | SkeletonPlaceholder;
  /**
   * Current settings of the report
   */
  requestedSettings: ReproductionCrReportSettingsFormType;
}

export const ReproductionCrReportData: React.FC<Props> = React.memo(
  ({ className, reportData, requestedSettings }) => {
    const renderCrCrossTable = (
      title: string,
      tableData: ReproductionCrCrossTableSubTableFragment
    ) => {
      if (!requestedSettings.groupBy) {
        return null;
      }
      return (
        <div key={title}>
          <Typography
            className="mb-16"
            tag="h3"
            variant={TypographyVariants.bodyMediumStrong}
          >
            {title}
          </Typography>
          <ReproductionCrCrossTable
            {...{
              xAxisMode: requestedSettings.xAxisMode,
              groupBy: requestedSettings.groupBy,
              tableData,
            }}
          />
        </div>
      );
    };

    const commonChartProps = {
      xAxisMode: requestedSettings.xAxisMode,
      yAxisMode: requestedSettings.yAxisMode,
      shouldShowOther: requestedSettings.xAxisShouldShowOther,
    };

    return (
      <div className={clsx('flex flex-col gap-24', className)}>
        {isReproductionCrReportDataEmpty(reportData) && (
          <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
            <DataBlockedMessage
              {...{
                className: 'p-24',
                message:
                  'Недостаточно данных для отображения, проверьте настройки',
              }}
            />
          </div>
        )}

        {(isReproductionCrByEntityReportData(reportData) ||
          isSkeletonPlaceholder(reportData)) && (
          <>
            <ReproductionCrChart
              {...{
                chartData:
                  reportData.detailedRows ?? getSingleSkeletonPlaceholder(),
                ...commonChartProps,
              }}
            />
            <ReproductionCrTable
              {...{
                chartData:
                  reportData.detailedRows ?? getSingleSkeletonPlaceholder(),
                xAxisMode: requestedSettings.xAxisMode,
                femaleAnimalKind: requestedSettings.femaleAnimalKind,
                farmId: requestedSettings.farmID,
              }}
            />
          </>
        )}

        {isReproductionCrComparisonReportData(reportData) && (
          <>
            <ReproductionCrChart
              {...{
                chartData: reportData.actual,
                historicChartData: reportData.historic,
                actualPeriod: requestedSettings.period,
                historicPeriod: { interval: reportData.historicPeriod },
                ...commonChartProps,
                chartKind:
                  requestedSettings.yAxisCompareToPreviousPeriodChartKind ??
                  ReproductionCrReportChartKind.Bar,
              }}
            />
            <ReproductionCrTable
              {...{
                chartData: reportData.actual,
                historicChartData: reportData.historic,
                xAxisMode: requestedSettings.xAxisMode,
                femaleAnimalKind: requestedSettings.femaleAnimalKind,
                farmId: requestedSettings.farmID,
              }}
            />
          </>
        )}

        {isReproductionCrCrossTableReportData(reportData) &&
          !!requestedSettings.groupBy && (
            <>
              <ReproductionCrCrossChart
                {...{
                  chartData: reportData.cr,
                  ...commonChartProps,
                  groupBy: requestedSettings.groupBy,
                  chartKind:
                    requestedSettings.groupByChartKind ??
                    ReproductionCrReportChartKind.Line,
                }}
              />
              {renderCrCrossTable('Оплодотворяемость (CR), %', reportData.cr)}
              {renderCrCrossTable(
                'Проверенные осеменения, ед.',
                reportData.checkedInseminations
              )}
              {renderCrCrossTable(
                'Результативные осеменения, ед.',
                reportData.successInseminations
              )}
            </>
          )}
      </div>
    );
  }
);
