import React, { ReactNode, Suspense } from 'react';

import { Outlet, useLocation } from '@tanstack/react-router';
import R from 'ramda';

import { Loader } from '~/shared/components/Loader';
import {
  TabConfig,
  Tabs,
  TabsProps,
  TabsVariants,
} from '~/shared/components/Tabs';

import { useLayoutContext } from '../../context';

interface RouterTabConfig extends Required<TabConfig> {
  /**
   * If passed along with href, the route is rendered with this path
   */
  path?: string;
}

interface Props extends Omit<TabsProps<RouterTabConfig>, 'children'> {
  /**
   * If you pass this prop, you can render some general wrapper for all the rendered tabs
   */
  children?: (routeChildren: ReactNode) => ReactNode;
}

export const RouterTabs: React.FC<Props> = ({
  variant = TabsVariants.page,
  children = R.identity,

  ...tabsProps
}) => {
  const { pathname } = useLocation();

  const { routerTabsRightContent } = useLayoutContext();

  const { tabs } = tabsProps;
  const firstTab = tabs[0];
  // This is heuristic detection that will work only
  // if we don't have same route parts repeated
  const activeTab =
    pathname.split('/').find(part => tabs.some(t => t.href === part)) ??
    firstTab.href;

  return (
    <Tabs
      {...{
        variant,
        ...tabsProps,
        selectedTab: activeTab,
        rightContent: routerTabsRightContent,
      }}
    >
      {children(
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      )}
    </Tabs>
  );
};
