import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { CalfData, CalfDataEditFunction } from '../../reducer';
import { CalfsTableRow } from '../CalfsTableRow';
import styles from './index.module.scss';

interface Props {
  calfsData: CalfData[];

  update: CalfDataEditFunction;
  del: CalfDataEditFunction;
}

export const CalfsTable: React.FC<Props> = ({ calfsData, del, update }) => {
  const [focused, setFocused] = useState(false);

  const lastClalfRowId = [...calfsData].reverse().find(item => item)?.tempId;
  useEffect(() => {
    setFocused(true);
  }, [lastClalfRowId]);

  const rows = calfsData.map((item, index) => (
    <CalfsTableRow
      del={del}
      update={update}
      key={item.tempId}
      calf={item}
      focused={index === calfsData.length - 1 && focused}
    />
  ));

  return (
    <table className={clsx('full-width', styles.table)}>
      <tbody>{rows}</tbody>
    </table>
  );
};
