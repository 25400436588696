import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintEditService,
  BlueprintInputNode,
  BlueprintNodeType,
} from '../../../../services';
import { InputNodeUI } from '../InputNodeUI';

interface Props {
  input: BlueprintInputNode;
}

export const InputNode: React.FC<Props> = ({ input }) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const delFunc = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc.deleteNode]);

  const {
    errors: delErrors,
    loading: delLoading,
    reload: deleteFunction,
  } = useApiData(delFunc);

  const deleteCallback = useCallback(
    (nodeId: string) => {
      deleteFunction({
        nodeId,
        nodeType: BlueprintNodeType.Input,
      });
    },
    [deleteFunction]
  );

  const saveFunc = useMemo(() => {
    return bpEditSvc.updateInputNode.bind(bpEditSvc);
  }, [bpEditSvc.deleteNode]);

  const {
    errors: saveErrors,
    loading: saveLoading,
    reload: saveFunction,
  } = useApiData(saveFunc);

  const saveCallback = useCallback(
    async (newInput: BlueprintInputNode) => {
      const result = await saveFunction(newInput);
      return result;
    },
    [saveFunction]
  );

  return (
    <InputNodeUI
      errors={[...delErrors, ...saveErrors]}
      loading={delLoading || saveLoading}
      input={input}
      delete={deleteCallback}
      save={saveCallback}
    />
  );
};
