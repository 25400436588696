import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import { useSourceFields } from '../../../../hooks';
import {
  BlueprintEditService,
  BlueprintFilterConditionNode,
  BlueprintNodeType,
} from '../../../../services';
import { FilterConditionNodeUI } from '../FilterConditionNodeUI';

interface Props {
  filterCondition: BlueprintFilterConditionNode;
}

export const FilterConditionNode: React.FC<Props> = ({ filterCondition }) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );
  const fields = useSourceFields(bpEditSvc);

  const memoizedDel = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    errors: delErrors,
    loading: delLoading,
    reload: deleteFunc,
  } = useApiData(memoizedDel);

  const deleteCallback = useCallback(() => {
    deleteFunc({
      nodeId: filterCondition.id || '',
      nodeType: BlueprintNodeType.FilterCondition,
    });
  }, [deleteFunc]);

  const memoizedUpdate = useMemo(() => {
    return bpEditSvc.updateFilterCondition.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    errors: updateErrors,
    loading: updateLoading,
    reload: updateFunc,
  } = useApiData(memoizedUpdate);

  const updateCallback = useCallback(
    async (condition: BlueprintFilterConditionNode) => {
      const result = await updateFunc(condition);
      return result.success;
    },
    [updateFunc]
  );

  return (
    <FilterConditionNodeUI
      errors={[...delErrors, ...updateErrors]}
      loading={delLoading || updateLoading}
      fields={fields}
      filterCondition={filterCondition}
      delete={deleteCallback}
      update={updateCallback}
    />
  );
};
