import {
  BlueprintCycleInputField,
  BlueprintExecutionInfo,
  BlueprintExecutionResult,
  BlueprintInputField,
  BlueprintSpecialInputField,
  DataRow,
  ExecutionResultRow,
  GroupedRow,
} from '~/~legacy/services/BlueprintExecutionService';

import {
  GetBlueprintExecutionInfoQuery,
  LaunchBlueprintMutation,
} from '~/shared/graphql';

export class BlueprintExecutionResponseConvertor {
  fromBlueprintExecutionInfoResponse(
    response: GetBlueprintExecutionInfoQuery,
    blueprintId: string
  ): BlueprintExecutionInfo {
    const { blueprint } = response;
    if (!blueprint)
      throw new Error(`Blueprint with id [${blueprintId}] not found`);

    const inputs: BlueprintInputField[] = blueprint.inputs
      .filter(inputValue => inputValue.valueKinds.length > 0)
      .map(inputValue => {
        return {
          id: inputValue.id,
          name: inputValue.name,
          prompt: inputValue.prompt,
          cycleInputId: inputValue.cycleInput?.id,
          userInput: undefined,
          valueKind: inputValue.valueKinds.find(x => x) || 'Unknown',
        };
      });

    const cycleInputs: BlueprintCycleInputField[] =
      blueprint.cycleInputs.map(cycleInput => {
        return {
          id: cycleInput.id,
          userInput: [],
          inputs: inputs.filter(item => item.cycleInputId === cycleInput.id),
        };
      }) || [];

    const specialInput: BlueprintSpecialInputField | undefined =
      blueprint.specialInput
        ? {
            actionId: blueprint.specialInput.action.id,
            actionType: blueprint.specialInput.action.kind,
            name: blueprint.specialInput.name,
            prompt: blueprint.specialInput.prompt,
            data: undefined,
            convertedForApi: undefined,
          }
        : undefined;

    const result: BlueprintExecutionInfo = {
      id: blueprint.id,
      description: blueprint.description,
      name: blueprint.name,
      runType: blueprint.runSettings.runType,
      inputs,
      cycleInputs,
      specialInput,

      isForIntegratorOnly: blueprint.isForIntegratorOnly,
      priority: blueprint.priority,
    };

    return result;
  }

  fromLaunchBlueprintResponse(
    response: LaunchBlueprintMutation
  ): BlueprintExecutionResult {
    const result: BlueprintExecutionResult = {
      dataColumns: response.launchBlueprint?.dataRowColumns || [],
      rowsCount: response.launchBlueprint?.rowsCount ?? 0,
      rows:
        response.launchBlueprint?.rows.map(item => {
          const groupedRow: GroupedRow | undefined =
            item.__typename === 'BlueprintLaunchGroupedRow'
              ? {
                  __typename: item.__typename,
                  groupKey: item.groupKey,
                  groupValue: item.groupValue,
                  rowsCount: item.rowsCount,
                  rows: item.rows as ExecutionResultRow[],
                }
              : undefined;

          const dataRow: DataRow | undefined =
            item.__typename === 'BlueprintLaunchDataRow'
              ? {
                  __typename: item.__typename,
                  row: item.row,
                }
              : undefined;

          const res = groupedRow || dataRow;
          if (!res) throw new Error('empty row in resuts!');

          return res;
        }) || [],
    };

    return result;
  }
}
