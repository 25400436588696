import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * A hook for creating a memoized event callback.
 */
export function useEventCallback<Args extends unknown[], R>(
  handler: (...args: Args) => R
): (...args: Args) => R {
  const ref = useRef<typeof handler>(handler);

  useLayoutEffect(() => {
    ref.current = handler;
  }, [handler]);

  return useCallback((...args: Args) => ref.current(...args), [ref]);
}
