import React from 'react';

import R from 'ramda';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { useEditReproductionHdrAndPrReportModal } from '~/entities/reproductionHdrAndPrReports';
import { ReproductionHdrAndPrReportFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrReport.graphql';
import { useDeleteReproductionHdrAndPrReportMutation } from '~/entities/reproductionHdrAndPrReports/gql/mutations/deleteReproductionHdrAndPrReport.graphql';
import {
  ReproductionHdrAndPrReportsDocument,
  ReproductionHdrAndPrReportsQueryVariables,
} from '~/entities/reproductionHdrAndPrReports/gql/queries/reproductionHdrAndPrReports.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render menu for
   */
  reproductionHdrAndPrReport: ReproductionHdrAndPrReportFragment;
  /**
   * Gql custom reports query variables
   */
  queryVariables: ReproductionHdrAndPrReportsQueryVariables;
}

export const ReproductionHdrAndPrReportCardMenu: React.FC<Props> = ({
  reproductionHdrAndPrReport,
  className,
  queryVariables,
}) => {
  const [deleteReproductionHdrAndPrReport] =
    useDeleteReproductionHdrAndPrReportMutation();

  const confirmDelete = useConfirm();

  const { open: openEditReproductionHdrAndPrReportModal } =
    useEditReproductionHdrAndPrReportModal();

  return (
    <ContextMenuButton
      {...{
        className,
        tooltip: 'Действия с отчётом',
        items: [
          {
            content: 'Редактировать свойства отчёта',
            onPress: () =>
              openEditReproductionHdrAndPrReportModal({
                reproductionHdrAndPrReport,
                queryVariables,
              }),
          },
          {
            variant: MenuItemVariants.destructive,
            content: 'Удалить',
            onPress: () => {
              confirmDelete({
                title: 'Удаление отчёта',
                message: (
                  <div className="grid gap-12">
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Вы хотите удалить отчёт{' '}
                      <Typography variant={TypographyVariants.bodySmallStrong}>
                        {reproductionHdrAndPrReport.name}
                      </Typography>
                      ?
                    </Typography>
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Это действие невозможно отменить.
                    </Typography>
                  </div>
                ),
                isDelete: true,
              }).then(isConfirmed => {
                if (!isConfirmed) return;
                deleteReproductionHdrAndPrReport({
                  variables: {
                    id: reproductionHdrAndPrReport.id,
                  },
                  optimisticResponse: {
                    deleteReproductionHdrAndPrReport: null,
                  },
                  update: R.juxt([
                    makeDeleteFragmentFromQuery({
                      typeName: 'ReproductionHdrAndPrReport',
                      query: ReproductionHdrAndPrReportsDocument,
                      queryName: 'reproductionHdrAndPrReports',
                      variables: queryVariables,
                    })(reproductionHdrAndPrReport.id),
                    makeDeleteQueriesByNameWithoutVariables(
                      'reproductionHdrAndPrReports',
                      queryVariables
                    ),
                  ]),
                });
              });
            },
          },
        ],
      }}
    />
  );
};
