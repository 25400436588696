import { MilkingParlorManufacturerEnum } from '@graphql-types';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { MILKING_PARLOR_MANUFACTURERS_DICT } from '../constants';

/**
 * Select for milking parlor manufacturer
 */
export const useMilkingParlorManufacturerSelect = makeUseEnumSelect(
  MilkingParlorManufacturerEnum,
  enumValue =>
    MILKING_PARLOR_MANUFACTURERS_DICT[
      enumValue as MilkingParlorManufacturerEnum
    ]
);
