import { useEffect, useMemo, useRef, useState } from 'react';

import { ChangeValueCallbackType } from './types/valueEditorTypes';
import { useEditorSubmit } from './useEditorSubmit';

export function useEditLogic<T = any>(
  value: T | undefined,
  onChange: ChangeValueCallbackType<T> | undefined,
  submit: ChangeValueCallbackType<T> | undefined,
  toggle: () => void,
  focused?: boolean
) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const changeCallback = useMemo(() => {
    const emptyFunc = () => {};
    return onChange || emptyFunc;
  }, [onChange]);

  const elementRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (focused) {
      window.setTimeout(() => {
        elementRef.current?.focus();
      }, 10);
    }
  }, [focused]);

  const formSubmitCallback = useEditorSubmit(submit, toggle, val);

  return {
    val,
    setVal,
    changeCallback,
    formSubmitCallback,
    elementRef,
  };
}
