import React from 'react';

import clsx from 'clsx';

import {
  Button,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography } from '~/shared/components/Typography';
import { formatInt } from '~/shared/helpers/number';

import {
  SizeVariants,
  TypographyVariants,
} from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

const TABLE_ROWS_TO_OVERLAP = 2;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, shows caption for hidden rows, columns otherwise
   */
  isRows?: boolean;
  /**
   * If true, changes the layout of hidden rows caption to not overlap hidden columns caption
   */
  isColumnsHidden?: boolean;
  /**
   * Items count to correct render row overlap
   */
  itemsCount?: number;
  /**
   * Hidden items count
   */
  hiddenItemsCount: number;
  /**
   * Called, when show all button is pressed
   */
  onShowAll: () => void;
}

export const HiddenDataCaption: React.FC<Props> = ({
  className,
  isRows = false,
  isColumnsHidden = false,
  itemsCount,
  hiddenItemsCount,
  onShowAll,
}) => {
  const WrapperComponent = isRows ? 'tr' : 'caption';
  const CardComponent = isRows ? 'td' : 'div';
  return (
    <WrapperComponent
      {...{
        className: clsx(
          isRows
            ? styles.hiddenDataRowsCaption
            : styles.hiddenDataColumnsCaption,
          isColumnsHidden && styles.hiddenDataRowsAndColumns,
          className
        ),
        style: {
          gridRow: itemsCount
            ? `${itemsCount - TABLE_ROWS_TO_OVERLAP} / ${itemsCount + 1}`
            : undefined,
        },
      }}
    >
      <CardComponent className="flex flex-col text-center items-center">
        <Icon
          {...{
            className: 'mb-12 text-soft',
            variant: IconVariants.eyeShow,
            size: SizeVariants.size20,
          }}
        />
        <Typography
          className="mb-8"
          variant={TypographyVariants.descriptionLarge}
        >
          Часть таблицы была скрыта из-за большого размера
        </Typography>
        <Typography
          className="mb-16"
          variant={TypographyVariants.descriptionLarge}
        >
          Скрыто {isRows ? 'строк' : 'столбцов'}: {formatInt(hiddenItemsCount)}
        </Typography>

        <Button
          {...{
            theme: ButtonThemes.accent,
            variant: ButtonVariants.secondary,
            onPress: onShowAll,
          }}
        >
          Показать все
        </Button>
      </CardComponent>
    </WrapperComponent>
  );
};
