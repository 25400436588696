import { useMemo } from 'react';
import {
  FieldValues,
  Resolver,
  useForm as useFormVendor,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { UseFormInterface, UseFormProps } from './types';

/**
 * Vendor wrapper for react-hook-form for using with validation schema
 */
export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TTransformedValues extends FieldValues | undefined = TFieldValues,
  TContext extends object = object,
>({
  schema,
  ...useFormProps
}: UseFormProps<TFieldValues, TContext>): UseFormInterface<
  TFieldValues,
  TTransformedValues,
  TContext
> => {
  // https://github.com/react-hook-form/resolvers/issues/648
  const resolver = useMemo(
    () => yupResolver(schema),
    [schema]
  ) as unknown as Resolver<TFieldValues>;
  return useFormVendor<TFieldValues, TContext, TTransformedValues>({
    ...useFormProps,
    resolver,
  });
};
