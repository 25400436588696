import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { CustomMilkingReportFragment } from '../gql/fragments/customMilkingReport.graphql';
import {
  CustomMilkingReportsQuery,
  CustomMilkingReportsQueryVariables,
  useCustomMilkingReportsQuery,
} from '../gql/queries/customMilkingReports.graphql';

export const useCustomMilkingReportsPaginatedQuery = makeUsePaginatedQuery<
  CustomMilkingReportFragment,
  CustomMilkingReportsQuery,
  CustomMilkingReportsQueryVariables
>({
  useQuery: useCustomMilkingReportsQuery,
  getItemsFromQueryData: data =>
    data.customMilkingReports.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.customMilkingReports.pageInfo,
});
