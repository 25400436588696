import {
  FemaleAnimalKind,
  ReproductionHdrAndPrXAxisMode,
} from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { formatXAxisModeName } from '../../../../helpers';

const useReproductionHdrAndPrXAxisModeSelect = makeUseEnumSelect<
  Record<ReproductionHdrAndPrXAxisMode, string>,
  { femaleAnimalKind: FemaleAnimalKind }
>(
  ReproductionHdrAndPrXAxisMode as unknown as Record<
    ReproductionHdrAndPrXAxisMode,
    string
  >,
  (enumValue, { femaleAnimalKind }) =>
    formatXAxisModeName(enumValue, femaleAnimalKind)
);

export const ReproductionHdrAndPrXAxisModeSelect = makeSelectComponentFromHook(
  useReproductionHdrAndPrXAxisModeSelect
);
