import React, { ReactElement } from 'react';

import { Link, LinkProps } from '@tanstack/react-router';
import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  /**
   * If true, link is not rendered, renders a span
   */
  isDisabled?: boolean;
  /**
   * Typography variant of the rendered link
   */
  typographyVariant?: TypographyVariants;
  /**
   * className applied to the Link element
   */
  className?: string;
}

export const TextLink = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      className,
      isDisabled = false,
      typographyVariant = TypographyVariants.bodySmall,

      children,
      ...other
    }: Props,
    ref
  ) => {
    if (isDisabled) {
      return (
        <Typography
          {...{
            ref,
            variant: typographyVariant,
            className: typeof className === 'string' ? className : undefined,
          }}
        >
          {typeof children === 'function' ? null : children}
        </Typography>
      );
    }

    return (
      <Link
        {...{
          ref,
          className: clsx(styles.root, className),
          ...other,
        }}
      >
        {React.isValidElement(children) ? (
          children
        ) : (
          <Typography variant={typographyVariant}>
            {children as ReactElement}
          </Typography>
        )}
      </Link>
    );
  }
);
