import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { MAPPING_ITEM_SCHEMA } from '../constants';

interface Props {
  /**
   * Initial values for the DiseaseMappings form
   */
  defaultValues: DiseaseMappingsFormType;
  /**
   * Called, when the form is submitted
   */
  onSubmit: (form: DiseaseMappingsFormType) => void;
}

const FORM_ID = 'DiseaseMappingsForm';

const SCHEMA = yup.object({
  diseaseMappings: yup.array(MAPPING_ITEM_SCHEMA).default([]),
});

/**
 * Form for editing disease mapping items
 */
export type DiseaseMappingsFormType = InferValidatedSchema<typeof SCHEMA>;

export const useDiseaseMappingsForm = ({
  defaultValues,
  onSubmit: handleSubmit,
}: Props): UseModalStepFormInterface<DiseaseMappingsFormType> => {
  const formContext = useForm<DiseaseMappingsFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...defaultValues,
    },
  });

  return {
    formContext,
    formId: FORM_ID,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
