import { ModalRegistration } from '~/services/modals';

import {
  EditCustomMilkingReportModal,
  EditCustomMilkingReportModalProps,
} from '.';

export const registration: ModalRegistration<EditCustomMilkingReportModalProps> =
  {
    Component: EditCustomMilkingReportModal,
  };
