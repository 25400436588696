import React from 'react';

export const Duplicate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3.61612 3.61612C3.85054 3.3817 4.16848 3.25 4.5 3.25H13.5C13.8315 3.25 14.1495 3.3817 14.3839 3.61612C14.6183 3.85054 14.75 4.16848 14.75 4.5V5.5C14.75 5.91421 15.0858 6.25 15.5 6.25C15.9142 6.25 16.25 5.91421 16.25 5.5V4.5C16.25 3.77065 15.9603 3.07118 15.4445 2.55546C14.9288 2.03973 14.2293 1.75 13.5 1.75H4.5C3.77065 1.75 3.07118 2.03973 2.55546 2.55546C2.03973 3.07118 1.75 3.77065 1.75 4.5V13.5C1.75 14.2293 2.03973 14.9288 2.55546 15.4445C3.07118 15.9603 3.77065 16.25 4.5 16.25H5.5C5.91421 16.25 6.25 15.9142 6.25 15.5C6.25 15.0858 5.91421 14.75 5.5 14.75H4.5C4.16848 14.75 3.85054 14.6183 3.61612 14.3839C3.3817 14.1495 3.25 13.8315 3.25 13.5V4.5C3.25 4.16848 3.3817 3.85054 3.61612 3.61612Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 7.75C8.98122 7.75 7.75 8.98122 7.75 10.5V19.5C7.75 21.0188 8.98122 22.25 10.5 22.25H19.5C21.0188 22.25 22.25 21.0188 22.25 19.5V10.5C22.25 8.98122 21.0188 7.75 19.5 7.75H10.5ZM9.25 10.5C9.25 9.80964 9.80964 9.25 10.5 9.25H19.5C20.1904 9.25 20.75 9.80964 20.75 10.5V19.5C20.75 20.1904 20.1904 20.75 19.5 20.75H10.5C9.80964 20.75 9.25 20.1904 9.25 19.5V10.5Z"
      fill="currentColor"
    />
  </svg>
);
