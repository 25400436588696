import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDiseaseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDiseaseMutation = { __typename?: 'Mutation', deleteDisease?: any | null };


export const DeleteDiseaseDocument = gql`
    mutation deleteDisease($id: ID!) {
  deleteDisease(id: $id)
}
    `;
export type DeleteDiseaseMutationFn = Apollo.MutationFunction<DeleteDiseaseMutation, DeleteDiseaseMutationVariables>;

/**
 * __useDeleteDiseaseMutation__
 *
 * To run a mutation, you first call `useDeleteDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiseaseMutation, { data, loading, error }] = useDeleteDiseaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiseaseMutation, DeleteDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiseaseMutation, DeleteDiseaseMutationVariables>(DeleteDiseaseDocument, options);
      }
export type DeleteDiseaseMutationHookResult = ReturnType<typeof useDeleteDiseaseMutation>;
export type DeleteDiseaseMutationResult = Apollo.MutationResult<DeleteDiseaseMutation>;
export type DeleteDiseaseMutationOptions = Apollo.BaseMutationOptions<DeleteDiseaseMutation, DeleteDiseaseMutationVariables>;