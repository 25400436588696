import React, { useState } from 'react';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import R from 'ramda';

import {
  BlueprintOrderableEntity,
  BlueprintOrderableEntityType,
} from '../BlueprintOrderableEntity';
import { BlueprintOrderableEntitySortable } from '../BlueprintOrderableEntitySortable';

interface Props {
  /**
   * Fields list to display
   */
  entities: BlueprintOrderableEntityType[];
  /**
   * If true, than entities rendered with checkboxes, default - true
   */
  withSelectableEntities?: boolean;
  /**
   * Called, when a field selected state is changed
   */
  onEntitySelectionChange?: (entityId: string, isSelected: boolean) => void;
  /**
   * Called, when entities ordering is changed via dnd
   */
  onEntitiesOrderChange: (entityIds: string[]) => void;
}

export const SortableBlueprintOrderableEntitiesList: React.FC<Props> = ({
  entities,
  withSelectableEntities = true,
  onEntitySelectionChange,
  onEntitiesOrderChange,
}) => {
  const [draggedFieldId, setDraggedFieldId] = useState<string>();
  const draggedEntity = entities.find(f => f.id === draggedFieldId);

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDraggedFieldId(active.id.toString());
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) return;

    const activeFieldIndex = entities.findIndex(f => f.id === active.id);
    const overFieldIndex = entities.findIndex(f => f.id === over.id);
    onEntitiesOrderChange(
      R.move(activeFieldIndex, overFieldIndex, entities.map(R.prop('id')))
    );

    setDraggedFieldId(undefined);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      {...{
        sensors,
        collisionDetection: closestCenter,
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
      }}
    >
      <SortableContext items={entities} strategy={verticalListSortingStrategy}>
        {entities.map((entity, index) => (
          <BlueprintOrderableEntitySortable
            key={`${entity.id}_${index}`}
            {...{
              entity,
              withCheckbox: withSelectableEntities,
              isSelected: true,
              onSelectedChange: newIsSelected => {
                onEntitySelectionChange?.(entity.id, newIsSelected);
              },
            }}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {draggedEntity && (
          <BlueprintOrderableEntity
            {...{
              entity: draggedEntity,
              withCheckbox: withSelectableEntities,
              isSelected: true,
              isDragging: true,
              isDragOverlay: true,
              withDragHandle: true,
            }}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};
