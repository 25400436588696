import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { HerriotIntegrationEntityFragmentDoc } from '../../gql/fragments/herriotIntegrationEntity.graphql';
import { useHerriotIntegrationEntitiesQuery } from '../../gql/queries/herriotIntegrationEntities.graphql';

/**
 * Select for a herriot integration entity
 */
const useHerriotIntegrationEntitySelect = makeUseAsyncSelect({
  typeName: 'HerriotIntegrationEntity',
  fragment: HerriotIntegrationEntityFragmentDoc,
  useQuery: useHerriotIntegrationEntitiesQuery,
  getItemsFromQueryData: data => data.herriotIntegrationEntities.nodes,
  getPageInfoFromQueryData: data => data.herriotIntegrationEntities.pageInfo,
  getItemKey: item => item.guid,
  getIdentifyFields: guid => ({ guid }),
});

export const HerriotIntegrationEntityAsyncSelect = makeAsyncSelectFormComponent(
  useHerriotIntegrationEntitySelect
);
