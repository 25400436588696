import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { ReactComponent as TimesSVG } from '~/~legacy/icons/times.svg';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { ArgumentValueInput, NodeValueKindEnum } from '~/shared/graphql';

import TOKENS from '~/styles/__generated__/tokens.json';

import {
  BlueprintNodeType,
  BlueprintWritableArgument,
} from '../../../../services';
import { NodeFrame } from '../../../NodeFrame';
import { ValueEditor } from '../ValueEditor';
import styles from './index.module.scss';

interface Props extends ApiFeedbackProps {
  parentNodeType: BlueprintNodeType;
  argument: BlueprintWritableArgument;
  submit: (
    value: ArgumentValueInput,
    nodeValueKind: NodeValueKindEnum
  ) => Promise<boolean>;
  unset: () => void;
}

export const ArgumentNodeUI: React.FC<Props> = props => {
  const { parentNodeType, argument, submit, unset, errors, loading } = props;

  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const unsetCallback = useCallback(() => {
    unset();
  }, [unset]);

  const delButton = argument.isRequired ? null : (
    <div onClick={unsetCallback} className={styles.action}>
      <TimesSVG />
    </div>
  );

  const [forceClosed] = useState(false);

  const [, setLocalValue] = useState({
    value: argument.value,
    valueKind: argument.valueKind || NodeValueKindEnum.Hardcode,
  });
  const changeCallback = useCallback(
    (val: any, valueKind: NodeValueKindEnum) => {
      setLocalValue({
        value: val,
        valueKind,
      });
    },
    [setLocalValue]
  );

  return (
    <NodeFrame
      className="my-8"
      header={
        <div className={styles.header}>
          {argument.name} / {argument.kind}{' '}
          {argument.isRequired
            ? t(IntegratorStrings.blueprintEdit.nodes.agumentNode.required)
            : ''}
          {delButton}
        </div>
      }
      headerStyle={{
        background: TOKENS.colorSuccess150,
      }}
    >
      <div className="p-8">
        <ValueEditor
          name="value"
          edit={forceClosed}
          submit={submit}
          onChange={changeCallback}
          value={argument.value}
          inputValue={argument.inputValue}
          kind={argument.kind}
          valueKind={argument.valueKind}
          parentNodeId={argument.nodeId}
          parentNodeType={parentNodeType}
        />

        {loader}
        {errorMessage}
      </div>
    </NodeFrame>
  );
};
