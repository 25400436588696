import React, { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { createFileRoute, Outlet } from '@tanstack/react-router';

import { usePrevious } from '~/shared/hooks/usePrevious';

import { useArkaNavigation } from '~/services/navigation';

import { CompanyLoadingBlocker } from '~/entities/companies';

import { BaseLayout } from '~/features/layouts';

export const Route = createFileRoute('/$companyId/_layout')({
  component: AuthBaseLayout,
});

function AuthBaseLayout() {
  const apolloClient = useApolloClient();
  const { selectedCompanyId, selectedCompany } = useArkaNavigation();
  const prevCompanyId = usePrevious(selectedCompanyId);

  // Clear cache on selectedCompanyId change,
  // cause all queries should be requested with the new x-active-company header
  useEffect(() => {
    if (prevCompanyId && prevCompanyId !== selectedCompanyId) {
      apolloClient.resetStore();
    }
  }, [selectedCompanyId]);

  return (
    <BaseLayout withMenu>
      {selectedCompany?.isLockedForWrite ? (
        <CompanyLoadingBlocker />
      ) : (
        <Outlet />
      )}
    </BaseLayout>
  );
}
