import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MilkingParlorMilkingMistakesReportGroupedRowEntryFragment = { __typename: 'MilkingParlorMilkingMistakesReportGroupedRowEntry', reattachCount: number, noMilkCount: number, enteredTwiceCount: number, noLetdownCount: number, earlyFalloffCount: number, lateRehangCount: number, manualDetachCount: number, manualModeCount: number, notIdentifiedCount: number, dryCount: number, totalCount: number };

export const MilkingParlorMilkingMistakesReportGroupedRowEntryFragmentDoc = gql`
    fragment MilkingParlorMilkingMistakesReportGroupedRowEntry on MilkingParlorMilkingMistakesReportGroupedRowEntry {
  __typename
  reattachCount
  noMilkCount
  enteredTwiceCount
  noLetdownCount
  earlyFalloffCount
  lateRehangCount
  manualDetachCount
  manualModeCount
  notIdentifiedCount
  dryCount
  totalCount
}
    `;