import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import Backend, { BackendOptions } from 'i18next-http-backend';

import { AppLocalStorageKeys } from '~/shared/constants';

export function getLanguage() {
  try {
    const storedLang = localStorage.getItem(AppLocalStorageKeys.language);
    return storedLang || 'ru';
  } catch {
    return 'ru';
  }
}

export function initI18n(
  defaultLang: string,
  namespaces?: string[],
  devMode?: boolean
) {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'ru',
      returnEmptyString: false,
      lng: defaultLang,
      ns: namespaces || ['common'],
      debug: devMode,
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?version=${process.env.REACT_APP_VERSION}`,
      } as BackendOptions,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

  return i18n;
}
