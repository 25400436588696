import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCustomMilkingReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteCustomMilkingReportMutation = { __typename?: 'Mutation', deleteCustomMilkingReport?: any | null };


export const DeleteCustomMilkingReportDocument = gql`
    mutation deleteCustomMilkingReport($id: ID!) {
  deleteCustomMilkingReport(id: $id)
}
    `;
export type DeleteCustomMilkingReportMutationFn = Apollo.MutationFunction<DeleteCustomMilkingReportMutation, DeleteCustomMilkingReportMutationVariables>;

/**
 * __useDeleteCustomMilkingReportMutation__
 *
 * To run a mutation, you first call `useDeleteCustomMilkingReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomMilkingReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomMilkingReportMutation, { data, loading, error }] = useDeleteCustomMilkingReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomMilkingReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomMilkingReportMutation, DeleteCustomMilkingReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomMilkingReportMutation, DeleteCustomMilkingReportMutationVariables>(DeleteCustomMilkingReportDocument, options);
      }
export type DeleteCustomMilkingReportMutationHookResult = ReturnType<typeof useDeleteCustomMilkingReportMutation>;
export type DeleteCustomMilkingReportMutationResult = Apollo.MutationResult<DeleteCustomMilkingReportMutation>;
export type DeleteCustomMilkingReportMutationOptions = Apollo.BaseMutationOptions<DeleteCustomMilkingReportMutation, DeleteCustomMilkingReportMutationVariables>;