import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMonitorEntryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteMonitorEntryMutation = { __typename?: 'Mutation', deleteMonitorEntry?: any | null };


export const DeleteMonitorEntryDocument = gql`
    mutation deleteMonitorEntry($id: ID!) {
  deleteMonitorEntry(id: $id)
}
    `;
export type DeleteMonitorEntryMutationFn = Apollo.MutationFunction<DeleteMonitorEntryMutation, DeleteMonitorEntryMutationVariables>;

/**
 * __useDeleteMonitorEntryMutation__
 *
 * To run a mutation, you first call `useDeleteMonitorEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonitorEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonitorEntryMutation, { data, loading, error }] = useDeleteMonitorEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMonitorEntryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMonitorEntryMutation, DeleteMonitorEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMonitorEntryMutation, DeleteMonitorEntryMutationVariables>(DeleteMonitorEntryDocument, options);
      }
export type DeleteMonitorEntryMutationHookResult = ReturnType<typeof useDeleteMonitorEntryMutation>;
export type DeleteMonitorEntryMutationResult = Apollo.MutationResult<DeleteMonitorEntryMutation>;
export type DeleteMonitorEntryMutationOptions = Apollo.BaseMutationOptions<DeleteMonitorEntryMutation, DeleteMonitorEntryMutationVariables>;