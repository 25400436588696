import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as PencilSVG } from '~/~legacy/icons/pencil-outline.svg';

import { MControlProps } from '~/shared/types/controls';

import './MInlineEditor.scss';

interface Props extends MControlProps {
  value: string;

  notifyEditStatus?: (val: boolean) => void;
  onUpdate: (val: string) => void;
}

export const MInlineEditor: React.FC<Props> = props => {
  const { value, placeholder, onUpdate, notifyEditStatus, className, onBlur } =
    props;

  const inputRref = useRef<HTMLInputElement>(null);
  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState(value || '');

  useLayoutEffect(() => {
    if (editing) {
      inputRref.current?.focus();
    }
  }, [editing]);

  const toggle = useCallback(() => {
    setEditing(prev => !prev);
  }, [setEditing, notifyEditStatus]);

  useEffect(() => {
    if (notifyEditStatus) {
      notifyEditStatus(editing);
    }
  }, [editing]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value;
      setVal(newVal);
    },
    [setVal]
  );

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        toggle();
      }
    },
    [toggle]
  );

  const onBlurCallback: FocusEventHandler = useCallback(
    e => {
      onUpdate(val);
      toggle();
      if (onBlur) {
        onBlur(e);
      }
    },
    [onUpdate, toggle, val, onBlur]
  );

  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onUpdate(val);
      toggle();

      return false;
    },
    [onUpdate, toggle, val]
  );

  const view = (
    <span className={`m-inline-editor ${className || ''}`} onClick={toggle}>
      {value} <PencilSVG />
    </span>
  );
  const edit = (
    <form className={`m-inline-editor ${className || ''}`} onSubmit={submit}>
      <input
        ref={inputRref}
        value={val}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onBlur={onBlurCallback}
        placeholder={placeholder}
      />

      <input
        tabIndex={-1}
        type="submit"
        style={{
          width: 0,
          height: 0,
          opacity: 0,
          padding: 0,
          border: 'none',
        }}
      />
    </form>
  );

  return editing ? edit : view;
};
