import React from 'react';

import clsx from 'clsx';

import { ColoredDot } from '~/shared/components/ColoredDot';
import { TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatInt, formatNumber } from '~/shared/helpers/number';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Row label
   */
  label: string;
  /**
   * Row value
   */
  value?: number;
  /**
   * If passed, label has a dot with dotColor before it
   */
  dotColor?: string;
  /**
   * Typography variant for the row
   */
  typographyVariant?: TypographyVariants;
  /**
   * If true, value is formatted as float number
   */
  isFloat?: boolean;
}

export const CardRow: React.FC<Props> = ({
  className,
  label,
  value,
  dotColor,
  typographyVariant = TypographyVariants.bodySmall,
  isFloat = false,
}) => {
  return (
    <div className={clsx('flex items-center', className)}>
      {!!dotColor && <ColoredDot className="mr-8" color={dotColor} />}
      <Typography variant={typographyVariant}>{label}</Typography>
      <Typography
        {...{
          className: 'ml-a',
          variant: typographyVariant,
          skeletonSize: TextSkeletonSizes.small,
        }}
      >
        {isFloat ? formatNumber(value) : formatInt(value)}
      </Typography>
    </div>
  );
};
