import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MonitorGroupFragment = { __typename: 'MonitorGroup', id: string, name: string };

export const MonitorGroupFragmentDoc = gql`
    fragment MonitorGroup on MonitorGroup {
  __typename
  id
  name
}
    `;