import React from 'react';

export const Dots = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z"
      fill="currentColor"
    />
    <path
      d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
      fill="currentColor"
    />
    <path
      d="M6 14C7.10456 14 8 13.1046 8 12C8 10.8954 7.10456 10 6 10C4.89544 10 4 10.8954 4 12C4 13.1046 4.89544 14 6 14Z"
      fill="currentColor"
    />
  </svg>
);
