import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetMilkingParlorFarmSettingsMutationVariables = Types.Exact<{
  farmID: Types.Scalars['ID']['input'];
  firstMilkingPerDayStartOn: Types.Scalars['Time']['input'];
}>;


export type SetMilkingParlorFarmSettingsMutation = { __typename?: 'Mutation', setMilkingParlorFarmSettings?: any | null };


export const SetMilkingParlorFarmSettingsDocument = gql`
    mutation setMilkingParlorFarmSettings($farmID: ID!, $firstMilkingPerDayStartOn: Time!) {
  setMilkingParlorFarmSettings(
    farmID: $farmID
    firstMilkingPerDayStartOn: $firstMilkingPerDayStartOn
  )
}
    `;
export type SetMilkingParlorFarmSettingsMutationFn = Apollo.MutationFunction<SetMilkingParlorFarmSettingsMutation, SetMilkingParlorFarmSettingsMutationVariables>;

/**
 * __useSetMilkingParlorFarmSettingsMutation__
 *
 * To run a mutation, you first call `useSetMilkingParlorFarmSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMilkingParlorFarmSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMilkingParlorFarmSettingsMutation, { data, loading, error }] = useSetMilkingParlorFarmSettingsMutation({
 *   variables: {
 *      farmID: // value for 'farmID'
 *      firstMilkingPerDayStartOn: // value for 'firstMilkingPerDayStartOn'
 *   },
 * });
 */
export function useSetMilkingParlorFarmSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetMilkingParlorFarmSettingsMutation, SetMilkingParlorFarmSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMilkingParlorFarmSettingsMutation, SetMilkingParlorFarmSettingsMutationVariables>(SetMilkingParlorFarmSettingsDocument, options);
      }
export type SetMilkingParlorFarmSettingsMutationHookResult = ReturnType<typeof useSetMilkingParlorFarmSettingsMutation>;
export type SetMilkingParlorFarmSettingsMutationResult = Apollo.MutationResult<SetMilkingParlorFarmSettingsMutation>;
export type SetMilkingParlorFarmSettingsMutationOptions = Apollo.BaseMutationOptions<SetMilkingParlorFarmSettingsMutation, SetMilkingParlorFarmSettingsMutationVariables>;