import React, { ReactElement } from 'react';

import { BlueprintRunSettings, ScheduleInterval } from '~/shared/graphql';

import { BlueprintDetailsEditModel } from '../../../../types';
import { BlueprintDetailsRunSettingsScheduleBasicForm } from '../BlueprintDetailsRunSettingsScheduleBasicForm';
import { BlueprintDetailsRunSettingsScheduleBiWeekly } from '../BlueprintDetailsRunSettingsScheduleBiWeekly';
import { BlueprintDetailsRunSettingsScheduleWeekly } from '../BlueprintDetailsRunSettingsScheduleWeekly';

interface Props {
  runSettings: BlueprintRunSettings;
  onChange: React.Dispatch<React.SetStateAction<BlueprintDetailsEditModel>>;
}

export const BlueprintDetailsRunSettingsSchedule: React.FC<Props> = ({
  runSettings,
  onChange,
}) => {
  const specialIntervalMap = {
    [ScheduleInterval.Weekly]: () => (
      <BlueprintDetailsRunSettingsScheduleWeekly
        runSettings={runSettings}
        onChange={onChange}
      />
    ),
    [ScheduleInterval.BiWeekly]: () => (
      <BlueprintDetailsRunSettingsScheduleBiWeekly
        runSettings={runSettings}
        onChange={onChange}
      />
    ),
  } as Record<ScheduleInterval, () => ReactElement>;

  const defaultFormGetter = () => <></>;
  const getForm = runSettings.interval
    ? specialIntervalMap[runSettings.interval] || defaultFormGetter
    : defaultFormGetter;

  const specialForm = getForm();

  return (
    <div>
      <BlueprintDetailsRunSettingsScheduleBasicForm
        onChange={onChange}
        runSettings={runSettings}
      />
      {specialForm}
    </div>
  );
};
