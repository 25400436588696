import React from 'react';

export const Profile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.75C7.41421 3.75 3.75 7.41421 3.75 12C3.75 13.8106 4.36058 15.4931 5.35674 16.869C7.06312 15.2742 9.40355 14.25 12 14.25C14.5918 14.25 16.8803 15.2437 18.6704 16.8313C19.6505 15.4624 20.25 13.7941 20.25 12C20.25 7.47 16.53 3.75 12 3.75ZM17.7272 18C16.1752 16.6031 14.213 15.75 12 15.75C9.76649 15.75 7.757 16.646 6.30926 18.0326C7.8555 19.4102 9.80434 20.25 12 20.25C14.213 20.25 16.1752 19.3969 17.7272 18ZM2.25 12C2.25 6.58579 6.58579 2.25 12 2.25C17.3722 2.25 21.75 6.63395 21.75 12C21.75 17.4333 17.4299 21.75 12 21.75C6.57652 21.75 2.25 17.4315 2.25 12ZM8.75 9.5C8.75 7.68579 10.1858 6.25 12 6.25C13.8142 6.25 15.25 7.68579 15.25 9.5C15.25 11.3142 13.8142 12.75 12 12.75C10.1858 12.75 8.75 11.3142 8.75 9.5ZM12 7.75C11.0142 7.75 10.25 8.51421 10.25 9.5C10.25 10.4858 11.0142 11.25 12 11.25C12.9858 11.25 13.75 10.4858 13.75 9.5C13.75 8.51421 12.9858 7.75 12 7.75Z"
      fill="currentColor"
    />
  </svg>
);
