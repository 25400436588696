import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type FloatHardcodedValueFragment = { __typename: 'FloatHardcodedValue', floatValue: number };

export const FloatHardcodedValueFragmentDoc = gql`
    fragment FloatHardcodedValue on FloatHardcodedValue {
  __typename
  floatValue: value
}
    `;