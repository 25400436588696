import React, { ReactElement } from 'react';

import { MenuItem, MenuItemType } from './MMenu';

interface Props {
  item: MenuItem;
}

export const MMenuItem: React.FC<Props> = props => {
  const { item } = props;

  const items = {
    [MenuItemType.delemiter]: () => <li className="m-menu-item-delemiter" />,
    [MenuItemType.item]: () => (
      <li tabIndex={0} className="m-menu-item" onClick={item.action}>
        {item.content}
      </li>
    ),
    // [MenuItemType.custom]: () => <li tabIndex={0}>{item.content}</li>
  } as Record<MenuItemType, () => ReactElement>;

  const type = item.type || MenuItemType.item;
  const component = items[type];

  return component();
};
