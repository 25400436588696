import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorScheduleReportStallEntryFragmentDoc } from '../fragments/milkingParlorScheduleReportStallEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorScheduleReportEntriesQueryVariables = Types.Exact<{
  milkingDate: Types.Scalars['Date']['input'];
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingParlorIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MilkingParlorScheduleReportEntriesQuery = { __typename?: 'Query', milkingParlorScheduleReportEntries: Array<{ __typename: 'MilkingParlorScheduleReportStallEntry', stallNumber: number, milkings: Array<{ __typename?: 'ParlorStallEntryMilkingType', happenedAt: string, durationSec: number, endedAt: string, weightKilograms: number, deviationWeightKilograms: number, flowRate: number, cow?: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: number } | null, mistakes: Array<{ __typename?: 'ParlorStallEntryMistakeType', kind: Types.MilkingMistakeKindsEnum, happenedAt: string, durationSec: number, endedAt: string }> }> }> };


export const MilkingParlorScheduleReportEntriesDocument = gql`
    query milkingParlorScheduleReportEntries($milkingDate: Date!, $farmIDs: [ID!], $milkingParlorIDs: [ID!], $milkingNumbers: [Int!]) {
  milkingParlorScheduleReportEntries(
    milkingDate: $milkingDate
    farmIDs: $farmIDs
    milkingParlorIDs: $milkingParlorIDs
    milkingNumbers: $milkingNumbers
  ) {
    ...MilkingParlorScheduleReportStallEntry
  }
}
    ${MilkingParlorScheduleReportStallEntryFragmentDoc}`;

/**
 * __useMilkingParlorScheduleReportEntriesQuery__
 *
 * To run a query within a React component, call `useMilkingParlorScheduleReportEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorScheduleReportEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorScheduleReportEntriesQuery({
 *   variables: {
 *      milkingDate: // value for 'milkingDate'
 *      farmIDs: // value for 'farmIDs'
 *      milkingParlorIDs: // value for 'milkingParlorIDs'
 *      milkingNumbers: // value for 'milkingNumbers'
 *   },
 * });
 */
export function useMilkingParlorScheduleReportEntriesQuery(baseOptions: Apollo.QueryHookOptions<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables> & ({ variables: MilkingParlorScheduleReportEntriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables>(MilkingParlorScheduleReportEntriesDocument, options);
      }
export function useMilkingParlorScheduleReportEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables>(MilkingParlorScheduleReportEntriesDocument, options);
        }
export function useMilkingParlorScheduleReportEntriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables>(MilkingParlorScheduleReportEntriesDocument, options);
        }
export type MilkingParlorScheduleReportEntriesQueryHookResult = ReturnType<typeof useMilkingParlorScheduleReportEntriesQuery>;
export type MilkingParlorScheduleReportEntriesLazyQueryHookResult = ReturnType<typeof useMilkingParlorScheduleReportEntriesLazyQuery>;
export type MilkingParlorScheduleReportEntriesSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorScheduleReportEntriesSuspenseQuery>;
export type MilkingParlorScheduleReportEntriesQueryResult = Apollo.QueryResult<MilkingParlorScheduleReportEntriesQuery, MilkingParlorScheduleReportEntriesQueryVariables>;