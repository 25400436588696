import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MilkingParlorMeterFunctionReportRowEntryFragment = { __typename: 'MilkingParlorMeterFunctionReportRowEntry', cowsCount?: number | null, milkDeviationFromExpectedKilograms?: number | null, milkWeightKilograms?: number | null, durationSeconds?: number | null, flowRate?: number | null, conductivity?: number | null, avgPeakFlowRate?: number | null, fallCount?: number | null, manualModeCount?: number | null, manualDetachCount?: number | null, stallNumber: number, notIdentifiedCount?: number | null };

export const MilkingParlorMeterFunctionReportRowEntryFragmentDoc = gql`
    fragment MilkingParlorMeterFunctionReportRowEntry on MilkingParlorMeterFunctionReportRowEntry {
  __typename
  cowsCount
  milkDeviationFromExpectedKilograms
  milkWeightKilograms
  durationSeconds
  flowRate
  conductivity
  avgPeakFlowRate
  fallCount
  manualModeCount
  manualDetachCount
  stallNumber
  notIdentifiedCount
}
    `;