import React from 'react';

import clsx from 'clsx';

import {
  CloseButton,
  CloseButtonThemes,
} from '~/shared/components/CloseButton';
import {
  FunctionButton,
  FunctionButtonVariants,
} from '~/shared/components/FunctionButton';
import { Icon } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';

import { NOTIFICATION_ICON_VARIANTS_DICT } from '../../constants';
import notificationStyles from '../../notifications.module.scss';
import { NotificationProps, NotificationVariants } from '../../types';
import styles from './index.module.scss';

export type ToastProps = NotificationProps;

export const Toast: React.FC<ToastProps> = ({
  className,
  variant = NotificationVariants.warning,
  message,
  withCloseButton = false,
  onClose,
  functionButtonProps,
}) => {
  const functionButtonPropsArray = normalizeValuesToArray(functionButtonProps);
  const withFunctionButtons = !!functionButtonPropsArray?.length;

  return (
    <div className={clsx(styles.root, notificationStyles[variant], className)}>
      {variant !== NotificationVariants.neutral && (
        <Icon
          className="ml-8"
          variant={NOTIFICATION_ICON_VARIANTS_DICT[variant]}
        />
      )}
      <div className="ml-8 mr-a py-2">
        <Typography variant={TypographyVariants.bodySmall}>
          {message}
        </Typography>
      </div>
      {withFunctionButtons &&
        functionButtonPropsArray.map((buttonProps, buttonIndex) => (
          <FunctionButton
            key={buttonIndex}
            {...{
              className: 'ml-16',
              isDark: true,
              variant: FunctionButtonVariants.secondary,
              ...buttonProps,
              onPress: e => {
                onClose?.();
                buttonProps.onPress?.(e);
              },
            }}
          />
        ))}
      {withCloseButton && (
        <CloseButton
          {...{
            className: 'ml-16',
            onPress: onClose,
            theme: CloseButtonThemes.onDark,
          }}
        />
      )}
    </div>
  );
};
