import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportFragmentDoc } from '../fragments/customReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomReportMutationVariables = Types.Exact<{
  input: Types.CreateCustomReportInput;
}>;


export type CreateCustomReportMutation = { __typename?: 'Mutation', createCustomReport: { __typename: 'CustomReport', id: string, name: string, blueprint: { __typename: 'Blueprint', id: string, name: string, description: string, inputs: Array<{ __typename: 'BlueprintInput', id: string, name: string, prompt: string, valueKinds: Array<Types.ValueKindEnum> }> }, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } };


export const CreateCustomReportDocument = gql`
    mutation createCustomReport($input: CreateCustomReportInput!) {
  createCustomReport(input: $input) {
    ...CustomReport
  }
}
    ${CustomReportFragmentDoc}`;
export type CreateCustomReportMutationFn = Apollo.MutationFunction<CreateCustomReportMutation, CreateCustomReportMutationVariables>;

/**
 * __useCreateCustomReportMutation__
 *
 * To run a mutation, you first call `useCreateCustomReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomReportMutation, { data, loading, error }] = useCreateCustomReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomReportMutation, CreateCustomReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomReportMutation, CreateCustomReportMutationVariables>(CreateCustomReportDocument, options);
      }
export type CreateCustomReportMutationHookResult = ReturnType<typeof useCreateCustomReportMutation>;
export type CreateCustomReportMutationResult = Apollo.MutationResult<CreateCustomReportMutation>;
export type CreateCustomReportMutationOptions = Apollo.BaseMutationOptions<CreateCustomReportMutation, CreateCustomReportMutationVariables>;