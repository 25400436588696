import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import {
  Button,
  ButtonProps,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { getTmpId } from '~/shared/helpers/string';

import { useNotifications } from '~/services/notifications';

import { FARM_SCHEMA } from '~/entities/farms';

import panelStyles from '~/styles/modules/panel.module.scss';

import { EditCompanyFormType } from '../..';
import companyFieldArraysStyles from '../../companyFieldArrays.module.scss';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const EditFarmsArrayFieldsForm: React.FC<Props> = ({ className }) => {
  const { sendNeutralToast } = useNotifications();

  const formContext = useFormContext<EditCompanyFormType>();

  const {
    fields: farmsArrayItems,
    remove: removeFromFarmsArray,
    append: appendToFarmsArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'farms',
    keyName: 'uniqKey',
  });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    theme: ButtonThemes.accent,
    variant: ButtonVariants.secondary,
    onPress: () =>
      appendToFarmsArray({
        ...FARM_SCHEMA.getDefault(),
        id: getTmpId('farm'),
      }),
    children: 'Добавить ферму',
  } satisfies ButtonProps;

  if (!farmsArrayItems.length) {
    return (
      <div className={clsx('p-24', className, panelStyles.panel)}>
        <DataBlockedMessage
          {...{
            className: 'p-24',
            message: 'Фермы пока не добавлены',
            buttonProps: addButtonProps,
          }}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={companyFieldArraysStyles.tableHeader}>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Номер
        </Typography>
        <Typography variant={TypographyVariants.descriptionLargeStrong}>
          Название
        </Typography>
      </div>
      {farmsArrayItems.map((farmFields, farmIndex) => (
        <div key={farmFields.id} className={companyFieldArraysStyles.tableRow}>
          <Input
            {...{
              name: `farms.${farmIndex}.number`,
              className: companyFieldArraysStyles.input,
              placeholder: '№',
            }}
          />
          <Input
            {...{
              name: `farms.${farmIndex}.name`,
              className: companyFieldArraysStyles.input,
            }}
          />
          <FunctionButton
            {...{
              className: clsx(
                'col-start-4',
                companyFieldArraysStyles.deleteButton
              ),
              iconVariant: IconVariants.delete,
              onPress: () => {
                removeFromFarmsArray(farmIndex);
                sendNeutralToast('Ферма удалена');
              },
            }}
          />
        </div>
      ))}
      <Button
        {...{
          ...addButtonProps,
          size: ButtonSizes.small24,
        }}
      />
    </div>
  );
};
