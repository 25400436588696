import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CustomReportBlueprintSourceFieldValueFragment = { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null };

export const CustomReportBlueprintSourceFieldValueFragmentDoc = gql`
    fragment CustomReportBlueprintSourceFieldValue on CustomReportBlueprintSourceFieldValue {
  __typename
  intValue
  floatValue
  strValue
  datetimeValue
}
    `;