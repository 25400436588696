import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUserEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteUserEventMutation = { __typename?: 'Mutation', deleteUserEvent?: any | null };


export const DeleteUserEventDocument = gql`
    mutation deleteUserEvent($id: ID!) {
  deleteUserEvent(id: $id)
}
    `;
export type DeleteUserEventMutationFn = Apollo.MutationFunction<DeleteUserEventMutation, DeleteUserEventMutationVariables>;

/**
 * __useDeleteUserEventMutation__
 *
 * To run a mutation, you first call `useDeleteUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserEventMutation, { data, loading, error }] = useDeleteUserEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserEventMutation, DeleteUserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserEventMutation, DeleteUserEventMutationVariables>(DeleteUserEventDocument, options);
      }
export type DeleteUserEventMutationHookResult = ReturnType<typeof useDeleteUserEventMutation>;
export type DeleteUserEventMutationResult = Apollo.MutationResult<DeleteUserEventMutation>;
export type DeleteUserEventMutationOptions = Apollo.BaseMutationOptions<DeleteUserEventMutation, DeleteUserEventMutationVariables>;