import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InseminationFragmentDoc } from '../fragments/insemination.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReproductionInseminationsQueryVariables = Types.Exact<{
  hashID: Types.Scalars['String']['input'];
}>;


export type ReproductionInseminationsQuery = { __typename?: 'Query', reproductionInseminations: Array<{ __typename: 'Insemination', id: string, result: Types.InseminationResult, daysInMilk: number, happenedAt: string, cow: { __typename: 'Cow', retiredAt?: string | null, id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null }, employee?: { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string, number: string } | null, scheme?: { __typename: 'InseminationScheme', id: string, name: string } | null }> };


export const ReproductionInseminationsDocument = gql`
    query reproductionInseminations($hashID: String!) {
  reproductionInseminations(hashID: $hashID) {
    ...Insemination
  }
}
    ${InseminationFragmentDoc}`;

/**
 * __useReproductionInseminationsQuery__
 *
 * To run a query within a React component, call `useReproductionInseminationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReproductionInseminationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReproductionInseminationsQuery({
 *   variables: {
 *      hashID: // value for 'hashID'
 *   },
 * });
 */
export function useReproductionInseminationsQuery(baseOptions: Apollo.QueryHookOptions<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables> & ({ variables: ReproductionInseminationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables>(ReproductionInseminationsDocument, options);
      }
export function useReproductionInseminationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables>(ReproductionInseminationsDocument, options);
        }
export function useReproductionInseminationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables>(ReproductionInseminationsDocument, options);
        }
export type ReproductionInseminationsQueryHookResult = ReturnType<typeof useReproductionInseminationsQuery>;
export type ReproductionInseminationsLazyQueryHookResult = ReturnType<typeof useReproductionInseminationsLazyQuery>;
export type ReproductionInseminationsSuspenseQueryHookResult = ReturnType<typeof useReproductionInseminationsSuspenseQuery>;
export type ReproductionInseminationsQueryResult = Apollo.QueryResult<ReproductionInseminationsQuery, ReproductionInseminationsQueryVariables>;