import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SomaticCellsReportFragmentDoc } from '../fragments/somaticCellsReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetSomaticCellsReportSettingsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.SetSomaticCellsReportSettingsInput;
}>;


export type SetSomaticCellsReportSettingsMutation = { __typename?: 'Mutation', setSomaticCellsReportSettings: { __typename: 'SomaticCellsReport', id: string, createdAt: string, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, settings: { __typename: 'SomaticCellsReportSettings', since?: string | null, till?: string | null, sccThousandsPerMl?: number | null, filters: Array<{ __typename: 'SomaticCellsReportSettingsHistoricFilter', kind?: Types.MilkingHistoricFilterKind | null, value?: { __typename: 'SomaticCellsReportSettingsHistoricValueLactationNumbers', lactationNumbers?: Array<number> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers', lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValuePenGroups', penGroups: Array<{ __typename: 'PenGroup', id: string, name: string, identifier: number }> } | null }>, blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string } | null }, calculatedReport: { __typename: 'SomaticCellsReportChartEmpty', dates: Array<string> } | { __typename: 'SomaticCellsReportChart', dates: Array<string>, researchedAnimalsCounts: Array<number | null>, chronicallyIllPercents: Array<number | null>, chronicallyIllCounts: Array<number | null>, illPercents: Array<number | null>, illCounts: Array<number | null>, recoveredPercents: Array<number | null>, recoveredCounts: Array<number | null>, healthyPercents: Array<number | null>, healthyCounts: Array<number | null>, highValueFirstTestMilkingPercents: Array<number | null>, highValueFirstTestMilkingCounts: Array<number | null>, normalValueFirstTestMilkingPercents: Array<number | null>, normalValueFirstTestMilkingCounts: Array<number | null>, probabilityOfRecoveryPercents: Array<number | null>, probabilityOfIllnessPercents: Array<number | null>, cacheCreatedAt: string } } };


export const SetSomaticCellsReportSettingsDocument = gql`
    mutation setSomaticCellsReportSettings($id: ID!, $input: SetSomaticCellsReportSettingsInput!) {
  setSomaticCellsReportSettings(id: $id, input: $input) {
    ...SomaticCellsReport
  }
}
    ${SomaticCellsReportFragmentDoc}`;
export type SetSomaticCellsReportSettingsMutationFn = Apollo.MutationFunction<SetSomaticCellsReportSettingsMutation, SetSomaticCellsReportSettingsMutationVariables>;

/**
 * __useSetSomaticCellsReportSettingsMutation__
 *
 * To run a mutation, you first call `useSetSomaticCellsReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSomaticCellsReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSomaticCellsReportSettingsMutation, { data, loading, error }] = useSetSomaticCellsReportSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSomaticCellsReportSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetSomaticCellsReportSettingsMutation, SetSomaticCellsReportSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSomaticCellsReportSettingsMutation, SetSomaticCellsReportSettingsMutationVariables>(SetSomaticCellsReportSettingsDocument, options);
      }
export type SetSomaticCellsReportSettingsMutationHookResult = ReturnType<typeof useSetSomaticCellsReportSettingsMutation>;
export type SetSomaticCellsReportSettingsMutationResult = Apollo.MutationResult<SetSomaticCellsReportSettingsMutation>;
export type SetSomaticCellsReportSettingsMutationOptions = Apollo.BaseMutationOptions<SetSomaticCellsReportSettingsMutation, SetSomaticCellsReportSettingsMutationVariables>;