import React from 'react';

import clsx from 'clsx';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { Icon, IconVariants } from '~/shared/components/Icon';
import {
  Skeleton,
  SkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatWithPercent } from '~/shared/helpers/number';
import { renderOrMdash } from '~/shared/helpers/render';

import { formatDate } from '~/services/dateTime';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { ReproductionMetricFragment } from '../../gql/fragments/reproductionMetric.graphql';
import { getMetricColorForegroundClassName } from '../../helpers';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Title of the metric
   */
  title: string;
  /**
   * Metric to render
   */
  metric: ReproductionMetricFragment | SkeletonPlaceholder;
  /**
   * If true, shows tooltip for last cycle calculation, calculatedOn otherwise
   */
  isLastCycleCalculation?: boolean;
}

const METRIC_CARD_HEIGHT_PX = 156;

export const ReproductionMetricCard: React.FC<Props> = ({
  className,
  title,
  metric: {
    targetPercent,
    valuePercent,
    deltaPercent,
    deviationThresholdPercent,
    calculatedOn,
  },
  isLastCycleCalculation = false,
}) => {
  const { renderWithSkeleton } = useSkeletonContext();

  const renderValue = (value: number | undefined | null) =>
    renderOrMdash(!R.isNil(value) && formatWithPercent(Math.abs(value)));

  const metricValueClassName = getMetricColorForegroundClassName({
    targetPercent,
    deviationThresholdPercent,
    valuePercent,
  });

  const metricDeltaSettings = match(deltaPercent)
    .with(
      P.number.gt(0),
      R.always({
        className: 'text-success',
        iconName: IconVariants.metricPositive,
      })
    )
    .with(
      P.number.lt(0),
      R.always({
        className: 'text-error',
        iconName: IconVariants.metricNegative,
      })
    )
    .otherwise(
      R.always({
        className: 'text-soft',
        iconName: IconVariants.metricUnchanged,
      })
    );

  const cardElement = (
    <div
      className={clsx(
        'grid gap-16 p-16 background-bg-surface-3 rounded-8',
        className
      )}
    >
      <div className="flex gap-12 items-center justify-between ellipsis">
        <Typography
          className="ellipsis"
          variant={TypographyVariants.bodyMediumStrong}
        >
          {title}
        </Typography>
        <Tooltip
          size={SizeVariants.size24}
          content={
            isLastCycleCalculation
              ? 'Указано значение показателя за последний цикл'
              : `Указано значение показателя за цикл ${formatDate(calculatedOn)}`
          }
        />
      </div>
      <div className="grid gap-12 grid-cols-2">
        <div className="flex flex-col">
          <Typography
            className="text-soft"
            variant={TypographyVariants.descriptionLarge}
          >
            Факт
          </Typography>
          <Typography
            className={metricValueClassName}
            variant={TypographyVariants.displaySmall}
          >
            {renderValue(valuePercent)}
          </Typography>

          <Typography
            {...{
              tag: 'div',
              variant: TypographyVariants.bodyMediumStrong,
              className: clsx(
                'flex items-center gap-4',
                metricDeltaSettings.className
              ),
            }}
          >
            <Icon variant={metricDeltaSettings.iconName} />
            {!R.isNil(deltaPercent) && renderValue(deltaPercent)}
          </Typography>
        </div>

        <div className="flex flex-col text-soft">
          <Typography variant={TypographyVariants.descriptionLarge}>
            Цель
          </Typography>
          <Typography variant={TypographyVariants.displaySmall}>
            {renderValue(targetPercent)}
          </Typography>
        </div>
      </div>
    </div>
  );

  return renderWithSkeleton(
    <Skeleton.Block
      {...{
        className,
        height: METRIC_CARD_HEIGHT_PX,
      }}
    />,
    cardElement
  );
};
