import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorDetailedFragmentDoc } from '../fragments/milkingParlorDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorsDetailedQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type MilkingParlorsDetailedQuery = { __typename?: 'Query', milkingParlors: { __typename?: 'MilkingParlorConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'MilkingParlorEdge', cursor: string, node: { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, farm: { __typename: 'Farm', id: string, name: string, number: string, milkingParlorSettings?: { __typename?: 'MilkingParlorFarmSetting', firstMilkingPerDayStartOn: string } | null }, intervals: Array<{ __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string, penGroups: { __typename?: 'MilkingParlorIntervalPenGroupsConnection', edges: Array<{ __typename?: 'MilkingParlorIntervalPenGroupEdge', milkingNumber?: number | null, node: { __typename: 'PenGroup', id: string, name: string, identifier: number } }> } }> } }> } };


export const MilkingParlorsDetailedDocument = gql`
    query milkingParlorsDetailed($first: Int, $after: String, $farmIDs: [ID!]) {
  milkingParlors(first: $first, after: $after, farmIDs: $farmIDs) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...MilkingParlorDetailed
      }
    }
  }
}
    ${MilkingParlorDetailedFragmentDoc}`;

/**
 * __useMilkingParlorsDetailedQuery__
 *
 * To run a query within a React component, call `useMilkingParlorsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorsDetailedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      farmIDs: // value for 'farmIDs'
 *   },
 * });
 */
export function useMilkingParlorsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>(MilkingParlorsDetailedDocument, options);
      }
export function useMilkingParlorsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>(MilkingParlorsDetailedDocument, options);
        }
export function useMilkingParlorsDetailedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>(MilkingParlorsDetailedDocument, options);
        }
export type MilkingParlorsDetailedQueryHookResult = ReturnType<typeof useMilkingParlorsDetailedQuery>;
export type MilkingParlorsDetailedLazyQueryHookResult = ReturnType<typeof useMilkingParlorsDetailedLazyQuery>;
export type MilkingParlorsDetailedSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorsDetailedSuspenseQuery>;
export type MilkingParlorsDetailedQueryResult = Apollo.QueryResult<MilkingParlorsDetailedQuery, MilkingParlorsDetailedQueryVariables>;