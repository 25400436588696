import React, { useId, useState } from 'react';

import clsx from 'clsx';
import R from 'ramda';
import { useRifm } from 'rifm';
import { useDebouncedCallback } from 'use-debounce';

import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Label } from '~/shared/components/Label';
import { SEARCH_DEBOUNCE_MS } from '~/shared/constants';
import { withDefaultPropsByVariant } from '~/shared/hocs/withDefaultPropsByVariant';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';

import {
  INPUT_DEFAULT_PROPS_BY_VARIANT_DICT,
  NUMBER_INPUT_VARIANTS,
} from './constants';
import styles from './index.module.scss';
import {
  FormInputValueTypes,
  InputAddon,
  InputProps,
  InputThemes,
  InputVariants,
} from './types';

const InputInner: React.FC<InputProps> = React.forwardRef<
  HTMLInputElement,
  InputProps
>((props, ref) => {
  const {
    className,

    name,
    variant = InputVariants.text,
    theme = InputThemes.dark,

    accept = /.*/,
    formatRawToDisplayedValue = R.identity,
    formatDisplayedToRawValue = R.identity,
    replace,

    value: valueProp,
    defaultValue: defaultValueProp,
    onValueChange,
    onValueChangeDebounced: onValueChangeDebouncedProp,

    placeholder,
    htmlInputProps,

    isDisabled,
    isRequired,
    hasError,

    addonBefore,
    addonAfter,
    withAutoComplete = false,

    onEnter,
    onKeyDown,
    onBlur,

    label,
    labelProps,
    feedback,
    feedbackProps,
    withRightFeedback = false,
  } = props;

  const valuePropString = valueProp === null ? '' : valueProp?.toString();
  const defaultValue = defaultValueProp?.toString() ?? '';

  const inputId = useId();

  // Additional state is needed to store some uncompleted values, like typing float number : 12.
  const [innerValue, setInnerValue] = useState(valuePropString ?? defaultValue);

  const onValueChangeDebounced = useDebouncedCallback(
    onValueChangeDebouncedProp ?? R.always(undefined),
    SEARCH_DEBOUNCE_MS
  );
  // Main state stores completed value, that can be safely returned in change event
  const [value, setValue] = useControllableState(
    valuePropString,
    newValue => {
      setInnerValue(newValue);
      // TS can't determine correct type for union function arguments, depend on variant,
      // but this conversion is safe, cause we have discriminating union types for formatting functions
      const rawValue = formatDisplayedToRawValue(
        newValue,
        props
      ) as any as never;

      onValueChange?.(rawValue);
      onValueChangeDebounced?.(rawValue);
    },
    defaultValue
  );

  const rifm = useRifm({
    accept,
    value:
      !NUMBER_INPUT_VARIANTS.includes(variant) || !innerValue
        ? value
        : innerValue,
    onChange: setValue,
    format: v => formatRawToDisplayedValue(v, props),
    replace,
  });

  const renderAddon = (addon: InputAddon) => {
    if (typeof addon === 'function') {
      return addon(props);
    }

    return addon;
  };

  const autoCompleteProps = withAutoComplete
    ? ({
        autoComplete: name,
      } as const)
    : ({
        autoComplete: 'off',
        'aria-autocomplete': 'none',
      } as const);

  return (
    <div
      className={clsx(styles.root, className, styles[theme], {
        [styles.disabled]: isDisabled,
        [styles.error]: hasError,
        [styles.withRightFeedback]: withRightFeedback,
      })}
    >
      {!!label && (
        <Label
          {...{
            htmlFor: inputId,
            isRequired,
            ...labelProps,
          }}
        >
          {label}
        </Label>
      )}
      <div className={styles.inputContainer}>
        {!!addonBefore && (
          <div className="text-muted mr-8 grid">{renderAddon(addonBefore)}</div>
        )}
        <input
          {...{
            ref,
            id: inputId,
            name,
            onBlur,
            value: rifm.value,
            onChange: rifm.onChange,
            className: styles.input,
            disabled: isDisabled,
            placeholder,
            onKeyDown: e => {
              onKeyDown?.(e);
              if (e.key === 'Enter') {
                onEnter?.(
                  formatDisplayedToRawValue(value, props) as any as never,
                  e
                );
              }
            },
            ...autoCompleteProps,
            ...htmlInputProps,
          }}
        />
        {!!addonAfter && (
          <div className={clsx('text-muted ml-8 grid', styles.addonAfter)}>
            {renderAddon(addonAfter)}
          </div>
        )}
      </div>
      {!!feedback && <FieldFeedback content={feedback} {...feedbackProps} />}
    </div>
  );
});

export const Input = withOptionalFormController<
  InputProps,
  any,
  FormInputValueTypes
>({
  defaultValue: '',
  convertValueFromComponentToForm: (
    value,
    { variant = InputVariants.text }
  ) => {
    if (NUMBER_INPUT_VARIANTS.includes(variant)) {
      return R.isNil(value) || value === '' ? null : +value;
    }
    return value;
  },
  convertValueFromFormToComponent: value => value?.toString() ?? '',
})(
  withDefaultPropsByVariant<InputVariants, InputProps>(
    INPUT_DEFAULT_PROPS_BY_VARIANT_DICT,
    InputVariants.text
  )(InputInner)
);

export * from './types';
