import React from 'react';

import { BlueprintReadOnlyArgument } from '../../../../services';
import { NodeFrame } from '../../../NodeFrame';

interface Props {
  argument: BlueprintReadOnlyArgument;
}

export const ReadOnlyArgumentNode: React.FC<Props> = props => {
  const { argument } = props;
  return (
    <NodeFrame
      header={<></>}
      headerStyle={{
        background:
          'linear-gradient(rgb(248, 199, 65) 0%, rgb(248, 229, 65) 100%)',
      }}
      contentClass="p-4"
    >
      {argument.name}
    </NodeFrame>
  );
};
