import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CalendarDividerFragmentDoc } from '../fragments/calendarDivider.graphql';
import { CalendarEntryFragmentDoc } from '../fragments/calendarEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarEntriesQueryVariables = Types.Exact<{
  since?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  till?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  roleIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  viewKind?: Types.InputMaybe<Types.ViewKindEnum>;
}>;


export type CalendarEntriesQuery = { __typename?: 'Query', calendarEntries: { __typename?: 'CalendarRowTypeConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CalendarRowTypeEdge', cursor: string, node: { __typename: 'CalendarDivider', date: string } | { __typename: 'CalendarEntry', date: string, blueprint: { __typename: 'Blueprint', id: string, name: string, description: string, roles: Array<{ __typename?: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }>, viewSettings: { __typename?: 'BlueprintViewSettings', kind?: Types.ViewKindEnum | null } } } }> } };


export const CalendarEntriesDocument = gql`
    query calendarEntries($since: Date, $till: Date, $after: String, $first: Int, $roleIDs: [ID!], $search: String, $viewKind: ViewKindEnum) {
  calendarEntries(
    since: $since
    till: $till
    after: $after
    first: $first
    roleIDs: $roleIDs
    search: $search
    viewKind: $viewKind
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        __typename
        ...CalendarDivider
        ...CalendarEntry
      }
      cursor
    }
  }
}
    ${CalendarDividerFragmentDoc}
${CalendarEntryFragmentDoc}`;

/**
 * __useCalendarEntriesQuery__
 *
 * To run a query within a React component, call `useCalendarEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEntriesQuery({
 *   variables: {
 *      since: // value for 'since'
 *      till: // value for 'till'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      roleIDs: // value for 'roleIDs'
 *      search: // value for 'search'
 *      viewKind: // value for 'viewKind'
 *   },
 * });
 */
export function useCalendarEntriesQuery(baseOptions?: Apollo.QueryHookOptions<CalendarEntriesQuery, CalendarEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarEntriesQuery, CalendarEntriesQueryVariables>(CalendarEntriesDocument, options);
      }
export function useCalendarEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarEntriesQuery, CalendarEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarEntriesQuery, CalendarEntriesQueryVariables>(CalendarEntriesDocument, options);
        }
export function useCalendarEntriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarEntriesQuery, CalendarEntriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarEntriesQuery, CalendarEntriesQueryVariables>(CalendarEntriesDocument, options);
        }
export type CalendarEntriesQueryHookResult = ReturnType<typeof useCalendarEntriesQuery>;
export type CalendarEntriesLazyQueryHookResult = ReturnType<typeof useCalendarEntriesLazyQuery>;
export type CalendarEntriesSuspenseQueryHookResult = ReturnType<typeof useCalendarEntriesSuspenseQuery>;
export type CalendarEntriesQueryResult = Apollo.QueryResult<CalendarEntriesQuery, CalendarEntriesQueryVariables>;