import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { CalvingFragmentDoc } from '../../gql/fragments/calving.graphql';
import { useCalvingsQuery } from '../../gql/queries/calvings.graphql';
import { formatCalving } from '../../helpers';

/**
 * Select for a calving
 */
export const useCalvingSelect = makeUseAsyncSelect({
  typeName: 'Calving',
  fragment: CalvingFragmentDoc,
  useQuery: useCalvingsQuery,
  getItemsFromQueryData: data => data.calvings.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.calvings.pageInfo,

  selectProps: {
    placeholder: 'Введите отёл',
    getItemValue: item => item.id,
    getItemText: item => formatCalving(item, { withRestInfo: false }),
    getItemDescription: item => formatCalving(item, { withId: false }),
  },
});

export const CalvingAsyncSelect =
  makeAsyncSelectFormComponent(useCalvingSelect);
