import React, { ReactNode } from 'react';

import { NodeHeader } from '../NodeHeader';
import styles from './index.module.scss';

interface Props {
  children: ReactNode;
  delete?: () => void;
}

export const FilterNodeFrame: React.FC<Props> = props => {
  const { children, delete: delFunc } = props;
  const header = delFunc ? (
    <NodeHeader title="&nbsp;" delete={delFunc} />
  ) : null;
  return (
    <div className={styles.root}>
      {header}
      {children}
    </div>
  );
};
