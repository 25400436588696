import React from 'react';

import clsx from 'clsx';

import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';

import { formatSourceFieldValue } from '~/entities/customReports';

import { CustomReportBlueprintLaunchResultFragment } from '../../gql/fragments/customReportBlueprintLaunchResult.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Launch result to display report table
   */
  blueprintLaunchResult:
    | CustomReportBlueprintLaunchResultFragment
    | SkeletonPlaceholder;
}

const SKELETON_COLUMNS_COUNT = 12;
const SKELETON_ROWS_COUNT = 8;

type CustomReportLaunchResultRow =
  CustomReportBlueprintLaunchResultFragment['rows'][number];

type TableRow = CustomReportLaunchResultRow | SkeletonPlaceholder;

export const BlueprintLaunchResultTable: React.FC<Props> = ({
  className,
  blueprintLaunchResult,
}) => {
  const items =
    blueprintLaunchResult.rows ?? getSkeletonPlaceholders(SKELETON_ROWS_COUNT);
  const columns =
    blueprintLaunchResult.columnSourceFields ??
    getSkeletonPlaceholders(SKELETON_COLUMNS_COUNT);

  const columnConfigs: TableColumnConfig<TableRow>[] = columns.map(
    (sourceField, columnIndex) => ({
      title: sourceField.name,
      key: sourceField.id,
      renderCellContent: row =>
        isSkeletonPlaceholder(row)
          ? ''
          : formatSourceFieldValue(row.values[columnIndex]),
      columnClassName: columnIndex === 0 ? undefined : 'text-right',
      cellTypographyProps: () => ({
        skeletonProps: {
          size:
            columnIndex === 0
              ? TextSkeletonSizes.medium
              : TextSkeletonSizes.small,
        },
      }),
    })
  );

  return (
    <Table<TableRow>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items,
        getItemKey: (row, index) => index,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
