import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportTestMilkingUploadNotFoundCowsMutationVariables = Types.Exact<{
  testMilkingUploadID: Types.Scalars['ID']['input'];
}>;


export type ExportTestMilkingUploadNotFoundCowsMutation = { __typename?: 'Mutation', exportTestMilkingUploadNotFoundCows: { __typename?: 'File', downloadUrl: string, name: string, extension: string } };


export const ExportTestMilkingUploadNotFoundCowsDocument = gql`
    mutation exportTestMilkingUploadNotFoundCows($testMilkingUploadID: ID!) {
  exportTestMilkingUploadNotFoundCows(testMilkingUploadID: $testMilkingUploadID) {
    downloadUrl
    name
    extension
  }
}
    `;
export type ExportTestMilkingUploadNotFoundCowsMutationFn = Apollo.MutationFunction<ExportTestMilkingUploadNotFoundCowsMutation, ExportTestMilkingUploadNotFoundCowsMutationVariables>;

/**
 * __useExportTestMilkingUploadNotFoundCowsMutation__
 *
 * To run a mutation, you first call `useExportTestMilkingUploadNotFoundCowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTestMilkingUploadNotFoundCowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTestMilkingUploadNotFoundCowsMutation, { data, loading, error }] = useExportTestMilkingUploadNotFoundCowsMutation({
 *   variables: {
 *      testMilkingUploadID: // value for 'testMilkingUploadID'
 *   },
 * });
 */
export function useExportTestMilkingUploadNotFoundCowsMutation(baseOptions?: Apollo.MutationHookOptions<ExportTestMilkingUploadNotFoundCowsMutation, ExportTestMilkingUploadNotFoundCowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportTestMilkingUploadNotFoundCowsMutation, ExportTestMilkingUploadNotFoundCowsMutationVariables>(ExportTestMilkingUploadNotFoundCowsDocument, options);
      }
export type ExportTestMilkingUploadNotFoundCowsMutationHookResult = ReturnType<typeof useExportTestMilkingUploadNotFoundCowsMutation>;
export type ExportTestMilkingUploadNotFoundCowsMutationResult = Apollo.MutationResult<ExportTestMilkingUploadNotFoundCowsMutation>;
export type ExportTestMilkingUploadNotFoundCowsMutationOptions = Apollo.BaseMutationOptions<ExportTestMilkingUploadNotFoundCowsMutation, ExportTestMilkingUploadNotFoundCowsMutationVariables>;