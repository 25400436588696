import React from 'react';

import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';
import { useControls } from '~/~legacy/value-editors/useControls';

export const VoidEdit: React.FC<ValueEditorStandardProps<void>> = props => {
  const { hideControls, toggle } = props;

  const controls = useControls(Boolean(hideControls), toggle);

  return (
    <>
      <span />
      {controls}
    </>
  );
};
