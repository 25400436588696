import {
  defaultEditGetter,
  editKindsMap as kindsMap,
} from '~/~legacy/helpers/inputToValueMap';
import { SpecialConstants } from '~/~legacy/types/specialConstants';

import { NodeValueKindEnum } from '~/shared/graphql';

import { ValueEditorKind } from '../services';

export function getValueToEdit(
  value: any,
  inputValue: any,
  kind: ValueEditorKind | undefined,
  valueKind: NodeValueKindEnum | null | undefined,
  currentValueKind: NodeValueKindEnum | null | undefined
) {
  const useValueItself =
    typeof inputValue !== 'object' && typeof inputValue !== 'boolean';

  if (valueKind !== currentValueKind) {
    return undefined;
  }

  if (inputValue !== undefined && useValueItself) {
    return inputValue;
  }

  if (currentValueKind === NodeValueKindEnum.Variable) {
    return value?.id;
  }

  const selectionKind = kind || SpecialConstants.Unknown;
  const getter = kindsMap[selectionKind] || defaultEditGetter;
  return getter(value);
}
