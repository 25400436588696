import React from 'react';

export const Block = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25C17.4745 2.25 21.75 6.56092 21.75 12C21.75 17.4142 17.4142 21.75 12 21.75C6.58899 21.75 2.25 17.411 2.25 12C2.25 6.58579 6.58579 2.25 12 2.25ZM3.75 12C3.75 7.41421 7.41421 3.75 12 3.75C14.0204 3.75 15.862 4.46128 17.2901 5.64928L5.64928 17.2901C4.46128 15.862 3.75 14.0204 3.75 12ZM6.70994 18.3507L18.3507 6.70994C19.5387 8.13803 20.25 9.97957 20.25 12C20.25 16.5858 16.5858 20.25 12 20.25C9.97957 20.25 8.13803 19.5387 6.70994 18.3507Z"
      fill="currentColor"
    />
  </svg>
);
