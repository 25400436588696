import React from 'react';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * File object to display
   */
  file: File;
  /**
   * Called, when the delete file icon is pressed
   */
  onDelete: () => void;
}

export const FileItem: React.FC<Props> = ({ className, file, onDelete }) => {
  return (
    <div className={clsx(styles.root, className)}>
      {file.name}
      <Icon
        {...{
          className: 'text-muted',
          variant: IconVariants.delete,
          onPress: onDelete,
        }}
      />
    </div>
  );
};
