import { BehaviorSubject } from 'rxjs';

import {
  ActionKindEnum,
  ActionType,
  CompareOperator,
  CreateSourceFieldInput,
  LogicOperator,
  NodeValueKindEnum,
  SourceFieldKindEnum,
  SourceFieldType,
  SourceSectionKindEnum,
  ValueKindEnum,
  ViewGraphKindEnum,
  ViewKindEnum,
  VitalityFilter,
} from '~/shared/graphql';

export enum BlueprintNodeType {
  Field = 'Field',
  Section = 'Section',
  Input = 'Input',
  CycleInputs = 'CycleInputs',
  Action = 'Action',
  FilterGroup = 'FilterGroup',
  FilterCondition = 'FilterCondition',
  Union = 'Union',

  None = '',
}

export type ValueEditorKind =
  | ValueKindEnum
  | NodeValueKindEnum.Variable
  | 'Unknown';

// here will be union of all input types like existing gql BlueprintLaunchInput
export type ValueInput = any;

interface BaseArgument {
  type?: BlueprintArgumentType;
  parentNodeType?: BlueprintNodeType;
  name: string;
}

export interface BlueprintSectionTypeItem {
  kind: SourceSectionKindEnum;
  verboseName: string;
  fields: SourceFieldType[];
}

export interface BlueprintWritableArgument extends BaseArgument {
  id: string;
  kind: ValueKindEnum;
  isRequired: boolean;
  valueKind?: NodeValueKindEnum | null;
  value: any;
  inputValue?: any;
  nodeId: string;
}

export type BlueprintReadOnlyArgument = BaseArgument;

export enum BlueprintArgumentType {
  WritableArgument = 'WritableArgument',
  ReadOnlyArgument = 'ReadOnlyArgument',
}

export type BlueprintArgument =
  | BlueprintWritableArgument
  | BlueprintReadOnlyArgument;

export interface BlueprintSourceSectionField {
  id: string;
  kind: SourceFieldKindEnum;
  verboseName: string;
  returnValueKind: ValueKindEnum;
  name: string;
  arguments: BlueprintWritableArgument[];
  sourceNodeId?: string;
}

export interface BlueprintSourceSection {
  id: string;
  kind: SourceSectionKindEnum;
  verboseName: string;
  fields: BlueprintSourceSectionField[];
}

export interface BlueprintInputNode {
  id: string;
  name: string;
  prompt: string;

  cycleInputId?: string;
}

export interface BlueprintActionNode {
  id?: string;
  kind?: ActionKindEnum;
  filterGroupId?: string;

  arguments: BlueprintArgument[];
}

export interface BlueprintUnionNode {
  id: string;
  name: string;
}

export interface BlueprintFilterConditionNode {
  id?: string;
  compareOperator: CompareOperator;
  field?: BlueprintSourceSectionField;
  value?: any;
  valueKind?: NodeValueKindEnum;
  userInputValue?: any;
  filterGroupId: string;
}

export interface BlueprintFilterGroupNode {
  id?: string;
  logicalOperator: LogicOperator;
  parentFilterGroupId?: string | undefined;
  filters: BlueprintFilterConditionNode[];
  actionIds: string[];
  unionId?: string;
}

export interface AppBlueprintViewTableSettings {
  fieldsIds: string[];
  groupByFieldsIds: string[];
}

interface AppBlueprintViewGraphSettings {
  kind: ViewGraphKindEnum;
}

interface AppBlueprintViewKindSettings {
  tableSettings: AppBlueprintViewTableSettings;
  graphSettings: AppBlueprintViewGraphSettings;
}

export interface BlueprintGeneralSetings {
  vitalityFilter: VitalityFilter;
  orderedInputIDs: string[];
}

export interface BlueprintViewSetings {
  kind: ViewKindEnum;
  kindSettings: AppBlueprintViewKindSettings;
}

export interface BlueprintCycleInputNode {
  id: string;
}

export interface BluperintUnderEdit {
  id: string;
  name: string;
  isMaster: boolean;

  generalSettings: BlueprintGeneralSetings;
  viewSettings: BlueprintViewSetings;

  masterSourceNodes$: BehaviorSubject<BlueprintSourceSection[]>;
  sourceNodes$: BehaviorSubject<BlueprintSourceSection[]>;
  inputNodes$: BehaviorSubject<BlueprintInputNode[]>;
  cycleInputNodes$: BehaviorSubject<BlueprintCycleInputNode[]>;
  actionNodes$: BehaviorSubject<BlueprintActionNode[]>;
  filterNodes$: BehaviorSubject<BlueprintFilterGroupNode[]>;
  unionNodes$: BehaviorSubject<BlueprintUnionNode[]>;
}

export interface DeleteNodeParams {
  nodeId: string;
  nodeType: BlueprintNodeType;
}

export interface BlueprintEditService {
  readonly blueprint: BluperintUnderEdit | null;

  readonly sourceSectionsDefinition: BlueprintSectionTypeItem[];
  readonly actionsDefinition: ActionType[];

  getMasterBlueprintId(): Promise<string | undefined>;
  initEditing(blueprintId: string): Promise<BluperintUnderEdit>;

  deleteNode(params: DeleteNodeParams): Promise<void>;

  getAvailableSections(): BlueprintSectionTypeItem[];
  createSourceSection(section: SourceSectionKindEnum): Promise<void>;

  getFieldsForSection(
    section: SourceSectionKindEnum
  ): Promise<SourceFieldType[]>;

  createSourceField(
    input: CreateSourceFieldInput
  ): Promise<BlueprintSourceSectionField>;
  updateSourceField(input: BlueprintSourceSectionField): Promise<void>;

  setArgument(argument: BlueprintWritableArgument): Promise<void>;
  unsetArgument(argument: BlueprintWritableArgument): Promise<void>;

  createInputNode(input: BlueprintInputNode): Promise<BlueprintInputNode>;
  updateInputNode(input: BlueprintInputNode): Promise<BlueprintInputNode>;

  getAvailableActions(): ActionType[];
  createAction(input: BlueprintActionNode): Promise<BlueprintActionNode>;
  // updateAction(input: BlueprintActionNode): Promise<BlueprintActionNode>;

  createFilterGroup(
    input: BlueprintFilterGroupNode
  ): Promise<BlueprintFilterGroupNode>;
  updateFilterGroup(
    input: BlueprintFilterGroupNode
  ): Promise<BlueprintFilterGroupNode>;

  createFilterCondition(
    input: BlueprintFilterConditionNode
  ): Promise<BlueprintFilterConditionNode>;
  updateFilterCondition(
    input: BlueprintFilterConditionNode
  ): Promise<BlueprintFilterConditionNode>;

  createCycleInput(): Promise<BlueprintCycleInputNode>;

  updateViewSettings(
    input: BlueprintViewSetings
  ): Promise<BlueprintViewSetings>;

  createUnion(): Promise<void>;
  updateUnion(input: BlueprintUnionNode): Promise<void>;
}
