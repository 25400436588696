/**
 * Possible milk fraction names from test milkings
 * on backend they are just fields of a test milking
 */
export enum TestMilkingFields {
  weight = 'weight',
  fat = 'fat',
  protein = 'protein',
  somaticCells = 'somaticCells',
}
