import { ReactElement, ReactNode, useEffect } from 'react';

import { useLayoutContext } from '../context';

interface UseLayoutStateProps {
  /**
   * Content to render on the right part of the header.
   */
  headerRightContent?: ReactNode;
  /**
   * Content to render on the right part of the router tab header.
   */
  routerTabsRightContent?: ReactElement | null;
  /**
   * Used to trigger layout state rerender
   */
  dependencies?: unknown[];
}

/**
 * Hook for controlling the upper level page layout from the nested pages
 */
export const useLayoutState = ({
  headerRightContent,
  routerTabsRightContent,
  dependencies = [],
}: UseLayoutStateProps) => {
  const {
    headerRightContent: headerRightContentContext,
    setHeaderRightContent,
    routerTabsRightContent: routerTabsRightContentContext,
    setRouterTabsRightContent,
  } = useLayoutContext();

  useEffect(() => {
    if (headerRightContent) {
      setHeaderRightContent(headerRightContent);
    } else {
      // TODO this is a hack for LayoutStateReset, remove after refactor
      setTimeout(() => {
        setHeaderRightContent(headerRightContent);
      }, 0);
    }

    if (routerTabsRightContent) {
      setRouterTabsRightContent(routerTabsRightContent);
    } else {
      // TODO this is a hack for LayoutStateReset, remove after refactor
      setTimeout(() => {
        setRouterTabsRightContent(routerTabsRightContent);
      }, 0);
    }

    return () => {
      // Return to initial mount value after unmount
      setHeaderRightContent(headerRightContentContext);
      setRouterTabsRightContent(routerTabsRightContentContext);
    };
  }, [
    !routerTabsRightContent && !!routerTabsRightContentContext,
    ...dependencies,
  ]);
};

/**
 * Hacky component to allow to conditionally reset layout state,
 * such as in case, when we have an AsyncList with no items, and
 * we need to render the button at the center of the page, instead of in the layout.
 *
 * TODO rework AsyncList component, to decouple it from filters, when we create actual Filters component
 */
export const LayoutStateReset = () => {
  useLayoutState({
    headerRightContent: undefined,
    routerTabsRightContent: undefined,
  });

  return null;
};
