import React from 'react';

import clsx from 'clsx';

import { DiscriminatedUnionProps } from '~/shared/types/utility';

import styles from './index.module.scss';

interface BaseProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, full rounding is used
   */
  isRound?: boolean;
}

interface SizeProps {
  /**
   * Size defined equal width and height for the skeleton
   */
  size: number | string;
}

/**
 * Props for defining height and width of the skeleton explicitly
 */
export interface SkeletonDimensionsProps {
  /**
   * Height css value for the skeleton
   */
  height: number | string;
  /**
   * Width css value for the skeleton
   */
  width?: number | string;
  /**
   * Custom border radius of the skeleton block
   */
  borderRadius?: string;
}

type Props = BaseProps &
  DiscriminatedUnionProps<SizeProps, SkeletonDimensionsProps>;

export const SkeletonBlock: React.FC<Props> = ({
  className,
  size,
  height = size,
  width = size ?? '100%',
  borderRadius,
  isRound = false,
}) => {
  return (
    <div {...{ className, style: { width } }}>
      <div
        {...{
          className: clsx(
            'full-width',
            styles.inner,
            isRound && 'rounded-full'
          ),
          style: {
            height,
            borderRadius,
          },
        }}
      />
    </div>
  );
};
