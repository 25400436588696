import {
  FemaleAnimalKind,
  ReproductionHdrAndPrXAxisMode,
} from '@graphql-types';
import R from 'ramda';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import { DATE_PERIOD_FORM_SCHEMA } from '~/services/dateTime';
import { omitTypename } from '~/services/gql';

import { ReproductionHdrAndPrReportSettingsFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrReportSettings.graphql';

import { REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA } from '../constants';
import {
  ReproductionHdrAndPrReportDataType,
  ReproductionHdrAndPrReportSettingsFormType,
} from '../types';

/**
 * Checks, if calculated HDR and PR report is empty
 */
export const isReproductionHdrAndPrReportDataEmpty = (
  reportData:
    | ReproductionHdrAndPrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) => reportData?.__typename === 'ReproductionHdrAndPrCalculatedReportEmpty';

/**
 * Checks, if calculated HDR and PR report is simple report data
 */
export const isReproductionHdrAndPrCalculatedReportData = (
  reportData:
    | ReproductionHdrAndPrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) => reportData?.__typename === 'ReproductionHdrAndPrCalculatedReport';

/**
 * Checks, if calculated HDR and PR report contains period comparison
 */
export const isReproductionHdrAndPrComparisonReportData = (
  reportData:
    | ReproductionHdrAndPrReportDataType
    | null
    | undefined
    | SkeletonPlaceholder
) =>
  reportData?.__typename === 'ReproductionHdrAndPrCalculatedReportComparison';

/**
 * Maps reproduction HDR and PR report settings fragment into settings form fields representation
 */
export const mapReproductionHdrAndPrReportSettingsToForm = (
  reportSettings:
    | ReproductionHdrAndPrReportSettingsFragment
    | SkeletonPlaceholder
): ReproductionHdrAndPrReportSettingsFormType => ({
  ...REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA.getDefault(),
  ...(R.pick(
    [
      'femaleAnimalKind',
      'xAxisMode',
      'yAxisMode',
      'yAxisMetrics',
      'yAxisShouldCompareToPreviousPeriod',
      'yAxisCompareToPreviousPeriodMode',
      'yAxisCompareToPreviousPeriodChartKind',
      'lactationGroupNumbers',
      'voluntaryWaitingPeriodDays',
    ],
    reportSettings ?? {}
  ) as ReproductionHdrAndPrReportSettingsFormType),
  period: reportSettings.period
    ? omitTypename(reportSettings.period)
    : DATE_PERIOD_FORM_SCHEMA.getDefault(),
  farmID: reportSettings.farm?.id ?? null,
  cowIDs: reportSettings.cows?.map(R.prop('id')) ?? null,
});

/**
 * Getter for x axis or x axis mode select items display name
 */
export const formatXAxisModeName = (
  xAxisMode: ReproductionHdrAndPrXAxisMode,
  femaleAnimalKind: FemaleAnimalKind
) => {
  if (xAxisMode === ReproductionHdrAndPrXAxisMode.Date) {
    return 'Дата';
  }
  return femaleAnimalKind === FemaleAnimalKind.Cow
    ? 'День в доении'
    : 'Возраст в днях';
};
