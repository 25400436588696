import React from 'react';

import { Link, LinkProps, useRouter } from '@tanstack/react-router';
import clsx from 'clsx';

import { ReactComponent as BrandSvg } from './brand-image.svg';

type Props = LinkProps &
  React.PropsWithoutRef<
    Omit<React.HTMLProps<HTMLAnchorElement>, 'children' | 'preload'>
  >;

export const Brand: React.FC<Partial<Props>> = ({ className, ...props }) => {
  const { basepath } = useRouter();

  return (
    <Link
      {...{
        to: basepath as '.',
        className: clsx('flex items-center', className),
        ...props,
      }}
    >
      <BrandSvg />
    </Link>
  );
};
