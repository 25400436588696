import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionHdrAndPrMainRowsFragmentDoc } from '../fragments/reproductionHdrAndPrMainRows.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReproductionMainChartQueryVariables = Types.Exact<{
  farmID: Types.Scalars['ID']['input'];
  since: Types.Scalars['Date']['input'];
  till: Types.Scalars['Date']['input'];
  femaleAnimalKind: Types.FemaleAnimalKind;
}>;


export type ReproductionMainChartQuery = { __typename?: 'Query', reproductionMainChart: { __typename: 'ReproductionHdrAndPrMainRows', hdrMetric: { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string }, prMetric: { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string }, crMetric: { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string }, rows: Array<{ __typename: 'ReproductionHdrAndPrRowByDate', date: string, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null }> } };


export const ReproductionMainChartDocument = gql`
    query reproductionMainChart($farmID: ID!, $since: Date!, $till: Date!, $femaleAnimalKind: FemaleAnimalKind!) {
  reproductionMainChart(
    farmID: $farmID
    since: $since
    till: $till
    femaleAnimalKind: $femaleAnimalKind
  ) {
    ...ReproductionHdrAndPrMainRows
  }
}
    ${ReproductionHdrAndPrMainRowsFragmentDoc}`;

/**
 * __useReproductionMainChartQuery__
 *
 * To run a query within a React component, call `useReproductionMainChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useReproductionMainChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReproductionMainChartQuery({
 *   variables: {
 *      farmID: // value for 'farmID'
 *      since: // value for 'since'
 *      till: // value for 'till'
 *      femaleAnimalKind: // value for 'femaleAnimalKind'
 *   },
 * });
 */
export function useReproductionMainChartQuery(baseOptions: Apollo.QueryHookOptions<ReproductionMainChartQuery, ReproductionMainChartQueryVariables> & ({ variables: ReproductionMainChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReproductionMainChartQuery, ReproductionMainChartQueryVariables>(ReproductionMainChartDocument, options);
      }
export function useReproductionMainChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReproductionMainChartQuery, ReproductionMainChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReproductionMainChartQuery, ReproductionMainChartQueryVariables>(ReproductionMainChartDocument, options);
        }
export function useReproductionMainChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReproductionMainChartQuery, ReproductionMainChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReproductionMainChartQuery, ReproductionMainChartQueryVariables>(ReproductionMainChartDocument, options);
        }
export type ReproductionMainChartQueryHookResult = ReturnType<typeof useReproductionMainChartQuery>;
export type ReproductionMainChartLazyQueryHookResult = ReturnType<typeof useReproductionMainChartLazyQuery>;
export type ReproductionMainChartSuspenseQueryHookResult = ReturnType<typeof useReproductionMainChartSuspenseQuery>;
export type ReproductionMainChartQueryResult = Apollo.QueryResult<ReproductionMainChartQuery, ReproductionMainChartQueryVariables>;