import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
import { BullFragmentDoc } from '../../../bulls/gql/fragments/bull.graphql';
export type InseminationShortFragment = { __typename: 'Insemination', id: string, happenedAt: string, movedToGroupWithBullAt?: string | null, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export const InseminationShortFragmentDoc = gql`
    fragment InseminationShort on Insemination {
  __typename
  id
  happenedAt
  movedToGroupWithBullAt
  cow {
    ...CowShort
  }
  bull {
    ...Bull
  }
}
    ${CowShortFragmentDoc}
${BullFragmentDoc}`;