import React, { ChangeEvent, useMemo } from 'react';

import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Label } from '~/shared/components/Label';
import { mergeRefs } from '~/shared/helpers/mergeProps';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import styles from './MInput.module.scss';

import './MInput.scss';

type InputValueTypes = string | number | null | undefined;

export interface MInputProps extends BaseFieldProps<InputValueTypes> {
  /**
   * Hack for blueprint cycle inputs focus to work
   * TODO remove after complete rework of blueprint value inputs
   */
  inputRef?: React.Ref<HTMLInputElement>;
  autocomplete?: string;
  id?: string;
  fullWidth?: boolean;
  type?: 'text' | 'password' | 'number' | 'date' | 'time' | 'email';

  step?: string;
  pattern?: string;
  noBorder?: boolean;
  /**
   * If true, autocomplete is used on the input, false by default,
   * cause field names may confuse browser for wrong autocompletions
   */
  withAutoComplete?: boolean;

  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const MInput = withOptionalFormController<MInputProps, InputValueTypes>({
  defaultValue: ({ type }) => (type === 'number' ? null : ''),
  convertValueFromComponentToForm: (value, { type }) => {
    if (type === 'number') {
      return R.isNil(value) || value === '' ? null : +value;
    }
    return value ?? '';
  },
  convertValueFromFormToComponent: (value, { type }) => {
    if (type === 'date') {
      return dayjs(value).format('YYYY-MM-DD');
    }
    return value;
  },
})(
  React.memo(
    React.forwardRef<any, MInputProps>((props, ref) => {
      const {
        inputRef,
        autocomplete,
        isDisabled,
        isRequired,
        fullWidth = true,
        placeholder,
        id,
        name,
        type = 'text',
        withAutoComplete = false,
        className,
        value: valueProp,
        defaultValue,
        label,
        labelProps,
        feedback,
        feedbackProps,
        hasError,
        step,
        pattern,
        noBorder,
        onChange,
        onValueChange,
        onKeyDown,
        onFocus,
        onBlur,
        onPaste,
      } = props;

      const htmlId = useMemo(() => Math.random().toFixed(6) || id, [id]);

      const convertValue = (val: InputValueTypes) =>
        R.isNil(val) ? '' : val.toString();

      const [stateValue, setStateValue] = useControllableState<InputValueTypes>(
        valueProp,
        newValue => onValueChange?.(convertValue(newValue)),
        defaultValue
      );
      const value = convertValue(stateValue);

      // const showPickerCallback = useCallback((e: any) => {
      //   e.target.showPicker();
      // }, []);

      const autoCompleteProps = withAutoComplete
        ? ({
            autoComplete: autocomplete,
          } as const)
        : ({
            autoComplete: 'off',
            'aria-autocomplete': 'none',
          } as const);

      return (
        <div className={className}>
          {!!label && (
            <Label
              {...{
                className: 'mb-4',
                htmlFor: htmlId,
                isRequired,
                ...labelProps,
              }}
            >
              {label}
            </Label>
          )}
          <input
            ref={mergeRefs(ref, inputRef)}
            name={name}
            id={htmlId}
            type={type}
            placeholder={placeholder}
            className={clsx('m-control', styles.input, {
              [styles.full]: fullWidth,
              [styles.error]: hasError,
              [styles.noBorder]: noBorder,
            })}
            value={value}
            onChange={e => {
              onChange?.(e);
              setStateValue(e.target.value);
            }}
            onKeyDown={onKeyDown}
            // onClick={showPickerCallback}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={isDisabled}
            step={step}
            pattern={pattern}
            onPaste={onPaste}
            // CWRK-682
            max={type === 'date' ? '9999-12-31' : undefined}
            {...autoCompleteProps}
          />
          {!!feedback && (
            <FieldFeedback
              className="mt-4"
              content={feedback}
              {...feedbackProps}
            />
          )}
        </div>
      );
    })
  )
);
