import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMilkingParlorIntervalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteMilkingParlorIntervalMutation = { __typename?: 'Mutation', deleteMilkingParlorInterval?: any | null };


export const DeleteMilkingParlorIntervalDocument = gql`
    mutation deleteMilkingParlorInterval($id: ID!) {
  deleteMilkingParlorInterval(id: $id)
}
    `;
export type DeleteMilkingParlorIntervalMutationFn = Apollo.MutationFunction<DeleteMilkingParlorIntervalMutation, DeleteMilkingParlorIntervalMutationVariables>;

/**
 * __useDeleteMilkingParlorIntervalMutation__
 *
 * To run a mutation, you first call `useDeleteMilkingParlorIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMilkingParlorIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMilkingParlorIntervalMutation, { data, loading, error }] = useDeleteMilkingParlorIntervalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMilkingParlorIntervalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMilkingParlorIntervalMutation, DeleteMilkingParlorIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMilkingParlorIntervalMutation, DeleteMilkingParlorIntervalMutationVariables>(DeleteMilkingParlorIntervalDocument, options);
      }
export type DeleteMilkingParlorIntervalMutationHookResult = ReturnType<typeof useDeleteMilkingParlorIntervalMutation>;
export type DeleteMilkingParlorIntervalMutationResult = Apollo.MutationResult<DeleteMilkingParlorIntervalMutation>;
export type DeleteMilkingParlorIntervalMutationOptions = Apollo.BaseMutationOptions<DeleteMilkingParlorIntervalMutation, DeleteMilkingParlorIntervalMutationVariables>;