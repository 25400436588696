import {
  CompareToPreviousPeriodMode,
  CustomMilkingReportDataSource,
  CustomMilkingReportXAxisMode,
  CustomMilkingReportXAxisStep,
  CustomMilkingReportYAxisFieldGrouping,
  CustomMilkingReportYAxisFieldKind,
  LactationGroupNumber,
  MilkingHistoricFilterKind,
} from '@graphql-types';
import * as yup from 'yup';

import { HALF_SPACE } from '~/shared/constants';
import { oneOfEnum } from '~/shared/helpers/yup';

import { DATE_PERIOD_FORM_SCHEMA } from '~/services/dateTime';

import {
  CustomMilkingReportFiltersAndGroupingMenuItems,
  CustomMilkingReportYAxisFieldConfig,
  FiltersAndGroupingsModes,
} from './types';

/**
 * Max y axis fields for a report
 */
export const MAX_Y_AXIS_FIELDS = 3;

/**
 * Max y axis fields, if we selected a grouping
 */
export const MAX_Y_AXIS_FIELDS_FOR_GROUPING = 1;

/**
 * Max y axis fields, if we selected a historic period comparison
 */
export const MAX_Y_AXIS_FIELDS_FOR_HISTORIC_COMPARISON = 1;

/**
 * Form id for custom milking report edit form
 */
export const CUSTOM_MILKING_REPORT_SETTINGS_FORM_ID =
  'CustomMilkingReportSettingsForm';

/**
 * Dict with possible data sources for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_DATA_SOURCES_DICT: Record<
  CustomMilkingReportDataSource,
  string
> = {
  [CustomMilkingReportDataSource.TestMilkings]: 'Контрольные дойки',
  [CustomMilkingReportDataSource.MilkingParlors]: 'Доильные залы',
};

/**
 * Dict with possible x axis modes for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_X_AXIS_MODES_DICT: Record<
  CustomMilkingReportXAxisMode,
  string
> = {
  [CustomMilkingReportXAxisMode.ByDate]: 'По дате',
  [CustomMilkingReportXAxisMode.ByDayInMilk]: 'По дню в доении',
  [CustomMilkingReportXAxisMode.TestMilkingNumber]: 'Номер контрольной дойки',
};

/**
 * Dict with possible x axis steps for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_X_AXIS_STEPS_DICT: Record<
  CustomMilkingReportXAxisStep,
  string
> = {
  [CustomMilkingReportXAxisStep.Day]: 'День',
  [CustomMilkingReportXAxisStep.Week]: 'Неделя',
  [CustomMilkingReportXAxisStep.Month]: 'Месяц',
};

/**
 * Dict with y axis field rendering and logic configs for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS: Record<
  CustomMilkingReportYAxisFieldKind,
  CustomMilkingReportYAxisFieldConfig
> = {
  [CustomMilkingReportYAxisFieldKind.MilkWeightKilogramsPerCow]: {
    label: 'Надой на голову',
    measurementUnit: 'кг',
    precision: 1,
    isAvailableForMilkingParlors: true,
    shouldHaveGroupingFormula: true,
  },
  [CustomMilkingReportYAxisFieldKind.CowsCount]: {
    label: 'Число животных',
    measurementUnit: '',
    precision: 0,
    isAvailableForMilkingParlors: true,
  },
  [CustomMilkingReportYAxisFieldKind.MilkWeightKilogramsPerHerd]: {
    label: 'Надой на стадо',
    measurementUnit: 'кг',
    precision: 0,
    isAvailableForMilkingParlors: true,
  },
  [CustomMilkingReportYAxisFieldKind.AverageDayInMilkInHerd]: {
    label: 'Средний день в доении в стаде',
    measurementUnit: '',
    precision: 0,
    isAvailableForMilkingParlors: true,
  },
  [CustomMilkingReportYAxisFieldKind.FatPercent]: {
    label: 'Жир',
    measurementUnit: '%',
    precision: 2,
    shouldHaveGroupingFormula: true,
  },
  [CustomMilkingReportYAxisFieldKind.ProteinPercent]: {
    label: 'Белок',
    measurementUnit: '%',
    precision: 2,
    shouldHaveGroupingFormula: true,
  },
  [CustomMilkingReportYAxisFieldKind.FatCorrectedMilkKilograms]: {
    label: `Молоко, скорректир. по жирности 3,5${HALF_SPACE}%`,
    measurementUnit: 'кг',
    precision: 1,
    shouldHaveGroupingFormula: true,
  },
  [CustomMilkingReportYAxisFieldKind.SccThousands]: {
    label: 'Сомат. клетки',
    measurementUnit: 'тыс. ед. / см³',
    precision: 0,
    shouldHaveGroupingFormula: true,
  },
  [CustomMilkingReportYAxisFieldKind.LgSccThousands]: {
    label: 'Десятичный логарифм от сомат. клеток',
    measurementUnit: '',
    precision: 1,
    shouldHaveGroupingFormula: true,
  },
  [CustomMilkingReportYAxisFieldKind.MunMlPerDl]: {
    label: 'Мочевина',
    measurementUnit: 'мг/дл',
    precision: 1,
    shouldHaveGroupingFormula: true,
  },
};

/**
 * Dict with y axis field groupings for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_GROUPINGS_DICT: Record<
  CustomMilkingReportYAxisFieldGrouping,
  string
> = {
  [CustomMilkingReportYAxisFieldGrouping.Sum]: 'Сумма',
  [CustomMilkingReportYAxisFieldGrouping.Mean]: 'Среднее',
  [CustomMilkingReportYAxisFieldGrouping.Count]: 'Количество элементов',
  [CustomMilkingReportYAxisFieldGrouping.Max]: 'Максимум',
  [CustomMilkingReportYAxisFieldGrouping.Min]: 'Минимум',
  [CustomMilkingReportYAxisFieldGrouping.Median]: 'Медиана',
};

/**
 * Dict with filters and grouping add menu items for custom milking report form
 */
export const CUSTOM_MILKING_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT: Record<
  CustomMilkingReportFiltersAndGroupingMenuItems,
  string
> = {
  [CustomMilkingReportFiltersAndGroupingMenuItems.filter]: 'Фильтр',
  [CustomMilkingReportFiltersAndGroupingMenuItems.grouping]: 'Группировка',
};

/**
 * Dict with filters and grouping modes
 */
export const FILTERS_AND_GROUPINGS_MODES_DICT: Record<
  FiltersAndGroupingsModes,
  string
> = {
  [FiltersAndGroupingsModes.actual]: 'Текущие',
  [FiltersAndGroupingsModes.historic]: 'Исторические',
};

/**
 * Form schema for custom milking report y axis
 */
export const CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA = yup.object({
  kind: oneOfEnum(CustomMilkingReportYAxisFieldKind).required(),
  groupBy: oneOfEnum(CustomMilkingReportYAxisFieldGrouping)
    .default(CustomMilkingReportYAxisFieldGrouping.Mean)
    .nullable(),
  withRightScale: yup.boolean().default(false),
});

/**
 * Form schema for custom milking report historic filter or grouping value
 */
const CUSTOM_MILKING_REPORT_HISTORIC_VALUE_SCHEMA = yup.object({
  lactationNumbers: yup.array(yup.number().required()).nullable().default(null),
  lactationGroupNumbers: yup
    .array(oneOfEnum(LactationGroupNumber).required())
    .nullable()
    .default(null),
  penGroupIDs: yup.array(yup.string().required()).nullable().default(null), // [ID!]
});

/**
 * Form schema for custom milking report filter
 */
export const CUSTOM_MILKING_REPORT_FILTER_SCHEMA = yup.object({
  mode: oneOfEnum(FiltersAndGroupingsModes).default(
    FiltersAndGroupingsModes.actual
  ),
  actualFilter: yup
    .object({
      masterBlueprintSourceFieldID: yup.string().nullable().default(null), // ID
      // We should have source field values validation here,
      // but cause it's dynamic, it will require to rewrite all this schemas for hooks
      // and make exporting schema types impossible.
      // So for now we rely on inputs to provide correct values
      value: yup.mixed(),
    })
    .nullable()
    .default(null),
  historicFilter: yup
    .object({
      kind: oneOfEnum(MilkingHistoricFilterKind).nullable().default(null),
      value:
        CUSTOM_MILKING_REPORT_HISTORIC_VALUE_SCHEMA.nullable().default(null),
    })
    .nullable()
    .default(null),
});

/**
 * Form schema for custom milking report grouping
 */
export const CUSTOM_MILKING_REPORT_GROUPING_SCHEMA = yup.object({
  mode: oneOfEnum(FiltersAndGroupingsModes).default(
    FiltersAndGroupingsModes.actual
  ),
  masterBlueprintSourceFieldID: yup.string().nullable().default(null), // ID
  kind: oneOfEnum(MilkingHistoricFilterKind).nullable().default(null),
});

/**
 * Form schema for custom milking report settings
 */
export const CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA = yup.object({
  blueprintID: yup.string().nullable().default(null), // ID
  dataSource: oneOfEnum(CustomMilkingReportDataSource)
    .required()
    .default(CustomMilkingReportDataSource.MilkingParlors),
  period: DATE_PERIOD_FORM_SCHEMA,
  mode: oneOfEnum(CustomMilkingReportXAxisMode)
    .required()
    .default(CustomMilkingReportXAxisMode.ByDate),
  step: oneOfEnum(CustomMilkingReportXAxisStep)
    .default(CustomMilkingReportXAxisStep.Day)
    .nullable(),
  shouldCompareWithHistoricData: yup.boolean().default(false),
  compareWithHistoricDataPeriod: oneOfEnum(CompareToPreviousPeriodMode)
    .nullable()
    .default(null),
  yAxisFields: yup.array(CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA).default([]),
  filters: yup.array(CUSTOM_MILKING_REPORT_FILTER_SCHEMA).default([]),
  grouping: CUSTOM_MILKING_REPORT_GROUPING_SCHEMA.nullable().default(null),
});
