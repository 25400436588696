import React from 'react';

export const EyeShow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.942 2.94194C18.186 2.69786 18.186 2.30214 17.942 2.05806C17.6979 1.81398 17.3022 1.81398 17.0581 2.05806L13.9502 5.16593C12.7745 4.42228 11.4465 3.95834 9.99996 3.95834C6.78465 3.95834 4.15536 6.25047 2.48076 8.81162C1.84366 9.78601 1.86773 10.2508 2.48076 11.1884C3.17447 12.2493 4.032 13.2641 5.02716 14.089L2.05806 17.0581C1.81398 17.3021 1.81398 17.6979 2.05806 17.9419C2.30214 18.186 2.69786 18.186 2.94194 17.9419L17.942 2.94194ZM13.0494 6.0667C12.1264 5.53386 11.103 5.20834 9.99996 5.20834C7.03149 5.20834 4.62352 7.58329 3.21466 10C3.8952 11.1674 4.80885 12.325 5.9062 13.2099L7.99983 11.1163C7.81425 10.7856 7.7083 10.4047 7.7083 10C7.7083 8.73816 8.73812 7.70834 9.99996 7.70834C10.4047 7.70834 10.7856 7.81429 11.1163 7.99987L13.0494 6.0667ZM10.1473 8.96887C10.0991 8.96193 10.0499 8.95834 9.99996 8.95834C9.42847 8.95834 8.9583 9.42852 8.9583 10C8.9583 10.05 8.96189 10.0991 8.96883 10.1473L10.1473 8.96887Z"
      fill="currentColor"
    />
    <path
      d="M16.4706 7.42177C16.2433 7.162 15.8484 7.13567 15.5886 7.36298C15.3289 7.59028 15.3025 7.98513 15.5298 8.2449C16.0019 8.78439 16.4202 9.38715 16.7829 10.0044C15.3722 12.4215 12.9766 14.7917 10.0002 14.7917C9.46844 14.7917 9.09898 14.783 8.81566 14.6697C8.49517 14.5415 8.13144 14.6974 8.00324 15.0179C7.87505 15.3384 8.03093 15.7021 8.35142 15.8303C8.88165 16.0424 9.48671 16.042 9.94906 16.0417C9.96631 16.0417 9.98336 16.0417 10.0002 16.0417C13.5007 16.0417 16.25 13.3333 17.5 11.25C17.9167 10.474 18.3333 10 17.5 8.75C17.3312 8.49684 17.04 8.08012 16.4706 7.42177Z"
      fill="currentColor"
    />
  </svg>
);
