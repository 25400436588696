import { ModalRegistration } from '~/services/modals';

import {
  EditMilkingParlorIntervalModal,
  EditMilkingParlorIntervalModalProps,
} from '.';

export const registration: ModalRegistration<EditMilkingParlorIntervalModalProps> =
  {
    Component: EditMilkingParlorIntervalModal,
  };
