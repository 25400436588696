import { RegNumFieldEnum } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

/**
 * Dict with possible reg num fields
 */
const REG_NUM_FIELDS_DICT: Record<RegNumFieldEnum, string> = {
  [RegNumFieldEnum.UsdaNumber]: 'Международный номер',
  [RegNumFieldEnum.RegistrationNumber]: 'Номер ошейника',
  [RegNumFieldEnum.EarTagNumber]: 'Номер ушной бирки',
};

const useRegNumFieldSelect = makeUseEnumSelect(
  RegNumFieldEnum,
  enumValue => REG_NUM_FIELDS_DICT[enumValue as RegNumFieldEnum]
);

export const RegNumFieldSelect =
  makeSelectComponentFromHook(useRegNumFieldSelect);
