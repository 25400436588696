import React from 'react';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { formatDate } from '~/services/dateTime';
import { InjectedModalProps, Modal } from '~/services/modals';
import { Callout } from '~/services/notifications';

import { TestMilkingUploadFragment } from '../../gql/fragments/testMilkingUpload.graphql';
import { useExportTestMilkingUploadNotFoundCows } from '../../hooks';

export interface LoadTestMilkingPartialResultsModalProps
  extends InjectedModalProps<LoadTestMilkingPartialResultsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Test milking upload with upload issues to display info about
   */
  testMilkingUpload: TestMilkingUploadFragment;
}

export const LoadTestMilkingPartialResultsModal: React.FC<
  LoadTestMilkingPartialResultsModalProps
> = ({ className, testMilkingUpload, close }) => {
  const { exportTestMilkingUploadNotFoundCows, isLoading } =
    useExportTestMilkingUploadNotFoundCows();

  return (
    <Modal
      {...{
        className,
        title: 'Результаты контрольной дойки загружены не полностью',
        submitButtonProps: {
          children: 'Скачать ненайденных',
          isLoading,
          onPress: () => {
            exportTestMilkingUploadNotFoundCows(testMilkingUpload.id).then(
              close
            );
          },
        },
      }}
    >
      <Callout
        {...{
          className: 'mb-24',
          title: 'В хозяйстве не найдены некоторые коровы из файла',
          message:
            'Так происходит, когда коровы выбыли или не были добавлены в хозяйство',
        }}
      />
      <Typography variant={TypographyVariants.bodySmall} tag="p">
        Скачайте и проверьте список не найденных коров из
        результатов&nbsp;контрольной дойки от{' '}
        <Typography variant={TypographyVariants.bodySmallStrong}>
          {formatDate(testMilkingUpload.happenedAt)}
        </Typography>
      </Typography>
    </Modal>
  );
};
