import {
  FemaleAnimalKind,
  ReproductionHdrAndPrXAxisMode,
  ReproductionHdrAndPrYAxisMetric,
} from '@graphql-types';
import { ConditionalKeys } from 'type-fest';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import { InferValidatedSchema } from '~/services/forms';

import { ReproductionHdrAndPrCalculatedReportFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrCalculatedReport.graphql';
import { ReproductionHdrAndPrRowFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrRow.graphql';
import { CalculateReproductionHdrAndPrReportMutation } from '~/entities/reproductionHdrAndPrReports/gql/mutations/calculateReproductionHdrAndPrReport.graphql';

import { BarChartType } from '~/features/charts';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import { REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA } from '../constants';

/**
 * Form type for reproduction HDR and PR report settings
 */
export type ReproductionHdrAndPrReportSettingsFormType = InferValidatedSchema<
  typeof REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA
>;

/**
 * Possible HDR and PR report mutation results
 */
export type ReproductionHdrAndPrReportDataType =
  CalculateReproductionHdrAndPrReportMutation['calculateReproductionHdrAndPrReport'];

/**
 * Items for adding filters and grouping
 */
export enum ReproductionHdrAndPrReportFiltersAndGroupingMenuItems {
  filterLactations = 'filterLactations',
  filterAnimals = 'filterAnimals',
}

/**
 * Config for an y axis metric that can be added to the HDR and PR chart
 */
export interface ReproductionHdrAndPrYAxisMetricConfig {
  field: ConditionalKeys<
    ReproductionHdrAndPrRowFragment,
    number | null | undefined
  >;
  label: string;
  withComparison?: boolean;
  chartType: BarChartType;
  color: ColorVariants;
}

/**
 * Common props for HDR and PR table and chart x axis labels
 */
export interface HdrAndPrReportDisplayProps {
  /**
   * Periods to display on the chart
   */
  reportRows: (ReproductionHdrAndPrRowFragment | SkeletonPlaceholder)[];
  /**
   * Metrics, to display on the chart
   */
  yAxisMetrics: ReproductionHdrAndPrYAxisMetric[];
  /**
   * If passed, draws chart and table in period comparison mode
   */
  historicReportRows?: ReproductionHdrAndPrCalculatedReportFragment;
  /**
   * Mode for x axis
   */
  xAxisMode: ReproductionHdrAndPrXAxisMode;
  /**
   * Female animal kind, used for report calculations, to get target and x axis label
   */
  femaleAnimalKind: FemaleAnimalKind;
}
