import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { ReactComponent as TriangelDownSVG } from '~/~legacy/icons/triangle-down.svg';
import { ReactComponent as TriangelRightSVG } from '~/~legacy/icons/triangle-right.svg';

import { useToggle } from '~/shared/hooks/useToggle';

import styles from './index.module.scss';

interface Props {
  header?: ReactNode;
  className?: string;
  headerClass?: string;
  contentClass?: string;
  headerStyle?: React.CSSProperties | undefined;
  children?: ReactNode;

  collapsable?: boolean;
}

export const NodeFrame: React.FC<Props> = ({
  children,
  header,
  className,
  headerClass,
  contentClass,
  headerStyle,
  collapsable = true,
}) => {
  const [expanded, toggle] = useToggle(true);

  const icon = expanded ? (
    <TriangelDownSVG className={styles.icon} />
  ) : (
    <TriangelRightSVG className={styles.icon} />
  );

  const toggleBtn = collapsable ? (
    <div onClick={() => toggle()}>{icon}</div>
  ) : null;

  const headerCmpnt = header ? (
    <div style={headerStyle} className={clsx(styles.header, headerClass)}>
      {toggleBtn}
      {header}
    </div>
  ) : null;

  return (
    <div className={clsx(styles.root, className)}>
      {headerCmpnt}
      <div
        className={clsx(
          styles.content,
          contentClass,
          !expanded && styles.collapsed
        )}
      >
        {children}
      </div>
    </div>
  );
};
