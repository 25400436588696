import { CowRetirementReason } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { COW_RETIREMENT_REASON_DICT } from '../../constants';

const useCowRetirementReasonSelect = makeUseEnumSelect(
  CowRetirementReason,
  enumValue => COW_RETIREMENT_REASON_DICT[enumValue as CowRetirementReason]
);

export const CowRetirementReasonSelect = makeSelectComponentFromHook(
  useCowRetirementReasonSelect
);
