import React from 'react';

import clsx from 'clsx';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useArkaNavigation } from '~/services/navigation';

import { CustomMilkingReportFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReport.graphql';
import { CustomMilkingReportsQueryVariables } from '~/entities/customMilkingReports/gql/queries/customMilkingReports.graphql';
import { RoleBadges } from '~/entities/roles';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CustomMilkingReportCardMenu } from '../CustomMilkingReportCardMenu';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  customMilkingReport: CustomMilkingReportFragment | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomMilkingReportsQueryVariables;
}

export const CustomMilkingReportCard: React.FC<Props> = ({
  className,
  customMilkingReport,
  queryVariables,
}) => {
  const { navigate, urlCompanyId } = useArkaNavigation();

  return (
    <div
      {...{
        className: clsx(styles.root, panelStyles.panel, className),
        onClick: () => {
          if (isSkeletonPlaceholder(customMilkingReport)) return;

          navigate({
            to: '/$companyId/user/analytics/milking/by-herd/$customMilkingReportId',
            params: {
              customMilkingReportId: customMilkingReport.id,
              companyId: urlCompanyId,
            },
          });
        },
      }}
    >
      <div className="flex items-start">
        <Typography
          {...{
            className: 'mr-12',
            variant: TypographyVariants.bodyMediumStrong,
          }}
        >
          {customMilkingReport.name}
        </Typography>
        {!isSkeletonPlaceholder(customMilkingReport) && (
          <CustomMilkingReportCardMenu
            {...{
              className: 'ml-a',
              customMilkingReport,
              queryVariables,
            }}
          />
        )}
      </div>
      <div className="mt-a flex">
        <RoleBadges roles={customMilkingReport.roles} />
      </div>
    </div>
  );
};
