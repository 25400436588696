import { useCallback } from 'react';

import { mergeProps } from '~/shared/helpers/mergeProps';

import { ModalNames } from '../__generated__/modalNames';
import { ConfirmModalProps } from '../components/ConfirmModal';
import { makeUseModal } from '../helpers';

const useConfirmModal = makeUseModal<ConfirmModalProps>(
  ModalNames.confirmModal
);

type ConfirmProps = Omit<
  ConfirmModalProps,
  'onSubmit' | 'onCancel' | 'close' | 'open'
>;

/**
 * It returns a function that opens a confirm modal and returns a promise that resolves to true if the
 * user clicks the submit button and false if the user clicks the cancel button
 * @param {Props} [props] - The props that will be passed to the ConfirmModal component.
 * @returns A function that returns a promise that resolves to a boolean.
 */
export const useConfirm = (props?: ConfirmProps) => {
  const { open } = useConfirmModal(props);

  const confirm = useCallback(
    (
      overrideProps?: typeof props extends undefined
        ? ConfirmProps
        : Partial<ConfirmProps> | undefined
    ): Promise<boolean> => {
      return new Promise(resolve => {
        open({
          ...mergeProps(props, overrideProps),
          onSubmit: () => {
            resolve(true);
          },
          onCancel: () => resolve(false),
        });
      });
    },
    []
  );

  return confirm;
};
