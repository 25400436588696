import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetHerriotIntegrationSettingsMutationVariables = Types.Exact<{
  companyID: Types.Scalars['ID']['input'];
  input: Types.SetHerriotIntegrationSettingsInput;
}>;


export type SetHerriotIntegrationSettingsMutation = { __typename?: 'Mutation', setHerriotIntegrationSettings?: any | null };


export const SetHerriotIntegrationSettingsDocument = gql`
    mutation setHerriotIntegrationSettings($companyID: ID!, $input: SetHerriotIntegrationSettingsInput!) {
  setHerriotIntegrationSettings(companyID: $companyID, input: $input)
}
    `;
export type SetHerriotIntegrationSettingsMutationFn = Apollo.MutationFunction<SetHerriotIntegrationSettingsMutation, SetHerriotIntegrationSettingsMutationVariables>;

/**
 * __useSetHerriotIntegrationSettingsMutation__
 *
 * To run a mutation, you first call `useSetHerriotIntegrationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHerriotIntegrationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHerriotIntegrationSettingsMutation, { data, loading, error }] = useSetHerriotIntegrationSettingsMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetHerriotIntegrationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetHerriotIntegrationSettingsMutation, SetHerriotIntegrationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHerriotIntegrationSettingsMutation, SetHerriotIntegrationSettingsMutationVariables>(SetHerriotIntegrationSettingsDocument, options);
      }
export type SetHerriotIntegrationSettingsMutationHookResult = ReturnType<typeof useSetHerriotIntegrationSettingsMutation>;
export type SetHerriotIntegrationSettingsMutationResult = Apollo.MutationResult<SetHerriotIntegrationSettingsMutation>;
export type SetHerriotIntegrationSettingsMutationOptions = Apollo.BaseMutationOptions<SetHerriotIntegrationSettingsMutation, SetHerriotIntegrationSettingsMutationVariables>;