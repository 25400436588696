import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorFlowRateReportRowEntryFragmentDoc } from '../fragments/milkingParlorFlowRateReportRowEntry.graphql';
import { MilkingParlorFlowRateReportGroupedRowEntryFragmentDoc } from '../fragments/milkingParlorFlowRateReportGroupedRowEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorFlowRateReportRowsQueryVariables = Types.Exact<{
  milkingDate: Types.Scalars['Date']['input'];
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingParlorIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MilkingParlorFlowRateReportRowsQuery = { __typename?: 'Query', milkingParlorFlowRateReportRows: Array<{ __typename: 'MilkingParlorFlowRateReportGroupedRowEntry', cowStallPerHour?: number | null, flowRate0To15Sec?: number | null, flowRate15To30Sec?: number | null, flowRate30To60Sec?: number | null, peakFlowRate?: number | null, milkWeight0To120Sec?: number | null, milkWeightPercent0To120Sec?: number | null } | { __typename: 'MilkingParlorFlowRateReportRowEntry', cowStallPerHour?: number | null, flowRate0To15Sec?: number | null, flowRate15To30Sec?: number | null, flowRate30To60Sec?: number | null, peakFlowRate?: number | null, milkWeight0To120Sec?: number | null, milkWeightPercent0To120Sec?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: number } | null }> };


export const MilkingParlorFlowRateReportRowsDocument = gql`
    query milkingParlorFlowRateReportRows($milkingDate: Date!, $farmIDs: [ID!], $milkingParlorIDs: [ID!], $milkingNumbers: [Int!]) {
  milkingParlorFlowRateReportRows(
    milkingDate: $milkingDate
    farmIDs: $farmIDs
    milkingParlorIDs: $milkingParlorIDs
    milkingNumbers: $milkingNumbers
  ) {
    ... on MilkingParlorFlowRateReportRowEntry {
      ...MilkingParlorFlowRateReportRowEntry
    }
    ... on MilkingParlorFlowRateReportGroupedRowEntry {
      ...MilkingParlorFlowRateReportGroupedRowEntry
    }
  }
}
    ${MilkingParlorFlowRateReportRowEntryFragmentDoc}
${MilkingParlorFlowRateReportGroupedRowEntryFragmentDoc}`;

/**
 * __useMilkingParlorFlowRateReportRowsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorFlowRateReportRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorFlowRateReportRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorFlowRateReportRowsQuery({
 *   variables: {
 *      milkingDate: // value for 'milkingDate'
 *      farmIDs: // value for 'farmIDs'
 *      milkingParlorIDs: // value for 'milkingParlorIDs'
 *      milkingNumbers: // value for 'milkingNumbers'
 *   },
 * });
 */
export function useMilkingParlorFlowRateReportRowsQuery(baseOptions: Apollo.QueryHookOptions<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables> & ({ variables: MilkingParlorFlowRateReportRowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables>(MilkingParlorFlowRateReportRowsDocument, options);
      }
export function useMilkingParlorFlowRateReportRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables>(MilkingParlorFlowRateReportRowsDocument, options);
        }
export function useMilkingParlorFlowRateReportRowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables>(MilkingParlorFlowRateReportRowsDocument, options);
        }
export type MilkingParlorFlowRateReportRowsQueryHookResult = ReturnType<typeof useMilkingParlorFlowRateReportRowsQuery>;
export type MilkingParlorFlowRateReportRowsLazyQueryHookResult = ReturnType<typeof useMilkingParlorFlowRateReportRowsLazyQuery>;
export type MilkingParlorFlowRateReportRowsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorFlowRateReportRowsSuspenseQuery>;
export type MilkingParlorFlowRateReportRowsQueryResult = Apollo.QueryResult<MilkingParlorFlowRateReportRowsQuery, MilkingParlorFlowRateReportRowsQueryVariables>;