import { useEffect, useState } from 'react';

/**
 * Simple hook for timeout a value update,
 * we can replace it if we'll have a use case for something more complex, like use-debounce package
 */
export const useDebouncedValue = <T>(
  value: T,
  delay: number,
  onDebouncedSearchValueChange?: (value: T) => void
): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
      onDebouncedSearchValueChange?.(value);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay, onDebouncedSearchValueChange]);

  return debouncedValue;
};
