import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from '../../../../entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';
export type CustomReportChartFragment = { __typename: 'CustomReportChart', xAxisLabels: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null>, yAxisDatasets: Array<Array<{ __typename: 'CustomReportChartValue', value?: number | null }>> };

export const CustomReportChartFragmentDoc = gql`
    fragment CustomReportChart on CustomReportChart {
  __typename
  xAxisLabels {
    ...CustomReportBlueprintSourceFieldValue
  }
  yAxisDatasets {
    __typename
    value
  }
}
    ${CustomReportBlueprintSourceFieldValueFragmentDoc}`;