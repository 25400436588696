import React from 'react';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { SizeVariants } from '~/styles/__generated__/token-variants';

interface Props {
  /**
   * departDate of cow movement
   */
  departDate?: string;
  /**
   * Company name (inn/name)
   */
  companyTitle?: string;
  /**
   * fullName of key creator
   */
  fullName?: string;
  /**
   * Amount of cows to move
   */
  cowsAmount?: string | number;
  /**
   * getIconClassName function to get className for icon wrapper by icon variant
   */
  getIconClassName?: (iconVariant: IconVariants) => string;
  /**
   * The size of icons and typography
   */
  isSmall?: boolean;
}

export const MoveCowsKeyInfoIcons: React.FC<Props> = ({
  departDate,
  companyTitle,
  fullName,
  cowsAmount,
  getIconClassName,
  isSmall = false,
}) => {
  const moveKeyResultItems: {
    iconVariant: IconVariants;
    title?: string | number;
  }[] = [
    { iconVariant: IconVariants.calendar, title: departDate },
    { iconVariant: IconVariants.company, title: companyTitle },
    { iconVariant: IconVariants.profile, title: fullName },
    { iconVariant: IconVariants.cow, title: cowsAmount },
  ].filter(({ title }) => title);

  return (
    <div className="flex flex-wrap items-center gap-16 text-soft">
      {moveKeyResultItems.map(({ iconVariant, title }) => (
        <div
          key={iconVariant}
          {...{
            className: clsx('flex gap-8', getIconClassName?.(iconVariant)),
          }}
        >
          <Icon
            {...{
              variant: iconVariant,
              size: isSmall ? SizeVariants.size20 : SizeVariants.size24,
            }}
          />
          <Typography
            key={iconVariant}
            {...{
              className: 'ellipsis',
              title:
                iconVariant === IconVariants.company ||
                iconVariant === IconVariants.profile
                  ? title?.toString()
                  : undefined,
              variant: isSmall
                ? TypographyVariants.bodySmall
                : TypographyVariants.bodyMedium,
            }}
          >
            {title}
          </Typography>
        </div>
      ))}
    </div>
  );
};
