import React, { ReactNode } from 'react';

import clsx from 'clsx';

import {
  FunctionButton,
  FunctionButtonVariants,
} from '~/shared/components/FunctionButton';
import { IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { useAccordion, UseAccordionProps } from '~/shared/hooks/useAccordion';

import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

interface Props extends UseAccordionProps {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Title of the accordion
   */
  title?: ReactNode;
}

export const Accordion: React.FC<Props> = ({
  className,

  title,

  children,
  ...useAccordionProps
}) => {
  const {
    isOpen,
    shouldRenderContent,

    childrenContainerRef,

    openableHeaderProps,
    openButtonProps,
    childrenWrapperProps,
  } = useAccordion(useAccordionProps);

  return (
    <div className={clsx(panelStyles.panel, className, isOpen && styles.open)}>
      <div
        {...{
          className: styles.header,
          ...openableHeaderProps,
        }}
      >
        <Typography
          className={styles.title}
          variant={TypographyVariants.bodyMediumStrong}
        >
          {title}
        </Typography>
        <FunctionButton
          {...{
            iconVariant: IconVariants.chevronDown,
            variant: FunctionButtonVariants.secondary,
            iconProps: {
              rotate: isOpen ? RotateVariants.down : RotateVariants.up,
            },
            ...openButtonProps,
          }}
        />
      </div>
      <div
        {...{
          className: styles.children,
          ...childrenWrapperProps,
        }}
      >
        <div ref={childrenContainerRef}>
          {shouldRenderContent && <div>{children}</div>}
        </div>
      </div>
    </div>
  );
};
