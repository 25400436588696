import { EventKindEnum } from '@graphql-types';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { makeUseEnumSelect } from '~/shared/components/Select';

/**
 * Select for event kinds
 */
export const useEventKindsSelect = makeUseEnumSelect(
  EventKindEnum,
  EnumStrings.eventKinds
);
