import { PenGroupAttributeFields } from './types';

export const PEN_GROUP_ATTRIBUTES_DICT: Record<
  PenGroupAttributeFields,
  string
> = {
  [PenGroupAttributeFields.isWithBull]: 'С быком',
  [PenGroupAttributeFields.isArtificialInsemination]:
    'Искусственное осеменение',
  [PenGroupAttributeFields.isMilking]: 'Доение',
  [PenGroupAttributeFields.isDry]: 'Сухостой',
  [PenGroupAttributeFields.isHospital]: 'Госпиталь',
  [PenGroupAttributeFields.isCalf]: 'Теленок',
  [PenGroupAttributeFields.isUser]: 'Пользователь',
};
