import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import R from 'ramda';

import {
  Select,
  SelectBaseProps,
  SelectItem,
} from '~/shared/components/Select';
import { BaseFieldProps } from '~/shared/types/controls';

import { ReproductionCrValueFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrValue.graphql';

import {
  formatReproductionCrValue,
  mapReproductionCrValueToForm,
} from '../../../../helpers';
import { ReproductionCrReportSettingsFormType } from '../../../../types';

type SelectPropsToExtend = Omit<SelectBaseProps, 'items' | 'isMulti'> &
  BaseFieldProps<SelectItem[]>;

type Props = SelectPropsToExtend & {
  name: 'xAxisValues' | 'groupByValues';
  /**
   * className applied to the root element
   */
  reproductionCrValues: ReproductionCrValueFragment[];
};

export const ReproductionCrValueSelect: React.FC<Props> = ({
  name,
  reproductionCrValues,
  ...other
}) => {
  const formContext = useFormContext<ReproductionCrReportSettingsFormType>();
  const currentValue = formContext.watch(name);

  const items = useMemo(
    () =>
      reproductionCrValues.map((crValue, index) => ({
        id: index,
        name: formatReproductionCrValue(crValue),
        formValue: mapReproductionCrValueToForm(crValue),
      })),
    [reproductionCrValues]
  );

  const rawValue = useMemo(
    () =>
      currentValue?.map(crFormValue =>
        items.findIndex(item => R.equals(item.formValue, crFormValue))
      ),
    [reproductionCrValues, currentValue]
  );

  return (
    <Select
      {...{
        name,
        items,
        isMulti: true,
        withFormContext: false,
        rawValue,
        isClearable: true,
        onValueChange: newValue => {
          formContext.setValue(
            name,
            newValue.map(({ id }) => items[id].formValue)
          );
        },
        ...other,
      }}
    />
  );
};
