import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { BlueprintShortFragmentDoc } from '../../gql/fragments/blueprintShort.graphql';
import { useBlueprintsShortQuery } from '../../gql/queries/blueprintsShort.graphql';

/**
 * Select for a blueprint
 */
const useBlueprintSelect = makeUseAsyncSelect({
  typeName: 'Blueprint',
  fragment: BlueprintShortFragmentDoc,
  useQuery: useBlueprintsShortQuery,
  getItemsFromQueryData: data => data.blueprints.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.blueprints.pageInfo,
});

export const BlueprintAsyncSelect =
  makeAsyncSelectFormComponent(useBlueprintSelect);
