import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { HerriotIntegrationEntityFragmentDoc } from './herriotIntegrationEntity.graphql';
export type HerriotIntegrationSettingsFragment = { __typename: 'HerriotIntegrationSettings', apiKey: string, apiLogin: string, issuerID: string, breedingValueType: Types.BreedingValueTypeEnum, markingMeans: Types.MarkingMeanEnum, supervisedObjectGuid: string, initiatorLogin: string, keepingPurpose: { __typename: 'HerriotIntegrationEntity', name: string, guid: string, kind: Types.HerriotIntegrationEntityKindEnum }, keepingType: { __typename: 'HerriotIntegrationEntity', name: string, guid: string, kind: Types.HerriotIntegrationEntityKindEnum }, markingAttachmentLocation: { __typename: 'HerriotIntegrationEntity', name: string, guid: string, kind: Types.HerriotIntegrationEntityKindEnum } };

export const HerriotIntegrationSettingsFragmentDoc = gql`
    fragment HerriotIntegrationSettings on HerriotIntegrationSettings {
  __typename
  apiKey
  apiLogin
  issuerID
  breedingValueType
  markingMeans
  supervisedObjectGuid
  initiatorLogin
  keepingPurpose {
    ...HerriotIntegrationEntity
  }
  keepingType {
    ...HerriotIntegrationEntity
  }
  markingAttachmentLocation {
    ...HerriotIntegrationEntity
  }
}
    ${HerriotIntegrationEntityFragmentDoc}`;