/**
 * Formats a string, so it is starting from uppercase letter
 */
export const capitalize = (s: string | null | undefined): string => {
  if (!s) {
    return '';
  }

  return s[0].toUpperCase() + s.slice(1);
};

/**
 * Fabric to make getter of ID by passed type
 */
export const makeIdGetter =
  <T extends string>(type: T) =>
  (id = ''): `${T}_${string}` =>
    `${type}_${id}`;

/**
 * Takes a type and temporarily ID for passed type
 */
export const getTmpId = <T extends string>(type: T) =>
  makeIdGetter(type)(Math.random().toString());

/**
 * It returns true if the node's id is temporary.
 * We use Math.random for tmp ids, so we check, if an id contains a period
 */
export const isTmpId = (id: string | null | undefined) => !!id?.includes('.');
