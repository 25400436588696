import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';

import { useForm } from '~/services/forms';

import { useReportCardBuilderForm } from '~/features/reportCardBuilder';

import {
  SOMATIC_CELLS_REPORT_FILTER_SCHEMA,
  SOMATIC_CELLS_REPORT_SETTINGS_FORM_SCHEMA,
} from '../../constants';
import { mapSomaticCellsReportSettingsToForm } from '../../helpers';
import { SomaticCellsReportSettingsFormType } from '../../types';
import { SomaticCellsReportSettingsFormProps } from './types';

export const useSomaticCellsReportSettingsForm = ({
  somaticCellsReport,

  onSettingsFormChange,
}: SomaticCellsReportSettingsFormProps) => {
  const reportSettings = somaticCellsReport.settings;

  const defaultValues = useMemo(
    () => mapSomaticCellsReportSettingsToForm(reportSettings),
    [reportSettings]
  );

  const formContext = useForm<SomaticCellsReportSettingsFormType>({
    schema: SOMATIC_CELLS_REPORT_SETTINGS_FORM_SCHEMA,
    defaultValues,
  });

  const {
    fields: filtersArrayItems,
    remove: removeFromFiltersArray,
    append: appendToFiltersArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'filters',
  });

  useReportCardBuilderForm({
    formContext,
    defaultValues,
    onSettingsFormChange,
  });

  const handleAddNewFilter = () => {
    appendToFiltersArray(SOMATIC_CELLS_REPORT_FILTER_SCHEMA.getDefault());
  };

  return {
    formContext,
    filtersArrayItems,
    handleAddNewFilter,
    removeFromFiltersArray,
  };
};
