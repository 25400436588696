import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { BullDetailedFragment } from '../gql/fragments/bullDetailed.graphql';
import {
  BullsDetailedQuery,
  BullsDetailedQueryVariables,
  useBullsDetailedQuery,
} from '../gql/queries/bullsDetailed.graphql';

export const useBullsDetailedPaginatedQuery = makeUsePaginatedQuery<
  BullDetailedFragment,
  BullsDetailedQuery,
  BullsDetailedQueryVariables
>({
  useQuery: useBullsDetailedQuery,
  getItemsFromQueryData: data => data.bulls.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.bulls.pageInfo,
});
