import React from 'react';

import * as yup from 'yup';

import {
  Button,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { FilePicker } from '~/services/files';
import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { FarmAsyncSelect } from '~/entities/farms';

import { useLoadDcImportArchiveMutation } from '../../gql/mutations/loadDCImportArchive.graphql';
import { updateCompanyShortFragment } from '../../helpers';
import { useDetailedCompany } from '../../hooks';
import { AnyCompanyFragment } from '../../types';

export interface UploadCowFileModalProps
  extends InjectedModalProps<UploadCowFileModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Company to upload file
   */
  company: AnyCompanyFragment;
  /**
   * Called, when an import modal starts async company update by writing to company's isLockedForWrite
   */
  onPolledCompanyUpdateStarted?: () => void;
}

const FORM_ID = 'uploadCowFileForm';

const SCHEMA = yup.object({
  file: yup.mixed().required(), // Upload!
  farmID: yup.string().required(), // ID!
});

type UploadCowsFileFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type UploadCowsFileFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

export const UploadCowFileModal: React.FC<UploadCowFileModalProps> = ({
  className,

  company,
  onPolledCompanyUpdateStarted,

  close,
}) => {
  const [loadDcImportArchive, { loading: isLoadDcImportLoading }] =
    useLoadDcImportArchiveMutation();

  const { isDetailedCompanyLoading, companyDetailed, companyDetailedPromise } =
    useDetailedCompany(company);

  const formContext = useForm<
    UploadCowsFileFormType,
    UploadCowsFileFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: () =>
      companyDetailedPromise.then(detailedCompany => ({
        ...SCHEMA.getDefault(),
        farmID: detailedCompany?.farms.nodes.at(0)?.id,
      })),
  });

  const handleSubmit = (fileInput: UploadCowsFileFormTransformedType) => {
    loadDcImportArchive({
      variables: {
        fileInput: {
          ...fileInput,
          companyID: company.id,
        },
      },
      update: updateCompanyShortFragment(company.id, draft => {
        draft.isLockedForWrite = true;
      }),
    }).then(() => {
      onPolledCompanyUpdateStarted?.();
      close();
    });
  };

  return (
    <Modal
      {...{
        className,
        title: `Загрузка данных в компанию ${company.name}`,
        submitButtonProps: {
          isLoading: isLoadDcImportLoading,
          children: 'Загрузить',
          form: FORM_ID,
        },
      }}
    >
      <Form
        formContext={formContext}
        id={FORM_ID}
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <Typography variant={TypographyVariants.bodySmall}>
          Используйте инструкцию, чтобы правильно подготовить файл для загрузки
        </Typography>
        <Button
          {...{
            className: 'mt-16',
            size: ButtonSizes.small24,
            iconVariant: IconVariants.newTab,
            theme: ButtonThemes.accent,
            variant: ButtonVariants.secondary,
            onPress: () => {
              window.open(
                process.env.REACT_APP_COW_FILE_INSTRUCTION_URL,
                '_blank'
              );
            },
          }}
        >
          Перейти к инструкции
        </Button>

        <FarmAsyncSelect
          {...{
            name: 'farmID',
            className: 'mt-24',
            label: 'Ферма',
            items: companyDetailed?.farms.nodes ?? [],
            asyncProps: {
              isLoading: isDetailedCompanyLoading,
            },
          }}
        />

        <FilePicker className="mt-16" name="file" />
      </Form>
    </Modal>
  );
};
