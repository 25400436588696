import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MonitorScheduleFragment = { __typename: 'MonitorSchedule', interval: Types.MonitorScheduleInterval, startOn: string, dows?: Array<Array<Types.Dow>> | null };

export const MonitorScheduleFragmentDoc = gql`
    fragment MonitorSchedule on MonitorSchedule {
  __typename
  interval
  startOn
  dows
}
    `;