import React from 'react';

export const ChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.47237 9.46698C6.76674 9.17558 7.24161 9.17799 7.53302 9.47237L11.9549 13.9393L16.4723 9.46701C16.7667 9.17559 17.2416 9.17798 17.533 9.47234C17.8244 9.7667 17.822 10.2416 17.5277 10.533L12.4772 15.533C12.1944 15.8129 11.6964 15.8104 11.4165 15.5276L6.46698 10.5276C6.17558 10.2333 6.17799 9.75839 6.47237 9.46698Z"
      fill="currentColor"
    />
  </svg>
);
