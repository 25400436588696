import { FemaleAnimalKind } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { FEMALE_ANIMAL_KINDS_DICT } from '../../constants';

const useFemaleAnimalKindSelect = makeUseEnumSelect(
  FemaleAnimalKind,
  enumValue => FEMALE_ANIMAL_KINDS_DICT[enumValue as FemaleAnimalKind]
);

export const FemaleAnimalKindSelect = makeSelectComponentFromHook(
  useFemaleAnimalKindSelect
);
