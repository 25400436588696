import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type TestMilkingUploadFragment = { __typename: 'TestMilkingUpload', id: string, happenedAt: string, cowsCount: number, hasUploadIssues: boolean };

export const TestMilkingUploadFragmentDoc = gql`
    fragment TestMilkingUpload on TestMilkingUpload {
  __typename
  id
  happenedAt
  cowsCount
  hasUploadIssues
}
    `;