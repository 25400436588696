import R from 'ramda';

import {
  CreateFarmMutationOptions,
  useCreateFarmMutation,
} from '../gql/mutations/createFarm.graphql';
import {
  DeleteFarmMutationOptions,
  useDeleteFarmMutation,
} from '../gql/mutations/deleteFarm.graphql';
import { useUpdateFarmMutation } from '../gql/mutations/updateFarm.graphql';
import { updateFarmFragment } from '../helpers';
import { FarmFormType } from '../types';

const getFarmInputFromForm = (form: FarmFormType) => R.omit(['id'], form);

/**
 * Hook for CRUD actions on a farm
 */
export const useFarmsCRUD = () => {
  // Farm create logic
  const [createFarmMutation] = useCreateFarmMutation();

  const createFarm = (
    companyID: string,
    form: FarmFormType,
    mutationOptions?: Partial<CreateFarmMutationOptions>
  ) =>
    createFarmMutation({
      variables: {
        input: {
          ...getFarmInputFromForm(form),
          companyID,
        },
      },
      ...mutationOptions,
    });

  // Farm update logic
  const [updateFarmMutation] = useUpdateFarmMutation();

  const updateFarm = (form: FarmFormType) =>
    updateFarmMutation({
      variables: {
        id: form.id,
        input: getFarmInputFromForm(form),
      },
      optimisticResponse: {
        updateFarm: null,
      },
      update: updateFarmFragment(form.id, draft => {
        draft.name = form.name;
        draft.number = form.number;
      }),
    });

  // Farm delete logic
  const [deleteFarmMutation] = useDeleteFarmMutation();
  const deleteFarm = (
    farmId: string,
    mutationOptions?: Partial<DeleteFarmMutationOptions>
  ) =>
    deleteFarmMutation({
      variables: {
        id: farmId,
      },
      optimisticResponse: { deleteFarm: null },
      ...mutationOptions,
    });

  return {
    createFarm,
    updateFarm,
    deleteFarm,
  };
};
