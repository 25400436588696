import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { CalendarEntryFragment } from '../gql/fragments/calendarEntry.graphql';
import {
  CalendarEntriesQuery,
  CalendarEntriesQueryVariables,
  useCalendarEntriesQuery,
} from '../gql/queries/calendarEntries.graphql';

export const useCalendarEntriesPaginatedQuery = makeUsePaginatedQuery<
  CalendarEntryFragment,
  CalendarEntriesQuery,
  CalendarEntriesQueryVariables
>({
  useQuery: useCalendarEntriesQuery,
  getItemsFromQueryData: data =>
    data.calendarEntries.edges
      .map(R.prop('node'))
      // TODO remove calendar divider on backend, cause we don't use it
      .filter(
        (item): item is CalendarEntryFragment =>
          item.__typename === 'CalendarEntry'
      ),
  getPageInfoFromQueryData: data => data.calendarEntries.pageInfo,
});
