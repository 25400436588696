import React, { useCallback } from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { AsyncListProps } from '~/shared/components/AsyncList';
import { Badge } from '~/shared/components/Badge';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatInt, formatWithPercent } from '~/shared/helpers/number';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { formatPenGroup } from '~/entities/penGroups';
import { PenGroupFragment } from '~/entities/penGroups/gql/fragments/penGroup.graphql';
import { useDeletePenGroupMutation } from '~/entities/penGroups/gql/mutations/deletePenGroup.graphql';
import {
  PenGroupsDetailedDocument,
  PenGroupsDetailedQueryVariables,
} from '~/entities/penGroups/gql/queries/penGroupsDetailed.graphql';

import { PEN_GROUP_ATTRIBUTES_DICT } from '../../constants';
import { useEditPenGroupModal } from '../../modals';
import { PenGroupAttributeFields } from '../../types';
import { PenGroupNestedTable } from '../PenGroupNestedTable';

const NAME_COLUMN_WIDTH_PX = 204;
const ATTRIBUTE_COLUMN_WIDTH_PX = 320;
const CAPACITY_AND_OCCUPIED_COLUMN_WIDTH_PX = 128;
const FREE_COLUMN_WIDTH_PX = 92;

const PEN_GROUP_ROW_SKELETON_COUNT = 8;

const SKELETON_COLUMN_PROPS = {
  cellTypographyProps: {
    skeletonProps: {
      size: TextSkeletonSizes.small,
    },
  },
};

interface Props extends Partial<AsyncListProps<PenGroupFragment>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Pen groups to render in the table
   */
  penGroupItems: PenGroupFragment[];
  /**
   * Query variables for pen groups query
   */
  penGroupsQueryVariables: PenGroupsDetailedQueryVariables;
}

export const PenGroupTable: React.FC<Props> = ({
  className,
  penGroupItems,
  penGroupsQueryVariables,
  ...asyncProps
}) => {
  const { open: openEditPenGroupModal } = useEditPenGroupModal();

  const columnConfigs: TableColumnConfig<PenGroupFragment>[] = [
    {
      title: 'Название группы',
      key: 'name',
      renderCellContent: penGroup => formatPenGroup(penGroup),
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Признак группы',
      key: 'attribute',
      renderCellContent: penGroup => {
        if (isSkeletonPlaceholder(penGroup)) return null;

        return (
          <div className="flex gap-8 flex-wrap">
            {(
              Object.keys(
                PEN_GROUP_ATTRIBUTES_DICT
              ) as PenGroupAttributeFields[]
            )
              .filter(item => penGroup[item])
              .map(item => (
                <Badge key={item}>{PEN_GROUP_ATTRIBUTES_DICT[item]}</Badge>
              ))}
          </div>
        );
      },
      width: ATTRIBUTE_COLUMN_WIDTH_PX,
      ...SKELETON_COLUMN_PROPS,
    },
    {
      title: 'Количество голов',
      key: 'occupied',
      renderCellContent: ({ occupied }) => formatInt(occupied),
      columnClassName: 'text-right',
      width: CAPACITY_AND_OCCUPIED_COLUMN_WIDTH_PX,
      ...SKELETON_COLUMN_PROPS,
    },
    {
      title: 'Вместимость группы',
      key: 'capacity',
      renderCellContent: ({ capacity }) => formatInt(capacity),
      columnClassName: 'text-right',
      width: CAPACITY_AND_OCCUPIED_COLUMN_WIDTH_PX,
      ...SKELETON_COLUMN_PROPS,
    },
    {
      title: 'Свободно',
      key: 'free',
      renderCellContent: penGroup => {
        if (isSkeletonPlaceholder(penGroup) || R.isNil(penGroup.capacity)) {
          return null;
        }

        return formatInt(penGroup.capacity - penGroup.occupied);
      },
      columnClassName: 'text-right',
      width: FREE_COLUMN_WIDTH_PX,
      ...SKELETON_COLUMN_PROPS,
    },
    {
      title: 'Заполнено',
      key: 'occupiedPercent',
      columnClassName: 'text-right',
      renderCellContent: ({ occupiedPercent }) =>
        formatWithPercent(occupiedPercent),
      ...SKELETON_COLUMN_PROPS,
    },
  ];

  const [deletePenGroup] = useDeletePenGroupMutation();
  const confirmDelete = useConfirm();

  const deleteCallback = useCallback(
    async (penGroup: PenGroupFragment) => {
      const isConfirmed = await confirmDelete({
        title: 'Удаление группы',
        message: (
          <div className="grid gap-12">
            <Typography tag="p" variant={TypographyVariants.bodySmall}>
              Вы хотите удалить группу{' '}
              <Typography variant={TypographyVariants.bodySmallStrong}>
                {formatPenGroup(penGroup)}
              </Typography>
              ?
            </Typography>
            <Typography tag="p" variant={TypographyVariants.bodySmall}>
              Это действие невозможно отменить.
            </Typography>
          </div>
        ),
        isDelete: true,
      });
      if (!isConfirmed) return;

      deletePenGroup({
        variables: { id: penGroup.id },
        optimisticResponse: { deletePenGroup: null },
        update: R.juxt([
          makeDeleteFragmentFromQuery({
            typeName: 'PenGroup',
            query: PenGroupsDetailedDocument,
            variables: penGroupsQueryVariables,
            queryName: 'penGroups',
          })(penGroup.id),
          makeDeleteQueriesByNameWithoutVariables(
            'penGroups',
            penGroupsQueryVariables
          ),
        ]),
      });
    },
    [penGroupsQueryVariables]
  );

  return (
    <Table<PenGroupFragment>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: penGroupItems,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        noSearchItemsMessage: 'Группы не найдены',
        isSearchActive: !R.isEmpty(penGroupsQueryVariables),
        skeletonItemsCount: PEN_GROUP_ROW_SKELETON_COUNT,
        hasExpandableRowContent: penGroup => {
          if (isSkeletonPlaceholder(penGroup)) {
            return true;
          }

          return !!penGroup.occupied;
        },
        renderExpandableRowContent: penGroup => (
          <PenGroupNestedTable
            {...{
              groupId: penGroup.id,
            }}
          />
        ),
        renderItemActions: penGroup => {
          if (isSkeletonPlaceholder(penGroup)) return null;

          return (
            <div className="flex gap-12">
              <FunctionButton
                {...{
                  iconVariant: IconVariants.edit,
                  onPress: () => openEditPenGroupModal({ penGroup }),
                }}
              />
              <FunctionButton
                {...{
                  iconVariant: IconVariants.delete,
                  onPress: () => deleteCallback(penGroup),
                }}
              />
            </div>
          );
        },
        ...asyncProps,
      }}
    />
  );
};
