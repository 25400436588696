import { useEffect } from 'react';

import R from 'ramda';

import { useControllableState } from '~/shared/hooks/useControllableState';

const POLLING_INTERVAL_MS = 5 * 1000;

interface UsePollingProps<Data> {
  /**
   * Callback to poll some data
   */
  pollData: () => Promise<Data>;
  /**
   * Should hook start polling
   */
  isPolling?: boolean;
  /**
   * Default value for should hook start polling
   */
  defaultIsPolling?: boolean;
  /**
   * Called, when isPolling is changed
   */
  onIsPollingChange?: (newIsPolling: boolean) => void;
  /**
   * Predicate to define, should we stop polling
   */
  getShouldStop?: (currentPolledData: Data) => boolean;
  /**
   * Called, when condition in met in the getShouldStop
   */
  onShouldStopConditionMet?: (polledData: Data) => void;
}

export const usePolling = <Data>({
  pollData,
  isPolling: isPollingProp,
  onIsPollingChange,
  defaultIsPolling = false,
  getShouldStop = R.always(false),
  onShouldStopConditionMet,
}: UsePollingProps<Data>) => {
  const [isPolling, setIsPolling] = useControllableState(
    isPollingProp,
    onIsPollingChange,
    defaultIsPolling
  );

  useEffect(() => {
    if (!isPolling) {
      return () => {};
    }

    const intervalId = setInterval(
      () =>
        pollData().then(data => {
          if (getShouldStop(data)) {
            onShouldStopConditionMet?.(data);
            setIsPolling(false);
            clearInterval(intervalId);
          }
        }),
      POLLING_INTERVAL_MS
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [isPolling]);

  return { isPolling, setIsPolling };
};
