import R from 'ramda';

import {
  CreateEmployeeMutationOptions,
  useCreateEmployeeMutation,
} from '../gql/mutations/createEmployee.graphql';
import {
  DeleteEmployeeMutationOptions,
  useDeleteEmployeeMutation,
} from '../gql/mutations/deleteEmployee.graphql';
import { useUpdateEmployeeMutation } from '../gql/mutations/updateEmployee.graphql';
import { updateEmployeeFragment } from '../helpers';
import { EmployeeFormType } from '../types';

const getEmployeeInputFromForm = (form: EmployeeFormType) =>
  R.omit(['id'], form);

/**
 * Hook for CRUD actions on a employee
 */
export const useEmployeesCRUD = () => {
  // Employee create logic
  const [createEmployeeMutation] = useCreateEmployeeMutation();

  const createEmployee = (
    companyID: string,
    form: EmployeeFormType,
    mutationOptions?: Partial<CreateEmployeeMutationOptions>
  ) =>
    createEmployeeMutation({
      variables: {
        input: {
          ...getEmployeeInputFromForm(form),
          companyID,
        },
      },
      ...mutationOptions,
    });

  // Employee update logic
  const [updateEmployeeMutation] = useUpdateEmployeeMutation();

  const updateEmployee = (form: EmployeeFormType) =>
    updateEmployeeMutation({
      variables: {
        id: form.id,
        input: getEmployeeInputFromForm(form),
      },
      optimisticResponse: {
        updateEmployee: null,
      },
      update: updateEmployeeFragment(form.id, draft => {
        draft.firstName = form.firstName;
        draft.lastName = form.lastName;
        draft.middleName = form.middleName;
        draft.comment = form.comment;
        draft.number = form.number;
      }),
    });

  // Employee delete logic
  const [deleteEmployeeMutation] = useDeleteEmployeeMutation();
  const deleteEmployee = (
    farmId: string,
    mutationOptions?: Partial<DeleteEmployeeMutationOptions>
  ) =>
    deleteEmployeeMutation({
      variables: {
        id: farmId,
      },
      optimisticResponse: { deleteEmployee: null },
      ...mutationOptions,
    });

  return {
    createEmployee,
    updateEmployee,
    deleteEmployee,
  };
};
