import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BlueprintDetailedFragment = { __typename: 'Blueprint', id: string, name: string, description: string, sourceSections: Array<{ __typename: 'SourceSection', id: string, verboseName: string, fields: Array<{ __typename: 'SourceField', id: string, name: string }> }> };

export const BlueprintDetailedFragmentDoc = gql`
    fragment BlueprintDetailed on Blueprint {
  __typename
  id
  name
  description
  sourceSections {
    __typename
    id
    verboseName
    fields {
      __typename
      id
      name
    }
  }
}
    `;