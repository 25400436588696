import * as Types from '../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowMilkParlorStatsRowFragment = { __typename: 'CowMilkParlorStatsRow', name: string, expectedWeightKilograms: number, deviationWeightKilograms: number, averageWeightKilograms: number, dates: Array<{ __typename?: 'CowMilkParlorStatsOnDate', date: string, weightKilograms?: number | null, kind: Types.MilkParlorStatsDateKind }> };

export const CowMilkParlorStatsRowFragmentDoc = gql`
    fragment CowMilkParlorStatsRow on CowMilkParlorStatsRow {
  __typename
  name
  expectedWeightKilograms
  deviationWeightKilograms
  averageWeightKilograms
  dates {
    date
    weightKilograms
    kind
  }
}
    `;