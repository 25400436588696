import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomMilkingReportFragmentDoc } from '../fragments/customMilkingReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomMilkingReportsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  roleIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CustomMilkingReportsQuery = { __typename?: 'Query', customMilkingReports: { __typename?: 'CustomMilkingReportConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CustomMilkingReportEdge', cursor: string, node: { __typename: 'CustomMilkingReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } }> } };


export const CustomMilkingReportsDocument = gql`
    query customMilkingReports($first: Int, $after: String, $ids: [ID!], $roleIDs: [ID!], $search: String) {
  customMilkingReports(
    first: $first
    after: $after
    ids: $ids
    roleIDs: $roleIDs
    search: $search
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CustomMilkingReport
      }
    }
  }
}
    ${CustomMilkingReportFragmentDoc}`;

/**
 * __useCustomMilkingReportsQuery__
 *
 * To run a query within a React component, call `useCustomMilkingReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomMilkingReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomMilkingReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      ids: // value for 'ids'
 *      roleIDs: // value for 'roleIDs'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCustomMilkingReportsQuery(baseOptions?: Apollo.QueryHookOptions<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>(CustomMilkingReportsDocument, options);
      }
export function useCustomMilkingReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>(CustomMilkingReportsDocument, options);
        }
export function useCustomMilkingReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>(CustomMilkingReportsDocument, options);
        }
export type CustomMilkingReportsQueryHookResult = ReturnType<typeof useCustomMilkingReportsQuery>;
export type CustomMilkingReportsLazyQueryHookResult = ReturnType<typeof useCustomMilkingReportsLazyQuery>;
export type CustomMilkingReportsSuspenseQueryHookResult = ReturnType<typeof useCustomMilkingReportsSuspenseQuery>;
export type CustomMilkingReportsQueryResult = Apollo.QueryResult<CustomMilkingReportsQuery, CustomMilkingReportsQueryVariables>;