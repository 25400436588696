import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintCycleInputNode,
  BlueprintEditService,
  BlueprintInputNode,
  BlueprintNodeType,
} from '../../../../services';
import { CycleInputNodeUI } from '../CycleInputNodeUI';

interface Props {
  cycleInput: BlueprintCycleInputNode;
}

export const CycleInputNode: React.FC<Props> = ({ cycleInput }) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const [inputs, setInputs] = useState<BlueprintInputNode[]>([]);

  useEffect(() => {
    const inputsSubscription = bpEditSvc.blueprint?.inputNodes$.subscribe(
      newInputs => {
        setInputs(
          newInputs.filter(input => input.cycleInputId === cycleInput.id)
        );
      }
    );
    return () => {
      inputsSubscription?.unsubscribe();
    };
  }, []);

  const memoizedDel = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc.deleteNode]);

  const { errors, loading, reload: deleteNode } = useApiData(memoizedDel);

  const delCallback = useCallback(() => {
    deleteNode({
      nodeId: cycleInput.id,
      nodeType: BlueprintNodeType.CycleInputs,
    });
  }, [deleteNode]);

  return (
    <CycleInputNodeUI
      delete={delCallback}
      errors={errors}
      loading={loading}
      cycleInput={cycleInput}
      inputs={inputs}
    />
  );
};
