import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type MilkingParlorMilkingMistakesReportRowEntryFragment = { __typename: 'MilkingParlorMilkingMistakesReportRowEntry', reattachCount: number, noMilkCount: number, enteredTwiceCount: number, noLetdownCount: number, earlyFalloffCount: number, lateRehangCount: number, manualDetachCount: number, manualModeCount: number, notIdentifiedCount: number, dryCount: number, totalCount: number, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number } };

export const MilkingParlorMilkingMistakesReportRowEntryFragmentDoc = gql`
    fragment MilkingParlorMilkingMistakesReportRowEntry on MilkingParlorMilkingMistakesReportRowEntry {
  __typename
  penGroup {
    ...PenGroupShort
  }
  reattachCount
  noMilkCount
  enteredTwiceCount
  noLetdownCount
  earlyFalloffCount
  lateRehangCount
  manualDetachCount
  manualModeCount
  notIdentifiedCount
  dryCount
  totalCount
}
    ${PenGroupShortFragmentDoc}`;