import React from 'react';

import * as yup from 'yup';

import { MInput } from '~/shared/components/MInput';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import {
  CreateMonitorGroupMutation,
  useCreateMonitorGroupMutation,
} from '../../gql/mutations/createMonitorGroup.graphql';

export interface CreateMonitorGroupModalProps
  extends InjectedModalProps<CreateMonitorGroupModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Called, when a group is submitted and successfully created
   */
  onSubmit?: (
    newGroup: CreateMonitorGroupMutation['createMonitorGroup']
  ) => void;
}

const FORM_ID = 'CreateMonitorGroupForm';

const SCHEMA = yup.object({
  name: yup.string().required(),
});

type CreateMonitorGroupFormType = InferValidatedSchema<typeof SCHEMA>;

export const CreateMonitorGroupModal: React.FC<
  CreateMonitorGroupModalProps
> = ({ className, onSubmit, close }) => {
  const formContext = useForm<CreateMonitorGroupFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const [createMonitorGroup, { loading: isLoading }] =
    useCreateMonitorGroupMutation();

  const handleSubmit = (form: CreateMonitorGroupFormType) => {
    createMonitorGroup({
      variables: {
        input: form,
      },
      refetchQueries: ['monitorGroups'],
    })
      .then(data => {
        if (!data.data) return;

        onSubmit?.(data.data.createMonitorGroup);
        close();
      })
      .catch(() => {});
  };

  return (
    <Modal
      {...{
        className,
        title: 'Создание новой группы',
        submitButtonProps: {
          children: 'Создать',
          form: FORM_ID,
          isLoading,
        },
      }}
    >
      <Form
        formContext={formContext}
        id={FORM_ID}
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <MInput
          {...{
            name: 'name',
            label: 'Название группы',
            placeholder: 'Введите название группы',
            isRequired: true,
          }}
        />
      </Form>
    </Modal>
  );
};
