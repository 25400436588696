import { FemaleAnimalKind, ReproductionYAxisMode } from '@graphql-types';
import { ChartOptions } from 'chart.js';

import {
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
} from '~/features/charts';

/**
 * Domain specific constant for HDR, PR AND CR calculation periods
 */
export const REPRODUCTION_CALCULATION_PERIOD_DAYS = 21;

/**
 * Domain specific constant for cows waiting period
 */
export const DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS = 50;

/**
 * Domain specific constant for heifers waiting period
 */
export const DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS = 365;

/**
 * Dict with domain specific female animal kinds
 */
export const FEMALE_ANIMAL_KINDS_DICT: Record<FemaleAnimalKind, string> = {
  [FemaleAnimalKind.Cow]: 'Коровы',
  [FemaleAnimalKind.Heifer]: 'Тёлки',
};

/**
 * Dict with y axis modes, used in reproduction
 */
export const REPRODUCTION_Y_AXIS_MODE_DICT: Record<
  ReproductionYAxisMode,
  string
> = {
  [ReproductionYAxisMode.ClosestToMax]: 'Ближайшее к максимальному',
  [ReproductionYAxisMode.Percent_100]: '100%',
};

/**
 * Options used to render both CR and cross CR charts
 */
export const REPRODUCTION_CHART_OPTIONS = {
  interaction: {
    mode: 'index',
  },
  scales: {
    x: {
      type: 'category',
      title: {
        display: true,
      },
      grid: {
        display: true,
        offset: false,
      },
    },
  },
  plugins: {
    zoom: {
      limits: {
        x: {
          min: 'original',
          max: 'original',
          minRange: CHART_X_SCALE_MIN_RANGE,
        },
        y: {
          min: 'original',
          max: 'original',
          minRange: CHART_Y_SCALE_MIN_RANGE,
        },
      },
      zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
      pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
    },
  },
} satisfies ChartOptions;
