import { ApolloCache, ApolloClient } from '@apollo/client';

import { AnyFragment } from '../..';
import {
  makeGetFragmentCacheOptions,
  MakeGetFragmentCacheOptionsProps,
} from './makeGetFragmentCacheOptions';

/**
 * Factory for making apollo read fragment function
 */
export const makeReadFragment = <Fragment extends AnyFragment>(
  makeGetFragmentCacheOptionsProps: MakeGetFragmentCacheOptionsProps
) => {
  const getFragmentCacheOptions = makeGetFragmentCacheOptions(
    makeGetFragmentCacheOptionsProps
  );

  return (
    clientOrCache: ApolloClient<unknown> | ApolloCache<unknown>,
    id?: string | null
  ) => {
    if (!id) return null;

    const cache =
      'cache' in clientOrCache ? clientOrCache.cache : clientOrCache;

    const fragmentCacheOptions = getFragmentCacheOptions(cache, id);

    return cache.readFragment<Fragment>(fragmentCacheOptions);
  };
};
