import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OperationResult } from '~/~legacy/hooks/useApiData';
import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import TOKENS from '~/styles/__generated__/tokens.json';

import { BlueprintInputNode } from '../../../../services';
import { InputNodeEditForm } from '../../../InputNodeEditForm';
import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';
import { InputNodeViewForm } from '../InputNodeViewForm';

interface Props extends ApiFeedbackProps {
  input: BlueprintInputNode;

  delete: (nodeId: string) => void;
  save: (
    input: BlueprintInputNode
  ) => Promise<OperationResult<BlueprintInputNode>>;
}

export const InputNodeUI: React.FC<Props> = ({
  input,
  errors,
  loading,
  delete: deleteAction,
  save,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);
  const [edit, setEdit] = useState(false);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const toggle = useCallback(() => {
    setEdit(prev => !prev);
  }, [setEdit]);

  const submitCallback = useCallback(
    async (data: BlueprintInputNode) => {
      const result = await save(data);
      if (result.success) {
        setEdit(false);
      }
    },
    [save]
  );

  const delCallback = useCallback(() => {
    deleteAction(input.id || '');
  }, [deleteAction, input.id]);

  const content = edit ? (
    <InputNodeEditForm input={input} submit={submitCallback} cancel={toggle} />
  ) : (
    <InputNodeViewForm input={input} toggle={toggle} />
  );

  return (
    <NodeFrame
      headerStyle={{
        background: TOKENS.colorNeutral150,
      }}
      header={
        <NodeHeader
          title={t(IntegratorStrings.blueprintEdit.nodes.userInputNode.title)}
          delete={delCallback}
        />
      }
    >
      {content}
      {loader}
      {errorMessage}
    </NodeFrame>
  );
};
