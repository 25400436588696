import React from 'react';

import { MControlsPanel } from '~/shared/components/MControlsPanel';

export function useControls(
  hideControls: boolean | undefined,
  toggle: () => void,
  canSubmit = true,
  className = 'my-8'
) {
  const controls = hideControls ? null : (
    <MControlsPanel
      className={className}
      cancel={toggle}
      canSubmit={canSubmit}
    />
  );

  return controls;
}
