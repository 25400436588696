import React from 'react';

export const Cow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.85446 2.95234C6.09392 5.69278 8.99457 7.12501 12 7.12501C15.0054 7.12501 17.9061 5.69278 19.1455 2.95234C19.4736 2.22698 20.5764 2.3302 20.7414 3.11923C20.9292 4.01741 21.2972 6.7934 19.3949 9.3997C20.5242 10.4269 21.6974 11.807 21.7484 13.4027C21.7609 13.7934 21.4999 14.1563 21.0979 14.2492C20.7552 14.3284 19.7347 14.4818 18.5576 13.9163C18.5313 15.2927 18.4643 17.3415 18.2652 18.6677C18.1083 19.713 17.6995 20.5706 16.6911 21.0483C15.8365 21.4532 14.4248 21.75 12 21.75C9.57832 21.75 8.17152 21.4633 7.31902 21.0637C6.30541 20.5885 5.88039 19.702 5.72682 18.6507C5.5336 17.328 5.46857 15.289 5.44234 13.9163C4.26521 14.4818 3.24476 14.3284 2.90201 14.2492C2.50002 14.1563 2.23904 13.7934 2.25153 13.4027C2.30252 11.807 3.47579 10.4269 4.60511 9.39969C2.70274 6.79339 3.07083 4.0174 3.25861 3.11922C3.42358 2.33019 4.52639 2.22698 4.85446 2.95234ZM4.63339 5.40293C4.73909 6.45115 5.11811 7.73205 6.14481 8.92949C6.44052 9.27439 6.43042 9.83229 6.03237 10.1509C5.16485 10.8454 4.26396 11.7522 3.88069 12.8202C4.31052 12.7803 4.90169 12.6117 5.51581 12.0858C6.02985 11.6457 6.92278 11.9517 6.92729 12.727C6.93058 13.2925 6.9465 14.7989 7.0223 16.25H16.9776C17.0534 14.7989 17.0694 13.2925 17.0726 12.727C17.0772 11.9517 17.9701 11.6457 18.4841 12.0858C19.0982 12.6117 19.6894 12.7803 20.1193 12.8202C19.736 11.7522 18.8351 10.8454 17.9676 10.1509C17.5696 9.83228 17.5595 9.27438 17.8552 8.92949C18.8819 7.73206 19.2609 6.45117 19.3666 5.40295C17.5615 7.57218 14.7472 8.62501 12 8.62501C9.25282 8.62501 6.43853 7.57218 4.63339 5.40293ZM16.8679 17.75H7.1298C7.20534 18.5224 7.17348 19.3388 7.95576 19.7055C8.51164 19.9661 9.65691 20.25 12 20.25C14.3398 20.25 15.4901 19.9575 16.0488 19.6928C16.825 19.325 16.7887 18.5221 16.8679 17.75Z"
      fill="currentColor"
    />
  </svg>
);
