import React from 'react';

import { CalfLifeState } from '~/shared/graphql';

import { FreshActionCalvingData } from '../../reducer';
import { Gender } from '../../types';
import styles from './index.module.scss';

interface Props {
  calving: FreshActionCalvingData;
}

export const CollapsedFreshActionCalvingForm: React.FC<Props> = ({
  calving,
}) => {
  const bulls = calving.calfs.filter(
    item => item.gender === Gender.male
  ).length;
  const cows = calving.calfs.filter(
    item => item.gender === Gender.female
  ).length;
  const dead = calving.calfs.filter(
    item => item.state === CalfLifeState.Dead
  ).length;

  return (
    <div className={styles.root}>
      <span>Животное № {calving.cow?.id}</span>
      <span>{bulls} телят,</span>
      <span>{cows} тёлочек,</span>
      <span>{dead} мёртвых</span>
    </div>
  );
};
