import { useEffect, useRef } from 'react';

/**
 * Always return previous value of the passed one
 */
export const usePrevious = <T extends any>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
