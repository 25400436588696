import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { ReproductionCrReportFragment } from '../gql/fragments/reproductionCrReport.graphql';
import {
  ReproductionCrReportsQuery,
  ReproductionCrReportsQueryVariables,
  useReproductionCrReportsQuery,
} from '../gql/queries/reproductionCrReports.graphql';

export const useReproductionCrReportsPaginatedQuery = makeUsePaginatedQuery<
  ReproductionCrReportFragment,
  ReproductionCrReportsQuery,
  ReproductionCrReportsQueryVariables
>({
  useQuery: useReproductionCrReportsQuery,
  getItemsFromQueryData: data =>
    data.reproductionCrReports.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.reproductionCrReports.pageInfo,
});
