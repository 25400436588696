import { DefaultEventEnum, EventKindEnum } from '@graphql-types';

import { CowEventFragment } from './gql/fragments/cowEvent.graphql';

/**
 * We have special categorization for events that differs from backend entities
 * Some of the kinds are a default event shortcode and some are event kind.
 */
export enum CowEventCategories {
  insemination = 'insemination',
  disease = 'disease',
  dry = 'dry',
  death = 'death',
  calving = 'calving',
  other = 'other',
  protocol = 'protocol',
}

/**
 * We have special categorization for events that differs from backend entities,
 * so we have a config for each event.
 * Shortcodes and kinds define, which event shortcodes or kinds will be included in the category
 */
export interface CowEventConfig {
  name: string;
  color: string;
  shortcodes?: DefaultEventEnum[];
  kinds?: EventKindEnum[];
}

/**
 * Normalized event data for displaying on the chart
 */
export interface CowNormalizedEvent {
  name: string;
  kind: EventKindEnum;
  category: CowEventCategories;
  config: CowEventConfig;
  originalCowEvent: CowEventFragment;
  lactationNumber?: number | null;
}
