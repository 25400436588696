import { useEffect, useRef } from 'react';

/**
 * Hook for getting a ref that will be scrolled into view on open
 */
export const useScrollIntoViewRef = <T extends HTMLElement = HTMLLIElement>(
  isOpen = true
) => {
  const itemToScrollRef = useRef<T>(null);

  // Scroll selected item into view
  useEffect(() => {
    if (!isOpen) return undefined;

    // Timeout is needed, so fixed element can be calculated correctly
    const timeout = setTimeout(() => {
      if (!itemToScrollRef.current) {
        return;
      }
      const scrollOptions = {
        block: 'center',
        scrollMode: 'if-needed',
      } as const;
      if ('scrollIntoViewIfNeeded' in itemToScrollRef.current) {
        (itemToScrollRef.current as any).scrollIntoViewIfNeeded(scrollOptions);
      } else {
        itemToScrollRef.current?.scrollIntoView(scrollOptions);
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, [isOpen]);

  return itemToScrollRef;
};
