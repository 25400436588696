import { CustomMilkingReportYAxisFieldGrouping } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_GROUPINGS_DICT } from '../../../../constants';

const useCustomMilkingReportYAxisFieldGroupingSelect = makeUseEnumSelect(
  CustomMilkingReportYAxisFieldGrouping,
  enumValue =>
    CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_GROUPINGS_DICT[
      enumValue as CustomMilkingReportYAxisFieldGrouping
    ]
);

export const CustomMilkingReportYAxisFieldGroupingSelect =
  makeSelectComponentFromHook(useCustomMilkingReportYAxisFieldGroupingSelect);
