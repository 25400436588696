import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInjectionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateInjectionInput;
}>;


export type UpdateInjectionMutation = { __typename?: 'Mutation', updateInjection?: any | null };


export const UpdateInjectionDocument = gql`
    mutation updateInjection($id: ID!, $input: UpdateInjectionInput!) {
  updateInjection(id: $id, input: $input)
}
    `;
export type UpdateInjectionMutationFn = Apollo.MutationFunction<UpdateInjectionMutation, UpdateInjectionMutationVariables>;

/**
 * __useUpdateInjectionMutation__
 *
 * To run a mutation, you first call `useUpdateInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInjectionMutation, { data, loading, error }] = useUpdateInjectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInjectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInjectionMutation, UpdateInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInjectionMutation, UpdateInjectionMutationVariables>(UpdateInjectionDocument, options);
      }
export type UpdateInjectionMutationHookResult = ReturnType<typeof useUpdateInjectionMutation>;
export type UpdateInjectionMutationResult = Apollo.MutationResult<UpdateInjectionMutation>;
export type UpdateInjectionMutationOptions = Apollo.BaseMutationOptions<UpdateInjectionMutation, UpdateInjectionMutationVariables>;