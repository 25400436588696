import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { LivestockForecastSettingFragmentDoc } from '../fragments/livestockForecastSetting.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LivestockForecastSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LivestockForecastSettingsQuery = { __typename?: 'Query', livestockForecastSettings: Array<{ __typename: 'LivestockForecastSetting', id: string, dryPeriod?: number | null, dryPeriodCalc: number, freshPeriod?: number | null, freshPeriodCalc: number, cowsVoluntaryWaitingPeriod?: number | null, cowsVoluntaryWaitingPeriodCalc: number, heifersVoluntaryWaitingPeriod?: number | null, heifersVoluntaryWaitingPeriodCalc: number, cowsPregnancyRate?: number | null, cowsPregnancyRateCalc: number, heifersPregnancyRate?: number | null, heifersPregnancyRateCalc: number, numberOfCyclesCalc: number, cowsLeftInFirstLactRate?: number | null, cowsLeftInFirstLactRateCalc: number, cowsLeftInOtherLactRate?: number | null, cowsLeftInOtherLactRateCalc: number, bullsLeftZeroToTwoMonthRate?: number | null, bullsLeftZeroToTwoMonthRateCalc: number, bullsLeftThreeToSixMonthRate?: number | null, bullsLeftThreeToSixMonthRateCalc: number, bullsLeftSevenToTwelveMonthRate?: number | null, bullsLeftSevenToTwelveMonthRateCalc: number, heifersLeftZeroToTwoMonthRate?: number | null, heifersLeftZeroToTwoMonthRateCalc: number, heifersLeftThreeToSixMonthRate?: number | null, heifersLeftThreeToSixMonthRateCalc: number, heifersLeftSevenToTwelveMonthRate?: number | null, heifersLeftSevenToTwelveMonthRateCalc: number, heifersInFirstLactRate?: number | null, heifersInFirstLactRateCalc: number, heifersInOtherLactRate?: number | null, heifersInOtherLactRateCalc: number, stillbirthsInFirstLactRate?: number | null, stillbirthsInFirstLactRateCalc: number, stillbirthsInOtherLactRate?: number | null, stillbirthsInOtherLactRateCalc: number, farm: { __typename: 'Farm', id: string, name: string, number: string } }> };


export const LivestockForecastSettingsDocument = gql`
    query livestockForecastSettings {
  livestockForecastSettings {
    ...LivestockForecastSetting
  }
}
    ${LivestockForecastSettingFragmentDoc}`;

/**
 * __useLivestockForecastSettingsQuery__
 *
 * To run a query within a React component, call `useLivestockForecastSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivestockForecastSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivestockForecastSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLivestockForecastSettingsQuery(baseOptions?: Apollo.QueryHookOptions<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>(LivestockForecastSettingsDocument, options);
      }
export function useLivestockForecastSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>(LivestockForecastSettingsDocument, options);
        }
export function useLivestockForecastSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>(LivestockForecastSettingsDocument, options);
        }
export type LivestockForecastSettingsQueryHookResult = ReturnType<typeof useLivestockForecastSettingsQuery>;
export type LivestockForecastSettingsLazyQueryHookResult = ReturnType<typeof useLivestockForecastSettingsLazyQuery>;
export type LivestockForecastSettingsSuspenseQueryHookResult = ReturnType<typeof useLivestockForecastSettingsSuspenseQuery>;
export type LivestockForecastSettingsQueryResult = Apollo.QueryResult<LivestockForecastSettingsQuery, LivestockForecastSettingsQueryVariables>;