import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  companyID: Types.Scalars['ID']['input'];
  input: Types.UpdateCompanyUserInput;
}>;


export type UpdateCompanyUserMutation = { __typename?: 'Mutation', updateCompanyUser?: any | null };


export const UpdateCompanyUserDocument = gql`
    mutation updateCompanyUser($id: ID!, $companyID: ID!, $input: UpdateCompanyUserInput!) {
  updateCompanyUser(id: $id, companyID: $companyID, input: $input)
}
    `;
export type UpdateCompanyUserMutationFn = Apollo.MutationFunction<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;

/**
 * __useUpdateCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserMutation, { data, loading, error }] = useUpdateCompanyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyID: // value for 'companyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>(UpdateCompanyUserDocument, options);
      }
export type UpdateCompanyUserMutationHookResult = ReturnType<typeof useUpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationResult = Apollo.MutationResult<UpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;