import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { MControlsPanel } from '~/shared/components/MControlsPanel';
import { MInput } from '~/shared/components/MInput';

import { BlueprintInputNode } from '../../services';

interface Props {
  input?: BlueprintInputNode;

  submit: (input: BlueprintInputNode) => void;
  cancel: () => void;
}

export const InputNodeEditForm: React.FC<Props> = ({
  input,
  cancel,
  submit,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const [state, setState] = useState<BlueprintInputNode>({
    id: '',
    name: '',
    prompt: '',
    ...input,
  });

  const nameChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState(prev => {
        return {
          ...prev,
          name: e.target.value,
        };
      });
    },
    [setState]
  );

  const promptChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState(prev => {
        return {
          ...prev,
          prompt: e.target.value,
        };
      });
    },
    [setState]
  );

  const submitCallback = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submit(state);
      return false;
    },
    [state, submit]
  );

  return (
    <form onSubmit={submitCallback}>
      <MInput
        name="name"
        label={t(
          IntegratorStrings.blueprintEdit.nodes.userInputNode.variableName
        )}
        className="m-8"
        value={state.name}
        onChange={nameChangeCallback}
      />
      <MInput
        name="prompt"
        label={t(IntegratorStrings.blueprintEdit.nodes.userInputNode.prompt)}
        className="m-8"
        value={state.prompt}
        onChange={promptChangeCallback}
      />
      <MControlsPanel
        className="m-8"
        canSubmit={Boolean(true)}
        cancel={cancel}
      />
    </form>
  );
};
