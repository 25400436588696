import { useMemo } from 'react';

import { capitalize } from '~/shared/helpers/string';

import { useNotificationsContext } from '../context';
import {
  NotificationKinds,
  NotificationPropsByKind,
  NotificationVariants,
} from '../types';

/**
 * Hook for using notifications service for sending alerts and toasts to user
 */
export const useNotifications = () => {
  const { sendNotification, notificationsSubject$ } = useNotificationsContext();

  const makeSendNotification =
    <Kind extends NotificationKinds>(kind: Kind) =>
    (variant: NotificationVariants) =>
    (
      propsOrMessage: string | Omit<NotificationPropsByKind<Kind>, 'variant'>
    ) => {
      const props =
        typeof propsOrMessage === 'string'
          ? {
              message: propsOrMessage,
            }
          : propsOrMessage;

      sendNotification(kind, { variant, ...props });
    };

  const makeSendAlert = makeSendNotification(NotificationKinds.alert);
  const makeSendToast = makeSendNotification(NotificationKinds.toast);

  const sendAlertHelpers = useMemo(
    () =>
      Object.fromEntries(
        Object.values(NotificationVariants).map(variant => [
          `send${capitalize(variant)}Alert`,
          makeSendAlert(variant),
        ])
      ) as Record<
        `send${Capitalize<NotificationVariants>}Alert`,
        ReturnType<typeof makeSendAlert>
      >,
    [notificationsSubject$]
  );

  const sendToastHelpers = useMemo(
    () =>
      Object.fromEntries(
        Object.values(NotificationVariants).map(variant => [
          `send${capitalize(variant)}Toast`,
          makeSendToast(variant),
        ])
      ) as Record<
        `send${Capitalize<NotificationVariants>}Toast`,
        ReturnType<typeof makeSendToast>
      >,
    [notificationsSubject$]
  );

  return {
    ...sendAlertHelpers,
    ...sendToastHelpers,
  };
};
