import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { TestMilkingFragment } from '../gql/fragments/testMilking.graphql';
import {
  TestMilkingsQuery,
  TestMilkingsQueryVariables,
  useTestMilkingsQuery,
} from '../gql/queries/testMilkings.graphql';

export const useTestMilkingsPaginatedQuery = makeUsePaginatedQuery<
  TestMilkingFragment,
  TestMilkingsQuery,
  TestMilkingsQueryVariables
>({
  useQuery: useTestMilkingsQuery,
  getItemsFromQueryData: data => data.testMilkings.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.testMilkings.pageInfo,
});
