import {
  BlueprintExecutionInfo,
  BlueprintExecutionResult,
} from '~/~legacy/services/BlueprintExecutionService';

export enum BlueprintExecutionSteps {
  Loading = 'Loading',
  Inputs = 'Inputs',
  SpecialInput = 'SpecialInput',
  Results = 'Results',
}

interface BlueprintExecutorState {
  step: BlueprintExecutionSteps;
  companyId: string;
  executionInfo?: BlueprintExecutionInfo;
  executionResult?: BlueprintExecutionResult;
}

export const defaultBlueprintExecutorState: BlueprintExecutorState = {
  step: BlueprintExecutionSteps.Loading,
  companyId: '',
};

export enum BlueprintExecutorActionTypes {
  InfoLoaded = 'InfoLoaded',
  InputsSubmitted = 'InputsSubmitted',
  Executed = 'Executed',
}

interface BlueprintExecutorAction<T> {
  type: BlueprintExecutorActionTypes;
  data?: T;
}

type BlueprintExecutorActionFunction<T> = (
  state: BlueprintExecutorState,
  action: BlueprintExecutorAction<T>
) => BlueprintExecutorState;

const defaultReaction: BlueprintExecutorActionFunction<void> = state => {
  return state;
};

const infoLoadedReaction: BlueprintExecutorActionFunction<
  BlueprintExecutionInfo
> = (state, action) => {
  const step = action.data?.specialInput
    ? BlueprintExecutionSteps.SpecialInput
    : BlueprintExecutionSteps.Inputs;

  const newState: BlueprintExecutorState = {
    ...state,
    step,
    executionInfo: action.data,
  };
  return newState;
};

const inputsSubmittedReaction: BlueprintExecutorActionFunction<
  BlueprintExecutionInfo
> = (state, action) => {
  const newState: BlueprintExecutorState = {
    ...state,
    executionInfo: action.data,
  };
  return newState;
};

const blueprintExecutedReaction: BlueprintExecutorActionFunction<
  BlueprintExecutionResult
> = (state, action) => {
  const newState: BlueprintExecutorState = {
    ...state,
    step: BlueprintExecutionSteps.Results,
    executionResult: action.data,
  };
  return newState;
};

const actionFunctionsMap = {
  [BlueprintExecutorActionTypes.InfoLoaded]: infoLoadedReaction,
  [BlueprintExecutorActionTypes.InputsSubmitted]: inputsSubmittedReaction,
  [BlueprintExecutorActionTypes.Executed]: blueprintExecutedReaction,
} as Record<BlueprintExecutorActionTypes, BlueprintExecutorActionFunction<any>>;

export function blueprintExecutorReducer(
  state: BlueprintExecutorState,
  action: BlueprintExecutorAction<any>
) {
  const reaction = actionFunctionsMap[action.type] || defaultReaction;
  const newState = reaction(state, action);
  return newState;
}
