import { ApolloClient, InMemoryCache } from '@apollo/client';

import fragmentMatcher from '../../__generated__/fragment-matcher.json';
import { makeMainLink, MakeMainLinkProps } from './makeMainLink';
import { typePolicies } from './typePolicies';

/**
 * Creates apollo client
 */
export const makeApolloClient = (props: MakeMainLinkProps) => {
  return new ApolloClient({
    connectToDevTools:
      process.env.REACT_APP_SHOULD_APOLLO_CONNECT_TO_DEV_TOOLS === 'true',
    link: makeMainLink(props),
    cache: new InMemoryCache({
      addTypename: false,
      resultCaching: true,
      typePolicies,
      possibleTypes: fragmentMatcher.possibleTypes,
    }),
  });
};
