import { CustomReportBlueprintLaunchResultFilterInput } from '@graphql-types';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { makeIdGetter } from '~/shared/helpers/string';

import { getNumberPartFromGlobalId, omitTypename } from '~/services/gql';

import { CustomReportBlueprintSourceFieldValueFragment } from '~/entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';
import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';
import { CustomReportSettingsColumnFragment } from '~/entities/customReports/gql/fragments/customReportSettingsColumn.graphql';
import { CustomReportSettingsRowFragment } from '~/entities/customReports/gql/fragments/customReportSettingsRow.graphql';

import {
  AnyCustomReportColumn,
  isCustomReportHeaderColumn,
  mapCustomReportValueKeyToForm,
} from '~/features/customReportLaunch';
import { CustomReportColumnFragment } from '~/features/customReportLaunch/gql/fragments/customReportColumn.graphql';
import { CustomReportFormulaColumnFragment } from '~/features/customReportLaunch/gql/fragments/customReportFormulaColumn.graphql';
import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';
import { CustomReportNestedRowFragment } from '~/features/customReportLaunch/gql/fragments/customReportNestedRow.graphql';

import {
  AnyCustomReportRow,
  CustomReportLunchDetailFilterForUrl,
  CustomReportPivotCellDataWithRows,
  CustomReportPivotSettingsFormType,
} from './types';

/**
 * Checks, if pivot table is in dirty state (means blueprint errors)
 */
export const isDirtyPivotTable = (
  pivotTable:
    | CustomReportLaunchResultFragment['pivotTable']
    | SkeletonPlaceholder
): pivotTable is Extract<
  CustomReportLaunchResultFragment['pivotTable'],
  { __typename: 'CustomReportPivotTableDirty' }
> => pivotTable.__typename === 'CustomReportPivotTableDirty';

/**
 * Checks, if pivot table is in empty state (means no blueprint data)
 */
export const isEmptyPivotTable = (
  pivotTable:
    | CustomReportLaunchResultFragment['pivotTable']
    | SkeletonPlaceholder
): pivotTable is Extract<
  CustomReportLaunchResultFragment['pivotTable'],
  { __typename: 'CustomReportPivotTableEmpty' }
> => pivotTable.__typename === 'CustomReportPivotTableEmpty';

/**
 * If we got null for source field value, we know it is total column or row by contract
 */
export const isTotalSourceFieldValue = (
  sourceFieldValue:
    | CustomReportBlueprintSourceFieldValueFragment
    | undefined
    | null
): sourceFieldValue is null | undefined => R.isNil(sourceFieldValue);

/**
 * Checks, if the pivot table column is a general column
 */
export const isCustomReportColumn = (
  column: AnyCustomReportColumn
): column is CustomReportColumnFragment =>
  column.__typename === 'CustomReportColumn';

/**
 * Checks, if the pivot table column is a formula column
 */
export const isCustomReportFormulaColumn = (
  column: AnyCustomReportColumn
): column is CustomReportFormulaColumnFragment =>
  column.__typename === 'CustomReportFormulaColumn';

/**
 * Checks, if the pivot table row is with nested rows
 */
export const isCustomReportNestedRow = (
  row: AnyCustomReportRow
): row is CustomReportNestedRowFragment =>
  row.__typename === 'CustomReportNestedRow';

const mapRowOrColumnFragmentToForm = (
  config: CustomReportSettingsRowFragment | CustomReportSettingsColumnFragment
) => ({
  order: config.order,
  sortBy: {
    kind: config.sortBy.kind,
    sortingValue: config.sortBy.sortingValue
      ? {
          blueprintSourceFieldValue: config.sortBy.sortingValue
            .blueprintSourceFieldValue
            ? omitTypename(config.sortBy.sortingValue.blueprintSourceFieldValue)
            : null,
          valueKey: mapCustomReportValueKeyToForm(
            config.sortBy.sortingValue.valueKey
          ),
        }
      : null,
  },
  withTotal: config.withTotal,
  blueprintSourceFieldID: config.blueprintSourceField.id,
});

/**
 * Maps custom report fragment into settings form fields representation
 */
export const mapCustomReportSettingsToForm = (
  customReport: CustomReportDetailedFragment | SkeletonPlaceholder
): CustomReportPivotSettingsFormType => ({
  rows: customReport.settings?.rows.map(mapRowOrColumnFragmentToForm) ?? [],
  columns:
    customReport.settings?.columns.map(mapRowOrColumnFragmentToForm) ?? [],
  values:
    customReport.settings?.values.map(mapCustomReportValueKeyToForm) ?? [],
});

/**
 * Gets a single minified object for a custom report detail filter
 */
const getCustomReportLunchDetailSingleFilter = (
  blueprintSourceFieldID: CustomReportBlueprintLaunchResultFilterInput['blueprintSourceFieldID'],
  blueprintSourceFieldValue: CustomReportBlueprintLaunchResultFilterInput['blueprintSourceFieldValue']
): CustomReportLunchDetailFilterForUrl => ({
  id: getNumberPartFromGlobalId(blueprintSourceFieldID),
  ...match(blueprintSourceFieldValue)
    .with({ intValue: P.not(P.nullish) }, ({ intValue }) => ({
      i: intValue,
    }))
    .with({ floatValue: P.not(P.nullish) }, ({ floatValue }) => ({
      f: floatValue,
    }))
    .with({ datetimeValue: P.not(P.nullish) }, ({ datetimeValue }) => ({
      d: datetimeValue,
    }))
    .otherwise(({ strValue }) => ({ s: strValue })),
});

/**
 * Transforms a minified custom report detailed filter object into backend filter format
 */
const parseCustomReportLunchDetailSingleFilter = ({
  id,
  ...value
}: CustomReportLunchDetailFilterForUrl): CustomReportBlueprintLaunchResultFilterInput => ({
  blueprintSourceFieldID: makeIdGetter('blueprintSourceField')(id),
  blueprintSourceFieldValue: match(value)
    .with({ i: P.not(P.nullish) }, ({ i }) => ({
      intValue: +i,
    }))
    .with({ f: P.not(P.nullish) }, ({ f }) => ({
      floatValue: +f,
    }))
    .with({ d: P.not(P.nullish) }, ({ d }) => ({
      datetimeValue: d,
    }))
    .otherwise(({ s }) => ({ strValue: s })),
});

/**
 * Getter for a filter format of detail blueprint launch page
 */
export const getCustomReportLaunchDetailFilters = ({
  pivotRow,
  pivotRowSourceFieldColumn,
  pivotNestedRow,
  pivotNestedRowSourceFieldColumn,
  pivotColumn,
  pivotNestedColumnIndex,
}: CustomReportPivotCellDataWithRows) => {
  const result = [];

  if (
    isCustomReportColumn(pivotRowSourceFieldColumn) &&
    pivotRow.blueprintSourceFieldValue
  ) {
    result.push(
      getCustomReportLunchDetailSingleFilter(
        pivotRowSourceFieldColumn.blueprintSourceField.id,
        pivotRow.blueprintSourceFieldValue
      )
    );
  }

  if (
    pivotNestedRowSourceFieldColumn &&
    pivotNestedRow?.blueprintSourceFieldValue
  ) {
    result.push(
      getCustomReportLunchDetailSingleFilter(
        pivotNestedRowSourceFieldColumn.blueprintSourceField.id,
        pivotNestedRow.blueprintSourceFieldValue
      )
    );
  }

  // TODO typescript 5.5 will fix type inference in property accessor expression, so we may remove this variable
  const pivotNestedColumnValue =
    isCustomReportHeaderColumn(pivotColumn) &&
    !R.isNil(pivotNestedColumnIndex) &&
    pivotColumn.blueprintSourceFieldValues[pivotNestedColumnIndex];

  if (isCustomReportHeaderColumn(pivotColumn) && pivotNestedColumnValue) {
    result.push(
      getCustomReportLunchDetailSingleFilter(
        pivotColumn.blueprintSourceField.id,
        pivotNestedColumnValue
      )
    );
  }

  return result;
};

/**
 * Parses url search to get custom report detail filters
 */
export const parseCustomReportLaunchDetailFiltersFromUrlSearch = (
  searchParams: CustomReportLunchDetailFilterForUrl[]
) => {
  if (!searchParams) {
    return [];
  }

  return Object.values(searchParams).map(
    parseCustomReportLunchDetailSingleFilter
  );
};
