import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorGeneralReportRowEntryFragmentDoc } from '../fragments/milkingParlorGeneralReportRowEntry.graphql';
import { MilkingParlorGeneralReportGroupedRowEntryFragmentDoc } from '../fragments/milkingParlorGeneralReportGroupedRowEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorGeneralReportRowsQueryVariables = Types.Exact<{
  milkingDate: Types.Scalars['Date']['input'];
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingParlorIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MilkingParlorGeneralReportRowsQuery = { __typename?: 'Query', milkingParlorGeneralReportRows: Array<{ __typename: 'MilkingParlorGeneralReportRowEntry', notIdentifiedCount: number, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number }, cows: { __typename?: 'MlkParlorGeneralReportCows', total: number, perHour: number }, milk: { __typename?: 'MlkParlorGeneralReportMilk', total: number, perHour: number, perCows: number, avgFlow: number }, milkingTime: { __typename?: 'MlkParlorGeneralReportMilkingTime', durationSec: number, avgSec: number, start: string, stop: string } } | { __typename: 'MilkingParlorGeneralReportGroupedRowEntry', notIdentifiedCount: number, cows: { __typename?: 'MlkParlorGeneralReportCows', total: number, perHour: number }, milk: { __typename?: 'MlkParlorGeneralReportMilk', total: number, perHour: number, perCows: number, avgFlow: number }, milkingTime: { __typename?: 'MlkParlorGeneralReportMilkingTime', durationSec: number, avgSec: number, start: string, stop: string } }> };


export const MilkingParlorGeneralReportRowsDocument = gql`
    query milkingParlorGeneralReportRows($milkingDate: Date!, $farmIDs: [ID!], $milkingParlorIDs: [ID!], $milkingNumbers: [Int!]) {
  milkingParlorGeneralReportRows(
    milkingDate: $milkingDate
    farmIDs: $farmIDs
    milkingParlorIDs: $milkingParlorIDs
    milkingNumbers: $milkingNumbers
  ) {
    ... on MilkingParlorGeneralReportRowEntry {
      ...MilkingParlorGeneralReportRowEntry
    }
    ... on MilkingParlorGeneralReportGroupedRowEntry {
      ...MilkingParlorGeneralReportGroupedRowEntry
    }
  }
}
    ${MilkingParlorGeneralReportRowEntryFragmentDoc}
${MilkingParlorGeneralReportGroupedRowEntryFragmentDoc}`;

/**
 * __useMilkingParlorGeneralReportRowsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorGeneralReportRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorGeneralReportRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorGeneralReportRowsQuery({
 *   variables: {
 *      milkingDate: // value for 'milkingDate'
 *      farmIDs: // value for 'farmIDs'
 *      milkingParlorIDs: // value for 'milkingParlorIDs'
 *      milkingNumbers: // value for 'milkingNumbers'
 *   },
 * });
 */
export function useMilkingParlorGeneralReportRowsQuery(baseOptions: Apollo.QueryHookOptions<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables> & ({ variables: MilkingParlorGeneralReportRowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables>(MilkingParlorGeneralReportRowsDocument, options);
      }
export function useMilkingParlorGeneralReportRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables>(MilkingParlorGeneralReportRowsDocument, options);
        }
export function useMilkingParlorGeneralReportRowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables>(MilkingParlorGeneralReportRowsDocument, options);
        }
export type MilkingParlorGeneralReportRowsQueryHookResult = ReturnType<typeof useMilkingParlorGeneralReportRowsQuery>;
export type MilkingParlorGeneralReportRowsLazyQueryHookResult = ReturnType<typeof useMilkingParlorGeneralReportRowsLazyQuery>;
export type MilkingParlorGeneralReportRowsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorGeneralReportRowsSuspenseQuery>;
export type MilkingParlorGeneralReportRowsQueryResult = Apollo.QueryResult<MilkingParlorGeneralReportRowsQuery, MilkingParlorGeneralReportRowsQueryVariables>;