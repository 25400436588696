import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorShortFragmentDoc } from '../fragments/milkingParlorShort.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorsShortQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type MilkingParlorsShortQuery = { __typename?: 'Query', milkingParlors: { __typename?: 'MilkingParlorConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'MilkingParlorEdge', cursor: string, node: { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, farm: { __typename: 'Farm', id: string, name: string, number: string } } }> } };


export const MilkingParlorsShortDocument = gql`
    query milkingParlorsShort($first: Int, $after: String, $ids: [ID!], $search: String) {
  milkingParlors(first: $first, after: $after, ids: $ids, search: $search) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...MilkingParlorShort
      }
    }
  }
}
    ${MilkingParlorShortFragmentDoc}`;

/**
 * __useMilkingParlorsShortQuery__
 *
 * To run a query within a React component, call `useMilkingParlorsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorsShortQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMilkingParlorsShortQuery(baseOptions?: Apollo.QueryHookOptions<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>(MilkingParlorsShortDocument, options);
      }
export function useMilkingParlorsShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>(MilkingParlorsShortDocument, options);
        }
export function useMilkingParlorsShortSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>(MilkingParlorsShortDocument, options);
        }
export type MilkingParlorsShortQueryHookResult = ReturnType<typeof useMilkingParlorsShortQuery>;
export type MilkingParlorsShortLazyQueryHookResult = ReturnType<typeof useMilkingParlorsShortLazyQuery>;
export type MilkingParlorsShortSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorsShortSuspenseQuery>;
export type MilkingParlorsShortQueryResult = Apollo.QueryResult<MilkingParlorsShortQuery, MilkingParlorsShortQueryVariables>;