import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetMonitorScheduleMutationVariables = Types.Exact<{
  input: Types.SetMonitorScheduleInput;
}>;


export type SetMonitorScheduleMutation = { __typename?: 'Mutation', setMonitorSchedule?: any | null };


export const SetMonitorScheduleDocument = gql`
    mutation setMonitorSchedule($input: SetMonitorScheduleInput!) {
  setMonitorSchedule(input: $input)
}
    `;
export type SetMonitorScheduleMutationFn = Apollo.MutationFunction<SetMonitorScheduleMutation, SetMonitorScheduleMutationVariables>;

/**
 * __useSetMonitorScheduleMutation__
 *
 * To run a mutation, you first call `useSetMonitorScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMonitorScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMonitorScheduleMutation, { data, loading, error }] = useSetMonitorScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMonitorScheduleMutation(baseOptions?: Apollo.MutationHookOptions<SetMonitorScheduleMutation, SetMonitorScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMonitorScheduleMutation, SetMonitorScheduleMutationVariables>(SetMonitorScheduleDocument, options);
      }
export type SetMonitorScheduleMutationHookResult = ReturnType<typeof useSetMonitorScheduleMutation>;
export type SetMonitorScheduleMutationResult = Apollo.MutationResult<SetMonitorScheduleMutation>;
export type SetMonitorScheduleMutationOptions = Apollo.BaseMutationOptions<SetMonitorScheduleMutation, SetMonitorScheduleMutationVariables>;