import * as Types from '../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadCowsCsvMutationVariables = Types.Exact<{
  fileInput: Types.CowFileInput;
}>;


export type LoadCowsCsvMutation = { __typename?: 'Mutation', loadCowsCsv?: any | null };


export const LoadCowsCsvDocument = gql`
    mutation loadCowsCsv($fileInput: CowFileInput!) {
  loadCowsCsv(fileInput: $fileInput)
}
    `;
export type LoadCowsCsvMutationFn = Apollo.MutationFunction<LoadCowsCsvMutation, LoadCowsCsvMutationVariables>;

/**
 * __useLoadCowsCsvMutation__
 *
 * To run a mutation, you first call `useLoadCowsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadCowsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadCowsCsvMutation, { data, loading, error }] = useLoadCowsCsvMutation({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *   },
 * });
 */
export function useLoadCowsCsvMutation(baseOptions?: Apollo.MutationHookOptions<LoadCowsCsvMutation, LoadCowsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadCowsCsvMutation, LoadCowsCsvMutationVariables>(LoadCowsCsvDocument, options);
      }
export type LoadCowsCsvMutationHookResult = ReturnType<typeof useLoadCowsCsvMutation>;
export type LoadCowsCsvMutationResult = Apollo.MutationResult<LoadCowsCsvMutation>;
export type LoadCowsCsvMutationOptions = Apollo.BaseMutationOptions<LoadCowsCsvMutation, LoadCowsCsvMutationVariables>;