import React, { ReactNode } from 'react';

import { ReactComponent as Pencil } from '~/~legacy/icons/pencil.svg';

import styles from './index.module.scss';

interface Props {
  children: ReactNode;
  toggle: () => void;
}

export const NodeView: React.FC<Props> = ({ children, toggle }) => {
  return (
    <div className={styles.root}>
      {children}
      <div onClick={toggle} className={styles.icon}>
        <Pencil />
      </div>
    </div>
  );
};
