import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MonitorGroupFragmentDoc } from '../fragments/monitorGroup.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMonitorGroupMutationVariables = Types.Exact<{
  input: Types.CreateMonitorGroupInput;
}>;


export type CreateMonitorGroupMutation = { __typename?: 'Mutation', createMonitorGroup: { __typename: 'MonitorGroup', id: string, name: string } };


export const CreateMonitorGroupDocument = gql`
    mutation createMonitorGroup($input: CreateMonitorGroupInput!) {
  createMonitorGroup(input: $input) {
    ...MonitorGroup
  }
}
    ${MonitorGroupFragmentDoc}`;
export type CreateMonitorGroupMutationFn = Apollo.MutationFunction<CreateMonitorGroupMutation, CreateMonitorGroupMutationVariables>;

/**
 * __useCreateMonitorGroupMutation__
 *
 * To run a mutation, you first call `useCreateMonitorGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonitorGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonitorGroupMutation, { data, loading, error }] = useCreateMonitorGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMonitorGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateMonitorGroupMutation, CreateMonitorGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMonitorGroupMutation, CreateMonitorGroupMutationVariables>(CreateMonitorGroupDocument, options);
      }
export type CreateMonitorGroupMutationHookResult = ReturnType<typeof useCreateMonitorGroupMutation>;
export type CreateMonitorGroupMutationResult = Apollo.MutationResult<CreateMonitorGroupMutation>;
export type CreateMonitorGroupMutationOptions = Apollo.BaseMutationOptions<CreateMonitorGroupMutation, CreateMonitorGroupMutationVariables>;