import React from 'react';

import clsx from 'clsx';
import * as yup from 'yup';

import { Button } from '~/shared/components/Button';
import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useRequestAuthCodeMutation } from '~/services/auth/gql/mutations/requestAuthCode.graphql';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';

import panelStyles from '~/styles/modules/panel.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Called, when form should go to the next step
   */
  onNextStep: (email: string) => void;
}

const FORM_ID = 'LoginStepEmailForm';

const SCHEMA = yup.object({
  email: yup.string().email().required().default(''),
});

type LoginStepEmailFormType = InferValidatedSchema<typeof SCHEMA>;

export const LoginStepEmailForm: React.FC<Props> = ({
  className,
  onNextStep,
}) => {
  const [requestAuthCode] = useRequestAuthCodeMutation();

  const formContext = useForm<LoginStepEmailFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = (form: LoginStepEmailFormType) => {
    requestAuthCode({ variables: form }).then(() => onNextStep(form.email));
  };

  return (
    <>
      <Typography variant={TypographyVariants.bodySmall} className="mb-16">
        Добро пожаловать в ARKA
      </Typography>
      <Form
        {...{
          className: clsx(
            panelStyles.panel,
            'flex flex-col gap-16 p-24',
            className
          ),
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Input
          {...{
            name: 'email',
            label: 'Электронная почта',
            placeholder: 'Введите email',
            withAutoComplete: true,
          }}
        />
        <Button type="submit">Войти</Button>
      </Form>
    </>
  );
};
