import { from, fromPromise } from '@apollo/client';
import { ContextSetter, setContext } from '@apollo/client/link/context';
import {
  ErrorHandler,
  ErrorResponse,
  onError as createErrorLink,
} from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';

import loggerLink from 'apollo-link-logger';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { extractFiles } from 'extract-files';
import { ReplaySubject } from 'rxjs';

import { CustomAppHeaders, GqlAuthProvider, HandleGqlError } from '../../types';

const MAX_RETRIES = 5;

export type NetworkError = ErrorResponse['networkError'] & {
  bodyText?: string;
  statusCode?: number;
};

export const networkErrorsSubject$ = new ReplaySubject<NetworkError>(1);

const getAppHeaders = (
  gqlAuthProvider: GqlAuthProvider,
  getSelectedCompanyId: () => string
) => {
  return {
    authorization: gqlAuthProvider.getAccessToken() ?? '',
    [CustomAppHeaders.activeCompany]: getSelectedCompanyId(),
  };
};

/**
 * Handle client errors
 */
const makeHandleGraphQLErrors =
  (onError: HandleGqlError): ErrorHandler =>
  ({ graphQLErrors, operation }) => {
    if (!graphQLErrors || !graphQLErrors.length) {
      return;
    }

    // show first error with extensions as notification
    const error = graphQLErrors.find(er => er.extensions);

    if (!error) return;

    const context = operation.getContext();

    if (!context.shouldSkipErrorNotifications) {
      onError(error);
    }
  };

/**
 * Handle network errors
 */
const handleNetworkErrors: ErrorHandler = res => {
  const networkError = res.networkError as NetworkError;

  if (networkError && !(networkError instanceof DOMException)) {
    // Check if error response is JSON
    try {
      JSON.parse(String(networkError.bodyText));
    } catch (e) {
      // If not replace parsing error message with real one
      networkError.message = networkError.bodyText || '';
    }
  }

  networkErrorsSubject$.next(networkError);
};

/**
 * Handle auth errors
 */
const makeHandleAuthErrors =
  (
    gqlAuthProvider: GqlAuthProvider,
    getSelectedCompanyId: () => string
  ): ErrorHandler =>
  res => {
    const networkError = res.networkError as NetworkError;
    if (networkError?.statusCode === 401) {
      gqlAuthProvider.logout();
      return;
    }

    if (networkError instanceof DOMException) {
      return;
    }

    if (networkError?.statusCode !== 419) {
      console.error(networkError);
      return;
    }

    const refreshToken = gqlAuthProvider.getRefreshToken();

    if (refreshToken) {
      /* eslint-disable-next-line consistent-return -- to implement the apollo onError interface */
      return fromPromise(gqlAuthProvider.refreshToken()).flatMap(() => {
        const { operation, forward } = res;
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            ...getAppHeaders(gqlAuthProvider, getSelectedCompanyId),
          },
        }));

        return forward(operation);
      });
    }

    console.error(networkError);
    gqlAuthProvider.logout();
  };

/**
 * Apollo errors handler
 */
const makeHandleApolloErrors =
  (
    gqlAuthProvider: GqlAuthProvider,
    getSelectedCompanyId: () => string,
    onError: HandleGqlError
  ): ErrorHandler =>
  res => {
    makeHandleGraphQLErrors(onError)(res);
    handleNetworkErrors(res);

    return makeHandleAuthErrors(gqlAuthProvider, getSelectedCompanyId)(res);
  };

/**
 * Creates Apollo link for error handling
 */
const makeErrorLink = (
  gqlAuthProvider: GqlAuthProvider,
  getSelectedCompanyId: () => string,
  onError: HandleGqlError
) =>
  createErrorLink(
    makeHandleApolloErrors(gqlAuthProvider, getSelectedCompanyId, onError)
  );

/**
 * Creates Apollo link for retrying operations
 */
const makeRetryLink = () =>
  new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: MAX_RETRIES,
      retryIf: (error: NetworkError) => {
        return (
          !!error.statusCode &&
          !error.statusCode.toString().startsWith('4') &&
          ![502].includes(error.statusCode)
        );
      },
    },
  });

/**
 * Returns header context for Apollo
 */
const makeGetHeadersContext =
  (
    gqlAuthProvider: GqlAuthProvider,
    getSelectedCompanyId: () => string
  ): ContextSetter =>
  (gqlRequest, { headers = {} }) => {
    return {
      headers: {
        ...getAppHeaders(gqlAuthProvider, getSelectedCompanyId),
        ...headers,
      },
    };
  };

/**
 * Creates Apollo link for auth
 */
const makeAuthLink = (
  gqlAuthProvider: GqlAuthProvider,
  getSelectedCompanyId: () => string
) => setContext(makeGetHeadersContext(gqlAuthProvider, getSelectedCompanyId));

/**
 * input Props types for makeMainLink fabric
 */
export interface MakeMainLinkProps {
  apolloUri: string;
  gqlAuthProvider: GqlAuthProvider;
  getSelectedCompanyId: () => string;
  onError: HandleGqlError;
}

/**
 * Join and returns all Apollo links
 */
export const makeMainLink = ({
  apolloUri,
  gqlAuthProvider,
  onError,
  getSelectedCompanyId,
}: MakeMainLinkProps) => {
  const errorLink = makeErrorLink(
    gqlAuthProvider,
    getSelectedCompanyId,
    onError
  );
  const retryLink = makeRetryLink();
  const authLink = makeAuthLink(gqlAuthProvider, getSelectedCompanyId);

  const httpLink = new HttpLink({ uri: apolloUri });
  const uploadLink = createUploadLink({ uri: apolloUri });

  const defaultLinks = [errorLink, authLink, loggerLink, retryLink];

  return from(defaultLinks).split(
    operation => extractFiles(operation).files.size > 0,
    uploadLink,
    httpLink
  );
};
