import React, { isValidElement, ReactNode } from 'react';

import { MDASH } from '../constants';

/**
 * Function to render value, if condition is true, otherwise renders &mdash; symbol
 */
export const renderOrMdash = <T extends ReactNode>(
  content: T,
  condition = !!content
) => {
  if (condition) {
    return content;
  }
  return MDASH;
};

/**
 * Function to render an array of elements split by a separator
 */
export const joinJsxArray = <T extends ReactNode>(
  array: T[],
  delimiter: ReactNode = ', '
) => {
  return array
    .filter(Boolean)
    .reduce<(T | React.ReactElement)[]>((acc, item, index, currentArray) => {
      const newArray = [...acc, item];
      if (index !== currentArray.length - 1) {
        return [
          ...newArray,
          <span
            key={`${isValidElement(item) ? item.key : item?.toString()}_delimiter`}
            style={{ whiteSpace: 'pre' }}
          >
            {delimiter}
          </span>,
        ];
      }
      return newArray;
    }, []);
};
