import { HasRequiredKeys } from 'type-fest';

import { PartialSelectProps, SelectItem } from '../types';

/**
 * Fabric for creating a Select component, using a hook, that returns renderSelectElement
 */
export const makeSelectComponentFromHook = <
  UseSelectProps extends any,
  AdditionalSelectProps extends object = object,
  I extends SelectItem = SelectItem,
>(
  useSelect: (
    props: UseSelectProps,
    additionalSelectProps?: AdditionalSelectProps
  ) => {
    renderSelectElement: (
      innerProps?: PartialSelectProps<I>
    ) => React.JSX.Element;
  }
) => {
  return ({
    additionalProps,
    ...props
  }: UseSelectProps &
    (HasRequiredKeys<AdditionalSelectProps> extends true
      ? { additionalProps: AdditionalSelectProps }
      : { additionalProps?: undefined })) => {
    const { renderSelectElement } = useSelect(
      props as UseSelectProps,
      additionalProps
    );

    return renderSelectElement({});
  };
};
