import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ProtocolInjectionFragmentDoc } from '../fragments/protocolInjection.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProtocolInjectionMutationVariables = Types.Exact<{
  input: Types.CreateProtocolInjectionInput;
}>;


export type CreateProtocolInjectionMutation = { __typename?: 'Mutation', createProtocolInjection: { __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null } };


export const CreateProtocolInjectionDocument = gql`
    mutation createProtocolInjection($input: CreateProtocolInjectionInput!) {
  createProtocolInjection(input: $input) {
    ...ProtocolInjection
  }
}
    ${ProtocolInjectionFragmentDoc}`;
export type CreateProtocolInjectionMutationFn = Apollo.MutationFunction<CreateProtocolInjectionMutation, CreateProtocolInjectionMutationVariables>;

/**
 * __useCreateProtocolInjectionMutation__
 *
 * To run a mutation, you first call `useCreateProtocolInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProtocolInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProtocolInjectionMutation, { data, loading, error }] = useCreateProtocolInjectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProtocolInjectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProtocolInjectionMutation, CreateProtocolInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProtocolInjectionMutation, CreateProtocolInjectionMutationVariables>(CreateProtocolInjectionDocument, options);
      }
export type CreateProtocolInjectionMutationHookResult = ReturnType<typeof useCreateProtocolInjectionMutation>;
export type CreateProtocolInjectionMutationResult = Apollo.MutationResult<CreateProtocolInjectionMutation>;
export type CreateProtocolInjectionMutationOptions = Apollo.BaseMutationOptions<CreateProtocolInjectionMutation, CreateProtocolInjectionMutationVariables>;