import { useRef } from 'react';

import { useApolloClient } from '@apollo/client';

import R from 'ramda';

import { isTmpId } from '~/shared/helpers/string';

import { readFarmFragment } from '~/entities/farms';
import { readRoleFragment } from '~/entities/roles/helpers';

import {
  CreateCompanyUserMutationOptions,
  useCreateCompanyUserMutation,
} from '../gql/mutations/createCompanyUser.graphql';
import {
  RemoveUserFromCompanyMutationOptions,
  useRemoveUserFromCompanyMutation,
} from '../gql/mutations/removeUserFromCompany.graphql';
import {
  UpdateCompanyUserMutationOptions,
  useUpdateCompanyUserMutation,
} from '../gql/mutations/updateCompanyUser.graphql';
import { updateUserFragment } from '../helpers';
import { UserFormType } from '../types';

/**
 * Hook for CRUD actions on a user
 */
export const useUsersCRUD = () => {
  const client = useApolloClient();

  // We need created farm and role ids to use them in creating users
  const tempIdsMapRef = useRef<Record<string, string | undefined>>({});

  const getRealId = (id: string) =>
    isTmpId(id) ? tempIdsMapRef.current[id] : id;

  const getUserInputFromForm = (form: UserFormType) => ({
    ...R.omit(['id'], form),
    farmIDs: form.farmIDs.map(getRealId).filter(Boolean),
    blueprintRoleIDs: form.blueprintRoleIDs.map(getRealId).filter(Boolean),
  });

  const mapFarmIDsToCache = (farmIDs: string[]) =>
    farmIDs
      .map(farmId => readFarmFragment(client, getRealId(farmId)))
      .filter(Boolean);

  const mapRoleIDsToCache = (roleIDs: string[]) =>
    roleIDs
      .map(roleId => readRoleFragment(client, getRealId(roleId)))
      .filter(Boolean);

  // User create logic
  const [createCompanyUserMutation] = useCreateCompanyUserMutation();

  const createUser = (
    companyID: string,
    form: UserFormType,
    mutationOptions?: Partial<CreateCompanyUserMutationOptions>
  ) =>
    createCompanyUserMutation({
      variables: {
        input: { ...getUserInputFromForm(form), companyID },
      },
      ...mutationOptions,
    });

  // User update logic
  const [updateUserMutation] = useUpdateCompanyUserMutation();

  const updateUser = (
    companyID: string,
    form: UserFormType,
    mutationOptions?: Partial<UpdateCompanyUserMutationOptions>
  ) =>
    updateUserMutation({
      variables: {
        companyID,
        id: form.id,
        input: R.omit(['email', 'farmIDs'], getUserInputFromForm(form)),
      },
      optimisticResponse: {
        updateCompanyUser: null,
      },
      ...mutationOptions,
      update: R.juxt(
        [
          updateUserFragment(form.id, draft => {
            draft.firstName = form.firstName;
            draft.lastName = form.lastName;
            draft.middleName = form.middleName;
          }),
          mutationOptions?.update,
        ].filter(Boolean)
      ),
    });

  // User delete logic
  const [removeUserFromCompany] = useRemoveUserFromCompanyMutation();
  const deleteUser = (
    companyID: string,
    userId: string,
    mutationOptions?: Partial<RemoveUserFromCompanyMutationOptions>
  ) =>
    removeUserFromCompany({
      variables: {
        id: userId,
        companyID,
      },
      optimisticResponse: { removeUserFromCompany: null },
      ...mutationOptions,
    });

  return {
    mapFarmIDsToCache,
    mapRoleIDsToCache,

    tempIdsMapRef,

    createUser,
    updateUser,
    deleteUser,
  };
};
