import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { CowEventFragment } from '../gql/fragments/cowEvent.graphql';
import {
  CowEventsQuery,
  CowEventsQueryVariables,
  useCowEventsQuery,
} from '../gql/queries/cowEvents.graphql';

export const useCowEventsPaginatedQuery = makeUsePaginatedQuery<
  CowEventFragment,
  CowEventsQuery,
  CowEventsQueryVariables
>({
  useQuery: useCowEventsQuery,
  getItemsFromQueryData: data => data.cowEvents.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.cowEvents.pageInfo,
});
