import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CustomMilkingReportYAxisFieldFragment = { __typename: 'CustomMilkingReportYAxisField', kind: Types.CustomMilkingReportYAxisFieldKind, groupBy?: Types.CustomMilkingReportYAxisFieldGrouping | null, withRightScale: boolean };

export const CustomMilkingReportYAxisFieldFragmentDoc = gql`
    fragment CustomMilkingReportYAxisField on CustomMilkingReportYAxisField {
  __typename
  kind
  groupBy
  withRightScale
}
    `;