import React from 'react';

export const XClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4801 6.51992C17.773 6.81281 17.773 7.28768 17.4801 7.58058L13.0607 12L17.4801 16.4194C17.773 16.7123 17.773 17.1872 17.4801 17.4801C17.1872 17.773 16.7124 17.773 16.4195 17.4801L12 13.0607L7.58062 17.4801C7.28773 17.773 6.81286 17.773 6.51996 17.4801C6.22707 17.1872 6.22707 16.7123 6.51996 16.4194L10.9394 12L6.51996 7.58058C6.22707 7.28768 6.22707 6.81281 6.51996 6.51992C6.81286 6.22702 7.28773 6.22702 7.58062 6.51992L12 10.9393L16.4195 6.51992C16.7124 6.22702 17.1872 6.22702 17.4801 6.51992Z"
      fill="currentColor"
    />
  </svg>
);
