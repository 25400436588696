import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionCrReportFragmentDoc } from '../fragments/reproductionCrReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReproductionCrReportsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  roleIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ReproductionCrReportsQuery = { __typename?: 'Query', reproductionCrReports: { __typename?: 'ReproductionCrReportConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'ReproductionCrReportEdge', cursor: string, node: { __typename: 'ReproductionCrReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } }> } };


export const ReproductionCrReportsDocument = gql`
    query reproductionCrReports($first: Int, $after: String, $ids: [ID!], $roleIDs: [ID!], $search: String) {
  reproductionCrReports(
    first: $first
    after: $after
    ids: $ids
    roleIDs: $roleIDs
    search: $search
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...ReproductionCrReport
      }
    }
  }
}
    ${ReproductionCrReportFragmentDoc}`;

/**
 * __useReproductionCrReportsQuery__
 *
 * To run a query within a React component, call `useReproductionCrReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReproductionCrReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReproductionCrReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      ids: // value for 'ids'
 *      roleIDs: // value for 'roleIDs'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReproductionCrReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>(ReproductionCrReportsDocument, options);
      }
export function useReproductionCrReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>(ReproductionCrReportsDocument, options);
        }
export function useReproductionCrReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>(ReproductionCrReportsDocument, options);
        }
export type ReproductionCrReportsQueryHookResult = ReturnType<typeof useReproductionCrReportsQuery>;
export type ReproductionCrReportsLazyQueryHookResult = ReturnType<typeof useReproductionCrReportsLazyQuery>;
export type ReproductionCrReportsSuspenseQueryHookResult = ReturnType<typeof useReproductionCrReportsSuspenseQuery>;
export type ReproductionCrReportsQueryResult = Apollo.QueryResult<ReproductionCrReportsQuery, ReproductionCrReportsQueryVariables>;