import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import { SourceFieldFragment } from '~/entities/blueprintSourceFields/gql/fragments/sourceField.graphql';
import { CustomReportSettingsValueFragment } from '~/entities/customReports/gql/fragments/customReportSettingsValue.graphql';

import {
  CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT,
  CUSTOM_REPORT_VALUE_VIEW_KIND_DICT,
} from './constants';
import { CustomReportHeaderColumnFragment } from './gql/fragments/customReportHeaderColumn.graphql';
import { CustomReportLaunchResultFragment } from './gql/fragments/customReportLaunchResult.graphql';
import { CustomReportPivotTableFragment } from './gql/fragments/customReportPivotTable.graphql';
import { AnyCustomReportColumn, ValueConfigArrayItemFormType } from './types';

/**
 * Checks, if pivot table is calculated without problems
 */
export const isValidPivotTable = (
  pivotTable:
    | CustomReportLaunchResultFragment['pivotTable']
    | SkeletonPlaceholder
): pivotTable is CustomReportPivotTableFragment =>
  pivotTable.__typename === 'CustomReportPivotTable';

/**
 * Checks, if the pivot table column is with nested columns
 */
export const isCustomReportHeaderColumn = (
  column: AnyCustomReportColumn
): column is CustomReportHeaderColumnFragment =>
  column.__typename === 'CustomReportHeaderColumn';

/**
 * Gets a uniq custom report value identifier
 */
export const getCustomReportValueConfigKey = (
  valueConfig: ValueConfigArrayItemFormType | CustomReportSettingsValueFragment
) =>
  [
    'blueprintSourceFieldID' in valueConfig
      ? valueConfig.blueprintSourceFieldID
      : valueConfig.blueprintSourceField.id,
    valueConfig.formula,
    valueConfig.view,
  ].join('__');

/**
 * Gets the display name for the value config column
 */
export const getValueColumnName = (
  valueConfig: ValueConfigArrayItemFormType | CustomReportSettingsValueFragment,
  getSourceFieldById: (id: string) => SourceFieldFragment | undefined,
  withViewKind = true
) => {
  const blueprintSourceFieldId =
    'blueprintSourceFieldID' in valueConfig
      ? valueConfig.blueprintSourceFieldID
      : valueConfig.blueprintSourceField.id;

  const viewKindAddition = withViewKind
    ? `, ${CUSTOM_REPORT_VALUE_VIEW_KIND_DICT[valueConfig.view]}`
    : '';

  return `${CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT[valueConfig.formula]} из ${
    getSourceFieldById(blueprintSourceFieldId)?.name ?? ''
  }${viewKindAddition}`;
};

/**
 * Maps value config fragment into form fields representation, that is also can be used as valueKey
 */
export const mapCustomReportValueKeyToForm = (
  valueConfig: CustomReportSettingsValueFragment
) => ({
  formula: valueConfig.formula,
  view: valueConfig.view,
  blueprintSourceFieldID: valueConfig.blueprintSourceField.id,
});
