import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { InseminationSchemeFragment } from '../gql/fragments/inseminationScheme.graphql';
import { useCreateInseminationSchemeMutation } from '../gql/mutations/createInseminationScheme.graphql';
import { useUpdateInseminationSchemeMutation } from '../gql/mutations/updateInseminationScheme.graphql';

/**
 * Props for editing an event with InseminationScheme kind
 */
interface Props {
  /**
   * If passed, this is the event to edit, creating new otherwise
   */
  inseminationScheme?: InseminationSchemeFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (newInseminationScheme?: InseminationSchemeFragment) => void;
}

const FORM_ID = 'EditInseminationSchemeForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  code: yup.string().default(''),
  description: yup.string().default(''),
});

/**
 * Form for editing an event with InseminationScheme kind
 */
export type EditInseminationSchemeFormType = InferValidatedSchema<
  typeof SCHEMA
>;

export const useInseminationSchemeForm = ({
  inseminationScheme,
  onSubmit,
}: Props): UseModalStepFormInterface<EditInseminationSchemeFormType> => {
  const isEditing = !!inseminationScheme;

  const [isLoading, setLoading] = useState(false);

  const [createInseminationScheme] = useCreateInseminationSchemeMutation();
  const [updateInseminationScheme] = useUpdateInseminationSchemeMutation();

  const formContext = useForm<EditInseminationSchemeFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['name', 'code', 'description'],
        inseminationScheme ?? ({} as InseminationSchemeFragment)
      ),
    },
  });

  const handleSubmit = async (input: EditInseminationSchemeFormType) => {
    setLoading(true);
    try {
      let newInseminationScheme: InseminationSchemeFragment | undefined;
      if (isEditing) {
        await updateInseminationScheme({
          variables: { id: inseminationScheme.id, input },
          refetchQueries: ['events'],
        });
      } else {
        const res = await createInseminationScheme({
          variables: { input: { ...input, comment: '' } },
          refetchQueries: ['events'],
        });

        newInseminationScheme = res.data?.createInseminationScheme;
      }
      onSubmit?.(newInseminationScheme);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
