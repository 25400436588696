import React from 'react';

import {
  FemaleAnimalKind,
  ReproductionHdrAndPrXAxisMode,
  ReproductionHdrAndPrYAxisMetric,
} from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { Typography } from '~/shared/components/Typography';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { formatInt, formatWithPercent } from '~/shared/helpers/number';

import { InseminationsTable } from '~/entities/inseminations';
import { ReproductionHdrAndPrRowFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrRow.graphql';

import { TypographyVariants } from '~/styles/__generated__/token-variants';

import { REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS } from '../../../../constants';
import { getMetricColorBackgroundClassName } from '../../../../helpers';
import {
  useFarmWithReproductionSettingsFromCacheOrQuery,
  useReproductionHdrAndPrReportXAxis,
} from '../../../../hooks';
import { HdrAndPrReportDisplayProps } from '../../../../types';
import styles from './index.module.scss';

interface Props extends HdrAndPrReportDisplayProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Total row for the report
   */
  reportRowsTotal?: ReproductionHdrAndPrRowFragment | null;
  /**
   * Farm, used for report calculations, to get target from
   */
  farmId?: string | null;
  /**
   * Female animal kind, used for report calculations, to get target for
   */
  femaleAnimalKind: FemaleAnimalKind;
}

const SKELETON_ROWS_COUNT = 8;

const NAME_COLUMN_WIDTH_PX = 204;
const SMALL_COLUMN_WIDTH_PX = 92;
const METRIC_COLUMN_WIDTH_PX = 172;
const FIT_FOR_PREGNANCY_COLUMN_WIDTH_PX = 100;
const NOT_PREGNANT_PERCENT_COLUMN_WIDTH_PX = 140;

export const ReproductionHdrAndPrTable: React.FC<Props> = ({
  className,

  reportRows,
  reportRowsTotal,
  historicReportRows,
  xAxisMode,
  yAxisMetrics,

  farmId,
  femaleAnimalKind,
}) => {
  const isSkeleton = isSkeletonPlaceholder(reportRows.at(0));

  const isComparison = !!historicReportRows;

  const { fragment: currentFarm } =
    useFarmWithReproductionSettingsFromCacheOrQuery(farmId);

  const currentHdrTarget =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.hdrCowsTargetPercent
      : currentFarm?.reproductionTargets.hdrHeifersTargetPercent;
  const currentHdrDeviationThreshold =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.hdrCowsDeviationThresholdPercent
      : currentFarm?.reproductionTargets.hdrHeifersDeviationThresholdPercent;

  const currentPrTarget =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.prCowsTargetPercent
      : currentFarm?.reproductionTargets.prHeifersTargetPercent;
  const currentPrDeviationThreshold =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.prCowsDeviationThresholdPercent
      : currentFarm?.reproductionTargets.prHeifersDeviationThresholdPercent;

  const currentCrTarget =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.crCowsTargetPercent
      : currentFarm?.reproductionTargets.crHeifersTargetPercent;
  const currentCrDeviationThreshold =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.crCowsDeviationThresholdPercent
      : currentFarm?.reproductionTargets.crHeifersDeviationThresholdPercent;

  const { xAxisName, getXAxisLabelByRowIndex } =
    useReproductionHdrAndPrReportXAxis({
      reportRows,
      historicReportRows,
      xAxisMode,
      femaleAnimalKind,
      shouldShowTotal: true,
    });

  let items: (ReproductionHdrAndPrRowFragment | SkeletonPlaceholder)[];
  if (isSkeleton) {
    items = getSkeletonPlaceholders(SKELETON_ROWS_COUNT);
  } else {
    const actualItems = [...(reportRows ?? []), reportRowsTotal].filter(
      Boolean
    );
    items = actualItems;
    if (isComparison) {
      const historicItems = [
        ...historicReportRows.rows,
        historicReportRows.total,
      ].filter(Boolean);
      items = R.zip(actualItems, historicItems).flat();
    }
  }

  const getMetricColumnConfig = (
    yAxisMetric: ReproductionHdrAndPrYAxisMetric,
    currentTarget: number | undefined,
    currentDeviationThreshold: number | undefined
  ): TableColumnConfig<
    ReproductionHdrAndPrRowFragment | SkeletonPlaceholder
  > => {
    const isTargetEnabled = yAxisMetrics.includes(yAxisMetric);
    return {
      title: (
        <>
          <div>
            {REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric].label}
          </div>
          {!R.isNil(currentTarget) && isTargetEnabled && (
            <Typography
              className="text-soft"
              variant={TypographyVariants.descriptionLarge}
            >
              Цель {formatWithPercent(currentTarget)}
            </Typography>
          )}
        </>
      ),
      key: yAxisMetric,
      columnClassName: 'text-right',
      cellClassName: item =>
        isTargetEnabled
          ? getMetricColorBackgroundClassName({
              targetPercent: currentTarget,
              deviationThresholdPercent: currentDeviationThreshold,
              valuePercent:
                item[
                  REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric]
                    .field
                ],
            })
          : undefined,
      headerClassName:
        !isTargetEnabled || isSkeleton
          ? undefined
          : 'background-neutral-container-soft',
      renderCellContent: item =>
        formatWithPercent(
          item[REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric].field]
        ),
      width: METRIC_COLUMN_WIDTH_PX,
    };
  };

  const columnConfigs: TableColumnConfig<
    ReproductionHdrAndPrRowFragment | SkeletonPlaceholder
  >[] = [
    {
      title: xAxisName,
      key: 'name',
      renderCellContent: (item, index) => {
        const label = getXAxisLabelByRowIndex(index, true);
        const rowName = Array.isArray(label) ? label.join('') : label;

        return (
          <>
            <div>{rowName}</div>
            {isComparison && (
              <Typography
                className="text-muted"
                variant={TypographyVariants.descriptionLarge}
              >
                {index % 2 === 0 ? 'Текущий период' : 'Период сравнения'}
              </Typography>
            )}
          </>
        );
      },
      isSticky: true,
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Пригодные к осемен.',
      key: 'fitForInsemination',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.fitForInsemination),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    {
      title: 'Осемен.',
      key: 'inseminated',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.inseminated),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    getMetricColumnConfig(
      ReproductionHdrAndPrYAxisMetric.Hdr,
      currentHdrTarget,
      currentHdrDeviationThreshold
    ),
    {
      title: 'Пригодные к стельности',
      key: 'fitForPregnancy',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.fitForPregnancy),
      width: FIT_FOR_PREGNANCY_COLUMN_WIDTH_PX,
    },
    {
      title: 'Стельные',
      key: 'pregnant',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.pregnant),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    getMetricColumnConfig(
      ReproductionHdrAndPrYAxisMetric.Pr,
      currentPrTarget,
      currentPrDeviationThreshold
    ),
    ...wrapConditionalArrayElement(
      xAxisMode === ReproductionHdrAndPrXAxisMode.DaysInMilk &&
        ({
          title:
            REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[
              ReproductionHdrAndPrYAxisMetric.NotPregnant
            ].label,
          key: 'notPregnantPercent',
          columnClassName: 'text-right',
          renderCellContent: item => formatWithPercent(item.notPregnantPercent),
          width: NOT_PREGNANT_PERCENT_COLUMN_WIDTH_PX,
        } satisfies TableColumnConfig<
          ReproductionHdrAndPrRowFragment | SkeletonPlaceholder
        >)
    ),
    getMetricColumnConfig(
      ReproductionHdrAndPrYAxisMetric.Cr,
      currentCrTarget,
      currentCrDeviationThreshold
    ),
    {
      title: 'Аборты',
      key: 'abortions',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.abortions),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<ReproductionHdrAndPrRowFragment | SkeletonPlaceholder>
      {...{
        className: clsx(className, 'min-w-full w-min'),
        items,
        columnConfigs,
        getItemKey: (item, index) =>
          `${getXAxisLabelByRowIndex(index, true)?.toString()}__${index}`,
        getRowClassName: (item, index) => {
          const isTotalRow =
            !isSkeleton &&
            (index === items.length - 1 ||
              (isComparison && index === items.length - 2));
          const isHistoricRow = isComparison && index % 2 === 1;

          return clsx({
            ['background-info-opaque-container-muted']:
              isHistoricRow && !isTotalRow,
            ['background-neutral-opaque-container-default']:
              isTotalRow && !isHistoricRow,
            [styles.historicTotalRow]: isTotalRow && isHistoricRow,
          });
        },
        getRowTypographyVariant: (item, index) => {
          const isTotalRow =
            !isSkeleton &&
            (index === items.length - 1 ||
              (isComparison && index === items.length - 2));
          return isTotalRow ? TypographyVariants.bodySmallStrong : undefined;
        },
        hasExpandableRowContent: ({ hashID }) => !!hashID,
        renderExpandableRowContent: ({ hashID }) =>
          !!hashID && (
            <InseminationsTable
              {...{
                hashID,
                femaleAnimalKind,
                withRetiredAtColumn: true,
              }}
            />
          ),
        noItemsMessage:
          'Недостаточно данных для отображения, проверьте настройки',
      }}
    />
  );
};
