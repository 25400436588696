import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { RoleFragmentDoc } from '../../../../services/auth/gql/fragments/role.graphql';
export type ReproductionCrReportFragment = { __typename: 'ReproductionCrReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> };

export const ReproductionCrReportFragmentDoc = gql`
    fragment ReproductionCrReport on ReproductionCrReport {
  __typename
  id
  name
  roles {
    ...Role
  }
}
    ${RoleFragmentDoc}`;