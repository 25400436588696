import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type HerriotIntegrationEntityFragment = { __typename: 'HerriotIntegrationEntity', name: string, guid: string, kind: Types.HerriotIntegrationEntityKindEnum };

export const HerriotIntegrationEntityFragmentDoc = gql`
    fragment HerriotIntegrationEntity on HerriotIntegrationEntity {
  __typename
  name
  guid
  kind
}
    `;