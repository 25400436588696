import React, {
  FocusEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSvgKeyPress } from '~/~legacy/hooks/useSvgKeyPress';
import { ReactComponent as ChevronDownSVG } from '~/~legacy/icons/chevron-down.svg';
import { ReactComponent as ChevroneUpSVG } from '~/~legacy/icons/chevron-up.svg';
import { ReactComponent as TimesSVG } from '~/~legacy/icons/times.svg';
import { ListItem } from '~/~legacy/types/keyValue';

import { MOverlay } from '~/shared/components/MOverlay';
import { useForwardRef } from '~/shared/hooks/useForwardRef';

interface UseDropDownProps {
  isOpened?: boolean;
  isDisabled?: boolean;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler;

  listItems: ListItem<any>[];
  selectedValue: any;
  onChange: (value: any) => void;

  noReset?: boolean;
  ref?: React.ForwardedRef<any>;
}

export function useDropDown(config: UseDropDownProps) {
  const {
    isDisabled,
    isOpened,
    onBlur,
    onFocus,
    onKeyDown,
    listItems,
    selectedValue,
    onChange,
    ref,
    noReset,
  } = config;

  const [opened, setOpened] = useState(Boolean(isOpened));

  const [internalSelected, setInternalSelected] = useState(selectedValue);

  useEffect(() => {
    setInternalSelected(selectedValue);
  }, [setInternalSelected, selectedValue]);

  const elementRef = useForwardRef(ref);
  const selectedElementRef = useRef<HTMLLIElement>(null);

  const setFocus = () => {
    setTimeout(() => {
      elementRef.current?.focus();
    }, 10);
  };

  const toggle = useCallback(
    (e?: any) => {
      setOpened(val => !val && !isDisabled);
      if (e && onFocus && !opened) {
        onFocus(e);
      }

      if (e && onBlur && opened) {
        onBlur(e);
      }

      setFocus();
    },
    [setOpened, isDisabled, opened]
  );

  useEffect(() => {
    if (isDisabled) {
      setOpened(false);
    }
  }, [setOpened, isDisabled]);

  const selectedItem = listItems.find(item => item.value === internalSelected);
  const selectedIndex = listItems.findIndex(
    item => item.value === internalSelected
  );

  const selectNext = useCallback(() => {
    if (listItems.length === 0) return;
    const shiftedIndex = selectedIndex + 1;
    const nextIndex = listItems.length === shiftedIndex ? 0 : shiftedIndex;
    const nextItem = listItems[nextIndex].value;

    setInternalSelected(nextItem);
    // onChange(nextItem);
  }, [selectedIndex, onChange, listItems, setInternalSelected]);

  const selectPrevious = useCallback(() => {
    if (listItems.length === 0) return;

    const shiftedIndex = selectedIndex - 1;
    const nextIndex = shiftedIndex < 0 ? listItems.length - 1 : shiftedIndex;
    const nextItem = listItems[nextIndex].value;

    setInternalSelected(nextItem);
    // onChange(nextItem);
  }, [selectedIndex, onChange, listItems, setInternalSelected]);

  const keyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Shift') {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (opened && (e.key === 'Escape' || e.key === 'Tab')) {
        toggle();
        return;
      }

      // if (!opened && (e.key !== 'Escape' && e.key !== 'Tab')) {
      //   toggle();
      //   return;
      // }

      if (opened && e.key === 'ArrowUp') {
        selectPrevious();
      }

      if (opened && e.key === 'ArrowDown') {
        selectNext();
      }

      if (e.key === 'Enter') {
        if (opened) onChange(internalSelected);
        toggle();
      }

      if (!opened && e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        toggle();
        return;
      }

      if (!opened && onKeyDown) {
        onKeyDown(e);
      }
    },
    [
      onChange,
      toggle,
      opened,
      selectNext,
      selectPrevious,
      elementRef.current,
      internalSelected,
    ]
  );

  const clearAction = useCallback(() => {
    onChange(undefined);
    setFocus();
  }, [onChange]);

  const clearClick = useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      clearAction();
    },
    [clearAction]
  );

  const clearPress = useSvgKeyPress(clearAction);
  const clearIcon =
    !noReset && selectedItem?.value && !opened ? (
      <>
        <TimesSVG
          style={{ position: 'relative' }}
          tabIndex={0}
          onKeyDown={clearPress}
          onClick={clearClick}
        />
        &nbsp;
      </>
    ) : null;

  const overlay = opened ? <MOverlay click={toggle} /> : null;
  const IconSVG = opened ? ChevroneUpSVG : ChevronDownSVG;

  return {
    opened,
    setOpened,
    toggle,
    overlay,
    IconSVG,
    selectedElementRef,
    elementRef,
    keyDown,
    clearIcon,
    internalSelected,
    setFocus,
  };
}
