import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type RoleFragment = { __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor };

export const RoleFragmentDoc = gql`
    fragment Role on BlueprintRole {
  __typename
  id
  name
  color
}
    `;