import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { AsyncListProps } from '~/shared/components/AsyncList';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatInt } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';
import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { formatBull } from '~/entities/bulls';
import { useEditSemenDoseModal } from '~/entities/semenDoses';
import { SemenDoseFragment } from '~/entities/semenDoses/gql/fragments/semenDose.graphql';
import { useDeleteSemenDoseMutation } from '~/entities/semenDoses/gql/mutations/deleteSemenDose.graphql';
import {
  SemenDosesDocument,
  SemenDosesQueryVariables,
} from '~/entities/semenDoses/gql/queries/semenDoses.graphql';

interface Props extends Partial<AsyncListProps<SemenDoseFragment>> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Semen doses to display in table
   */
  semenDoses: SemenDoseFragment[];
  /**
   * Query variables for the semen doses codes query
   */
  queryVariables: SemenDosesQueryVariables;
}

const DELIVERY_DATE_COLUMN_WIDTH_PX = 112;
const DEFAULT_COLUMN_WIDTH_PX = 204;

export const SemenDosesTable: React.FC<Props> = ({
  className,
  semenDoses,
  queryVariables,
  ...asyncProps
}) => {
  const { open: openEditSemenDoseModal } = useEditSemenDoseModal();

  const [deleteSemenDoseMutation] = useDeleteSemenDoseMutation();
  const confirmDelete = useConfirm();

  const columnConfigs: TableColumnConfig<SemenDoseFragment>[] = [
    {
      title: 'Дата поставки',
      key: 'deliveryDate',
      renderCellContent: ({ deliveryDate }) => formatDate(deliveryDate),
      width: DELIVERY_DATE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Название или код поставщика',
      key: 'studCode',
      itemField: 'studCode',
      width: DEFAULT_COLUMN_WIDTH_PX,
    },
    {
      title: 'Номер партии',
      key: 'batchNumber',
      itemField: 'batchNumber',
      width: DEFAULT_COLUMN_WIDTH_PX,
    },
    {
      title: 'Бык',
      key: 'bullID',
      renderCellContent: ({ bull }) => formatBull(bull),
      width: DEFAULT_COLUMN_WIDTH_PX,
    },
    {
      title: 'Количество доз',
      key: 'dosesCount',
      renderCellContent: ({ dosesCount }) => formatInt(dosesCount),
    },
  ];

  const deleteSemenDose = (semenDose: SemenDoseFragment) => {
    confirmDelete({
      title: 'Удаление поставки семени',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить поставку семени{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {semenDose.studCode}
            </Typography>{' '}
            от{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatDate(semenDose.deliveryDate)}
            </Typography>
            ?
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    }).then(isConfirmed => {
      if (!isConfirmed) return;

      deleteSemenDoseMutation({
        variables: {
          id: semenDose.id,
        },
        optimisticResponse: { deleteSemenDose: null },
        update: R.juxt([
          makeDeleteFragmentFromQuery({
            typeName: 'SemenDose',
            query: SemenDosesDocument,
            variables: queryVariables,
            queryName: 'semenDoses',
          })(semenDose.id),
          makeDeleteQueriesByNameWithoutVariables('semenDoses', queryVariables),
        ]),
      });
    });
  };

  return (
    <Table<SemenDoseFragment>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: semenDoses,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        renderItemActions: semenDose => (
          <div className="flex gap-8">
            <FunctionButton
              {...{
                iconVariant: IconVariants.edit,
                tooltip: 'Редактировать поставку семени',
                onPress: () => {
                  openEditSemenDoseModal({
                    semenDose,
                  });
                },
              }}
            />
            <FunctionButton
              {...{
                iconVariant: IconVariants.delete,
                tooltip: 'Удалить поставку семени',
                onPress: () => deleteSemenDose(semenDose),
              }}
            />
          </div>
        ),
        ...asyncProps,
      }}
    />
  );
};
