import { CowState } from '@graphql-types';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { COW_STATES_DICT } from '../constants';

/**
 * Select for cow states
 */
export const useCowStateSelect = makeUseEnumSelect(
  CowState,
  enumValue => COW_STATES_DICT[enumValue as CowState]
);
