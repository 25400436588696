import { MilkingParlorKindEnum } from '@graphql-types';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { makeUseEnumSelect } from '~/shared/components/Select';

/**
 * Select for milking parlor kind
 */
export const useMilkingParlorKindSelect = makeUseEnumSelect(
  MilkingParlorKindEnum,
  EnumStrings.milkingParlorKind
);
