import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionMetricFragmentDoc } from './reproductionMetric.graphql';
import { ReproductionHdrAndPrRowFragmentDoc } from '../../../../entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrRow.graphql';
export type ReproductionHdrAndPrMainRowsFragment = { __typename: 'ReproductionHdrAndPrMainRows', hdrMetric: { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string }, prMetric: { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string }, crMetric: { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string }, rows: Array<{ __typename: 'ReproductionHdrAndPrRowByDate', date: string, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null }> };

export const ReproductionHdrAndPrMainRowsFragmentDoc = gql`
    fragment ReproductionHdrAndPrMainRows on ReproductionHdrAndPrMainRows {
  __typename
  hdrMetric {
    ...ReproductionMetric
  }
  prMetric {
    ...ReproductionMetric
  }
  crMetric {
    ...ReproductionMetric
  }
  rows {
    ...ReproductionHdrAndPrRow
  }
}
    ${ReproductionMetricFragmentDoc}
${ReproductionHdrAndPrRowFragmentDoc}`;