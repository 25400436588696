import React, { ReactNode } from 'react';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants, RotateVariants } from '~/shared/components/Icon';
import {
  Typography,
  TypographyProps,
  TypographyVariants,
} from '~/shared/components/Typography';
import { useAccordion, UseAccordionProps } from '~/shared/hooks/useAccordion';

import styles from './index.module.scss';

/**
 * Possible render variants of the expandable block
 */
export enum ExpandableBlockVariants {
  text = 'text',
  header = 'header',
}

interface Props extends UseAccordionProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Render variant of the block
   */
  variant?: ExpandableBlockVariants;
  /**
   * Text for the open button, when block is collapsed
   */
  openButtonText: ReactNode;
  /**
   * Text for the close button, when block is expanded
   */
  closeButtonText: ReactNode;
  /**
   * Title of the opened block
   */
  title?: ReactNode;
}

const TITLE_TYPOGRAPHY_PROPS_BY_VARIANT: Record<
  ExpandableBlockVariants,
  TypographyProps
> = {
  [ExpandableBlockVariants.text]: {
    className: 'mb-8',
    tag: 'h4',
    variant: TypographyVariants.bodySmallStrong,
  },
  [ExpandableBlockVariants.header]: {
    className: 'mb-16',
    tag: 'h3',
    variant: TypographyVariants.heading4,
  },
};

export const ExpandableBlock: React.FC<Props> = ({
  className,

  variant = ExpandableBlockVariants.text,

  openButtonText,
  closeButtonText,
  title,

  children,
  ...useAccordionProps
}) => {
  const {
    isOpen,
    shouldRenderContent,

    childrenContainerRef,

    openButtonProps,
    childrenWrapperProps,
  } = useAccordion(useAccordionProps);

  return (
    <div className={className}>
      <div
        {...{
          className: styles.children,
          ...childrenWrapperProps,
        }}
      >
        <div ref={childrenContainerRef}>
          {shouldRenderContent && (
            <div className={styles.contentWrapper}>
              <Typography
                {...{
                  ...TITLE_TYPOGRAPHY_PROPS_BY_VARIANT[variant],
                }}
              >
                {title}
              </Typography>
              <div>{children}</div>
            </div>
          )}
        </div>
      </div>
      <FunctionButton
        {...{
          className:
            isOpen && variant === ExpandableBlockVariants.header
              ? 'mt-24'
              : undefined,
          iconVariant: IconVariants.chevronDown,
          iconProps: {
            rotate: isOpen ? RotateVariants.down : RotateVariants.up,
          },
          isWithRightIcon: true,
          ...openButtonProps,
        }}
      >
        {isOpen ? closeButtonText : openButtonText}
      </FunctionButton>
    </div>
  );
};
