import { useMemo } from 'react';

import {
  ReproductionHdrAndPrXAxisMode,
  ReproductionHdrAndPrYAxisMetric,
} from '@graphql-types';
import { match } from 'ts-pattern';

import { useForm } from '~/services/forms';

import { useReportCardBuilderForm } from '~/features/reportCardBuilder';

import {
  REPRODUCTION_HDR_AND_PR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT,
  REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA,
  REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS,
} from '../../../constants';
import { mapReproductionHdrAndPrReportSettingsToForm } from '../../../helpers';
import {
  ReproductionHdrAndPrReportFiltersAndGroupingMenuItems,
  ReproductionHdrAndPrReportSettingsFormType,
} from '../../../types';
import { ReproductionHdrAndPrReportSettingsFormProps } from '../types';

export const useReproductionHdrAndPrReportSettingsForm = ({
  reproductionHdrAndPrReport,

  onSettingsFormChange,
}: ReproductionHdrAndPrReportSettingsFormProps) => {
  const reportSettings = reproductionHdrAndPrReport.settings;

  const defaultValues = useMemo(
    () => mapReproductionHdrAndPrReportSettingsToForm(reportSettings),
    [reportSettings]
  );

  const formContext = useForm<ReproductionHdrAndPrReportSettingsFormType>({
    schema: REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA,
    defaultValues,
    // Cause we have live recalculations, we should not wait submission for showing errors.
    // onBlur is chosen cause it's more friendly than onChange.
    mode: 'onBlur',
  });

  const { formValues } = useReportCardBuilderForm({
    formContext,
    defaultValues,
    onSettingsFormChange,
  });

  const availableForAddingYAxisMetrics = Object.values(
    ReproductionHdrAndPrYAxisMetric
  ).filter(
    yAxisMetric =>
      // Already added metrics
      !formValues.yAxisMetrics.includes(yAxisMetric) &&
      // Not pregnant only applies to days in milk
      (formValues.xAxisMode === ReproductionHdrAndPrXAxisMode.DaysInMilk ||
        yAxisMetric !== ReproductionHdrAndPrYAxisMetric.NotPregnant)
  );

  const availableForAddingYAxisMetricsItems =
    availableForAddingYAxisMetrics.map(yAxisMetric => ({
      key: yAxisMetric,
      content: REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric].label,
    }));

  const addToYAxisMetricsArray = (
    item: (typeof availableForAddingYAxisMetricsItems)[number]
  ) => {
    formContext.setValue('yAxisMetrics', [
      ...formValues.yAxisMetrics,
      item.key,
    ]);
  };

  const removeFromYAxisMetricsArray = (index: number) => {
    formContext.setValue(
      'yAxisMetrics',
      formValues.yAxisMetrics.toSpliced(index, 1)
    );
  };

  const availableForAddingFiltersAndGrouping = [
    !formValues.lactationGroupNumbers &&
      ReproductionHdrAndPrReportFiltersAndGroupingMenuItems.filterLactations,
    !formValues.cowIDs &&
      ReproductionHdrAndPrReportFiltersAndGroupingMenuItems.filterAnimals,
  ].filter(Boolean);

  // Filters and grouping adding
  const availableForFiltersAndGroupingItems =
    availableForAddingFiltersAndGrouping.map(menuItem => ({
      key: menuItem,
      content:
        REPRODUCTION_HDR_AND_PR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[
          menuItem
        ],
      onPress: () =>
        match(menuItem)
          .with(
            ReproductionHdrAndPrReportFiltersAndGroupingMenuItems.filterAnimals,
            () => formContext.setValue('cowIDs', [])
          )
          .with(
            ReproductionHdrAndPrReportFiltersAndGroupingMenuItems.filterLactations,
            () => formContext.setValue('lactationGroupNumbers', [])
          )
          .exhaustive(),
    }));

  return {
    formValues,
    formContext,

    availableForAddingYAxisMetricsItems,
    addToYAxisMetricsArray,
    removeFromYAxisMetricsArray,

    availableForFiltersAndGroupingItems,
  };
};
