import { ApolloCache, MutationUpdaterFn } from '@apollo/client';

import { Draft, produce } from 'immer';

import { AnyFragment } from '../../types';
import {
  makeGetFragmentCacheOptions,
  MakeGetFragmentCacheOptionsProps,
} from './makeGetFragmentCacheOptions';
import { makeReadFragment } from './makeReadFragment';

/**
 * Factory for making apollo fragment update functions.
 */
export const makeUpdateFragment = <Fragment extends AnyFragment>(
  makeGetFragmentCacheOptionsProps: MakeGetFragmentCacheOptionsProps
) => {
  const getFragmentCacheOptions = makeGetFragmentCacheOptions(
    makeGetFragmentCacheOptionsProps
  );

  const readFragment = makeReadFragment<Fragment>(
    makeGetFragmentCacheOptionsProps
  );

  return <MutationData extends Record<string, any>>(
      id: string,
      updateFragmentData: (
        draft: Draft<Fragment>,
        updateArgs: {
          mutationData: MutationData;
          cache: ApolloCache<MutationData>;
        }
      ) => void
    ): MutationUpdaterFn<MutationData> =>
    (cache, { data: mutationData }) => {
      const fragmentCacheOptions = getFragmentCacheOptions(cache, id);

      const currentData = readFragment(cache, id);

      if (!mutationData || !currentData) {
        return;
      }

      cache.writeFragment({
        ...fragmentCacheOptions,
        data: produce(currentData, draft =>
          updateFragmentData(draft, { mutationData, cache })
        ),
      });
    };
};
