import React from 'react';

export const Printer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.37496 1.6665C4.37496 1.32133 4.65478 1.0415 4.99996 1.0415H15C15.3451 1.0415 15.625 1.32133 15.625 1.6665V6.87484H16.6666C17.9148 6.87484 18.9583 7.91831 18.9583 9.1665V13.3332C18.9583 14.5814 17.9148 15.6248 16.6666 15.6248H15.625V18.3332C15.625 18.6783 15.3451 18.9582 15 18.9582H4.99996C4.65478 18.9582 4.37496 18.6783 4.37496 18.3332V15.6248H3.33329C2.0851 15.6248 1.04163 14.5814 1.04163 13.3332V9.1665C1.04163 7.91831 2.0851 6.87484 3.33329 6.87484H4.37496V1.6665ZM5.62496 17.7082H14.375V12.2915H5.62496V17.7082ZM15.625 14.3748V11.6665C15.625 11.3213 15.3451 11.0415 15 11.0415H4.99996C4.65478 11.0415 4.37496 11.3213 4.37496 11.6665V14.3748H3.33329C2.76593 14.3748 2.29163 13.9005 2.29163 13.3332V9.1665C2.29163 8.59914 2.76593 8.12484 3.33329 8.12484H16.6666C17.234 8.12484 17.7083 8.59914 17.7083 9.1665V13.3332C17.7083 13.9005 17.234 14.3748 16.6666 14.3748H15.625ZM14.375 2.2915V6.87484H5.62496V2.2915H14.375Z"
      fill="currentColor"
    />
  </svg>
);
