import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorIntervalWithMilkingParlorFragmentDoc } from '../fragments/milkingParlorIntervalWithMilkingParlor.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorIntervalsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type MilkingParlorIntervalsQuery = { __typename?: 'Query', milkingParlorIntervals: { __typename?: 'MilkingParlorIntervalConnection', nodes: Array<{ __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string, milkingParlor: { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, farm: { __typename: 'Farm', id: string, name: string, number: string } }, penGroups: { __typename?: 'MilkingParlorIntervalPenGroupsConnection', edges: Array<{ __typename?: 'MilkingParlorIntervalPenGroupEdge', node: { __typename: 'PenGroup', id: string, name: string, identifier: number } }> } }> } };


export const MilkingParlorIntervalsDocument = gql`
    query milkingParlorIntervals($ids: [ID!]) {
  milkingParlorIntervals(ids: $ids) {
    nodes {
      ...MilkingParlorIntervalWithMilkingParlor
    }
  }
}
    ${MilkingParlorIntervalWithMilkingParlorFragmentDoc}`;

/**
 * __useMilkingParlorIntervalsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorIntervalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorIntervalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorIntervalsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMilkingParlorIntervalsQuery(baseOptions?: Apollo.QueryHookOptions<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>(MilkingParlorIntervalsDocument, options);
      }
export function useMilkingParlorIntervalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>(MilkingParlorIntervalsDocument, options);
        }
export function useMilkingParlorIntervalsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>(MilkingParlorIntervalsDocument, options);
        }
export type MilkingParlorIntervalsQueryHookResult = ReturnType<typeof useMilkingParlorIntervalsQuery>;
export type MilkingParlorIntervalsLazyQueryHookResult = ReturnType<typeof useMilkingParlorIntervalsLazyQuery>;
export type MilkingParlorIntervalsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorIntervalsSuspenseQuery>;
export type MilkingParlorIntervalsQueryResult = Apollo.QueryResult<MilkingParlorIntervalsQuery, MilkingParlorIntervalsQueryVariables>;