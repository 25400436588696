import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { ProtocolFragment } from '../gql/fragments/protocol.graphql';
import { useUpdateProtocolMutation } from '../gql/mutations/updateProtocol.graphql';

/**
 * Props for editing protocol third step
 */
interface Props {
  /**
   * Protocol to edit
   */
  protocol: ProtocolFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: () => void;
}

const FORM_ID = 'EditProtocolTotalTreatmentCostForm';

const SCHEMA = yup.object({
  totalTreatmentCost: yup.number().nullable().default(null),
});

/**
 * Form for editing protocol third step
 */
export type EditProtocolTotalTreatmentCostFormType = InferValidatedSchema<
  typeof SCHEMA
>;

export const useProtocolTotalTreatmentCostForm = ({
  protocol,
  onSubmit,
}: Props): UseModalStepFormInterface<EditProtocolTotalTreatmentCostFormType> => {
  const [isLoading, setLoading] = useState(false);

  const [updateProtocol] = useUpdateProtocolMutation();

  const formContext = useForm<EditProtocolTotalTreatmentCostFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(['totalTreatmentCost'], protocol ?? {}),
    },
  });

  const handleSubmit = async (
    input: EditProtocolTotalTreatmentCostFormType
  ) => {
    setLoading(true);

    try {
      if (input.totalTreatmentCost !== protocol.totalTreatmentCost) {
        await updateProtocol({
          variables: { id: protocol.id, input },
          refetchQueries: ['events'],
          awaitRefetchQueries: true,
        });
      }
      onSubmit?.();
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
