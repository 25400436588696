import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type IntHardcodedValueFragment = { __typename: 'IntHardcodedValue', intValue: number };

export const IntHardcodedValueFragmentDoc = gql`
    fragment IntHardcodedValue on IntHardcodedValue {
  __typename
  intValue: value
}
    `;