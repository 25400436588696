import {
  DefaultEventEnum,
  EventIdInput,
  EventKindEnum,
  InseminationScheme,
} from '@graphql-types';

import { NO_NAME_MESSAGE } from '~/shared/constants';

import { makeReadFragment } from '~/services/gql';

import { DefaultEventFragment } from './gql/fragments/defaultEvent.graphql';
import {
  DiseaseFragment,
  DiseaseFragmentDoc,
} from './gql/fragments/disease.graphql';
import { EventFragment } from './gql/fragments/event.graphql';
import { InjectionFragment } from './gql/fragments/injection.graphql';
import { InseminationSchemeFragment } from './gql/fragments/inseminationScheme.graphql';
import {
  ProtocolFragment,
  ProtocolFragmentDoc,
} from './gql/fragments/protocol.graphql';
import {
  UserEventFragment,
  UserEventFragmentDoc,
} from './gql/fragments/userEvent.graphql';

/**
 * Default events have only shortcodes as ids, so we using this helper for getting an id
 */
export const getEventId = (
  event: { id?: string; shortcode?: string } | undefined | null
) => {
  return event?.shortcode ?? event?.id ?? '';
};

/**
 * We use special filter object for filtering events by id, cause default events doesn't have an id
 */
export const getEventIdsFilter = (
  eventId?: string
): EventIdInput | undefined => {
  if (!eventId) return undefined;

  const isDefaultEventId = !eventId.includes('_');
  return {
    eventID: isDefaultEventId ? undefined : eventId,
    defaultEventKind: isDefaultEventId
      ? (eventId as DefaultEventEnum)
      : undefined,
  };
};

/**
 * Checks, if event is default event
 */
export const isDefaultEvent = (
  event: EventFragment | undefined | null
): event is EventFragment & DefaultEventFragment =>
  event?.kind === EventKindEnum.Default;

/**
 * Checks, if event is disease
 */
export const isDisease = (
  event: EventFragment | undefined | null
): event is EventFragment & DiseaseFragment =>
  event?.kind === EventKindEnum.Disease;

/**
 * Checks, if event is injection
 */
export const isInjection = (
  event: EventFragment | undefined | null
): event is EventFragment & InjectionFragment =>
  event?.kind === EventKindEnum.Injection;

/**
 * Checks, if event is insemination scheme
 */
export const isInseminationScheme = (
  event: EventFragment | undefined | null
): event is EventFragment & InseminationSchemeFragment =>
  event?.kind === EventKindEnum.InseminationScheme;

/**
 * Checks, if event is protocol
 */
export const isProtocol = (
  event: EventFragment | undefined | null
): event is EventFragment & ProtocolFragment =>
  event?.kind === EventKindEnum.Protocol;

/**
 * Checks, if event is user event
 */
export const isUserEvent = (
  event: EventFragment | undefined | null
): event is EventFragment & UserEventFragment =>
  event?.kind === EventKindEnum.User;

/**
 * Reads protocol fragment from cache by id
 */
export const readProtocolFragment = makeReadFragment<ProtocolFragment>({
  typeName: 'Protocol',
  fragment: ProtocolFragmentDoc,
});

/**
 * Reads disease fragment from cache by id
 */
export const readDiseaseFragment = makeReadFragment<DiseaseFragment>({
  typeName: 'Disease',
  fragment: DiseaseFragmentDoc,
});

/**
 * Reads user event fragment from cache by id
 */
export const readUserEventFragment = makeReadFragment<UserEventFragment>({
  typeName: 'UserEvent',
  fragment: UserEventFragmentDoc,
});

/**
 * Returns insemination scheme formatted for rendering
 */
export const formatInseminationScheme = (
  scheme: Pick<InseminationScheme, 'name'>
) => scheme.name || NO_NAME_MESSAGE;
