import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Button } from '~/shared/components/Button';

import TOKENS from '~/styles/__generated__/tokens.json';

import {
  BlueprintCycleInputNode,
  BlueprintInputNode,
} from '../../../../services';
import { NewInputNode } from '../../../NewInputNode';
import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';
import { InputNode } from '../InputNode';

interface Props extends ApiFeedbackProps {
  delete: () => void;
  inputs: BlueprintInputNode[];
  cycleInput: BlueprintCycleInputNode;
}

export const CycleInputNodeUI: React.FC<Props> = ({
  inputs,
  errors,
  loading,
  cycleInput,
  delete: deleteFunc,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const [showNewField, setShowNewField] = useState(false);

  const toggelNew = useCallback(() => {
    setShowNewField(val => !val);
  }, []);

  const newFieldCmpnt = showNewField ? (
    <NewInputNode
      key="new_input_node"
      cycleInputId={cycleInput.id}
      close={toggelNew}
    />
  ) : null;

  const inputComponents = inputs.map(input => (
    <InputNode key={input.id} input={input} />
  ));

  return (
    <NodeFrame
      header={
        <NodeHeader
          style={{
            color: TOKENS.colorNeutralOnNeutral,
          }}
          title={t(IntegratorStrings.blueprintEdit.nodes.cycleInputNode.title)}
          delete={deleteFunc}
        />
      }
      headerStyle={{
        background: TOKENS.colorNeutral400,
      }}
    >
      {errorMessage}
      {loader}

      <div className="p-8">
        {inputComponents}
        {newFieldCmpnt}
      </div>

      <div className="p-8">
        <Button onPress={toggelNew}>
          {t(
            IntegratorStrings.blueprintEdit.nodes.cycleInputNode.addInputButton
          )}
        </Button>
      </div>
    </NodeFrame>
  );
};
