import { useEffect, useRef } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

/**
 * Hook input props
 */
export interface UseInfiniteScrollProps extends IntersectionOptions {
  /**
   * Are items currently loading
   */
  isLoading?: boolean;
  /**
   * If true, than more items can be loaded
   */
  hasMore?: boolean;
  /**
   * Called, when new items should be loaded
   */
  onFetchMore?: () => void;
}

/**
 * When we trigger 'onFetchMore' and new items are added to the list,
 * right before they become rendered on the screen, 'isLoading' becomes false
 * and 'isInView' can be true for a brief time, based on the scroll position.
 * So, it triggers 'onFetchMore' just after the first one is finished.
 * We use a small delay here to prevent this kind of situations.
 */
const FETCH_MORE_DELAY_MS = 100;

/**
 * Infinite scroll, using intersection observer.
 * It returns 2 refs: rootRef to be applied to scroll container
 * and sentryRef to be applied to load detection element (it can be loader, for example)
 */
export const useInfiniteScroll = <
  RootElement extends HTMLElement = HTMLDivElement,
>({
  isLoading = false,
  hasMore = false,
  onFetchMore,

  ...intersectionOptions
}: UseInfiniteScrollProps) => {
  const rootRef = useRef<RootElement>(null);
  const [sentryRef, isInView] = useInView({
    root: rootRef.current,
    ...intersectionOptions,
  });

  const shouldLoadMore = !isLoading && isInView && hasMore;
  useEffect(() => {
    if (shouldLoadMore) {
      const timeout = setTimeout(() => {
        onFetchMore?.();
      }, FETCH_MORE_DELAY_MS);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [onFetchMore, shouldLoadMore]);

  return { rootRef, sentryRef };
};
