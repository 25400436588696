import {
  CartesianParsedData,
  ChartType,
  DefaultDataPoint,
  TooltipItem,
} from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { MDASH } from '~/shared/constants';
import { formatInt } from '~/shared/helpers/number';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { FormatMainValue, ReactChartDatasetConfig } from '../../types';
import styles from './index.module.scss';

interface Props<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Title for the tooltip, usually x axis name
   */
  title?: string;
  /**
   * Data points to display
   */
  dataPoints: TooltipItem<TType>[];
  /**
   * Datasets, used in the chart
   */
  datasets: ReactChartDatasetConfig<TType, TData>[];
  /**
   * If passed, all applies measurement unit for all main values, you can also configure it separately for each dataset
   */
  mainValueMeasurementUnit?: string;
  /**
   * Formatter for a point main value
   */
  formatMainValue?: FormatMainValue;
  /**
   * If true, renders color not from dataset, by from point config, cause pie charts have only one dataset
   */
  isPie?: boolean;
}

// We display long measurement units under the data point label
const MAX_MEASUREMENT_UNIT_LENGTH_FOR_INLINE = 2;

export const DataPointsTooltip = <
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
>({
  className,
  title,
  dataPoints,
  datasets,
  isPie = false,
  mainValueMeasurementUnit: mainValueMeasurementUnitComponent,
  formatMainValue: formatMainValueComponent = val => val?.toString() ?? '',
}: Props<TType, TData>) => {
  return (
    <div className={className}>
      <Typography
        {...{
          variant: TypographyVariants.descriptionLargeStrong,
          tag: 'h4',
          className: clsx(styles.title, 'mb-8'),
        }}
      >
        {title}
      </Typography>
      <ul className={clsx('grid', styles.tooltipContent)}>
        {dataPoints.map(point => {
          const currentDatasetConfig = datasets.at(point.datasetIndex);
          if (!currentDatasetConfig) return null;

          const { tooltipConfig = {}, label } = currentDatasetConfig;

          const {
            getMainValue = p => (p.parsed as CartesianParsedData).y,
            getMainValueMeasurementUnit,
            getCowsCount,
            renderLabel = R.always(label),
            formatMainValue: formatMainValueInner,
          } = tooltipConfig;
          const mainValue = getMainValue?.(point, currentDatasetConfig);
          const mainValueMeasurementUnitInner = getMainValueMeasurementUnit?.(
            point,
            currentDatasetConfig
          );
          const cowsCount = getCowsCount?.(point, currentDatasetConfig);

          const mainValueMeasurementUnit =
            mainValueMeasurementUnitInner ?? mainValueMeasurementUnitComponent;
          const formatMainValue =
            formatMainValueInner ?? formatMainValueComponent;

          const isSmallMeasurementUnit =
            !!mainValueMeasurementUnit &&
            mainValueMeasurementUnit.length <=
              MAX_MEASUREMENT_UNIT_LENGTH_FOR_INLINE;

          return (
            <Typography
              key={`${point.datasetIndex}__${point.dataIndex}`}
              {...{
                tag: 'li',
                variant: TypographyVariants.descriptionLarge,
                className: 'grid grid-cols-subgrid col-span-full items-center',
              }}
            >
              <div className="flex items-center pl-2 gap-4">
                <ColoredDot
                  size={ColoredDotSizes.small10}
                  color={
                    isPie
                      ? point.element.options.backgroundColor
                      : currentDatasetConfig.color
                  }
                />

                <span className="ellipsis">
                  {renderLabel(point, currentDatasetConfig)}
                </span>
              </div>

              <span className="text-right">
                {R.isNil(mainValue) && R.isNil(cowsCount) && MDASH}
                {!R.isNil(mainValue) && formatMainValue(mainValue)}
              </span>

              <span className="-ml-8">
                {!R.isNil(mainValue) && isSmallMeasurementUnit
                  ? mainValueMeasurementUnit
                  : ''}
              </span>

              <div className="flex items-center gap-2">
                {!R.isNil(cowsCount) && (
                  <>
                    <Icon
                      {...{
                        variant: IconVariants.cow,
                        size: SizeVariants.size12,
                        className: 'text-soft',
                      }}
                    />
                    {formatInt(cowsCount)}
                  </>
                )}
              </div>

              {!R.isNil(mainValue) &&
                !isSmallMeasurementUnit &&
                !!mainValueMeasurementUnit && (
                  <Typography
                    variant={TypographyVariants.descriptionSmall}
                    className="col-span-full pl-16 text-soft"
                  >
                    {mainValueMeasurementUnit}
                  </Typography>
                )}
            </Typography>
          );
        })}
      </ul>
    </div>
  );
};
