import React, { useCallback, useMemo } from 'react';

import { useImmerReducer } from 'use-immer';

import { ModalNames } from '../../__generated__/modalNames';
import { ModalsContext } from '../../context';
import { AnyModalProps, ModalRegistrationsDict } from '../../types';
import { ModalWrapper } from '../ModalWrapper';
import { makeInitialState, ModalActionTypes, reducer } from './reducer';

interface Props extends React.PropsWithChildren {
  /**
   * Dict of modal registration objects to render
   */
  modalRegistrationsDict: ModalRegistrationsDict;
}

export const ModalsProvider: React.FC<Props> = ({
  modalRegistrationsDict,
  children,
}) => {
  const [state, dispatch] = useImmerReducer(
    reducer,
    modalRegistrationsDict,
    makeInitialState
  );

  const modalNames = Object.entries(state)
    .sort(
      ([, modalLeft], [, modalRight]) =>
        modalLeft.stackIndex - modalRight.stackIndex
    )
    .map(([name]) => name) as ModalNames[];

  const openModal = useCallback((name: ModalNames, props?: AnyModalProps) => {
    dispatch({
      type: ModalActionTypes.open,
      payload: {
        name,
        props,
      },
    });
  }, []);

  const closeModal = useCallback((name: ModalNames) => {
    dispatch({
      type: ModalActionTypes.close,
      payload: {
        name,
      },
    });
  }, []);

  const setModalStep = useCallback((name: ModalNames, stepNumber: number) => {
    dispatch({
      type: ModalActionTypes.setStep,
      payload: {
        name,
        stepNumber,
      },
    });
  }, []);

  const value = useMemo(
    () => ({
      state,
      openModal,
      closeModal,
      setModalStep,
    }),
    [state]
  );

  return (
    <ModalsContext.Provider value={value}>
      {children}
      {modalNames.map(name => {
        return (
          <ModalWrapper
            key={name}
            {...{
              stackIndex: state[name].stackIndex,
              name,
              ...modalRegistrationsDict[name],
            }}
          />
        );
      })}
    </ModalsContext.Provider>
  );
};
