import React from 'react';

import clsx from 'clsx';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useNotifications } from '~/services/notifications';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Move cows copy key to copy
   */
  moveCowsKey: string;
}

export const CopyKeyItem: React.FC<Props> = ({ className, moveCowsKey }) => {
  const { sendNeutralToast } = useNotifications();

  return (
    <Typography
      className={clsx('flex gap-8', className)}
      variant={TypographyVariants.bodyMediumStrong}
    >
      {moveCowsKey}
      <FunctionButton
        {...{
          iconVariant: IconVariants.duplicate,
          onPress: () => {
            navigator.clipboard.writeText(moveCowsKey).then(() => {
              sendNeutralToast('Ключ скопирован');
            });
          },
        }}
      />
    </Typography>
  );
};
