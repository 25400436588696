import React, { ReactElement } from 'react';

import { BlueprintNodeType } from '../services';

const NewSectionNode = React.lazy(() =>
  import('../components/NewSectionNode').then(component => ({
    default: component.NewSectionNode,
  }))
);

const NewInputNode = React.lazy(() =>
  import('../components/NewInputNode').then(component => ({
    default: component.NewInputNode,
  }))
);

const NewActionNode = React.lazy(() =>
  import('../components/NewActionNode').then(component => ({
    default: component.NewActionNode,
  }))
);

const NewFilterGroupNode = React.lazy(() =>
  import('../components/NewFilterGroupNode').then(component => ({
    default: component.NewFilterGroupNode,
  }))
);

const NewFilterConditionNode = React.lazy(() =>
  import('../components/NewFilterConditionNode').then(component => ({
    default: component.NewFilterConditionNode,
  }))
);

const NewCycleInputsNode = React.lazy(() =>
  import('../components/NewCycleInputsNode').then(component => ({
    default: component.NewCycleInputsNode,
  }))
);

const elementsMap = {
  [BlueprintNodeType.Section]: cancel => (
    <NewSectionNode key="new_section" close={cancel} />
  ),
  [BlueprintNodeType.Input]: cancel => (
    <NewInputNode key="new_input" close={cancel} />
  ),
  [BlueprintNodeType.Action]: (cancel, parentNodeId) => (
    <NewActionNode
      key="new_action"
      close={cancel}
      parentNodeId={parentNodeId}
    />
  ),
  [BlueprintNodeType.FilterGroup]: (cancel, parentNodeId) => (
    <NewFilterGroupNode
      key="new_filter_group"
      close={cancel}
      parentNodeId={parentNodeId}
    />
  ),
  [BlueprintNodeType.FilterCondition]: (cancel, parentNodeId) => (
    <NewFilterConditionNode
      key="new_filter_condition"
      close={cancel}
      parentNodeId={parentNodeId}
    />
  ),
  [BlueprintNodeType.CycleInputs]: cancel => (
    <NewCycleInputsNode key="new_cycle_input" close={cancel} />
  ),
} as Record<
  BlueprintNodeType,
  (cancel: () => void, paretNodeId?: string) => ReactElement
>;

const emptyElm = () => <React.Fragment key="empty" />;

export function getEditElement(
  type: BlueprintNodeType,
  cancel: () => void,
  parentNodeId?: string
): ReactElement {
  const elementFn = elementsMap[type] || emptyElm;
  return elementFn(cancel, parentNodeId);
}
