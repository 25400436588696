import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyCutCodesMutationVariables = Types.Exact<{
  milkingParlorIntervalID: Types.Scalars['ID']['input'];
  date: Types.Scalars['Date']['input'];
  input: Types.ApplyCutCodeInput;
}>;


export type ApplyCutCodesMutation = { __typename?: 'Mutation', applyCutCodes?: any | null };


export const ApplyCutCodesDocument = gql`
    mutation applyCutCodes($milkingParlorIntervalID: ID!, $date: Date!, $input: ApplyCutCodeInput!) {
  applyCutCodes(
    milkingParlorIntervalID: $milkingParlorIntervalID
    date: $date
    input: $input
  )
}
    `;
export type ApplyCutCodesMutationFn = Apollo.MutationFunction<ApplyCutCodesMutation, ApplyCutCodesMutationVariables>;

/**
 * __useApplyCutCodesMutation__
 *
 * To run a mutation, you first call `useApplyCutCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCutCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCutCodesMutation, { data, loading, error }] = useApplyCutCodesMutation({
 *   variables: {
 *      milkingParlorIntervalID: // value for 'milkingParlorIntervalID'
 *      date: // value for 'date'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyCutCodesMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCutCodesMutation, ApplyCutCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCutCodesMutation, ApplyCutCodesMutationVariables>(ApplyCutCodesDocument, options);
      }
export type ApplyCutCodesMutationHookResult = ReturnType<typeof useApplyCutCodesMutation>;
export type ApplyCutCodesMutationResult = Apollo.MutationResult<ApplyCutCodesMutation>;
export type ApplyCutCodesMutationOptions = Apollo.BaseMutationOptions<ApplyCutCodesMutation, ApplyCutCodesMutationVariables>;