import {
  LactationGroupNumber,
  MilkingHistoricFilterKind,
} from '@graphql-types';

/**
 * Dict with lactation group numbers for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_LACTATION_GROUP_NUMBERS_DICT: Record<
  LactationGroupNumber,
  string
> = {
  [LactationGroupNumber.First]: '1-я лактация',
  [LactationGroupNumber.Second]: '2-я лактация',
  [LactationGroupNumber.Third]: '3-я лактация и старше',
};

/**
 * Dict with historic filter or grouping kinds for custom milking reports
 */
export const CUSTOM_MILKING_REPORT_HISTORIC_KINDS_DICT: Record<
  MilkingHistoricFilterKind,
  string
> = {
  [MilkingHistoricFilterKind.Lactation]: 'Лактация',
  [MilkingHistoricFilterKind.LactationGroup]: 'Группа лактации',
  [MilkingHistoricFilterKind.PenGroup]: 'Группа доения',
};
