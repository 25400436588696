import React, { ReactElement, ReactNode } from 'react';

import './MPanel.scss';

interface Props extends Omit<React.ComponentProps<'div'>, 'ref'> {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;

  elevation?: boolean;

  variant?: 'primary' | 'secondary';
  tag?: 'div' | 'ul';
}

export const MPanel = React.forwardRef<any, Props>((props, ref) => {
  const {
    children,
    className,
    elevation,
    onClick,
    tag = 'div',
    variant,
    style,
  } = props;

  const classNameVal = `m-panel ${elevation ? 'elevation' : ''} ${
    variant || ''
  } ${className || ''} `;

  const tags = {
    div: () => (
      <div ref={ref} className={classNameVal} style={style} onClick={onClick}>
        {children}
      </div>
    ),
    ul: () => (
      <ul ref={ref} className={classNameVal} style={style} onClick={onClick}>
        {children}
      </ul>
    ),
  } as Record<string, () => ReactElement>;

  const component = tags[tag];

  return component();
});
