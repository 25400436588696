import { InseminationResult } from '@graphql-types';

/**
 * Insemination result display values
 */
export const INSEMINATION_RESULTS_DICT: Record<InseminationResult, string> = {
  [InseminationResult.Abort]: 'Аборт',
  [InseminationResult.Conceive]: 'Стельная от др.',
  [InseminationResult.Estimate]: 'Живым быком',
  [InseminationResult.Null]: 'Не проверена',
  [InseminationResult.Open]: 'Нестельная',
  [InseminationResult.Preg]: 'Стельная',
  [InseminationResult.Rebred]: 'Переосемен.',
};
