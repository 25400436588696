import { VitalityFilter } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { IconVariants } from '~/shared/components/Icon';

import { useEditCowModal } from '~/entities/cows';
import { CowsTable } from '~/entities/cows/components/CowsTable';
import { useCowsPaginatedQuery } from '~/entities/cows/hooks/useCowsPaginatedQuery';

import { PageHeader } from '~/features/layouts';

import { useAddCowByKeyModal, useMoveCowsModal } from '~/widgets/cowsMovement';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { useUploadCowsCsvModal } from './modals';

const COW_ROW_SKELETON_COUNT = 12;

export const Route = createFileRoute('/$companyId/_layout/user/herd/cows/')({
  wrapInSuspense: true,
  component: HerdCowsPage,
});

function HerdCowsPage() {
  const navigate = Route.useNavigate();

  const { open: openAddCowByKeyModal } = useAddCowByKeyModal();
  const { open: openMoveCowsModal } = useMoveCowsModal();
  const { open: openEditCowModal } = useEditCowModal();
  const { open: openUploadCowsCsvModal } = useUploadCowsCsvModal();

  const { items: cowItems, ...asyncProps } = useCowsPaginatedQuery({
    variables: {
      vitalityFilter: VitalityFilter.All,
      search: '',
    },
  });

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Животные',
          rightContent: (
            <>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.key,
                  onPress: () =>
                    navigate({
                      to: '/$companyId/user/herd/cows/moveCowsKeys',
                    }),
                }}
              >
                Список ключей
              </Button>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.chevronRightCircle,
                  onPress: () => openMoveCowsModal(),
                }}
              >
                Переместить
              </Button>
              <ContextMenuButton
                {...{
                  buttonProps: {
                    children: 'Добавить',
                    iconVariant: IconVariants.plus,
                    variant: ButtonVariants.primary,
                    rightIconVariant: IconVariants.chevronDown,
                  },
                  items: [
                    {
                      onPress: openUploadCowsCsvModal,
                      content: 'Загрузить из файла',
                    },
                    {
                      onPress: openEditCowModal,
                      content: 'Создать новых',
                    },
                    {
                      onPress: openAddCowByKeyModal,
                      content: 'Добавить по ключу',
                    },
                  ],
                  isFunctionButton: false,
                }}
              />
            </>
          ),
        }}
      />
      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <CowsTable
          {...{
            items: cowItems,
            className: panelStyles.panel,
            skeletonItemsCount: COW_ROW_SKELETON_COUNT,
            ...asyncProps,
          }}
        />
      </div>
    </div>
  );
}
