import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../penGroups/gql/fragments/penGroupShort.graphql';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
export type TestMilkingFragment = { __typename: 'TestMilking', id: string, happenedAt: string, daysInMilk: number, weightKilograms: number, fatPercent?: number | null, proteinPercent?: number | null, sccThousandsPerMl?: number | null, ureaMgPerDl?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: number } | null, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } };

export const TestMilkingFragmentDoc = gql`
    fragment TestMilking on TestMilking {
  __typename
  id
  happenedAt
  daysInMilk
  weightKilograms
  fatPercent
  proteinPercent
  sccThousandsPerMl
  ureaMgPerDl
  penGroup {
    ...PenGroupShort
  }
  cow {
    ...CowShort
  }
}
    ${PenGroupShortFragmentDoc}
${CowShortFragmentDoc}`;