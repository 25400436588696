import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useDropDown } from '~/~legacy/hooks/useDropDown';
import { ListItem } from '~/~legacy/types/keyValue';

import { SEARCH_DEBOUNCE_MS } from '~/shared/constants';

import { MBackdrop } from '../MBackdrop';
import { MDropDownProps } from '../MDropDown';
import { MLoader } from '../MLoader';
import { MPanel } from '../MPanel';

import './MAutoComplete.scss';

interface Props<T> extends MDropDownProps<T> {
  searchKeyChanged?: (key: string) => void;
  loading?: boolean;
}

export const MAutoComplete = React.memo(
  React.forwardRef<any, Props<any>>((props, ref) => {
    const {
      items,
      onChange,
      selectedValue,
      className,
      isDisabled,
      isOpened,
      placeholder,
      noBorder,
      searchKeyChanged,
      loading,
      onBlur,
      onFocus,
      onPaste,
      getSelectedContend,
      noReset,
      onKeyDown,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const timerRef = useRef<NodeJS.Timeout>();

    const selectedElementRef = useRef<HTMLLIElement>(null);

    const [searchKey, setSerachKey] = useState('');
    const [listItems, setListitems] = useState<ListItem<any>[]>([]);

    const {
      opened,
      toggle,
      overlay,
      IconSVG,
      elementRef,
      keyDown,
      clearIcon,
      internalSelected,
      setFocus,
    } = useDropDown({
      isOpened,
      isDisabled,
      onFocus,
      onBlur,
      listItems,
      selectedValue,
      onChange,
      ref,
      noReset,
      onKeyDown,
    });

    useEffect(() => {
      const filteredItems = items.filter(
        item =>
          item.searchKey
            ?.toLocaleLowerCase()
            .indexOf(searchKey.toLocaleLowerCase()) !== -1
      );
      setListitems(filteredItems);
    }, [searchKey, items]);

    useEffect(() => {
      if (opened) {
        inputRef.current?.focus();
      }
    }, [opened]);

    const keyChangeCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        setSerachKey(key);

        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
          if (searchKeyChanged) searchKeyChanged(key);
        }, SEARCH_DEBOUNCE_MS);
      },
      [setSerachKey, searchKeyChanged]
    );

    const itemsToRender = useMemo(() => {
      return listItems.map((item, i) => {
        const selected =
          item.value === selectedValue || item.value === internalSelected;

        return (
          <li
            ref={selected ? selectedElementRef : undefined}
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.value}__${item.content?.toString()}__${i}`}
            onClick={() => {
              onChange(item.value);
              setSerachKey('');
              toggle();
              setFocus();
            }}
            className={`m-dropdown-list-item ${selected ? 'selected' : ''}`}
          >
            {item.content}
          </li>
        );
      });
    }, [listItems, selectedValue, onChange, elementRef, internalSelected]);

    const emptyElement = <>&nbsp;</>;
    const selectedItem = listItems.find(item => item.value === selectedValue);

    const alternative = selectedItem?.content || (
      <span className="text-muted">{placeholder || emptyElement}</span>
    );
    const condition = Boolean(
      getSelectedContend && (selectedItem || selectedValue)
    );

    const selectedContent =
      getSelectedContend && condition
        ? getSelectedContend(selectedItem?.value || selectedValue)
            ?.toString()
            .trim() || <>&nbsp;</>
        : alternative;

    const selectedElementTitle =
      typeof selectedContent === 'string' ? selectedContent : undefined;
    const selectedElement = (
      <div className="m-dropdown-content-selected">{selectedContent}</div>
    );
    const inputElement = (
      <input
        tabIndex={0}
        ref={inputRef}
        type="text"
        onChange={keyChangeCallback}
        value={searchKey}
      />
    );
    const editElement = opened ? inputElement : selectedElement;
    const clickOverlay = opened ? null : (
      <div className="m-click-overlay" onClick={toggle} />
    );

    const loader = loading ? <MLoader /> : null;
    const backdrop = loading ? <MBackdrop /> : null;

    useEffect(() => {
      if (selectedElementRef.current) {
        selectedElementRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }, [selectedElement]);

    const onParentFocus = useCallback(() => {
      inputRef.current?.focus();
    }, [inputRef.current]);

    return (
      <>
        {overlay}
        <div
          onPaste={onPaste}
          tabIndex={0}
          className={`m-autocomplete ${isDisabled ? 'disabled' : ''}  ${
            className || ''
          } m-control ${noBorder ? 'no-border' : ''} `}
          onFocus={onParentFocus}
          onKeyDown={keyDown}
          title={selectedElementTitle}
          ref={elementRef}
        >
          <div className={`m-dropdown-content `}>
            {editElement}
            {clickOverlay}
            <div className="m-dropdown-content-toggle">
              {clearIcon}
              <IconSVG onClick={toggle} />
            </div>
          </div>
          <MPanel
            elevation
            tag="ul"
            className={`m-dropdown-list ${opened ? 'opened' : ''}`}
          >
            {itemsToRender}
            {backdrop}
          </MPanel>
          {loader}
        </div>
      </>
    );
  })
);
