import React, { useEffect, useMemo, useState } from 'react';

import { ListItem } from '~/~legacy/types/keyValue';

import {
  BlueprintEditService,
  BlueprintSourceSectionField,
} from '../../services';
import styles from './index.module.scss';

export function useSourceFields(bpEditSvc: BlueprintEditService) {
  const [sourceFields, setSourceFields] = useState<
    ListItem<BlueprintSourceSectionField>[]
  >([]);
  const [masterFields, setMasterFields] = useState<
    ListItem<BlueprintSourceSectionField>[]
  >([]);

  useEffect(() => {
    const subscription = bpEditSvc.blueprint?.sourceNodes$.subscribe(
      sections => {
        const flatFields: BlueprintSourceSectionField[] = [];
        sections.forEach(section => {
          flatFields.push(...section.fields);
        });

        setSourceFields(
          flatFields.map(field => {
            return {
              value: field,
              content: field.name,
              searchKey: field.name,
            };
          })
        );
      }
    );

    const masterFieldsSubscription =
      bpEditSvc.blueprint?.masterSourceNodes$.subscribe(sections => {
        const flatFields: BlueprintSourceSectionField[] = [];
        sections.forEach(section => {
          flatFields.push(...section.fields);
        });

        setMasterFields(
          flatFields.map(field => {
            return {
              value: field,
              content: <span className={styles.field}>{field.name}</span>,
              searchKey: field.name,
            };
          })
        );
      });

    return () => {
      subscription?.unsubscribe();
      masterFieldsSubscription?.unsubscribe();
    };
  }, [bpEditSvc]);

  const fields = useMemo(() => {
    return [...sourceFields, ...masterFields];
  }, [sourceFields, masterFields]);

  return fields;
}
