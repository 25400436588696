import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CowsCopyReasonEnum } from '@graphql-types';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { formatDate } from '~/services/dateTime';

import { useFormatMoveToCompanyTitle } from '../../../../hooks';
import { MoveCowsFormType } from '../../types';
import styles from './index.module.scss';

export const InfoCard: React.FC = () => {
  const formContext = useFormContext<MoveCowsFormType>();

  const [cowIDs, departDate, toCompanyID, toCompanyName, toCompanyInn, reason] =
    formContext.watch([
      'cowIDs',
      'departDate',
      'toCompanyID',
      'toCompanyName',
      'toCompanyInn',
      'reason',
    ]);

  const companyTitle = useFormatMoveToCompanyTitle({
    toCompanyName,
    toCompanyID,
    toCompanyInn,
  });

  const infoCardItems: {
    iconVariant: IconVariants;
    title: string | number;
  }[] = [
    { iconVariant: IconVariants.calendar, title: formatDate(departDate) },
    { iconVariant: IconVariants.company, title: companyTitle },
    { iconVariant: IconVariants.cow, title: cowIDs.length },
  ];

  return (
    <div className={styles.root}>
      <Typography variant={TypographyVariants.bodyMediumStrong}>
        {reason === CowsCopyReasonEnum.Sell
          ? 'Информация о продаже'
          : 'Информация о перемещении'}
      </Typography>
      <div className={styles.moveInfoContent}>
        {infoCardItems.map(({ iconVariant, title }) => (
          <Typography
            key={iconVariant}
            {...{
              className: 'flex gap-8',
              variant: TypographyVariants.bodyMedium,
            }}
          >
            <Icon variant={iconVariant} />
            {title}
          </Typography>
        ))}
      </div>
    </div>
  );
};
