import React from 'react';

import { MEditToggle } from '~/shared/components/MEditToggle';

import { VariableEditProps } from './VariableEditor';

export const VariableView: React.FC<VariableEditProps<string>> = props => {
  const { value, toggle, inputs, fields, masterFields } = props;

  const input = inputs.find(x => x.id === value);
  const field = fields.find(x => x.id === value);
  const masterField = masterFields.find(x => x.id === value);

  const valueToRender = input?.name || field?.name || masterField?.name;

  const text = value ? (
    <>
      <i>Переменная:</i>&nbsp; {valueToRender}
    </>
  ) : (
    'Значение не установлено'
  );

  return (
    <div className="m-editor">
      <p>{text}</p>

      <MEditToggle toggle={toggle} />
    </div>
  );
};
