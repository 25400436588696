import React from 'react';

import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import panelStyles from '~/styles/modules/panel.module.scss';

import { isCustomMilkingReportDataEmpty } from '../../helpers';
import {
  CustomMilkingReportDataType,
  CustomMilkingReportSettingsFormType,
} from '../../types';
import { CustomMilkingReportChart } from './components/CustomMilkingReportChart';
import { CustomMilkingReportTable } from './components/CustomMilkingReportTable';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Chart data to render
   */
  reportData: CustomMilkingReportDataType | SkeletonPlaceholder;
  /**
   * Current settings of the report
   */
  requestedSettings: CustomMilkingReportSettingsFormType;
}

export const CustomMilkingReportData: React.FC<Props> = React.memo(
  ({ className, reportData, requestedSettings }) => {
    return (
      <div className={clsx('flex flex-col gap-24', className)}>
        {isCustomMilkingReportDataEmpty(reportData) && (
          <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
            <DataBlockedMessage
              {...{
                className: 'p-24',
                message: 'Нет данных для отображения отчёта',
              }}
            />
          </div>
        )}

        {!isCustomMilkingReportDataEmpty(reportData) && (
          <>
            <CustomMilkingReportChart
              {...{
                reportData,
                requestedSettings,
              }}
            />
            <CustomMilkingReportTable
              {...{
                reportData,
                requestedSettings,
              }}
            />
          </>
        )}
      </div>
    );
  }
);
