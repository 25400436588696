import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionHdrAndPrReportFragmentDoc } from '../fragments/reproductionHdrAndPrReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReproductionHdrAndPrReportsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  roleIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ReproductionHdrAndPrReportsQuery = { __typename?: 'Query', reproductionHdrAndPrReports: { __typename?: 'ReproductionHdrAndPrReportConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'ReproductionHdrAndPrReportEdge', cursor: string, node: { __typename: 'ReproductionHdrAndPrReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } }> } };


export const ReproductionHdrAndPrReportsDocument = gql`
    query reproductionHdrAndPrReports($first: Int, $after: String, $ids: [ID!], $roleIDs: [ID!], $search: String) {
  reproductionHdrAndPrReports(
    first: $first
    after: $after
    ids: $ids
    roleIDs: $roleIDs
    search: $search
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...ReproductionHdrAndPrReport
      }
    }
  }
}
    ${ReproductionHdrAndPrReportFragmentDoc}`;

/**
 * __useReproductionHdrAndPrReportsQuery__
 *
 * To run a query within a React component, call `useReproductionHdrAndPrReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReproductionHdrAndPrReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReproductionHdrAndPrReportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      ids: // value for 'ids'
 *      roleIDs: // value for 'roleIDs'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReproductionHdrAndPrReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>(ReproductionHdrAndPrReportsDocument, options);
      }
export function useReproductionHdrAndPrReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>(ReproductionHdrAndPrReportsDocument, options);
        }
export function useReproductionHdrAndPrReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>(ReproductionHdrAndPrReportsDocument, options);
        }
export type ReproductionHdrAndPrReportsQueryHookResult = ReturnType<typeof useReproductionHdrAndPrReportsQuery>;
export type ReproductionHdrAndPrReportsLazyQueryHookResult = ReturnType<typeof useReproductionHdrAndPrReportsLazyQuery>;
export type ReproductionHdrAndPrReportsSuspenseQueryHookResult = ReturnType<typeof useReproductionHdrAndPrReportsSuspenseQuery>;
export type ReproductionHdrAndPrReportsQueryResult = Apollo.QueryResult<ReproductionHdrAndPrReportsQuery, ReproductionHdrAndPrReportsQueryVariables>;