import React from 'react';

import { useProgressBar } from '@react-aria/progress';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';

interface Props {
  /**
   * Count of steps
   */
  stepsCount: number;
  /**
   * Current step number
   */
  stepNumber: number;
  /**
   * className applied to the root element
   */
  className?: string;
}

export const ProgressBar: React.FC<Props> = ({
  className,
  stepNumber,
  stepsCount,
}) => {
  const valueInPercentage = Math.round((stepNumber / stepsCount) * 100);
  const label = `Шаг ${stepNumber} из ${stepsCount}`;

  const { progressBarProps, labelProps } = useProgressBar({
    label,
    minValue: 0,
    maxValue: 100,
    value: valueInPercentage,
  });

  return (
    <div
      {...{
        className,
        ...progressBarProps,
      }}
    >
      <Typography
        {...{
          variant: TypographyVariants.descriptionLarge,
          ...labelProps,
        }}
      >
        {`Шаг ${stepNumber} из ${stepsCount}`}
      </Typography>
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBarValue}
          style={{ width: `${valueInPercentage}%` }}
        />
      </div>
    </div>
  );
};
