import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { Checkbox } from '~/shared/components/Checkbox';
import { ColoredDot } from '~/shared/components/ColoredDot';
import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { SizeVariants } from '~/styles/__generated__/token-variants';

import { ChartLegendItem } from '../../types';

interface Props<Key extends string = string> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Legend items to display
   */
  items: ChartLegendItem<Key>[];
  /**
   * Optional legend title, used to shorten dataset names
   */
  title?: ReactNode;
  /**
   * Called, when an item with checkbox is toggled
   */
  onItemToggle?: (datasetIndex: number, isSelected: boolean) => void;
}

const LEGEND_SKELETON_ITEMS = 4;

export const ChartLegend = <Key extends string = string>({
  className,
  items,
  title,
  onItemToggle,
}: Props<Key>) => {
  const { isLoading } = useSkeletonContext();

  const itemsToRender =
    !isLoading || !!items.length
      ? items
      : getSkeletonPlaceholders(LEGEND_SKELETON_ITEMS);
  return (
    <div className={clsx('grid gap-16', className)}>
      {!!title && (
        <Typography
          withSkeleton={false}
          variant={TypographyVariants.descriptionLargeStrong}
        >
          {title}
        </Typography>
      )}
      <div className="flex gap-16 flex-wrap">
        {itemsToRender.map((item, datasetIndex) => (
          <div
            key={isSkeletonPlaceholder(item) ? item.id : item.key}
            {...{
              className: 'flex items-center gap-8',
            }}
          >
            {!isSkeletonPlaceholder(item) && item.isTogglable && (
              <Checkbox
                {...{
                  name: item.key,
                  label: item.label,
                  size: SizeVariants.size16,
                  color: item.color,
                  hoverColor: item.hoverColor,
                  pressedColor: item.pressedColor,
                  defaultValue: true,
                  onValueChange: newValue =>
                    onItemToggle?.(datasetIndex, newValue),
                }}
              />
            )}
            {!item.isTogglable && (
              <>
                <ColoredDot
                  {...{
                    color: item.color,
                    borderColor: item.borderColor,
                  }}
                />
                <Typography
                  {...{
                    variant: TypographyVariants.descriptionLarge,
                    skeletonProps: {
                      className: 'py-2',
                    },
                  }}
                >
                  {item.label}
                </Typography>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
