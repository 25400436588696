import React from 'react';

import { StandardView } from '~/~legacy/value-editors/components/StandardView';
import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

export const FloatView: React.FC<
  ValueEditorStandardProps<number | string>
> = props => {
  const { toggle, value, caption, hideControls } = props;
  return (
    <StandardView
      caption={caption}
      hideControls={hideControls}
      toggle={toggle}
      value={value}
    />
  );
};
