import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { MaslovServices } from '~/~legacy/types/services';

import { Button } from '~/shared/components/Button';
import { MErrorMessage } from '~/shared/components/MErrorMessage';
import { MLoader } from '~/shared/components/MLoader';

import TOKENS from '~/styles/__generated__/tokens.json';

import {
  BlueprintEditService,
  BlueprintNodeType,
  BlueprintSourceSection,
} from '../../../../services';
import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';
import { FieldNode } from '../FieldNode';
import { NewFieldNode } from '../NewFieldNode';

interface Props {
  section: BlueprintSourceSection;
}

export const SectionNode: React.FC<Props> = ({ section }) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const {
    loading: deleting,
    errors: deleteErrors,
    reload: deleteFunc,
  } = useApiData(bpEditSvc.deleteNode.bind(bpEditSvc));

  const [showNewField, setShowNewField] = useState(false);

  const toggelNew = useCallback(() => {
    setShowNewField(val => !val);
  }, []);

  const fields = section.fields.map(field => (
    <FieldNode key={field.id} field={field} />
  ));

  const newFieldCmpnt = showNewField ? (
    <NewFieldNode
      cancel={toggelNew}
      sectionType={section.kind}
      sectionNodeId={section.id}
    />
  ) : null;

  const deleteCallback = useCallback(() => {
    deleteFunc({
      nodeId: section.id,
      nodeType: BlueprintNodeType.Section,
    });
  }, []);

  const loader = deleting ? <MLoader /> : null;
  const errors =
    deleteErrors.length > 0 ? (
      <MErrorMessage>{deleteErrors.join(' ')}</MErrorMessage>
    ) : null;

  const header = (
    <NodeHeader title={section.verboseName} delete={deleteCallback} />
  );

  return (
    <NodeFrame
      className="my-8"
      headerStyle={{
        background: TOKENS.colorAccent150,
      }}
      header={header}
    >
      {errors}

      <div className="p-8">
        {fields}
        {newFieldCmpnt}
      </div>

      <div className="p-8">
        <Button onPress={toggelNew}>
          {t(IntegratorStrings.blueprintEdit.source.addFieldButton)}
        </Button>
      </div>

      {loader}
    </NodeFrame>
  );
};
