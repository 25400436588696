import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import TOKENS from '~/styles/__generated__/tokens.json';

import { BlueprintInputNode } from '../../../../services';
import { InputNodeEditForm } from '../../../InputNodeEditForm';
import { NodeFrame } from '../../../NodeFrame';

interface Props extends ApiFeedbackProps {
  cancel: () => void;
  submit: (input: BlueprintInputNode) => void;
}

export const NewInputNodeUI: React.FC<Props> = ({
  cancel,
  submit,
  errors,
  loading,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const { loader, errorMessage } = useApiFeedback(errors, loading);

  return (
    <NodeFrame
      headerStyle={{
        background: TOKENS.colorNeutral150,
      }}
      header={t(IntegratorStrings.blueprintEdit.nodes.userInputNode.title)}
    >
      <InputNodeEditForm cancel={cancel} submit={submit} />
      <div className="p-8">
        {loader}
        {errorMessage}
      </div>
    </NodeFrame>
  );
};
