import React from 'react';

export const HelpCircleFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8 2C11.3333 2 14 4.66667 14 8C14 11.3333 11.3333 14 8 14C4.66667 14 2 11.3333 2 8C2 4.66667 4.66667 2 8 2Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99967 10C7.59967 10 7.33301 10.2667 7.33301 10.6667C7.33301 11.0667 7.59967 11.3333 7.99967 11.3333C8.39967 11.3333 8.66634 11.0667 8.66634 10.6667C8.66634 10.2667 8.39967 10 7.99967 10Z"
      fill="white"
    />
    <path
      d="M7.99967 8.66732C8.93301 8.66732 9.66634 7.93398 9.66634 7.00065C9.66634 6.06732 8.93301 5.33398 7.99967 5.33398C7.06634 5.33398 6.33301 6.06732 6.33301 7.00065"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
