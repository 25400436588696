import React, { useCallback } from 'react';

import clsx from 'clsx';

import { BlueprintCycleInputField } from '~/~legacy/services/BlueprintExecutionService';

import { Button } from '~/shared/components/Button';
import { MPanel } from '~/shared/components/MPanel';

import { MoveDirection } from '../../types';
import {
  CellCoordinates,
  FocusChange,
} from '../BlueprintForms/BlueprintForms.reducer';
import { UserCycleInputRow } from '../UserCycleInputRow';
import styles from './index.module.scss';

interface Props {
  cycleInput: BlueprintCycleInputField;
  activeCell?: CellCoordinates;

  onChange: (
    cycleInput: BlueprintCycleInputField,
    rowIndex: number,
    fieldIndex: number,
    val: any
  ) => void;
  paste: (
    cycleInput: BlueprintCycleInputField,
    rowIndex: number,
    fieldIndex: number,
    data: React.ClipboardEvent
  ) => void;
  addRow: (cycleInput: BlueprintCycleInputField) => void;
  delRow: (cycleInput: BlueprintCycleInputField, index: number) => void;

  focusHandler: (focusChangeEvent: FocusChange) => void;
}

export const UserCycleInput: React.FC<Props> = ({
  cycleInput,
  activeCell,
  onChange,
  addRow,
  delRow,
  paste,
  focusHandler,
}) => {
  const headers = cycleInput.inputs.map(x => <th key={x.id}> {x.name} </th>);

  const add = useCallback(() => {
    addRow(cycleInput);
  }, [addRow, cycleInput]);

  const blur = useCallback(() => {
    focusHandler({
      cycleInput,
      direction: MoveDirection.none,
    });
  }, [focusHandler, cycleInput]);

  const rows = cycleInput.userInput.map((row, rowIndex) => {
    const deleteCallback = () => {
      delRow(cycleInput, rowIndex);
    };

    const onFocus = (cell: CellCoordinates) => {
      focusHandler({
        direction: MoveDirection.none,
        cell,
        cycleInput,
      });
    };
    const focusMoved = (cell: CellCoordinates, direction: MoveDirection) => {
      focusHandler({
        direction,
        cycleInput,
        cell,
      });
    };

    const enterPressed = () => {
      const lastIndex = rowIndex === cycleInput.userInput.length - 1;
      if (lastIndex) {
        add();
      }
    };

    // zero indexed value is tempId
    return (
      <UserCycleInputRow
        key={row[0]}
        row={row}
        rowIndex={rowIndex}
        cycleInput={cycleInput}
        del={deleteCallback}
        onChange={onChange}
        paste={paste}
        activeCell={activeCell}
        onFocus={onFocus}
        focusMoved={focusMoved}
        enterPressed={enterPressed}
      />
    );
  });

  return (
    <MPanel className={clsx('my-16 no-panel', styles.root)}>
      <table onBlur={blur}>
        <thead>
          <tr>
            {headers}
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <Button className="mt-16" onPress={add}>
        Добавить запись
      </Button>
    </MPanel>
  );
};
