import React from 'react';

export const CheckCircleFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM16.5614 9.49728C16.8361 9.18721 16.8074 8.71321 16.4973 8.43857C16.1872 8.16393 15.7132 8.19266 15.4386 8.50273L10.6827 13.8722L8.5588 11.4998C8.28252 11.1911 7.80837 11.1649 7.49976 11.4412C7.19114 11.7175 7.16493 12.1916 7.44122 12.5003L10.1269 15.5003C10.4217 15.8295 10.9542 15.8281 11.2472 15.4973L16.5614 9.49728Z"
      fill="currentColor"
    />
  </svg>
);
