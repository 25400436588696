import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type SomaticCellsReportChartFragment = { __typename: 'SomaticCellsReportChart', dates: Array<string>, researchedAnimalsCounts: Array<number | null>, chronicallyIllPercents: Array<number | null>, chronicallyIllCounts: Array<number | null>, illPercents: Array<number | null>, illCounts: Array<number | null>, recoveredPercents: Array<number | null>, recoveredCounts: Array<number | null>, healthyPercents: Array<number | null>, healthyCounts: Array<number | null>, highValueFirstTestMilkingPercents: Array<number | null>, highValueFirstTestMilkingCounts: Array<number | null>, normalValueFirstTestMilkingPercents: Array<number | null>, normalValueFirstTestMilkingCounts: Array<number | null>, probabilityOfRecoveryPercents: Array<number | null>, probabilityOfIllnessPercents: Array<number | null>, cacheCreatedAt: string };

export const SomaticCellsReportChartFragmentDoc = gql`
    fragment SomaticCellsReportChart on SomaticCellsReportChart {
  __typename
  dates
  researchedAnimalsCounts
  chronicallyIllPercents
  chronicallyIllCounts
  illPercents
  illCounts
  recoveredPercents
  recoveredCounts
  healthyPercents
  healthyCounts
  highValueFirstTestMilkingPercents
  highValueFirstTestMilkingCounts
  normalValueFirstTestMilkingPercents
  normalValueFirstTestMilkingCounts
  probabilityOfRecoveryPercents
  probabilityOfIllnessPercents
  cacheCreatedAt
}
    `;