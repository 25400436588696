/**
 * Delay before showing skeleton, when updating data.
 * We don't show it to early, cause small delay seems smother this way.
 */
export const SKELETON_SHOW_DELAY_MS = 1000;

/**
 * Min skeleton show time to avoid flashing
 */
export const MIN_SKELETON_SHOW_MS = 500;
