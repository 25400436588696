import React from 'react';

export const CalendarCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.7872 11.6509C16.0483 11.8707 16.0725 12.2501 15.8412 12.4983L11.366 17.3007C11.1193 17.5655 10.6709 17.5666 10.4227 17.3031L8.16102 14.9019C7.92837 14.6549 7.95044 14.2754 8.21032 14.0542C8.4702 13.8331 8.86949 13.8541 9.10214 14.1011L10.8907 16L14.8956 11.7023C15.1269 11.4541 15.5261 11.4311 15.7872 11.6509Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 1C16.25 0.585786 15.9142 0.25 15.5 0.25C15.0858 0.25 14.75 0.585786 14.75 1V2.25H9.25V1C9.25 0.585786 8.91421 0.25 8.5 0.25C8.08579 0.25 7.75 0.585786 7.75 1V2.25H7C4.38579 2.25 2.25 4.38579 2.25 7V17C2.25 19.6142 4.38579 21.75 7 21.75H17C19.6142 21.75 21.75 19.6142 21.75 17V7C21.75 4.38579 19.6142 2.25 17 2.25H16.25V1ZM7.75 5V3.75H7C5.21421 3.75 3.75 5.21421 3.75 7V8.25H20.25V7C20.25 5.21421 18.7858 3.75 17 3.75H16.25V5C16.25 5.41421 15.9142 5.75 15.5 5.75C15.0858 5.75 14.75 5.41421 14.75 5V3.75H9.25V5C9.25 5.41421 8.91421 5.75 8.5 5.75C8.08579 5.75 7.75 5.41421 7.75 5ZM20.25 17V9.75H3.75V17C3.75 18.7858 5.21421 20.25 7 20.25H17C18.7858 20.25 20.25 18.7858 20.25 17Z"
      fill="currentColor"
    />
  </svg>
);
