import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DiseaseFragmentDoc } from '../fragments/disease.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDiseaseMutationVariables = Types.Exact<{
  input: Types.CreateDiseaseInput;
}>;


export type CreateDiseaseMutation = { __typename?: 'Mutation', createDisease: { __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> } };


export const CreateDiseaseDocument = gql`
    mutation createDisease($input: CreateDiseaseInput!) {
  createDisease(input: $input) {
    ...Disease
  }
}
    ${DiseaseFragmentDoc}`;
export type CreateDiseaseMutationFn = Apollo.MutationFunction<CreateDiseaseMutation, CreateDiseaseMutationVariables>;

/**
 * __useCreateDiseaseMutation__
 *
 * To run a mutation, you first call `useCreateDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiseaseMutation, { data, loading, error }] = useCreateDiseaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiseaseMutation, CreateDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiseaseMutation, CreateDiseaseMutationVariables>(CreateDiseaseDocument, options);
      }
export type CreateDiseaseMutationHookResult = ReturnType<typeof useCreateDiseaseMutation>;
export type CreateDiseaseMutationResult = Apollo.MutationResult<CreateDiseaseMutation>;
export type CreateDiseaseMutationOptions = Apollo.BaseMutationOptions<CreateDiseaseMutation, CreateDiseaseMutationVariables>;