import React from 'react';

import clsx from 'clsx';

import { BlueprintExecutionInfo } from '~/~legacy/services/BlueprintExecutionService';

import { MLinkButton } from '~/shared/components/MLinkButton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

interface Props {
  executionInfo: BlueprintExecutionInfo;
  cancel: () => void;
}

export const EmptyExecutionResult: React.FC<Props> = ({
  executionInfo,
  cancel,
}) => {
  return (
    <div
      className={clsx(
        layoutStyles.fullHeightContainer,
        layoutStyles.limitedContainer,
        'grid place-items-center'
      )}
    >
      <div className={clsx(panelStyles.panel, 'p-16')}>
        <Typography variant={TypographyVariants.heading4} className="mb-8">
          Блюпринт [{executionInfo.name}] исполнен
        </Typography>
        <MLinkButton onClick={cancel} className="text-center ">
          Перейти в календарь
        </MLinkButton>
      </div>
    </div>
  );
};
