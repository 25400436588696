import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MilkingParlorMeterFunctionReportToleranceRowEntryFragment = { __typename: 'MilkingParlorMeterFunctionReportToleranceRowEntry', cowsCount?: number | null, milkDeviationFromExpectedKilograms?: number | null, milkWeightKilograms?: number | null, durationSeconds?: number | null, flowRate?: number | null, conductivity?: number | null, avgPeakFlowRate?: number | null, fallCount?: number | null, manualModeCount?: number | null, manualDetachCount?: number | null };

export const MilkingParlorMeterFunctionReportToleranceRowEntryFragmentDoc = gql`
    fragment MilkingParlorMeterFunctionReportToleranceRowEntry on MilkingParlorMeterFunctionReportToleranceRowEntry {
  __typename
  cowsCount
  milkDeviationFromExpectedKilograms
  milkWeightKilograms
  durationSeconds
  flowRate
  conductivity
  avgPeakFlowRate
  fallCount
  manualModeCount
  manualDetachCount
}
    `;