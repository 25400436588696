import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProtocolInjectionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateProtocolInjectionInput;
}>;


export type UpdateProtocolInjectionMutation = { __typename?: 'Mutation', updateProtocolInjection?: any | null };


export const UpdateProtocolInjectionDocument = gql`
    mutation updateProtocolInjection($id: ID!, $input: UpdateProtocolInjectionInput!) {
  updateProtocolInjection(id: $id, input: $input)
}
    `;
export type UpdateProtocolInjectionMutationFn = Apollo.MutationFunction<UpdateProtocolInjectionMutation, UpdateProtocolInjectionMutationVariables>;

/**
 * __useUpdateProtocolInjectionMutation__
 *
 * To run a mutation, you first call `useUpdateProtocolInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProtocolInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProtocolInjectionMutation, { data, loading, error }] = useUpdateProtocolInjectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProtocolInjectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProtocolInjectionMutation, UpdateProtocolInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProtocolInjectionMutation, UpdateProtocolInjectionMutationVariables>(UpdateProtocolInjectionDocument, options);
      }
export type UpdateProtocolInjectionMutationHookResult = ReturnType<typeof useUpdateProtocolInjectionMutation>;
export type UpdateProtocolInjectionMutationResult = Apollo.MutationResult<UpdateProtocolInjectionMutation>;
export type UpdateProtocolInjectionMutationOptions = Apollo.BaseMutationOptions<UpdateProtocolInjectionMutation, UpdateProtocolInjectionMutationVariables>;