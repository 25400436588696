import React from 'react';

import './MTextArea.scss';

interface Props {
  value: string;
  placeholder?: string;
  className?: string;

  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const MTextArea: React.FC<Props> = React.memo(props => {
  const { value, placeholder, className, onChange } = props;

  return (
    <textarea
      className={`m-textarea m-control ${className || ''}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
});
