import * as Types from '../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowMilkParlorStatsRowFragmentDoc } from '../fragments/cowMilkParlorStatsRow.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkParlorStatsQueryVariables = Types.Exact<{
  cowID: Types.Scalars['ID']['input'];
}>;


export type MilkParlorStatsQuery = { __typename?: 'Query', milkParlorStats: Array<{ __typename: 'CowMilkParlorStatsRow', name: string, expectedWeightKilograms: number, deviationWeightKilograms: number, averageWeightKilograms: number, dates: Array<{ __typename?: 'CowMilkParlorStatsOnDate', date: string, weightKilograms?: number | null, kind: Types.MilkParlorStatsDateKind }> }> };


export const MilkParlorStatsDocument = gql`
    query milkParlorStats($cowID: ID!) {
  milkParlorStats(cowID: $cowID) {
    ...CowMilkParlorStatsRow
  }
}
    ${CowMilkParlorStatsRowFragmentDoc}`;

/**
 * __useMilkParlorStatsQuery__
 *
 * To run a query within a React component, call `useMilkParlorStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkParlorStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkParlorStatsQuery({
 *   variables: {
 *      cowID: // value for 'cowID'
 *   },
 * });
 */
export function useMilkParlorStatsQuery(baseOptions: Apollo.QueryHookOptions<MilkParlorStatsQuery, MilkParlorStatsQueryVariables> & ({ variables: MilkParlorStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkParlorStatsQuery, MilkParlorStatsQueryVariables>(MilkParlorStatsDocument, options);
      }
export function useMilkParlorStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkParlorStatsQuery, MilkParlorStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkParlorStatsQuery, MilkParlorStatsQueryVariables>(MilkParlorStatsDocument, options);
        }
export function useMilkParlorStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkParlorStatsQuery, MilkParlorStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkParlorStatsQuery, MilkParlorStatsQueryVariables>(MilkParlorStatsDocument, options);
        }
export type MilkParlorStatsQueryHookResult = ReturnType<typeof useMilkParlorStatsQuery>;
export type MilkParlorStatsLazyQueryHookResult = ReturnType<typeof useMilkParlorStatsLazyQuery>;
export type MilkParlorStatsSuspenseQueryHookResult = ReturnType<typeof useMilkParlorStatsSuspenseQuery>;
export type MilkParlorStatsQueryResult = Apollo.QueryResult<MilkParlorStatsQuery, MilkParlorStatsQueryVariables>;