import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BlueprintInputFragment = { __typename: 'BlueprintInput', id: string, name: string, prompt: string, valueKinds: Array<Types.ValueKindEnum> };

export const BlueprintInputFragmentDoc = gql`
    fragment BlueprintInput on BlueprintInput {
  __typename
  id
  name
  prompt
  valueKinds
}
    `;