import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BullFragmentDoc } from '../../../bulls/gql/fragments/bull.graphql';
export type SemenDoseFragment = { __typename: 'SemenDose', id: string, deliveryDate?: string | null, studCode: string, dosesCount: number, batchNumber: string, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } };

export const SemenDoseFragmentDoc = gql`
    fragment SemenDose on SemenDose {
  __typename
  id
  deliveryDate
  studCode
  dosesCount
  batchNumber
  bull {
    ...Bull
  }
}
    ${BullFragmentDoc}`;