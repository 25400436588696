import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintShortFragmentDoc } from '../../../blueprints/gql/fragments/blueprintShort.graphql';
export type BlueprintLaunchFragment = { __typename: 'BlueprintLaunch', id: string, hasEvents: boolean, cowsCount: number, createdAt: string, user: { __typename: 'User', id: string, firstName: string, lastName: string, middleName: string }, blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string } | null };

export const BlueprintLaunchFragmentDoc = gql`
    fragment BlueprintLaunch on BlueprintLaunch {
  __typename
  id
  user {
    __typename
    id
    firstName
    lastName
    middleName
  }
  blueprint {
    ...BlueprintShort
  }
  hasEvents
  cowsCount
  createdAt
}
    ${BlueprintShortFragmentDoc}`;