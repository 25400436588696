import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milking-parlors/intervals/'
)({
  loader: () =>
    redirect({
      to: '/$companyId/user/entities/milking-parlors',
      from: Route.fullPath,
    }),
});
