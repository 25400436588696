import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmWithReproductionSettingsFragmentDoc } from '../fragments/farmWithReproductionSettings.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FarmsWithReproductionSettingsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type FarmsWithReproductionSettingsQuery = { __typename?: 'Query', farms: { __typename?: 'FarmConnection', pageInfo: { __typename?: 'BeriaPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes: Array<{ __typename: 'Farm', id: string, name: string, number: string, reproductionSettings: { __typename?: 'ReproductionSettings', cowsVoluntaryWaitingPeriodDays: number, heifersVoluntaryWaitingPeriodDays: number }, reproductionTargets: { __typename?: 'ReproductionTargets', hdrCowsTargetPercent: number, hdrCowsDeviationThresholdPercent: number, hdrHeifersTargetPercent: number, hdrHeifersDeviationThresholdPercent: number, prCowsTargetPercent: number, prCowsDeviationThresholdPercent: number, prHeifersTargetPercent: number, prHeifersDeviationThresholdPercent: number, crCowsTargetPercent: number, crCowsDeviationThresholdPercent: number, crHeifersTargetPercent: number, crHeifersDeviationThresholdPercent: number } }> } };


export const FarmsWithReproductionSettingsDocument = gql`
    query farmsWithReproductionSettings($ids: [ID!]) {
  farms(ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...FarmWithReproductionSettings
    }
  }
}
    ${FarmWithReproductionSettingsFragmentDoc}`;

/**
 * __useFarmsWithReproductionSettingsQuery__
 *
 * To run a query within a React component, call `useFarmsWithReproductionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFarmsWithReproductionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFarmsWithReproductionSettingsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFarmsWithReproductionSettingsQuery(baseOptions?: Apollo.QueryHookOptions<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>(FarmsWithReproductionSettingsDocument, options);
      }
export function useFarmsWithReproductionSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>(FarmsWithReproductionSettingsDocument, options);
        }
export function useFarmsWithReproductionSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>(FarmsWithReproductionSettingsDocument, options);
        }
export type FarmsWithReproductionSettingsQueryHookResult = ReturnType<typeof useFarmsWithReproductionSettingsQuery>;
export type FarmsWithReproductionSettingsLazyQueryHookResult = ReturnType<typeof useFarmsWithReproductionSettingsLazyQuery>;
export type FarmsWithReproductionSettingsSuspenseQueryHookResult = ReturnType<typeof useFarmsWithReproductionSettingsSuspenseQuery>;
export type FarmsWithReproductionSettingsQueryResult = Apollo.QueryResult<FarmsWithReproductionSettingsQuery, FarmsWithReproductionSettingsQueryVariables>;