import { makeUseModal, ModalNames } from '~/services/modals';

import { CalculateMonitorModalProps } from '~/widgets/monitor/modals/CalculateMonitorModal';

import { CreateMonitorGroupModalProps } from './CreateMonitorGroupModal';
import { EditMonitorEntryModalProps } from './EditMonitorEntryModal';
import { MonitorScheduleSettingsModalProps } from './MonitorScheduleSettingsModal';

export const useEditMonitorEntryModal =
  makeUseModal<EditMonitorEntryModalProps>(ModalNames.editMonitorEntryModal);

export const useCreateMonitorGroupModal =
  makeUseModal<CreateMonitorGroupModalProps>(
    ModalNames.createMonitorGroupModal
  );

export const useMonitorScheduleSettingsModal =
  makeUseModal<MonitorScheduleSettingsModalProps>(
    ModalNames.monitorScheduleSettingsModal
  );

export const useCalculateMonitorModal =
  makeUseModal<CalculateMonitorModalProps>(ModalNames.calculateMonitorModal);
