import * as Sentry from '@sentry/react';

import { router } from '~/apps/chicherin/routing';

const ERROR_DEPTH_TO_REPORT = 10;

export const initSentry = () => {
  const release = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release,
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.replayIntegration(),
      Sentry.extraErrorDataIntegration({ depth: ERROR_DEPTH_TO_REPORT }),
    ],

    tracesSampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
  });
};
