import React, { useCallback, useMemo } from 'react';

import R from 'ramda';

import { Checkbox } from '~/shared/components/Checkbox';
import { Label } from '~/shared/components/Label';
import { MInput } from '~/shared/components/MInput';
import { MTextArea } from '~/shared/components/MTextArea';
import { Select, SelectThemes } from '~/shared/components/Select';

import { RoleFragment } from '~/services/auth/gql/fragments/role.graphql';

import { useRoleSelect } from '~/entities/roles';

import { BlueprintDetailsEditModel } from '../../types';

interface Props {
  className?: string;
  blueprint: BlueprintDetailsEditModel;

  onChange: React.Dispatch<React.SetStateAction<BlueprintDetailsEditModel>>;
}

type ChangeHandler = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;

enum BlueprintDetailsFormFields {
  name = 'name',
  description = 'description',
  isForIntegratorOnly = 'isForIntegratorOnly',
  priority = 'priority',
}

const AVAILABLE_PRIORITIES = [
  'Очень низкий',
  'Низкий',
  'Средний',
  'Высокий',
  'Очень высокий',
];

const PRIORITY_ITEMS = AVAILABLE_PRIORITIES.map((priority, index) => ({
  id: index + 1,
  name: priority,
}));

export const BlueprintDetailsForm: React.FC<Props> = React.memo(props => {
  const { className, blueprint, onChange } = props;

  const changeCallbacks = useMemo(() => {
    return {} as Record<BlueprintDetailsFormFields, ChangeHandler>;
  }, []);

  const { renderSelectElement: renderRoleSelectElement } = useRoleSelect({
    selectProps: {
      name: 'role',
      label: null,
      withFormContext: false,
      theme: SelectThemes.light,
      rawValue: blueprint.roles?.at(0)?.id,
      onValueChange: newRoles => {
        onChange(prevState => ({
          ...prevState,
          roles: [newRoles].filter<RoleFragment>(R.complement(R.isNil)),
        }));
      },
    },
  });

  const getChangeCallback = useCallback(
    (field: BlueprintDetailsFormFields) => {
      const callback = changeCallbacks[field];

      if (callback) return callback;

      const newCallback: ChangeHandler = e => {
        onChange(prevBlueprint => {
          return {
            ...prevBlueprint,
            [field]: e.target.value,
          };
        });
      };
      changeCallbacks[field] = newCallback;

      return newCallback;
    },
    [onChange]
  );

  return (
    <div className={className || ''}>
      <Label className="mt-16 mb-4">Название карточки</Label>
      <MInput
        name="name"
        className=""
        value={blueprint.name}
        placeholder="Введите название карточки"
        onChange={getChangeCallback(BlueprintDetailsFormFields.name)}
      />
      <Label className="mt-16 mb-4">Описание</Label>
      <MTextArea
        className=""
        onChange={getChangeCallback(BlueprintDetailsFormFields.description)}
        value={blueprint.description}
        placeholder="Введите назначение карточки"
      />

      <Label className="mt-16 mb-4">Кто исполнитель</Label>

      {renderRoleSelectElement()}

      <Select<(typeof PRIORITY_ITEMS)[number]>
        {...{
          name: 'priority',
          className: 'mt-16',
          label: 'Приоритет в выдаче',
          placeholder: 'Выберите приоритет',
          items: PRIORITY_ITEMS,
          rawValue: blueprint.priority,
          theme: SelectThemes.light,
          onValueChange: newValue =>
            onChange(prevState => {
              return {
                ...prevState,
                priority: newValue?.id ?? 1,
              };
            }),
        }}
      />
      <Checkbox
        {...{
          name: 'isForIntegratorOnly',
          className: 'mt-16',
          label: 'Показывать карточку только интегратору',
          value: blueprint.isForIntegratorOnly,
          onValueChange: newValue =>
            onChange(prevState => {
              return {
                ...prevState,
                isForIntegratorOnly: newValue,
              };
            }),
        }}
      />
    </div>
  );
});
