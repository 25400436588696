import React, { useCallback } from 'react';

import { RunType } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import R from 'ramda';

import {
  normalizeToArrayOrUndefined,
  normalizeToValueOrUndefined,
} from '~/shared/helpers/normalize';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { WithSearchParamsValidation } from '~/services/navigation';

import { useBlueprintsPaginatedQuery } from '~/entities/blueprints';
import { useDeleteBlueprintMutation } from '~/entities/blueprints/gql/mutations/deleteBlueprint.graphql';
import {
  BlueprintsDocument,
  BlueprintsQuery,
  BlueprintsQueryVariables,
} from '~/entities/blueprints/gql/queries/blueprints.graphql';

import {
  BlueprintCardsList,
  BlueprintsPageHeader,
  BlueprintsSearchParams,
  useBlueprintFilters,
} from '~/widgets/blueprintsList';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute('/$companyId/_layout/user/incidents/')({
  component: IncidentsPage,
  wrapInSuspense: true,
  validateSearch: ({
    roleIds,
    viewKind,
    search,
  }: WithSearchParamsValidation<BlueprintsSearchParams>) => ({
    roleIds: Array.isArray(roleIds) ? roleIds : [],
    viewKind: viewKind ?? undefined,
    search: search ?? '',
  }),
});

function IncidentsPage() {
  const blueprintFiltersInterface = useBlueprintFilters();
  const { roleIds, viewKind, debouncedSearch } = blueprintFiltersInterface;

  // Load blueprints
  const queryVariables = {
    runType: RunType.Incident,
    roleIDs: normalizeToArrayOrUndefined(roleIds),
    search: normalizeToValueOrUndefined(debouncedSearch),
    viewKind: normalizeToValueOrUndefined(viewKind),
  };

  const { items: blueprintItems, ...asyncProps } = useBlueprintsPaginatedQuery({
    variables: queryVariables,
  });

  // Blueprint deletion logic
  const [deleteBlueprint] = useDeleteBlueprintMutation();

  const handleDeleteBlueprint = useCallback((blueprintId: string) => {
    deleteBlueprint({
      variables: {
        id: blueprintId,
      },
      optimisticResponse: { deleteBlueprint: null },
      update: R.juxt([
        makeDeleteFragmentFromQuery<BlueprintsQuery, BlueprintsQueryVariables>({
          typeName: 'Blueprint',
          query: BlueprintsDocument,
          variables: queryVariables,
        })(blueprintId),
        makeDeleteQueriesByNameWithoutVariables('blueprints', queryVariables),
      ]),
      refetchQueries: ['blueprints'],
    });
  }, []);

  return (
    <div className={layoutStyles.limitedContainer}>
      <BlueprintsPageHeader
        {...{
          className: 'mb-24',
          title: 'Команды',
          runType: RunType.Incident,
          blueprintFiltersInterface,
        }}
      />

      <BlueprintCardsList
        {...{
          blueprints: blueprintItems,
          onDelete: handleDeleteBlueprint,
          ...asyncProps,
        }}
      />
    </div>
  );
}
