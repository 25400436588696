import { useLayoutEffect, useState } from 'react';

import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Hook that tracks the latest value of an rxjs Observable
 */
export function useObservable<T>(observable$: BehaviorSubject<T>): T;
export function useObservable<T>(
  observable$: Observable<T> | undefined,
  initialValue: T
): T;
export function useObservable<T>(
  observable$: Observable<T> | undefined,
  initialValue?: T
): T {
  const [value, setValue] = useState<T>(
    observable$ instanceof BehaviorSubject
      ? (observable$ as BehaviorSubject<T>).value
      : (initialValue as T)
  );

  useLayoutEffect(() => {
    const s = observable$?.subscribe(setValue);
    return () => s?.unsubscribe();
  }, [observable$]);

  return value;
}
