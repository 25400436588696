import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ReproductionCrRowsRowFragment = { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null };

export const ReproductionCrRowsRowFragmentDoc = gql`
    fragment ReproductionCrRowsRow on ReproductionCrRowsRow {
  __typename
  hashID
  cr
  pregnant
  notPregnant
  other
  abortions
  inseminations
  uncheckedInseminations
  uncheckedInseminationsPercent
  inseminationsPercent
  semenDosesTillPregnancyCount
}
    `;