import React, { ReactNode } from 'react';

import { MLoader } from '~/shared/components/MLoader';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import './MLinkButton.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  color?: 'primary' | 'muted';
  isLoading?: boolean;
}

export const MLinkButton: React.FC<Props> = ({
  children,
  className,
  color,
  onClick,
  isLoading = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`m-link-button ${color || 'primary'} ${className || ''}`}
      type="button"
    >
      {isLoading && <MLoader />}
      <Typography variant={TypographyVariants.bodySmall}>{children}</Typography>
    </button>
  );
};
