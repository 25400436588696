import { createContext, useContext, useMemo } from 'react';

import { Subject } from 'rxjs';

import { getTmpId } from '~/shared/helpers/string';

import { NotificationConfig, NotificationsContextType } from './types';

/**
 * Fabric for notifications context with subject
 */
const createNotificationsContext = (): NotificationsContextType => {
  const notificationsSubject$ = new Subject<NotificationConfig>();

  return {
    notificationsSubject$,
    sendNotification: (kind, props) => {
      notificationsSubject$.next({
        id: getTmpId(kind),
        kind,
        props,
      });
    },
  };
};

/**
 * General context for all modals
 */
export const NotificationsContext = createContext(createNotificationsContext());

/**
 * Hook for creating context to use in NotificationsContext.Provider
 */
export const useCreateNotificationsContext = () =>
  useMemo(createNotificationsContext, []);

/**
 * Hook for using general modals context
 */
export const useNotificationsContext = () => useContext(NotificationsContext);
