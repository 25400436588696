import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { Falsy } from '~/shared/types/utility';

type AnyRef =
  | React.MutableRefObject<any>
  | ((node: any) => void)
  | undefined
  | null
  | string;

/**
 * Applies multiple refs to one element
 * Doesn't work for string refs, they're deprecated anyway
 */
export const mergeRefs = <T extends AnyRef>(...refs: T[]) => {
  return (node: any) => {
    refs.forEach(ref => {
      if (!ref || typeof ref === 'string') {
        return;
      }
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
  };
};

/**
 * Call multiple event handlers on one element
 */
type EventHandler<T extends any> = (...args: T[]) => void;
export const mergeHandlers = <T extends any>(
  ...handlers: (EventHandler<T> | undefined)[]
) => {
  return (...args: T[]) => {
    handlers.forEach(handler => handler?.(...args));
  };
};

type MergePropsArrays<T extends any[]> = T extends [infer First, ...infer Rest]
  ? First extends Falsy
    ? MergePropsArrays<Rest>
    : First & MergePropsArrays<Rest>
  : object;

/**
 * Merge props of react components, like Object.assign, but:
 * classNames are concatenated
 * refs and handlers (functions with convention naming witch starts with 'on') are combined
 * styles are deeply merged
 * Props (objects with convention naming witch ends with 'Props') are merged recursively
 */
export const mergeProps = <T extends any[]>(
  ...argsProps: T
): MergePropsArrays<T> => {
  const mergePropsWithKey = (key: string, left: any, right: any) => {
    if (key === 'className') {
      return clsx(left, right);
    }
    if (key === 'ref') {
      return mergeRefs(left, right);
    }
    if (key === 'style') {
      return R.mergeDeepRight(left ?? {}, right ?? {});
    }
    if (key.startsWith('on')) {
      return mergeHandlers(left, right);
    }
    if (key.endsWith('Props')) {
      return mergeProps(left, right);
    }

    return right;
  };

  const propsToMerge = argsProps.filter(Boolean);
  return R.reduce(
    R.mergeWithKey(mergePropsWithKey),
    propsToMerge[0],
    propsToMerge.slice(1)
  );
};
