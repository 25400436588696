import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { EventFragment } from '~/entities/events/gql/fragments/event.graphql';
import {
  EventsQuery,
  EventsQueryVariables,
  useEventsQuery,
} from '~/entities/events/gql/queries/events.graphql';

export const useEventsPaginatedQuery = makeUsePaginatedQuery<
  EventFragment,
  EventsQuery,
  EventsQueryVariables
>({
  useQuery: useEventsQuery,
  getItemsFromQueryData: data => data.events.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.events.pageInfo,
});
