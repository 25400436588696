import React, { useMemo } from 'react';

import {
  BarElement,
  CategoryScale,
  Chart,
  ChartOptions,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  TimeScale,
  Tooltip,
} from 'chart.js';
import R from 'ramda';

import { Skeleton } from '~/shared/components/Skeleton';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

import {
  BARS_BORDER_RADIUS_PX,
  BARS_CHART_OPTIONS,
  PERCENT_BARS_CHART_OPTIONS,
} from '../../constants';
import { getChartOptions, makeDefaultDatasetConfig } from '../../helpers';
import { ReactChartDatasetConfig } from '../../types';
import { ReactChart, ReactChartProps } from '../ReactChart';

Chart.register(...registerables);
Chart.register(LineController);
Chart.register(Tooltip);
Chart.register(Filler);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(CategoryScale);
Chart.register(TimeScale);
Chart.register(LinearScale);
Chart.register(BarElement);

const BAR_CHART_DATASETS_OPTIONS = {
  datasets: {
    bar: { borderRadius: BARS_BORDER_RADIUS_PX },
    line: { pointStyle: false },
  },
} satisfies ChartOptions;

/**
 * Data item for the bar chart
 */
export type BarChartDataPoint =
  | {
      x: string | undefined;
      y: number;
    }
  | number;

/**
 * Possible chart types to display on a bar chart
 */
export type BarChartType = 'bar' | 'line';

/**
 * Dataset config for a bar chart
 */
export type BarChartDatasetConfig = ReactChartDatasetConfig<
  BarChartType,
  BarChartDataPoint[]
>;

interface Props
  extends Omit<
    ReactChartProps<BarChartType, BarChartDataPoint[]>,
    'type' | 'data' | 'datasetConfigs' | 'skeleton'
  > {
  /**
   * Chart data
   */
  datasets: BarChartDatasetConfig[];
  /**
   * Labels, used in the chart x axis, double array is supported by chart.js for multiline labels
   */
  labels?: (string[] | string)[];
  /**
   * If true, bars are stacked on both axes
   */
  isStacked?: boolean;
  /**
   * If true, bars are stacked on x axis
   */
  isStackedX?: boolean;
  /**
   * If true, bars are stacked on y axis
   */
  isStackedY?: boolean;
  /**
   * If true, y values a drawn with percent formatting
   */
  isPercents?: boolean;
  /**
   * Additional options for the chart
   */
  chartOptions?: ChartOptions;
}

export const BarChart: React.FC<Props> = ({
  datasets,
  isStacked = false,
  isStackedX = isStacked,
  isStackedY = isStacked,
  isPercents = false,
  labels,
  chartOptions: chartOptionsProp,

  ...chartProps
}) => {
  const chartOptions = getChartOptions<BarChartType>(
    isPercents ? PERCENT_BARS_CHART_OPTIONS : BARS_CHART_OPTIONS,
    {
      scales: {
        x: {
          stacked: isStackedX,
        },
        y: {
          stacked: isStackedY,
        },
      },
    },
    BAR_CHART_DATASETS_OPTIONS,
    chartOptionsProp
  );

  const datasetConfigs = useMemo<BarChartDatasetConfig[]>(() => {
    // We should place line datasets on top of bar ones for visibility,
    // though they're usually come last in the legend
    const datasetByTypesCount = R.countBy(R.propOr('bar', 'type'), datasets);
    const barDatasetsCount = datasetByTypesCount['bar'] ?? 0;
    const lineDatasetsCount = datasetByTypesCount['line'] ?? 0;

    return datasets.map((dataset, index) => ({
      ...dataset,
      // TODO can't properly assign to Partial<BarChartDatasetConfig> here because of variance problem,
      // need to rethink how dataset types work on type level
      ...(makeDefaultDatasetConfig(dataset) as any),
      order:
        dataset.order ??
        (dataset.type === 'line'
          ? lineDatasetsCount - (index - barDatasetsCount)
          : lineDatasetsCount + index + 1),
      maxBarThickness: NUMBER_TOKENS.size64,
    }));
  }, [datasets, labels]);

  return (
    <ReactChart<BarChartType, BarChartDataPoint[]>
      {...{
        options: chartOptions,
        type: 'bar',
        datasetIdKey: 'first',
        labels,
        datasetConfigs,
        skeleton: <Skeleton.BarChart />,
        ...chartProps,
      }}
    />
  );
};
