import { useEffect, useState } from 'react';

/**
 * Hook for observing the result of a given media query
 */
export const useMediaQuery = (query: string) => {
  const [state, setState] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    let isMounted = true;
    const mediaQueryList = window.matchMedia(query);
    const onChange = () => {
      if (!isMounted) {
        return;
      }
      setState(mediaQueryList.matches);
    };

    mediaQueryList.addEventListener('change', onChange);
    setState(mediaQueryList.matches);

    return () => {
      isMounted = false;
      mediaQueryList.removeEventListener('change', onChange);
    };
  }, [query]);

  return state;
};
