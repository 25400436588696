import React from 'react';

import { ImportProvidersEnum } from '@graphql-types';
import * as yup from 'yup';

import { FilePicker, ZIP_FILE_ACCEPT } from '~/services/files';
import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import {
  InjectedModalProps,
  Modal,
  ModalComponentProps,
} from '~/services/modals';
import {
  Callout,
  NotificationVariants,
  useNotifications,
} from '~/services/notifications';

import { useImportCompanyFromFileMutation } from '~/entities/companies/gql/mutations/importCompanyFromFile.graphql';
import { FarmAsyncSelect } from '~/entities/farms';

import formStyles from '~/styles/modules/form.module.scss';

import { updateCompanyShortFragment } from '../../../../helpers';
import { useDetailedCompany } from '../../../../hooks';

interface Props
  extends React.PropsWithChildren,
    InjectedModalProps<Props>,
    Pick<ModalComponentProps, 'title' | 'withOpenAnimation'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * ID of the company to import file to
   */
  companyID: string;
  /**
   * Called, when an import modal starts async company update by writing to company's isLockedForWrite
   */
  onPolledCompanyUpdateStarted?: () => void;
}

const FORM_ID = 'SelexImportModalForm';

const SCHEMA = yup.object({
  farmID: yup.string().required(), // ID!
  file: yup.mixed().required(), // Upload!
});

type SelexImportFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type SelexImportFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

export const SelexImportModalForm: React.FC<Props> = ({
  className,
  companyID,
  children,
  onPolledCompanyUpdateStarted,

  close,

  ...modalProps
}) => {
  const { sendNeutralToast } = useNotifications();

  const [importCompanyFromFile] = useImportCompanyFromFileMutation();

  const { isDetailedCompanyLoading, companyDetailed, companyDetailedPromise } =
    useDetailedCompany(companyID);

  const formContext = useForm<
    SelexImportFormType,
    SelexImportFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: () =>
      companyDetailedPromise.then(detailedCompany => ({
        ...SCHEMA.getDefault(),
        farmID: detailedCompany?.farms.nodes.at(0)?.id,
      })),
  });

  const handleSubmit = (form: SelexImportFormTransformedType) => {
    importCompanyFromFile({
      variables: {
        input: {
          ...form,
          provider: ImportProvidersEnum.Selex,
          companyID,
        },
      },
      optimisticResponse: { importCompanyFromFile: null },
      update: updateCompanyShortFragment(companyID, draft => {
        draft.isLockedForWrite = true;
      }),
    }).then(() => onPolledCompanyUpdateStarted?.());

    sendNeutralToast('Загружаем данные из Селэкс');
    close();
  };

  return (
    <Modal
      {...{
        className,
        ...modalProps,
        submitButtonProps: {
          form: FORM_ID,
          children: 'Загрузить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      {children}
      <Callout
        {...{
          className: 'mb-24',
          variant: NotificationVariants.info,
          message: 'Для импорта необходим архив .zip с файлом Selex.fdb внутри',
        }}
      />
      <Form
        {...{
          className: formStyles.singleColumnForm,
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <FarmAsyncSelect
          {...{
            name: 'farmID',
            label: 'Ферма',
            items: companyDetailed?.farms.nodes ?? [],
            asyncProps: {
              isLoading: isDetailedCompanyLoading,
            },
          }}
        />

        <FilePicker name="file" accept={ZIP_FILE_ACCEPT} />
      </Form>
    </Modal>
  );
};
