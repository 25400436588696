import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteReproductionCrReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteReproductionCrReportMutation = { __typename?: 'Mutation', deleteReproductionCrReport?: any | null };


export const DeleteReproductionCrReportDocument = gql`
    mutation deleteReproductionCrReport($id: ID!) {
  deleteReproductionCrReport(id: $id)
}
    `;
export type DeleteReproductionCrReportMutationFn = Apollo.MutationFunction<DeleteReproductionCrReportMutation, DeleteReproductionCrReportMutationVariables>;

/**
 * __useDeleteReproductionCrReportMutation__
 *
 * To run a mutation, you first call `useDeleteReproductionCrReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReproductionCrReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReproductionCrReportMutation, { data, loading, error }] = useDeleteReproductionCrReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReproductionCrReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReproductionCrReportMutation, DeleteReproductionCrReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReproductionCrReportMutation, DeleteReproductionCrReportMutationVariables>(DeleteReproductionCrReportDocument, options);
      }
export type DeleteReproductionCrReportMutationHookResult = ReturnType<typeof useDeleteReproductionCrReportMutation>;
export type DeleteReproductionCrReportMutationResult = Apollo.MutationResult<DeleteReproductionCrReportMutation>;
export type DeleteReproductionCrReportMutationOptions = Apollo.BaseMutationOptions<DeleteReproductionCrReportMutation, DeleteReproductionCrReportMutationVariables>;