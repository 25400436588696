import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyCowPlannedInjectionsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ApplyCowPlannedInjectionsMutation = { __typename?: 'Mutation', applyCowPlannedInjections?: any | null };


export const ApplyCowPlannedInjectionsDocument = gql`
    mutation applyCowPlannedInjections {
  applyCowPlannedInjections
}
    `;
export type ApplyCowPlannedInjectionsMutationFn = Apollo.MutationFunction<ApplyCowPlannedInjectionsMutation, ApplyCowPlannedInjectionsMutationVariables>;

/**
 * __useApplyCowPlannedInjectionsMutation__
 *
 * To run a mutation, you first call `useApplyCowPlannedInjectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCowPlannedInjectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCowPlannedInjectionsMutation, { data, loading, error }] = useApplyCowPlannedInjectionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useApplyCowPlannedInjectionsMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCowPlannedInjectionsMutation, ApplyCowPlannedInjectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCowPlannedInjectionsMutation, ApplyCowPlannedInjectionsMutationVariables>(ApplyCowPlannedInjectionsDocument, options);
      }
export type ApplyCowPlannedInjectionsMutationHookResult = ReturnType<typeof useApplyCowPlannedInjectionsMutation>;
export type ApplyCowPlannedInjectionsMutationResult = Apollo.MutationResult<ApplyCowPlannedInjectionsMutation>;
export type ApplyCowPlannedInjectionsMutationOptions = Apollo.BaseMutationOptions<ApplyCowPlannedInjectionsMutation, ApplyCowPlannedInjectionsMutationVariables>;