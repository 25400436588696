import { BreedingValueTypeEnum, MarkingMeanEnum } from '@graphql-types';

/**
 * Herriot breeding value types
 */
export const BREEDING_VALUE_TYPES_DICT: Record<BreedingValueTypeEnum, string> =
  {
    [BreedingValueTypeEnum.Breeding]: 'Племенное',
    [BreedingValueTypeEnum.NonBreeding]: 'Неплеменное',
    [BreedingValueTypeEnum.Undefined]: 'Тип не определен',
  };

/**
 * Herriot marking means
 */
export const MARKING_MEAN_DICT: Record<MarkingMeanEnum, string> = {
  [MarkingMeanEnum.Label]: 'Бирка',
  [MarkingMeanEnum.Microchip]: 'Вживляемый микрочип',
  [MarkingMeanEnum.Brand]: 'Тавро/Клеймо',
  [MarkingMeanEnum.Tattoo]: 'Татуировка',
  [MarkingMeanEnum.Bolus]: 'Болюс',
  [MarkingMeanEnum.Ring]: 'Кольцо',
  [MarkingMeanEnum.ElectronicRing]: 'Электронное кольцо',
  [MarkingMeanEnum.Collar]: 'Ошейник',
  [MarkingMeanEnum.ElectronicColar]: 'Электронный ошейник',
  [MarkingMeanEnum.WingTag]: 'Крыло-метка',
  [MarkingMeanEnum.ElectronicWingTag]: 'Электронное крыло-метка',
  [MarkingMeanEnum.ElectronicTag]: 'Электронная метка',
  [MarkingMeanEnum.TissueSection]: 'Вырез тканей',
  [MarkingMeanEnum.Nameplate]: 'Табло',
};
