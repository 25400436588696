import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { isTmpId } from '~/shared/helpers/string';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { ProtocolFragment } from '../gql/fragments/protocol.graphql';
import { useCreateProtocolInjectionMutation } from '../gql/mutations/createProtocolInjection.graphql';
import { useDeleteProtocolInjectionMutation } from '../gql/mutations/deleteProtocolInjection.graphql';
import { useUpdateProtocolInjectionMutation } from '../gql/mutations/updateProtocolInjection.graphql';
import { readProtocolFragment } from '../helpers';

/**
 * Props for editing protocol injections
 */
interface Props {
  /**
   * Protocol to edit
   */
  protocol: ProtocolFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (editedProtocol?: ProtocolFragment) => void;
}

const FORM_ID = 'EditProtocolInjectionsForm';

const SCHEMA = yup.object({
  protocolInjections: yup
    .array(
      yup.object({
        id: yup.string().required(), // ID!
        comment: yup.string().default(''),
        injectionID: yup.string().required(), // ID!
        dayNumber: yup.number().required(),
        isDeleted: yup.boolean().default(false),
      })
    )
    .default([]),
});

/**
 * Form for editing protocol injections
 */
export type EditProtocolInjectionsFormType = InferValidatedSchema<
  typeof SCHEMA
>;

export const useProtocolInjectionsForm = ({
  protocol,
  onSubmit,
}: Props): UseModalStepFormInterface<EditProtocolInjectionsFormType> => {
  const [isLoading, setLoading] = useState(false);

  const [createProtocolInjection, { client }] =
    useCreateProtocolInjectionMutation();
  const [updateProtocolInjection] = useUpdateProtocolInjectionMutation();
  const [deleteProtocolInjection] = useDeleteProtocolInjectionMutation();

  const defaultProtocolInjections = protocol.protocolInjections.map(
    protocolInjection => ({
      ...R.pick(['id', 'comment', 'dayNumber'], protocolInjection),
      injectionID: protocolInjection.injection?.id,
      isDeleted: false,
    })
  );

  const formContext = useForm<EditProtocolInjectionsFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      protocolInjections: defaultProtocolInjections,
    },
  });

  const handleSubmit = async (form: EditProtocolInjectionsFormType) => {
    setLoading(true);

    const promises = form.protocolInjections
      .map(protocolInjection => {
        const protocolInjectionInput = R.omit(
          ['isDeleted', 'id', 'uniqKey' as 'id'],
          protocolInjection
        );

        if (protocolInjection.isDeleted) {
          return deleteProtocolInjection({
            variables: {
              id: protocolInjection.id,
            },
          });
        }

        if (isTmpId(protocolInjection.id)) {
          return createProtocolInjection({
            variables: {
              input: {
                ...protocolInjectionInput,
                protocolID: protocol.id,
              },
            },
          });
        }

        const existingInjection = protocol.protocolInjections.find(
          i => i.id === protocolInjection.id
        );
        const pickFieldsToCompare = R.pick(['comment', 'dayNumber']);
        if (
          existingInjection &&
          (!R.equals(
            pickFieldsToCompare(existingInjection),
            pickFieldsToCompare(protocolInjectionInput)
          ) ||
            existingInjection?.injection?.id !==
              protocolInjectionInput.injectionID)
        ) {
          return updateProtocolInjection({
            variables: {
              id: protocolInjection.id,
              input: protocolInjectionInput,
            },
          });
        }

        return undefined;
      })
      .filter(Boolean);

    try {
      if (promises.length) {
        await Promise.all(promises);
        await client.refetchQueries({ include: ['events'] });
      }

      const editedProtocol =
        readProtocolFragment(client, protocol.id) ?? undefined;

      onSubmit?.(editedProtocol);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
