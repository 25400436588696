import { ModalRegistration } from '~/services/modals';

import {
  EditReproductionHdrAndPrReportModal,
  EditReproductionHdrAndPrReportModalProps,
} from '.';

export const registration: ModalRegistration<EditReproductionHdrAndPrReportModalProps> =
  {
    Component: EditReproductionHdrAndPrReportModal,
  };
