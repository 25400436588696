import { useContext } from 'react';

import { ServicesContext } from '../components/ServicesContext';

export function useService<T>(type: any) {
  const iocContainer = useContext(ServicesContext);
  const service = iocContainer[type] as T;

  if (!service) {
    throw new Error(`Service of type [${type}] is not registered!`);
  }

  return service;
}
