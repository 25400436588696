import { ModalRegistration } from '~/services/modals';

import {
  MonitorScheduleSettingsModal,
  MonitorScheduleSettingsModalProps,
} from '.';

export const registration: ModalRegistration<MonitorScheduleSettingsModalProps> =
  {
    Component: MonitorScheduleSettingsModal,
  };
