import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorFragmentDoc } from '../fragments/milkingParlor.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMilkingParlorMutationVariables = Types.Exact<{
  input: Types.CreateMilkingParlorInput;
}>;


export type CreateMilkingParlorMutation = { __typename?: 'Mutation', createMilkingParlor: { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, tagField: Types.ParlorExportStatusFileTagFieldEnum, regNumField: Types.RegNumFieldEnum, farm: { __typename: 'Farm', id: string, name: string, number: string }, intervals: Array<{ __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string }> } };


export const CreateMilkingParlorDocument = gql`
    mutation createMilkingParlor($input: CreateMilkingParlorInput!) {
  createMilkingParlor(input: $input) {
    ...MilkingParlor
  }
}
    ${MilkingParlorFragmentDoc}`;
export type CreateMilkingParlorMutationFn = Apollo.MutationFunction<CreateMilkingParlorMutation, CreateMilkingParlorMutationVariables>;

/**
 * __useCreateMilkingParlorMutation__
 *
 * To run a mutation, you first call `useCreateMilkingParlorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilkingParlorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilkingParlorMutation, { data, loading, error }] = useCreateMilkingParlorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMilkingParlorMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilkingParlorMutation, CreateMilkingParlorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilkingParlorMutation, CreateMilkingParlorMutationVariables>(CreateMilkingParlorDocument, options);
      }
export type CreateMilkingParlorMutationHookResult = ReturnType<typeof useCreateMilkingParlorMutation>;
export type CreateMilkingParlorMutationResult = Apollo.MutationResult<CreateMilkingParlorMutation>;
export type CreateMilkingParlorMutationOptions = Apollo.BaseMutationOptions<CreateMilkingParlorMutation, CreateMilkingParlorMutationVariables>;