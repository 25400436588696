import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { SemenDoseFragmentDoc } from '../../gql/fragments/semenDose.graphql';
import { useSemenDosesQuery } from '../../gql/queries/semenDoses.graphql';
import { formatSemenDose } from '../../helpers';

/**
 * Select for a semen dose
 */
export const useSemenDoseSelect = makeUseAsyncSelect({
  typeName: 'SemenDose',
  fragment: SemenDoseFragmentDoc,
  useQuery: useSemenDosesQuery,
  getItemsFromQueryData: data => data.semenDoses.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.semenDoses.pageInfo,

  selectProps: {
    placeholder: 'Введите дозу',
    getItemValue: item => item.id,
    getItemText: item => formatSemenDose(item, { withBull: false }),
    getItemDescription: item => formatSemenDose(item, { withMainInfo: false }),
  },
});

export const SemenDoseAsyncSelect =
  makeAsyncSelectFormComponent(useSemenDoseSelect);
