import React from 'react';

import R from 'ramda';

import { Select, SelectVariants } from '~/shared/components/Select';
import { MDASH } from '~/shared/constants';

import { MAX_MILKINGS_PER_DAY_COUNT } from '~/entities/milkingParlors';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import { useSetPenGroupMilkingIntervalNumberMutation } from '../../../../gql/mutations/setPenGroupMilkingIntervalNumber.graphql';
import { updateMilkingParlorIntervalDetailedFragment } from '../../../../helpers';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking interval id, where we render the select
   */
  intervalId: string;
  /**
   * Pen group id, where we render the select
   */
  penGroupId: string;
  /**
   * Value of the select
   */
  value: number | null | undefined;
}

const MILKING_NUMBER_SELECT_ITEMS = [
  { id: null, name: MDASH },
  ...R.range(1, MAX_MILKINGS_PER_DAY_COUNT + 1).map(num => ({
    id: num,
    name: num,
  })),
];

const MILKING_COLORS_DICT: Record<number, ColorVariants> = {
  0: ColorVariants.neutral,
  1: ColorVariants.status01,
  2: ColorVariants.status02,
  3: ColorVariants.status03,
  4: ColorVariants.status05,
};

export const MilkingNumberSelect: React.FC<Props> = ({
  className,
  intervalId,
  penGroupId,
  value,
}) => {
  const [setPenGroupMilkingIntervalNumber, { loading: isOptimistic }] =
    useSetPenGroupMilkingIntervalNumberMutation();

  return (
    <Select<(typeof MILKING_NUMBER_SELECT_ITEMS)[number]>
      {...{
        className,
        name: `milkingNumber__${intervalId}__${penGroupId}`,
        items: MILKING_NUMBER_SELECT_ITEMS,
        variant: SelectVariants.compact,
        isOptimistic,
        getItemColorVariant: item => MILKING_COLORS_DICT[item?.id ?? 0],
        rawValue: value,
        onValueChange: newValue => {
          const newMilkingNumber = newValue?.id ?? null;

          setPenGroupMilkingIntervalNumber({
            variables: {
              milkingParlorIntervalID: intervalId,
              penGroupID: penGroupId,
              milkingNumber: newMilkingNumber,
            },
            optimisticResponse: {
              setPenGroupMilkingIntervalNumber: null,
            },
            update: updateMilkingParlorIntervalDetailedFragment(
              intervalId,
              draft => {
                const groupEdgeToUpdate = draft.penGroups.edges.find(
                  e => e.node.id === penGroupId
                );
                if (groupEdgeToUpdate) {
                  groupEdgeToUpdate.milkingNumber = newMilkingNumber;
                }
              }
            ),
          });
        },
      }}
    />
  );
};
