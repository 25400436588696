import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useSvgKeyPress } from '~/~legacy/hooks/useSvgKeyPress';
import { ReactComponent as TrashSVG } from '~/~legacy/icons/trash.svg';
import { PenGroupShortInfo } from '~/~legacy/types/entities';
import { ListItem } from '~/~legacy/types/keyValue';
import { PenGroupSelector } from '~/~legacy/value-editors/editors';
import { ChangeValueCallbackType } from '~/~legacy/value-editors/types/valueEditorTypes';

import { FeedbackVariants } from '~/shared/components/FieldFeedback';
import { Input } from '~/shared/components/Input';
import { MDropDown } from '~/shared/components/MDropDown';
import { MFormField } from '~/shared/components/MFormField';
import { MInput } from '~/shared/components/MInput';
import { SelectThemes } from '~/shared/components/Select';
import { CalfLifeState } from '~/shared/graphql';

import { CalfData, CalfDataEditFunction } from '../../reducer';
import { Gender } from '../../types';

interface Props {
  calf: CalfData;
  update: CalfDataEditFunction;
  del: CalfDataEditFunction;
  focused?: boolean;
}

export const CalfsTableRow: React.FC<Props> = ({
  calf,
  update,
  del,
  focused,
}) => {
  const firstCellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused && firstCellRef.current) {
      firstCellRef.current.focus();
    }
  }, [focused, firstCellRef]);

  const delCallback = useCallback(() => {
    del(calf);
  }, [del, calf.tempId]);

  const statusItems = useMemo(() => {
    const items: ListItem<CalfLifeState | undefined>[] = [
      {
        value: CalfLifeState.Alive,
        content: 'живой',
      },
      {
        value: CalfLifeState.Dead,
        content: 'мёртвый',
      },
    ];
    return items;
  }, []);

  const genderItems = useMemo(() => {
    const items: ListItem<Gender | undefined>[] = [
      {
        value: Gender.female,
        content: 'жен.',
      },
      {
        value: Gender.male,
        content: 'муж.',
      },
    ];

    return items;
  }, []);

  const statusChanged = useCallback(
    (state: CalfLifeState) => {
      update({
        ...calf,
        state,
      });
    },
    [calf, update]
  );

  const numberChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const number = e.target.value;

      update({
        ...calf,
        number,
      });
    },
    [calf, update]
  );

  const genderChanged = useCallback(
    (gender: Gender) => {
      update({
        ...calf,
        gender,
      });
    },
    [calf, update]
  );

  const penGroupChanged: ChangeValueCallbackType<PenGroupShortInfo> =
    useCallback(
      group => {
        update({
          ...calf,
          penGroupId: group?.id,
        });
        return Promise.resolve(true);
      },
      [calf, update]
    );

  const weightChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const weight = e.target.value;
      update({
        ...calf,
        weight,
      });
    },
    [calf, update]
  );

  const herriotCodeChanged = useCallback(
    (herriotCode: string) => {
      update({
        ...calf,
        herriotCode,
      });
    },
    [calf, update]
  );

  const heightChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const height = e.target.value;
      update({
        ...calf,
        height,
      });
    },
    [calf, update]
  );

  const svgDelPress = useSvgKeyPress(delCallback);

  return (
    <tr>
      <td>
        <MFormField
          title="Статус телёнка"
          editor={
            <MDropDown
              name="state"
              ref={firstCellRef}
              items={statusItems}
              onChange={statusChanged}
              selectedValue={calf.state}
            />
          }
        />
      </td>
      <td>
        <MFormField
          title="Номер телёнка"
          editor={
            <MInput
              name="number"
              placeholder="Введите номер"
              onChange={numberChanged}
              value={calf.number || ''}
            />
          }
        />
      </td>
      <td style={{ minWidth: 80 }}>
        <MFormField
          title="Пол телёнка"
          editor={
            <MDropDown
              name="gender"
              items={genderItems}
              onChange={genderChanged}
              selectedValue={calf.gender}
            />
          }
        />
      </td>
      <td>
        <MFormField
          title="Группа"
          editor={
            <PenGroupSelector
              name="penGroup"
              edit
              hideControls
              noForm
              rawValue={calf.penGroupId}
              onChange={penGroupChanged}
              additionalArgs={{ selectTheme: SelectThemes.dark }}
            />
          }
        />
      </td>
      <td>
        <MFormField
          title="Вес телёнка"
          editor={
            <MInput
              name="weight"
              placeholder="Введите кг"
              value={calf.weight || ''}
              onChange={weightChanged}
            />
          }
        />
      </td>
      <td>
        <Input
          {...{
            name: 'herriotCode',
            label: 'Хорриот код',
            feedback: 'Необязательное поле',
            feedbackProps: {
              variant: FeedbackVariants.neutral,
            },
            placeholder: 'Введите код',
            value: calf.herriotCode || '',
            onValueChange: herriotCodeChanged,
          }}
        />
      </td>
      <td>
        <MFormField
          title="Рост телёнка"
          subtitle="Необязательное поле"
          editor={
            <MInput
              name="height"
              placeholder="Введите см"
              value={calf.height || ''}
              onChange={heightChanged}
            />
          }
        />
      </td>
      <td>
        <TrashSVG tabIndex={0} onKeyUp={svgDelPress} onClick={delCallback} />
      </td>
    </tr>
  );
};
