import { capitalize } from '~/shared/helpers/string';

const getGlobalIdParts = <T extends string>(globalId?: T | null) => {
  const stringId = globalId ?? '';
  return stringId.includes('_') ? stringId.split('_') : [stringId, stringId];
};

/**
 * Returns typename from global id
 */
export const getTypePartFromGlobalId = <T extends string>(
  globalId?: T | null
) => capitalize(getGlobalIdParts(globalId)[0]);

/**
 * Returns number from global id
 */
export const getNumberPartFromGlobalId = <T extends string>(
  globalId?: T | null
) => getGlobalIdParts(globalId)[1];

/**
 * Creates a global id from number id (if a global id is passed, changes it to specified type)
 */
export const toGlobalId = <T extends string>(
  id: string | number | null | undefined,
  type: T
) => {
  if (!id) return undefined;

  const stringId = id.toString();

  return `${type}_${getNumberPartFromGlobalId(stringId)}`;
};
