import { useCallback } from 'react';

import { ChangeValueCallbackType } from './types/valueEditorTypes';

export function useEditorSubmit<T = any>(
  submit: ChangeValueCallbackType<T> | undefined,
  toggle: () => void,
  val: any
) {
  const submitCallback = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const emptyFunc = () => {};
      const submitFn = submit || emptyFunc;
      const success = await submitFn(val);
      const postSubmitAction = success ? toggle : emptyFunc;
      postSubmitAction();

      return false;
    },
    [submit, val]
  );

  return submitCallback;
}
