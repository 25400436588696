import { Employee } from '@graphql-types';

import { HALF_SPACE, MDASH, SPACED_INTERPUNCT } from '~/shared/constants';
import {
  formatFullName,
  formatFullNameWithInitials,
} from '~/shared/helpers/nameFormat';

import { makeUpdateFragment } from '~/services/gql';

import {
  EmployeeFragment,
  EmployeeFragmentDoc,
} from './gql/fragments/employee.graphql';
import { EmployeeFormatOptions } from './types';

/**
 * Returns employee formatted for rendering
 */
export const formatEmployee = (
  employee?: Pick<
    Employee,
    'firstName' | 'lastName' | 'middleName' | 'number'
  > | null,
  {
    withName = true,
    withFullName = true,
    withNumber = true,
  }: EmployeeFormatOptions = {}
) => {
  if (!employee) return MDASH;

  const employeeNumber = withNumber ? `№${HALF_SPACE}${employee.number}` : '';
  const formatName = withFullName ? formatFullName : formatFullNameWithInitials;
  const employeeName = withName ? formatName(employee) : '';

  return [employeeName, employeeNumber].filter(Boolean).join(SPACED_INTERPUNCT);
};

/**
 * Update Employee in the cache
 */
export const updateEmployeeFragment = makeUpdateFragment<EmployeeFragment>({
  typeName: 'Employee',
  fragment: EmployeeFragmentDoc,
});
