import { makeUseModal, ModalNames } from '~/services/modals';

import { DeleteBullModalProps } from './DeleteBullModal';
import { EditBullModalProps } from './EditBullModal';

export const useEditBullModal = makeUseModal<EditBullModalProps>(
  ModalNames.editBullModal
);

export const useDeleteBullModal = makeUseModal<DeleteBullModalProps>(
  ModalNames.deleteBullModal
);
