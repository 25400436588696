import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import { NodeValueKindEnum } from '~/shared/graphql';

import {
  BlueprintEditService,
  BlueprintNodeType,
  BlueprintWritableArgument,
} from '../../services';
import { ArgumentNodeUI } from './components/ArgumentNodeUI';

interface Props {
  parentNodeType: BlueprintNodeType;
  argument: BlueprintWritableArgument;
}

export const ArgumentNode: React.FC<Props> = props => {
  const { argument, parentNodeType } = props;

  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const memoizedUnset = useMemo(() => {
    return bpEditSvc.unsetArgument.bind(bpEditSvc);
  }, [bpEditSvc.unsetArgument]);

  const {
    errors: unsetErorrs,
    loading: unseting,
    reload: unset,
  } = useApiData(memoizedUnset);

  const unsetCallback = useCallback(() => {
    unset(argument);
  }, [unset]);

  const updateNodeFunc = useMemo(() => {
    return bpEditSvc.setArgument.bind(bpEditSvc);
  }, [bpEditSvc.setArgument]);

  const {
    loading: updating,
    errors: updateErorrs,
    reload: updateNode,
  } = useApiData(updateNodeFunc);

  const errors = useMemo(() => {
    return [...updateErorrs, ...unsetErorrs];
  }, [updateErorrs, unsetErorrs]);

  const loading = useMemo(() => {
    return unseting || updating;
  }, [unseting, updating]);

  const submit = useCallback(
    async (val: any, kind: NodeValueKindEnum) => {
      const result = await updateNode({
        ...argument,
        valueKind: kind,
        inputValue: val,
        value: val,
        parentNodeType,
      });
      return result.success;
    },
    [updateNode]
  );

  return (
    <ArgumentNodeUI
      parentNodeType={parentNodeType}
      argument={argument}
      submit={submit}
      unset={unsetCallback}
      errors={errors}
      loading={loading}
    />
  );
};
