import { useMemo } from 'react';

import { ConceptionRateParameterEnum } from '@graphql-types';
import { match } from 'ts-pattern';

import { useForm } from '~/services/forms';

import { useReportCardBuilderForm } from '~/features/reportCardBuilder';

import {
  REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT,
  REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA,
} from '../../../constants';
import { mapReproductionCrReportSettingsToForm } from '../../../helpers';
import {
  ReproductionCrReportFiltersAndGroupingMenuItems,
  ReproductionCrReportSettingsFormType,
} from '../../../types';
import { ReproductionCrReportSettingsFormProps } from '../types';

export const useReproductionCrReportSettingsForm = ({
  reproductionCrReport,

  onSettingsFormChange,
}: ReproductionCrReportSettingsFormProps) => {
  const reportSettings = reproductionCrReport.settings;

  const defaultValues = useMemo(
    () => mapReproductionCrReportSettingsToForm(reportSettings),
    [reportSettings]
  );

  const formContext = useForm<ReproductionCrReportSettingsFormType>({
    schema: REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA,
    defaultValues,
  });

  const { formValues } = useReportCardBuilderForm({
    formContext,
    defaultValues,
    onSettingsFormChange,
  });

  const availableForAddingFiltersAndGrouping = [
    !formValues.lactationGroupNumbers &&
      ReproductionCrReportFiltersAndGroupingMenuItems.filterLactations,
    !formValues.cowIDs &&
      ReproductionCrReportFiltersAndGroupingMenuItems.filterAnimals,
    !formValues.groupBy &&
      !formValues.yAxisShouldCompareToPreviousPeriod &&
      ReproductionCrReportFiltersAndGroupingMenuItems.grouping,
  ].filter(Boolean);

  // Filters and grouping adding
  const availableForFiltersAndGroupingItems =
    availableForAddingFiltersAndGrouping.map(menuItem => ({
      key: menuItem,
      content:
        REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[menuItem],
      onPress: () =>
        match(menuItem)
          .with(
            ReproductionCrReportFiltersAndGroupingMenuItems.filterAnimals,
            () => formContext.setValue('cowIDs', [])
          )
          .with(
            ReproductionCrReportFiltersAndGroupingMenuItems.filterLactations,
            () => formContext.setValue('lactationGroupNumbers', [])
          )
          .with(
            ReproductionCrReportFiltersAndGroupingMenuItems.grouping,
            () => {
              formContext.setValue('groupByValues', null);
              formContext.setValue(
                'groupBy',
                formValues.xAxisMode === ConceptionRateParameterEnum.Bull
                  ? ConceptionRateParameterEnum.Date
                  : ConceptionRateParameterEnum.Bull
              );
            }
          )
          .exhaustive(),
    }));

  return {
    formValues,
    formContext,

    availableForFiltersAndGroupingItems,
  };
};
