import React from 'react';

import { FloatingPortal } from '@floating-ui/react';
import { useTransition, UseTransitionProps } from '@react-spring/web';

import { useIsPrefersReducedMotion } from '~/shared/hooks/useIsPrefersReducedMotion';

import { ModalNames } from '../../__generated__/modalNames';
import { SingleModalContext } from '../../context';
import { useModal } from '../../hooks';
import { AnyModalProps, ModalRegistration } from '../../types';

interface Props extends ModalRegistration<AnyModalProps> {
  /**
   * Name of Modal
   */
  name: ModalNames;
}

const TRANSITION_CONFIG: UseTransitionProps = {
  from: { opacity: 0, scale: 0.97 },
  enter: { opacity: 1, scale: 1 },
  leave: { opacity: 0, scale: 0.97 },
  config: (isOpen, index, phase) => key => {
    return {
      tension: 500,
      friction: 18,
      // Disable spring for close animation
      clamp: !isOpen || phase === 'leave' || key === 'opacity',
    };
  },
};

export const ModalWrapper: React.FC<Props> = modalRegistrationProps => {
  const { name, Component } = modalRegistrationProps;

  const { isOpen, props, ...otherModalProps } = useModal(name);

  const isPrefersReducedMotion = useIsPrefersReducedMotion();

  const transitions = useTransition<boolean, any>(isOpen, {
    ...TRANSITION_CONFIG,
    immediate: isPrefersReducedMotion,
  });

  // This is a workaround for this https://github.com/floating-ui/floating-ui/issues/2454
  // TODO remove, when the issue is resolved
  if (!isOpen) return null;

  return (
    <FloatingPortal>
      {transitions(
        (transitionStyles, shouldRender) =>
          shouldRender && (
            <SingleModalContext.Provider
              value={{
                modalName: name,
                transitionStyles,
              }}
            >
              <Component {...props} {...otherModalProps} />
            </SingleModalContext.Provider>
          )
      )}
    </FloatingPortal>
  );
};
