import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ExportJobFragmentDoc } from '../fragments/exportJob.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportJobsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type ExportJobsQuery = { __typename?: 'Query', exportJobs: { __typename?: 'ExportJobConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes: Array<{ __typename: 'ExportJob', id: string, status: Types.ExportJobStatus, file?: { __typename?: 'File', downloadUrl: string, name: string, extension: string } | null }> } };


export const ExportJobsDocument = gql`
    query exportJobs($ids: [ID!]) {
  exportJobs(ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...ExportJob
    }
  }
}
    ${ExportJobFragmentDoc}`;

/**
 * __useExportJobsQuery__
 *
 * To run a query within a React component, call `useExportJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportJobsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useExportJobsQuery(baseOptions?: Apollo.QueryHookOptions<ExportJobsQuery, ExportJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportJobsQuery, ExportJobsQueryVariables>(ExportJobsDocument, options);
      }
export function useExportJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportJobsQuery, ExportJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportJobsQuery, ExportJobsQueryVariables>(ExportJobsDocument, options);
        }
export function useExportJobsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExportJobsQuery, ExportJobsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportJobsQuery, ExportJobsQueryVariables>(ExportJobsDocument, options);
        }
export type ExportJobsQueryHookResult = ReturnType<typeof useExportJobsQuery>;
export type ExportJobsLazyQueryHookResult = ReturnType<typeof useExportJobsLazyQuery>;
export type ExportJobsSuspenseQueryHookResult = ReturnType<typeof useExportJobsSuspenseQuery>;
export type ExportJobsQueryResult = Apollo.QueryResult<ExportJobsQuery, ExportJobsQueryVariables>;