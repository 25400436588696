import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { ListItem } from '~/~legacy/types/keyValue';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { MToggleButtons } from '~/shared/components/MToggleButtons';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { BlueprintRunSettings, RunType } from '~/shared/graphql';

import { BlueprintDetailsEditModel } from '../../types';
import { BlueprintDetailsRunSettingsSchedule } from './components/BlueprintDetailsRunSettingsSchedule';

interface Props {
  className?: string;
  runSettings: BlueprintRunSettings;

  onChange: React.Dispatch<React.SetStateAction<BlueprintDetailsEditModel>>;
}

export const BlueprintDetailsRunSettings: React.FC<Props> = React.memo(
  ({ className, runSettings, onChange }) => {
    const { t } = useTranslation([MaslovNamespaces.integrator]);

    const toggelItems = useMemo(() => {
      const items: ListItem<RunType>[] = [
        {
          value: RunType.Schedule,
          content: t(IntegratorStrings.blueprintDetails.runSettings.schedule),
        },
        {
          value: RunType.Incident,
          content: t(IntegratorStrings.blueprintDetails.runSettings.incident),
        },
      ];
      return items;
    }, [t]);

    const runTypeChangeCallback = useCallback(
      (val: RunType) => {
        onChange(prevState => {
          return {
            ...prevState,
            runSettings: {
              ...prevState.runSettings,
              runType: val,
            },
          };
        });
      },
      [onChange]
    );

    const runSettingsDetailsMap = {
      [RunType.Incident]: null,
      [RunType.Schedule]: (
        <BlueprintDetailsRunSettingsSchedule
          onChange={onChange}
          runSettings={runSettings}
        />
      ),
    } as Record<RunType, ReactElement | null>;

    const runSettingsDetails = runSettingsDetailsMap[runSettings.runType];

    return (
      <div className={className || ''}>
        <Typography variant={TypographyVariants.heading2}>
          {t(IntegratorStrings.blueprintDetails.runSettings.title)}
        </Typography>
        <p className="my-16">
          {t(IntegratorStrings.blueprintDetails.runSettings.subtitle)}
        </p>

        <MToggleButtons
          items={toggelItems}
          selectedValue={runSettings.runType}
          onChange={runTypeChangeCallback}
        />

        {runSettingsDetails}
      </div>
    );
  }
);
