import { useCallback, useContext, useState } from 'react';

import { ServicesContext } from '../components/ServicesContext';

export function useServiceInitialization<T>() {
  const iocContainer = useContext(ServicesContext);

  const [initialized, setInitialized] = useState(false);

  const initService = useCallback(
    (serviceType: any, serviceInstance: T) => {
      const existingInstance = iocContainer[serviceType];

      const alreadyExists = Boolean(existingInstance);
      iocContainer[serviceType] = alreadyExists
        ? existingInstance
        : serviceInstance;
      setInitialized(true);

      if (alreadyExists) return false;
      // eslint-disable-next-line no-console
      console.info(`Service of type [${serviceType}] added to ioc container`);
      return true;
    },
    [iocContainer]
  );

  return { initService, initialized };
}
