import * as yup from 'yup';

/**
 * Form schema for blueprint source field value
 */
export const BLUEPRINT_SOURCE_FIELD_VALUE_SCHEMA = yup.object({
  intValue: yup.number().nullable(),
  floatValue: yup.number().nullable(),
  strValue: yup.string().nullable(),
  datetimeValue: yup.string().nullable(),
});
