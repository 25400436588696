import React, { ReactNode } from 'react';

import './MErrorMessage.scss';

interface Props {
  className?: string;
  children: ReactNode;
}

export const MErrorMessage: React.FC<Props> = props => {
  const { children, className } = props;

  const classValue = `m-error-message ${className || ''}`;
  return <p className={classValue}>{children}</p>;
};
