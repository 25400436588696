import React from 'react';

import clsx from 'clsx';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const SkeletonLineChart: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(styles.root, 'full-height', className)}>
      <svg viewBox="0 0 1239 258" fill="none">
        <path
          d="M1 198.5L96.5 111L191 156L285.5 68.5L381 135L476 90L573 110.5L667.5 90L763 256L858 155L953 211.5L1048 155L1143 242.5L1237.5 1"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
      </svg>
    </div>
  );
};
