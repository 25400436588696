import React, { ReactNode } from 'react';

import { formatPhoneNumber } from '~/shared/helpers/phone';

import { Typography, TypographyVariants } from '../../components/Typography';

interface Props extends Omit<React.ComponentPropsWithoutRef<'a'>, 'children'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Phone number string
   */
  phone?: string;
  /**
   * Typography variant, used to render link
   */
  typographyVariant?: TypographyVariants;
  /**
   * If passed, formattedPhone is passed as a render prop to render custom link
   */
  children?: (formattedPhone: string) => ReactNode;
}

export const PhoneLink: React.FC<Props> = ({
  className,
  phone,

  typographyVariant = TypographyVariants.bodySmall,

  children,

  ...linkProps
}) => {
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <Typography
      {...{
        tag: 'a',
        variant: typographyVariant,
        className,
        href: `tel:${formattedPhone}`,
        ...linkProps,
      }}
    >
      {typeof children === 'function'
        ? children(formattedPhone)
        : formattedPhone}
    </Typography>
  );
};
