import React from 'react';

import clsx from 'clsx';

import notificationStyles from '../../notifications.module.scss';
import { NotificationVariants } from '../../types';
import { Callout, CalloutProps } from '../Callout';
import styles from './index.module.scss';

export type AlertProps = CalloutProps;

export const Alert: React.FC<AlertProps> = ({
  className,
  variant = NotificationVariants.error,
  ...otherCalloutProps
}) => {
  return (
    <div className={clsx(styles.root, notificationStyles[variant], className)}>
      <Callout
        {...{
          variant,
          className: styles.callout,
          ...otherCalloutProps,
        }}
      />
    </div>
  );
};
