import React from 'react';

import { ButtonVariants } from '~/shared/components/Button';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { formatDateRange } from '~/services/dateTime';

import { ReproductionHdrAndPrReportDetailedFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrReportDetailed.graphql';
import { useCalculateReproductionHdrAndPrReportMutation } from '~/entities/reproductionHdrAndPrReports/gql/mutations/calculateReproductionHdrAndPrReport.graphql';
import { useSetReproductionHdrAndPrReportSettingsMutation } from '~/entities/reproductionHdrAndPrReports/gql/mutations/setReproductionHdrAndPrReportSettings.graphql';

import {
  ReportCardBuilderCard,
  useReportCardBuilder,
} from '~/features/reportCardBuilder';

import {
  FEMALE_ANIMAL_KINDS_DICT,
  REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_ID,
} from '../../constants';
import { mapReproductionHdrAndPrReportSettingsToForm } from '../../helpers';
import { useFarmWithReproductionSettingsFromCacheOrQuery } from '../../hooks';
import { ReproductionHdrAndPrReportData } from '../ReproductionHdrAndPrReportData';
import { ReproductionHdrAndPrReportSettingsForm } from '../ReproductionHdrAndPrReportSettingsForm';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to take settings from
   */
  reproductionHdrAndPrReport:
    | ReproductionHdrAndPrReportDetailedFragment
    | SkeletonPlaceholder;
}

export const ReproductionHdrAndPrReportCard: React.FC<Props> = ({
  className,
  reproductionHdrAndPrReport,
}) => {
  const [
    setReproductionHdrAndPrReportSettings,
    { loading: isSetReproductionHdrAndPrReportSettingsLoading },
  ] = useSetReproductionHdrAndPrReportSettingsMutation();

  const [
    calculateReproductionHdrAndPrReport,
    { reset: resetCalculateReproductionHdrAndPrReportMutation },
  ] = useCalculateReproductionHdrAndPrReportMutation();

  const {
    currentReportData,

    initialSettings,
    requestedSettings,

    setCurrentReportData,

    customReportCardProps,
    customReportSettingsFormProps,
  } = useReportCardBuilder({
    reportDeps: [reproductionHdrAndPrReport],

    getReportData: ([reproductionHdrAndPrReportDep]) =>
      reproductionHdrAndPrReportDep.calculatedReport,
    getReportSettings: ([reproductionHdrAndPrReportDep]) =>
      reproductionHdrAndPrReportDep.settings,
    mapReportSettingsToForm: mapReproductionHdrAndPrReportSettingsToForm,

    emptyReportData: {
      __typename: 'ReproductionHdrAndPrCalculatedReportEmpty',
      cacheCreatedAt: '',
    },

    calculateReport: (settingsFormValues, mutationContext) => {
      if (!settingsFormValues.voluntaryWaitingPeriodDays) {
        return Promise.reject(
          new Error('voluntaryWaitingPeriodDays is required')
        );
      }
      return calculateReproductionHdrAndPrReport({
        variables: {
          id: reproductionHdrAndPrReport.id,
          settings: settingsFormValues,
        },
        context: mutationContext,
      }).then(({ data }) => {
        if (data?.calculateReproductionHdrAndPrReport.__typename) {
          setCurrentReportData(data.calculateReproductionHdrAndPrReport);
        }
      });
    },
    setReportSettings: settingsForm =>
      setReproductionHdrAndPrReportSettings({
        variables: {
          id: reproductionHdrAndPrReport.id,
          input: settingsForm,
        },
      }),

    onCardEditCancel: resetCalculateReproductionHdrAndPrReportMutation,
  });

  const { fragment: currentFarm } =
    useFarmWithReproductionSettingsFromCacheOrQuery(requestedSettings.farmID);

  const formattedFarm = currentFarm?.name;
  const formattedReportRange = formatDateRange(
    initialSettings?.period.interval.since,
    initialSettings?.period.interval.till
  );
  const formattedFemaleAnimalKind =
    initialSettings &&
    FEMALE_ANIMAL_KINDS_DICT[initialSettings.femaleAnimalKind];
  const reportTitle = [
    formattedFarm,
    formattedReportRange,
    formattedFemaleAnimalKind,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <ReportCardBuilderCard
      {...{
        className,
        title: reportTitle,
        updatingTitle: 'Настройки графика и таблицы',
        ...customReportCardProps,
        settingsButtonProps: {
          variant: ButtonVariants.primary,
          children: 'Настройки',
        },
        submitButtonProps: {
          form: REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_ID,
          isLoading: isSetReproductionHdrAndPrReportSettingsLoading,
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(reproductionHdrAndPrReport) && (
            <ReproductionHdrAndPrReportSettingsForm
              {...{
                reproductionHdrAndPrReport,
                ...customReportSettingsFormProps,
              }}
            />
          ),
      }}
    >
      <Skeleton isLoading={isSkeletonPlaceholder(currentReportData)}>
        <ReproductionHdrAndPrReportData
          {...{
            reportData: currentReportData,
            requestedSettings,
          }}
        />
      </Skeleton>
    </ReportCardBuilderCard>
  );
};
