import { DocumentNode, MutationUpdaterFn } from '@apollo/client';
import { getOperationName, type StoreObject } from '@apollo/client/utilities';

import R from 'ramda';

import { StrictTypedTypePolicies } from '../../__generated__/typedTypePolicies';
import { QueryFieldsWithPolicies } from '../initializeApollo/typePolicies';
import { getQueryCacheName } from './getQueryCacheName';

interface StoreEdge {
  node: {
    id: string;
  };
}

interface MakeDeleteFragmentFromQueryProps<Variables> {
  /**
   * Type name of the fragment
   */
  typeName: keyof StrictTypedTypePolicies;
  /**
   * Query string (usually ends with suffix 'Document')
   */
  query: DocumentNode;
  /**
   * The name of query
   */
  queryName?: string;
  /**
   * variables used in the apollo query
   */
  variables?: Variables;
  /**
   * Some fragments doesn't have an id, so we need to pass a function to determine identify fields
   */
  getIdentifyFields?: (id: string) => Record<string, any>;
}

/**
 * Factory for making apollo query update functions and writing a fragment into store
 */
export const makeDeleteFragmentFromQuery =
  <QueryType extends any, QueryVariables extends Record<string, any>>({
    typeName,
    query,
    queryName: queryNameProp,
    variables,
    getIdentifyFields = id => ({ id }),
  }: MakeDeleteFragmentFromQueryProps<QueryVariables>) =>
  <Mutation extends any>(id: string): MutationUpdaterFn<Mutation> =>
  cache => {
    const identifyFields = getIdentifyFields(id);

    const prevQuery = cache.readQuery<QueryType, QueryVariables>({
      query,
      variables,
    });
    if (!prevQuery) return;

    const queryName = queryNameProp ?? getOperationName(query);

    if (!queryName) return;

    const queryCacheKey = getQueryCacheName(
      queryName as QueryFieldsWithPolicies,
      R.isEmpty(variables) ? undefined : variables
    );

    const fragmentId =
      cache.identify({ __typename: typeName, ...identifyFields }) ??
      `${typeName}:${id}`;

    const filterPaginatedQuery = (existing: Record<string, any>) => {
      return {
        ...existing,
        edges: (existing?.edges ?? []).filter((itemRef: StoreEdge) => {
          const cacheId = cache.identify(itemRef.node);
          // For now we assume, that having some global_id in the cache key is enough for identification.
          // For more complex cases we would need to add some search function in fabric options
          return cacheId !== fragmentId && !cacheId?.includes(`"${id}"`);
        }),
      };
    };

    const filterStoreObjects = (items: StoreObject[]) => {
      return items.filter(item => {
        const cacheId = cache.identify(item);
        // For now we assume, that having some global_id in the cache key is enough for identification.
        // For more complex cases we would need to add some search function in fabric options
        return cacheId !== fragmentId && !cacheId?.includes(`"${id}"`);
      });
    };

    cache.modify({
      fields: {
        [queryCacheKey]: (existing: Record<string, any> | StoreObject[]) => {
          // if gql response doesn't have a pagination structure (edge property)
          if (Array.isArray(existing)) {
            return filterStoreObjects(existing as StoreObject[]);
          }
          return filterPaginatedQuery(existing);
        },
      },
    });

    // Run the garbage collector if the filtered fragment is no longer linked to anything.
    cache.gc();
  };
