import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type JsonHardcodedValueFragment = { __typename: 'JSONHardcodedValue', jsonValue: string };

export const JsonHardcodedValueFragmentDoc = gql`
    fragment JSONHardcodedValue on JSONHardcodedValue {
  __typename
  jsonValue: value
}
    `;