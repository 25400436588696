import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintActionNode,
  BlueprintEditService,
  BlueprintFilterConditionNode,
  BlueprintFilterGroupNode,
  BlueprintNodeType,
} from '../../services';
import { FilterGroupNodeUI } from './components/FilterGroupNodeUI';

interface FilterGroupNodeState {
  filterGroups: BlueprintFilterGroupNode[];
  filerConditions: BlueprintFilterConditionNode[];
  actions: BlueprintActionNode[];
}

interface Props {
  filterGroup: BlueprintFilterGroupNode;
  topLevel?: boolean;
}

export const FilterGroupNode: React.FC<Props> = ({ filterGroup, topLevel }) => {
  const [state, setState] = useState<FilterGroupNodeState>({
    actions: [],
    filerConditions: [],
    filterGroups: [],
  });

  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  useEffect(() => {
    const subscription = bpEditSvc.blueprint?.filterNodes$.subscribe(
      filterGroups => {
        setState(prev => {
          return {
            ...prev,
            filterGroups: filterGroups.filter(
              group => group.parentFilterGroupId === filterGroup.id
            ),
          };
        });
      }
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, [setState]);

  useEffect(() => {
    const subscription = bpEditSvc.blueprint?.actionNodes$.subscribe(
      actions => {
        setState(prev => {
          return {
            ...prev,
            actions: actions.filter(
              action => action.filterGroupId === filterGroup.id
            ),
          };
        });
      }
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, [setState]);

  const memoizedDelete = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    errors: delErrors,
    loading: deleting,
    reload: deleteFunc,
  } = useApiData(memoizedDelete);

  const deleteCallback = useCallback(async () => {
    // will handle error with no Id in the service
    await deleteFunc({
      nodeId: filterGroup.id || '',
      nodeType: BlueprintNodeType.FilterGroup,
    });
  }, [deleteFunc]);

  const memoizedUpdate = useMemo(() => {
    return bpEditSvc.updateFilterGroup.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    errors: updateErorrs,
    loading: updating,
    reload: updateFunc,
  } = useApiData(memoizedUpdate);

  const updateCallback = useCallback(
    async (gorup: BlueprintFilterGroupNode) => {
      await updateFunc(gorup);
    },
    [updateFunc]
  );

  return (
    <FilterGroupNodeUI
      filterGroup={filterGroup}
      topLevel={topLevel}
      nestedFilterGroups={state.filterGroups}
      nestedActions={state.actions}
      errors={[...delErrors, ...updateErorrs]}
      loading={deleting || updating}
      delete={deleteCallback}
      update={updateCallback}
    />
  );
};
