import React, { useEffect, useState } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { useService } from '~/~legacy/hooks/useService';
import { useServiceInitialization } from '~/~legacy/hooks/useServiceInitialization';
import { HTTPTransport } from '~/~legacy/services/HttpTransport';
import { AppBlueprintsService } from '~/~legacy/services/implementations/AppBlueprintsService';
import { MaslovServices, StandardServices } from '~/~legacy/types/services';

import { Loader } from '~/shared/components/Loader';

import {
  AppBlueprintEditService,
  BlueprintEditService,
} from '~/widgets/blueprintEdit';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { MasterBlueprintEdit } from './components/MasterBlueprintEdit';
import styles from './index.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/integrator/blueprints/edit/master/'
)({
  wrapInSuspense: true,
  component: MasterBlueprintEditPage,
});

function MasterBlueprintEditPage() {
  const { initService, initialized } = useServiceInitialization();
  const http = useService<HTTPTransport>(StandardServices.Http);

  const [masterId, setMasterId] = useState<string>();
  const [bpEditSvc, setBpEditSvc] = useState<BlueprintEditService>();

  useEffect(() => {
    const blueprintEditSvc = new AppBlueprintEditService(http);
    const blueprintsSvc = new AppBlueprintsService(http);
    setBpEditSvc(blueprintEditSvc);
    initService(MaslovServices.BlueprintEditService, blueprintEditSvc);
    initService(MaslovServices.BlueprintsService, blueprintsSvc);
  }, []);

  useEffect(() => {
    if (!bpEditSvc) return;

    const load = async () => {
      const id = await bpEditSvc.getMasterBlueprintId();
      setMasterId(id);
    };

    load();
  }, [bpEditSvc]);

  const content =
    masterId && initialized ? (
      <MasterBlueprintEdit blueprintId={masterId} />
    ) : (
      <Loader className={layoutStyles.fullHeightContainer} />
    );

  return (
    <div
      className={clsx(
        layoutStyles.limitedContainer,
        layoutStyles.fullHeightContainer,
        styles.root
      )}
    >
      {content}
    </div>
  );
}
