import React, { useCallback, useMemo, useState } from 'react';

import clsx from 'clsx';

import { ReactComponent as TDown } from '~/~legacy/icons/triangle-down.svg';
import { ReactComponent as TRight } from '~/~legacy/icons/triangle-right.svg';
import { GroupedRow } from '~/~legacy/services/BlueprintExecutionService';

import { Badge } from '~/shared/components/Badge';
import { formatInt } from '~/shared/helpers/number';

import { useTableResultComponentsMap } from '../../hooks';
import styles from './index.module.scss';

interface Props {
  row: GroupedRow;
  totalColumns: number;
  level?: number;
}

export const GroupedTabelRow: React.FC<Props> = ({
  row,
  totalColumns,
  level = 0,
}) => {
  const componentsMap = useTableResultComponentsMap(totalColumns);

  const [expanded, setExpanded] = useState(true);

  const dataRows = useMemo(() => {
    return row.rows.map(item => {
      const getRow = componentsMap[item.__typename];
      return getRow(item, level + 1);
    });
  }, [row, totalColumns]);

  const content = expanded ? dataRows : null;

  const icon = expanded ? (
    <TDown className="mr-8" />
  ) : (
    <TRight className="mr-8" />
  );

  const groupClick = useCallback(() => {
    setExpanded(val => !val);
  }, [setExpanded]);

  const title = `${row.groupKey} ${row.groupKey ? ':' : ''} ${JSON.parse(
    row.groupValue
  )?.toString()}`;

  return (
    <>
      <tr
        onClick={groupClick}
        className={clsx(
          styles.groupRow,
          level ? styles.shift : styles.firstLevel
        )}
      >
        <td colSpan={totalColumns}>
          {icon}
          {title}
          <Badge className="ml-8" isPill>
            {formatInt(row.rowsCount)}
          </Badge>
        </td>
      </tr>
      {content}
    </>
  );
};
