import React from 'react';

export const WarningCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.75C7.41421 3.75 3.75 7.41421 3.75 12C3.75 16.5858 7.41421 20.25 12 20.25C16.5858 20.25 20.25 16.5858 20.25 12C20.25 7.41421 16.5858 3.75 12 3.75ZM2.25 12C2.25 6.58579 6.58579 2.25 12 2.25C17.4142 2.25 21.75 6.58579 21.75 12C21.75 17.4142 17.4142 21.75 12 21.75C6.58579 21.75 2.25 17.4142 2.25 12ZM12 15C11.4 15 11 15.4 11 16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16C13 15.4 12.6 15 12 15ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V13C11.25 13.4142 11.5858 13.75 12 13.75C12.4142 13.75 12.75 13.4142 12.75 13V8Z"
      fill="currentColor"
    />
  </svg>
);
