import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from '~/~legacy/hooks/useService';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintEditSection,
  BlueprintEditService,
  BlueprintSourceSection,
} from '~/widgets/blueprintEdit';

export const MasterViewSection: React.FC = () => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);
  const bulueprintEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );
  const [sections, setSections] = useState<BlueprintSourceSection[]>([]);

  useEffect(() => {
    bulueprintEditSvc.blueprint?.sourceNodes$.subscribe(items => {
      setSections(items);
    });
  }, []);

  return (
    <BlueprintEditSection
      sectionName={t(IntegratorStrings.blueprintEdit.masterViewSectionName)}
      sectionType="view"
    >
      <div className="p-8">
        {sections.map((section, index) => (
          <React.Fragment key={section.id}>
            <h5 title={section.kind} className={index !== 0 ? 'mt-8' : ''}>
              {section.verboseName}
            </h5>
            <ul>
              {section.fields.map(field => (
                <li key={field.id} title={field.kind}>
                  {field.name}
                </li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </div>
    </BlueprintEditSection>
  );
};
