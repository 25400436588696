import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
import { CowShortFragmentDoc } from '../../../../entities/cows/gql/fragments/cowShort.graphql';
export type MilkingParlorMixedMilkingReportRowEntryFragment = { __typename: 'MilkingParlorMixedMilkingReportRowEntry', id: string, stallNumber: string, attachTime: string, expectedPenGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number }, actualPenGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number }, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } };

export const MilkingParlorMixedMilkingReportRowEntryFragmentDoc = gql`
    fragment MilkingParlorMixedMilkingReportRowEntry on MilkingParlorMixedMilkingReportRowEntry {
  __typename
  id
  expectedPenGroup {
    ...PenGroupShort
  }
  actualPenGroup {
    ...PenGroupShort
  }
  cow {
    ...CowShort
  }
  stallNumber
  attachTime
}
    ${PenGroupShortFragmentDoc}
${CowShortFragmentDoc}`;