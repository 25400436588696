import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SourceFieldFragmentDoc } from '../../../../entities/blueprintSourceFields/gql/fragments/sourceField.graphql';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from '../../../../entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';
export type CustomReportBlueprintLaunchResultFragment = { __typename: 'CustomReportBlueprintLaunchResult', columnSourceFields: Array<{ __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum }>, rows: Array<{ __typename?: 'CustomReportBlueprintLaunchResultRow', values: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null }> }> };

export const CustomReportBlueprintLaunchResultFragmentDoc = gql`
    fragment CustomReportBlueprintLaunchResult on CustomReportBlueprintLaunchResult {
  __typename
  columnSourceFields {
    ...SourceField
  }
  rows {
    values {
      ...CustomReportBlueprintSourceFieldValue
    }
  }
}
    ${SourceFieldFragmentDoc}
${CustomReportBlueprintSourceFieldValueFragmentDoc}`;