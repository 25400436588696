import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type UserEventFragment = { __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum };

export const UserEventFragmentDoc = gql`
    fragment UserEvent on UserEvent {
  __typename
  id
  name
  kind
}
    `;