import { createContext, ReactNode, useContext } from 'react';

/**
 * Skeleton context function to render some element with a loading skeleton fallback
 */
export type RenderWithSkeleton = (
  skeleton: ReactNode,
  content: ReactNode,
  isStaticContent?: boolean
) => ReactNode;

/**
 * Context type for a skeleton context
 */
export interface SkeletonContextType {
  isLoading: boolean;
  renderWithSkeleton: RenderWithSkeleton;
  renderWithoutSkeleton: (content: ReactNode) => ReactNode;
  getSkeletonClassNames: (
    skeletonClassNames: string,
    contentClassNames?: string
  ) => string;
}

/**
 * Context that stores the loading state of a skeleton-able component, for now we just store loading state
 */
export const SkeletonContext = createContext<SkeletonContextType>({
  isLoading: false,
  renderWithSkeleton: (skeleton, content) => content,
  renderWithoutSkeleton: content => content,
  getSkeletonClassNames: (skeletonClassNames, contentClassNames = '') =>
    contentClassNames,
});

/**
 * Hook for using the global skeleton context to show skeleton blocks, where needed
 */
export const useSkeletonContext = () => useContext(SkeletonContext);
