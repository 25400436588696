import React from 'react';

import { useFocusRing } from '@react-aria/focus';
import { usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { Icon, IconProps, IconVariants } from '~/shared/components/Icon';
import { mergeProps } from '~/shared/helpers/mergeProps';

import COMPONENT_TOKENS from '~/styles/__generated__/component-tokens.json';
import { CloseButtonSizes } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

/**
 * Possible button color themes
 */
export enum CloseButtonThemes {
  onLight = 'onLight',
  onDark = 'onDark',
}

interface Props extends Omit<React.ComponentProps<'button'>, 'disabled'> {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Button color theme
   */
  theme?: CloseButtonThemes;
  /**
   * Button size
   */
  size?: CloseButtonSizes;

  /**
   * Called, when button is pressed via mouse or keyboard
   */
  onPress?: () => void;
  /**
   * Don't use this prop, use onPress for accessibility reasons
   */
  onClick?: undefined;

  /**
   * Additional props for close icon
   */
  iconProps?: Partial<Omit<IconProps, 'ref'>>;
}

export const CloseButton: React.FC<Props> = ({
  className,

  theme = CloseButtonThemes.onLight,
  size = CloseButtonSizes.large24,

  onPress,

  iconProps,

  ...other
}) => {
  const { pressProps, isPressed } = usePress({
    onPress,
  });

  // :focus-visible is not working with usePress correctly, so we use react-aria solution
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <button
      {...{
        type: 'button',
        className: clsx(styles.root, className, styles[theme], styles[size], {
          [styles.pressed]: isPressed,
          [styles.focused]: isFocusVisible,
        }),
        ...mergeProps(pressProps, focusProps, other),
      }}
    >
      <Icon
        {...{
          variant: IconVariants.xClose,
          size: COMPONENT_TOKENS.closeButton.size[size],
          ...iconProps,
        }}
      />
    </button>
  );
};

export { CloseButtonSizes } from '~/styles/__generated__/token-variants';
