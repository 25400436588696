import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintForCustomReportFragmentDoc } from './blueprintForCustomReport.graphql';
import { RoleFragmentDoc } from '../../../../services/auth/gql/fragments/role.graphql';
export type CustomReportFragment = { __typename: 'CustomReport', id: string, name: string, blueprint: { __typename: 'Blueprint', id: string, name: string, description: string, inputs: Array<{ __typename: 'BlueprintInput', id: string, name: string, prompt: string, valueKinds: Array<Types.ValueKindEnum> }> }, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> };

export const CustomReportFragmentDoc = gql`
    fragment CustomReport on CustomReport {
  __typename
  id
  name
  blueprint {
    ...BlueprintForCustomReport
  }
  roles {
    ...Role
  }
}
    ${BlueprintForCustomReportFragmentDoc}
${RoleFragmentDoc}`;