import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMilkingParlorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteMilkingParlorMutation = { __typename?: 'Mutation', deleteMilkingParlor?: any | null };


export const DeleteMilkingParlorDocument = gql`
    mutation deleteMilkingParlor($id: ID!) {
  deleteMilkingParlor(id: $id)
}
    `;
export type DeleteMilkingParlorMutationFn = Apollo.MutationFunction<DeleteMilkingParlorMutation, DeleteMilkingParlorMutationVariables>;

/**
 * __useDeleteMilkingParlorMutation__
 *
 * To run a mutation, you first call `useDeleteMilkingParlorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMilkingParlorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMilkingParlorMutation, { data, loading, error }] = useDeleteMilkingParlorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMilkingParlorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMilkingParlorMutation, DeleteMilkingParlorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMilkingParlorMutation, DeleteMilkingParlorMutationVariables>(DeleteMilkingParlorDocument, options);
      }
export type DeleteMilkingParlorMutationHookResult = ReturnType<typeof useDeleteMilkingParlorMutation>;
export type DeleteMilkingParlorMutationResult = Apollo.MutationResult<DeleteMilkingParlorMutation>;
export type DeleteMilkingParlorMutationOptions = Apollo.BaseMutationOptions<DeleteMilkingParlorMutation, DeleteMilkingParlorMutationVariables>;