import { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import { useMyUserUnsafe } from '~/services/auth';

export const useSentry = () => {
  const { myUser } = useMyUserUnsafe();
  useEffect(() => {
    if (!myUser) return;
    Sentry.setUser({ id: myUser.id });
  }, [myUser]);
};
