export const ValidationStrings = {
  validation: {
    mixed: {
      required: 'validation.mixed.required',
      notNull: 'validation.mixed.notNull',
      notType: 'validation.mixed.notType',
    },
    string: {
      email: 'validation.string.email',
      min: 'validation.string.min',
      max: 'validation.string.max',
    },
    number: {
      positive: 'validation.number.positive',
      min: 'validation.number.min',
      max: 'validation.number.max',
    },
    array: {
      min: 'validation.array.min',
    },
  },
} as const;
