import React from 'react';

export const Download = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25C12.4143 2.25 12.75 2.58579 12.75 3V13.1893L15.6697 10.2697C15.9626 9.97678 16.4375 9.97678 16.7304 10.2697C17.0233 10.5626 17.0233 11.0374 16.7304 11.3303L12.5304 15.5303C12.2375 15.8232 11.7626 15.8232 11.4697 15.5303L7.26971 11.3303C6.97682 11.0374 6.97682 10.5626 7.26971 10.2697C7.56261 9.97678 8.03748 9.97678 8.33037 10.2697L11.25 13.1893V3C11.25 2.58579 11.5858 2.25 12 2.25ZM2.66463 16.3292C3.03512 16.1439 3.48562 16.2941 3.67086 16.6646L4.56529 18.4534C5.11581 19.5545 6.24117 20.25 7.47218 20.25H16.5279C17.7589 20.25 18.8843 19.5545 19.4348 18.4534L20.3292 16.6646C20.5145 16.2941 20.965 16.1439 21.3355 16.3292C21.7059 16.5144 21.8561 16.9649 21.6709 17.3354L20.7764 19.1243C19.9718 20.7335 18.3271 21.75 16.5279 21.75H7.47218C5.67301 21.75 4.02826 20.7335 3.22365 19.1243L2.32922 17.3354C2.14398 16.9649 2.29415 16.5144 2.66463 16.3292Z"
      fill="currentColor"
    />
  </svg>
);
