import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';

import {
  CustomMilkingReportDataSource,
  CustomMilkingReportYAxisFieldKind,
} from '@graphql-types';

import { useForm } from '~/services/forms';

import { useReportCardBuilderForm } from '~/features/reportCardBuilder';

import {
  CUSTOM_MILKING_REPORT_FILTER_SCHEMA,
  CUSTOM_MILKING_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT,
  CUSTOM_MILKING_REPORT_GROUPING_SCHEMA,
  CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA,
  MAX_Y_AXIS_FIELDS_FOR_GROUPING,
} from '../../../constants';
import {
  formatYAxisFieldName,
  mapCustomMilkingReportSettingsToForm,
} from '../../../helpers';
import {
  CustomMilkingReportFiltersAndGroupingMenuItems,
  CustomMilkingReportSettingsFormType,
} from '../../../types';
import { CustomMilkingReportSettingsFormProps } from '../types';

export const useCustomMilkingReportSettingsForm = ({
  customMilkingReport,

  onSettingsFormChange,
}: CustomMilkingReportSettingsFormProps) => {
  const reportSettings = customMilkingReport.settings;

  const defaultValues = useMemo(
    () => mapCustomMilkingReportSettingsToForm(reportSettings),
    [reportSettings]
  );

  const formContext = useForm<CustomMilkingReportSettingsFormType>({
    schema: CUSTOM_MILKING_REPORT_SETTINGS_FORM_SCHEMA,
    defaultValues,
  });

  const {
    fields: yAxisFieldsArrayItems,
    remove: removeFromYAxisFieldsArray,
    append: appendToYAxisFieldsArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'yAxisFields',
  });

  const {
    fields: filtersArrayItems,
    remove: removeFromFiltersArray,
    append: appendToFiltersArray,
  } = useFieldArray({
    control: formContext.control,
    name: 'filters',
  });

  const { formValues } = useReportCardBuilderForm({
    formContext,
    defaultValues,
    onSettingsFormChange,
  });

  const selectedDataSource = formValues.dataSource;
  const isMilkingParlorsSelected =
    selectedDataSource === CustomMilkingReportDataSource.MilkingParlors;

  // Y axis fields adding
  const availableForYAxisMenuItems = (
    Object.keys(
      CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS
    ) as CustomMilkingReportYAxisFieldKind[]
  )
    .filter(fieldKind => {
      if (
        isMilkingParlorsSelected &&
        !CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[fieldKind]
          .isAvailableForMilkingParlors
      ) {
        return false;
      }
      return !formValues.yAxisFields.some(field => field.kind === fieldKind);
    })
    .map(yAxisField => ({
      key: yAxisField,
      content: formatYAxisFieldName(yAxisField),
    }));

  const addToYAxisFieldsArray = (
    item: (typeof availableForYAxisMenuItems)[number]
  ) => {
    const formDefaults = CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_SCHEMA.getDefault();
    const shouldHaveGrouping =
      CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[item.key]
        .shouldHaveGroupingFormula;
    appendToYAxisFieldsArray({
      ...formDefaults,
      groupBy: shouldHaveGrouping ? formDefaults.groupBy : null,
      kind: item.key,
    });
  };

  const availableForAddingFiltersAndGrouping = Object.values(
    CustomMilkingReportFiltersAndGroupingMenuItems
  ).filter(item => {
    if (item === CustomMilkingReportFiltersAndGroupingMenuItems.grouping) {
      return (
        yAxisFieldsArrayItems.length <= MAX_Y_AXIS_FIELDS_FOR_GROUPING &&
        !formValues.grouping &&
        !formValues.shouldCompareWithHistoricData
      );
    }
    return true;
  });

  // Filters and grouping adding
  const availableForFiltersAndGroupingItems =
    availableForAddingFiltersAndGrouping.map(menuItem => ({
      key: menuItem,
      content:
        CUSTOM_MILKING_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[menuItem],
      onPress: () => {
        if (
          menuItem === CustomMilkingReportFiltersAndGroupingMenuItems.filter
        ) {
          appendToFiltersArray(
            CUSTOM_MILKING_REPORT_FILTER_SCHEMA.getDefault()
          );
        } else {
          formContext.setValue(
            'grouping',
            CUSTOM_MILKING_REPORT_GROUPING_SCHEMA.getDefault()
          );
        }
      },
    }));

  return {
    formValues,
    formContext,

    yAxisFieldsArrayItems,
    availableForYAxisMenuItems,
    removeFromYAxisFieldsArray,
    addToYAxisFieldsArray,

    filtersArrayItems,
    availableForFiltersAndGroupingItems,
    removeFromFiltersArray,
  };
};
