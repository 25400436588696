import React, { useEffect, useState } from 'react';

import { CatchBoundary } from '@tanstack/react-router';
import { tap } from 'rxjs';

import { useObservable } from '~/shared/hooks/useObservable';

import { NetworkError, networkErrorsSubject$ } from '~/services/gql';

import { ClientErrorPage } from '../ClientErrorPage';
import { TechnicalMaintenancePage } from '../TechnicalMaintenancePage';

interface Props {
  /**
   * Caught by error boundary error
   */
  error: Error;
  /**
   * Function to reset error
   */
  reset: () => void;
  /**
   * Error info
   */
  info?: { componentStack: string };
}

export const ErrorBoundary: React.FC<Props> = ({ error, reset }) => {
  const [shouldShowTechMaintenanceError, setShouldShowTechMaintenanceError] =
    useState(false);

  useEffect(() => {
    const hasChunk = error.message.indexOf('chunk') !== -1;
    const hasFailed = error.message.indexOf('failed') !== -1;
    const hasMissing = error.message.indexOf('missing:') !== -1;

    if (hasChunk && hasFailed && hasMissing) {
      window.location.reload();
    }
  }, [error]);

  const processNetworkError = (networkError: NetworkError) => {
    if (networkError?.statusCode === 503) {
      setShouldShowTechMaintenanceError(true);
    }
  };

  useObservable(
    networkErrorsSubject$.pipe(tap(processNetworkError)),
    undefined
  );

  return shouldShowTechMaintenanceError ? (
    <TechnicalMaintenancePage />
  ) : (
    <CatchBoundary getResetKey={() => (error ? 'error' : 'reset')}>
      <ClientErrorPage onUpdatePage={reset} />
    </CatchBoundary>
  );
};
