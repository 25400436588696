import {
  LactationGroupNumber,
  MilkingHistoricFilterKind,
} from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { DATE_PERIOD_FORM_SCHEMA } from '~/services/dateTime';

/**
 * Form id for somatic cells edit form
 */
export const SOMATIC_CELLS_REPORT_SETTINGS_FORM_ID =
  'SomaticCellsReportSettingsForm';

/**
 * Form schema for somatic cells report filter value
 */
const SOMATIC_CELLS_REPORT_VALUE_SCHEMA = yup.object({
  lactationNumbers: yup.array(yup.number().required()).nullable().default(null),
  lactationGroupNumbers: yup
    .array(oneOfEnum(LactationGroupNumber).required())
    .nullable()
    .default(null),
  penGroupIDs: yup.array(yup.string().required()).nullable().default(null), // [ID!]
});

export const SOMATIC_CELLS_REPORT_FILTER_SCHEMA = yup.object({
  kind: oneOfEnum(MilkingHistoricFilterKind).nullable().default(null),
  value: SOMATIC_CELLS_REPORT_VALUE_SCHEMA.nullable().default(null),
});

/**
 * Form schema for somatic cells report settings
 */
export const SOMATIC_CELLS_REPORT_SETTINGS_FORM_SCHEMA = yup.object({
  blueprintID: yup.string().nullable().default(null), // ID
  sccThousandsPerMl: yup.number().nullable().default(null),
  period: DATE_PERIOD_FORM_SCHEMA,
  filters: yup.array(SOMATIC_CELLS_REPORT_FILTER_SCHEMA).default([]),
});
