import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateReproductionCrReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateReproductionCrReportInput;
}>;


export type UpdateReproductionCrReportMutation = { __typename?: 'Mutation', updateReproductionCrReport?: any | null };


export const UpdateReproductionCrReportDocument = gql`
    mutation updateReproductionCrReport($id: ID!, $input: UpdateReproductionCrReportInput!) {
  updateReproductionCrReport(id: $id, input: $input)
}
    `;
export type UpdateReproductionCrReportMutationFn = Apollo.MutationFunction<UpdateReproductionCrReportMutation, UpdateReproductionCrReportMutationVariables>;

/**
 * __useUpdateReproductionCrReportMutation__
 *
 * To run a mutation, you first call `useUpdateReproductionCrReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReproductionCrReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReproductionCrReportMutation, { data, loading, error }] = useUpdateReproductionCrReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReproductionCrReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReproductionCrReportMutation, UpdateReproductionCrReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReproductionCrReportMutation, UpdateReproductionCrReportMutationVariables>(UpdateReproductionCrReportDocument, options);
      }
export type UpdateReproductionCrReportMutationHookResult = ReturnType<typeof useUpdateReproductionCrReportMutation>;
export type UpdateReproductionCrReportMutationResult = Apollo.MutationResult<UpdateReproductionCrReportMutation>;
export type UpdateReproductionCrReportMutationOptions = Apollo.BaseMutationOptions<UpdateReproductionCrReportMutation, UpdateReproductionCrReportMutationVariables>;