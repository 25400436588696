import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintLaunchFragmentDoc } from '../fragments/blueprintLaunch.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlueprintLaunchesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  since?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  till?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type BlueprintLaunchesQuery = { __typename?: 'Query', blueprintLaunches: { __typename?: 'BlueprintLaunchConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'BlueprintLaunchEdge', cursor: string, node: { __typename: 'BlueprintLaunch', id: string, hasEvents: boolean, cowsCount: number, createdAt: string, user: { __typename: 'User', id: string, firstName: string, lastName: string, middleName: string }, blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string } | null } }> } };


export const BlueprintLaunchesDocument = gql`
    query blueprintLaunches($after: String, $first: Int, $search: String, $since: Date, $till: Date) {
  blueprintLaunches(
    after: $after
    first: $first
    search: $search
    since: $since
    till: $till
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...BlueprintLaunch
      }
    }
  }
}
    ${BlueprintLaunchFragmentDoc}`;

/**
 * __useBlueprintLaunchesQuery__
 *
 * To run a query within a React component, call `useBlueprintLaunchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintLaunchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintLaunchesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      since: // value for 'since'
 *      till: // value for 'till'
 *   },
 * });
 */
export function useBlueprintLaunchesQuery(baseOptions?: Apollo.QueryHookOptions<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>(BlueprintLaunchesDocument, options);
      }
export function useBlueprintLaunchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>(BlueprintLaunchesDocument, options);
        }
export function useBlueprintLaunchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>(BlueprintLaunchesDocument, options);
        }
export type BlueprintLaunchesQueryHookResult = ReturnType<typeof useBlueprintLaunchesQuery>;
export type BlueprintLaunchesLazyQueryHookResult = ReturnType<typeof useBlueprintLaunchesLazyQuery>;
export type BlueprintLaunchesSuspenseQueryHookResult = ReturnType<typeof useBlueprintLaunchesSuspenseQuery>;
export type BlueprintLaunchesQueryResult = Apollo.QueryResult<BlueprintLaunchesQuery, BlueprintLaunchesQueryVariables>;