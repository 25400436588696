import React from 'react';

import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';

interface Props {
  canSubmit: boolean;
  className?: string;

  submit?: () => void;
  cancel: () => void;
}

export const MControlsPanel: React.FC<Props> = props => {
  const { canSubmit, className, cancel, submit } = props;
  return (
    <div className={clsx('flex justify-end gap-8', className)}>
      <Button
        {...{
          type: 'reset',
          variant: ButtonVariants.secondary,
          onPress: cancel,
          iconVariant: IconVariants.xClose,
        }}
      />
      <Button
        {...{
          type: 'submit',
          isDisabled: !canSubmit,
          onPress: submit,
          iconVariant: IconVariants.check,
        }}
      />
    </div>
  );
};
