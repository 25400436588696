import { makeUseFragmentFromCacheOrQuery } from '~/services/gql';

import {
  FarmWithReproductionSettingsFragment,
  FarmWithReproductionSettingsFragmentDoc,
} from '../gql/fragments/farmWithReproductionSettings.graphql';
import {
  FarmsWithReproductionSettingsQuery,
  FarmsWithReproductionSettingsQueryVariables,
  useFarmsWithReproductionSettingsQuery,
} from '../gql/queries/farmsWithReproductionSettings.graphql';

/**
 * Hook for getting farm with reproduction settings and targets from cache or by query
 */
export const useFarmWithReproductionSettingsFromCacheOrQuery =
  makeUseFragmentFromCacheOrQuery<
    FarmWithReproductionSettingsFragment,
    FarmsWithReproductionSettingsQuery,
    FarmsWithReproductionSettingsQueryVariables
  >({
    typeName: 'FarmWithReproductionSettings',
    fragment: FarmWithReproductionSettingsFragmentDoc,
    useQuery: useFarmsWithReproductionSettingsQuery,
    getItemFromQueryData: data => data.farms.nodes.at(0),
  });
