import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowFragmentDoc } from '../../../../../../../entities/cows/gql/fragments/cow.graphql';
export type CowPlannedInjectionsFragment = { __typename: 'CowPlannedInjections', diseaseName: string, protocolName: string, expectedDaysOnProtocol: number, protocolDayNumber: number, injectionNames: Array<string>, protocolDayComments: Array<string>, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number } }, cowProtocol: { __typename: 'CowProtocol', id: string } };

export const CowPlannedInjectionsFragmentDoc = gql`
    fragment CowPlannedInjections on CowPlannedInjections {
  __typename
  cow {
    ...Cow
  }
  cowProtocol {
    __typename
    id
  }
  diseaseName
  protocolName
  expectedDaysOnProtocol
  protocolDayNumber
  injectionNames
  protocolDayComments
}
    ${CowFragmentDoc}`;