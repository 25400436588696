import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from '~/~legacy/hooks/useService';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { MaslovServices } from '~/~legacy/types/services';

import { Button, ButtonVariants } from '~/shared/components/Button';

import { getEditElement } from '../../helpers';
import {
  BlueprintCycleInputNode,
  BlueprintEditService,
  BlueprintInputNode,
  BlueprintNodeType,
  BlueprintSourceSection,
} from '../../services';
import { BlueprintEditSection } from '../BlueprintEditSection';
import { CycleInputNode } from './components/CycleInputNode';
import { InputNode } from './components/InputNode';
import { SectionNode } from './components/SectionNode';
import styles from './index.module.scss';

interface Props {
  masterPrint?: boolean;
}

export const SourceSection: React.FC<Props> = React.memo(({ masterPrint }) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const bpEditingSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const [state, setState] = useState({
    addBlockType: BlueprintNodeType.None,
    sections: [] as BlueprintSourceSection[],
    inputs: [] as BlueprintInputNode[],
    cycleInputs: [] as BlueprintCycleInputNode[],
  });

  const canAddSection = bpEditingSvc.getAvailableSections().length > 0;

  useEffect(() => {
    const sourceSection$ = masterPrint
      ? bpEditingSvc.blueprint?.masterSourceNodes$
      : bpEditingSvc.blueprint?.sourceNodes$;

    const sourceNodesSubscription = sourceSection$?.subscribe(val => {
      setState(prevState => {
        return {
          ...prevState,
          sections: val,
        };
      });
    });

    const inputNodesSubscription =
      bpEditingSvc.blueprint?.inputNodes$.subscribe(val => {
        setState(prevState => {
          return {
            ...prevState,
            inputs: val.filter(input => !input.cycleInputId),
          };
        });
      });

    const cycleInputNodesSubscription =
      bpEditingSvc.blueprint?.cycleInputNodes$.subscribe(val => {
        setState(prevState => {
          return {
            ...prevState,
            cycleInputs: val,
          };
        });
      });

    return () => {
      sourceNodesSubscription?.unsubscribe();
      inputNodesSubscription?.unsubscribe();
      cycleInputNodesSubscription?.unsubscribe();
    };
  }, [setState]);

  const cancelCallback = useCallback(() => {
    setState(prevState => {
      return {
        ...prevState,
        addBlockType: BlueprintNodeType.None,
      };
    });
  }, [setState]);

  const editElement = getEditElement(state.addBlockType, cancelCallback);

  const sections = state.sections.map(section => (
    <SectionNode key={section.id} section={section} />
  ));

  const inputs = state.inputs.map(input => (
    <InputNode key={input.id} input={input} />
  ));

  const cycleInputs = state.cycleInputs.map(input => (
    <CycleInputNode key={input.id} cycleInput={input} />
  ));

  const addBlock = (type: BlueprintNodeType) => {
    setState({
      ...state,
      addBlockType: type,
    });
  };

  const inputButton = masterPrint ? null : (
    <Button
      variant={ButtonVariants.secondary}
      className={styles.button}
      onPress={() => {
        addBlock(BlueprintNodeType.Input);
      }}
    >
      {t(IntegratorStrings.blueprintEdit.source.userInputButton)}
    </Button>
  );

  const cycleInputButton = masterPrint ? null : (
    <Button
      variant={ButtonVariants.secondary}
      className={styles.button}
      onPress={() => {
        addBlock(BlueprintNodeType.CycleInputs);
      }}
    >
      {t(IntegratorStrings.blueprintEdit.source.cycleInputButton)}
    </Button>
  );

  return (
    <BlueprintEditSection
      sectionType="source"
      sectionName={t(IntegratorStrings.blueprintEdit.source.caption)}
      controls={
        <>
          <Button
            variant={ButtonVariants.secondary}
            isDisabled={!canAddSection}
            className={styles.button}
            onPress={() => {
              addBlock(BlueprintNodeType.Section);
            }}
          >
            {t(IntegratorStrings.blueprintEdit.source.sectionButton)}
          </Button>
          {inputButton}
          {cycleInputButton}
        </>
      }
    >
      {sections}
      {inputs}
      {cycleInputs}
      <Suspense fallback="loading">{editElement}</Suspense>
    </BlueprintEditSection>
  );
});
