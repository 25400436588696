import { makeUpdateFragment } from '~/services/gql';

import {
  FarmWithReproductionSettingsFragment,
  FarmWithReproductionSettingsFragmentDoc,
} from '../gql/fragments/farmWithReproductionSettings.graphql';

/**
 * Update FarmWithReproductionSettings in the cache
 */
export const updateFarmWithReproductionSettingsFragment =
  makeUpdateFragment<FarmWithReproductionSettingsFragment>({
    typeName: 'Farm',
    fragment: FarmWithReproductionSettingsFragmentDoc,
  });
