import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { RoleFragmentDoc } from '../../../../services/auth/gql/fragments/role.graphql';
export type CustomMilkingReportFragment = { __typename: 'CustomMilkingReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> };

export const CustomMilkingReportFragmentDoc = gql`
    fragment CustomMilkingReport on CustomMilkingReport {
  __typename
  id
  name
  roles {
    ...Role
  }
}
    ${RoleFragmentDoc}`;