import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorMilkingMistakesReportRowEntryFragmentDoc } from '../fragments/milkingParlorMilkingMistakesReportRowEntry.graphql';
import { MilkingParlorMilkingMistakesReportGroupedRowEntryFragmentDoc } from '../fragments/milkingParlorMilkingMistakesReportGroupedRowEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorMilkingMistakesReportRowsQueryVariables = Types.Exact<{
  milkingDate: Types.Scalars['Date']['input'];
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingParlorIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MilkingParlorMilkingMistakesReportRowsQuery = { __typename?: 'Query', milkingParlorMilkingMistakesReportRows: Array<{ __typename: 'MilkingParlorMilkingMistakesReportRowEntry', reattachCount: number, noMilkCount: number, enteredTwiceCount: number, noLetdownCount: number, earlyFalloffCount: number, lateRehangCount: number, manualDetachCount: number, manualModeCount: number, notIdentifiedCount: number, dryCount: number, totalCount: number, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number } } | { __typename: 'MilkingParlorMilkingMistakesReportGroupedRowEntry', reattachCount: number, noMilkCount: number, enteredTwiceCount: number, noLetdownCount: number, earlyFalloffCount: number, lateRehangCount: number, manualDetachCount: number, manualModeCount: number, notIdentifiedCount: number, dryCount: number, totalCount: number }> };


export const MilkingParlorMilkingMistakesReportRowsDocument = gql`
    query milkingParlorMilkingMistakesReportRows($milkingDate: Date!, $farmIDs: [ID!], $milkingParlorIDs: [ID!], $milkingNumbers: [Int!]) {
  milkingParlorMilkingMistakesReportRows(
    milkingDate: $milkingDate
    farmIDs: $farmIDs
    milkingParlorIDs: $milkingParlorIDs
    milkingNumbers: $milkingNumbers
  ) {
    ... on MilkingParlorMilkingMistakesReportRowEntry {
      ...MilkingParlorMilkingMistakesReportRowEntry
    }
    ... on MilkingParlorMilkingMistakesReportGroupedRowEntry {
      ...MilkingParlorMilkingMistakesReportGroupedRowEntry
    }
  }
}
    ${MilkingParlorMilkingMistakesReportRowEntryFragmentDoc}
${MilkingParlorMilkingMistakesReportGroupedRowEntryFragmentDoc}`;

/**
 * __useMilkingParlorMilkingMistakesReportRowsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorMilkingMistakesReportRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorMilkingMistakesReportRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorMilkingMistakesReportRowsQuery({
 *   variables: {
 *      milkingDate: // value for 'milkingDate'
 *      farmIDs: // value for 'farmIDs'
 *      milkingParlorIDs: // value for 'milkingParlorIDs'
 *      milkingNumbers: // value for 'milkingNumbers'
 *   },
 * });
 */
export function useMilkingParlorMilkingMistakesReportRowsQuery(baseOptions: Apollo.QueryHookOptions<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables> & ({ variables: MilkingParlorMilkingMistakesReportRowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables>(MilkingParlorMilkingMistakesReportRowsDocument, options);
      }
export function useMilkingParlorMilkingMistakesReportRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables>(MilkingParlorMilkingMistakesReportRowsDocument, options);
        }
export function useMilkingParlorMilkingMistakesReportRowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables>(MilkingParlorMilkingMistakesReportRowsDocument, options);
        }
export type MilkingParlorMilkingMistakesReportRowsQueryHookResult = ReturnType<typeof useMilkingParlorMilkingMistakesReportRowsQuery>;
export type MilkingParlorMilkingMistakesReportRowsLazyQueryHookResult = ReturnType<typeof useMilkingParlorMilkingMistakesReportRowsLazyQuery>;
export type MilkingParlorMilkingMistakesReportRowsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorMilkingMistakesReportRowsSuspenseQuery>;
export type MilkingParlorMilkingMistakesReportRowsQueryResult = Apollo.QueryResult<MilkingParlorMilkingMistakesReportRowsQuery, MilkingParlorMilkingMistakesReportRowsQueryVariables>;