import React, { useMemo } from 'react';

import {
  ConceptionRateParameterEnum,
  ReproductionCrReportChartKind,
  ReproductionYAxisMode,
} from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { wrapConditionalArrayElement } from '~/shared/helpers/array';

import { ReproductionCrCrossTableSubTableFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrCrossTableSubTable.graphql';

import {
  BarChart,
  BarChartDatasetConfig,
  CHART_COLOR_PROPS_ITERATOR,
  DataPointsTooltip,
  getChartOptions,
  getScaleOptions,
  PERCENT_LINEAR_Y_SCALE_OPTIONS,
} from '~/features/charts';

import panelStyles from '~/styles/modules/panel.module.scss';

import {
  REPRODUCTION_CHART_OPTIONS,
  REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT,
} from '../../../../constants';
import { formatReproductionCrValue } from '../../../../helpers';
import { CrReportDisplayProps } from '../../../../types';

interface Props extends Pick<CrReportDisplayProps, 'xAxisMode'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Mode for y axis
   */
  yAxisMode?: ReproductionYAxisMode;
  /**
   * Cross CR data to display
   */
  chartData: ReproductionCrCrossTableSubTableFragment;
  /**
   * Group by parameter
   */
  groupBy: ConceptionRateParameterEnum;
  /**
   * Chart kind to use
   */
  chartKind?: ReproductionCrReportChartKind;
  /**
   * If true, adds additional x point to the chart with others inseminations
   */
  shouldShowOther?: boolean;
}

const CHART_HEIGHT_PX = 412;

export const ReproductionCrCrossChart: React.FC<Props> = ({
  className,
  chartData,
  xAxisMode,
  groupBy,
  yAxisMode = ReproductionYAxisMode.Percent_100,
  chartKind = ReproductionCrReportChartKind.Line,
  shouldShowOther: shouldShowOtherProp = false,
}) => {
  const shouldShowOther = shouldShowOtherProp && !!chartData.other;
  const isBar = chartKind === ReproductionCrReportChartKind.Bar;

  const xAxisName = REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[xAxisMode];
  const groupByName = REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[groupBy];

  const xAxisLabelsWithoutOthers = chartData.rowNames.map(rowName =>
    formatReproductionCrValue(rowName, true)
  );
  const xAxisLabels = [
    ...xAxisLabelsWithoutOthers,
    ...wrapConditionalArrayElement(shouldShowOther && 'Прочие'),
  ];

  const { datasets, chartOptions } = useMemo(() => {
    const datasetsInternal = chartData.columnNames.map(
      (columnName, columnIndex) => {
        let datasetLabel =
          columnIndex === chartData.columnNames.length - 1
            ? 'Итого'
            : formatReproductionCrValue(columnName, true);
        if (Array.isArray(datasetLabel)) {
          datasetLabel = datasetLabel.join('');
        }
        return {
          key: `${datasetLabel}__${columnIndex}`,
          type: isBar ? 'bar' : 'line',
          label: datasetLabel,
          ...CHART_COLOR_PROPS_ITERATOR.next(!columnIndex).value,
          isTogglable: true,
          data: [
            ...chartData.rows.map(row => row[columnIndex] ?? NaN),
            ...wrapConditionalArrayElement(
              shouldShowOther && (chartData.other?.[columnIndex] ?? NaN),
              R.complement(R.isNil)
            ),
          ],
        } satisfies BarChartDatasetConfig;
      }
    );

    return {
      datasets: datasetsInternal,
      chartOptions: getChartOptions(REPRODUCTION_CHART_OPTIONS, {
        scales: {
          x: {
            title: {
              text: xAxisName,
            },
          },
          y: getScaleOptions(PERCENT_LINEAR_Y_SCALE_OPTIONS, {
            max:
              yAxisMode === ReproductionYAxisMode.Percent_100 ? 100 : undefined,
          }),
        },
      }),
    };
  }, [chartData, yAxisMode]);

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-16', className)}>
      <BarChart
        {...{
          datasets,
          labels: xAxisLabels,
          legendTitle: groupByName,
          legendClassName: 'mb-32',
          height: CHART_HEIGHT_PX,
          renderTooltip: dataPoints => {
            const dateIndex = dataPoints.at(0)?.parsed.x;
            if (R.isNil(dateIndex)) {
              return null;
            }
            let xAxisLabel = xAxisLabels.at(dateIndex);
            if (R.isNil(xAxisLabel)) {
              return null;
            }
            if (Array.isArray(xAxisLabel)) {
              xAxisLabel = xAxisLabel.join(' ');
            }
            return (
              <DataPointsTooltip
                {...{
                  title: `${xAxisName} ${xAxisLabel}`,
                  dataPoints,
                  datasets,
                  mainValueMeasurementUnit: '%',
                }}
              />
            );
          },
          chartOptions,
        }}
      />
    </div>
  );
};
