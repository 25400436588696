import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BullDetailedFragmentDoc } from '../fragments/bullDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BullsDetailedQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type BullsDetailedQuery = { __typename?: 'Query', bulls: { __typename?: 'BullConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'BullEdge', cursor: string, node: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, comment: string, category: string, bullState: Types.BullState, farm: { __typename: 'Farm', id: string, name: string, number: string } } }> } };


export const BullsDetailedDocument = gql`
    query bullsDetailed($search: String, $first: Int, $after: String) {
  bulls(first: $first, search: $search, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...BullDetailed
      }
    }
  }
}
    ${BullDetailedFragmentDoc}`;

/**
 * __useBullsDetailedQuery__
 *
 * To run a query within a React component, call `useBullsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBullsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBullsDetailedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBullsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<BullsDetailedQuery, BullsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BullsDetailedQuery, BullsDetailedQueryVariables>(BullsDetailedDocument, options);
      }
export function useBullsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BullsDetailedQuery, BullsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BullsDetailedQuery, BullsDetailedQueryVariables>(BullsDetailedDocument, options);
        }
export function useBullsDetailedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BullsDetailedQuery, BullsDetailedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BullsDetailedQuery, BullsDetailedQueryVariables>(BullsDetailedDocument, options);
        }
export type BullsDetailedQueryHookResult = ReturnType<typeof useBullsDetailedQuery>;
export type BullsDetailedLazyQueryHookResult = ReturnType<typeof useBullsDetailedLazyQuery>;
export type BullsDetailedSuspenseQueryHookResult = ReturnType<typeof useBullsDetailedSuspenseQuery>;
export type BullsDetailedQueryResult = Apollo.QueryResult<BullsDetailedQuery, BullsDetailedQueryVariables>;