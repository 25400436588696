import React from 'react';

import { CowState } from '@graphql-types';

import { Badge, BadgeSizes } from '~/shared/components/Badge';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import { COW_STATE_ABBREVIATIONS_DICT, COW_STATES_DICT } from '../../constants';

const COW_BADGE_COLORS_DICT: Record<CowState, ColorVariants> = {
  [CowState.Calf]: ColorVariants.status04,
  [CowState.Fresh]: ColorVariants.success,
  [CowState.Open]: ColorVariants.status02,
  [CowState.Bred]: ColorVariants.warning,
  [CowState.Preg]: ColorVariants.info,
  [CowState.Dry]: ColorVariants.status06,
  [CowState.LateDry]: ColorVariants.status06,
  [CowState.Sold]: ColorVariants.status01,
  [CowState.Moved]: ColorVariants.status01,
  [CowState.Dnb]: ColorVariants.error,
  [CowState.Dead]: ColorVariants.neutral,
  [CowState.Bull]: ColorVariants.status03,
};

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Cow state to display
   */
  state: CowState;
  /**
   * If true, shows full state name, abbreviation otherwise
   */
  isFull?: boolean;
}

export const CowStateBadge: React.FC<Props> = ({
  className,
  state,
  isFull = false,
}) => {
  return (
    <Badge
      {...{
        className,
        size: isFull ? BadgeSizes.medium24 : BadgeSizes.small16,
        color: COW_BADGE_COLORS_DICT[state],
      }}
    >
      {isFull ? COW_STATES_DICT[state] : COW_STATE_ABBREVIATIONS_DICT[state]}
    </Badge>
  );
};
