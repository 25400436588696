import React, { useRef } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { useRadio } from '@react-aria/radio';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import clsx from 'clsx';

import { RadioButtonProps } from '~/shared/components/RadioGroup/types';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';

import styles from './index.module.scss';

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (props, forwardedRef) => {
    const {
      className,

      value,
      description,
      radioGroupState,

      children,
    } = props;

    const ref = useRef(null);

    const { inputProps, labelProps } = useRadio(props, radioGroupState, ref);
    const isSelected = radioGroupState.selectedValue === value;

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
      <label
        {...{
          htmlFor: inputProps.id,
          className: clsx(className, styles.root, {
            [styles.selected]: isSelected,
            [styles.focused]: isFocusVisible,
          }),
          ...labelProps,
        }}
      >
        <VisuallyHidden>
          <input
            {...{
              'aria-label': value,
              ...mergeProps(inputProps, focusProps),
              ref: mergeRefs(ref, forwardedRef),
            }}
          />
        </VisuallyHidden>
        <div className={styles.radioButton}>
          <div className={styles.dot} />
        </div>
        <Typography variant={TypographyVariants.bodySmall}>
          {children}
        </Typography>
        {!!description && (
          <Typography
            className="col-start-2 text-soft"
            variant={TypographyVariants.descriptionLarge}
          >
            {description}
          </Typography>
        )}
      </label>
    );
  }
);
