import { makeReadFragment } from '~/services/gql';

import {
  SourceFieldFragment,
  SourceFieldFragmentDoc,
} from '../gql/fragments/sourceField.graphql';

/**
 * Reads source field fragment from cache by id
 */
export const readSourceFieldFragment = makeReadFragment<SourceFieldFragment>({
  typeName: 'SourceField',
  fragment: SourceFieldFragmentDoc,
});
