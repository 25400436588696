import { BehaviorSubject } from 'rxjs';

import {
  ActionType,
  BlueprintEditingInitializationQuery,
  CraeteSectionNodeMutation,
  CreateActionMutation,
  CreateBlueprintUnionMutation,
  CreateFieldNodeMutation,
  CreateFilterConditionMutation,
  CreateFilterGroupMutation,
  CreateInputNodeMutation,
  GetFieldsDefinitionsBySectionQuery,
  NodeValueKindEnum,
  RunType,
  SourceFieldType,
  SourceSectionType,
  ViewGraphKindEnum,
  ViewKindEnum,
  VitalityFilter,
} from '~/shared/graphql';

import {
  BlueprintActionNode,
  BlueprintArgument,
  BlueprintArgumentType,
  BlueprintCycleInputNode,
  BlueprintFilterConditionNode,
  BlueprintFilterGroupNode,
  BlueprintInputNode,
  BlueprintReadOnlyArgument,
  BlueprintSourceSection,
  BlueprintSourceSectionField,
  BlueprintUnionNode,
  BlueprintWritableArgument,
  BluperintUnderEdit,
} from './types';

export class BlueprintEditResponseConverter {
  getDataFromInitializationResponse(
    response: BlueprintEditingInitializationQuery
  ): [BluperintUnderEdit, SourceSectionType[], ActionType[]] {
    const sectionTypes =
      response.blueprintSourceSectionTypes as SourceSectionType[];

    const actionTypes = response.blueprintActionTypes as ActionType[];

    const masterSections: BlueprintSourceSection[] =
      response.blueprint?.masterSourceSections.map(section => {
        return {
          fields: section.fields.map(field => {
            const convertedField: BlueprintSourceSectionField = {
              id: field.id,
              kind: field.kind,
              verboseName: field.verboseName,
              returnValueKind: field.returnValueKind,
              name: field.name,
              arguments: field.arguments.map(argument => {
                const convertedArgument: BlueprintWritableArgument = {
                  id: argument.id,
                  nodeId: field.id,
                  isRequired: argument.isRequired,
                  kind: argument.kind,
                  name: argument.name,
                  valueKind: argument.valueKind || NodeValueKindEnum.Hardcode,
                  value: argument.value,
                };

                return convertedArgument;
              }),
            };
            return convertedField;
          }),
          id: section.id,
          kind: section.kind,
          verboseName: section.verboseName,
        };
      }) || [];

    const sourceSections: BlueprintSourceSection[] =
      response.blueprint?.sourceSections.map(section => {
        return {
          fields: section.fields.map(field => {
            const convertedField: BlueprintSourceSectionField = {
              id: field.id,
              kind: field.kind,
              verboseName: field.verboseName,
              returnValueKind: field.returnValueKind,
              name: field.name,
              arguments: field.arguments.map(argument => {
                const convertedArgument: BlueprintWritableArgument = {
                  id: argument.id,
                  nodeId: field.id,
                  isRequired: argument.isRequired,
                  kind: argument.kind,
                  name: argument.name,
                  valueKind: argument.valueKind || NodeValueKindEnum.Hardcode,
                  value: argument.value,
                };

                return convertedArgument;
              }),
            };
            return convertedField;
          }),
          id: section.id,
          kind: section.kind,
          verboseName: section.verboseName,
        };
      }) || [];

    const userInputs: BlueprintInputNode[] =
      response.blueprint?.inputs.map(input => {
        return {
          id: input.id,
          name: input.name,
          prompt: input.prompt,
          cycleInputId: input.cycleInput?.id,
        };
      }) || [];

    const cycleInputs: BlueprintCycleInputNode[] =
      response.blueprint?.cycleInputs.map(cycleInput => {
        return {
          id: cycleInput.id,
          inputs: userInputs.filter(
            input => input.cycleInputId === cycleInput.id
          ),
        };
      }) || [];

    const actions: BlueprintActionNode[] =
      response.blueprint?.actions.map(action => {
        return {
          id: action.id,
          kind: action.kind,
          filterGroupId: action.parentFilterGroup?.id,
          arguments: action.arguments
            .map(item => {
              if (item.__typename === 'WritableArgument') {
                const obj: BlueprintWritableArgument = {
                  type: BlueprintArgumentType.WritableArgument,
                  id: item.id,
                  isRequired: item.isRequired,
                  kind: item.kind,
                  name: item.name,
                  valueKind: item.valueKind || NodeValueKindEnum.Hardcode,
                  nodeId: action.id,
                  value: item.value,
                };

                return obj;
              }

              if (item.__typename === 'ReadOnlyArgument') {
                const obj: BlueprintReadOnlyArgument = {
                  type: BlueprintArgumentType.ReadOnlyArgument,
                  name: item.name,
                };
                return obj;
              }

              return null;
            })
            .filter(item => item) as BlueprintArgument[],
        };
      }) || [];

    const filters: BlueprintFilterGroupNode[] =
      response.blueprint?.filterGroups.map(filterGroup => {
        return {
          id: filterGroup.id,
          unionId: filterGroup.union?.id,
          filters: filterGroup.filters.map(filter => {
            const result: BlueprintFilterConditionNode = {
              id: filter.id,
              filterGroupId: filterGroup.id,
              field: {
                id: filter.field.id,
                kind: filter.field.kind,
                verboseName: filter.field.verboseName,
                returnValueKind: filter.field.returnValueKind,
                name: filter.field.name,
                arguments: [],
              },
              compareOperator: filter.compareOperator,
              value: filter.value,
              valueKind:
                filter.value?.__typename === 'BlueprintInput' ||
                filter.value?.__typename === 'SourceField'
                  ? NodeValueKindEnum.Variable
                  : NodeValueKindEnum.Hardcode,
            };
            return result;
          }),
          actionIds: actions
            .filter(action => action.filterGroupId === filterGroup.id)
            .map(action => action.id || '')
            .filter(id => id),
          logicalOperator: filterGroup.logicOperator,
          parentFilterGroupId: filterGroup.parentFilterGroup?.id,
        };
      }) || [];

    // TODO: ↓ update after api deploy, for now empty array
    // const unions = response.blueprint.

    const unions: BlueprintUnionNode[] =
      response.blueprint?.unions.map(union => {
        const item: BlueprintUnionNode = {
          id: union.id,
          name: union.name,
        };
        return item;
      }) || [];

    const blueprint: BluperintUnderEdit = {
      id: response.blueprint?.id || '',
      name: response.blueprint?.name || '',
      isMaster: response.blueprint?.runSettings.runType === RunType.Master,

      generalSettings: {
        vitalityFilter:
          response.blueprint?.generalSettings.vitalityFilter ||
          VitalityFilter.Alive,
        orderedInputIDs:
          response.blueprint?.generalSettings.orderedInputIDs ?? [],
      },

      viewSettings: {
        kind: response.blueprint?.viewSettings.kind || ViewKindEnum.Table,
        kindSettings: {
          tableSettings: {
            fieldsIds:
              response.blueprint?.viewSettings.kindSettings?.tableSettings?.fields.map(
                field => field.id
              ) || [],
            groupByFieldsIds:
              response.blueprint?.viewSettings.kindSettings?.tableSettings?.groupByFields.map(
                field => field.id
              ) || [],
          },
          graphSettings: {
            kind:
              response.blueprint?.viewSettings.kindSettings?.graphSettings
                ?.kind || ViewGraphKindEnum.HorizontalBars,
          },
        },
      },

      actionNodes$: new BehaviorSubject<BlueprintActionNode[]>(actions),
      inputNodes$: new BehaviorSubject<BlueprintInputNode[]>(userInputs),
      cycleInputNodes$: new BehaviorSubject<BlueprintCycleInputNode[]>(
        cycleInputs
      ),
      sourceNodes$: new BehaviorSubject<BlueprintSourceSection[]>(
        sourceSections
      ),
      masterSourceNodes$: new BehaviorSubject<BlueprintSourceSection[]>(
        masterSections
      ),
      filterNodes$: new BehaviorSubject<BlueprintFilterGroupNode[]>(filters),
      unionNodes$: new BehaviorSubject<BlueprintUnionNode[]>(unions),
    };

    return [blueprint, sectionTypes, actionTypes];
  }

  sectionCreationResponseToSectionNode(
    response: CraeteSectionNodeMutation
  ): BlueprintSourceSection {
    const result: BlueprintSourceSection = {
      id: response.createSourceSection.id,
      kind: response.createSourceSection.kind,
      verboseName: response.createSourceSection.verboseName,
      fields: [], // because there are no fields yet
    };

    return result;
  }

  sourceFieldsResponseToFieldsDefinition(
    response: GetFieldsDefinitionsBySectionQuery
  ): [SourceFieldType[], string] {
    const section = response.blueprintSourceSectionTypes.find(x => x);

    const fields: SourceFieldType[] =
      section?.fields.map(field => {
        return {
          kind: field.kind,
          verboseName: field.verboseName,
          returnValueKind: field.returnValueKind,
          arguments: field.arguments,
        };
      }) || [];

    return [fields, section?.verboseName || ''];
  }

  sourceFieldCreationResponseToAppModel(
    response: CreateFieldNodeMutation
  ): BlueprintSourceSectionField {
    const field = response.createSourceField;

    const result: BlueprintSourceSectionField = {
      id: field.id,
      kind: field.kind,
      verboseName: field.verboseName,
      returnValueKind: field.returnValueKind,
      name: field.name,
      arguments: field.arguments.map(arg => {
        return {
          id: arg.id,
          nodeId: field.id,
          isRequired: arg.isRequired,
          kind: arg.kind,

          name: arg.name,
          valueKind: arg.valueKind || NodeValueKindEnum.Hardcode,
          value: arg.value,
          type: BlueprintArgumentType.WritableArgument,
        };
      }),
    };

    return result;
  }

  fromCreateInputNode(response: CreateInputNodeMutation): BlueprintInputNode {
    const result: BlueprintInputNode = {
      id: response.createBlueprintInput.id,
      name: response.createBlueprintInput.name,
      prompt: response.createBlueprintInput.prompt,
      cycleInputId: response.createBlueprintInput.cycleInput?.id,
    };
    return result;
  }

  fromCreateActionResponse(
    response: CreateActionMutation
  ): BlueprintActionNode {
    const result = response.createBlueprintAction;

    return {
      id: result.id,
      filterGroupId: result.parentFilterGroup?.id,
      kind: result.kind,
      // TODO
      arguments: result.arguments
        .map(item => {
          if (item.__typename === 'WritableArgument') {
            const obj: BlueprintWritableArgument = {
              type: BlueprintArgumentType.WritableArgument,
              id: item.id,
              isRequired: item.isRequired,
              kind: item.kind,
              name: item.name,
              valueKind: item.valueKind || NodeValueKindEnum.Hardcode,
              nodeId: result.id,
              value: item.value,
            };

            return obj;
          }

          if (item.__typename === 'ReadOnlyArgument') {
            const obj: BlueprintReadOnlyArgument = {
              type: BlueprintArgumentType.ReadOnlyArgument,
              name: item.name,
            };
            return obj;
          }

          return null;
        })
        .filter(item => item) as BlueprintArgument[],
    };
  }

  fromCreateFilterGroup(
    response: CreateFilterGroupMutation
  ): BlueprintFilterGroupNode {
    return {
      id: response.createBlueprintFilterGroup.id,
      actionIds: [],
      logicalOperator: response.createBlueprintFilterGroup.logicOperator,
      parentFilterGroupId:
        response.createBlueprintFilterGroup.parentFilterGroup?.id,
      filters: [],
    };
  }

  fromCreateFilterCondition(
    response: CreateFilterConditionMutation,
    filterGroupId: string
  ): BlueprintFilterConditionNode {
    const valueKind =
      response.createBlueprintFilter?.value?.__typename === 'BlueprintInput'
        ? NodeValueKindEnum.Variable
        : NodeValueKindEnum.Hardcode;

    const result: BlueprintFilterConditionNode = {
      id: response.createBlueprintFilter.id,
      compareOperator: response.createBlueprintFilter.compareOperator,
      filterGroupId,
      value: response.createBlueprintFilter.value,
      field: {
        id: response.createBlueprintFilter.field.id,
        kind: response.createBlueprintFilter.field.kind,
        verboseName: response.createBlueprintFilter.field.verboseName,
        name: response.createBlueprintFilter.field.name,
        returnValueKind: response.createBlueprintFilter.field.returnValueKind,
        arguments: [],
      },
      valueKind,
    };
    return result;
  }

  fromCreateUnion(
    response: CreateBlueprintUnionMutation
  ): [BlueprintUnionNode, BlueprintFilterGroupNode] {
    const union: BlueprintUnionNode = {
      id: response.createBlueprintUnion.id,
      name: response.createBlueprintUnion.name,
    };

    const filterGroup: BlueprintFilterGroupNode = {
      actionIds: [],
      filters: [],
      logicalOperator: response.createBlueprintUnion.filterGroup.logicOperator,
      id: response.createBlueprintUnion.filterGroup.id,
      unionId: response.createBlueprintUnion.id,
    };

    return [union, filterGroup];
  }
}
