import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { SourceFieldFragmentDoc } from '../../gql/fragments/sourceField.graphql';
import { useBlueprintSourceFieldsQuery } from '../../gql/queries/blueprintSourceFields.graphql';

const useBlueprintSourceFieldSelect = makeUseAsyncSelect({
  typeName: 'SourceField',
  fragment: SourceFieldFragmentDoc,
  useQuery: useBlueprintSourceFieldsQuery,
  getItemsFromQueryData: data =>
    data.blueprintSourceFields.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.blueprintSourceFields.pageInfo,
});

export const BlueprintSourceFieldAsyncSelect = makeAsyncSelectFormComponent(
  useBlueprintSourceFieldSelect
);
