import React from 'react';

import { ReproductionCrReportChartKind } from '@graphql-types';
import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import panelStyles from '~/styles/modules/panel.module.scss';

import {
  isReproductionHdrAndPrCalculatedReportData,
  isReproductionHdrAndPrComparisonReportData,
  isReproductionHdrAndPrReportDataEmpty,
} from '../../helpers';
import {
  ReproductionHdrAndPrReportDataType,
  ReproductionHdrAndPrReportSettingsFormType,
} from '../../types';
import { ReproductionHdrAndPrChart } from '../ReproductionHdrAndPrChart';
import { ReproductionHdrAndPrTable } from './components/ReproductionHdrAndPrTable';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Chart data to render
   */
  reportData: ReproductionHdrAndPrReportDataType | SkeletonPlaceholder;
  /**
   * Current settings of the report
   */
  requestedSettings: ReproductionHdrAndPrReportSettingsFormType;
}

export const ReproductionHdrAndPrReportData: React.FC<Props> = React.memo(
  ({ className, reportData, requestedSettings }) => {
    const commonProps = {
      yAxisMetrics: requestedSettings.yAxisMetrics,
      xAxisMode: requestedSettings.xAxisMode,
      femaleAnimalKind: requestedSettings.femaleAnimalKind,
    };

    return (
      <div className={clsx('flex flex-col gap-24', className)}>
        {isReproductionHdrAndPrReportDataEmpty(reportData) && (
          <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
            <DataBlockedMessage
              {...{
                className: 'p-24',
                message:
                  'Недостаточно данных для отображения, проверьте настройки',
              }}
            />
          </div>
        )}

        {(isReproductionHdrAndPrCalculatedReportData(reportData) ||
          isSkeletonPlaceholder(reportData)) && (
          <>
            <ReproductionHdrAndPrChart
              {...{
                reportRows: reportData.rows ?? getSkeletonPlaceholders(1),
                reportRowsTotal: reportData.total,
                ...commonProps,
                yAxisMode: requestedSettings.yAxisMode,
              }}
            />
            <ReproductionHdrAndPrTable
              {...{
                reportRows: reportData.rows ?? getSkeletonPlaceholders(1),
                reportRowsTotal: reportData.total,
                farmId: requestedSettings.farmID,
                ...commonProps,
              }}
            />
          </>
        )}

        {isReproductionHdrAndPrComparisonReportData(reportData) && (
          <>
            <ReproductionHdrAndPrChart
              {...{
                reportRows: reportData.actual.rows,
                actualPeriod: requestedSettings.period,
                historicReportRows: reportData.historic,
                historicPeriod: reportData.historicPeriod,
                yAxisMode: requestedSettings.yAxisMode,
                chartKind:
                  requestedSettings.yAxisCompareToPreviousPeriodChartKind ??
                  ReproductionCrReportChartKind.Bar,
                ...commonProps,
              }}
            />
            <ReproductionHdrAndPrTable
              {...{
                reportRows: reportData.actual.rows,
                reportRowsTotal: reportData.actual.total,
                historicReportRows: reportData.historic,
                farmId: requestedSettings.farmID,
                ...commonProps,
              }}
            />
          </>
        )}
      </div>
    );
  }
);
