import { gql } from './fakeGql';

const StrValueFragmentGQL = gql`
  fragment StrValueFragment on StrHardcodedValue {
    strValue: value
  }
`;

const IntValueFragmentGQL = gql`
  fragment IntValueFragment on IntHardcodedValue {
    intValue: value
  }
`;

const FloatValueFragmentGQL = gql`
  fragment FloatValueFragment on FloatHardcodedValue {
    floatValue: value
  }
`;

const DateTimeValueFragmentGQL = gql`
  fragment DateTimeValueFragment on DatetimeHardcodedValue {
    datetimeValue: value
  }
`;

const JSONHardcodedValueFragmentGQL = gql`
  fragment JSONHardcodedValueFragment on JSONHardcodedValue {
    jsonValue: value
  }
`;

const PenGroupFragmentGQL = gql`
  fragment PenGroupFragment on PenGroup {
    id
    name
    identifier

    # isMilking
    # isDry
    # isHospital
    # isCalf
    # isArtificialInsemination
    # isWithBull
    # isUser

    farm {
      id
    }
  }
`;

const BlueprintInputFragmentGQL = gql`
  fragment BlueprintInputFragment on BlueprintInput {
    id
    name
  }
`;

const SourceFieldFragmentGQL = gql`
  fragment SourceFieldFragment on SourceField {
    id
    name
  }
`;

const CowValueFragmentGQL = gql`
  fragment CowValueFragment on Cow {
    id
    identifier
    name
  }
`;

const UserEventValueFragmentGQL = gql`
  fragment UserEventValueFragment on UserEvent {
    id
    name
    kind
  }
`;

const ProtocolValueFragmentGQL = gql`
  fragment ProtocolValueFragment on Protocol {
    id
    name
    kind
    expectedDaysOnProtocol
    daysUntilCheckAfterLastThreatment
    totalTreatmentCost
    penGroup {
      id
    }
  }
`;

const InjectionlValueFragmentGQL = gql`
  fragment InjectionlValueFragment on Injection {
    id
    name
    kind
    daysMilkWithholdAfterLastThreatment
    daysMeatWithholdAfterLastThreatment
    cost
    hasProtocol
  }
`;

const DiseaseValueFragmentGQL = gql`
  fragment DiseaseValueFragment on Disease {
    id
    name
    kind
    protocols {
      id
    }
  }
`;

const EmployeeValueFragmentGQL = gql`
  fragment EmployeeValueFragment on Employee {
    id
    comment
    number
    firstName
    middleName
    lastName

    company {
      id
    }
  }
`;

const BullValueFragmentGQL = gql`
  fragment BullValueFragment on Bull {
    id
    name
    breed
  }
`;

const CalvingValueFragmentGQL = gql`
  fragment CalvingValueFragment on Calving {
    id
    happenedAt
    isAbortion
    difficultyScore
    cow {
      id
      identifier
      name
    }
    employee {
      id
      firstName
      lastName
    }
  }
`;

const FarmValueFragmentGQL = gql`
  fragment FarmValueFragment on Farm {
    id
    name
  }
`;

const InseminationValueFragmentGQL = gql`
  fragment InseminationFragment on Insemination {
    id

    happenedAt
    movedToGroupWithBullAt

    cow {
      id
      name
      identifier
    }

    bull {
      id
      name
    }
  }
`;

const InseminationSchemeValueFragmentGQL = gql`
  fragment InseminationSchemeValueFragment on InseminationScheme {
    id
    name
    code
    description
  }
`;

const SemenDoseValueFragmentGQL = gql`
  fragment SemenDoseValueFragment on SemenDose {
    id
    deliveryDate
    studCode
    dosesCount
    batchNumber

    bull {
      id
      name
      registrationNumber
    }
  }
`;

export const BlueprintValueFragmentGQL = gql`
  ${BlueprintInputFragmentGQL}

  ${BullValueFragmentGQL}
  ${CalvingValueFragmentGQL}

  ${CowValueFragmentGQL}
  ${DiseaseValueFragmentGQL}
  ${EmployeeValueFragmentGQL}
  ${FarmValueFragmentGQL}

  ${InjectionlValueFragmentGQL}
  ${InseminationValueFragmentGQL}
  ${InseminationSchemeValueFragmentGQL}

  ${PenGroupFragmentGQL}
  ${ProtocolValueFragmentGQL}
  ${SemenDoseValueFragmentGQL}
  ${SourceFieldFragmentGQL}
  ${UserEventValueFragmentGQL}

  ${StrValueFragmentGQL}
  ${IntValueFragmentGQL}
  ${FloatValueFragmentGQL}
  ${DateTimeValueFragmentGQL}
  ${JSONHardcodedValueFragmentGQL}

  fragment BlueprintValueFragment on BlueprintValue {
    ...BullValueFragment
    ...CalvingValueFragment
    ...CowValueFragment
    ...DiseaseValueFragment
    ...EmployeeValueFragment
    ...FarmValueFragment
    ...InjectionlValueFragment
    ...InseminationFragment
    ...InseminationSchemeValueFragment
    ...PenGroupFragment
    ...ProtocolValueFragment
    ...SemenDoseValueFragment
    ...UserEventValueFragment
    ...StrValueFragment
    ...IntValueFragment
    ...FloatValueFragment
    ...DateTimeValueFragment
    ...JSONHardcodedValueFragment
    ...SourceFieldFragment
    ...BlueprintInputFragment
  }
`;

export const WritableArgumentFragmentGQL = gql`
  fragment WritableArgumentFragment on WritableArgument {
    id
    name
    kind
    verboseName
    valueKind
    isRequired
    value {
      __typename
      #  should be imported in top level gql
      ...BlueprintValueFragment
    }
  }
`;

export const ReadOnlyArgumentFragmentGQL = gql`
  fragment ReadOnlyArgumentFragment on ReadOnlyArgument {
    name
  }
`;
