import React, { useCallback, useState } from 'react';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { ListItem } from '~/~legacy/types/keyValue';

import {
  BlueprintFilterConditionNode,
  BlueprintSourceSectionField,
} from '../../../../services';
import { FilterConditionEditForm } from '../../../FilterConditionEditForm';
import { FilterNodeFrame } from '../../../FilterNodeFrame';
import { FilterConditionViewForm } from '../FilterConditionViewForm';

interface Props extends ApiFeedbackProps {
  filterCondition: BlueprintFilterConditionNode;
  fields: ListItem<BlueprintSourceSectionField>[];

  delete: () => void;
  update: (filterCondition: BlueprintFilterConditionNode) => Promise<boolean>;
}

export const FilterConditionNodeUI: React.FC<Props> = ({
  filterCondition,
  fields,
  errors,
  loading,
  delete: delFunc,
  update,
}) => {
  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const [edit, setEdit] = useState(false);

  const toggle = useCallback(() => {
    setEdit(prev => !prev);
  }, [setEdit]);

  const submitCallback = useCallback(
    (condition: BlueprintFilterConditionNode) => {
      return update(condition);
    },
    []
  );

  const content = edit ? (
    <FilterConditionEditForm
      cancel={toggle}
      filterCondition={filterCondition}
      filterGroupId={filterCondition.filterGroupId}
      submit={submitCallback}
      fields={fields}
    />
  ) : (
    <FilterConditionViewForm
      toggle={toggle}
      filterCondition={filterCondition}
    />
  );

  return (
    <FilterNodeFrame delete={delFunc}>
      {content}
      {errorMessage}
      {loader}
    </FilterNodeFrame>
  );
};
