import React from 'react';

export const Key = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.63 2.97249C20.9214 3.26693 20.9189 3.74179 20.6244 4.03313L19.3571 5.28712L21.5275 7.43466C21.8122 7.71641 21.8122 8.21918 21.5275 8.50092L18.3667 11.6284C18.0745 11.9175 17.6039 11.9175 17.3117 11.6284L15.13 9.46966L12.4518 12.1196C14.1886 14.3444 13.9774 17.6069 11.9695 19.5937C9.78006 21.76 6.0583 21.7681 3.86318 19.5668L3.85752 19.5611C1.71834 17.3696 1.75049 13.7685 3.92724 11.6147C5.91131 9.65156 9.13167 9.42451 11.375 11.075L19.5694 2.96687C19.8638 2.67553 20.3387 2.67805 20.63 2.97249ZM18.2908 6.3422L16.1963 8.41457L17.8392 10.0402L19.9337 7.96779L18.2908 6.3422ZM10.9232 12.6521C10.9151 12.645 10.9071 12.6377 10.8993 12.6302C9.26509 11.0685 6.58927 11.0909 4.98227 12.681C3.38458 14.2618 3.35954 16.9004 4.92814 18.5104C6.54931 20.1331 9.29875 20.1261 10.9145 18.5274C12.5281 16.9308 12.5318 14.2531 10.9232 12.6521Z"
      fill="currentColor"
    />
  </svg>
);
