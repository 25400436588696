import { BlueprintSpecialInputField } from '~/~legacy/services/BlueprintExecutionService';
import {
  AppCow,
  AppEmployee,
  PenGroupShortInfo,
} from '~/~legacy/types/entities';

import { CalfLifeState } from '~/shared/graphql';
import { getTmpId } from '~/shared/helpers/string';

import {
  HERRIOT_CODE_ERROR_MESSAGE,
  HERRIOT_CODE_REGEXP,
} from '~/entities/cows';

import { Gender } from './types';

export type CalvingEditFunction = (data: FreshActionCalvingData) => void;
export type CalfDataEditFunction = (data: CalfData) => void;

export interface FreshActionCommonData {
  employee?: AppEmployee;
  happenedAt?: string;
}

export interface CalfData {
  state?: CalfLifeState;
  number?: string;
  gender?: Gender;
  penGroupId?: string;
  weight?: string;
  height?: string;
  herriotCode?: string;

  tempId?: string;
}

export interface FreshActionCalvingData {
  cow?: AppCow;
  penGroup?: PenGroupShortInfo;
  difficultyScore?: number;
  comment?: string;
  calfs: CalfData[];

  tempId: string;
}

interface PanelData {
  cowsCount: number;
  bullsCount: number;
  heifersCount: number;
  deadsCount: number;
}

interface FreshActionInputState {
  common: FreshActionCommonData;
  topCalving: FreshActionCalvingData;
  calvings: FreshActionCalvingData[];
  showFullForm: boolean;
  showAddCalvingButton: boolean;
  showPanelData: boolean;
  panelData: PanelData;
  errors: string[];
  submissionDataKey: string;
}

export enum FreshActionInputActionTypes {
  UpdateDataFromState = 'UpdateDataFromState',
  ActionClick = 'ActionClick',
  UpdateCommonData = 'UpdateCommonData',
  UpdateCalvingData = 'UpdateCalvingData',
  DeleteCalvingData = 'DeleteCalvingData',
  AddCalvingData = 'AddCalvingData',
}

interface FreshAction<T> {
  type: FreshActionInputActionTypes;
  data?: T;
}

type FreshActionFunction<T> = (
  state: FreshActionInputState,
  action: FreshAction<T>
) => FreshActionInputState;

function getNewCalving(): FreshActionCalvingData {
  return {
    calfs: [],
    tempId: `${Math.random()}`,
  };
}

export function getInitialState(
  specialInput?: BlueprintSpecialInputField
): FreshActionInputState {
  return {
    showFullForm: Boolean(specialInput?.data?.common),
    showAddCalvingButton: false,
    common: specialInput?.data?.common || {},
    topCalving: specialInput?.data?.topCalving || getNewCalving(),
    calvings: specialInput?.data?.calvings || [],
    showPanelData: false,
    panelData: {
      bullsCount: 0,
      cowsCount: 0,
      heifersCount: 0,
      deadsCount: 0,
    },
    errors: [],
    submissionDataKey: '',
  };
}

function isEmptyCalf(calf: CalfData) {
  const filled =
    Boolean(calf.gender) ||
    Boolean(calf.height) ||
    Boolean(calf.weight) ||
    Boolean(calf.number) ||
    Boolean(calf.state) ||
    Boolean(calf.penGroupId);

  return !filled;
}

export function isCalvingEmpty(calving: FreshActionCalvingData) {
  const filled =
    Boolean(calving.cow) ||
    Boolean(calving.difficultyScore) ||
    Boolean(calving.comment) ||
    Boolean(calving.calfs.filter(calf => !isEmptyCalf(calf)).length);

  return !filled;
}

function validateCalving(calving: FreshActionCalvingData) {
  const errors: string[] = [];

  if (!calving.cow) {
    errors.push('Корова должна быть заполнена');
  }

  if (calving.calfs.length === 0) {
    errors.push('Не добавлено ни одного телёнка');
  }
  if (!calving.penGroup) {
    errors.push('Группа коровы должна быть выбрана');
  }
  calving.calfs.forEach(item => {
    if (item.state === undefined) {
      errors.push('Статус телёнка должен быть выбран');
    }
    if (item.gender === undefined) {
      errors.push('Пол телёнка должен быть выбран');
    }
    if (!(item.herriotCode ?? '').match(HERRIOT_CODE_REGEXP)) {
      errors.push(HERRIOT_CODE_ERROR_MESSAGE);
    }

    if (item.state === CalfLifeState.Alive) {
      if (!item.penGroupId) {
        errors.push('Группа должна быть выбрана');
      }
      if (item.number === undefined || item.number === '') {
        errors.push('Номер телёнка должен быть заполнен');
      }
      if (item.weight === undefined || item.weight === '') {
        errors.push('Вес телёнка должен быть заполнен');
      }
    }
  });

  return errors;
}

const defaultReaction: FreshActionFunction<void> = state => {
  return state;
};

const updateDataFromExternalStateReaction: FreshActionFunction<any> = state => {
  return state;
};

const updateCommonDataReaction: FreshActionFunction<{
  happenedAt?: string;
  employee?: AppEmployee;
}> = (state, action) => {
  const buttonEnabled = action.data?.employee && action.data?.happenedAt;

  const newState: FreshActionInputState = {
    ...state,
    showFullForm: Boolean(buttonEnabled),
    common: {
      employee: action.data?.employee,
      happenedAt: action.data?.happenedAt,
    },
  };

  return newState;
};

const addCalvingDataReaction: FreshActionFunction<void> = state => {
  const calvingToAdd: FreshActionCalvingData = {
    ...state.topCalving,
    calfs: state.topCalving.calfs.filter(calf => !isEmptyCalf(calf)),
  };

  const errors = validateCalving(calvingToAdd);
  const hasErrors = errors.length > 0;

  const errorCase = () => {
    const newState: FreshActionInputState = {
      ...state,
      errors,
    };
    return newState;
  };

  const normalCase = () => {
    const calvings = [...state.calvings, calvingToAdd];
    const topCalving = getNewCalving();

    const newState: FreshActionInputState = {
      ...state,
      calvings,
      topCalving,
      errors: [],
    };
    return newState;
  };
  const newState = hasErrors ? errorCase() : normalCase();

  return newState;
};

const delCalvingDataReaction: FreshActionFunction<FreshActionCalvingData> = (
  state,
  action
) => {
  // case 1:  it is top calving
  const topCase = () => {
    const topCalving = getNewCalving();

    const newState: FreshActionInputState = {
      ...state,
      topCalving,
    };
    return newState;
  };

  // case 2: it is not top calving
  const notTopCase = () => {
    const newState: FreshActionInputState = {
      ...state,
      calvings: state.calvings.filter(
        item => item.tempId !== action.data?.tempId
      ),
    };
    return newState;
  };

  const isTop = action.data?.tempId === state.topCalving.tempId;

  const newState = isTop ? topCase() : notTopCase();
  return newState;
};

const updateCalvingDataReaction: FreshActionFunction<FreshActionCalvingData> = (
  state,
  action
) => {
  const calvings = state.calvings.map(calving =>
    calving.tempId === action.data?.tempId ? action.data : calving
  );

  const topCalving =
    state.topCalving.tempId === action.data?.tempId
      ? action.data
      : state.topCalving;

  const showAddCalvingButton =
    topCalving.calfs.length > 0 ||
    Boolean(calvings.find(item => item.calfs.length > 0));

  const newState: FreshActionInputState = {
    ...state,
    calvings,
    topCalving,
    showAddCalvingButton,
  };

  return newState;
};

const actionClickReaction: FreshActionFunction<void> = state => {
  // case 1  - common form filled
  const firstStep = !state.showFullForm;

  const firstStepAction = () => {
    const newState: FreshActionInputState = {
      ...state,
      showFullForm: true,
    };

    return newState;
  };

  // case 2 - data submit attempt

  const otherStepsAction = () => {
    const errors: string[] = [];

    if (!state.common.employee) {
      errors.push('Техник должен быть заполнен');
    }
    if (!state.common.happenedAt) {
      errors.push('Дата отёла должна быть заполнена');
    }

    const validateTop = !isCalvingEmpty(state.topCalving);
    const topValidations = validateTop ? validateCalving(state.topCalving) : [];

    errors.push(...topValidations);

    state.calvings.forEach(calving => {
      errors.push(...validateCalving(calving));
    });

    const submissionDataKey = errors.length
      ? state.submissionDataKey
      : getTmpId('submit');

    const newState: FreshActionInputState = {
      ...state,
      errors,
      submissionDataKey,
    };

    return newState;
  };

  const newState = firstStep ? firstStepAction() : otherStepsAction();

  return newState;
};

const actionFunctionsMap = {
  [FreshActionInputActionTypes.UpdateDataFromState]:
    updateDataFromExternalStateReaction,
  [FreshActionInputActionTypes.UpdateCommonData]: updateCommonDataReaction,
  [FreshActionInputActionTypes.UpdateCalvingData]: updateCalvingDataReaction,
  [FreshActionInputActionTypes.AddCalvingData]: addCalvingDataReaction,
  [FreshActionInputActionTypes.DeleteCalvingData]: delCalvingDataReaction,
  [FreshActionInputActionTypes.ActionClick]: actionClickReaction,
} as Record<FreshActionInputActionTypes, FreshActionFunction<any>>;

export function freshActionInputsReducer(
  state: FreshActionInputState,
  action: FreshAction<any>
): FreshActionInputState {
  const reaction = actionFunctionsMap[action.type] || defaultReaction;
  const newState = reaction(state, action);
  return newState;
}
