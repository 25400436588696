import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { FarmFragmentDoc } from '../../gql/fragments/farm.graphql';
import { useFarmsQuery } from '../../gql/queries/farms.graphql';

/**
 * Select for a farm
 */
export const useFarmSelect = makeUseAsyncSelect({
  typeName: 'Farm',
  fragment: FarmFragmentDoc,
  useQuery: useFarmsQuery,
  getItemsFromQueryData: data => data.farms.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.farms.pageInfo,
  selectProps: {
    placeholder: 'Выберите ферму',
  },
});

export const FarmAsyncSelect = makeAsyncSelectFormComponent(useFarmSelect);
