import React from 'react';
import { useTranslation } from 'react-i18next';

import { getValueToRender } from '~/~legacy/helpers/getValueToRender';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { CompareOperator } from '~/shared/graphql';

import { BlueprintFilterConditionNode } from '../../../../services';
import { NodeView } from '../NodeView';

interface Props {
  filterCondition: BlueprintFilterConditionNode;
  toggle: () => void;
}

export const FilterConditionViewForm: React.FC<Props> = ({
  filterCondition,
  toggle,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const valueView =
    filterCondition.value?.name ||
    getValueToRender(
      filterCondition.value,
      filterCondition.userInputValue,
      filterCondition.field?.returnValueKind,
      filterCondition.valueKind
    );

  const nullOperator =
    filterCondition.compareOperator === CompareOperator.NotNull ||
    filterCondition.compareOperator === CompareOperator.IsNull;

  const value = nullOperator ? null : (
    <p>
      <i>
        {t(IntegratorStrings.blueprintEdit.nodes.filterNode.valueToCompare)}
      </i>{' '}
      {valueView}
    </p>
  );

  return (
    <NodeView toggle={toggle}>
      <p>
        <i>{t(IntegratorStrings.blueprintEdit.nodes.filterNode.field)}</i>{' '}
        {filterCondition.field?.name}
      </p>
      <p>
        <i>
          {t(
            IntegratorStrings.blueprintEdit.nodes.filterNode.comparisonOperator
          )}
        </i>{' '}
        {filterCondition.compareOperator}
      </p>
      {value}
    </NodeView>
  );
};
