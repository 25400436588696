import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ProtocolInjectionFragmentDoc } from './protocolInjection.graphql';
export type ProtocolFragment = { __typename: 'Protocol', id: string, name: string, kind: Types.EventKindEnum, expectedDaysOnProtocol?: number | null, daysUntilCheckAfterLastThreatment?: number | null, daysMeatWithhold: number, daysMilkWithhold: number, totalTreatmentCost?: number | null, penGroup?: { __typename: 'PenGroup', id: string } | null, protocolInjections: Array<{ __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null }> };

export const ProtocolFragmentDoc = gql`
    fragment Protocol on Protocol {
  __typename
  id
  name
  kind
  expectedDaysOnProtocol
  daysUntilCheckAfterLastThreatment
  daysMeatWithhold
  daysMilkWithhold
  totalTreatmentCost
  penGroup {
    __typename
    id
  }
  protocolInjections {
    ...ProtocolInjection
  }
}
    ${ProtocolInjectionFragmentDoc}`;