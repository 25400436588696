import React, { ReactNode, useState } from 'react';

import { Menu, MenuItemType, MenuProps } from '~/shared/components/Menu';
import { Popover, PopoverProps } from '~/shared/components/Popover';
import { Tooltip, TooltipProps } from '~/shared/components/Tooltip';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { useControllableState } from '~/shared/hooks/useControllableState';

/**
 * Props, passed to ContextMenu children for rendering a some menu trigger, like a button
 */
interface ContextMenuRenderProps {
  /**
   * Is context menu open
   */
  isOpen: boolean;
  /**
   * Action to set context menu open state
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Is context menu tooltip open
   */
  isTooltipOpen: boolean;
  /**
   * Action to set context menu tooltip open state
   */
  setIsTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ContextMenuProps<
  ItemType extends MenuItemType = MenuItemType,
> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Items to render in the menu
   */
  items: ItemType[];
  /**
   * If true, renders disabled button without context menu
   */
  isDisabled?: boolean;
  /**
   * Tooltip for icon
   */
  tooltip?: ReactNode;
  /**
   * Additional props for the tooltip
   */
  tooltipProps?: Partial<TooltipProps>;
  /**
   * Props for menu popover
   */
  popoverProps?: Partial<Omit<PopoverProps, 'withListNavigation'>>;
  /**
   * Additional props for menu, e.g.
   */
  menuProps?: Partial<MenuProps<ItemType>>;
  /**
   * Children to render context menu trigger
   */
  children: (renderProps: ContextMenuRenderProps) => ReactNode;
}

export const ContextMenu = <ItemType extends MenuItemType = MenuItemType>({
  className,
  isDisabled = false,
  items,
  tooltip,
  tooltipProps,
  popoverProps,
  menuProps,
  children,
}: ContextMenuProps<ItemType>) => {
  const hasItems = !!items.length;

  const [isOpen, setIsOpen] = useControllableState(
    popoverProps?.isOpen,
    popoverProps?.onIsOpenChange,
    popoverProps?.defaultIsOpen ?? false
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <Popover
      {...{
        className,
        placement: 'bottom-end',
        isDisabled: !hasItems || isDisabled,
        shouldCloseOnContentClick: true,
        withListNavigation: true,
        listNavigationProps: {
          activeIndex,
          onNavigate: setActiveIndex,
        },
        floatingFocusManagerProps: {
          order: ['floating', 'reference'],
          returnFocus: true,
        },
        renderContent: ({ listRef, getItemProps }) => (
          <Menu
            {...mergeProps(
              {
                items,
                onItemPress: () => {
                  setIsOpen(false);
                },
                setIsOpen,
                activeIndex,
                getItemProps: (index, userProps) => ({
                  ref: node => {
                    listRef.current[index] = node;
                  },
                  ...getItemProps(userProps),
                }),
              } satisfies MenuProps,
              menuProps
            )}
          />
        ),
        isOpen: hasItems && isOpen,
        ...popoverProps,
        onIsOpenChange: newIsOpen => {
          setIsTooltipOpen(false);
          setIsOpen(newIsOpen);
        },
      }}
    >
      <Tooltip
        {...{
          isDisabled: isDisabled || !tooltip || isOpen,
          isOpen: isTooltipOpen,
          onIsOpenChange: setIsTooltipOpen,
          content: tooltip,
          ...tooltipProps,
        }}
      >
        {children({
          isOpen: hasItems && !isDisabled && isOpen,
          setIsOpen,
          isTooltipOpen,
          setIsTooltipOpen,
        })}
      </Tooltip>
    </Popover>
  );
};
