import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { DiseaseFragment } from '../gql/fragments/disease.graphql';
import { useCreateDiseaseMutation } from '../gql/mutations/createDisease.graphql';
import { useUpdateDiseaseMutation } from '../gql/mutations/updateDisease.graphql';

/**
 * Props for editing an event with Disease kind
 */
interface Props {
  /**
   * If passed, this is the event to edit, creating new otherwise
   */
  disease?: DiseaseFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (newDisease?: DiseaseFragment) => void;
}

const FORM_ID = 'EditDiseaseForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  protocolIDs: yup.array(yup.string().required()), // [ID!]
});

/**
 * Form for editing an event with Disease kind
 */
export type EditDiseaseFormType = InferValidatedSchema<typeof SCHEMA>;

export const useDiseaseForm = ({
  disease,
  onSubmit,
}: Props): UseModalStepFormInterface<EditDiseaseFormType> => {
  const isEditing = !!disease;

  const [isLoading, setLoading] = useState(false);

  const [createDisease] = useCreateDiseaseMutation();
  const [updateDisease] = useUpdateDiseaseMutation();

  const formContext = useForm<EditDiseaseFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(['name'], disease ?? ({} as DiseaseFragment)),
      protocolIDs: disease?.protocols.map(R.prop('id')),
    },
  });

  const handleSubmit = async (input: EditDiseaseFormType) => {
    setLoading(true);
    try {
      let newDisease: DiseaseFragment | undefined;
      if (isEditing) {
        await updateDisease({
          variables: { id: disease.id, input },
          refetchQueries: ['events'],
        });
      } else {
        const res = await createDisease({
          variables: { input },
          refetchQueries: ['events'],
        });

        newDisease = res.data?.createDisease;
      }
      onSubmit?.(newDisease);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
