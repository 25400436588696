import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { MInlineEditor } from '~/shared/components/MInlineEditor';

import TOKENS from '~/styles/__generated__/tokens.json';

import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';

interface Props extends ApiFeedbackProps {
  children: ReactNode;

  name: string;
  update: (name: string) => void;
  del: () => void;
}

export const UnionNodeUI: React.FC<Props> = ({
  children,
  name,
  update,
  del,
  errors,
  loading,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  return (
    <NodeFrame
      header={
        <NodeHeader
          title={
            <span className="flex full-width">
              {t(IntegratorStrings.blueprintEdit.nodes.unionNode.title)}
              <MInlineEditor
                name="title"
                placeholder={t(
                  IntegratorStrings.blueprintEdit.nodes.unionNode
                    .titlePlaceholder
                )}
                value={name}
                onUpdate={update}
              />
            </span>
          }
          delete={del}
        />
      }
      headerStyle={{
        background: TOKENS.colorWarning400,
      }}
      contentClass="p-8"
    >
      {children}
      {loader}
      {errorMessage}
    </NodeFrame>
  );
};
