import React from 'react';

export const Code = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14 3.25C13.5858 3.25 13.25 3.58579 13.25 4C13.25 4.41421 13.5858 4.75 14 4.75H15.25V9.25H14C13.5858 9.25 13.25 9.58579 13.25 10C13.25 10.4142 13.5858 10.75 14 10.75H18C18.4142 10.75 18.75 10.4142 18.75 10C18.75 9.58579 18.4142 9.25 18 9.25H16.75V4C16.75 3.58579 16.4142 3.25 16 3.25H14Z"
      fill="currentColor"
    />
    <path
      d="M6 19.25C5.58579 19.25 5.25 19.5858 5.25 20C5.25 20.4142 5.58579 20.75 6 20.75H10C10.4142 20.75 10.75 20.4142 10.75 20C10.75 19.5858 10.4142 19.25 10 19.25H8.75V14C8.75 13.5858 8.41421 13.25 8 13.25H6C5.58579 13.25 5.25 13.5858 5.25 14C5.25 14.4142 5.58579 14.75 6 14.75H7.25V19.25H6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 4C5.25 3.58579 5.58579 3.25 6 3.25H10C10.4142 3.25 10.75 3.58579 10.75 4V10C10.75 10.4142 10.4142 10.75 10 10.75H6C5.58579 10.75 5.25 10.4142 5.25 10V4ZM6.75 4.75V9.25H9.25V4.75H6.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 13.25C13.5858 13.25 13.25 13.5858 13.25 14V20C13.25 20.4142 13.5858 20.75 14 20.75H18C18.4142 20.75 18.75 20.4142 18.75 20V14C18.75 13.5858 18.4142 13.25 18 13.25H14ZM14.75 19.25V14.75H17.25V19.25H14.75Z"
      fill="currentColor"
    />
  </svg>
);
