import React, { useCallback } from 'react';

import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { MInput, MInputProps } from '~/shared/components/MInput';

export const StringEdit: React.FC<ValueEditorStandardProps<string>> = props => {
  const {
    value,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    className,
    noForm,
    noBorder,
    onPaste,
    ...other
  } = props;

  const valueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  return (
    <MInput
      {...(other as MInputProps)}
      className={noForm ? className : ''}
      placeholder="Введите значение"
      onChange={valueChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || ''}
      onPaste={onPaste}
      noBorder={noBorder}
      autocomplete="off"
    />
  );
};
