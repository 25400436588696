import { makeReadFragment, makeUpdateFragment } from '~/services/gql';

import { FarmFragment, FarmFragmentDoc } from './gql/fragments/farm.graphql';

/**
 * Reads farm fragment from cache by id
 */
export const readFarmFragment = makeReadFragment<FarmFragment>({
  typeName: 'Farm',
  fragment: FarmFragmentDoc,
});

/**
 * Update Farm in the cache
 */
export const updateFarmFragment = makeUpdateFragment<FarmFragment>({
  typeName: 'Farm',
  fragment: FarmFragmentDoc,
});
