import {
  AppBlueprint,
  AppBlueprintsModel,
  AppBlueprintTemplate,
  TemplateResponse,
} from '~/~legacy/services/BlueprintsService';

import {
  CreateBlueprintMutation,
  GetBlueprintByIdQuery,
  GetBlueprintsQuery,
  GetTemplatesQuery,
} from '~/shared/graphql';

export class BlueprintsResponseConverter {
  convertFromBlueprintsResponse(
    response: GetBlueprintsQuery
  ): AppBlueprintsModel {
    const blueprints = response.blueprints.nodes;

    const result: AppBlueprintsModel = {
      blueprints: blueprints.map(item => {
        return {
          id: item.id,
          name: item.name,
        } as AppBlueprint;
      }),
    };

    return result;
  }

  convertFromCreateBlueprintResponse(
    response: CreateBlueprintMutation
  ): AppBlueprint {
    const blueprint = response.createBlueprint;

    const result: AppBlueprint = {
      id: blueprint.id,
      name: blueprint.name,
      description: blueprint.description,

      isTemplate: blueprint.isTemplate,
      runSettings: blueprint.runSettings,
      roles: blueprint.roles.map(item => {
        return {
          color: item.color,
          id: item.id,
          name: item.name,
        };
      }),
      isForIntegratorOnly: blueprint.isForIntegratorOnly,
      priority: blueprint.priority,
    };

    return result;
  }

  convertFromGetBlueprintByIdResponse(
    response: GetBlueprintByIdQuery,
    id: string
  ): AppBlueprint {
    const { blueprint } = response;

    if (!blueprint) throw new Error(`Blueprint with id [${id}] not found!`);

    const result: AppBlueprint = {
      id: blueprint.id,
      name: blueprint.name,
      description: blueprint.description,
      roles: blueprint.roles.map(x => {
        return {
          color: x.color,
          id: x.id,
          name: x.name,
        };
      }),

      isTemplate: blueprint.isTemplate,
      runSettings: blueprint.runSettings,

      isForIntegratorOnly: blueprint.isForIntegratorOnly,
      priority: blueprint.priority,
    };

    return result;
  }

  convertFromTemplatesResponse(response: GetTemplatesQuery): TemplateResponse {
    const templates: AppBlueprintTemplate[] = response.templates.edges.map(
      item => {
        return {
          id: item.node.id,
          name: item.node.name,
        };
      }
    );

    const result: TemplateResponse = {
      pageInfo: response.templates.pageInfo,
      templates,
    };
    return result;
  }
}
