import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomMilkingReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateCustomMilkingReportInput;
}>;


export type UpdateCustomMilkingReportMutation = { __typename?: 'Mutation', updateCustomMilkingReport?: any | null };


export const UpdateCustomMilkingReportDocument = gql`
    mutation updateCustomMilkingReport($id: ID!, $input: UpdateCustomMilkingReportInput!) {
  updateCustomMilkingReport(id: $id, input: $input)
}
    `;
export type UpdateCustomMilkingReportMutationFn = Apollo.MutationFunction<UpdateCustomMilkingReportMutation, UpdateCustomMilkingReportMutationVariables>;

/**
 * __useUpdateCustomMilkingReportMutation__
 *
 * To run a mutation, you first call `useUpdateCustomMilkingReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomMilkingReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomMilkingReportMutation, { data, loading, error }] = useUpdateCustomMilkingReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomMilkingReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomMilkingReportMutation, UpdateCustomMilkingReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomMilkingReportMutation, UpdateCustomMilkingReportMutationVariables>(UpdateCustomMilkingReportDocument, options);
      }
export type UpdateCustomMilkingReportMutationHookResult = ReturnType<typeof useUpdateCustomMilkingReportMutation>;
export type UpdateCustomMilkingReportMutationResult = Apollo.MutationResult<UpdateCustomMilkingReportMutation>;
export type UpdateCustomMilkingReportMutationOptions = Apollo.BaseMutationOptions<UpdateCustomMilkingReportMutation, UpdateCustomMilkingReportMutationVariables>;