import R from 'ramda';

import { DEFAULT_PRECISION } from '~/shared/constants';
import { formatNumber } from '~/shared/helpers/number';
import { renderOrMdash } from '~/shared/helpers/render';

/**
 * Formatter for cow metrics with placeholder
 */
export const formatCowMetrics = (
  value: number | null | undefined,
  withRequiredFractionPart = true
) =>
  renderOrMdash(
    !R.isNil(value) &&
      formatNumber(
        value,
        withRequiredFractionPart
          ? DEFAULT_PRECISION
          : {
              minimumFractionDigits: 0,
              maximumFractionDigits: DEFAULT_PRECISION,
            }
      )
  );
