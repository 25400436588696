import React from 'react';

import clsx from 'clsx';

import { Favorite } from '~/shared/components/Favorite';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { renderOrMdash } from '~/shared/helpers/render';
import { useDebugProps } from '~/shared/hooks/useDebugProps';

import { formatBlueprintValue } from '~/entities/blueprintValues';
import { CowFieldFragment } from '~/entities/cows/gql/fragments/cowField.graphql';

import panelStyles from '~/styles/modules/panel.module.scss';

import { getCowFieldId } from '../../helpers';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field to display
   */
  field: CowFieldFragment;
  /**
   * If passed, renders favorite component and calls this callback on favorite changed
   */
  onFieldPin?: (isPinned: boolean) => void;
}

export const CowField: React.FC<Props> = ({ className, field, onFieldPin }) => {
  const debugProps = useDebugProps(
    () => ({
      kind: field.kind,
      valueKind: field.valueKind,
      sectionKind: field.section.kind,
    }),
    [field]
  );

  return (
    <div className={clsx(styles.root, panelStyles.panel, className)}>
      <Typography
        className="flex gap-8 text-soft mb-2"
        tag="div"
        variant={TypographyVariants.bodySmall}
        {...debugProps}
      >
        <span className="ellipsis">{field.verboseName}</span>
        {onFieldPin && (
          <Favorite
            {...{
              className: styles.favorite,
              name: `${getCowFieldId(field)}__favorite`,
              value: field.isPinned,
              onValueChange: onFieldPin,
              tooltip: field.isPinned
                ? 'Убрать из избранного'
                : 'Добавить в избранное',
            }}
          />
        )}
      </Typography>
      <Typography tag="div" variant={TypographyVariants.bodyMedium}>
        {renderOrMdash(
          field.value &&
            formatBlueprintValue(field.value, field.kind, field.valueKind)
        )}
      </Typography>
    </div>
  );
};
