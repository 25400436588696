import React from 'react';

export const Dot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.625C8.47918 5.625 5.625 8.47918 5.625 12C5.625 15.5208 8.47918 18.375 12 18.375C15.5208 18.375 18.375 15.5208 18.375 12C18.375 8.47918 15.5208 5.625 12 5.625Z"
      fill="currentColor"
    />
  </svg>
);
