import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { CompanyFragment } from '../gql/fragments/company.graphql';
import {
  CompaniesQuery,
  CompaniesQueryVariables,
  useCompaniesQuery,
} from '../gql/queries/companies.graphql';

export const useCompaniesPaginatedQuery = makeUsePaginatedQuery<
  CompanyFragment,
  CompaniesQuery,
  CompaniesQueryVariables
>({
  useQuery: useCompaniesQuery,
  getItemsFromQueryData: data => data.companies.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.companies.pageInfo,
});
