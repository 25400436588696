import React from 'react';

export const Clock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V11.6893L16.0303 14.9697C16.3232 15.2626 16.3232 15.7374 16.0303 16.0303C15.7374 16.3232 15.2626 16.3232 14.9697 16.0303L11.4697 12.5303C11.3978 12.4584 11.3435 12.3755 11.3069 12.2871C11.2702 12.1987 11.25 12.1017 11.25 12V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25C6.58579 2.25 2.25 6.58579 2.25 12C2.25 17.4142 6.58579 21.75 12 21.75C17.4142 21.75 21.75 17.4142 21.75 12C21.75 6.58579 17.4142 2.25 12 2.25ZM3.75 12C3.75 7.41421 7.41421 3.75 12 3.75C16.5858 3.75 20.25 7.41421 20.25 12C20.25 16.5858 16.5858 20.25 12 20.25C7.41421 20.25 3.75 16.5858 3.75 12Z"
      fill="currentColor"
    />
  </svg>
);
