import React from 'react';

import { ReactComponent as ArrowDownSVG } from '~/~legacy/icons/arrow-down.svg';
import { ReactComponent as ArrowUpSVG } from '~/~legacy/icons/arrow-up.svg';
import { ReactComponent as TrashSVG } from '~/~legacy/icons/trash.svg';

import styles from './index.module.scss';

interface Props {
  expanded: boolean;
  toggle: () => void;
  del: () => void;
}

export const FreshActionCalvingFormControls: React.FC<Props> = ({
  expanded,
  toggle,
  del,
}) => {
  const arrow = expanded ? (
    <ArrowUpSVG onClick={toggle} />
  ) : (
    <ArrowDownSVG onClick={toggle} />
  );

  return (
    <div className={styles.root}>
      <TrashSVG onClick={del} />
      {arrow}
    </div>
  );
};
