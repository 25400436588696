import React from 'react';

import { MEditToggle } from '~/shared/components/MEditToggle';

interface Props {
  hideControls: boolean | undefined;
  toggle: () => void;
  value: any;
  caption: string | undefined;
}

export const StandardView: React.FC<Props> = React.memo(props => {
  const { caption, hideControls, toggle, value } = props;
  const lable = typeof caption !== 'string' ? 'Значение: ' : caption;

  const valueToRender = value === undefined ? '[не установлено]' : value;
  const controls = !hideControls ? <MEditToggle toggle={toggle} /> : null;

  return (
    <div className="m-editor">
      <div>
        <i>{lable}</i>
        {valueToRender}
      </div>
      {controls}
    </div>
  );
});
