import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
export type MilkingParlorShortFragment = { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, farm: { __typename: 'Farm', id: string, name: string, number: string } };

export const MilkingParlorShortFragmentDoc = gql`
    fragment MilkingParlorShort on MilkingParlor {
  __typename
  id
  name
  farm {
    ...Farm
  }
  manufacturer
  kind
  capacity
  capacityStart
  capacityEnd
  sortingGatesCount
  connectionInfo
}
    ${FarmFragmentDoc}`;