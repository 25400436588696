import { SomaticCellsReportDataType } from '../../types';

export const DATASET_ITEM_FIELD_ITEMS: {
  itemField: keyof SomaticCellsReportDataType;
  title: string | null;
}[] = [
  {
    itemField: 'researchedAnimalsCounts',
    title: 'Исследовано животных',
  },
  {
    itemField: 'chronicallyIllPercents',
    title: 'Болеющие хронически, %',
  },
  {
    itemField: 'chronicallyIllCounts',
    title: null,
  },
  {
    itemField: 'illPercents',
    title: 'Заболевшие, %',
  },
  {
    itemField: 'illCounts',
    title: null,
  },
  {
    itemField: 'recoveredPercents',
    title: 'Выздоровевшие, %',
  },
  {
    itemField: 'recoveredCounts',
    title: null,
  },
  {
    itemField: 'healthyPercents',
    title: 'Здоровые, %',
  },
  {
    itemField: 'healthyCounts',
    title: null,
  },
  {
    itemField: 'highValueFirstTestMilkingPercents',
    title: 'Высокое значение на первой КД, %',
  },
  {
    itemField: 'highValueFirstTestMilkingCounts',
    title: null,
  },
  {
    itemField: 'normalValueFirstTestMilkingPercents',
    title: 'Нормальное значение на первой КД, %',
  },
  {
    itemField: 'normalValueFirstTestMilkingPercents',
    title: null,
  },
  {
    itemField: 'probabilityOfRecoveryPercents',
    title: 'Коэфф. выздоровления, %',
  },
  {
    itemField: 'probabilityOfIllnessPercents',
    title: 'Коэфф. заболеваемости, %',
  },
];
