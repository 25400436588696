import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MilkingParlorGeneralReportGroupedRowEntryFragment = { __typename: 'MilkingParlorGeneralReportGroupedRowEntry', notIdentifiedCount: number, cows: { __typename?: 'MlkParlorGeneralReportCows', total: number, perHour: number }, milk: { __typename?: 'MlkParlorGeneralReportMilk', total: number, perHour: number, perCows: number, avgFlow: number }, milkingTime: { __typename?: 'MlkParlorGeneralReportMilkingTime', durationSec: number, avgSec: number, start: string, stop: string } };

export const MilkingParlorGeneralReportGroupedRowEntryFragmentDoc = gql`
    fragment MilkingParlorGeneralReportGroupedRowEntry on MilkingParlorGeneralReportGroupedRowEntry {
  __typename
  cows {
    total
    perHour
  }
  milk {
    total
    perHour
    perCows
    avgFlow
  }
  milkingTime {
    durationSec
    avgSec
    start
    stop
  }
  notIdentifiedCount
}
    `;