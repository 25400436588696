import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BullForDeletionFragment = { __typename: 'Bull', id: string, shouldBeMigratedOnDelete: boolean };

export const BullForDeletionFragmentDoc = gql`
    fragment BullForDeletion on Bull {
  __typename
  id
  shouldBeMigratedOnDelete
}
    `;