import styles from './index.module.scss';
import { TableColumnConfig } from './types';

/**
 * Filler column for using in fixed table with 100% width
 */
export const FILLER_COLUMN_CONFIG = {
  title: '',
  key: 'filler',
  renderCellContent: () => '',
  columnClassName: styles.fillerCell,
  headerTypographyProps: {
    withSkeleton: false,
  },
  cellTypographyProps: {
    withSkeleton: false,
  },
  width: '1000fr',
  isSplittedHeader: true,
} satisfies TableColumnConfig<any>;
