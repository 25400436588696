import React from 'react';

export const Chart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M3.33464 7.99998C3.15782 7.99998 2.98826 8.07022 2.86323 8.19524C2.73821 8.32027 2.66797 8.48984 2.66797 8.66665V14C2.66797 14.1768 2.73821 14.3464 2.86323 14.4714C2.98826 14.5964 3.15782 14.6666 3.33464 14.6666C3.51145 14.6666 3.68102 14.5964 3.80604 14.4714C3.93106 14.3464 4.0013 14.1768 4.0013 14V8.66665C4.0013 8.48984 3.93106 8.32027 3.80604 8.19524C3.68102 8.07022 3.51145 7.99998 3.33464 7.99998ZM6.66797 1.33331C6.49116 1.33331 6.32159 1.40355 6.19656 1.52858C6.07154 1.6536 6.0013 1.82317 6.0013 1.99998V14C6.0013 14.1768 6.07154 14.3464 6.19656 14.4714C6.32159 14.5964 6.49116 14.6666 6.66797 14.6666C6.84478 14.6666 7.01435 14.5964 7.13937 14.4714C7.2644 14.3464 7.33464 14.1768 7.33464 14V1.99998C7.33464 1.82317 7.2644 1.6536 7.13937 1.52858C7.01435 1.40355 6.84478 1.33331 6.66797 1.33331ZM13.3346 10.6666C13.1578 10.6666 12.9883 10.7369 12.8632 10.8619C12.7382 10.9869 12.668 11.1565 12.668 11.3333V14C12.668 14.1768 12.7382 14.3464 12.8632 14.4714C12.9883 14.5964 13.1578 14.6666 13.3346 14.6666C13.5114 14.6666 13.681 14.5964 13.806 14.4714C13.9311 14.3464 14.0013 14.1768 14.0013 14V11.3333C14.0013 11.1565 13.9311 10.9869 13.806 10.8619C13.681 10.7369 13.5114 10.6666 13.3346 10.6666ZM10.0013 5.33331C9.82449 5.33331 9.65492 5.40355 9.5299 5.52858C9.40487 5.6536 9.33463 5.82317 9.33463 5.99998V14C9.33463 14.1768 9.40487 14.3464 9.5299 14.4714C9.65492 14.5964 9.82449 14.6666 10.0013 14.6666C10.1781 14.6666 10.3477 14.5964 10.4727 14.4714C10.5977 14.3464 10.668 14.1768 10.668 14V5.99998C10.668 5.82317 10.5977 5.6536 10.4727 5.52858C10.3477 5.40355 10.1781 5.33331 10.0013 5.33331Z"
      fill="currentColor"
    />
  </svg>
);
