import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type SomaticCellsReportSettingsHistoricValue_SomaticCellsReportSettingsHistoricValueLactationNumbers_Fragment = { __typename: 'SomaticCellsReportSettingsHistoricValueLactationNumbers', lactationNumbers?: Array<number> | null };

export type SomaticCellsReportSettingsHistoricValue_SomaticCellsReportSettingsHistoricValueLactationGroupNumbers_Fragment = { __typename: 'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers', lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null };

export type SomaticCellsReportSettingsHistoricValue_SomaticCellsReportSettingsHistoricValuePenGroups_Fragment = { __typename: 'SomaticCellsReportSettingsHistoricValuePenGroups', penGroups: Array<{ __typename: 'PenGroup', id: string, name: string, identifier: number }> };

export type SomaticCellsReportSettingsHistoricValueFragment = SomaticCellsReportSettingsHistoricValue_SomaticCellsReportSettingsHistoricValueLactationNumbers_Fragment | SomaticCellsReportSettingsHistoricValue_SomaticCellsReportSettingsHistoricValueLactationGroupNumbers_Fragment | SomaticCellsReportSettingsHistoricValue_SomaticCellsReportSettingsHistoricValuePenGroups_Fragment;

export const SomaticCellsReportSettingsHistoricValueFragmentDoc = gql`
    fragment SomaticCellsReportSettingsHistoricValue on SomaticCellsReportSettingsHistoricValue {
  __typename
  ... on SomaticCellsReportSettingsHistoricValueLactationNumbers {
    lactationNumbers
  }
  ... on SomaticCellsReportSettingsHistoricValueLactationGroupNumbers {
    lactationGroupNumbers
  }
  ... on SomaticCellsReportSettingsHistoricValuePenGroups {
    penGroups {
      ...PenGroupShort
    }
  }
}
    ${PenGroupShortFragmentDoc}`;