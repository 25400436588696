import React, { PropsWithoutRef } from 'react';
import {
  FieldError,
  useController,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';

import R from 'ramda';

import { FeedbackVariants } from '~/shared/components/FieldFeedback';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';
import { BaseFieldProps } from '~/shared/types/controls';

/**
 * Getter for default component value to use in form
 */
type GetDefaultValue<Props extends BaseFieldProps<Value>, Value> = (
  props: PropsWithoutRef<Props>
) => Value;

/**
 * Default value can be passed as a raw value or as a getter,
 * this function helps to get raw value from the passed one
 */
const getDefaultValue = <Props extends BaseFieldProps<Value>, Value>(
  defaultValue: Value | GetDefaultValue<Props, Value>,
  props: PropsWithoutRef<Props>
): Value => {
  if (typeof defaultValue === 'function') {
    return (defaultValue as GetDefaultValue<Props, Value>)(props);
  }

  return defaultValue;
};

/**
 * Function to transform change value for saving in form as expected value type
 */
type ConvertValueFromComponentToForm<
  Props extends BaseFieldProps<Value>,
  Value,
  FormValue = Value,
> = (value: Value, props: PropsWithoutRef<Props>) => FormValue;

/**
 * Function to transform value saved in a form to component value
 */
type ConvertValueFromFormToComponent<
  Props extends BaseFieldProps<Value>,
  Value,
  FormValue = Value,
> = (value: FormValue, props: PropsWithoutRef<Props>) => Value;

interface WithOptionalFormControllerProps<
  Props extends BaseFieldProps<Value>,
  Value,
  FormValue = Value,
> {
  /**
   * Default value for the field
   */
  defaultValue: Value | GetDefaultValue<Props, Value>;
  /**
   * Called to convert inner component value to serialized form representation
   */
  convertValueFromComponentToForm?: ConvertValueFromComponentToForm<
    Props,
    Value,
    FormValue
  >;
  /**
   * Called to convert serialized form value to inner component representation
   */
  convertValueFromFormToComponent?: ConvertValueFromFormToComponent<
    Props,
    Value,
    FormValue
  >;
  /**
   * Getter for some additional component props, based on default props
   * Probably, won't be used, but default R.identity is used
   * to avoid typing problems with generic props can be assigned to different a subtype of BaseFieldProps
   */
  getPropsFromBaseFieldProps?: (props: BaseFieldProps<Value>) => Props;
}

/**
 * Hoc to create an input field with general interface that can be used in forms and without them
 */
export const withOptionalFormController =
  <Props extends BaseFieldProps<Value>, Value, FormValue = Value>({
    defaultValue,
    convertValueFromComponentToForm = R.identity as any,
    convertValueFromFormToComponent = R.identity as any,
    getPropsFromBaseFieldProps = R.identity as any,
  }: WithOptionalFormControllerProps<Props, Value, FormValue>) =>
  (Component: React.ComponentType<Props>) => {
    // Create component with react-hook-form controller
    const ComponentWithController = React.forwardRef<any, Props>(
      (props, ref) => {
        const formContext = useFormContext();

        const {
          defaultValue: propsDefaultValue,
          name,
          onValueChange,
          onBlur,
          shouldChangeValueWithDebounce = false,
          ...otherFieldProps
        } = props;

        const {
          field: {
            value,
            ref: controllerRef,
            onChange: controllerHandleChange,
            onBlur: controllerHandleBlur,
            ...fieldControllerProps
          },
          fieldState,
        } = useController({
          name,
          defaultValue:
            propsDefaultValue ?? getDefaultValue(defaultValue, props),
        });

        const hasError = !!fieldState.error;

        let errorMessage = fieldState.error?.message;
        if (fieldState.error && !errorMessage) {
          const firstObjectError = Object.values(fieldState.error).find(
            (v: any) => v.message
          ) as FieldError | undefined;
          errorMessage = firstObjectError?.message;
        }

        return (
          <Component
            {...{
              ...getPropsFromBaseFieldProps(
                mergeProps(
                  {
                    hasError,
                    feedbackProps: {
                      variant: hasError ? FeedbackVariants.error : undefined,
                    },
                    labelProps: {
                      hasError,
                    },
                  },
                  otherFieldProps,
                  fieldControllerProps,
                  {
                    // Error is more important, than info feedback
                    feedback:
                      otherFieldProps.feedbackProps?.variant ===
                      FeedbackVariants.error
                        ? otherFieldProps.feedback
                        : (errorMessage ?? otherFieldProps.feedback),
                  }
                )
              ),
              value: convertValueFromFormToComponent(value, props),
              name,
              ref: mergeRefs(ref, controllerRef),
              onValueChange: newValue => {
                onValueChange?.(newValue);
                if (shouldChangeValueWithDebounce) {
                  // Clear errors on input and revalidate after debounce change
                  formContext.clearErrors();
                }

                if (!newValue || !shouldChangeValueWithDebounce) {
                  controllerHandleChange(
                    convertValueFromComponentToForm(newValue, props)
                  );
                }
              },
              ...wrapConditionalObjectElement(
                shouldChangeValueWithDebounce && {
                  onValueChangeDebounced: newValue => {
                    controllerHandleChange(
                      convertValueFromComponentToForm(newValue, props)
                    );
                  },
                }
              ),

              onBlur: e => {
                onBlur?.(e);
                controllerHandleBlur();
              },
            }}
          />
        );
      }
    );

    // Create result component
    const WrappedComponent = React.forwardRef(
      (props: PropsWithoutRef<Props>, ref: any) => {
        const { withFormContext = true } = props;

        // react-hook-form expects that context is always there,
        // so we can't render useController right away
        // but we use this workaround to allow the use of inputs without forms
        const context: UseFormReturn<any> | null = useFormContext();

        if (withFormContext && context) {
          return (
            <ComponentWithController
              ref={ref}
              {...(props as React.PropsWithoutRef<Props>)}
            />
          );
        }

        return <Component ref={ref} {...(props as Props)} />;
      }
    );

    WrappedComponent.displayName = `withOptionalFormController(${
      Component.displayName || Component.name || ''
    })`;

    return WrappedComponent;
  };
