import { ActionKindEnum, RunType } from '~/shared/graphql';

import {
  BlueprintCycleInputNode,
  BlueprintInputNode,
  ValueEditorKind,
} from '~/widgets/blueprintEdit';

export interface BlueprintInputField extends BlueprintInputNode {
  valueKind: ValueEditorKind;
  userInput: any;
}

export interface BlueprintCycleInputField extends BlueprintCycleInputNode {
  inputs: BlueprintInputField[];
  userInput: any[][];
}

export interface BlueprintSpecialInputField {
  name: string;
  prompt: string;

  actionId: string;
  actionType: ActionKindEnum;

  data: any;
  convertedForApi: any;
}

export interface BlueprintExecutionInfo {
  id: string;
  name: string;
  description: string;
  runType: RunType;
  inputs: BlueprintInputField[];
  cycleInputs: BlueprintCycleInputField[];
  specialInput?: BlueprintSpecialInputField;
  runOn?: string;
  isForIntegratorOnly?: boolean;
  priority?: number;
}

export interface GroupedRow {
  __typename: 'BlueprintLaunchGroupedRow';
  groupKey: string;
  groupValue: any;
  rowsCount: number;
  rows: ExecutionResultRow[];
}

export type ExecutionResultRow = GroupedRow | DataRow;

export enum ExecutionResultRowTypes {
  BlueprintLaunchDataRow = 'BlueprintLaunchDataRow',
  BlueprintLaunchGroupedRow = 'BlueprintLaunchGroupedRow',
}

export interface DataRow {
  __typename: 'BlueprintLaunchDataRow';
  row?: any;
}

export interface BlueprintExecutionResult {
  dataColumns: string[];
  rowsCount: number;
  rows: ExecutionResultRow[];
}

export interface BlueprintExecutionService {
  getBlueprintExecutionInfo(
    blueprintId: string
  ): Promise<BlueprintExecutionInfo>;
  executeBlueprint(
    info: BlueprintExecutionInfo
  ): Promise<BlueprintExecutionResult>;
}
