import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Dow } from '~/shared/graphql';

import styles from './index.module.scss';

interface Props {
  selectedDows: Dow[];
  weekNumber?: number;
  onChange: (dows: Dow[]) => void;
}

export const WeekDaysSelector: React.FC<Props> = React.memo(
  ({ selectedDows, weekNumber, onChange }) => {
    const { t } = useTranslation([MaslovNamespaces.integrator]);

    const buttonClickCallback = useCallback(
      (dow: Dow, select: boolean) => {
        const updatedDows = select
          ? [...selectedDows, dow]
          : selectedDows.filter(item => item !== dow);

        onChange(updatedDows);
      },
      [onChange, selectedDows]
    );

    const week = {
      [Dow.Mon]: t(IntegratorStrings.blueprintDetails.runSettings.mon),
      [Dow.Tue]: t(IntegratorStrings.blueprintDetails.runSettings.tue),
      [Dow.Wed]: t(IntegratorStrings.blueprintDetails.runSettings.wed),
      [Dow.Thu]: t(IntegratorStrings.blueprintDetails.runSettings.thu),
      [Dow.Fri]: t(IntegratorStrings.blueprintDetails.runSettings.fri),
      [Dow.Sat]: t(IntegratorStrings.blueprintDetails.runSettings.sat),
      [Dow.Sun]: t(IntegratorStrings.blueprintDetails.runSettings.sun),
    } as Record<Dow, string>;

    const daysButtons = Object.keys(week).map(key => {
      const selected = selectedDows.find(dow => dow === key);

      return (
        <div
          data-ui-id={weekNumber}
          key={key}
          onClick={() => {
            buttonClickCallback(key as Dow, !selected);
          }}
          className={clsx(styles.day, selected && styles.selected)}
        >
          {week[key as Dow]}
        </div>
      );
    });

    return <div className={styles.root}>{daysButtons}</div>;
  }
);
