import R from 'ramda';

import { PenGroupFragment } from '~/entities/penGroups/gql/fragments/penGroup.graphql';

import { PEN_GROUP_ATTRIBUTES_DICT } from './constants';
import { PenGroupAttributeFields, PenGroupAttributeObject } from './types';

/**
 * create filter object from pen group attributes array
 * can include empty attributes with includeEmptyValuesWith value:
 *
 * - null - it's needed for search query, because apollo
 *   client refetch method uses prev variables if some of them are missing
 * - false - it's needed for creating pen group because all the fields
 *   should be passed
 * - missing - it's can be used for update pen group (optionally)
 * getPenGroupFieldsFromTags
 */
export const getPenGroupFieldsFromAttributes = <
  T extends null | undefined | false,
>(
  penGroupAttributes: PenGroupAttributeFields[],
  includeEmptyValuesWith?: T
): PenGroupAttributeObject<T> =>
  (Object.keys(PEN_GROUP_ATTRIBUTES_DICT) as PenGroupAttributeFields[]).reduce(
    (acc, item) => {
      const attributeValue =
        penGroupAttributes.includes(item) || includeEmptyValuesWith;
      const shouldAddToObject =
        attributeValue || typeof attributeValue !== 'undefined';

      if (shouldAddToObject) {
        acc[item] =
          attributeValue as PenGroupAttributeObject<T>[keyof PenGroupAttributeObject<T>];
      }

      return acc;
    },
    {} as PenGroupAttributeObject<T>
  );

/**
 * return all possible ids of pen group attributes
 */
const checkObjectKeyIsPenGroupAttributeField = (
  key: string
): key is PenGroupAttributeFields =>
  Object.keys(PEN_GROUP_ATTRIBUTES_DICT).includes(key);

/**
 * Get pen group attributes as array from filter object
 */
export const extractPenGroupFieldsFromObject = (
  data?: PenGroupFragment
): PenGroupAttributeFields[] => {
  if (R.isNil(data)) return [];

  return (Object.keys(data) as PenGroupAttributeFields[]).filter(
    item => checkObjectKeyIsPenGroupAttributeField(item) && data[item]
  );
};
