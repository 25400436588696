import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowFragmentDoc } from '../fragments/cow.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCowMutationVariables = Types.Exact<{
  input: Types.CreateCowInput;
}>;


export type CreateCowMutation = { __typename?: 'Mutation', createCow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number } } };


export const CreateCowDocument = gql`
    mutation createCow($input: CreateCowInput!) {
  createCow(input: $input) {
    ...Cow
  }
}
    ${CowFragmentDoc}`;
export type CreateCowMutationFn = Apollo.MutationFunction<CreateCowMutation, CreateCowMutationVariables>;

/**
 * __useCreateCowMutation__
 *
 * To run a mutation, you first call `useCreateCowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCowMutation, { data, loading, error }] = useCreateCowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCowMutation(baseOptions?: Apollo.MutationHookOptions<CreateCowMutation, CreateCowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCowMutation, CreateCowMutationVariables>(CreateCowDocument, options);
      }
export type CreateCowMutationHookResult = ReturnType<typeof useCreateCowMutation>;
export type CreateCowMutationResult = Apollo.MutationResult<CreateCowMutation>;
export type CreateCowMutationOptions = Apollo.BaseMutationOptions<CreateCowMutation, CreateCowMutationVariables>;