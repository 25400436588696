import { LactationGroupNumber } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_LACTATION_GROUP_NUMBERS_DICT } from '../../constants';

const useLactationGroupNumberSelect = makeUseEnumSelect(
  LactationGroupNumber,
  enumValue =>
    CUSTOM_MILKING_REPORT_LACTATION_GROUP_NUMBERS_DICT[
      enumValue as LactationGroupNumber
    ]
);

export const LactationGroupNumberSelect = makeSelectComponentFromHook(
  useLactationGroupNumberSelect
);
