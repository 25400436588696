import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type StrHardcodedValueFragment = { __typename: 'StrHardcodedValue', strValue: string };

export const StrHardcodedValueFragmentDoc = gql`
    fragment StrHardcodedValue on StrHardcodedValue {
  __typename
  strValue: value
}
    `;