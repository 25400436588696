import { gql } from './fakeGql';

export const GetBlueprintExecutionInfoGQL = gql`
  query GetBlueprintExecutionInfo($id: ID!) {
    blueprint(id: $id) {
      id
      name
      description
      isForIntegratorOnly
      priority

      runSettings {
        runType
      }

      inputs {
        id
        name
        prompt
        valueKinds
        cycleInput {
          id
        }
      }

      cycleInputs {
        id
      }

      specialInput {
        name
        prompt
        action {
          id
          kind
        }
      }
    }
  }
`;

export const LaunchBlueprintGQL = gql`
  fragment DataRow on BlueprintLaunchDataRow {
    __typename
    row
  }

  fragment GroupedRowThirdLevelFragment on BlueprintLaunchGroupedRow {
    __typename
    groupKey
    groupValue
    rowsCount
    rows {
      ...DataRow
    }
  }

  fragment GroupedRowSecondLevelFragment on BlueprintLaunchGroupedRow {
    __typename
    groupKey
    groupValue
    rowsCount
    rows {
      ...DataRow
      ...GroupedRowThirdLevelFragment
    }
  }

  fragment GroupedRowFirstLevelFragment on BlueprintLaunchGroupedRow {
    __typename
    groupKey
    groupValue
    rowsCount
    rows {
      ...DataRow
      ...GroupedRowSecondLevelFragment
    }
  }

  mutation LaunchBlueprint(
    $id: ID!
    $inputs: [BlueprintLaunchInput!]!
    $cycleInputs: [BlueprintLaunchCycleInput!]!
    $specialInput: BlueprintLaunchSpecialInput
    $runOn: Date
  ) {
    launchBlueprint(
      id: $id
      inputs: $inputs
      cycleInputs: $cycleInputs
      specialInput: $specialInput
      runOn: $runOn
    ) {
      rowsCount
      dataRowColumns
      rows {
        ...DataRow
        ...GroupedRowFirstLevelFragment
      }
    }
  }
`;
