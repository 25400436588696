import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CutCodeFragmentDoc } from '../fragments/cutCode.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CutCodesQueryVariables = Types.Exact<{
  milkingParlorIntervalID: Types.Scalars['ID']['input'];
  penGroupID: Types.Scalars['ID']['input'];
  date: Types.Scalars['Date']['input'];
}>;


export type CutCodesQuery = { __typename?: 'Query', cutCodes: { __typename?: 'CutCodeConnection', nodes: Array<{ __typename: 'CutCode', sortingGates: Array<{ __typename?: 'SortingGate', number: number, value: number }>, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } }> } };


export const CutCodesDocument = gql`
    query cutCodes($milkingParlorIntervalID: ID!, $penGroupID: ID!, $date: Date!) {
  cutCodes(
    milkingParlorIntervalID: $milkingParlorIntervalID
    penGroupID: $penGroupID
    date: $date
  ) {
    nodes {
      ...CutCode
    }
  }
}
    ${CutCodeFragmentDoc}`;

/**
 * __useCutCodesQuery__
 *
 * To run a query within a React component, call `useCutCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCutCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCutCodesQuery({
 *   variables: {
 *      milkingParlorIntervalID: // value for 'milkingParlorIntervalID'
 *      penGroupID: // value for 'penGroupID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCutCodesQuery(baseOptions: Apollo.QueryHookOptions<CutCodesQuery, CutCodesQueryVariables> & ({ variables: CutCodesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CutCodesQuery, CutCodesQueryVariables>(CutCodesDocument, options);
      }
export function useCutCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CutCodesQuery, CutCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CutCodesQuery, CutCodesQueryVariables>(CutCodesDocument, options);
        }
export function useCutCodesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CutCodesQuery, CutCodesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CutCodesQuery, CutCodesQueryVariables>(CutCodesDocument, options);
        }
export type CutCodesQueryHookResult = ReturnType<typeof useCutCodesQuery>;
export type CutCodesLazyQueryHookResult = ReturnType<typeof useCutCodesLazyQuery>;
export type CutCodesSuspenseQueryHookResult = ReturnType<typeof useCutCodesSuspenseQuery>;
export type CutCodesQueryResult = Apollo.QueryResult<CutCodesQuery, CutCodesQueryVariables>;