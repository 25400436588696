import React from 'react';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { InjectedModalProps, Modal } from '~/services/modals';

import styles from './index.module.scss';

export interface SystemInfoModalProps
  extends InjectedModalProps<SystemInfoModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const SystemInfoModal: React.FC<SystemInfoModalProps> = ({
  className,
}) => {
  return (
    <Modal
      {...{
        className,
        contentClassName: styles.root,
        title: 'Системная информация',
        renderButtons: () => null,
      }}
    >
      <Typography variant={TypographyVariants.bodySmall}>
        Почта поддержки: arka.cowork@gmail.com
      </Typography>
      <Typography variant={TypographyVariants.bodySmall}>
        Часы работы: Пн–Пт 09:00–18:00
      </Typography>
      <Typography
        variant={TypographyVariants.bodySmall}
        tag="a"
        target="_blank"
        href="/docs/Руководство пользователя.pdf"
      >
        Руководство пользователя
      </Typography>
      <Typography
        variant={TypographyVariants.bodySmall}
        tag="a"
        target="_blank"
        href="/docs/Описание функциональных характеристик ПО.pdf"
      >
        Описание функциональных характеристик ПО
      </Typography>
    </Modal>
  );
};
