import { downloadFile } from './downloadFile';

export function exportToCsv(content: string, filename: string) {
  const universalBOM = '\uFEFF';
  const href = `data:text/csv;charset=utf-8,${encodeURIComponent(
    universalBOM + content
  )}`;

  downloadFile(href, filename, 'csv');
}
