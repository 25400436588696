import React, { ReactNode } from 'react';

import { MPanel } from '../MPanel';
import { MMenuItem } from './MMenuItem';

import './MMenu.scss';

export enum MenuItemType {
  item = 'item',
  // custom = 'custom',
  delemiter = 'delemiter',
}

export interface MenuItem {
  content?: string | ReactNode;
  type?: MenuItemType;
  action?: () => void;
}

interface Props {
  items: MenuItem[];

  className?: string;
}

export const MMenu: React.FC<Props> = props => {
  const { items, className = '' } = props;

  const components = items.map((item, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <MMenuItem key={`${item.content?.toString()}${i}`} item={item} />
  ));

  return (
    <MPanel elevation tag="ul" className={`m-menu ${className}`}>
      {components}
    </MPanel>
  );
};
