import { Falsy } from '~/shared/types/utility';

/**
 * Helps to construct conditional objects like this: {a: b, ...(some ? c : {}), z: x}
 */
export const wrapConditionalObjectElement = <T extends Record<any, any>>(
  element: T | Falsy,
  isPassingValueOrFunc: boolean | ((element: T | Falsy) => boolean) = el => !!el
) => {
  let isPassing = isPassingValueOrFunc;
  if (typeof isPassing === 'function') {
    isPassing = isPassing(element);
  }
  if (isPassing) {
    return element as T;
  }
  return {} as T;
};
