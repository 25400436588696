import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useApolloClient } from '@apollo/client';

import { CowsCopyReasonEnum } from '@graphql-types';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { Checkbox } from '~/shared/components/Checkbox';
import { Input, InputThemes } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { TextLink } from '~/shared/components/TextLink';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { DateInput, DateInputThemes } from '~/services/dateTime';
import { useNotifications } from '~/services/notifications';

import { CompanyAsyncSelect } from '~/entities/companies';
import { CowStateBadge, formatCow, readCowFragment } from '~/entities/cows';
import { formatPenGroup } from '~/entities/penGroups';

import formStyles from '~/styles/modules/form.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { MoveCowCard } from '../../../../components';
import { useAddCowIdsToListModal } from '../../../index';
import { MoveCowsFormType } from '../../types';
import { ContentItems } from '../ContentItems';
import styles from './index.module.scss';

const COW_NAME_TOOLTIP_MAX_WIDTH_PX = 264;

export const ChooseAnimalsStep: React.FC = () => {
  const { sendNeutralToast } = useNotifications();
  const formContext = useFormContext<MoveCowsFormType>();

  const [reason, cowIDs, shouldUseCustomCompany] = formContext.watch([
    'reason',
    'cowIDs',
    'shouldUseCustomCompany',
  ]);

  const client = useApolloClient();

  const { open: openAddCowIdsToListModal } = useAddCowIdsToListModal({
    onSubmit: newCowIDs => {
      formContext.setValue('cowIDs', newCowIDs, { shouldDirty: true });
    },
    defaultValues: cowIDs,
  });

  const cows = useMemo(
    () => cowIDs.map(cowId => readCowFragment(client, cowId)).filter(Boolean),
    [cowIDs]
  );

  return (
    <div
      {...{
        className: clsx(
          panelStyles.largeDarkPanel,
          formStyles.singleColumnForm,
          'p-16'
        ),
      }}
    >
      <Typography variant={TypographyVariants.bodyMediumStrong}>
        {reason === CowsCopyReasonEnum.Sell
          ? 'Информация о продаже'
          : 'Информация о перемещении'}
      </Typography>
      <div className={styles.inputSubForm}>
        {match(reason)
          .with(CowsCopyReasonEnum.Sell, () => (
            <>
              <DateInput
                {...{
                  name: 'departDate',
                  label: 'Дата продажи',
                  theme: DateInputThemes.light,
                  className: styles.input,
                }}
              />
              <Input
                {...{
                  name: 'toCompanyInn',
                  label: 'ИНН покупателя',
                  placeholder: 'Введите номер',
                  theme: InputThemes.light,
                  className: styles.input,
                  accept: /\d/g,
                  formatRawToDisplayedValue: value =>
                    (value.match(/(\d+)/g) ?? []).join(''),
                }}
              />
            </>
          ))
          .with(CowsCopyReasonEnum.Move, () => (
            <>
              <DateInput
                {...{
                  name: 'departDate',
                  label: 'Дата перемещения',
                  theme: DateInputThemes.light,
                  className: styles.input,
                }}
              />
              {shouldUseCustomCompany && (
                <Input
                  {...{
                    name: 'toCompanyName',
                    placeholder: 'Введите наименование',
                    label: 'Площадка получения',
                    theme: InputThemes.light,
                    className: styles.input,
                  }}
                />
              )}
              {!shouldUseCustomCompany && (
                <CompanyAsyncSelect
                  {...{
                    name: 'toCompanyID',
                    placeholder: 'Выберите наименование',
                    label: 'Площадка получения',
                    theme: SelectThemes.light,
                    className: styles.input,
                  }}
                />
              )}
              <Checkbox
                {...{
                  name: 'shouldUseCustomCompany',
                  className: styles.checkbox,
                  label: 'Указать другую площадку',
                  onValueChange: () => {
                    (['toCompanyName', 'toCompanyID'] as const).forEach(field =>
                      formContext.resetField(field)
                    );
                  },
                }}
              />
            </>
          ))
          .exhaustive()}
      </div>
      <ContentItems
        {...{
          title: 'Список животных',
          errorMessage: formContext.formState.errors.cowIDs?.message,
          amount: cows.length,
          addButtonProps: {
            onPress: () => openAddCowIdsToListModal(),
            children: 'Добавить животных',
          },
          onClear: () => {
            formContext.setValue('cowIDs', []);
          },
          dataBlockedMessageProps: {
            message: 'Животные пока не добавлены',
          },
          itemsToRender: cows.map((cow, index) => {
            const formattedPenGroup = formatPenGroup(cow.penGroup, {
              prefix: 'Группа',
            });

            return (
              <MoveCowCard
                key={cow.id}
                {...{
                  index: index + 1,
                  contentItems: [
                    <div key={cow.id} {...{ className: styles.cowIdTextBlock }}>
                      <Tooltip
                        {...{
                          content:
                            'Нажмите, чтобы открыть карточку животного в новой вкладке',
                          maxWidth: COW_NAME_TOOLTIP_MAX_WIDTH_PX,
                        }}
                      >
                        <TextLink
                          {...{
                            target: '_blank',
                            to: '/$companyId/user/cows/$cowId',
                            params: prev => ({
                              ...prev,
                              cowId: cow.id,
                            }),
                          }}
                        >
                          {formatCow(cow, { prefix: '', withName: false })}
                        </TextLink>
                      </Tooltip>
                    </div>,
                    <div
                      key={cow.penGroup.id}
                      {...{
                        title: formattedPenGroup,
                        className: clsx('ellipsis', styles.penGroupTextBlock),
                      }}
                    >
                      {formattedPenGroup}
                    </div>,
                    <CowStateBadge isFull key={cow.state} state={cow.state} />,
                  ],
                  onDelete: () => {
                    formContext.setValue(
                      'cowIDs',
                      cowIDs.filter(cowId => cowId !== cow.id)
                    );
                    sendNeutralToast('Животное удалено');
                  },
                }}
              />
            );
          }),
        }}
      />
    </div>
  );
};
