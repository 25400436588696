/**
 * Dict with all timezone translations
 */
export const TIME_ZONES_DICT = {
  'Africa/Abidjan': 'Африка, Абиджан',
  'Africa/Accra': 'Африка, Аккра',
  'Africa/Addis_Ababa': 'Африка, Аддис-Абеба',
  'Africa/Algiers': 'Африка, Алжир',
  'Africa/Asmara': 'Африка, Асмэра',
  'Africa/Bamako': 'Африка, Бамако',
  'Africa/Bangui': 'Африка, Банги',
  'Africa/Banjul': 'Африка, Банжул',
  'Africa/Bissau': 'Африка, Бисау',
  'Africa/Blantyre': 'Африка, Блантайр',
  'Africa/Brazzaville': 'Африка, Браззавиль',
  'Africa/Bujumbura': 'Африка, Бужумбура',
  'Africa/Cairo': 'Африка, Каир',
  'Africa/Casablanca': 'Африка, Касабланка',
  'Africa/Ceuta': 'Африка, Сеута',
  'Africa/Conakry': 'Африка, Конакри',
  'Africa/Dakar': 'Африка, Дакар',
  'Africa/Dar_es_Salaam': 'Африка, Дар-эс-Салам',
  'Africa/Djibouti': 'Африка, Джибути',
  'Africa/Douala': 'Африка, Дуала',
  'Africa/El_Aaiun': 'Африка, Эль-Аюн',
  'Africa/Freetown': 'Африка, Фритаун',
  'Africa/Gaborone': 'Африка, Габороне',
  'Africa/Harare': 'Африка, Хараре',
  'Africa/Johannesburg': 'Африка, Йоханнесбург',
  'Africa/Juba': 'Африка, Джуба',
  'Africa/Kampala': 'Африка, Кампала',
  'Africa/Khartoum': 'Африка, Хартум',
  'Africa/Kigali': 'Африка, Кигали',
  'Africa/Kinshasa': 'Африка, Киншаса',
  'Africa/Lagos': 'Африка, Лагос',
  'Africa/Libreville': 'Африка, Либревиль',
  'Africa/Lome': 'Африка, Ломе',
  'Africa/Luanda': 'Африка, Луанда',
  'Africa/Lubumbashi': 'Африка, Лубумбаши',
  'Africa/Lusaka': 'Африка, Лусака',
  'Africa/Malabo': 'Африка, Малабо',
  'Africa/Maputo': 'Африка, Мапуту',
  'Africa/Maseru': 'Африка, Масеру',
  'Africa/Mbabane': 'Африка, Мбабане',
  'Africa/Mogadishu': 'Африка, Могадишо',
  'Africa/Monrovia': 'Африка, Монровия',
  'Africa/Nairobi': 'Африка, Найроби',
  'Africa/Ndjamena': 'Африка, Нджамена',
  'Africa/Niamey': 'Африка, Ниамей',
  'Africa/Nouakchott': 'Африка, Нуакшот',
  'Africa/Ouagadougou': 'Африка, Уагадугу',
  'Africa/Porto-Novo': 'Африка, Порто-Ново',
  'Africa/Sao_Tome': 'Африка, Сан-Томе',
  'Africa/Timbuktu': 'Африка, Тимбукту',
  'Africa/Tripoli': 'Африка, Триполи',
  'Africa/Tunis': 'Африка, Тунис',
  'Africa/Windhoek': 'Африка, Виндхук',
  'America/Adak': 'Америка, Адак',
  'America/Anchorage': 'Америка, Анкоридж',
  'America/Anguilla': 'Америка, Ангилья',
  'America/Antigua': 'Америка, Антигуа',
  'America/Araguaina': 'Америка, Арагуайна',
  'America/Argentina/Buenos_Aires': 'Америка, Аргентина, Буэнос-Айрес',
  'America/Argentina/Catamarca': 'Америка, Аргентина, Катамарка',
  'America/Argentina/ComodRivadavia': 'Америка, Аргентина, Комод-Ривадавия',
  'America/Argentina/Cordoba': 'Америка, Аргентина, Кордова',
  'America/Argentina/Jujuy': 'Америка, Аргентина, Жужуй',
  'America/Argentina/La_Rioja': 'Америка, Аргентина, Ла-Риоха',
  'America/Argentina/Mendoza': 'Америка, Аргентина, Мендоса',
  'America/Argentina/Rio_Gallegos': 'Америка, Аргентина, Рио-Гальегос',
  'America/Argentina/Salta': 'Америка, Аргентина, Сальта',
  'America/Argentina/San_Juan': 'Америка, Аргентина, Сан-Хуан',
  'America/Argentina/San_Luis': 'Америка, Аргентина, Сан-Луис',
  'America/Argentina/Tucuman': 'Америка, Аргентина, Тукуман',
  'America/Argentina/Ushuaia': 'Америка, Аргентина, Ушуайя',
  'America/Aruba': 'Америка, Аруба',
  'America/Asuncion': 'Америка, Асунсьон',
  'America/Atikokan': 'Америка, Атикокан',
  'America/Atka': 'Америка, Атка',
  'America/Bahia': 'Америка, Баия',
  'America/Bahia_Banderas': 'Америка, Баия-де-Бандерас',
  'America/Barbados': 'Америка, Барбадос',
  'America/Belem': 'Америка, Белем',
  'America/Belize': 'Америка, Белиз',
  'America/Blanc-Sablon': 'Америка, Бланк-Саблон',
  'America/Boa_Vista': 'Америка, Боа-Виста',
  'America/Bogota': 'Америка, Богота',
  'America/Boise': 'Америка, Бойсе',
  'America/Buenos_Aires': 'Америка, Буэнос-Айрес',
  'America/Cambridge_Bay': 'Америка, Кембридж-Бей',
  'America/Campo_Grande': 'Америка, Кампу-Гранди',
  'America/Cancun': 'Америка, Канкун',
  'America/Caracas': 'Америка, Каракас',
  'America/Catamarca': 'Америка, Катамарка',
  'America/Cayenne': 'Америка, Кайенна',
  'America/Cayman': 'Америка, Каймановы острова',
  'America/Chicago': 'Америка, Чикаго',
  'America/Chihuahua': 'Америка, Чиуауа',
  'America/Ciudad_Juarez': 'Америка, Сьюдад-Хуарес',
  'America/Coral_Harbour': 'Америка, Корал-Харбор',
  'America/Cordoba': 'Америка, Кордова',
  'America/Costa_Rica': 'Америка, Коста-Рика',
  'America/Creston': 'Америка, Крестон',
  'America/Cuiaba': 'Америка, Куяба',
  'America/Curacao': 'Америка, Кюрасао',
  'America/Danmarkshavn': 'Америка, Денмаркшавн',
  'America/Dawson': 'Америка, Досон',
  'America/Dawson_Creek': 'Америка, Досон-Крик',
  'America/Denver': 'Америка, Денвер',
  'America/Detroit': 'Америка, Детройт',
  'America/Dominica': 'Америка, Доминика',
  'America/Edmonton': 'Америка, Эдмонтон',
  'America/Eirunepe': 'Америка, Эйрунепе',
  'America/El_Salvador': 'Америка, Сальвадор',
  'America/Ensenada': 'Америка, Энсенада',
  'America/Fort_Nelson': 'Америка, Форт-Нельсон',
  'America/Fort_Wayne': 'Америка, Форт-Уэйн',
  'America/Fortaleza': 'Америка, Форталеза',
  'America/Glace_Bay': 'Америка, Глейс-Бей',
  'America/Godthab': 'Америка, Готхоб',
  'America/Goose_Bay': 'Америка, Гус-Бей',
  'America/Grand_Turk': 'Америка, Гранд-Терк',
  'America/Grenada': 'Америка, Гренада',
  'America/Guadeloupe': 'Америка, Гваделупа',
  'America/Guatemala': 'Америка, Гватемала',
  'America/Guayaquil': 'Америка, Гуаякиль',
  'America/Guyana': 'Америка, Гайана',
  'America/Halifax': 'Америка, Галифакс',
  'America/Havana': 'Америка, Гавана',
  'America/Hermosillo': 'Америка, Эрмосильо',
  'America/Indiana/Indianapolis': 'Америка, Индиана, Индианаполис',
  'America/Indiana/Knox': 'Америка, Индиана, Нокс',
  'America/Indiana/Marengo': 'Америка, Индиана, Маренго',
  'America/Indiana/Petersburg': 'Америка, Индиана, Питерсберг',
  'America/Indiana/Tell_City': 'Америка, Индиана, Телл-Сити',
  'America/Indiana/Vevay': 'Америка, Индиана, Вивэй',
  'America/Indiana/Vincennes': 'Америка, Индиана, Винсенс',
  'America/Indiana/Winamac': 'Америка, Индиана, Винамак',
  'America/Indianapolis': 'Америка, Индианаполис',
  'America/Inuvik': 'Америка, Инувик',
  'America/Iqaluit': 'Америка, Икалуит',
  'America/Jamaica': 'Америка, Ямайка',
  'America/Jujuy': 'Америка, Жужуй',
  'America/Juneau': 'Америка, Джуно',
  'America/Kentucky/Louisville': 'Америка, Кентукки, Луисвилл',
  'America/Kentucky/Monticello': 'Америка, Кентукки, Монтиселло',
  'America/Knox_IN': 'Америка, Нокс-ИН',
  'America/Kralendijk': 'Америка, Кралендейк',
  'America/La_Paz': 'Америка, Ла-Пас',
  'America/Lima': 'Америка, Лима',
  'America/Los_Angeles': 'Америка, Лос-Анджелес',
  'America/Louisville': 'Америка, Луисвилл',
  'America/Lower_Princes': 'Америка, Лоуэр-Принсес',
  'America/Maceio': 'Америка, Масейо',
  'America/Managua': 'Америка, Манагуа',
  'America/Manaus': 'Америка, Манаус',
  'America/Marigot': 'Америка, Маригот',
  'America/Martinique': 'Америка, Мартиника',
  'America/Matamoros': 'Америка, Матаморос',
  'America/Mazatlan': 'Америка, Масатлан',
  'America/Mendoza': 'Америка, Мендоса',
  'America/Menominee': 'Америка, Меномини',
  'America/Merida': 'Америка, Мерида',
  'America/Metlakatla': 'Америка, Метлакатла',
  'America/Mexico_City': 'Америка, Мехико',
  'America/Miquelon': 'Америка, Микелон',
  'America/Moncton': 'Америка, Монктон',
  'America/Monterrey': 'Америка, Монтеррей',
  'America/Montevideo': 'Америка, Монтевидео',
  'America/Montreal': 'Америка, Монреаль',
  'America/Montserrat': 'Америка, Монтсеррат',
  'America/Nassau': 'Америка, Нассау',
  'America/New_York': 'Америка, Нью-Йорк',
  'America/Nipigon': 'Америка, Нипигон',
  'America/Nome': 'Америка, Ном',
  'America/Noronha': 'Америка, Норонья',
  'America/North_Dakota/Beulah': 'Америка, Северная Дакота, Бьюла',
  'America/North_Dakota/Center': 'Америка, Северная Дакота, Центр',
  'America/North_Dakota/New_Salem': 'Америка, Северная Дакота, Нью-Салем',
  'America/Nuuk': 'Америка, Нуук',
  'America/Ojinaga': 'Америка, Охинага',
  'America/Panama': 'Америка, Панама',
  'America/Pangnirtung': 'Америка, Пангниртанг',
  'America/Paramaribo': 'Америка, Парамарибо',
  'America/Phoenix': 'Америка, Финикс',
  'America/Port-au-Prince': 'Америка, Порт-о-Пренс',
  'America/Port_of_Spain': 'Америка, Порт-оф-Спейн',
  'America/Porto_Acre': 'Америка, Порту-Акри',
  'America/Porto_Velho': 'Америка, Порту-Велью',
  'America/Puerto_Rico': 'Америка, Пуэрто-Рико',
  'America/Punta_Arenas': 'Америка, Пунта-Аренас',
  'America/Rainy_River': 'Америка, Рейни-Ривер',
  'America/Rankin_Inlet': 'Америка, Ранкин-Инлет',
  'America/Recife': 'Америка, Ресифи',
  'America/Regina': 'Америка, Реджайна',
  'America/Resolute': 'Америка, Резольют',
  'America/Rio_Branco': 'Америка, Риу-Бранку',
  'America/Rosario': 'Америка, Росарио',
  'America/Santa_Isabel': 'Америка, Санта-Исабель',
  'America/Santarem': 'Америка, Сантарен',
  'America/Santiago': 'Америка, Сантьяго',
  'America/Santo_Domingo': 'Америка, Санто-Доминго',
  'America/Sao_Paulo': 'Америка, Сан-Паулу',
  'America/Scoresbysund': 'Америка, Скорсбисунд',
  'America/Shiprock': 'Америка, Шипрок',
  'America/Sitka': 'Америка, Ситка',
  'America/St_Barthelemy': 'Америка, Сен-Бартелеми',
  'America/St_Johns': 'Америка, Сент-Джонс',
  'America/St_Kitts': 'Америка, Сент-Китс',
  'America/St_Lucia': 'Америка, Сент-Люсия',
  'America/St_Thomas': 'Америка, Сент-Томас',
  'America/St_Vincent': 'Америка, Сент-Винсент',
  'America/Swift_Current': 'Америка, Свифт-Каррент',
  'America/Tegucigalpa': 'Америка, Тегусигальпа',
  'America/Thule': 'Америка, Туле',
  'America/Thunder_Bay': 'Америка, Тандер-Бей',
  'America/Tijuana': 'Америка, Тихуана',
  'America/Toronto': 'Америка, Торонто',
  'America/Tortola': 'Америка, Тортола',
  'America/Vancouver': 'Америка, Ванкувер',
  'America/Virgin': 'Америка, Виргин',
  'America/Whitehorse': 'Америка, Уайтхорс',
  'America/Winnipeg': 'Америка, Виннипег',
  'America/Yakutat': 'Америка, Якутат',
  'America/Yellowknife': 'Америка, Йеллоунайф',
  'Antarctica/Casey': 'Антарктика, Кейси',
  'Antarctica/Davis': 'Антарктика, Дейвис',
  'Antarctica/DumontDUrville': 'Антарктика, Дюмон-д’Юрвиль',
  'Antarctica/Macquarie': 'Антарктика, Маккуори',
  'Antarctica/Mawson': 'Антарктика, Моусон',
  'Antarctica/McMurdo': 'Антарктика, Мак-Мердо',
  'Antarctica/Palmer': 'Антарктика, Палмер',
  'Antarctica/Rothera': 'Антарктика, Ротера',
  'Antarctica/South_Pole': 'Антарктика, Южный Полюс',
  'Antarctica/Syowa': 'Антарктика, Сёва',
  'Antarctica/Troll': 'Антарктика, Тролль',
  'Antarctica/Vostok': 'Антарктика, Восток',
  'Arctic/Longyearbyen': 'Арктика, Лонгйир',
  'Asia/Aden': 'Азия, Аден',
  'Asia/Almaty': 'Азия, Алма-Ата',
  'Asia/Amman': 'Азия, Амман',
  'Asia/Anadyr': 'Азия, Анадырь',
  'Asia/Aqtau': 'Азия, Актау',
  'Asia/Aqtobe': 'Азия, Актобе',
  'Asia/Ashgabat': 'Азия, Ашхабад',
  'Asia/Atyrau': 'Азия, Атырау',
  'Asia/Baghdad': 'Азия, Багдад',
  'Asia/Bahrain': 'Азия, Бахрейн',
  'Asia/Baku': 'Азия, Баку',
  'Asia/Bangkok': 'Азия, Бангкок',
  'Asia/Barnaul': 'Азия, Барнаул',
  'Asia/Beirut': 'Азия, Бейрут',
  'Asia/Bishkek': 'Азия, Бишкек',
  'Asia/Brunei': 'Азия, Бруней',
  'Asia/Calcutta': 'Азия, Калькутта',
  'Asia/Chita': 'Азия, Чита',
  'Asia/Choibalsan': 'Азия, Чойбалсан',
  'Asia/Chungking': 'Азия, Чунцин',
  'Asia/Colombo': 'Азия, Коломбо',
  'Asia/Dacca': 'Азия, Дакка',
  'Asia/Damascus': 'Азия, Дамаск',
  'Asia/Dili': 'Азия, Дили',
  'Asia/Dubai': 'Азия, Дубай',
  'Asia/Dushanbe': 'Азия, Душанбе',
  'Asia/Famagusta': 'Азия, Фамагуста',
  'Asia/Gaza': 'Азия, Газа',
  'Asia/Harbin': 'Азия, Харбин',
  'Asia/Hebron': 'Азия, Хеврон',
  'Asia/Hong_Kong': 'Азия, Гонконг',
  'Asia/Hovd': 'Азия, Ховд',
  'Asia/Irkutsk': 'Азия, Иркутск',
  'Asia/Istanbul': 'Азия, Стамбул',
  'Asia/Jakarta': 'Азия, Джакарта',
  'Asia/Jayapura': 'Азия, Джаяпура',
  'Asia/Jerusalem': 'Азия, Иерусалим',
  'Asia/Kabul': 'Азия, Кабул',
  'Asia/Kamchatka': 'Азия, Камчатка',
  'Asia/Karachi': 'Азия, Карачи',
  'Asia/Kashgar': 'Азия, Кашгар',
  'Asia/Kathmandu': 'Азия, Катманду',
  'Asia/Khandyga': 'Азия, Хандыга',
  'Asia/Krasnoyarsk': 'Азия, Красноярск',
  'Asia/Kuala_Lumpur': 'Азия, Куала-Лумпур',
  'Asia/Kuching': 'Азия, Кучинг',
  'Asia/Kuwait': 'Азия, Кувейт',
  'Asia/Macao': 'Азия, Макао',
  'Asia/Magadan': 'Азия, Магадан',
  'Asia/Makassar': 'Азия, Макасар',
  'Asia/Manila': 'Азия, Манила',
  'Asia/Muscat': 'Азия, Мускат',
  'Asia/Nicosia': 'Азия, Никосия',
  'Asia/Novokuznetsk': 'Азия, Новокузнецк',
  'Asia/Novosibirsk': 'Азия, Новосибирск',
  'Asia/Omsk': 'Азия, Омск',
  'Asia/Oral': 'Азия, Уральск',
  'Asia/Phnom_Penh': 'Азия, Пномпень',
  'Asia/Pontianak': 'Азия, Понтианак',
  'Asia/Pyongyang': 'Азия, Пхеньян',
  'Asia/Qatar': 'Азия, Катар',
  'Asia/Qostanay': 'Азия, Костанай',
  'Asia/Qyzylorda': 'Азия, Кызылорда',
  'Asia/Rangoon': 'Азия, Янгон',
  'Asia/Riyadh': 'Азия, Эр-Рияд',
  'Asia/Saigon': 'Азия, Хошимин',
  'Asia/Sakhalin': 'Азия, Сахалин',
  'Asia/Samarkand': 'Азия, Самарканд',
  'Asia/Seoul': 'Азия, Сеул',
  'Asia/Shanghai': 'Азия, Шанхай',
  'Asia/Singapore': 'Азия, Сингапур',
  'Asia/Srednekolymsk': 'Азия, Среднеколымск',
  'Asia/Taipei': 'Азия, Тайбэй',
  'Asia/Tashkent': 'Азия, Ташкент',
  'Asia/Tbilisi': 'Азия, Тбилиси',
  'Asia/Tehran': 'Азия, Тегеран',
  'Asia/Tel_Aviv': 'Азия, Тель-Авив',
  'Asia/Thimbu': 'Азия, Тхимпху',
  'Asia/Tokyo': 'Азия, Токио',
  'Asia/Tomsk': 'Азия, Томск',
  'Asia/Ulaanbaatar': 'Азия, Улан-Батор',
  'Asia/Urumqi': 'Азия, Урумчи',
  'Asia/Ust-Nera': 'Азия, Усть-Нера',
  'Asia/Vientiane': 'Азия, Вьентьян',
  'Asia/Vladivostok': 'Азия, Владивосток',
  'Asia/Yakutsk': 'Азия, Якутск',
  'Asia/Yekaterinburg': 'Азия, Екатеринбург',
  'Asia/Yerevan': 'Азия, Ереван',
  'Atlantic/Azores': 'Атлантика, Азорские острова',
  'Atlantic/Bermuda': 'Атлантика, Бермуды',
  'Atlantic/Canary': 'Атлантика, Канарские острова',
  'Atlantic/Cape_Verde': 'Атлантика, Кабо-Верде',
  'Atlantic/Faeroe': 'Атлантика, Фареры',
  'Atlantic/Jan_Mayen': 'Атлантика, Ян-Майен',
  'Atlantic/Madeira': 'Атлантика, Мадейра',
  'Atlantic/Reykjavik': 'Атлантика, Рейкьявик',
  'Atlantic/South_Georgia': 'Атлантика, Южная Георгия',
  'Atlantic/St_Helena': 'Атлантика, Остров Святой Елены',
  'Atlantic/Stanley': 'Атлантика, Стэнли',
  'Australia/ACT': 'Австралия, АТС',
  'Australia/Adelaide': 'Австралия, Аделаида',
  'Australia/Brisbane': 'Австралия, Брисбен',
  'Australia/Broken_Hill': 'Австралия, Брокен-Хилл',
  'Australia/Canberra': 'Австралия, Канберра',
  'Australia/Currie': 'Австралия, Курри',
  'Australia/Darwin': 'Австралия, Дарвин',
  'Australia/Eucla': 'Австралия, Эвкла',
  'Australia/Hobart': 'Австралия, Хобарт',
  'Australia/LHI': 'Австралия, Лорд-Хау',
  'Australia/Lindeman': 'Австралия, Линдеман',
  'Australia/Melbourne': 'Австралия, Мельбурн',
  'Australia/NSW': 'Австралия, Новый Южный Уэльс',
  'Australia/North': 'Австралия, Север',
  'Australia/Perth': 'Австралия, Перт',
  'Australia/Queensland': 'Австралия, Квинсленд',
  'Australia/South': 'Австралия, Юг',
  'Australia/Sydney': 'Австралия, Сидней',
  'Australia/Tasmania': 'Австралия, Тасмания',
  'Australia/Victoria': 'Австралия, Виктория',
  'Australia/West': 'Австралия, Запад',
  'Australia/Yancowinna': 'Австралия, Янковинна',
  'Brazil/Acre': 'Бразилия, Акри',
  'Brazil/DeNoronha': 'Бразилия, Де Норонья',
  'Brazil/East': 'Бразилия, Восток',
  'Brazil/West': 'Бразилия, Запад',
  CET: 'Центральноевропейское время',
  CST6CDT: 'Центральное стандартное время / Центральное летнее время',
  'Canada/Atlantic': 'Канада, Атлантика',
  'Canada/Central': 'Канада, Центральная',
  'Canada/Eastern': 'Канада, Восточная',
  'Canada/Mountain': 'Канада, Горная',
  'Canada/Newfoundland': 'Канада, Ньюфаундленд',
  'Canada/Pacific': 'Канада, Тихоокеанская',
  'Canada/Saskatchewan': 'Канада, Саскачеван',
  'Canada/Yukon': 'Канада, Юкон',
  'Chile/Continental': 'Чили, Континентальная',
  'Chile/EasterIsland': 'Чили, Остров Пасхи',
  Cuba: 'Куба',
  EET: 'Восточноевропейское время',
  EST: 'Восточное стандартное время',
  EST5EDT: 'Восточное стандартное время / Восточное летнее время',
  Egypt: 'Египет',
  Eire: 'Ирландия',
  'Etc/GMT': 'Среднее время по Гринвичу',
  'Etc/GMT+1': 'Время по Гринвичу -1 час',
  'Etc/GMT+10': 'Время по Гринвичу -10 часов',
  'Etc/GMT+11': 'Время по Гринвичу -11 часов',
  'Etc/GMT+12': 'Время по Гринвичу -12 часов',
  'Etc/GMT+2': 'Время по Гринвичу -2 часа',
  'Etc/GMT+3': 'Время по Гринвичу -3 часа',
  'Etc/GMT+4': 'Время по Гринвичу -4 часа',
  'Etc/GMT+5': 'Время по Гринвичу -5 часов',
  'Etc/GMT+6': 'Время по Гринвичу -6 часов',
  'Etc/GMT+7': 'Время по Гринвичу -7 часов',
  'Etc/GMT+8': 'Время по Гринвичу -8 часов',
  'Etc/GMT+9': 'Время по Гринвичу -9 часов',
  'Etc/GMT-1': 'Время по Гринвичу +1 час',
  'Etc/GMT-10': 'Время по Гринвичу +10 часов',
  'Etc/GMT-11': 'Время по Гринвичу +11 часов',
  'Etc/GMT-12': 'Время по Гринвичу +12 часов',
  'Etc/GMT-13': 'Время по Гринвичу +13 часов',
  'Etc/GMT-14': 'Время по Гринвичу +14 часов',
  'Etc/GMT-2': 'Время по Гринвичу +2 часа',
  'Etc/GMT-3': 'Время по Гринвичу +3 часа',
  'Etc/GMT-4': 'Время по Гринвичу +4 часа',
  'Etc/GMT-5': 'Время по Гринвичу +5 часов',
  'Etc/GMT-6': 'Время по Гринвичу +6 часов',
  'Etc/GMT-7': 'Время по Гринвичу +7 часов',
  'Etc/GMT-8': 'Время по Гринвичу +8 часов',
  'Etc/GMT-9': 'Время по Гринвичу +9 часов',
  'Etc/UTC': 'Координированное всемирное время',
  'Etc/Universal': 'Всемирное время',
  'Europe/Amsterdam': 'Европа, Амстердам',
  'Europe/Andorra': 'Европа, Андорра',
  'Europe/Astrakhan': 'Европа, Астрахань',
  'Europe/Athens': 'Европа, Афины',
  'Europe/Belfast': 'Европа, Белфаст',
  'Europe/Belgrade': 'Европа, Белград',
  'Europe/Berlin': 'Европа, Берлин',
  'Europe/Bratislava': 'Европа, Братислава',
  'Europe/Brussels': 'Европа, Брюссель',
  'Europe/Bucharest': 'Европа, Бухарест',
  'Europe/Budapest': 'Европа, Будапешт',
  'Europe/Busingen': 'Европа, Бюзинген',
  'Europe/Chisinau': 'Европа, Кишинев',
  'Europe/Copenhagen': 'Европа, Копенгаген',
  'Europe/Dublin': 'Европа, Дублин',
  'Europe/Gibraltar': 'Европа, Гибралтар',
  'Europe/Guernsey': 'Европа, Гернси',
  'Europe/Helsinki': 'Европа, Хельсинки',
  'Europe/Isle_of_Man': 'Европа, Остров Мэн',
  'Europe/Istanbul': 'Европа, Стамбул',
  'Europe/Jersey': 'Европа, Джерси',
  'Europe/Kaliningrad': 'Европа, Калининград',
  'Europe/Kiev': 'Европа, Киев',
  'Europe/Kirov': 'Европа, Киров',
  'Europe/Lisbon': 'Европа, Лиссабон',
  'Europe/Ljubljana': 'Европа, Любляна',
  'Europe/London': 'Европа, Лондон',
  'Europe/Luxembourg': 'Европа, Люксембург',
  'Europe/Madrid': 'Европа, Мадрид',
  'Europe/Malta': 'Европа, Мальта',
  'Europe/Mariehamn': 'Европа, Мариехамн',
  'Europe/Minsk': 'Европа, Минск',
  'Europe/Monaco': 'Европа, Монако',
  'Europe/Moscow': 'Европа, Москва',
  'Europe/Nicosia': 'Европа, Никосия',
  'Europe/Oslo': 'Европа, Осло',
  'Europe/Paris': 'Европа, Париж',
  'Europe/Podgorica': 'Европа, Подгорица',
  'Europe/Prague': 'Европа, Прага',
  'Europe/Riga': 'Европа, Рига',
  'Europe/Rome': 'Европа, Рим',
  'Europe/Samara': 'Европа, Самара',
  'Europe/San_Marino': 'Европа, Сан-Марино',
  'Europe/Sarajevo': 'Европа, Сараево',
  'Europe/Saratov': 'Европа, Саратов',
  'Europe/Simferopol': 'Европа, Симферополь',
  'Europe/Skopje': 'Европа, Скопье',
  'Europe/Sofia': 'Европа, София',
  'Europe/Stockholm': 'Европа, Стокгольм',
  'Europe/Tallinn': 'Европа, Таллин',
  'Europe/Tirane': 'Европа, Тирана',
  'Europe/Tiraspol': 'Европа, Тирасполь',
  'Europe/Ulyanovsk': 'Европа, Ульяновск',
  'Europe/Uzhgorod': 'Европа, Ужгород',
  'Europe/Vaduz': 'Европа, Вадуц',
  'Europe/Vatican': 'Европа, Ватикан',
  'Europe/Vienna': 'Европа, Вена',
  'Europe/Vilnius': 'Европа, Вильнюс',
  'Europe/Volgograd': 'Европа, Волгоград',
  'Europe/Warsaw': 'Европа, Варшава',
  'Europe/Zagreb': 'Европа, Загреб',
  'Europe/Zaporozhye': 'Европа, Запорожье',
  'Europe/Zurich': 'Европа, Цюрих',
  GB: 'Великобритания',
  'GB-Eire': 'Великобритания-Ирландия',
  Greenwich: 'Гринвич',
  HST: 'Гавайское стандартное время',
  Hongkong: 'Гонконг',
  Iceland: 'Исландия',
  'Indian/Antananarivo': 'Индийский, Антананариву',
  'Indian/Chagos': 'Индийский, Чагос',
  'Indian/Christmas': 'Индийский, Рождество',
  'Indian/Cocos': 'Индийский, Кокосы',
  'Indian/Comoro': 'Индийский, Коморы',
  'Indian/Kerguelen': 'Индийский, Кергелен',
  'Indian/Mahe': 'Индийский, Махе',
  'Indian/Maldives': 'Индийский, Мальдивы',
  'Indian/Mauritius': 'Индийский, Маврикий',
  'Indian/Mayotte': 'Индийский, Майотта',
  'Indian/Reunion': 'Индийский, Реюньон',
  Iran: 'Иран',
  Israel: 'Израиль',
  Jamaica: 'Ямайка',
  Japan: 'Япония',
  Kwajalein: 'Кваджалейн',
  Libya: 'Ливия',
  MET: 'Ближневосточное время',
  MST: 'Горное стандартное время',
  MST7MDT: 'Горное стандартное время / Горное летнее время',
  'Mexico/BajaNorte': 'Мексика, Баха Норте',
  'Mexico/BajaSur': 'Мексика, Баха Сур',
  'Mexico/General': 'Мексика, Общий',
  NZ: 'Новая Зеландия',
  'NZ-CHAT': 'Время островов Чатем',
  Navajo: 'Навахо',
  PRC: 'КНР',
  PST8PDT: 'Тихоокеанское стандартное время / Тихоокеанское летнее время',
  'Pacific/Apia': 'Тихоокеанский, Апиа',
  'Pacific/Auckland': 'Тихоокеанский, Окленд',
  'Pacific/Bougainville': 'Тихоокеанский, Бугенвиль',
  'Pacific/Chatham': 'Тихоокеанский, Чатем',
  'Pacific/Easter': 'Тихоокеанский, Пасха',
  'Pacific/Efate': 'Тихоокеанский, Эфате',
  'Pacific/Enderbury': 'Тихоокеанский, Эндербери',
  'Pacific/Fakaofo': 'Тихоокеанский, Факаофо',
  'Pacific/Fiji': 'Тихоокеанский, Фиджи',
  'Pacific/Funafuti': 'Тихоокеанский, Фунафути',
  'Pacific/Galapagos': 'Тихоокеанский, Галапагосы',
  'Pacific/Gambier': 'Тихоокеанский, Гамбье',
  'Pacific/Guadalcanal': 'Тихоокеанский, Гуадалканал',
  'Pacific/Guam': 'Тихоокеанский, Гуам',
  'Pacific/Honolulu': 'Тихоокеанский, Гонолулу',
  'Pacific/Johnston': 'Тихоокеанский, Джонстон',
  'Pacific/Kanton': 'Тихоокеанский, Кантон',
  'Pacific/Kiritimati': 'Тихоокеанский, Киритимати',
  'Pacific/Kosrae': 'Тихоокеанский, Косраэ',
  'Pacific/Kwajalein': 'Тихоокеанский, Кваджалейн',
  'Pacific/Majuro': 'Тихоокеанский, Маджуро',
  'Pacific/Marquesas': 'Тихоокеанский, Маркизские острова',
  'Pacific/Midway': 'Тихоокеанский, Мидуэй',
  'Pacific/Nauru': 'Тихоокеанский, Науру',
  'Pacific/Niue': 'Тихоокеанский, Ниуэ',
  'Pacific/Norfolk': 'Тихоокеанский, Норфолк',
  'Pacific/Noumea': 'Тихоокеанский, Нумеа',
  'Pacific/Pago_Pago': 'Тихоокеанский, Панго-Панго',
  'Pacific/Palau': 'Тихоокеанский, Палау',
  'Pacific/Pitcairn': 'Тихоокеанский, Питкэрн',
  'Pacific/Pohnpei': 'Тихоокеанский, Понпеи',
  'Pacific/Port_Moresby': 'Тихоокеанский, Порт-Морсби',
  'Pacific/Rarotonga': 'Тихоокеанский, Раротонга',
  'Pacific/Saipan': 'Тихоокеанский, Сайпан',
  'Pacific/Samoa': 'Тихоокеанский, Самоа',
  'Pacific/Tahiti': 'Тихоокеанский, Таити',
  'Pacific/Tarawa': 'Тихоокеанский, Тарава',
  'Pacific/Tongatapu': 'Тихоокеанский, Тонгатапу',
  'Pacific/Truk': 'Тихоокеанский, Чуук',
  'Pacific/Wake': 'Тихоокеанский, Уэйк',
  'Pacific/Wallis': 'Тихоокеанский, Уоллис',
  'Pacific/Yap': 'Тихоокеанский, Яп',
  Poland: 'Польша',
  Portugal: 'Португалия',
  ROC: 'Тайвань',
  ROK: 'Южная Корея',
  Singapore: 'Сингапур',
  Turkey: 'Турция',
  'US/Alaska': 'США, Аляска',
  'US/Aleutian': 'США, Алеутские острова',
  'US/Arizona': 'США, Аризона',
  'US/Central': 'США, Центральный',
  'US/East-Indiana': 'США, Восточная Индиана',
  'US/Eastern': 'США, Восток',
  'US/Hawaii': 'США, Гавайи',
  'US/Indiana-Starke': 'США, Индиана-Старк',
  'US/Michigan': 'США, Мичиган',
  'US/Mountain': 'США, Горы',
  'US/Pacific': 'США, Тихий океан',
  'US/Samoa': 'США, Самоа',
  WET: 'Западноевропейское время',
  Zulu: 'Зулу',
} as const;
