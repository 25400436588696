import React from 'react';

import clsx from 'clsx';

import {
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { formatTime, secondsToMinutes } from '~/services/dateTime';

import { formatPenGroup } from '~/entities/penGroups';

import { MilkingParlorGeneralReportGroupedRowEntryFragment } from '../../gql/fragments/milkingParlorGeneralReportGroupedRowEntry.graphql';
import { MilkingParlorGeneralReportRowEntryFragment } from '../../gql/fragments/milkingParlorGeneralReportRowEntry.graphql';

type TableRow =
  | MilkingParlorGeneralReportRowEntryFragment
  | MilkingParlorGeneralReportGroupedRowEntryFragment
  | SkeletonPlaceholder;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking report rows to display
   */
  milkingParlorGeneralReportRows: TableRow[];
}

const isGroupedReportRow = (
  row: TableRow
): row is MilkingParlorGeneralReportGroupedRowEntryFragment =>
  row.__typename === 'MilkingParlorGeneralReportGroupedRowEntry';

const PEN_GROUP_COLUMN_WIDTH_PX = 156;
const OTHER_COLUMN_WIDTH_PX = 84;

const BASE_REPORT_COLUMN_PROPS = {
  columnClassName: 'text-right',
  cellTypographyProps: {
    skeletonProps: {
      size: TextSkeletonSizes.small,
    },
  },
  width: OTHER_COLUMN_WIDTH_PX,
};

export const MilkingGeneralReportTable: React.FC<Props> = ({
  className,
  milkingParlorGeneralReportRows,
}) => {
  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      key: 'penGroup',
      title: 'Группа',
      renderCellContent: row => {
        if (isGroupedReportRow(row)) {
          return 'Итого';
        }
        return formatPenGroup(row.penGroup);
      },
      width: PEN_GROUP_COLUMN_WIDTH_PX,
    },
    {
      key: 'milkTotal',
      title: 'Надой, кг',
      renderCellContent: row => formatInt(row.milk?.total),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkPerHour',
      title: (
        <>
          Надой,
          <br />
          кг/ч
        </>
      ),
      renderCellContent: row => formatInt(row.milk?.perHour),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkPerCows',
      title: 'Надой на гол., кг',
      renderCellContent: row => formatNumber(row.milk?.perCows, 1),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'cowsTotal',
      title: 'Число голов',
      renderCellContent: row => formatInt(row.cows?.total),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'cowsPerHour',
      title: 'Голов в час',
      renderCellContent: row => formatInt(row.cows?.perHour),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkingDuration',
      title: 'Время доения груп., мин',
      renderCellContent: row =>
        formatInt(secondsToMinutes(row.milkingTime?.durationSec)),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkingStart',
      title: 'Начало доения',
      renderCellContent: row => formatTime(row.milkingTime?.start),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkingStop',
      title: 'Конец доения',
      renderCellContent: row => formatTime(row.milkingTime?.stop),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkAvgFlow',
      title: 'Надой на голову, кг/мин',
      renderCellContent: row => formatNumber(row.milk?.avgFlow, 1),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkingTimeAvg',
      title: 'Время доения на гол., мин',
      renderCellContent: row =>
        formatNumber(secondsToMinutes(row.milkingTime?.avgSec), 1),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'notIdentifiedCount',
      title: 'Не идент. голов',
      renderCellContent: row => formatInt(row.notIdentifiedCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
  ];

  return (
    <Table<TableRow>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        getItemKey: (item, index) =>
          isGroupedReportRow(item) ? 'total' : (item.penGroup?.id ?? index),
        items: milkingParlorGeneralReportRows,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
