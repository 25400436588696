import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { isCompanyDetailed } from '../helpers';
import { AnyCompanyFragment } from '../types';
import { useCompaniesDetailedPaginatedQuery } from './useCompaniesDetailedPaginatedQuery';

/**
 * Gets a company from query, if passed company is not detailed
 */
export const useDetailedCompany = (
  companyOrId?: AnyCompanyFragment | string
) => {
  const isIdPassed = typeof companyOrId === 'string';
  const isDetailedCompanyPassed = !isIdPassed && isCompanyDetailed(companyOrId);

  const companyId = isIdPassed ? companyOrId : companyOrId?.id;

  const {
    items: companiesDetailedItems,
    itemsPromise: companiesDetailedItemsPromise,
    isLoading: isDetailedCompanyLoading,
  } = useCompaniesDetailedPaginatedQuery({
    variables: { ids: normalizeToArrayOrUndefined(companyId) },
    skip: !companyId || isDetailedCompanyPassed,
  });

  const companyDetailed = isDetailedCompanyPassed
    ? companyOrId
    : companiesDetailedItems.at(0);

  const companyDetailedPromise = isDetailedCompanyPassed
    ? Promise.resolve(companyOrId)
    : companiesDetailedItemsPromise.then(res => res.at(0));

  return {
    isDetailedCompanyLoading,
    companyDetailed,
    companyDetailedPromise,
  };
};
