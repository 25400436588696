import React, { useCallback, useEffect, useState } from 'react';

import { ListItem } from '~/~legacy/types/keyValue';

import './MToggleButtons.scss';

interface Props {
  items: ListItem<any>[];
  selectedValue?: any;
  className?: string;

  onChange: (value: any) => void;
}

export const MToggleButtons: React.FC<Props> = props => {
  const { items, selectedValue, className, onChange } = props;

  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  const clickCallback = useCallback(
    (item: ListItem) => {
      setValue(item.value);
      onChange(item.value);
    },
    [setValue, onChange]
  );

  const components = items.map(item => (
    <button
      type="button"
      key={item.value || null}
      onClick={() => {
        clickCallback(item);
      }}
      className={`m-toggle-buttons-item ${
        item.value === value ? 'selected' : ''
      }`}
    >
      {item.content}
    </button>
  ));

  return (
    <div className={`m-toggle-buttons-container ${className || ''}`}>
      {components}
    </div>
  );
};
