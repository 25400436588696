import React from 'react';

import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';

import customScrollStyles from '~/styles/modules/customScroll.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { getCowFieldId } from '../../helpers';
import { useCowPinnedFields } from '../../hooks';
import { CowField } from '../CowField';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Cow to render
   */
  cow: CowDetailedFragment;
}

export const CowPinnedFields: React.FC<Props> = ({ className, cow }) => {
  const { pinnedFields } = useCowPinnedFields(cow);

  return (
    <div
      className={clsx(
        panelStyles.largeDarkPanel,
        customScrollStyles.customScrollContainer,
        'p-16 flex flex-col gap-8 overflow-auto',
        className
      )}
    >
      <Typography className="mb-4" variant={TypographyVariants.heading4}>
        Избранное
      </Typography>
      {pinnedFields.map(field => (
        <CowField
          key={getCowFieldId(field)}
          {...{
            className: 'px-12 py-6',
            field,
            cow,
          }}
        />
      ))}
    </div>
  );
};
