import React from 'react';

import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';
import { usePolling } from '~/shared/hooks/usePolling';

import { useArkaNavigation } from '~/services/navigation';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { useCompaniesShortQuery } from '../../gql/queries/companiesShort.graphql';
import styles from './index.module.scss';

export const CompanyLoadingBlocker: React.FC = () => {
  const { selectedCompanyId } = useArkaNavigation();

  const { refetch } = useCompaniesShortQuery({
    variables: { ids: normalizeToArrayOrUndefined(selectedCompanyId) },
    skip: !selectedCompanyId,
  });

  usePolling({
    pollData: refetch,
    getShouldStop: ({ data }) => !data.companies.nodes.at(0)?.isLockedForWrite,
  });

  return (
    <div
      className={clsx(
        layoutStyles.fullHeightContainer,
        layoutStyles.limitedContainer,
        contentGridStyles.contentGrid,
        'items-center'
      )}
    >
      <div
        className={clsx(
          styles.card,
          'grid place-items-center p-24',
          panelStyles.largePanel,
          contentGridStyles.from4to6ColumnsCenteredBlock
        )}
      >
        <DataBlockedMessage
          {...{
            isLarge: true,
            isLoading: true,
            message: 'Загрузка файлов... Пожалуйста, подождите',
            description:
              'Интерфейс компании будет недоступен до завершения загрузки',
          }}
        />
      </div>
    </div>
  );
};
