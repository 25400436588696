import * as yup from 'yup';

import {
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

interface Props {
  /**
   * Called, when the form is submitted
   */
  onSubmit: (form: SelectTestMilkingFileFormType) => void;
  /**
   * Default values for the form
   */
  defaultValues?: Partial<SelectTestMilkingFileFormType>;
}

const FORM_ID = 'SelectTestMilkingFileForm';

export const SELECT_TEST_MILKING_FILE_FORM_SCHEMA = yup.object({
  file: yup.mixed().required(), // Upload!
  happenedAt: yup.string().default('').required(), // Date!
});

/**
 * Form for selecting test milking file
 */
export type SelectTestMilkingFileFormType = InferSchemaWithDefaults<
  typeof SELECT_TEST_MILKING_FILE_FORM_SCHEMA
>;
type SelectTestMilkingFileFormTransformedType = InferValidatedSchema<
  typeof SELECT_TEST_MILKING_FILE_FORM_SCHEMA
>;

export const useSelectTestMilkingFileForm = ({
  onSubmit: handleSubmit,
  defaultValues,
}: Props): UseModalStepFormInterface<SelectTestMilkingFileFormType> => {
  const formContext = useForm<
    SelectTestMilkingFileFormType,
    SelectTestMilkingFileFormTransformedType
  >({
    schema: SELECT_TEST_MILKING_FILE_FORM_SCHEMA,
    defaultValues: {
      ...SELECT_TEST_MILKING_FILE_FORM_SCHEMA.getDefault(),
      ...defaultValues,
    },
  });

  return {
    formId: FORM_ID,
    formContext,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
