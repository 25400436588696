import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorMixedMilkingReportRowEntryFragmentDoc } from '../fragments/milkingParlorMixedMilkingReportRowEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorMixedMilkingReportRowsQueryVariables = Types.Exact<{
  milkingDate: Types.Scalars['Date']['input'];
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingParlorIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MilkingParlorMixedMilkingReportRowsQuery = { __typename?: 'Query', milkingParlorMixedMilkingReportRows: Array<{ __typename: 'MilkingParlorMixedMilkingReportRowEntry', id: string, stallNumber: string, attachTime: string, expectedPenGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number }, actualPenGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number }, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } }> };


export const MilkingParlorMixedMilkingReportRowsDocument = gql`
    query milkingParlorMixedMilkingReportRows($milkingDate: Date!, $farmIDs: [ID!], $milkingParlorIDs: [ID!], $milkingNumbers: [Int!]) {
  milkingParlorMixedMilkingReportRows(
    milkingDate: $milkingDate
    farmIDs: $farmIDs
    milkingParlorIDs: $milkingParlorIDs
    milkingNumbers: $milkingNumbers
  ) {
    ...MilkingParlorMixedMilkingReportRowEntry
  }
}
    ${MilkingParlorMixedMilkingReportRowEntryFragmentDoc}`;

/**
 * __useMilkingParlorMixedMilkingReportRowsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorMixedMilkingReportRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorMixedMilkingReportRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorMixedMilkingReportRowsQuery({
 *   variables: {
 *      milkingDate: // value for 'milkingDate'
 *      farmIDs: // value for 'farmIDs'
 *      milkingParlorIDs: // value for 'milkingParlorIDs'
 *      milkingNumbers: // value for 'milkingNumbers'
 *   },
 * });
 */
export function useMilkingParlorMixedMilkingReportRowsQuery(baseOptions: Apollo.QueryHookOptions<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables> & ({ variables: MilkingParlorMixedMilkingReportRowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables>(MilkingParlorMixedMilkingReportRowsDocument, options);
      }
export function useMilkingParlorMixedMilkingReportRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables>(MilkingParlorMixedMilkingReportRowsDocument, options);
        }
export function useMilkingParlorMixedMilkingReportRowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables>(MilkingParlorMixedMilkingReportRowsDocument, options);
        }
export type MilkingParlorMixedMilkingReportRowsQueryHookResult = ReturnType<typeof useMilkingParlorMixedMilkingReportRowsQuery>;
export type MilkingParlorMixedMilkingReportRowsLazyQueryHookResult = ReturnType<typeof useMilkingParlorMixedMilkingReportRowsLazyQuery>;
export type MilkingParlorMixedMilkingReportRowsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorMixedMilkingReportRowsSuspenseQuery>;
export type MilkingParlorMixedMilkingReportRowsQueryResult = Apollo.QueryResult<MilkingParlorMixedMilkingReportRowsQuery, MilkingParlorMixedMilkingReportRowsQueryVariables>;