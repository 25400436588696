import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupDetailedFragmentDoc } from '../fragments/penGroupDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PenGroupsDetailedQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  isMilking?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isDry?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isHospital?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isCalf?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isArtificialInsemination?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isWithBull?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isUser?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type PenGroupsDetailedQuery = { __typename?: 'Query', penGroups: { __typename?: 'PenGroupConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes: Array<{ __typename: 'PenGroup', id: string, identifier: number, name: string, isMilking: boolean, isDry: boolean, isHospital: boolean, isCalf: boolean, isArtificialInsemination: boolean, isWithBull: boolean, isUser: boolean, capacity?: number | null, occupied: number, occupiedPercent?: number | null, remaining?: number | null, cows: Array<{ __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }>, farm: { __typename: 'Farm', id: string, name: string, number: string } }> } };


export const PenGroupsDetailedDocument = gql`
    query penGroupsDetailed($first: Int, $search: String, $after: String, $ids: [ID!], $isMilking: Boolean, $isDry: Boolean, $isHospital: Boolean, $isCalf: Boolean, $isArtificialInsemination: Boolean, $isWithBull: Boolean, $isUser: Boolean) {
  penGroups(
    first: $first
    after: $after
    ids: $ids
    search: $search
    isMilking: $isMilking
    isDry: $isDry
    isHospital: $isHospital
    isCalf: $isCalf
    isArtificialInsemination: $isArtificialInsemination
    isWithBull: $isWithBull
    isUser: $isUser
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...PenGroupDetailed
    }
  }
}
    ${PenGroupDetailedFragmentDoc}`;

/**
 * __usePenGroupsDetailedQuery__
 *
 * To run a query within a React component, call `usePenGroupsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePenGroupsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePenGroupsDetailedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      after: // value for 'after'
 *      ids: // value for 'ids'
 *      isMilking: // value for 'isMilking'
 *      isDry: // value for 'isDry'
 *      isHospital: // value for 'isHospital'
 *      isCalf: // value for 'isCalf'
 *      isArtificialInsemination: // value for 'isArtificialInsemination'
 *      isWithBull: // value for 'isWithBull'
 *      isUser: // value for 'isUser'
 *   },
 * });
 */
export function usePenGroupsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>(PenGroupsDetailedDocument, options);
      }
export function usePenGroupsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>(PenGroupsDetailedDocument, options);
        }
export function usePenGroupsDetailedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>(PenGroupsDetailedDocument, options);
        }
export type PenGroupsDetailedQueryHookResult = ReturnType<typeof usePenGroupsDetailedQuery>;
export type PenGroupsDetailedLazyQueryHookResult = ReturnType<typeof usePenGroupsDetailedLazyQuery>;
export type PenGroupsDetailedSuspenseQueryHookResult = ReturnType<typeof usePenGroupsDetailedSuspenseQuery>;
export type PenGroupsDetailedQueryResult = Apollo.QueryResult<PenGroupsDetailedQuery, PenGroupsDetailedQueryVariables>;