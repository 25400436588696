import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { ProtocolFragment } from '../gql/fragments/protocol.graphql';
import { useUpdateProtocolMutation } from '../gql/mutations/updateProtocol.graphql';
import { readProtocolFragment } from '../helpers';

/**
 * Props for editing protocol third step
 */
interface Props {
  /**
   * Protocol to edit
   */
  protocol: ProtocolFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (editedProtocol?: ProtocolFragment) => void;
}

const FORM_ID = 'EditProtocolDaysUntilCheckForm';

const SCHEMA = yup.object({
  daysUntilCheckAfterLastThreatment: yup.number().nullable().default(null),
});

/**
 * Form for editing protocol third step
 */
export type EditProtocolDaysUntilCheckFormType = InferValidatedSchema<
  typeof SCHEMA
>;

export const useProtocolDaysUntilCheckForm = ({
  protocol,
  onSubmit,
}: Props): UseModalStepFormInterface<EditProtocolDaysUntilCheckFormType> => {
  const [isLoading, setLoading] = useState(false);

  const [updateProtocol, { client }] = useUpdateProtocolMutation();

  const formContext = useForm<EditProtocolDaysUntilCheckFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(['daysUntilCheckAfterLastThreatment'], protocol ?? {}),
    },
  });

  const handleSubmit = async (input: EditProtocolDaysUntilCheckFormType) => {
    setLoading(true);

    try {
      if (
        input.daysUntilCheckAfterLastThreatment !==
        protocol.daysUntilCheckAfterLastThreatment
      ) {
        await updateProtocol({
          variables: { id: protocol.id, input },
          refetchQueries: ['events'],
          awaitRefetchQueries: true,
        });
      }

      const editedProtocol =
        readProtocolFragment(client, protocol.id) ?? undefined;

      onSubmit?.(editedProtocol);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
