import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportCowPlannedInjectionsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ExportCowPlannedInjectionsMutation = { __typename?: 'Mutation', exportCowPlannedInjections: { __typename?: 'File', downloadUrl: string, name: string, extension: string } };


export const ExportCowPlannedInjectionsDocument = gql`
    mutation exportCowPlannedInjections {
  exportCowPlannedInjections {
    downloadUrl
    name
    extension
  }
}
    `;
export type ExportCowPlannedInjectionsMutationFn = Apollo.MutationFunction<ExportCowPlannedInjectionsMutation, ExportCowPlannedInjectionsMutationVariables>;

/**
 * __useExportCowPlannedInjectionsMutation__
 *
 * To run a mutation, you first call `useExportCowPlannedInjectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCowPlannedInjectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCowPlannedInjectionsMutation, { data, loading, error }] = useExportCowPlannedInjectionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportCowPlannedInjectionsMutation(baseOptions?: Apollo.MutationHookOptions<ExportCowPlannedInjectionsMutation, ExportCowPlannedInjectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCowPlannedInjectionsMutation, ExportCowPlannedInjectionsMutationVariables>(ExportCowPlannedInjectionsDocument, options);
      }
export type ExportCowPlannedInjectionsMutationHookResult = ReturnType<typeof useExportCowPlannedInjectionsMutation>;
export type ExportCowPlannedInjectionsMutationResult = Apollo.MutationResult<ExportCowPlannedInjectionsMutation>;
export type ExportCowPlannedInjectionsMutationOptions = Apollo.BaseMutationOptions<ExportCowPlannedInjectionsMutation, ExportCowPlannedInjectionsMutationVariables>;