import {
  ChartOptions,
  ChartType,
  ScaleOptions,
  ScaleType,
  ScriptableLineSegmentContext,
} from 'chart.js';
import R from 'ramda';

import { isNilOrNaN } from '~/shared/helpers/number';
import { Falsy } from '~/shared/types/utility';

import { BarChartDatasetConfig, LineChartDatasetConfig } from './components';
import {
  GLOBAL_CHART_OPTIONS,
  GLOBAL_SCALE_OPTIONS,
  MISSING_SEGMENTS_DASH,
} from './constants';
import { AnyBasicChartDataPoints } from './types';

/**
 * Combines several chart options to generate a complete options config
 */
export const getChartOptions = <TType extends ChartType = ChartType>(
  ...optionsArray: (ChartOptions<ChartType> | Falsy)[]
) => {
  const optionsToMerge: ChartOptions<ChartType>[] = [
    GLOBAL_CHART_OPTIONS as ChartOptions<ChartType>,
    ...optionsArray.filter(Boolean),
  ];
  return optionsToMerge.reduce((acc, options) => {
    return R.mergeDeepRight<object, object>(
      acc as object,
      R.clone(options) as object
    ) as ChartOptions<TType>;
  }, {} as ChartOptions<TType>);
};

/**
 * Combines several scales options to generate a scale config
 */
export const getScaleOptions = <TScale extends ScaleType = ScaleType>(
  ...optionsArray: (ScaleOptions<ScaleType> | Falsy)[]
) => {
  const optionsToMerge: ScaleOptions<ScaleType>[] = [
    GLOBAL_SCALE_OPTIONS as ScaleOptions<ScaleType>,
    ...optionsArray.filter(Boolean),
  ];
  return optionsToMerge.reduce((acc, options) => {
    return R.mergeDeepRight<object, object>(
      acc as object,
      R.clone(options) as object
    ) as ScaleOptions<TScale>;
  }, {} as ScaleOptions<TScale>);
};

/**
 * Gets number value of the chart data point
 */
export const getDataPointValue = (
  dataPoint?: AnyBasicChartDataPoints[number]
) => (typeof dataPoint === 'number' ? dataPoint : dataPoint?.y);

/**
 * Checks, if a segment of a chart has no data and should be displayed with dash
 */
const isSegmentSkipped = (ctx: ScriptableLineSegmentContext) =>
  ctx.p0.skip || ctx.p1.skip;

/**
 * Makes an object with default dataset options for line or bar charts
 */
export const makeDefaultDatasetConfig = <
  DatasetConfig extends BarChartDatasetConfig | LineChartDatasetConfig,
>(
  dataset: DatasetConfig
) =>
  ({
    borderColor: dataset.color,
    backgroundColor: dataset.color,
    hoverBorderColor: dataset.hoverColor,
    hoverBackgroundColor: dataset.hoverColor,
    spanGaps: true,
    pointStyle: ctx => {
      const leftPoint = dataset.data.at(ctx.dataIndex - 1);
      const leftPointValue = getDataPointValue(leftPoint);
      const rightPoint = dataset.data.at(ctx.dataIndex + 1);
      const rightPointValue = getDataPointValue(rightPoint);

      return isNilOrNaN(leftPointValue) && isNilOrNaN(rightPointValue)
        ? 'circle'
        : false;
    },
    segment: {
      borderColor: ctx =>
        isSegmentSkipped(ctx) ? dataset.skippedColor : undefined,
      borderDash: ctx =>
        isSegmentSkipped(ctx) ? MISSING_SEGMENTS_DASH : undefined,
    },
  }) satisfies Partial<LineChartDatasetConfig>;
