import {
  RoleFragment,
  RoleFragmentDoc,
} from '~/services/auth/gql/fragments/role.graphql';
import {
  RoleDetailedFragment,
  RoleDetailedFragmentDoc,
} from '~/services/auth/gql/fragments/roleDetailed.graphql';
import { makeReadFragment, makeUpdateFragment } from '~/services/gql';

/**
 * Reads blueprint role fragment from cache by id
 */
export const readRoleFragment = makeReadFragment<RoleFragment>({
  typeName: 'BlueprintRole',
  fragment: RoleFragmentDoc,
});

/**
 * Reads blueprint role detailed fragment from cache by id
 */
export const readRoleDetailedFragment = makeReadFragment<RoleDetailedFragment>({
  typeName: 'BlueprintRole',
  fragment: RoleDetailedFragmentDoc,
});

/**
 * Update blueprint role in the cache
 */
export const updateRoleFragment = makeUpdateFragment<RoleFragment>({
  typeName: 'BlueprintRole',
  fragment: RoleFragmentDoc,
});
