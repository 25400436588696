import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { ListItem } from '~/~legacy/types/keyValue';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { MToggleButtons } from '~/shared/components/MToggleButtons';
import { NodeValueKindEnum } from '~/shared/graphql';

interface Props {
  className?: string;

  value?: NodeValueKindEnum | null;
  onChange: (valueKind: NodeValueKindEnum) => void;
}

export const NodeValueKindButtons: React.FC<Props> = ({
  className,
  value,
  onChange,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const valueKindItems = useMemo(() => {
    const items: ListItem<NodeValueKindEnum>[] = [
      {
        value: NodeValueKindEnum.Hardcode,
        content: t(IntegratorStrings.blueprintEdit.valueKindButtons.hardcode),
      },
      {
        value: NodeValueKindEnum.Variable,
        content: t(IntegratorStrings.blueprintEdit.valueKindButtons.variable),
      },
    ];
    return items;
  }, [t]);

  return (
    <MToggleButtons
      className={className}
      items={valueKindItems}
      selectedValue={value}
      onChange={onChange}
    />
  );
};
