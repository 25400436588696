import { AppLocalStorageKeys } from '~/shared/constants';

import { router } from '../../routing';

/**
 * Receives current selected company id, first trying to match it in the router,
 * if not successful, gets from localStorage
 */
export const getSelectedCompanyId = () => {
  const matchedCompanyId = router.matchRoute(
    { to: '/$companyId' },
    { fuzzy: true }
  );

  const companyIdParam = (matchedCompanyId || {}).companyId;

  const routerCompanyId =
    companyIdParam && !Number.isNaN(+companyIdParam)
      ? companyIdParam
      : undefined;
  return (
    routerCompanyId ??
    localStorage.getItem(AppLocalStorageKeys.selectedCompanyId) ??
    ''
  );
};
