import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { SpecialConstants } from '~/~legacy/types/specialConstants';

import TOKENS from '~/styles/__generated__/tokens.json';

import {
  BlueprintActionNode,
  BlueprintArgument,
  BlueprintArgumentType,
  BlueprintNodeType,
  BlueprintReadOnlyArgument,
  BlueprintWritableArgument,
} from '../../../../services';
import { ArgumentNode } from '../../../ArgumentNode';
import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';
import { ReadOnlyArgumentNode } from '../ReadOnlyArgumentNode';

const argumentsMap = {
  [BlueprintArgumentType.WritableArgument]: (
    arg: BlueprintWritableArgument
  ) => (
    <ArgumentNode
      key={arg.id}
      argument={arg}
      parentNodeType={BlueprintNodeType.Action}
    />
  ),
  [BlueprintArgumentType.ReadOnlyArgument]: (
    arg: BlueprintReadOnlyArgument,
    index
  ) => <ReadOnlyArgumentNode key={arg.name + index} argument={arg} />,
  [SpecialConstants.Unknown]: arg => {
    const { t } = useTranslation(MaslovNamespaces.integrator);
    return (
      <>
        {t(IntegratorStrings.blueprintEdit.nodes.actionNode.unknownArgument)} [
        {arg.type}]
      </>
    );
  },
} as Record<
  BlueprintArgumentType | SpecialConstants.Unknown,
  (arg: BlueprintArgument, index: number) => ReactElement
>;

interface Props extends ApiFeedbackProps {
  action: BlueprintActionNode;

  delete: () => void;
}

export const ActionNodeUI: React.FC<Props> = props => {
  const { delete: delFunc, errors, loading, action } = props;

  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const argumentsItems = useMemo(() => {
    return action.arguments.map((argument, index) => {
      const getComponent =
        argumentsMap[argument.type || SpecialConstants.Unknown];
      return getComponent(argument, index);
    });
  }, [action.arguments]);

  return (
    <NodeFrame
      headerStyle={{ background: TOKENS.colorAccent150 }}
      header={
        <NodeHeader
          title={t(IntegratorStrings.blueprintEdit.nodes.actionNode.title)}
          delete={delFunc}
        />
      }
    >
      <div className="p-8">
        <p>{action.kind}</p>
        {argumentsItems}
        {errorMessage}
        {loader}
      </div>
    </NodeFrame>
  );
};
