import { useCallback, useRef, useState } from 'react';

import { useResizeObserver } from '@react-aria/utils';

interface Size {
  width: number;
  height: number;
}

/**
 * Hook for reactive elements size measurement
 */
export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [
  React.RefObject<T>,
  Size,
] => {
  const ref = useRef<T>(null);

  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useResizeObserver({
    ref,
    onResize: useCallback(() => {
      setSize({
        width: ref.current?.offsetWidth ?? 0,
        height: ref.current?.offsetHeight ?? 0,
      });
    }, []),
  });

  return [ref, size];
};
