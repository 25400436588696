import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BullFragmentDoc } from '../../../bulls/gql/fragments/bull.graphql';
import { EmployeeShortFragmentDoc } from '../../../employees/gql/fragments/employeeShort.graphql';
import { InseminationSchemeFragmentDoc } from '../../../events/gql/fragments/inseminationScheme.graphql';
export type ReproductionCrValue_ReproductionCrDateValue_Fragment = { __typename: 'ReproductionCrDateValue', since?: string | null, till?: string | null };

export type ReproductionCrValue_Bull_Fragment = { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null };

export type ReproductionCrValue_Employee_Fragment = { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string, number: string };

export type ReproductionCrValue_InseminationScheme_Fragment = { __typename: 'InseminationScheme', id: string, name: string, kind: Types.EventKindEnum, code: string, description: string };

export type ReproductionCrValue_ReproductionCrInseminationNumberValue_Fragment = { __typename: 'ReproductionCrInseminationNumberValue', inseminationNumber?: number | null };

export type ReproductionCrValue_SemenSupplier_Fragment = { __typename: 'SemenSupplier', name: string };

export type ReproductionCrValue_ReproductionCrIntervalBetweenInseminationsValue_Fragment = { __typename: 'ReproductionCrIntervalBetweenInseminationsValue', periodStart?: number | null, periodEnd?: number | null };

export type ReproductionCrValue_ReproductionCrRowByDowsValue_Fragment = { __typename: 'ReproductionCrRowByDowsValue', dow?: Types.Dow | null };

export type ReproductionCrValue_ReproductionCrRowByAnimalCycleValue_Fragment = { __typename: 'ReproductionCrRowByAnimalCycleValue', periodStart?: number | null, periodEnd?: number | null };

export type ReproductionCrValue_Breed_Fragment = { __typename: 'Breed', name: string };

export type ReproductionCrValueFragment = ReproductionCrValue_ReproductionCrDateValue_Fragment | ReproductionCrValue_Bull_Fragment | ReproductionCrValue_Employee_Fragment | ReproductionCrValue_InseminationScheme_Fragment | ReproductionCrValue_ReproductionCrInseminationNumberValue_Fragment | ReproductionCrValue_SemenSupplier_Fragment | ReproductionCrValue_ReproductionCrIntervalBetweenInseminationsValue_Fragment | ReproductionCrValue_ReproductionCrRowByDowsValue_Fragment | ReproductionCrValue_ReproductionCrRowByAnimalCycleValue_Fragment | ReproductionCrValue_Breed_Fragment;

export const ReproductionCrValueFragmentDoc = gql`
    fragment ReproductionCrValue on ReproductionCrValue {
  __typename
  ... on ReproductionCrDateValue {
    since
    till
  }
  ... on Bull {
    ...Bull
  }
  ... on Employee {
    ...EmployeeShort
  }
  ... on InseminationScheme {
    ...InseminationScheme
  }
  ... on ReproductionCrInseminationNumberValue {
    inseminationNumber
  }
  ... on SemenSupplier {
    __typename
    name
  }
  ... on ReproductionCrIntervalBetweenInseminationsValue {
    periodStart
    periodEnd
  }
  ... on ReproductionCrRowByDowsValue {
    dow
  }
  ... on ReproductionCrRowByAnimalCycleValue {
    periodStart
    periodEnd
  }
  ... on Breed {
    __typename
    name
  }
}
    ${BullFragmentDoc}
${EmployeeShortFragmentDoc}
${InseminationSchemeFragmentDoc}`;