import { InferValidatedSchema } from '~/services/forms';

import { CowNormalizedEvent } from '~/entities/cowEvents';

import { COW_CHART_SETTINGS_SCHEMA } from './constants';

/**
 * Cow chart has different line configs for different data fields
 */
export enum CowChartLineValueTypes {
  milkWeightKilograms = 'milkWeightKilograms',
  fatPercent = 'fatPercent',
  proteinPercent = 'proteinPercent',
  sccThousandsPerMl = 'sccThousandsPerMl',
}

/**
 * Config values for a data field
 */
export interface CowChartLineValueTypeConfig {
  color: string;
  name: string;
}

/**
 * Cow chart has additional config to a line, based on the source of the data
 */
export enum CowChartLineDataSources {
  milk = 'milk',
  testMilk = 'testMilk',
  predictedMilk = 'predictedMilk',
}

/**
 * Settings for cow chart from settings modal
 */
export type CowChartSettings = InferValidatedSchema<
  typeof COW_CHART_SETTINGS_SCHEMA
>;

/**
 * Data for a line cow chart
 */
export interface CowChartData {
  x: number;
  y: number;
  happenedAt: string;
  lactationNumber: number;
}

/**
 * Special data type for cow chart events
 */
export interface CowChartEventData
  extends Omit<CowChartData, 'lactationNumber'> {
  r: number;
  backgroundColor: string;
  event: CowNormalizedEvent;
}
