import React from 'react';

export const HerriotDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.5303 9.53009C15.8232 9.23719 15.8232 8.76232 15.5303 8.46943C15.2374 8.17653 14.7626 8.17653 14.4697 8.46943L12 10.9391L9.53033 8.46943C9.23744 8.17653 8.76256 8.17653 8.46967 8.46943C8.17678 8.76232 8.17678 9.23719 8.46967 9.53009L10.9393 11.9998L8.46967 14.4694C8.17678 14.7623 8.17678 15.2372 8.46967 15.5301C8.76256 15.823 9.23744 15.823 9.53033 15.5301L12 13.0604L14.4697 15.5301C14.7626 15.823 15.2374 15.823 15.5303 15.5301C15.8232 15.2372 15.8232 14.7623 15.5303 14.4694L13.0607 11.9998L15.5303 9.53009Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6855 1.78329L10.6869 1.78264C11.501 1.40708 12.4991 1.40709 13.3132 1.78266L13.3145 1.78329L20.3145 4.98394C21.1228 5.35736 21.7488 6.13114 21.75 7.04813L21.75 16.9514C21.7488 17.8683 21.1228 18.6421 20.3145 19.0155L13.3145 22.2162L13.3132 22.2168C12.4991 22.5924 11.501 22.5924 10.6869 22.2169L10.6855 22.2162L3.68813 19.0168L3.68546 19.0155C2.87667 18.6419 2.25118 17.8678 2.25 16.9504V7.04968C2.25118 6.132 2.87636 5.35773 3.68546 4.98395L3.68813 4.98272L10.6855 1.78329ZM19.6866 6.34617C20.0836 6.53002 20.2497 6.82172 20.25 7.04813L20.25 16.9494C20.2497 17.1758 20.0836 17.4695 19.6866 17.6533L19.6855 17.6538L12.6881 20.8533L12.6855 20.8545C12.2841 21.0399 11.7159 21.0399 11.3145 20.8545L11.3119 20.8533L4.31454 17.6538L4.31336 17.6533C3.91655 17.4695 3.75045 17.1761 3.75 16.9498L3.75 7.04968C3.75045 6.82337 3.91655 6.52996 4.31336 6.34621L4.31454 6.34566L11.3119 3.14623L11.3145 3.145C11.7159 2.95959 12.2841 2.95959 12.6855 3.145L12.6881 3.14623L19.6855 6.34566L19.6866 6.34617Z"
      fill="currentColor"
    />
  </svg>
);
