import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorMeterFunctionReportRowEntryFragmentDoc } from '../fragments/milkingParlorMeterFunctionReportRowEntry.graphql';
import { MilkingParlorMeterFunctionReportAverageRowEntryFragmentDoc } from '../fragments/milkingParlorMeterFunctionReportAverageRowEntry.graphql';
import { MilkingParlorMeterFunctionReportToleranceRowEntryFragmentDoc } from '../fragments/milkingParlorMeterFunctionReportToleranceRowEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MilkingParlorMeterFunctionReportRowsQueryVariables = Types.Exact<{
  milkingDate: Types.Scalars['Date']['input'];
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingParlorIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  milkingNumbers?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type MilkingParlorMeterFunctionReportRowsQuery = { __typename?: 'Query', milkingParlorMeterFunctionReportRows: Array<{ __typename: 'MilkingParlorMeterFunctionReportAverageRowEntry', cowsCount?: number | null, milkDeviationFromExpectedKilograms?: number | null, milkWeightKilograms?: number | null, durationSeconds?: number | null, flowRate?: number | null, conductivity?: number | null, avgPeakFlowRate?: number | null, fallCount?: number | null, manualModeCount?: number | null, manualDetachCount?: number | null } | { __typename: 'MilkingParlorMeterFunctionReportToleranceRowEntry', cowsCount?: number | null, milkDeviationFromExpectedKilograms?: number | null, milkWeightKilograms?: number | null, durationSeconds?: number | null, flowRate?: number | null, conductivity?: number | null, avgPeakFlowRate?: number | null, fallCount?: number | null, manualModeCount?: number | null, manualDetachCount?: number | null } | { __typename: 'MilkingParlorMeterFunctionReportRowEntry', cowsCount?: number | null, milkDeviationFromExpectedKilograms?: number | null, milkWeightKilograms?: number | null, durationSeconds?: number | null, flowRate?: number | null, conductivity?: number | null, avgPeakFlowRate?: number | null, fallCount?: number | null, manualModeCount?: number | null, manualDetachCount?: number | null, stallNumber: number, notIdentifiedCount?: number | null }> };


export const MilkingParlorMeterFunctionReportRowsDocument = gql`
    query milkingParlorMeterFunctionReportRows($milkingDate: Date!, $farmIDs: [ID!], $milkingParlorIDs: [ID!], $milkingNumbers: [Int!]) {
  milkingParlorMeterFunctionReportRows(
    milkingDate: $milkingDate
    farmIDs: $farmIDs
    milkingParlorIDs: $milkingParlorIDs
    milkingNumbers: $milkingNumbers
  ) {
    ... on MilkingParlorMeterFunctionReportRowEntry {
      ...MilkingParlorMeterFunctionReportRowEntry
    }
    ... on MilkingParlorMeterFunctionReportAverageRowEntry {
      ...MilkingParlorMeterFunctionReportAverageRowEntry
    }
    ... on MilkingParlorMeterFunctionReportToleranceRowEntry {
      ...MilkingParlorMeterFunctionReportToleranceRowEntry
    }
  }
}
    ${MilkingParlorMeterFunctionReportRowEntryFragmentDoc}
${MilkingParlorMeterFunctionReportAverageRowEntryFragmentDoc}
${MilkingParlorMeterFunctionReportToleranceRowEntryFragmentDoc}`;

/**
 * __useMilkingParlorMeterFunctionReportRowsQuery__
 *
 * To run a query within a React component, call `useMilkingParlorMeterFunctionReportRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilkingParlorMeterFunctionReportRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilkingParlorMeterFunctionReportRowsQuery({
 *   variables: {
 *      milkingDate: // value for 'milkingDate'
 *      farmIDs: // value for 'farmIDs'
 *      milkingParlorIDs: // value for 'milkingParlorIDs'
 *      milkingNumbers: // value for 'milkingNumbers'
 *   },
 * });
 */
export function useMilkingParlorMeterFunctionReportRowsQuery(baseOptions: Apollo.QueryHookOptions<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables> & ({ variables: MilkingParlorMeterFunctionReportRowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables>(MilkingParlorMeterFunctionReportRowsDocument, options);
      }
export function useMilkingParlorMeterFunctionReportRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables>(MilkingParlorMeterFunctionReportRowsDocument, options);
        }
export function useMilkingParlorMeterFunctionReportRowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables>(MilkingParlorMeterFunctionReportRowsDocument, options);
        }
export type MilkingParlorMeterFunctionReportRowsQueryHookResult = ReturnType<typeof useMilkingParlorMeterFunctionReportRowsQuery>;
export type MilkingParlorMeterFunctionReportRowsLazyQueryHookResult = ReturnType<typeof useMilkingParlorMeterFunctionReportRowsLazyQuery>;
export type MilkingParlorMeterFunctionReportRowsSuspenseQueryHookResult = ReturnType<typeof useMilkingParlorMeterFunctionReportRowsSuspenseQuery>;
export type MilkingParlorMeterFunctionReportRowsQueryResult = Apollo.QueryResult<MilkingParlorMeterFunctionReportRowsQuery, MilkingParlorMeterFunctionReportRowsQueryVariables>;