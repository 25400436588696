import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowPlannedInjectionsFragmentDoc } from '../fragments/cowPlannedInjections.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CowPlannedInjectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CowPlannedInjectionsQuery = { __typename?: 'Query', cowPlannedInjections: { __typename?: 'CowPlannedInjectionsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CowPlannedInjectionsEdge', cursor: string, node: { __typename: 'CowPlannedInjections', diseaseName: string, protocolName: string, expectedDaysOnProtocol: number, protocolDayNumber: number, injectionNames: Array<string>, protocolDayComments: Array<string>, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number } }, cowProtocol: { __typename: 'CowProtocol', id: string } } }> } };


export const CowPlannedInjectionsDocument = gql`
    query cowPlannedInjections {
  cowPlannedInjections {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...CowPlannedInjections
      }
      cursor
    }
  }
}
    ${CowPlannedInjectionsFragmentDoc}`;

/**
 * __useCowPlannedInjectionsQuery__
 *
 * To run a query within a React component, call `useCowPlannedInjectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCowPlannedInjectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCowPlannedInjectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCowPlannedInjectionsQuery(baseOptions?: Apollo.QueryHookOptions<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>(CowPlannedInjectionsDocument, options);
      }
export function useCowPlannedInjectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>(CowPlannedInjectionsDocument, options);
        }
export function useCowPlannedInjectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>(CowPlannedInjectionsDocument, options);
        }
export type CowPlannedInjectionsQueryHookResult = ReturnType<typeof useCowPlannedInjectionsQuery>;
export type CowPlannedInjectionsLazyQueryHookResult = ReturnType<typeof useCowPlannedInjectionsLazyQuery>;
export type CowPlannedInjectionsSuspenseQueryHookResult = ReturnType<typeof useCowPlannedInjectionsSuspenseQuery>;
export type CowPlannedInjectionsQueryResult = Apollo.QueryResult<CowPlannedInjectionsQuery, CowPlannedInjectionsQueryVariables>;