import React, { useEffect, useMemo, useRef, useState } from 'react';

import { usePrevious } from '~/shared/hooks/usePrevious';

import { SkeletonBarChart } from './components/SkeletonBarChart';
import { SkeletonBlock } from './components/SkeletonBlock';
import { SkeletonLineChart } from './components/SkeletonLineChart';
import { SkeletonText } from './components/SkeletonText';
import { MIN_SKELETON_SHOW_MS, SKELETON_SHOW_DELAY_MS } from './constants';
import {
  SkeletonContext,
  SkeletonContextType,
  useSkeletonContext,
} from './context';

interface Props extends React.PropsWithChildren {
  /**
   * If true, skeleton provides loading state for its children
   */
  isLoading?: boolean;
  /**
   * If true, isLoading true state is applied with delay and min show time
   */
  withDelay?: boolean;
}

export const Skeleton = ({
  isLoading: isLoadingProp = false,
  children,
  withDelay = false,
}: Props) => {
  const minSkeletonShowDelayTimeoutRef = useRef<NodeJS.Timeout>();
  const skeletonMinShowPromiseRef = useRef<Promise<void>>();

  const { isLoading: isLoadingContext } = useSkeletonContext();

  // const [isLoadingState, setIsLoadingState] = useState(isLoadingProp);

  // We need to show previous children for skeleton show delay
  // to avoid any skeleton placeholders children to be renderer without isLoading state in the context
  const prevIsLoading = usePrevious(isLoadingProp);
  const prevChildren = usePrevious(children);
  const childrenWhenLoadingStartedRef = useRef(prevChildren);
  // const [visiblePrevChildren, setVisiblePrevChildren] = useState(children);

  const [{ isLoadingState, visiblePrevChildren }, setSkeletonState] = useState({
    isLoadingState: isLoadingProp,
    visiblePrevChildren: children,
  });

  const isLoadingStarted = !prevIsLoading && isLoadingProp;
  const isLoadingEnded = !isLoadingProp && !isLoadingState;
  const isLoadingInProgressRef = useRef(false);
  if (isLoadingStarted) {
    isLoadingInProgressRef.current = true;
    childrenWhenLoadingStartedRef.current = prevChildren;
  } else if (isLoadingEnded) {
    isLoadingInProgressRef.current = false;
  }

  useEffect(() => {
    if (!withDelay) {
      return () => {};
    }

    if (isLoadingProp) {
      setSkeletonState(current => ({
        ...current,
        visiblePrevChildren: prevChildren ?? children,
      }));
      minSkeletonShowDelayTimeoutRef.current = setTimeout(() => {
        setSkeletonState(current => ({
          ...current,
          isLoadingState: isLoadingProp,
        }));

        // Don't allow to set isLoading for a min show time to avoid flashing
        skeletonMinShowPromiseRef.current = new Promise(resolve =>
          setTimeout(() => {
            resolve();
            skeletonMinShowPromiseRef.current = undefined;
          }, MIN_SKELETON_SHOW_MS)
        );
      }, SKELETON_SHOW_DELAY_MS);
    } else {
      (skeletonMinShowPromiseRef.current ?? Promise.resolve()).then(() => {
        setSkeletonState({
          visiblePrevChildren: undefined,
          isLoadingState: isLoadingProp,
        });
      });
    }

    return () => {
      clearTimeout(minSkeletonShowDelayTimeoutRef.current);
    };
  }, [isLoadingProp]);

  // Sometimes we may have a case of rendering nested Skeletons,
  // but we shouldn't end up resetting initial loading state
  const isLoading =
    isLoadingContext || (withDelay ? isLoadingState : isLoadingProp);

  const contextValue = useMemo<SkeletonContextType>(
    () => ({
      isLoading,
      renderWithSkeleton: (skeleton, content, isStaticContent = false) =>
        isLoading && !isStaticContent ? skeleton : content,
      getSkeletonClassNames: (skeletonClassNames, contentClassNames = '') =>
        isLoading ? skeletonClassNames : contentClassNames,
      renderWithoutSkeleton: content => (isLoading ? null : content),
    }),
    [isLoading, isLoadingContext]
  );

  let childrenToRender = children;
  if (withDelay && isLoadingInProgressRef.current) {
    childrenToRender =
      visiblePrevChildren ?? childrenWhenLoadingStartedRef.current;
  }

  return (
    <SkeletonContext.Provider value={contextValue}>
      {childrenToRender}
    </SkeletonContext.Provider>
  );
};

Skeleton.Block = SkeletonBlock;
Skeleton.Text = SkeletonText;
Skeleton.BarChart = SkeletonBarChart;
Skeleton.LineChart = SkeletonLineChart;

export * from './context';
export * from './helpers';
export * from './types';
export * from './hooks';
export { TextSkeletonSizes } from './components/SkeletonText';
