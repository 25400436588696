import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { SizeVariants } from '~/styles/__generated__/token-variants';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Icon variant to display
   */
  iconVariant: IconVariants;
  /**
   * Tooltip for icon of the block
   */
  tooltip: ReactNode;
  /**
   * Value of the block
   */
  value: ReactNode;
}

export const CardBlock: React.FC<Props> = ({
  className,
  iconVariant,
  tooltip,
  value,
}) => (
  <div className={clsx('flex items-center text-soft ellipsis', className)}>
    <Icon
      {...{
        variant: iconVariant,
        size: SizeVariants.size20,
        tooltip,
      }}
    />
    <Typography
      {...{
        className: 'ml-8 ellipsis',
        skeletonSize: TextSkeletonSizes.small,
        variant: TypographyVariants.bodySmall,
      }}
    >
      {value}
    </Typography>
  </div>
);
