import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportSettingsValueFragmentDoc } from './customReportSettingsValue.graphql';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from './customReportBlueprintSourceFieldValue.graphql';
export type CustomReportSettingsSortByFragment = { __typename: 'CustomReportSettingsSortBy', kind: Types.CustomReportSortByKindEnum, sortingValue?: { __typename?: 'CustomReportSettingsSortBySortingValue', valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } }, blueprintSourceFieldValue?: { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null } | null };

export const CustomReportSettingsSortByFragmentDoc = gql`
    fragment CustomReportSettingsSortBy on CustomReportSettingsSortBy {
  __typename
  kind
  sortingValue {
    valueKey {
      ...CustomReportSettingsValue
    }
    blueprintSourceFieldValue {
      ...CustomReportBlueprintSourceFieldValue
    }
  }
}
    ${CustomReportSettingsValueFragmentDoc}
${CustomReportBlueprintSourceFieldValueFragmentDoc}`;