import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCowsFromCowsCopyKeyMutationVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
  cowIDs: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type DeleteCowsFromCowsCopyKeyMutation = { __typename?: 'Mutation', deleteCowsFromCowsCopyKey?: any | null };


export const DeleteCowsFromCowsCopyKeyDocument = gql`
    mutation deleteCowsFromCowsCopyKey($key: String!, $cowIDs: [ID!]!) {
  deleteCowsFromCowsCopyKey(key: $key, cowIDs: $cowIDs)
}
    `;
export type DeleteCowsFromCowsCopyKeyMutationFn = Apollo.MutationFunction<DeleteCowsFromCowsCopyKeyMutation, DeleteCowsFromCowsCopyKeyMutationVariables>;

/**
 * __useDeleteCowsFromCowsCopyKeyMutation__
 *
 * To run a mutation, you first call `useDeleteCowsFromCowsCopyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCowsFromCowsCopyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCowsFromCowsCopyKeyMutation, { data, loading, error }] = useDeleteCowsFromCowsCopyKeyMutation({
 *   variables: {
 *      key: // value for 'key'
 *      cowIDs: // value for 'cowIDs'
 *   },
 * });
 */
export function useDeleteCowsFromCowsCopyKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCowsFromCowsCopyKeyMutation, DeleteCowsFromCowsCopyKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCowsFromCowsCopyKeyMutation, DeleteCowsFromCowsCopyKeyMutationVariables>(DeleteCowsFromCowsCopyKeyDocument, options);
      }
export type DeleteCowsFromCowsCopyKeyMutationHookResult = ReturnType<typeof useDeleteCowsFromCowsCopyKeyMutation>;
export type DeleteCowsFromCowsCopyKeyMutationResult = Apollo.MutationResult<DeleteCowsFromCowsCopyKeyMutation>;
export type DeleteCowsFromCowsCopyKeyMutationOptions = Apollo.BaseMutationOptions<DeleteCowsFromCowsCopyKeyMutation, DeleteCowsFromCowsCopyKeyMutationVariables>;