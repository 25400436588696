import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { BlueprintFragment } from '../gql/fragments/blueprint.graphql';
import {
  BlueprintsQuery,
  BlueprintsQueryVariables,
  useBlueprintsQuery,
} from '../gql/queries/blueprints.graphql';

export const useBlueprintsPaginatedQuery = makeUsePaginatedQuery<
  BlueprintFragment,
  BlueprintsQuery,
  BlueprintsQueryVariables
>({
  useQuery: useBlueprintsQuery,
  getItemsFromQueryData: data => data.blueprints.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.blueprints.pageInfo,
});
