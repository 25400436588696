import { SourceFieldKindEnum, SourceSectionKindEnum } from '@graphql-types';

/**
 * Possible options to pass into cow format helper
 */
export interface CowFormatOptions {
  withNumberSign?: boolean;
  prefix?: string;
  withName?: boolean;
  noCowMessage?: string;
}

/**
 * Hard coded sections of the static cow fields,
 * other fields are calculated in blueprints and have sections, defined at backend
 */
export enum CowStaticFieldsSections {
  status = 'status',
  identification = 'identification',
  identificationHistory = 'identificationHistory',
  pedigree = 'pedigree',
  animalData = 'animalData',
  milk = 'milk',
  health = 'health',
  common = 'common',
}

/**
 * Rendering config for a static fields section
 */
interface CowStaticFieldsSectionConfig {
  name: string;
  fieldKinds: SourceFieldKindEnum[];
  calculatedSectionKinds: SourceSectionKindEnum[];
}

/**
 * Dict of static section configs
 */
export type CowStaticSectionConfigs = Record<
  CowStaticFieldsSections,
  CowStaticFieldsSectionConfig
>;
