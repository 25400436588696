import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { ListItem } from '~/~legacy/types/keyValue';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';
import { SpecialConstants } from '~/~legacy/types/specialConstants';
import { ControlWrapper } from '~/~legacy/value-editors/components/ControlWrapper';
import { VoidEditor } from '~/~legacy/value-editors/editors/VoidEditor';
import { EditorsMap } from '~/~legacy/value-editors/editorsMap';
import { useControls } from '~/~legacy/value-editors/useControls';
import { useEditLogic } from '~/~legacy/value-editors/useEditLogic';

import { MAutoComplete } from '~/shared/components/MAutoComplete';
import { MDropDown } from '~/shared/components/MDropDown';
import { SelectThemes } from '~/shared/components/Select';
import {
  CompareOperator,
  NodeValueKindEnum,
  ValueKindEnum,
} from '~/shared/graphql';

import { getValueToEdit } from '../../helpers/getValueToEdit';
import {
  BlueprintFilterConditionNode,
  BlueprintSourceSectionField,
} from '../../services';
import { NodeValueKindButtons } from '../NodeValueKindButtons';
import styles from './index.module.scss';

interface FilterConditionEditFormState {
  selectedField?: BlueprintSourceSectionField;
  filterCondition: BlueprintFilterConditionNode;

  nodeValueKind: NodeValueKindEnum;
}

interface Props {
  fields: ListItem<BlueprintSourceSectionField>[];
  filterCondition?: BlueprintFilterConditionNode;
  filterGroupId: string;

  submit: (condition: BlueprintFilterConditionNode) => Promise<boolean>;
  cancel: () => void;
}

export const FilterConditionEditForm: React.FC<Props> = ({
  fields,
  filterCondition,
  filterGroupId,
  submit,
  cancel,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const [state, setState] = useState<FilterConditionEditFormState>({
    selectedField: fields.find(
      field => field.value.id === filterCondition?.field?.id
    )?.value,
    nodeValueKind: filterCondition?.valueKind || NodeValueKindEnum.Hardcode,
    filterCondition: {
      ...filterCondition,
      compareOperator: filterCondition?.compareOperator || CompareOperator.Eq,
      filterGroupId,
    },
  });

  const fieldChangeCallback = useCallback(
    (field: BlueprintSourceSectionField) => {
      setState(prev => {
        return {
          ...prev,
          selectedField: field,
        };
      });
    },
    []
  );

  const valueKindCahnge = useCallback((valueKind: NodeValueKindEnum) => {
    setState(prev => {
      const newState: FilterConditionEditFormState = {
        ...prev,
        nodeValueKind: valueKind,
      };
      return newState;
    });
  }, []);

  const compareOperatorChange = useCallback(
    (compareOperator: CompareOperator) => {
      setState(prev => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            compareOperator,
          },
        };
      });
    },
    [setState]
  );

  const submitCallback = useCallback(
    (val: any) => {
      return submit({
        ...state.filterCondition,

        valueKind: state.nodeValueKind,
        field: state.selectedField,
        value: val,
      });
    },
    [submit, state]
  );

  const editors = useMemo(() => {
    return EditorsMap;
  }, []);

  const selectionKind =
    state.nodeValueKind === NodeValueKindEnum.Variable
      ? NodeValueKindEnum.Variable
      : state.selectedField?.returnValueKind || ValueKindEnum.Void;

  const editor = editors[selectionKind] || editors[SpecialConstants.Unknown];

  const valueToEdit = getValueToEdit(
    state.filterCondition.value,
    state.filterCondition.userInputValue,
    selectionKind,
    state.filterCondition.valueKind,
    state.nodeValueKind
  );

  const nullOperator =
    state.filterCondition.compareOperator === CompareOperator.NotNull ||
    state.filterCondition.compareOperator === CompareOperator.IsNull;

  const [isEditingInner, setEditingInner] = useState(true);

  const toggleCallback = useCallback(() => {
    setEditingInner(val => !val);
  }, []);

  const { val, setVal, formSubmitCallback } = useEditLogic(
    valueToEdit,
    () => Promise.resolve(true),
    newValue => {
      cancel();
      return submitCallback?.(newValue);
    },
    toggleCallback
  );

  const controls = useControls(!!nullOperator, toggleCallback);

  const editorCmpnt = nullOperator ? (
    <ControlWrapper isForm onSubmit={formSubmitCallback}>
      <VoidEditor
        {...{
          name: 'void',
          value: valueToEdit,
          edit: isEditingInner,
          cancel: () => {
            toggleCallback();
            cancel?.();
          },
        }}
      />
    </ControlWrapper>
  ) : (
    editor({
      name: state.nodeValueKind,
      submit: submitCallback,
      value: val,
      parentNodeId: state.selectedField?.id,
      returnValueKind: state.selectedField?.returnValueKind,
      valueKind: state.nodeValueKind,
      edit: isEditingInner,
      cancel: () => {
        toggleCallback();
        cancel?.();
      },
      onChange: newVal => {
        setVal(newVal);
        return Promise.resolve(true);
      },
      additionalArgs: {
        selectTheme: SelectThemes.dark,
      },
    })
  );

  const isWithControls =
    !nullOperator &&
    state.nodeValueKind !== NodeValueKindEnum.Variable &&
    selectionKind !== ValueKindEnum.Void;

  return (
    <>
      <MAutoComplete
        name="selectedField"
        className="my-8"
        items={fields}
        placeholder={t(
          IntegratorStrings.blueprintEdit.nodes.filterNode.selectField
        )}
        onChange={fieldChangeCallback}
        selectedValue={state.selectedField}
      />

      <NodeValueKindButtons
        value={state.nodeValueKind}
        onChange={valueKindCahnge}
      />

      <div className={styles.form}>
        <MDropDown
          name="operator"
          className={clsx(styles.operator, 'my-8')}
          noReset
          items={[
            {
              value: CompareOperator.Eq,
              content: '=',
            },
            {
              value: CompareOperator.Gt,
              content: '>',
            },
            {
              value: CompareOperator.Gte,
              content: '>=',
            },
            {
              value: CompareOperator.In,
              content: t(
                IntegratorStrings.blueprintEdit.nodes.filterNode.comparison.in
              ),
            },
            {
              value: CompareOperator.Lt,
              content: '<',
            },
            {
              value: CompareOperator.Lte,
              content: '<=',
            },
            {
              value: CompareOperator.Ne,
              content: '!=',
            },
            {
              value: CompareOperator.NotIn,
              content: t(
                IntegratorStrings.blueprintEdit.nodes.filterNode.comparison
                  .notIn
              ),
            },
            {
              value: CompareOperator.IsNull,
              content: t(
                IntegratorStrings.blueprintEdit.nodes.filterNode.comparison
                  .isNull
              ),
            },
            {
              value: CompareOperator.NotNull,
              content: t(
                IntegratorStrings.blueprintEdit.nodes.filterNode.comparison
                  .notNull
              ),
            },
          ]}
          onChange={compareOperatorChange}
          selectedValue={state.filterCondition.compareOperator}
        />
        <div className={styles.editor}>
          <ControlWrapper isForm={isWithControls} onSubmit={formSubmitCallback}>
            {editorCmpnt}
            {isWithControls && controls}
          </ControlWrapper>
        </div>
      </div>
    </>
  );
};
