import React, { ReactNode } from 'react';

import './MBackdrop.scss';

interface Props {
  children?: ReactNode;
  fixed?: boolean;
}

export const MBackdrop: React.FC<Props> = props => {
  const { children, fixed } = props;

  const position = fixed ? 'fixed' : undefined;

  return (
    <div className="m-backdrop pointer-events-none" style={{ position }}>
      {children}
    </div>
  );
};
