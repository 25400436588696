import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../penGroups/gql/fragments/penGroupShort.graphql';
export type CustomMilkingReportSettingsHistoricValue_CustomMilkingReportSettingsHistoricValueLactationNumbers_Fragment = { __typename: 'CustomMilkingReportSettingsHistoricValueLactationNumbers', lactationNumbers?: Array<number> | null };

export type CustomMilkingReportSettingsHistoricValue_CustomMilkingReportSettingsHistoricValueLactationGroupNumbers_Fragment = { __typename: 'CustomMilkingReportSettingsHistoricValueLactationGroupNumbers', lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null };

export type CustomMilkingReportSettingsHistoricValue_CustomMilkingReportSettingsHistoricValuePenGroups_Fragment = { __typename: 'CustomMilkingReportSettingsHistoricValuePenGroups', penGroups: Array<{ __typename: 'PenGroup', id: string, name: string, identifier: number }> };

export type CustomMilkingReportSettingsHistoricValueFragment = CustomMilkingReportSettingsHistoricValue_CustomMilkingReportSettingsHistoricValueLactationNumbers_Fragment | CustomMilkingReportSettingsHistoricValue_CustomMilkingReportSettingsHistoricValueLactationGroupNumbers_Fragment | CustomMilkingReportSettingsHistoricValue_CustomMilkingReportSettingsHistoricValuePenGroups_Fragment;

export const CustomMilkingReportSettingsHistoricValueFragmentDoc = gql`
    fragment CustomMilkingReportSettingsHistoricValue on CustomMilkingReportSettingsHistoricValue {
  __typename
  ... on CustomMilkingReportSettingsHistoricValueLactationNumbers {
    lactationNumbers
  }
  ... on CustomMilkingReportSettingsHistoricValueLactationGroupNumbers {
    lactationGroupNumbers
  }
  ... on CustomMilkingReportSettingsHistoricValuePenGroups {
    penGroups {
      ...PenGroupShort
    }
  }
}
    ${PenGroupShortFragmentDoc}`;