import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';

import { formatPenGroup } from '~/entities/penGroups';
import { PenGroupShortFragment } from '~/entities/penGroups/gql/fragments/penGroupShort.graphql';

import { MilkingParlorIntervalWithMilkingParlorFragment } from '../../gql/fragments/milkingParlorIntervalWithMilkingParlor.graphql';
import { CutCodesTable } from '../CutCodesTable';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Parlors with detailed info to render in the table
   */
  milkingParlorInterval:
    | MilkingParlorIntervalWithMilkingParlorFragment
    | SkeletonPlaceholder;
  /**
   * Date of the cut code tables
   */
  codesDate: string;
}

const PEN_GROUPS_COLUMNS_SKELETONS_COUNT = 8;

export const MilkingParlorIntervalPenGroupsTable: React.FC<Props> = ({
  className,
  milkingParlorInterval,
  codesDate,
}) => {
  type TableRow = PenGroupShortFragment | SkeletonPlaceholder;

  const intervalPenGroups =
    milkingParlorInterval.penGroups?.edges.map(R.prop('node')) ??
    getSkeletonPlaceholders(PEN_GROUPS_COLUMNS_SKELETONS_COUNT);

  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      title: 'Группа',
      key: 'penGroup',
      renderCellContent: penGroup => formatPenGroup(penGroup),
    },
  ];

  return (
    <Table<TableRow>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: intervalPenGroups,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        renderExpandableRowContent: group => {
          if (
            isSkeletonPlaceholder(milkingParlorInterval) ||
            isSkeletonPlaceholder(group)
          ) {
            return null;
          }
          return (
            <CutCodesTable
              {...{
                queryVariables: {
                  date: codesDate,
                  milkingParlorIntervalID: milkingParlorInterval.id,
                  penGroupID: group.id,
                },
                sortingGatesCount:
                  milkingParlorInterval.milkingParlor.sortingGatesCount,
              }}
            />
          );
        },
      }}
    />
  );
};
