import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CalvingFragmentDoc } from '../fragments/calving.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalvingsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CalvingsQuery = { __typename?: 'Query', calvings: { __typename?: 'CalvingConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CalvingEdge', cursor: string, node: { __typename: 'Calving', id: string, happenedAt: string, isAbortion: boolean, difficultyScore: number, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }, employee?: { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string, number: string } | null } }> } };


export const CalvingsDocument = gql`
    query calvings($search: String, $first: Int, $after: String) {
  calvings(first: $first, search: $search, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Calving
      }
    }
  }
}
    ${CalvingFragmentDoc}`;

/**
 * __useCalvingsQuery__
 *
 * To run a query within a React component, call `useCalvingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalvingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalvingsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCalvingsQuery(baseOptions?: Apollo.QueryHookOptions<CalvingsQuery, CalvingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalvingsQuery, CalvingsQueryVariables>(CalvingsDocument, options);
      }
export function useCalvingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalvingsQuery, CalvingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalvingsQuery, CalvingsQueryVariables>(CalvingsDocument, options);
        }
export function useCalvingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalvingsQuery, CalvingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalvingsQuery, CalvingsQueryVariables>(CalvingsDocument, options);
        }
export type CalvingsQueryHookResult = ReturnType<typeof useCalvingsQuery>;
export type CalvingsLazyQueryHookResult = ReturnType<typeof useCalvingsLazyQuery>;
export type CalvingsSuspenseQueryHookResult = ReturnType<typeof useCalvingsSuspenseQuery>;
export type CalvingsQueryResult = Apollo.QueryResult<CalvingsQuery, CalvingsQueryVariables>;