import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type FarmFragment = { __typename: 'Farm', id: string, name: string, number: string };

export const FarmFragmentDoc = gql`
    fragment Farm on Farm {
  __typename
  id
  name
  number
}
    `;