import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveUserFromCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  companyID: Types.Scalars['ID']['input'];
}>;


export type RemoveUserFromCompanyMutation = { __typename?: 'Mutation', removeUserFromCompany?: any | null };


export const RemoveUserFromCompanyDocument = gql`
    mutation removeUserFromCompany($id: ID!, $companyID: ID!) {
  removeUserFromCompany(id: $id, companyID: $companyID)
}
    `;
export type RemoveUserFromCompanyMutationFn = Apollo.MutationFunction<RemoveUserFromCompanyMutation, RemoveUserFromCompanyMutationVariables>;

/**
 * __useRemoveUserFromCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromCompanyMutation, { data, loading, error }] = useRemoveUserFromCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useRemoveUserFromCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromCompanyMutation, RemoveUserFromCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromCompanyMutation, RemoveUserFromCompanyMutationVariables>(RemoveUserFromCompanyDocument, options);
      }
export type RemoveUserFromCompanyMutationHookResult = ReturnType<typeof useRemoveUserFromCompanyMutation>;
export type RemoveUserFromCompanyMutationResult = Apollo.MutationResult<RemoveUserFromCompanyMutation>;
export type RemoveUserFromCompanyMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromCompanyMutation, RemoveUserFromCompanyMutationVariables>;