import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportBlueprintLaunchResultFragmentDoc } from '../fragments/customReportBlueprintLaunchResult.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomReportBlueprintLaunchResultQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  hashID: Types.Scalars['String']['input'];
  filters: Array<Types.CustomReportBlueprintLaunchResultFilterInput> | Types.CustomReportBlueprintLaunchResultFilterInput;
}>;


export type CustomReportBlueprintLaunchResultQuery = { __typename?: 'Query', customReportBlueprintLaunchResult: { __typename: 'CustomReportBlueprintLaunchResult', columnSourceFields: Array<{ __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum }>, rows: Array<{ __typename?: 'CustomReportBlueprintLaunchResultRow', values: Array<{ __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null }> }> } };


export const CustomReportBlueprintLaunchResultDocument = gql`
    query customReportBlueprintLaunchResult($id: ID!, $hashID: String!, $filters: [CustomReportBlueprintLaunchResultFilterInput!]!) {
  customReportBlueprintLaunchResult(id: $id, hashID: $hashID, filters: $filters) {
    ...CustomReportBlueprintLaunchResult
  }
}
    ${CustomReportBlueprintLaunchResultFragmentDoc}`;

/**
 * __useCustomReportBlueprintLaunchResultQuery__
 *
 * To run a query within a React component, call `useCustomReportBlueprintLaunchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomReportBlueprintLaunchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomReportBlueprintLaunchResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hashID: // value for 'hashID'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomReportBlueprintLaunchResultQuery(baseOptions: Apollo.QueryHookOptions<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables> & ({ variables: CustomReportBlueprintLaunchResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables>(CustomReportBlueprintLaunchResultDocument, options);
      }
export function useCustomReportBlueprintLaunchResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables>(CustomReportBlueprintLaunchResultDocument, options);
        }
export function useCustomReportBlueprintLaunchResultSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables>(CustomReportBlueprintLaunchResultDocument, options);
        }
export type CustomReportBlueprintLaunchResultQueryHookResult = ReturnType<typeof useCustomReportBlueprintLaunchResultQuery>;
export type CustomReportBlueprintLaunchResultLazyQueryHookResult = ReturnType<typeof useCustomReportBlueprintLaunchResultLazyQuery>;
export type CustomReportBlueprintLaunchResultSuspenseQueryHookResult = ReturnType<typeof useCustomReportBlueprintLaunchResultSuspenseQuery>;
export type CustomReportBlueprintLaunchResultQueryResult = Apollo.QueryResult<CustomReportBlueprintLaunchResultQuery, CustomReportBlueprintLaunchResultQueryVariables>;