import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateUserEventInput;
}>;


export type UpdateUserEventMutation = { __typename?: 'Mutation', updateUserEvent?: any | null };


export const UpdateUserEventDocument = gql`
    mutation updateUserEvent($id: ID!, $input: UpdateUserEventInput!) {
  updateUserEvent(id: $id, input: $input)
}
    `;
export type UpdateUserEventMutationFn = Apollo.MutationFunction<UpdateUserEventMutation, UpdateUserEventMutationVariables>;

/**
 * __useUpdateUserEventMutation__
 *
 * To run a mutation, you first call `useUpdateUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEventMutation, { data, loading, error }] = useUpdateUserEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEventMutation, UpdateUserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEventMutation, UpdateUserEventMutationVariables>(UpdateUserEventDocument, options);
      }
export type UpdateUserEventMutationHookResult = ReturnType<typeof useUpdateUserEventMutation>;
export type UpdateUserEventMutationResult = Apollo.MutationResult<UpdateUserEventMutation>;
export type UpdateUserEventMutationOptions = Apollo.BaseMutationOptions<UpdateUserEventMutation, UpdateUserEventMutationVariables>;