import React from 'react';

import { FemaleAnimalKind } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
} from '~/shared/components/Table';
import { Typography } from '~/shared/components/Typography';
import {
  formatInt,
  formatNumber,
  formatWithPercent,
} from '~/shared/helpers/number';

import { InseminationsTable } from '~/entities/inseminations';
import { ReproductionCrRowsRowFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrRowsRow.graphql';

import { TypographyVariants } from '~/styles/__generated__/token-variants';

import { getMetricColorBackgroundClassName } from '../../../../helpers';
import {
  useFarmWithReproductionSettingsFromCacheOrQuery,
  useReproductionCrReportXAxis,
} from '../../../../hooks';
import { CrReportDisplayProps } from '../../../../types';
import styles from './index.module.scss';

interface Props extends CrReportDisplayProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Farm, used for report calculations, to get target from
   */
  farmId?: string | null;
  /**
   * Female animal kind, used for report calculations, to get target for
   */
  femaleAnimalKind: FemaleAnimalKind;
}

const SKELETON_ROWS_COUNT = 8;

const NAME_COLUMN_WIDTH_PX = 204;
const SMALL_COLUMN_WIDTH_PX = 104;
const LARGE_COLUMN_WIDTH_PX = 172;

export const ReproductionCrTable: React.FC<Props> = ({
  className,

  chartData,
  historicChartData,
  xAxisMode,

  farmId,
  femaleAnimalKind,
}) => {
  const isComparison = !!historicChartData;

  const { fragment: currentFarm } =
    useFarmWithReproductionSettingsFromCacheOrQuery(farmId);

  const currentCrTarget =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.crCowsTargetPercent
      : currentFarm?.reproductionTargets.crHeifersTargetPercent;
  const currentCrDeviationThreshold =
    femaleAnimalKind === FemaleAnimalKind.Cow
      ? currentFarm?.reproductionTargets.crCowsDeviationThresholdPercent
      : currentFarm?.reproductionTargets.crHeifersDeviationThresholdPercent;

  const { xAxisName, getXAxisLabelByRowIndex } = useReproductionCrReportXAxis({
    chartData,
    historicChartData,
    xAxisMode,
    shouldShowOther: !!chartData.other,
    shouldShowTotal: !!chartData.total,
  });

  let items: (ReproductionCrRowsRowFragment | SkeletonPlaceholder)[];
  if (isSkeletonPlaceholder(chartData)) {
    items = getSkeletonPlaceholders(SKELETON_ROWS_COUNT);
  } else {
    const actualItems = [
      ...(chartData.rows ?? []),
      chartData.other,
      chartData.total,
    ].filter(Boolean);
    items = actualItems;
    if (isComparison) {
      const historicItems = [
        ...historicChartData.rows,
        historicChartData.other,
        historicChartData.total,
      ].filter(Boolean);
      items = R.zip(actualItems, historicItems).flat();
    }
  }

  const columnConfigs: TableColumnConfig<
    ReproductionCrRowsRowFragment | SkeletonPlaceholder
  >[] = [
    {
      title: xAxisName,
      key: 'name',
      renderCellContent: (item, index) => {
        const label = getXAxisLabelByRowIndex(index, true);
        const rowName = Array.isArray(label) ? label.join('') : label;

        return (
          <>
            <div>{rowName}</div>
            {isComparison && (
              <Typography
                className="text-muted"
                variant={TypographyVariants.descriptionLarge}
              >
                {index % 2 === 0 ? 'Текущий период' : 'Период сравнения'}
              </Typography>
            )}
          </>
        );
      },
      isSticky: true,
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: (
        <>
          <div>Оплодотворяемость (CR)</div>
          {!R.isNil(currentCrTarget) && (
            <Typography
              className="text-soft"
              variant={TypographyVariants.descriptionLarge}
            >
              Цель {formatWithPercent(currentCrTarget)}
            </Typography>
          )}
        </>
      ),
      key: 'cr',
      columnClassName: 'text-right',
      cellClassName: item =>
        getMetricColorBackgroundClassName({
          targetPercent: currentCrTarget,
          deviationThresholdPercent: currentCrDeviationThreshold,
          valuePercent: item.cr,
        }),
      headerClassName: isSkeletonPlaceholder(chartData)
        ? undefined
        : 'background-neutral-container-soft',
      renderCellContent: item => formatWithPercent(item.cr),
      width: LARGE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Стельные',
      key: 'pregnant',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.pregnant),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    {
      title: 'Нестельные',
      key: 'notPregnant',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.notPregnant),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    {
      title: 'Непроверен.',
      key: 'other',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.other),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    {
      title: 'Аборты',
      key: 'abortions',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.abortions),
      width: SMALL_COLUMN_WIDTH_PX,
    },
    {
      title: 'Всего осеменений в группе',
      key: 'inseminations',
      columnClassName: 'text-right',
      renderCellContent: item => formatInt(item.inseminations),
      width: LARGE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Процент от суммарного количества осеменений',
      key: 'inseminationsPercent',
      columnClassName: 'text-right',
      renderCellContent: item => formatWithPercent(item.inseminationsPercent),
      width: LARGE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Количество спермодоз до плодотворного осеменения',
      key: 'semenDosesTillPregnancyCount',
      columnClassName: 'text-right',
      renderCellContent: item =>
        formatNumber(item.semenDosesTillPregnancyCount, 1),
      width: LARGE_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<ReproductionCrRowsRowFragment | SkeletonPlaceholder>
      {...{
        className: clsx(className, 'min-w-full w-min'),
        items,
        columnConfigs,
        getItemKey: (item, index) =>
          `${getXAxisLabelByRowIndex(index, true)?.toString()}__${index}`,
        getRowClassName: (item, index) => {
          const isTotalRow =
            !isSkeletonPlaceholder(chartData) &&
            (index === items.length - 1 ||
              (isComparison && index === items.length - 2));
          const isHistoricRow = isComparison && index % 2 === 1;

          return clsx({
            ['background-info-opaque-container-muted']:
              isHistoricRow && !isTotalRow,
            ['background-neutral-opaque-container-default']:
              isTotalRow && !isHistoricRow,
            [styles.historicTotalRow]: isTotalRow && isHistoricRow,
          });
        },
        getRowTypographyVariant: (item, index) => {
          const isTotalRow =
            !isSkeletonPlaceholder(chartData) &&
            (index === items.length - 1 ||
              (isComparison && index === items.length - 2));
          return isTotalRow ? TypographyVariants.bodySmallStrong : undefined;
        },
        hasExpandableRowContent: ({ hashID }) => !!hashID,
        renderExpandableRowContent: ({ hashID }) =>
          !!hashID && (
            <InseminationsTable
              {...{
                hashID,
                femaleAnimalKind,
              }}
            />
          ),
        noItemsMessage:
          'Недостаточно данных для отображения, проверьте настройки',
      }}
    />
  );
};
