import React from 'react';

import { Input, InputVariants } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatCurrency } from '~/shared/helpers/number';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { ProtocolFragment } from '../../gql/fragments/protocol.graphql';
import { EditProtocolTotalTreatmentCostFormType } from '../../hooks/useProtocolTotalTreatmentCostForm';
import styles from './index.module.scss';

interface Props
  extends Pick<
    UseModalStepFormInterface<EditProtocolTotalTreatmentCostFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Protocol to edit
   */
  protocol: ProtocolFragment;
}

export const EditProtocolTotalTreatmentCostForm: React.FC<Props> = ({
  className,
  formProps,
  protocol,
}) => {
  const { formContext } = formProps;

  const totalTreatmentCost = formContext.watch('totalTreatmentCost');

  return (
    <Form
      {...{
        className,
        ...formProps,
      }}
    >
      <Input
        {...{
          name: 'totalTreatmentCost',
          variant: InputVariants.money,
          label: 'Стоимость инъекции',
          placeholder: 'Введите стоимость в рублях',
          feedback:
            protocol.totalTreatmentCost !== totalTreatmentCost
              ? `Расчетная стоимость – ${formatCurrency(
                  protocol.totalTreatmentCost
                )}`
              : undefined,
        }}
      />

      <Typography
        {...{
          className: 'mt-16 mb-8',
          variant: TypographyVariants.heading5,
          tag: 'h3',
        }}
      >
        Данные события
      </Typography>

      <Typography
        variant={TypographyVariants.bodySmall}
        className={styles.infoTable}
      >
        <div>Название протокола</div>
        <div>{protocol.name}</div>

        <div>Дни на протоколе</div>
        <div>{protocol.expectedDaysOnProtocol} дней</div>

        <div>Дни до выхода из молока</div>
        <div>{protocol.daysMilkWithhold} дней</div>

        <div>Дни до выхода из мяса</div>
        <div>{protocol.daysMeatWithhold} дней</div>

        <div>День проверки</div>
        <div>{protocol.daysUntilCheckAfterLastThreatment} день</div>
      </Typography>
    </Form>
  );
};
