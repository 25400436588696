import { FetchHTTPTransport } from '~/~legacy/services/HttpTransport';
import { StandardServices } from '~/~legacy/types/services';

import { GqlAuthProvider } from '~/services/gql';

export function initIoC(
  gqlAuthProvider: GqlAuthProvider,
  getSelectedCompanyId: () => string
) {
  const httpTransport = new FetchHTTPTransport(
    gqlAuthProvider,
    getSelectedCompanyId,
    {
      cache: 'no-cache',
    }
  );

  const container = {
    [StandardServices.Http]: httpTransport,
  };

  return container;
}
