import React from 'react';

import { getValueToRender } from '~/~legacy/helpers/getValueToRender';
import { PenGroupShortInfo } from '~/~legacy/types/entities';
import { StandardView } from '~/~legacy/value-editors/components/StandardView';
import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { ValueKindEnum } from '~/shared/graphql';

export const PenGroupSelectorView: React.FC<
  ValueEditorStandardProps<PenGroupShortInfo>
> = props => {
  const { caption, hideControls, toggle, value } = props;

  const valueToRender = getValueToRender(
    value,
    value,
    ValueKindEnum.PenGroupId
  );

  return (
    <StandardView
      caption={caption}
      hideControls={hideControls}
      toggle={toggle}
      value={valueToRender}
    />
  );
};
