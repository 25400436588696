import { downloadFile } from '~/shared/helpers/downloadFile';

import { useExportTestMilkingUploadNotFoundCowsMutation } from '../gql/mutations/exportTestMilkingUploadNotFoundCows.graphql';

/**
 * Hook for using not found cows export mutation and downloading it's csv file result
 */
export const useExportTestMilkingUploadNotFoundCows = () => {
  const [exportTestMilkingUploadNotFoundCowsMutation, { loading: isLoading }] =
    useExportTestMilkingUploadNotFoundCowsMutation();

  const exportTestMilkingUploadNotFoundCows = (testMilkingUploadID: string) =>
    exportTestMilkingUploadNotFoundCowsMutation({
      variables: {
        testMilkingUploadID,
      },
    }).then(({ data }) => {
      const file = data?.exportTestMilkingUploadNotFoundCows;

      if (file) {
        downloadFile(file.downloadUrl, file.name, file.extension);
      }
    });

  return {
    exportTestMilkingUploadNotFoundCows,
    isLoading,
  };
};
