import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { DatePeriodFragmentDoc } from '../../../../services/dateTime/gql/fragments/datePeriod.graphql';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
import { CowShortFragmentDoc } from '../../../cows/gql/fragments/cowShort.graphql';
export type ReproductionHdrAndPrReportSettingsFragment = { __typename: 'ReproductionHdrAndPrReportSettings', femaleAnimalKind: Types.FemaleAnimalKind, xAxisMode: Types.ReproductionHdrAndPrXAxisMode, yAxisMode: Types.ReproductionYAxisMode, yAxisMetrics: Array<Types.ReproductionHdrAndPrYAxisMetric>, yAxisShouldCompareToPreviousPeriod: boolean, yAxisCompareToPreviousPeriodMode?: Types.CompareToPreviousPeriodMode | null, yAxisCompareToPreviousPeriodChartKind?: Types.ReproductionCrReportChartKind | null, lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null, voluntaryWaitingPeriodDays: number, period: { __typename: 'DatePeriod', daysFromToday?: number | null, interval: { __typename?: 'DatePeriodInterval', since: string, till: string } }, farm?: { __typename: 'Farm', id: string, name: string, number: string } | null, cows?: Array<{ __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }> | null };

export const ReproductionHdrAndPrReportSettingsFragmentDoc = gql`
    fragment ReproductionHdrAndPrReportSettings on ReproductionHdrAndPrReportSettings {
  __typename
  period {
    ...DatePeriod
  }
  femaleAnimalKind
  xAxisMode
  yAxisMode
  yAxisMetrics
  yAxisShouldCompareToPreviousPeriod
  yAxisCompareToPreviousPeriodMode
  yAxisCompareToPreviousPeriodChartKind
  lactationGroupNumbers
  voluntaryWaitingPeriodDays
  farm {
    ...Farm
  }
  cows {
    ...CowShort
  }
}
    ${DatePeriodFragmentDoc}
${FarmFragmentDoc}
${CowShortFragmentDoc}`;