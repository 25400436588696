import { createFileRoute } from '@tanstack/react-router';

import { makeBeforeLoadRedirect, RouterTabs } from '~/services/navigation';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/reproduction'
)({
  wrapInSuspense: true,
  component: AnalyticsReproductionPage,
  beforeLoad: makeBeforeLoadRedirect<{ companyId: string }>({
    from: '/$companyId/user/analytics/reproduction',
    to: '/$companyId/user/analytics/reproduction/overview',
  }),
});

function AnalyticsReproductionPage() {
  return (
    <>
      <PageHeader
        className={layoutStyles.limitedContainer}
        title="Воспроизводство"
      />

      <RouterTabs
        {...{
          className: layoutStyles.stickyPageTabs,
          tabs: [
            {
              href: '/$companyId/user/analytics/reproduction/overview',
              title: 'Общее',
            },
            {
              href: '/$companyId/user/analytics/reproduction/hdr-and-pr',
              title: 'Выявление (HDR) и Стельность (PR)',
            },
            {
              href: '/$companyId/user/analytics/reproduction/cr',
              title: 'Оплодотворяемость (CR)',
            },
          ],
        }}
      />
    </>
  );
}
