import React from 'react';

import clsx from 'clsx';

import { Badge } from '~/shared/components/Badge';
import {
  FunctionButton,
  FunctionButtonVariants,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useMyUser } from '~/services/auth';
import { formatDate } from '~/services/dateTime';
import { getNumberPartFromGlobalId } from '~/services/gql';
import { useArkaNavigation } from '~/services/navigation';

import { useSetHerriotSettingsModal } from '~/entities/companies';
import { CompanyFragment } from '~/entities/companies/gql/fragments/company.graphql';
import { CompaniesQueryVariables } from '~/entities/companies/gql/queries/companies.graphql';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import panelStyles from '~/styles/modules/panel.module.scss';

import { CompanyCardContextMenu } from '../CompanyCardContextMenu';
import { CardBlock } from './components/CardBlock';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Company to render
   */
  company: CompanyFragment | SkeletonPlaceholder;
  /**
   * Variables, used in companies query
   */
  queryVariables: CompaniesQueryVariables;
}

export const CompanyCard: React.FC<Props> = ({
  className,
  company,
  queryVariables,
}) => {
  const { isIntegratorByCompanyId } = useMyUser();

  const { navigate } = useArkaNavigation();

  const { open: openSetHerriotSettingsModal } = useSetHerriotSettingsModal();

  const isIntegrator = isIntegratorByCompanyId(company.id);

  const shouldShowLoadDataBadge =
    isIntegrator && !isSkeletonPlaceholder(company) && !company.cowsCount;

  const licensedByElement = (
    <CardBlock
      {...{
        iconVariant: IconVariants.clock,
        tooltip: 'Дата окончания лицензии',
        value: formatDate(company.licensedBy),
      }}
    />
  );

  const cowsCountElement = (
    <CardBlock
      {...{
        iconVariant: IconVariants.cow,
        tooltip: 'Животные',
        value: company.cowsCount,
      }}
    />
  );

  return (
    <div
      {...{
        className: clsx(panelStyles.panel, styles.root, 'p-16', className),
        onClick: () => {
          navigate({
            to: '/$companyId/user/production-calendar',
            params: {
              companyId: getNumberPartFromGlobalId(company.id),
            },
          });
        },
      }}
    >
      <div className="flex items-center justify-between">
        <Typography
          variant={TypographyVariants.bodyMediumStrong}
          trim
          title={company.name}
        >
          {company.name}
        </Typography>
        {isIntegrator && !isSkeletonPlaceholder(company) && (
          <div className="flex gap-8">
            <FunctionButton
              {...{
                tooltip: (
                  <div className="grid gap-4">
                    {company.isHerriotIntegrationSet && (
                      <Typography
                        variant={TypographyVariants.descriptionLargeStrong}
                      >
                        Настроена передача данных в&nbsp;Хорриот
                      </Typography>
                    )}
                    {!company.isHerriotIntegrationSet && (
                      <Typography
                        variant={TypographyVariants.descriptionLargeStrong}
                      >
                        Не настроена передача данных в&nbsp;Хорриот
                      </Typography>
                    )}

                    <Typography variant={TypographyVariants.descriptionLarge}>
                      Нажмите, чтобы проверить настройки
                    </Typography>
                  </div>
                ),
                isDisabled: company.isLockedForWrite,
                iconVariant: company.isHerriotIntegrationSet
                  ? IconVariants.herriotConnected
                  : IconVariants.herriotDisabled,
                variant: company.isHerriotIntegrationSet
                  ? FunctionButtonVariants.success
                  : FunctionButtonVariants.failure,
                onPress: () => openSetHerriotSettingsModal({ company }),
              }}
            />
            <CompanyCardContextMenu
              {...{
                company,
                queryVariables,
                isDisabled: company.isLockedForWrite,
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.info}>
        <CardBlock
          {...{
            iconVariant: IconVariants.user,
            tooltip: 'Пользователи',
            value: company.usersCount,
          }}
        />
        {shouldShowLoadDataBadge ? licensedByElement : cowsCountElement}
        {}
        {!shouldShowLoadDataBadge &&
          (isIntegrator || isSkeletonPlaceholder(company)) && (
            <>
              <CardBlock
                {...{
                  iconVariant: IconVariants.clipboardList,
                  tooltip: 'Команды',
                  value: company.blueprintsCount,
                }}
              />
              {licensedByElement}
            </>
          )}
        {shouldShowLoadDataBadge && (
          <Badge
            {...{
              className: 'col-span-full',
              iconVariant: IconVariants.warningCircleFilled,
              color: ColorVariants.warning,
            }}
          >
            Загрузите данные на ферму
          </Badge>
        )}
      </div>
    </div>
  );
};
