import React from 'react';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import clsx from 'clsx';

import { Checkbox } from '~/shared/components/Checkbox';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import styles from './index.module.scss';

/**
 * Blueprint entity that can be ordered
 */
export interface BlueprintOrderableEntityType {
  id: string;
  name: string;
}

export interface BlueprintOrderableEntityProps
  extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field to display
   */
  entity: BlueprintOrderableEntityType;
  /**
   * If true, entity is selected
   */
  isSelected?: boolean;
  /**
   * Called, when the selection of the entity changes
   */
  onSelectedChange?: (newSelected: boolean) => void;
  /**
   * If true, than the entity is rendered with a checkbox, default - true
   */
  withCheckbox?: boolean;
  /**
   * If true, than the entity is rendered with a drag handle
   */
  withDragHandle?: boolean;
  /**
   * Props for the drag handle to connect it with the dnd provider
   */
  dragHandleProps?: SyntheticListenerMap & {
    ref: (node: HTMLElement | null) => void;
  };
  /**
   * If true, the item is rendered with opacity to indicate dragging
   */
  isDragging?: boolean;
  /**
   * If true, it indicates that the entity is render in a grag overlay
   */
  isDragOverlay?: boolean;
}

export const BlueprintOrderableEntity = React.forwardRef<
  HTMLDivElement,
  BlueprintOrderableEntityProps
>(
  (
    {
      className,

      entity,

      isSelected,
      onSelectedChange,

      withCheckbox = true,
      withDragHandle,
      dragHandleProps,
      isDragging,
      isDragOverlay,

      ...other
    },
    ref
  ) => {
    return (
      <div
        {...{
          ref,
          ...mergeProps(
            {
              className: clsx(styles.root, className, {
                [styles.dragging]: isDragging,
                [styles.dragOverlay]: isDragOverlay,
              }),
              onClick: () => onSelectedChange?.(!isSelected),
            },
            other
          ),
        }}
      >
        {withDragHandle && (
          <Icon
            {...{
              ...dragHandleProps,
              variant: isDragging
                ? IconVariants.dragIndicator
                : IconVariants.dragHandle,
              className: clsx(
                'text-muted mr-8',
                isDragging ? 'cursor-grabbing' : 'cursor-grab'
              ),
            }}
          />
        )}
        <Typography
          variant={TypographyVariants.bodySmall}
          className="ellipsis mr-8"
        >
          {entity.name}
        </Typography>
        {withCheckbox && (
          <Checkbox
            {...{
              className: 'ml-a',
              name: `${entity.id}_checkbox`,
              value: isSelected,
              onValueChange: newValue => onSelectedChange?.(newValue),
              htmlProps: {
                onClick: e => e.stopPropagation(),
              },
            }}
          />
        )}
      </div>
    );
  }
);
