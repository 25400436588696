import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SomaticCellsReportFragmentDoc } from '../fragments/somaticCellsReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SomaticCellsReportQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SomaticCellsReportQuery = { __typename?: 'Query', somaticCellsReport: { __typename: 'SomaticCellsReport', id: string, createdAt: string, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, settings: { __typename: 'SomaticCellsReportSettings', since?: string | null, till?: string | null, sccThousandsPerMl?: number | null, filters: Array<{ __typename: 'SomaticCellsReportSettingsHistoricFilter', kind?: Types.MilkingHistoricFilterKind | null, value?: { __typename: 'SomaticCellsReportSettingsHistoricValueLactationNumbers', lactationNumbers?: Array<number> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers', lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValuePenGroups', penGroups: Array<{ __typename: 'PenGroup', id: string, name: string, identifier: number }> } | null }>, blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string } | null }, calculatedReport: { __typename: 'SomaticCellsReportChartEmpty', dates: Array<string> } | { __typename: 'SomaticCellsReportChart', dates: Array<string>, researchedAnimalsCounts: Array<number | null>, chronicallyIllPercents: Array<number | null>, chronicallyIllCounts: Array<number | null>, illPercents: Array<number | null>, illCounts: Array<number | null>, recoveredPercents: Array<number | null>, recoveredCounts: Array<number | null>, healthyPercents: Array<number | null>, healthyCounts: Array<number | null>, highValueFirstTestMilkingPercents: Array<number | null>, highValueFirstTestMilkingCounts: Array<number | null>, normalValueFirstTestMilkingPercents: Array<number | null>, normalValueFirstTestMilkingCounts: Array<number | null>, probabilityOfRecoveryPercents: Array<number | null>, probabilityOfIllnessPercents: Array<number | null>, cacheCreatedAt: string } } };


export const SomaticCellsReportDocument = gql`
    query somaticCellsReport {
  somaticCellsReport {
    ...SomaticCellsReport
  }
}
    ${SomaticCellsReportFragmentDoc}`;

/**
 * __useSomaticCellsReportQuery__
 *
 * To run a query within a React component, call `useSomaticCellsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSomaticCellsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSomaticCellsReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useSomaticCellsReportQuery(baseOptions?: Apollo.QueryHookOptions<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>(SomaticCellsReportDocument, options);
      }
export function useSomaticCellsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>(SomaticCellsReportDocument, options);
        }
export function useSomaticCellsReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>(SomaticCellsReportDocument, options);
        }
export type SomaticCellsReportQueryHookResult = ReturnType<typeof useSomaticCellsReportQuery>;
export type SomaticCellsReportLazyQueryHookResult = ReturnType<typeof useSomaticCellsReportLazyQuery>;
export type SomaticCellsReportSuspenseQueryHookResult = ReturnType<typeof useSomaticCellsReportSuspenseQuery>;
export type SomaticCellsReportQueryResult = Apollo.QueryResult<SomaticCellsReportQuery, SomaticCellsReportQueryVariables>;