import { ApolloCache } from '@apollo/client';
import { Modifier } from '@apollo/client/cache';

import R from 'ramda';

/**
 * Factory for making delete query from cache function.
 * It removes a query from ROOT_QUERY, so it will be requested again.
 * It is a workaround for this issue
 * https://github.com/apollographql/apollo-client/issues/5419
 */
export const makeDeleteQuery =
  (queryName: string) => (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: queryName });

    cache.gc();
  };

/**
 * Remove query by name from cache with a predicate
 * Don't use evict, cause it doesn't support predicates
 * https://github.com/apollographql/apollo-feature-requests/issues/238
 */
const makeDeleteQueryByNameWithPredicate =
  (predicate: Modifier<any>) =>
  (queryName: string) =>
  (cache: ApolloCache<any>) => {
    cache.modify({
      fields: {
        [queryName]: predicate,
      },
    });

    cache.gc();
  };

/**
 * Fabric to create helper that deletes a query with specified variables from cache
 */
export const makeDeleteQueryByNameWithVariables = (
  queryName: string,
  variables: Record<string, any>
) =>
  makeDeleteQueryByNameWithPredicate(
    (queryData, { DELETE, storeFieldName }) => {
      if (!storeFieldName.includes(JSON.stringify(variables))) {
        return queryData;
      }
      return DELETE;
    }
  )(queryName);

/**
 * Fabric to create helper that deletes from cache all queries, that doesn't contain passed variables
 * Useful for clearing some queries, that are not displayed at the moment
 * to trigger future refetch, cause refetchQueries only works for currently rendered queries
 */
export const makeDeleteQueriesByNameWithoutVariables = (
  queryName: string,
  variables: Record<string, any>
) =>
  makeDeleteQueryByNameWithPredicate(
    (queryData, { DELETE, storeFieldName }) => {
      if (
        storeFieldName.includes(JSON.stringify(variables)) ||
        (R.isEmpty(variables) && !storeFieldName.includes('{'))
      ) {
        return queryData;
      }
      return DELETE;
    }
  )(queryName);
