import React from 'react';

import { Link, LinkProps } from '@tanstack/react-router';

import './MBreadCrumbs.scss';

export interface BreadCrumbConfig {
  linkProps: LinkProps;
  value: string;
}

interface Props {
  links: BreadCrumbConfig[];
}

export const MBreadCrumbs: React.FC<Props> = ({ links }) => {
  const len = links.length;
  const breadCrumbs = links.map((item, i) => {
    const endAdornment = i !== len - 1 ? <span>&gt;</span> : null;

    return (
      <React.Fragment key={item.linkProps.to + item.value}>
        <Link
          {...{
            ...item.linkProps,
            activeProps: {
              className: 'm-breadcrumbs-item active',
            },
            inactiveProps: {
              className: 'm-breadcrumbs-item',
            },
          }}
        >
          {item.value}
        </Link>
        {endAdornment}
      </React.Fragment>
    );
  });
  return <nav className="m-breadcrumbs">{breadCrumbs}</nav>;
};
