import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { secondsToMinutes } from '~/services/dateTime';

import { MilkingParlorMeterFunctionReportAverageRowEntryFragment } from '../../gql/fragments/milkingParlorMeterFunctionReportAverageRowEntry.graphql';
import { MilkingParlorMeterFunctionReportRowEntryFragment } from '../../gql/fragments/milkingParlorMeterFunctionReportRowEntry.graphql';
import { MilkingParlorMeterFunctionReportToleranceRowEntryFragment } from '../../gql/fragments/milkingParlorMeterFunctionReportToleranceRowEntry.graphql';

type TableRow =
  | MilkingParlorMeterFunctionReportRowEntryFragment
  | MilkingParlorMeterFunctionReportAverageRowEntryFragment
  | MilkingParlorMeterFunctionReportToleranceRowEntryFragment
  | SkeletonPlaceholder;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking report rows to display
   */
  milkingParlorMeterFunctionReportRows: TableRow[];
}

const isStallReportRow = (
  row: TableRow
): row is MilkingParlorMeterFunctionReportRowEntryFragment =>
  row.__typename === 'MilkingParlorMeterFunctionReportRowEntry';

const isAverageReportRow = (
  row: TableRow
): row is MilkingParlorMeterFunctionReportAverageRowEntryFragment =>
  row.__typename === 'MilkingParlorMeterFunctionReportAverageRowEntry';

const isToleranceReportRow = (
  row: TableRow
): row is MilkingParlorMeterFunctionReportToleranceRowEntryFragment =>
  row.__typename === 'MilkingParlorMeterFunctionReportToleranceRowEntry';

const STALL_NUMBER_COLUMN_WIDTH_PX = 160;
const OTHER_COLUMN_WIDTH_PX = 83;

const TABLE_FLOAT_CELL_PRECISION = 1;

const BASE_REPORT_COLUMN_PROPS = {
  columnClassName: 'text-right',
  cellTypographyProps: {
    skeletonProps: {
      size: TextSkeletonSizes.small,
    },
  },
  width: OTHER_COLUMN_WIDTH_PX,
};

export const MilkingMeterFunctionReportTable: React.FC<Props> = ({
  className,
  milkingParlorMeterFunctionReportRows,
}) => {
  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      key: 'stallNumber',
      title: 'Номер аппарата',
      renderCellContent: row => {
        if (isAverageReportRow(row)) {
          return 'Среднее значение';
        }
        if (isToleranceReportRow(row)) {
          return 'Допуст. отклонение';
        }
        return formatInt(row.stallNumber);
      },
      width: STALL_NUMBER_COLUMN_WIDTH_PX,
    },
    {
      key: 'cowsCount',
      title: 'Кол-во коров',
      renderCellContent: row => formatInt(row.cowsCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkDeviationFromExpectedKilograms',
      title: 'Откл. от ожидаем. надоя',
      renderCellContent: row =>
        formatInt(row.milkDeviationFromExpectedKilograms),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkWeightKilograms',
      title: 'Надой',
      renderCellContent: row => formatInt(row.milkWeightKilograms),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'durationSeconds',
      title: 'Время доения',
      renderCellContent: row =>
        !R.isNil(row.durationSeconds)
          ? formatNumber(
              secondsToMinutes(row.durationSeconds),
              TABLE_FLOAT_CELL_PRECISION
            )
          : '',
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'flowRate',
      title: 'Скорость потока',
      renderCellContent: row =>
        formatNumber(row.flowRate, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'conductivity',
      title: 'Эл-проводн.',
      renderCellContent: row =>
        formatNumber(row.conductivity, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'avgPeakFlowRate',
      title: 'Среднее знач. пик. потока',
      renderCellContent: row => formatInt(row.avgPeakFlowRate),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'fallCount',
      title: 'Кол-во спадений',
      renderCellContent: row => formatInt(row.fallCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'manualModeCount',
      title: 'Доение в ручном режиме',
      renderCellContent: row => formatInt(row.manualModeCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'manualDetachCount',
      title: 'Ручное откл.',
      renderCellContent: row => formatInt(row.manualDetachCount),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'notIdentifiedCount',
      title: 'Неидент. коров',
      renderCellContent: row =>
        isStallReportRow(row) ? formatInt(row.notIdentifiedCount) : '',
      ...BASE_REPORT_COLUMN_PROPS,
    },
  ];

  return (
    <Table<TableRow>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        getItemKey: (item, index) => {
          if (isAverageReportRow(item)) {
            return 'average';
          }
          if (isToleranceReportRow(item)) {
            return 'tolerance';
          }
          return item.stallNumber?.toString() ?? index;
        },
        items: milkingParlorMeterFunctionReportRows,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
