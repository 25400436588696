import React from 'react';

import { createFileRoute } from '@tanstack/react-router';

import { makeBeforeLoadRedirect } from '~/services/navigation';

import { isBullCow } from '~/entities/cows';
import { useCowDetailedQuery } from '~/entities/cows/gql/queries/cowDetailed.graphql';

import { BullEventsTabContent } from './components/BullEventsTabContent';
import { MilkDataTabContent } from './components/MilkDataTabContent';

export const Route = createFileRoute(
  '/$companyId/_layout/user/cows/$cowId/info'
)({
  component: CowInfoPage,
  beforeLoad: makeBeforeLoadRedirect<{ cowId: string; companyId: string }>({
    from: '/$companyId/user/cows/$cowId/info',
    to: '/$companyId/user/cows/$cowId/info/events',
  }),
});

function CowInfoPage() {
  const { cowId } = Route.useParams();

  const { data: cowDetailedData } = useCowDetailedQuery({
    variables: {
      id: cowId ?? '',
    },
    skip: !cowId,
  });

  const cow = cowDetailedData?.cow;
  const isBull = isBullCow(cow);

  if (!cow) {
    return null;
  }

  return isBull ? (
    <BullEventsTabContent key={cow.id} cow={cow} />
  ) : (
    <MilkDataTabContent key={cow.id} cow={cow} />
  );
}
