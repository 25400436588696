import { useMemo } from 'react';

import dayjs from 'dayjs';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { formatInt } from '~/shared/helpers/number';

import { DATE_RANGE_REGEXP, formatDateRange } from '~/services/dateTime';

import { ReproductionHdrAndPrRowFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrRow.graphql';

import { REPRODUCTION_CALCULATION_PERIOD_DAYS } from '../constants';
import { formatXAxisModeName } from '../helpers';
import { HdrAndPrReportDisplayProps } from '../types';

interface UseReproductionHdrAndPrReportXAxisProps
  extends Omit<HdrAndPrReportDisplayProps, 'yAxisMetrics'> {
  /**
   * If true, adds total to xAxisLabels
   */
  shouldShowTotal?: boolean;
}

const getChartDataXAxisLabels = (
  reportRows: (ReproductionHdrAndPrRowFragment | SkeletonPlaceholder)[]
) =>
  reportRows.map(row =>
    match(row)
      .with(P.when(isSkeletonPlaceholder), R.always(''))
      .with({ __typename: 'ReproductionHdrAndPrRowByDate' }, ({ date }) =>
        formatDateRange(
          date,
          dayjs(date).add(REPRODUCTION_CALCULATION_PERIOD_DAYS - 1, 'days')
        ).split(DATE_RANGE_REGEXP)
      )
      .with(
        { __typename: 'ReproductionHdrAndPrRowByDayInMilk' },
        ({ dayInMilk }) => formatInt(dayInMilk)
      )
      .exhaustive()
  );

/**
 * Hook for managing x axis labels and table first column periods display
 */
export const useReproductionHdrAndPrReportXAxis = ({
  reportRows,
  historicReportRows,
  xAxisMode,
  femaleAnimalKind,
  shouldShowTotal = false,
}: UseReproductionHdrAndPrReportXAxisProps) => {
  const isComparison = !!historicReportRows;

  const xAxisName = formatXAxisModeName(xAxisMode, femaleAnimalKind);

  const getXAxisLabels = (
    data: (ReproductionHdrAndPrRowFragment | SkeletonPlaceholder)[]
  ) => [
    ...getChartDataXAxisLabels(data),
    ...wrapConditionalArrayElement(shouldShowTotal && 'Всего'),
  ];

  const xAxisLabels = useMemo(() => getXAxisLabels(reportRows), [reportRows]);
  const historicXAxisLabels = useMemo(
    () => (historicReportRows ? getXAxisLabels(historicReportRows.rows) : []),
    [historicReportRows]
  );

  const getXAxisLabelByRowIndex = (
    rowIndex: number,
    shouldShowComparisonLabels = false
  ) => {
    if (!isComparison || !shouldShowComparisonLabels) {
      return xAxisLabels.at(rowIndex);
    }

    const normalizedRowIndex = Math.floor(rowIndex / 2);
    return rowIndex % 2
      ? historicXAxisLabels.at(normalizedRowIndex)
      : xAxisLabels.at(normalizedRowIndex);
  };

  return {
    xAxisName,
    xAxisLabels,
    getXAxisLabelByRowIndex,
  };
};
