import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { Button } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Skeleton } from '~/shared/components/Skeleton';

import { useEditEventModal } from '~/entities/events';
import { useEventsQuery } from '~/entities/events/gql/queries/events.graphql';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { EventsTable } from './components/EventsTable';

// TODO switch to pagination, when design and product are ready
const MAX_EVENTS_TO_LOAD = 1000;

export const Route = createFileRoute('/$companyId/_layout/user/events/all/')({
  wrapInSuspense: true,
  component: AllEventsPage,
});

function AllEventsPage() {
  const { data: eventsQueryData, loading: isLoading } = useEventsQuery({
    variables: { first: MAX_EVENTS_TO_LOAD },
    notifyOnNetworkStatusChange: true,
  });
  const events = eventsQueryData?.events.edges.map(R.prop('node')) ?? [];

  const { open: openEditEventModal } = useEditEventModal();

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Все события',
          rightContent: (
            <Button
              iconVariant={IconVariants.plus}
              onPress={() => openEditEventModal()}
            >
              Добавить событие
            </Button>
          ),
        }}
      />
      <div className={clsx(panelStyles.largePanel, 'p-24')}>
        <Skeleton isLoading={isLoading}>
          <EventsTable
            {...{
              events,
              isLoading,
            }}
          />
        </Skeleton>
      </div>
    </div>
  );
}
