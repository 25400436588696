import React, { useCallback } from 'react';

import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { MInput, MInputProps } from '~/shared/components/MInput';

export const FloatEdit: React.FC<
  ValueEditorStandardProps<number | string>
> = props => {
  const {
    value,
    onChange,
    onKeyDown,
    caption,
    onBlur,
    onFocus,
    className,
    noBorder,
    noForm,
    onPaste,
    ...other
  } = props;

  const valueToRender: number | string =
    value === undefined || value === null ? '' : Number(value);

  const valueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputVal = e.target.value;
      const valToSet = inputVal === '' ? inputVal : Number(inputVal);

      onChange?.(valToSet);
    },
    [onChange]
  );

  return (
    <MInput
      {...(other as MInputProps)}
      type="number"
      className={noForm ? className : ''}
      placeholder={caption || 'Введите число'}
      noBorder={noBorder}
      step="0.1"
      onChange={valueChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      onPaste={onPaste}
      value={valueToRender === undefined ? '' : valueToRender}
    />
  );
};
