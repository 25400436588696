import React, { useMemo } from 'react';

import { ChartOptions } from 'chart.js';
import clsx from 'clsx';
import R from 'ramda';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { formatInt } from '~/shared/helpers/number';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { formatDate } from '~/services/dateTime';

import {
  BarChart,
  BarChartDataPoint,
  BarChartDatasetConfig,
  BarChartType,
  CHART_COLOR_PROPS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DataPointsTooltip,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
  getScaleOptions,
  LINEAR_Y_SCALE_OPTIONS,
  PERCENT_LINEAR_Y_SCALE_OPTIONS,
  ReactChartProps,
} from '~/features/charts';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';
import TOKENS from '~/styles/__generated__/tokens.json';
import panelStyles from '~/styles/modules/panel.module.scss';

import { SomaticCellsReportChartFragment } from '../../../../gql/fragments/somaticCellsReportChart.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Report data to render as chart
   */
  reportData: SomaticCellsReportChartFragment | SkeletonPlaceholder;
}

const CHART_HEIGHT_PX = 644;

export const SomaticCellsReportChart: React.FC<Props> = ({
  className,
  reportData,
}) => {
  const xAxisLabels = (reportData.dates ?? []).map(d => formatDate(d));

  const { datasets, chartOptions } = useMemo(() => {
    const reportDatasets = [
      {
        key: 'chronicallyIllCounts' as const,
        label: 'Болеющие хронически',
        type: 'bar',
        tooltipConfig: {
          getMainValue: point =>
            reportData.chronicallyIllPercents?.[point.dataIndex],
          getCowsCount: point =>
            reportData.chronicallyIllCounts?.[point.dataIndex],
        },
      },
      {
        key: 'illCounts' as const,
        label: 'Заболевшие',
        type: 'bar',
        tooltipConfig: {
          getMainValue: point => reportData.illPercents?.[point.dataIndex],
          getCowsCount: point => reportData.illCounts?.[point.dataIndex],
        },
      },
      {
        key: 'recoveredCounts' as const,
        label: 'Выздоровевшие',
        type: 'bar',
        tooltipConfig: {
          getMainValue: point =>
            reportData.recoveredPercents?.[point.dataIndex],
          getCowsCount: point => reportData.recoveredCounts?.[point.dataIndex],
        },
      },
      {
        key: 'probabilityOfRecoveryPercents' as const,
        label: 'Коэффициент выздоровления',
        type: 'line',
        tooltipConfig: {
          getMainValue: point =>
            reportData.probabilityOfRecoveryPercents?.[point.dataIndex],
        },
      },
      {
        key: 'probabilityOfIllnessPercents' as const,
        label: 'Коэффициент заболеваемости',
        type: 'line',
        tooltipConfig: {
          getMainValue: point =>
            reportData.probabilityOfIllnessPercents?.[point.dataIndex],
        },
      },
      {
        key: 'highValueFirstTestMilkingPercents' as const,
        label: 'Высокое значение на первой КД',
        type: 'line',
        tooltipConfig: {
          getMainValue: point =>
            reportData.highValueFirstTestMilkingPercents?.[point.dataIndex],
          getCowsCount: point =>
            reportData.highValueFirstTestMilkingCounts?.[point.dataIndex],
        },
      },
    ] satisfies Partial<BarChartDatasetConfig>[];

    const animalCountDataKeys = reportDatasets
      .filter(({ type }) => type === 'bar')
      .map(({ key }) => key);

    const probabilityDataKeys = reportDatasets
      .filter(({ type }) => type === 'line')
      .map(({ key }) => key);

    const totalAnimalCountsByXAxis = xAxisLabels.map((_date, dateIndex) =>
      R.sum(animalCountDataKeys.map(key => reportData[key]?.[dateIndex] ?? 0))
    );

    const maxProbabilityByXAxis = xAxisLabels.map((_date, dateIndex) =>
      Math.max(
        ...probabilityDataKeys.map(key => reportData[key]?.[dateIndex] ?? 0)
      )
    );

    const datasetsInternal = reportDatasets.map<BarChartDatasetConfig>(
      (datasetConfig, reportDatasetIndex) => {
        return {
          ...datasetConfig,
          ...CHART_COLOR_PROPS_ITERATOR.next(!reportDatasetIndex).value,
          isTogglable: true,
          data: (reportData[datasetConfig.key] ?? []).map(v =>
            R.isNil(v) ? NaN : v
          ),
          ...wrapConditionalObjectElement(
            datasetConfig.type === 'line' && {
              yAxisID: 'rightY',
            }
          ),
        };
      }
    );

    const chartOptionsInternal = {
      interaction: {
        mode: 'index',
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: 'Дата контрольной дойки',
          },
          grid: {
            display: true,
          },
        },
        y: getScaleOptions(LINEAR_Y_SCALE_OPTIONS, {
          stacked: true,
          title: { display: true, text: 'Животные' },
          min: 0,
          max: Math.max(0, ...totalAnimalCountsByXAxis) + 1,
        }),
        rightY: getScaleOptions(PERCENT_LINEAR_Y_SCALE_OPTIONS, {
          max: Math.max(100, ...maxProbabilityByXAxis),
          grid: {
            display: true,
            color: 'transparent',
            tickColor: TOKENS.colorBorderMuted,
            tickLength: NUMBER_TOKENS.size16 + NUMBER_TOKENS.spacing12,
            tickBorderDash: [NUMBER_TOKENS.size16, NUMBER_TOKENS.spacing12],
            // Offset by tick width to start drawing with left padding on the right axis
            tickBorderDashOffset: NUMBER_TOKENS.size16,
          },
          ticks: {
            stepSize: undefined,
            padding: NUMBER_TOKENS.spacing12,
          },
          position: 'right',
        }),
      },
      plugins: {
        zoom: {
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: CHART_X_SCALE_MIN_RANGE,
            },
            y: {
              min: 'original',
              max: 'original',
              minRange: CHART_Y_SCALE_MIN_RANGE,
            },
            rightY: {
              min: 'original',
              max: 'original',
              minRange: CHART_Y_SCALE_MIN_RANGE,
            },
          },
          zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
          pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
        },
      },
    } satisfies ChartOptions;

    return {
      datasets: datasetsInternal,
      chartOptions: chartOptionsInternal,
    };
  }, [reportData]);

  const renderTooltip: ReactChartProps['renderTooltip'] = dataPoints => {
    const firstPoint = dataPoints.at(0);

    return (
      <DataPointsTooltip<BarChartType, BarChartDataPoint[]>
        {...{
          title: firstPoint && xAxisLabels.at(firstPoint.dataIndex),
          dataPoints,
          datasets,
          formatMainValue: formatInt,
          mainValueMeasurementUnit: '%',
        }}
      />
    );
  };

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
      <BarChart
        {...{
          height: CHART_HEIGHT_PX,
          chartOptions,
          legendClassName: 'mb-24',
          isStackedX: true,
          datasets,
          labels: xAxisLabels,
          renderTooltip,
        }}
      />
    </div>
  );
};
