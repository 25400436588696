import { makeUpdateQuery } from '~/services/gql';

import { MonitorEntryFragment } from './gql/fragments/monitorEntry.graphql';
import { MonitorLaunchCalculatingValueFragment } from './gql/fragments/monitorLaunchCalculatingValue.graphql';
import { MonitorLaunchErrorValueFragment } from './gql/fragments/monitorLaunchErrorValue.graphql';
import { MonitorLaunchResultValueFragment } from './gql/fragments/monitorLaunchResultValue.graphql';
import { MonitorDocument, MonitorQuery } from './gql/queries/monitor.graphql';
import { AnyMonitorEntry, AnyMonitorValue } from './types';

/**
 * Checks, if the item is MonitorEntryFragment
 */
export const isMonitorEntry = (
  item: AnyMonitorEntry
): item is MonitorEntryFragment => item.__typename === 'MonitorEntry';

/**
 * Checks, if the item is MonitorLaunchCalculatingValue
 */
export const isMonitorLaunchCalculatingValue = (
  item: AnyMonitorValue | undefined
): item is MonitorLaunchCalculatingValueFragment =>
  item?.__typename === 'MonitorLaunchCalculatingValue';

/**
 * Checks, if the item is MonitorLaunchErrorValue
 */
export const isMonitorLaunchErrorValue = (
  item: AnyMonitorValue | undefined
): item is MonitorLaunchErrorValueFragment =>
  item?.__typename === 'MonitorLaunchErrorValue';

/**
 * Checks, if the item is MonitorLaunchResultValue
 */
export const isMonitorLaunchResultValue = (
  item: AnyMonitorValue | undefined
): item is MonitorLaunchResultValueFragment =>
  item?.__typename === 'MonitorLaunchResultValue';

/**
 * Factory for updating monitor query after some mutation
 */
export const makeUpdateMonitorQuery =
  makeUpdateQuery<MonitorQuery>(MonitorDocument);
