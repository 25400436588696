import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteProtocolInjectionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteProtocolInjectionMutation = { __typename?: 'Mutation', deleteProtocolInjection?: any | null };


export const DeleteProtocolInjectionDocument = gql`
    mutation deleteProtocolInjection($id: ID!) {
  deleteProtocolInjection(id: $id)
}
    `;
export type DeleteProtocolInjectionMutationFn = Apollo.MutationFunction<DeleteProtocolInjectionMutation, DeleteProtocolInjectionMutationVariables>;

/**
 * __useDeleteProtocolInjectionMutation__
 *
 * To run a mutation, you first call `useDeleteProtocolInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProtocolInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProtocolInjectionMutation, { data, loading, error }] = useDeleteProtocolInjectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProtocolInjectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProtocolInjectionMutation, DeleteProtocolInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProtocolInjectionMutation, DeleteProtocolInjectionMutationVariables>(DeleteProtocolInjectionDocument, options);
      }
export type DeleteProtocolInjectionMutationHookResult = ReturnType<typeof useDeleteProtocolInjectionMutation>;
export type DeleteProtocolInjectionMutationResult = Apollo.MutationResult<DeleteProtocolInjectionMutation>;
export type DeleteProtocolInjectionMutationOptions = Apollo.BaseMutationOptions<DeleteProtocolInjectionMutation, DeleteProtocolInjectionMutationVariables>;