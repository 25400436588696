import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { RoleDetailedFragmentDoc } from '../../../../services/auth/gql/fragments/roleDetailed.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRoleMutationVariables = Types.Exact<{
  input: Types.CreateBlueprintRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor, shouldBeMigratedOnDelete: boolean } };


export const CreateRoleDocument = gql`
    mutation createRole($input: CreateBlueprintRoleInput!) {
  createRole(input: $input) {
    ...RoleDetailed
  }
}
    ${RoleDetailedFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;