import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SourceFieldFragmentDoc } from '../fragments/sourceField.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlueprintSourceFieldsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  onlyMaster: Types.Scalars['Boolean']['input'];
}>;


export type BlueprintSourceFieldsQuery = { __typename?: 'Query', blueprintSourceFields: { __typename?: 'SourceFieldConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'SourceFieldEdge', cursor: string, node: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } }> } };


export const BlueprintSourceFieldsDocument = gql`
    query blueprintSourceFields($after: String, $first: Int, $search: String, $ids: [ID!], $onlyMaster: Boolean!) {
  blueprintSourceFields(
    after: $after
    first: $first
    search: $search
    ids: $ids
    onlyMaster: $onlyMaster
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...SourceField
      }
    }
  }
}
    ${SourceFieldFragmentDoc}`;

/**
 * __useBlueprintSourceFieldsQuery__
 *
 * To run a query within a React component, call `useBlueprintSourceFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintSourceFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintSourceFieldsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *      onlyMaster: // value for 'onlyMaster'
 *   },
 * });
 */
export function useBlueprintSourceFieldsQuery(baseOptions: Apollo.QueryHookOptions<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables> & ({ variables: BlueprintSourceFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables>(BlueprintSourceFieldsDocument, options);
      }
export function useBlueprintSourceFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables>(BlueprintSourceFieldsDocument, options);
        }
export function useBlueprintSourceFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables>(BlueprintSourceFieldsDocument, options);
        }
export type BlueprintSourceFieldsQueryHookResult = ReturnType<typeof useBlueprintSourceFieldsQuery>;
export type BlueprintSourceFieldsLazyQueryHookResult = ReturnType<typeof useBlueprintSourceFieldsLazyQuery>;
export type BlueprintSourceFieldsSuspenseQueryHookResult = ReturnType<typeof useBlueprintSourceFieldsSuspenseQuery>;
export type BlueprintSourceFieldsQueryResult = Apollo.QueryResult<BlueprintSourceFieldsQuery, BlueprintSourceFieldsQueryVariables>;