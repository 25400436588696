import { ValueKindEnum, VitalityFilter } from '~/shared/graphql';
import { PartialRecord } from '~/shared/types/utility';

import { ValueEditorKind } from '~/widgets/blueprintEdit';

const argsMap: PartialRecord<ValueEditorKind, any> = {
  [ValueKindEnum.CowId]: {
    vitality: VitalityFilter.Alive,
  },
};

export function getAdditionalArgs(kind: ValueEditorKind) {
  return argsMap[kind];
}
