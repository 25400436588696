import { makeUpdateFragment } from '~/services/gql';

import { UserFragment, UserFragmentDoc } from './gql/fragments/user.graphql';

/**
 * Update User in the cache
 */
export const updateUserFragment = makeUpdateFragment<UserFragment>({
  typeName: 'User',
  fragment: UserFragmentDoc,
});
