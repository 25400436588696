import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  BlueprintOrderableEntity,
  BlueprintOrderableEntityProps,
} from '../BlueprintOrderableEntity';

export const BlueprintOrderableEntitySortable: React.FC<
  BlueprintOrderableEntityProps
> = props => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.entity.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <BlueprintOrderableEntity
      {...{
        ref: setNodeRef,
        ...attributes,
        style,
        isDragging,
        withDragHandle: true,
        dragHandleProps: {
          ref: setActivatorNodeRef,
          ...listeners,
        },
        ...props,
      }}
    />
  );
};
