import { ModalNames } from '../__generated__/modalNames';
import { useModalsContext } from '../context';
import { AnyModalProps, InjectedModalProps } from '../types';

interface UseModalInterface<Props extends AnyModalProps | undefined>
  extends InjectedModalProps<Props> {
  isOpen: boolean;
  stackIndex: number;
  maxModalIndex: number;
  props: Props;
}

/**
 * Hook returns interface to control modal by name.
 * @param {string} name - string - The name of the modal.
 * @param {P} [props] - The default props to pass to the modal when it's opened.
 */
export const useModal = <P extends AnyModalProps>(
  name: ModalNames,
  props?: P
) => {
  const { state, openModal, closeModal, setModalStep } = useModalsContext();

  const modalState = state[name] ?? {};

  const maxModalIndex = Object.values(state).filter(m => m.isOpen).length - 1;

  const { isOpen, stackIndex, props: currentProps, stepNumber } = modalState;

  const setStepNumber: React.Dispatch<
    React.SetStateAction<number>
  > = stepOrGetStep => {
    let stepToSet = stepOrGetStep;
    if (typeof stepToSet === 'function') {
      stepToSet = stepToSet(stepNumber);
    }
    setModalStep(name, stepToSet);
  };

  const res: UseModalInterface<P> = {
    stackIndex,
    maxModalIndex,
    isOpen,
    props: currentProps as P,
    open: overrideProps => openModal(name, { ...props, ...overrideProps }),
    close: () => closeModal(name),
    stepNumber,
    setStepNumber,
    goToNextStep: () => setStepNumber(current => current + 1),
    goToPrevStep: () => setStepNumber(current => current - 1),
  };

  return res;
};
