import React from 'react';

import { DataRow } from '~/~legacy/services/BlueprintExecutionService';

import styles from './index.module.scss';

interface Props {
  row: DataRow;
  level?: number;
}

export const TableRow: React.FC<Props> = ({ row, level = 0 }) => {
  const data = JSON.parse(row.row) as any;

  return (
    <tr>
      {Object.keys(data).map(key => (
        <td key={key} className={level > 0 ? styles.shift : undefined}>
          {data[key] === null ? '' : data[key]}
        </td>
      ))}
    </tr>
  );
};
