import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { BlueprintLaunchFragment } from '../gql/fragments/blueprintLaunch.graphql';
import {
  BlueprintLaunchesQuery,
  BlueprintLaunchesQueryVariables,
  useBlueprintLaunchesQuery,
} from '../gql/queries/blueprintLaunches.graphql';

export const useBlueprintLaunchesPaginatedQuery = makeUsePaginatedQuery<
  BlueprintLaunchFragment,
  BlueprintLaunchesQuery,
  BlueprintLaunchesQueryVariables
>({
  useQuery: useBlueprintLaunchesQuery,
  getItemsFromQueryData: data =>
    data.blueprintLaunches.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.blueprintLaunches.pageInfo,
});
