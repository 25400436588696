import { ReproductionYAxisMode } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { REPRODUCTION_Y_AXIS_MODE_DICT } from '../../constants';

const useReproductionYAxisModeSelect = makeUseEnumSelect(
  ReproductionYAxisMode,
  enumValue => REPRODUCTION_Y_AXIS_MODE_DICT[enumValue as ReproductionYAxisMode]
);

export const ReproductionYAxisModeSelect = makeSelectComponentFromHook(
  useReproductionYAxisModeSelect
);
