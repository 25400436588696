import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CompanyShortFragmentDoc } from '../../../../entities/companies/gql/fragments/companyShort.graphql';
import { SomaticCellsReportSettingsFragmentDoc } from './somaticCellsReportSettings.graphql';
import { SomaticCellsReportChartFragmentDoc } from './somaticCellsReportChart.graphql';
export type SomaticCellsReportFragment = { __typename: 'SomaticCellsReport', id: string, createdAt: string, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, settings: { __typename: 'SomaticCellsReportSettings', since?: string | null, till?: string | null, sccThousandsPerMl?: number | null, filters: Array<{ __typename: 'SomaticCellsReportSettingsHistoricFilter', kind?: Types.MilkingHistoricFilterKind | null, value?: { __typename: 'SomaticCellsReportSettingsHistoricValueLactationNumbers', lactationNumbers?: Array<number> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers', lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValuePenGroups', penGroups: Array<{ __typename: 'PenGroup', id: string, name: string, identifier: number }> } | null }>, blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string } | null }, calculatedReport: { __typename: 'SomaticCellsReportChartEmpty', dates: Array<string> } | { __typename: 'SomaticCellsReportChart', dates: Array<string>, researchedAnimalsCounts: Array<number | null>, chronicallyIllPercents: Array<number | null>, chronicallyIllCounts: Array<number | null>, illPercents: Array<number | null>, illCounts: Array<number | null>, recoveredPercents: Array<number | null>, recoveredCounts: Array<number | null>, healthyPercents: Array<number | null>, healthyCounts: Array<number | null>, highValueFirstTestMilkingPercents: Array<number | null>, highValueFirstTestMilkingCounts: Array<number | null>, normalValueFirstTestMilkingPercents: Array<number | null>, normalValueFirstTestMilkingCounts: Array<number | null>, probabilityOfRecoveryPercents: Array<number | null>, probabilityOfIllnessPercents: Array<number | null>, cacheCreatedAt: string } };

export const SomaticCellsReportFragmentDoc = gql`
    fragment SomaticCellsReport on SomaticCellsReport {
  __typename
  id
  createdAt
  company {
    ...CompanyShort
  }
  settings {
    ...SomaticCellsReportSettings
  }
  calculatedReport {
    ... on SomaticCellsReportChartEmpty {
      __typename
      dates
    }
    ...SomaticCellsReportChart
  }
}
    ${CompanyShortFragmentDoc}
${SomaticCellsReportSettingsFragmentDoc}
${SomaticCellsReportChartFragmentDoc}`;