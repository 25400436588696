import React from 'react';

export const Upload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 1.875C10.1659 1.875 10.3249 1.94085 10.4421 2.05806L13.9421 5.55806C14.1862 5.80214 14.1862 6.19786 13.9421 6.44194C13.698 6.68602 13.3023 6.68602 13.0582 6.44194L10.6251 4.00888L10.6251 12.5C10.6251 12.8452 10.3453 13.125 10.0001 13.125C9.65496 13.125 9.37513 12.8452 9.37513 12.5L9.37513 4.00889L6.94208 6.44195C6.698 6.68603 6.30227 6.68603 6.05819 6.44195C5.81411 6.19787 5.81411 5.80215 6.05819 5.55807L9.55819 2.05806C9.6754 1.94085 9.83437 1.875 10.0001 1.875ZM2.22063 13.6076C2.52936 13.4533 2.90479 13.5784 3.05915 13.8872L3.80451 15.3779C4.26328 16.2954 5.20108 16.875 6.22692 16.875H13.7734C14.7992 16.875 15.737 16.2954 16.1958 15.3779L16.9411 13.8872C17.0955 13.5784 17.4709 13.4533 17.7796 13.6076C18.0884 13.762 18.2135 14.1374 18.0592 14.4462L17.3138 15.9369C16.6433 17.2779 15.2727 18.125 13.7734 18.125H6.22692C4.72761 18.125 3.35699 17.2779 2.68648 15.9369L1.94112 14.4462C1.78675 14.1374 1.91189 13.762 2.22063 13.6076Z"
      fill="currentColor"
    />
  </svg>
);
