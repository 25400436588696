import { ImmerReducer } from 'use-immer';

import { ModalNames } from '../../__generated__/modalNames';
import {
  AnyModalProps,
  ModalRegistrationsDict,
  ModalsState,
} from '../../types';

/**
 * Possible action variants for modal
 */
export enum ModalActionTypes {
  open = 'open',
  close = 'close',
  setStep = 'setStep',
}

/**
 * Action for opening a modal
 */
interface OpenModalAction<P extends AnyModalProps> {
  type: ModalActionTypes.open;
  payload: {
    name: ModalNames;
    props?: P;
    stepNumber?: number;
  };
}

/**
 * Action for closing a modal
 */
interface CloseModalAction {
  type: ModalActionTypes.close;
  payload: {
    name: ModalNames;
  };
}

/**
 * Action for setting a step in a modal
 */
interface SetStepModalAction {
  type: ModalActionTypes.setStep;
  payload: {
    name: ModalNames;
    stepNumber: number;
  };
}

/**
 * Possible actions for modal
 */
type ModalAction<P extends AnyModalProps> =
  | OpenModalAction<P>
  | CloseModalAction
  | SetStepModalAction;

/**
 * Creates initial state object from on modal registrations
 */
export const makeInitialState = (
  modalRegistrationsDict: ModalRegistrationsDict
) =>
  Object.keys(modalRegistrationsDict).reduce((acc, name) => {
    acc[name as ModalNames] = { isOpen: false, stackIndex: -1, stepNumber: 1 };
    return acc;
  }, {} as ModalsState);

/**
 * Reducer to control modals state
 */
export const reducer: ImmerReducer<ModalsState, ModalAction<AnyModalProps>> = (
  draft,
  action
) => {
  const { payload, type } = action;
  const { name } = payload;

  switch (type) {
    case ModalActionTypes.open: {
      const { props, stepNumber } = payload;

      const stackIndex = Object.values(draft).filter(
        modal => modal.isOpen
      ).length;

      draft[name].isOpen = true;
      draft[name].stackIndex = stackIndex;
      draft[name].props = props;
      draft[name].stepNumber = stepNumber ?? 1;

      break;
    }

    case ModalActionTypes.close:
      draft[name].isOpen = false;

      break;

    case ModalActionTypes.setStep:
      draft[name].stepNumber = payload.stepNumber;

      break;

    default:
      break;
  }
};
