import React from 'react';

import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { formatPenGroup } from '~/entities/penGroups';

import { CowFragmentDoc } from '../../gql/fragments/cow.graphql';
import { useCowsQuery } from '../../gql/queries/cows.graphql';
import { formatCow } from '../../helpers';
import { CowStateBadge } from '../CowStateBadge';

/**
 * Select for a cow
 */
export const useCowSelect = makeUseAsyncSelect({
  typeName: 'Cow',
  fragment: CowFragmentDoc,
  useQuery: useCowsQuery,
  getItemsFromQueryData: data => data.cows.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.cows.pageInfo,

  selectProps: {
    placeholder: 'Выберите животное',
    getItemValue: item => item.id,
    getItemText: item => {
      if (!item) return '';

      return formatCow(item, { prefix: '' });
    },
    renderItemText: item => {
      if (!item) return '';

      return (
        <div className="grid justify-items-start gap-4">
          {formatCow(item, { prefix: '' })}
          <CowStateBadge state={item.state} />
        </div>
      );
    },
    getItemDescription: item => {
      if (!item) return '';
      return formatPenGroup(item.penGroup);
    },
  },
});

export const CowAsyncSelect = makeAsyncSelectFormComponent(useCowSelect);
