import { DocumentNode, MutationUpdaterFn } from '@apollo/client';

import { Draft, produce } from 'immer';
import * as R from 'ramda';

/**
 * Factory for making apollo query update functions
 */
export const makeUpdateQuery =
  <
    QueryType extends any,
    QueryVariables extends Record<string, any> = Record<string, any>,
  >(
    query: DocumentNode,
    variables?: QueryVariables
  ) =>
  <Mutation extends any>(
    updateQueryData: (draft: Draft<QueryType>, mutationData: Mutation) => void,
    overrideVariables?: QueryVariables
  ): MutationUpdaterFn<Mutation> =>
  (client, { data }) => {
    const currentQuery = {
      query,
      variables: R.mergeDeepRight(
        variables ?? {},
        overrideVariables ?? {}
      ) as QueryVariables,
    };

    const currentQueryData = client.readQuery<QueryType, QueryVariables>(
      currentQuery
    );

    if (!data || !currentQueryData) return;

    client.writeQuery<QueryType, QueryVariables>({
      ...currentQuery,
      data: produce(currentQueryData, draft => updateQueryData(draft, data)),
    });
  };
