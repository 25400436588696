import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useEventCallback } from '~/shared/hooks/useEventCallback';
import { useEventListener } from '~/shared/hooks/useEventListener';

const LOCAL_STORAGE_CUSTOM_EVENT = 'local-storage-change';

declare global {
  interface WindowEventMap {
    [LOCAL_STORAGE_CUSTOM_EVENT]: CustomEvent;
  }
}

/**
 * Custom hook for using local storage to persist state across page reloads.
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] {
  const serialize = useCallback((value: T) => {
    return JSON.stringify(value);
  }, []);

  const deserialize = useCallback(
    (value: string) => {
      // Support 'undefined' as a value
      if (value === 'undefined') {
        return undefined as unknown as T;
      }

      return JSON.parse(value) as T;
    },
    [initialValue]
  );

  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    const initialValueToUse =
      initialValue instanceof Function ? initialValue() : initialValue;

    const raw = window.localStorage.getItem(key);
    return raw ? deserialize(raw) : initialValueToUse;
  }, [initialValue, key, deserialize]);

  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue: Dispatch<SetStateAction<T>> = useEventCallback(value => {
    // Allow value to be a function so we have the same API as useState
    const newValue = value instanceof Function ? value(readValue()) : value;

    // Save to local storage
    window.localStorage.setItem(key, serialize(newValue));

    // Save state
    setStoredValue(newValue);

    // We dispatch a custom event so every similar useLocalStorage hook is notified
    window.dispatchEvent(new StorageEvent(LOCAL_STORAGE_CUSTOM_EVENT, { key }));
  });

  useEffect(() => {
    setStoredValue(readValue());
  }, [key]);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue]
  );

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueToLocalStorage
  useEventListener(LOCAL_STORAGE_CUSTOM_EVENT, handleStorageChange);

  return [storedValue, setValue];
}
