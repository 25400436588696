/* eslint-disable no-underscore-dangle -- this file uses lots of private memebers, for now ignore it */
import { getInputValue } from '~/~legacy/helpers/getInputValue';
import { StandardService } from '~/~legacy/services/implementations/StandardService';

import { API_URL } from '~/shared/constants';
import {
  ActionType,
  BlueprintEditingInitializationQuery,
  BlueprintEditingInitializationQueryVariables,
  CraeteSectionNodeMutation,
  CraeteSectionNodeMutationVariables,
  CreateActionMutation,
  CreateActionMutationVariables,
  CreateBlueprintCycleInputMutation,
  CreateBlueprintCycleInputMutationVariables,
  CreateBlueprintUnionMutation,
  CreateBlueprintUnionMutationVariables,
  CreateFieldNodeMutation,
  CreateFieldNodeMutationVariables,
  CreateFilterConditionMutation,
  CreateFilterConditionMutationVariables,
  CreateFilterGroupMutation,
  CreateFilterGroupMutationVariables,
  CreateInputNodeMutation,
  CreateInputNodeMutationVariables,
  CreateSourceFieldInput,
  DeleteNodeMutation,
  DeleteNodeMutationVariables,
  GetFieldsDefinitionsBySectionQuery,
  GetFieldsDefinitionsBySectionQueryVariables,
  GetMasterBlueprintIdQuery,
  GetMasterBlueprintIdQueryVariables,
  SetArgumentMutation,
  SetArgumentMutationVariables,
  SetBlueprintViewSettingsInput,
  SourceSectionKindEnum,
  UnsetArgumentMutation,
  UnsetArgumentMutationVariables,
  UpdateBlueprintUnionMutation,
  UpdateBlueprintUnionMutationVariables,
  UpdateFiledNodeMutation,
  UpdateFiledNodeMutationVariables,
  UpdateFilterConditionMutation,
  UpdateFilterConditionMutationVariables,
  UpdateFilterGroupMutation,
  UpdateFilterGroupMutationVariables,
  UpdateInputNodeMutation,
  UpdateInputNodeMutationVariables,
  UpdateViewSettingsMutation,
  UpdateViewSettingsMutationVariables,
  ViewKindEnum,
} from '~/shared/graphql';

import {
  BlueprintEditingInitializationGQL,
  CreateActionNodeGQL,
  CreateBlueprintCycleInputGQL,
  CreateFieldNodeGQL,
  CreateFilterConditionGQL,
  CreateFilterGroupGQL,
  CreateInputNodeGQL,
  CreateSectionNodeGQL,
  CreateUnionGQL,
  DeleteNodeGQL,
  GetBlueprintSourceFieldsDefinitionBySectionNodeGQL,
  GetMasterBlueprintIdGQL,
  SetArgumentGQL,
  UnsetArgumentGQL,
  UpdateFieldNodeGQL,
  UpdateFilterConditionGQL,
  UpdateFilterGroupGQL,
  UpdateInputNodeGQL,
  UpdateUnionGQL,
  UpdateViewSettingsGQL,
} from '~/services/gql/queries/blueprintEdit.gql';

import { BlueprintEditResponseConverter } from './BlueprintEditResponseConverter';
import {
  BlueprintActionNode,
  BlueprintArgumentType,
  BlueprintCycleInputNode,
  BlueprintEditService,
  BlueprintFilterConditionNode,
  BlueprintFilterGroupNode,
  BlueprintInputNode,
  BlueprintNodeType,
  BlueprintSectionTypeItem,
  BlueprintSourceSection,
  BlueprintSourceSectionField,
  BlueprintUnionNode,
  BlueprintViewSetings,
  BlueprintWritableArgument,
  BluperintUnderEdit,
  DeleteNodeParams,
} from './types';

export class AppBlueprintEditService
  extends StandardService
  implements BlueprintEditService
{
  private _blueprintId = '';

  private _actionsDefinition: ActionType[] = [];

  get actionsDefinition() {
    return this._actionsDefinition;
  }

  private _sourceSectionsDefinition: BlueprintSectionTypeItem[] = [];

  get sourceSectionsDefinition() {
    return this._sourceSectionsDefinition;
  }

  private _sourceFieldsDefinitionsMap: Map<
    SourceSectionKindEnum,
    BlueprintSectionTypeItem
  > = new Map();

  private _blueprint: BluperintUnderEdit | null = null;

  get blueprint() {
    return this._blueprint;
  }

  private readonly converter = new BlueprintEditResponseConverter();

  async getMasterBlueprintId(): Promise<string | undefined> {
    const response = await this.http.gql<
      GetMasterBlueprintIdQuery,
      GetMasterBlueprintIdQueryVariables
    >(API_URL, {
      query: GetMasterBlueprintIdGQL,
      variables: {},
    });

    const id = response.blueprints.nodes.find(x => x)?.id;
    return id;
  }

  async initEditing(blueprintId: string): Promise<BluperintUnderEdit> {
    this._blueprintId = blueprintId;

    const response = await this.http.gql<
      BlueprintEditingInitializationQuery,
      BlueprintEditingInitializationQueryVariables
    >(API_URL, {
      query: BlueprintEditingInitializationGQL,
      variables: { id: blueprintId },
    });

    const [bluperintUnderEdit, sectionTypes, actionTypes] =
      this.converter.getDataFromInitializationResponse(response);

    this._blueprint = bluperintUnderEdit;
    this._actionsDefinition = actionTypes;
    this._sourceSectionsDefinition = sectionTypes.map(item => {
      const blueprintSectionTypeItem: BlueprintSectionTypeItem = {
        kind: item.kind,
        fields: item.fields,
        verboseName: item.verboseName,
      };

      this._sourceFieldsDefinitionsMap.set(item.kind, blueprintSectionTypeItem);
      return blueprintSectionTypeItem;
    });

    return this._blueprint;
  }

  private deleteSectionNode(nodeId: string) {
    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    const sections = source$?.value || [];
    const newSections = sections.filter(section => section.id !== nodeId);
    source$?.next(newSections);
  }

  private deleteFieldNode(nodeId: string) {
    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    const sections = source$?.value || [];
    const updatedSections = sections.map(section => {
      const updatedSection: BlueprintSourceSection = {
        id: section.id,
        kind: section.kind,
        verboseName: section.verboseName,
        fields: section.fields.filter(field => field.id !== nodeId),
      };
      return updatedSection;
    });

    source$?.next(updatedSections);
  }

  private deleteInputNode(nodeId: string) {
    const updatedInputs = this._blueprint?.inputNodes$.value.filter(
      input => input.id !== nodeId
    );

    this._blueprint?.inputNodes$.next(updatedInputs || []);
  }

  private deleteActionNode(nodeId: string) {
    const updatedActions = this._blueprint?.actionNodes$.value.filter(
      action => action.id !== nodeId
    );

    this._blueprint?.actionNodes$.next(updatedActions || []);
  }

  private deleteFilterGroupNode(nodeId: string) {
    const unionNodeId = this._blueprint?.filterNodes$.value.find(
      item => item.id === nodeId
    )?.unionId;
    if (unionNodeId) {
      this.deleteUnionNode(unionNodeId);
    }

    const updatedFilers = this._blueprint?.filterNodes$.value.filter(
      filter => filter.id !== nodeId
    );

    this._blueprint?.filterNodes$.next(updatedFilers || []);
  }

  private deleteFilterConditionNode(nodeId: string) {
    const updatedFilers = this._blueprint?.filterNodes$.value.map(filter => {
      return {
        ...filter,
        filters: filter.filters.filter(condition => condition.id !== nodeId),
      };
    });

    this._blueprint?.filterNodes$.next(updatedFilers || []);
  }

  private deleteCycleInputNode(nodeId: string) {
    const updatedCycleInputs = this._blueprint?.cycleInputNodes$.value.filter(
      input => input.id !== nodeId
    );

    this._blueprint?.cycleInputNodes$.next(updatedCycleInputs || []);
  }

  private deleteUnionNode(nodeId: string) {
    const updatedUnions =
      this._blueprint?.unionNodes$.value.filter(union => union.id !== nodeId) ||
      [];

    this._blueprint?.unionNodes$.next(updatedUnions);
  }

  async deleteNode(params: DeleteNodeParams): Promise<void> {
    if (!params.nodeId) throw new Error('Cant delete node without Id');

    await this.http.gql<DeleteNodeMutation, DeleteNodeMutationVariables>(
      API_URL,
      {
        query: DeleteNodeGQL,
        variables: {
          id: params.nodeId,
        },
      }
    );

    // clear nodes cache
    const deleteFromCacheFuncsMap = {
      [BlueprintNodeType.Section]: this.deleteSectionNode.bind(this),
      [BlueprintNodeType.Field]: this.deleteFieldNode.bind(this),
      [BlueprintNodeType.Input]: this.deleteInputNode.bind(this),
      [BlueprintNodeType.Action]: this.deleteActionNode.bind(this),
      [BlueprintNodeType.Union]: this.deleteUnionNode.bind(this),
      [BlueprintNodeType.FilterGroup]: this.deleteFilterGroupNode.bind(this),
      [BlueprintNodeType.FilterCondition]:
        this.deleteFilterConditionNode.bind(this),
      [BlueprintNodeType.CycleInputs]: this.deleteCycleInputNode.bind(this),
    } as Record<BlueprintNodeType, (nodeId: string) => void>;

    const deleteFromCachce =
      deleteFromCacheFuncsMap[params.nodeType] || (() => {});

    deleteFromCachce(params.nodeId);
  }

  getAvailableSections(): BlueprintSectionTypeItem[] {
    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    return this._sourceSectionsDefinition.filter(
      section => !source$?.value.find(item => item.kind === section.kind)
    );
  }

  async createSourceSection(section: SourceSectionKindEnum) {
    // make request
    const response = await this.http.gql<
      CraeteSectionNodeMutation,
      CraeteSectionNodeMutationVariables
    >(API_URL, {
      query: CreateSectionNodeGQL,
      variables: {
        input: {
          blueprintID: this._blueprintId,
          kind: section,
        },
      },
    });

    // insert response into list of section nodes
    const result =
      this.converter.sectionCreationResponseToSectionNode(response);

    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    const nodesList = source$?.value || [];
    source$?.next([...nodesList, result]);
  }

  async getFieldsForSection(section: SourceSectionKindEnum) {
    // take it from cache
    const cacheHit = this._sourceFieldsDefinitionsMap.has(section);
    if (cacheHit) {
      const result = this._sourceFieldsDefinitionsMap.get(section);
      return result?.fields || [];
    }

    // load definitions and store in cache
    const response = await this.http.gql<
      GetFieldsDefinitionsBySectionQuery,
      GetFieldsDefinitionsBySectionQueryVariables
    >(API_URL, {
      query: GetBlueprintSourceFieldsDefinitionBySectionNodeGQL,
      variables: {
        kind: section,
      },
    });

    const [fields, sectionVerboseName] =
      this.converter.sourceFieldsResponseToFieldsDefinition(response);

    this._sourceFieldsDefinitionsMap.set(section, {
      kind: section,
      verboseName: sectionVerboseName,
      fields,
    });

    return fields;
  }

  async createSourceField(input: CreateSourceFieldInput) {
    const response = await this.http.gql<
      CreateFieldNodeMutation,
      CreateFieldNodeMutationVariables
    >(API_URL, {
      query: CreateFieldNodeGQL,
      variables: {
        input,
      },
    });

    const result =
      this.converter.sourceFieldCreationResponseToAppModel(response);

    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    const updatedSections = (source$?.value ?? []).map(section => ({
      ...section,
      fields:
        section.id === input.blueprintSourceSectionID
          ? [...section.fields, result]
          : section.fields,
    }));

    source$?.next(updatedSections);

    return result;
  }

  async updateSourceField(field: BlueprintSourceSectionField): Promise<void> {
    await this.http.gql<
      UpdateFiledNodeMutation,
      UpdateFiledNodeMutationVariables
    >(API_URL, {
      query: UpdateFieldNodeGQL,
      variables: {
        id: field.id,
        input: {
          name: field.name,
        },
      },
    });

    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    const updatedSources =
      source$?.value.map(sourceNode => {
        return {
          ...sourceNode,
          fields: sourceNode.fields.map(sourceFiled =>
            sourceFiled.id === field.id ? field : sourceFiled
          ),
        };
      }) || [];

    source$?.next(updatedSources);
  }

  async createInputNode(
    input: BlueprintInputNode
  ): Promise<BlueprintInputNode> {
    const response = await this.http.gql<
      CreateInputNodeMutation,
      CreateInputNodeMutationVariables
    >(API_URL, {
      query: CreateInputNodeGQL,
      variables: {
        input: {
          blueprintID: this._blueprintId,
          name: input.name,
          prompt: input.prompt,
          blueprintCycleInputID: input.cycleInputId,
        },
      },
    });

    const inputNode = this.converter.fromCreateInputNode(response);
    this._blueprint?.inputNodes$.next([
      ...(this._blueprint?.inputNodes$.value ?? []),
      inputNode,
    ]);
    return inputNode;
  }

  async updateInputNode(
    input: BlueprintInputNode
  ): Promise<BlueprintInputNode> {
    if (!input.id) throw new Error('Id of updated input cant be empty');

    await this.http.gql<
      UpdateInputNodeMutation,
      UpdateInputNodeMutationVariables
    >(API_URL, {
      query: UpdateInputNodeGQL,
      variables: {
        id: input.id,
        input: {
          name: input.name,
          prompt: input.prompt,
        },
      },
    });

    const updatedInputs = this._blueprint?.inputNodes$.value.map(item =>
      item.id === input.id ? input : item
    );

    this._blueprint?.inputNodes$.next(updatedInputs || []);

    return input;
  }

  getAvailableActions(): ActionType[] {
    return this._actionsDefinition;
  }

  async createAction(input: BlueprintActionNode): Promise<BlueprintActionNode> {
    if (!input.kind) throw new Error('Action must have kind');

    const response = await this.http.gql<
      CreateActionMutation,
      CreateActionMutationVariables
    >(API_URL, {
      query: CreateActionNodeGQL,
      variables: {
        input: {
          blueprintID: this._blueprintId,
          kind: input.kind,
          blueprintFilterGroupID: input.filterGroupId,
        },
      },
    });

    const action = this.converter.fromCreateActionResponse(response);

    this._blueprint?.actionNodes$.next([
      ...(this._blueprint?.actionNodes$.value ?? []),
      action,
    ]);

    return action;
  }

  private updateSourceArgs(updatedArgument: BlueprintWritableArgument) {
    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    // update argument in section filed
    const updatedSources = source$?.value.map(section => {
      return {
        ...section,
        fields: section.fields.map(field => {
          return {
            ...field,
            arguments: field.arguments.map(arg => {
              return arg.id !== updatedArgument.id ? arg : updatedArgument;
            }),
          };
        }),
      };
    });
    source$?.next(updatedSources || []);
  }

  private updateActionArgs(argument: BlueprintWritableArgument) {
    const source$ = this._blueprint?.actionNodes$;

    // update argument in section filed
    const updatedActions = source$?.value.map(action => {
      return {
        ...action,
        arguments: action.arguments.map(item => {
          if (item.type === BlueprintArgumentType.ReadOnlyArgument) {
            return item;
          }

          return (item as BlueprintWritableArgument).id === argument.id
            ? argument
            : item;
        }),
      };
    });

    source$?.next(updatedActions || []);
  }

  async setArgument(argument: BlueprintWritableArgument): Promise<void> {
    if (!argument.valueKind)
      throw new Error('ValueKind of argument must be selected');

    const valueToSend = getInputValue(
      argument.inputValue,
      argument.kind,
      argument.valueKind
    );

    await this.http.gql<SetArgumentMutation, SetArgumentMutationVariables>(
      API_URL,
      {
        query: SetArgumentGQL,
        variables: {
          id: argument.id,
          input: {
            value: valueToSend,
            valueKind: argument.valueKind,
          },
        },
      }
    );

    if (argument.parentNodeType === BlueprintNodeType.Action) {
      this.updateActionArgs(argument);
    } else {
      this.updateSourceArgs(argument);
    }
  }

  async unsetArgument(argument: BlueprintWritableArgument): Promise<void> {
    await this.http.gql<UnsetArgumentMutation, UnsetArgumentMutationVariables>(
      API_URL,
      {
        query: UnsetArgumentGQL,
        variables: {
          id: argument.id,
        },
      }
    );

    const updatedArgument: BlueprintWritableArgument = {
      ...argument,
      value: undefined,
      inputValue: undefined,
    };

    const source$ = this._blueprint?.isMaster
      ? this._blueprint?.masterSourceNodes$
      : this._blueprint?.sourceNodes$;

    // update argument in section filed
    const updatedSources = source$?.value.map(section => {
      return {
        ...section,
        fields: section.fields.map(field => {
          return {
            ...field,
            arguments: field.arguments.map(arg => {
              return arg.id !== argument.id ? arg : updatedArgument;
            }),
          };
        }),
      };
    });
    source$?.next(updatedSources || []);
  }

  async createFilterGroup(
    input: BlueprintFilterGroupNode
  ): Promise<BlueprintFilterGroupNode> {
    const response = await this.http.gql<
      CreateFilterGroupMutation,
      CreateFilterGroupMutationVariables
    >(API_URL, {
      query: CreateFilterGroupGQL,
      variables: {
        input: {
          blueprintID: this._blueprintId,
          logicOperator: input.logicalOperator,
          blueprintFilterGroupID: input.parentFilterGroupId,
        },
      },
    });

    const filterGroup = this.converter.fromCreateFilterGroup(response);
    this._blueprint?.filterNodes$.next([
      ...(this._blueprint?.filterNodes$.value ?? []),
      filterGroup,
    ]);

    return filterGroup;
  }

  async updateFilterGroup(
    input: BlueprintFilterGroupNode
  ): Promise<BlueprintFilterGroupNode> {
    if (!input.id) throw new Error('Id must not be empty');

    await this.http.gql<
      UpdateFilterGroupMutation,
      UpdateFilterGroupMutationVariables
    >(API_URL, {
      query: UpdateFilterGroupGQL,
      variables: {
        id: input.id,
        input: {
          logicOperator: input.logicalOperator,
        },
      },
    });
    const updatedFilterGroups = this._blueprint?.filterNodes$.value.map(
      filterGroup => (filterGroup.id === input.id ? input : filterGroup)
    );
    this._blueprint?.filterNodes$.next(updatedFilterGroups || []);

    return input;
  }

  async createFilterCondition(
    input: BlueprintFilterConditionNode
  ): Promise<BlueprintFilterConditionNode> {
    if (!input.valueKind) throw new Error('ValueKind should be filled');
    if (!input.field) throw new Error('Field for filter must be selected ');

    const valuetoSend = getInputValue(
      input.userInputValue || input.value,
      input.field.returnValueKind,
      input.valueKind
    );

    const response = await this.http.gql<
      CreateFilterConditionMutation,
      CreateFilterConditionMutationVariables
    >(API_URL, {
      query: CreateFilterConditionGQL,
      variables: {
        input: {
          blueprintFilterGroupID: input.filterGroupId,
          blueprintSourceFieldID: input.field?.id,
          compareOperator: input.compareOperator,
          valueKind: input.valueKind,
          value: valuetoSend,
        },
      },
    });

    const result = this.converter.fromCreateFilterCondition(
      response,
      input.filterGroupId
    );

    const updatedFilters = this._blueprint?.filterNodes$.value.map(
      filterGroup => {
        if (filterGroup.id === input.filterGroupId) {
          filterGroup.filters.push(result);
        }
        return filterGroup;
      }
    );

    this._blueprint?.filterNodes$.next(updatedFilters || []);
    return result;
  }

  async updateFilterCondition(
    input: BlueprintFilterConditionNode
  ): Promise<BlueprintFilterConditionNode> {
    if (!input.id) throw new Error('Id must not be empty');
    if (!input.valueKind) throw new Error('ValueKind should be filled');
    if (!input.field) throw new Error('Field for filter must be selected ');

    const valuetoSend = getInputValue(
      input.userInputValue || input.value,
      input.field.returnValueKind,
      input.valueKind
    );

    await this.http.gql<
      UpdateFilterConditionMutation,
      UpdateFilterConditionMutationVariables
    >(API_URL, {
      query: UpdateFilterConditionGQL,
      variables: {
        id: input.id,
        input: {
          blueprintSourceFieldID: input.field?.id,
          compareOperator: input.compareOperator,
          valueKind: input.valueKind,
          value: valuetoSend,
        },
      },
    });

    const updatedFilters = this._blueprint?.filterNodes$.value.map(
      filterGroup => {
        return {
          ...filterGroup,
          filters: filterGroup.filters.map(filterCondition =>
            filterCondition.id === input.id
              ? {
                  ...input,
                  value: valuetoSend,
                }
              : filterCondition
          ),
        };
      }
    );

    this._blueprint?.filterNodes$.next(updatedFilters || []);

    return input;
  }

  async updateViewSettings(
    input: BlueprintViewSetings
  ): Promise<BlueprintViewSetings> {
    if (!this._blueprint) throw new Error();

    // TODO: refactor to kind settings getter function ↓

    const settings = {
      kind: input.kind,
      kindSettings:
        input.kind === ViewKindEnum.Action
          ? undefined
          : {
              graphSettings:
                input.kind === ViewKindEnum.Graph
                  ? {
                      kind: input.kindSettings.graphSettings.kind,
                    }
                  : undefined,
              tableSettings:
                input.kind === ViewKindEnum.Table
                  ? {
                      fieldIDs: input.kindSettings.tableSettings.fieldsIds,
                      groupByFieldIDs:
                        input.kindSettings.tableSettings.groupByFieldsIds,
                    }
                  : undefined,
            },
    } as SetBlueprintViewSettingsInput;

    await this.http.gql<
      UpdateViewSettingsMutation,
      UpdateViewSettingsMutationVariables
    >(API_URL, {
      query: UpdateViewSettingsGQL,
      variables: {
        id: this._blueprintId,
        settings,
      },
    });

    this._blueprint.viewSettings = input;

    return input;
  }

  async createCycleInput(): Promise<BlueprintCycleInputNode> {
    const response = await this.http.gql<
      CreateBlueprintCycleInputMutation,
      CreateBlueprintCycleInputMutationVariables
    >(API_URL, {
      query: CreateBlueprintCycleInputGQL,
      variables: {
        input: {
          blueprintID: this._blueprintId,
        },
      },
    });

    const cycleInput: BlueprintCycleInputNode = {
      id: response.createBlueprintCycleInput.id,
    };

    const updatedCycleInputs = this._blueprint?.cycleInputNodes$.value || [];
    this._blueprint?.cycleInputNodes$.next([...updatedCycleInputs, cycleInput]);

    return cycleInput;
  }

  async createUnion(): Promise<void> {
    const response = await this.http.gql<
      CreateBlueprintUnionMutation,
      CreateBlueprintUnionMutationVariables
    >(API_URL, {
      query: CreateUnionGQL,
      variables: {
        input: {
          blueprintID: this._blueprintId,
          name: '',
        },
      },
    });

    const [union, filterGroup] = this.converter.fromCreateUnion(response);

    const unions = this._blueprint?.unionNodes$.value || [];
    this._blueprint?.unionNodes$.next([...unions, union]);

    // add all filter groups with empty unionId to created union
    const filterGroups =
      this._blueprint?.filterNodes$.value.map(group => {
        if (group.parentFilterGroupId || group.unionId) {
          return group;
        }

        return {
          ...group,
          unionId: union.id,
        };
      }) || [];

    const filterGroupExists = filterGroups.find(
      fgItem => fgItem.id === filterGroup.id
    );

    this._blueprint?.filterNodes$.next(
      filterGroupExists ? filterGroups : [...filterGroups, filterGroup]
    );
  }

  async updateUnion(input: BlueprintUnionNode): Promise<void> {
    await this.http.gql<
      UpdateBlueprintUnionMutation,
      UpdateBlueprintUnionMutationVariables
    >(API_URL, {
      query: UpdateUnionGQL,
      variables: {
        id: input.id,
        input: {
          name: input.name,
        },
      },
    });

    const updatedUnions =
      this._blueprint?.unionNodes$.value.map(item =>
        item.id !== input.id ? item : input
      ) || [];

    this._blueprint?.unionNodes$.next(updatedUnions);
  }
}
