import React from 'react';

import { camelCase } from 'camel-case';

import { IconVariants } from './__generated__/iconVariants';

const context = require.context('./components', true, /\.tsx$/);

/**
 * Dict with actual icon components by name
 */
export const ICON_COMPONENTS = context.keys().reduce(
  (acc, key) => {
    const originalName = key.replace(/\.\/|\.tsx/g, '');
    acc[camelCase(originalName) as IconVariants] = context(key)[originalName];
    return acc;
  },
  {} as Record<IconVariants, React.FC<React.SVGProps<SVGSVGElement>>>
);

/**
 * Common rotate variants to ensure correct rotation direction
 */
export enum RotateVariants {
  up = '0deg',
  right = '90deg',
  down = '180deg',
  left = '-90deg',
}
