import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type BlueprintShortFragment = { __typename: 'Blueprint', id: string, name: string, description: string };

export const BlueprintShortFragmentDoc = gql`
    fragment BlueprintShort on Blueprint {
  __typename
  id
  name
  description
}
    `;