import {
  CalfLifeState,
  CreateCalvingActionCalfInputGql,
  CreateCalvingActionInput,
  CreateFreshEventActionInput,
} from '~/shared/graphql';

import { FreshActionCalvingData, FreshActionCommonData } from '../../reducer';
import { Gender } from '../../types';

export function calvingActionToApiData(
  common: FreshActionCommonData,
  calvings: FreshActionCalvingData[]
) {
  const result: CreateFreshEventActionInput = {
    employeeID: common.employee?.id,
    happenedAt: common.happenedAt,

    calvings: calvings.map(item => {
      if (!item.cow) throw new Error('Cow must be selected');
      // if (!item.difficultyScore) throw new Error('DifficultyScore must be set');

      const calving: CreateCalvingActionInput = {
        cowID: item.cow?.id,
        difficultyScore: item.difficultyScore,
        penGroupID: item.penGroup?.id,
        comment: item.comment || '',
        calves: item.calfs.map(calf => {
          if (!calf.state) throw new Error('Calf life state must be selected');

          if (!calf.penGroupId && calf.state === CalfLifeState.Alive)
            throw new Error('Calf PenGroup must be selected');

          const calfInput: CreateCalvingActionCalfInputGql = {
            penGroupID: calf.penGroupId,
            state: calf.state,
            isBull: calf.gender === Gender.male,
            identifier:
              calf.number !== undefined ? Number(calf.number) : undefined,
            height: calf.height ? Number(calf.height) : undefined,
            weight: calf.weight ? Number(calf.weight) : undefined,
            herriotCode: calf.herriotCode ?? '',
          };

          return calfInput;
        }),
      };

      return calving;
    }),
  };

  return result;
}
