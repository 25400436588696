import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SemenDoseFragmentDoc } from '../fragments/semenDose.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSemenDoseMutationVariables = Types.Exact<{
  input: Types.CreateSemenDoseInput;
}>;


export type CreateSemenDoseMutation = { __typename?: 'Mutation', createSemenDose: { __typename: 'SemenDose', id: string, deliveryDate?: string | null, studCode: string, dosesCount: number, batchNumber: string, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } } };


export const CreateSemenDoseDocument = gql`
    mutation createSemenDose($input: CreateSemenDoseInput!) {
  createSemenDose(input: $input) {
    ...SemenDose
  }
}
    ${SemenDoseFragmentDoc}`;
export type CreateSemenDoseMutationFn = Apollo.MutationFunction<CreateSemenDoseMutation, CreateSemenDoseMutationVariables>;

/**
 * __useCreateSemenDoseMutation__
 *
 * To run a mutation, you first call `useCreateSemenDoseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSemenDoseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSemenDoseMutation, { data, loading, error }] = useCreateSemenDoseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSemenDoseMutation(baseOptions?: Apollo.MutationHookOptions<CreateSemenDoseMutation, CreateSemenDoseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSemenDoseMutation, CreateSemenDoseMutationVariables>(CreateSemenDoseDocument, options);
      }
export type CreateSemenDoseMutationHookResult = ReturnType<typeof useCreateSemenDoseMutation>;
export type CreateSemenDoseMutationResult = Apollo.MutationResult<CreateSemenDoseMutation>;
export type CreateSemenDoseMutationOptions = Apollo.BaseMutationOptions<CreateSemenDoseMutation, CreateSemenDoseMutationVariables>;