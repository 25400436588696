import React from 'react';

export const ClipboardList = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect
      x="3.125"
      y="3.95833"
      width="13.75"
      height="13.75"
      fill="none"
      rx="2.70833"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <rect
      x="6.45801"
      y="2.29167"
      width="7.08333"
      height="3.75"
      fill="none"
      rx="1.875"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M7.5 10L12.5 10"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.5 13.3333L12.5 13.3333"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);
