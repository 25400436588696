import React from 'react';

import clsx from 'clsx';
import R, { juxt } from 'ramda';
import { match } from 'ts-pattern';

import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';

import { formatDateForBackend } from '~/services/dateTime';
import { Form, useForm } from '~/services/forms';
import { makeDeleteQuery } from '~/services/gql';
import {
  InjectedModalProps,
  Modal,
  ModalSizes,
  ProgressBar,
} from '~/services/modals';
import { Callout, NotificationVariants } from '~/services/notifications';

import formStyles from '~/styles/modules/form.module.scss';

import { MOVE_COWS_REASON_TAB_ITEMS } from '../../constants';
import { useCreateCowsCopyKeyMutation } from '../../gql/mutations/createCowsCopyKey.graphql';
import { useMoveCowsResultModal } from '../index';
import {
  ChooseAnimalsStep,
  ChooseDiseasesStep,
  ChooseEventsStep,
} from './components';
import { InfoCard } from './components/InfoCard';
import { getMoveCowsSchema } from './helpers';
import stepsStyles from './steps.module.scss';
import { MoveCowsFormType } from './types';

const FORM_ID = 'MoveCowsForm';

const MOVE_COWS_STEPS = {
  animals: 1,
  diseases: 2,
  events: 3,
} as const;

const STEPS_COUNT = 3;

export interface MoveCowsModalProps
  extends InjectedModalProps<MoveCowsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Current step number value
   */
  stepNumber: (typeof MOVE_COWS_STEPS)[keyof typeof MOVE_COWS_STEPS];
}

export const MoveCowsModal: React.FC<MoveCowsModalProps> = ({
  className,
  close,
  stepNumber,
  goToNextStep,
  goToPrevStep,
  setStepNumber,
}) => {
  const { open: openMoveCowsResultModal } = useMoveCowsResultModal();

  const schema = getMoveCowsSchema();
  const formContext = useForm<MoveCowsFormType>({
    schema,
    defaultValues: schema.getDefault(),
  });

  const [createCowsCopyKey, { loading: isCreateCowsCopyKeyLoading }] =
    useCreateCowsCopyKeyMutation();

  const handleSubmit = (form: MoveCowsFormType) => {
    if (stepNumber !== MOVE_COWS_STEPS.events) {
      goToNextStep();
      return;
    }
    createCowsCopyKey({
      variables: {
        input: {
          ...R.pick(
            [
              'reason',
              'toCompanyID',
              'toCompanyName',
              'toCompanyInn',
              'cowIDs',
              'diseaseIDs',
              'userEventIDs',
            ],
            form
          ),
          departDate: formatDateForBackend(form.departDate, true),
        },
      },
      update: juxt([makeDeleteQuery('cowsCopyKeys'), makeDeleteQuery('cows')]),
    }).then(({ data: createdCowsCopyKeyResponse }) => {
      const { createCowsCopyKey: createdCowsCopyKey } =
        createdCowsCopyKeyResponse ?? {};

      if (!createdCowsCopyKey) return;

      close();
      openMoveCowsResultModal({
        company: {
          toCompanyName: createdCowsCopyKey.toCompanyName,
          toCompanyInn: createdCowsCopyKey.toCompanyInn,
          toCompanyID: createdCowsCopyKey.toCompany?.id ?? null,
        },
        cowsAmount: createdCowsCopyKey.cows.length,
        departDate: createdCowsCopyKey.departDate,
        moveCowsKey: createdCowsCopyKey.key,
      });
    });
  };

  return (
    <Modal
      {...{
        className: clsx(stepsStyles.root, className),
        size: ModalSizes.large1192,
        title: 'Перемещение животных',
        contentClassName: clsx(formStyles.singleColumnForm, 'gap-24'),
        submitButtonProps: {
          form: FORM_ID,
          children: 'Сформировать ключ',
          isLoading: isCreateCowsCopyKeyLoading,
        },
        cancelButtonProps: {
          onPress: goToPrevStep,
        },
        stepsCount: STEPS_COUNT,
        stepNumber,
        isRequireExplicitClosing: formContext.formState.isDirty,
        floatingFocusManagerProps: {
          // Skip segmented tabs to focus on first input
          initialFocus: 2,
        },
      }}
    >
      <Callout
        {...{
          message: `Запускайте процесс перемещения в день отъезда животных с фермы.
                    Сразу после формирования ключа карточки животных будут заморожены
                    в статусе «Продано» или «Перемещено», изменить информацию в них будет нельзя.`,
          variant: NotificationVariants.info,
        }}
      />
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: clsx(formStyles.singleColumnForm, 'gap-24'),
        }}
      >
        <RadioGroup
          {...{
            variant: RadioGroupVariants.segmented,
            items: MOVE_COWS_REASON_TAB_ITEMS,
            onValueChange: () => {
              (
                ['toCompanyInn', 'toCompanyName', 'toCompanyID'] as const
              ).forEach(field => formContext.resetField(field));
              setStepNumber(MOVE_COWS_STEPS.animals);
            },
            name: 'reason',
          }}
        />
        <ProgressBar stepNumber={stepNumber} stepsCount={STEPS_COUNT} />
        {match(stepNumber)
          .with(MOVE_COWS_STEPS.animals, () => <ChooseAnimalsStep />)
          .with(MOVE_COWS_STEPS.diseases, () => <ChooseDiseasesStep />)
          .with(MOVE_COWS_STEPS.events, () => <ChooseEventsStep />)
          .exhaustive()}
        {stepNumber !== MOVE_COWS_STEPS.animals && <InfoCard />}
      </Form>
    </Modal>
  );
};
