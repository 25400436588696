import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MonitorLaunchResultValueFragment = { __typename: 'MonitorLaunchResultValue', value: number, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } };

export const MonitorLaunchResultValueFragmentDoc = gql`
    fragment MonitorLaunchResultValue on MonitorLaunchResultValue {
  __typename
  monitorLaunch {
    __typename
    id
    happenedAt
  }
  value
}
    `;