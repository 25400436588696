import React from 'react';

export const NewTab = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.5 3.93333C2.5 3.22025 3.19858 2.5 4 2.5H7.33333C7.60948 2.5 7.83333 2.27614 7.83333 2C7.83333 1.72386 7.60948 1.5 7.33333 1.5H4C2.66809 1.5 1.5 2.64642 1.5 3.93333V12C1.5 13.3319 2.64642 14.5 3.93333 14.5H12C13.3206 14.5 14.5 13.4311 14.5 12.0667V8.66667C14.5 8.39052 14.2761 8.16667 14 8.16667C13.7239 8.16667 13.5 8.39052 13.5 8.66667V12.0667C13.5 12.8355 12.8127 13.5 12 13.5H3.93333C3.22025 13.5 2.5 12.8014 2.5 12V3.93333Z"
      fill="currentColor"
    />
    <path
      d="M12.7929 2.50001L7.64645 7.64644C7.45118 7.8417 7.45118 8.15829 7.64645 8.35355C7.84171 8.54881 8.15829 8.54881 8.35355 8.35355L13.5 3.2071V5.13334C13.5 5.40948 13.7239 5.63334 14 5.63334C14.2761 5.63334 14.5 5.40948 14.5 5.13334V2.00001C14.5 1.72387 14.2761 1.50001 14 1.50001L10.8667 1.50001C10.5905 1.50001 10.3667 1.72387 10.3667 2.00001C10.3667 2.27615 10.5905 2.50001 10.8667 2.50001L12.7929 2.50001Z"
      fill="currentColor"
    />
  </svg>
);
