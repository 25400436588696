import { createContext, ReactElement, ReactNode, useContext } from 'react';

/**
 * Context type for a router tabs context
 */
interface LayoutContextType {
  headerRightContent?: ReactNode;
  setHeaderRightContent: (rightContent?: ReactNode) => void;
  routerTabsRightContent?: ReactElement | null;
  setRouterTabsRightContent: (rightContent?: ReactElement | null) => void;
}

/**
 * Context for controlling upper level layout from the nested pages
 */
export const LayoutContext = createContext<LayoutContextType>({
  headerRightContent: undefined,
  setHeaderRightContent: () => {},
  routerTabsRightContent: undefined,
  setRouterTabsRightContent: () => {},
});

/**
 * Hook for using the router tabs context, so the nested pages can control the layout
 */
export const useLayoutContext = () => useContext(LayoutContext);
