import React from 'react';

export const Star = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.7608 8.57179C14.8702 8.79253 15.081 8.94548 15.3248 8.98097L21.4907 9.87877C21.4912 9.87994 21.4917 9.88125 21.4922 9.88266C21.4926 9.88408 21.493 9.88543 21.4932 9.88668L17.034 14.2134C16.8563 14.3858 16.7751 14.6349 16.8171 14.8789L17.8694 20.9908C17.8685 20.9916 17.8674 20.9925 17.8662 20.9934C17.865 20.9942 17.8638 20.995 17.8627 20.9956L12.348 18.1066C12.1301 17.9924 11.8699 17.9924 11.652 18.1066L6.13732 20.9956C6.13621 20.995 6.13504 20.9942 6.13384 20.9934C6.13263 20.9925 6.13155 20.9916 6.1306 20.9908L7.18286 14.8789C7.22488 14.6349 7.14373 14.3858 6.96601 14.2134L2.50676 9.88668C2.50703 9.88543 2.50739 9.88408 2.50785 9.88266C2.50831 9.88124 2.50881 9.87994 2.50932 9.87877L8.67521 8.98097C8.91899 8.94548 9.12979 8.79253 9.23917 8.57179L11.9958 3.00839C11.9971 3.00826 11.9985 3.00819 12 3.00819C12.0015 3.00819 12.0029 3.00826 12.0042 3.00839L14.7608 8.57179Z"
      stroke="var(--star-icon-stroke-color, transparent)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.672 2.66701L15.4329 8.2388L21.6081 9.13795C22.2118 9.22586 22.4601 9.99356 22.0223 10.4184L17.5563 14.7517L18.6101 20.8727C18.7135 21.473 18.0625 21.947 17.523 21.6644L12 18.771L6.47705 21.6644C5.93748 21.947 5.28653 21.473 5.38988 20.8727L6.44374 14.7517L1.97773 10.4184C1.53989 9.99356 1.78823 9.22586 2.39194 9.13795L8.56714 8.2388L11.328 2.66701C11.5981 2.12191 12.4019 2.12191 12.672 2.66701Z"
      fill="var(--star-icon-fill-color, currentColor)"
    />
    <path
      d="M15.9965 7.46191L13.4337 2.28962C12.8513 1.11437 11.1487 1.11437 10.5663 2.28962L8.00347 7.46191L2.26946 8.29682C0.967821 8.48635 0.441794 10.1125 1.38582 11.0284L5.52997 15.0494L4.5522 20.7285C4.32937 22.0228 5.70815 23.0267 6.8715 22.4173L12 19.7306L17.1285 22.4173C18.2919 23.0267 19.6706 22.0228 19.4478 20.7285L18.47 15.0494L22.6142 11.0284C23.5582 10.1125 23.0322 8.48635 21.7305 8.29682L15.9965 7.46191Z"
      stroke="var(--star-icon-outline-color, transparent)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
