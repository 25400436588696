import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SourceFieldFragmentDoc } from '../../../../entities/blueprintSourceFields/gql/fragments/sourceField.graphql';
export type CustomReportFormulaColumnFragment = { __typename: 'CustomReportFormulaColumn', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } };

export const CustomReportFormulaColumnFragmentDoc = gql`
    fragment CustomReportFormulaColumn on CustomReportFormulaColumn {
  __typename
  blueprintSourceField {
    ...SourceField
  }
  formula
  view
}
    ${SourceFieldFragmentDoc}`;