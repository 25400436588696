import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SourceFieldFragmentDoc } from '../../../../entities/blueprintSourceFields/gql/fragments/sourceField.graphql';
export type CustomReportColumnFragment = { __typename: 'CustomReportColumn', blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } };

export const CustomReportColumnFragmentDoc = gql`
    fragment CustomReportColumn on CustomReportColumn {
  __typename
  blueprintSourceField {
    ...SourceField
  }
}
    ${SourceFieldFragmentDoc}`;