import React from 'react';

import clsx from 'clsx';

import {
  Skeleton,
  TextSkeletonSizes,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatWithPercent } from '~/shared/helpers/number';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Number for 100%
   */
  total?: number;
  /**
   * Value for the left bar, right bar is calculated automatically
   */
  leftValue?: number;
  /**
   * Color for the left bar
   */
  leftColor: string;
  /**
   * Color for the right bar
   */
  rightColor: string;
}

export const PercentsBar: React.FC<Props> = ({
  className,
  total = 0,
  leftValue = 0,
  leftColor,
  rightColor,
}) => {
  const { renderWithSkeleton, getSkeletonClassNames } = useSkeletonContext();

  const leftPercent = Math.round((leftValue / total) * 100);
  const rightPercent = 100 - leftPercent;

  return (
    <div className={clsx(styles.root, className)}>
      {renderWithSkeleton(
        <Skeleton.Block height="var(--bar-height)" />,
        <div className={styles.bar}>
          <div style={{ background: leftColor, width: `${leftPercent}%` }} />
          <div style={{ background: rightColor, width: `${rightPercent}%` }} />
        </div>
      )}

      <div className="flex mt-4">
        <Typography
          {...{
            variant: TypographyVariants.descriptionLarge,
            skeletonSize: TextSkeletonSizes.small,
            className: getSkeletonClassNames('py-2'),
          }}
        >
          {formatWithPercent(leftPercent)}
        </Typography>
        <Typography
          {...{
            className: 'ml-a',
            variant: TypographyVariants.descriptionLarge,
            withSkeleton: false,
          }}
        >
          {formatWithPercent(rightPercent)}
        </Typography>
      </div>
    </div>
  );
};
