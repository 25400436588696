import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { HerriotIntegrationEntityFragmentDoc } from '../fragments/herriotIntegrationEntity.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HerriotIntegrationEntitiesQueryVariables = Types.Exact<{
  kinds?: Types.InputMaybe<Array<Types.HerriotIntegrationEntityKindEnum> | Types.HerriotIntegrationEntityKindEnum>;
}>;


export type HerriotIntegrationEntitiesQuery = { __typename?: 'Query', herriotIntegrationEntities: { __typename?: 'HerriotIntegrationEntityConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes: Array<{ __typename: 'HerriotIntegrationEntity', name: string, guid: string, kind: Types.HerriotIntegrationEntityKindEnum }> } };


export const HerriotIntegrationEntitiesDocument = gql`
    query herriotIntegrationEntities($kinds: [HerriotIntegrationEntityKindEnum!]) {
  herriotIntegrationEntities(kinds: $kinds) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...HerriotIntegrationEntity
    }
  }
}
    ${HerriotIntegrationEntityFragmentDoc}`;

/**
 * __useHerriotIntegrationEntitiesQuery__
 *
 * To run a query within a React component, call `useHerriotIntegrationEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHerriotIntegrationEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHerriotIntegrationEntitiesQuery({
 *   variables: {
 *      kinds: // value for 'kinds'
 *   },
 * });
 */
export function useHerriotIntegrationEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>(HerriotIntegrationEntitiesDocument, options);
      }
export function useHerriotIntegrationEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>(HerriotIntegrationEntitiesDocument, options);
        }
export function useHerriotIntegrationEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>(HerriotIntegrationEntitiesDocument, options);
        }
export type HerriotIntegrationEntitiesQueryHookResult = ReturnType<typeof useHerriotIntegrationEntitiesQuery>;
export type HerriotIntegrationEntitiesLazyQueryHookResult = ReturnType<typeof useHerriotIntegrationEntitiesLazyQuery>;
export type HerriotIntegrationEntitiesSuspenseQueryHookResult = ReturnType<typeof useHerriotIntegrationEntitiesSuspenseQuery>;
export type HerriotIntegrationEntitiesQueryResult = Apollo.QueryResult<HerriotIntegrationEntitiesQuery, HerriotIntegrationEntitiesQueryVariables>;