import { CompareToPreviousPeriodMode } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

/**
 * Dict with possible compare historic data periods
 */
const COMPARE_TO_PREVIOUS_PERIOD_MODES_DICT: Record<
  CompareToPreviousPeriodMode,
  string
> = {
  [CompareToPreviousPeriodMode.PeriodByPeriod]: 'Период к периоду',
  [CompareToPreviousPeriodMode.YearByYear]: 'Год к году',
};

const useCompareToPreviousPeriodModeSelect = makeUseEnumSelect(
  CompareToPreviousPeriodMode,
  enumValue =>
    COMPARE_TO_PREVIOUS_PERIOD_MODES_DICT[
      enumValue as CompareToPreviousPeriodMode
    ]
);

export const CompareToPreviousPeriodModeSelect = makeSelectComponentFromHook(
  useCompareToPreviousPeriodModeSelect
);
