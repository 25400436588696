import { BlueprintRoleColor } from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { ColorVariants } from '~/styles/__generated__/token-variants';

/**
 * Actual colors and their human names for backend role color enum
 */
export const ROLE_COLOR_CONFIGS: Record<
  BlueprintRoleColor,
  { title: string; colorVariant: ColorVariants }
> = {
  [BlueprintRoleColor.Grey]: {
    title: 'Серый',
    colorVariant: ColorVariants.neutral,
  },
  [BlueprintRoleColor.Red]: {
    title: 'Зеленый',
    colorVariant: ColorVariants.success,
  },
  [BlueprintRoleColor.Violet]: {
    title: 'Фиолетовый',
    colorVariant: ColorVariants.status04,
  },
  [BlueprintRoleColor.Orange]: {
    title: 'Бронзовый',
    colorVariant: ColorVariants.status06,
  },
  [BlueprintRoleColor.Green]: {
    title: 'Морской',
    colorVariant: ColorVariants.status01,
  },
  [BlueprintRoleColor.Blue]: {
    title: 'Синий',
    colorVariant: ColorVariants.info,
  },
  [BlueprintRoleColor.Yellow]: {
    title: 'Оранжевый',
    colorVariant: ColorVariants.warning,
  },
  [BlueprintRoleColor.Pink]: {
    title: 'Розовый',
    colorVariant: ColorVariants.status05,
  },
};

/**
 * Role form schema
 */
export const ROLE_SCHEMA = yup.object({
  id: yup.string().default('').required(), // ID! -- used for tempId also
  color: oneOfEnum(BlueprintRoleColor).required(),
  name: yup.string().default('').required(),
  // Frontend fields to handle deletion
  shouldBeDeleted: yup.boolean().default(false),
  migrateToRoleID: yup.string().nullable().default(null), // ID
});
