import React from 'react';

import { FemaleAnimalKind } from '@graphql-types';
import clsx from 'clsx';

import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { Tooltip } from '~/shared/components/Tooltip';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { formatInt } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';

import { formatBull } from '~/entities/bulls';
import { getCowIdentifier } from '~/entities/cows';
import { formatEmployee } from '~/entities/employees';

import { InseminationFragment } from '../../gql/fragments/insemination.graphql';
import { useReproductionInseminationsQuery } from '../../gql/queries/reproductionInseminations.graphql';
import { InseminationResultBadge } from '../InseminationResultBadge';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * hashID of the reproduction insemination row to load inseminations for
   */
  hashID: string;
  /**
   * Female animal kind, used for report calculations, to get target for
   */
  femaleAnimalKind: FemaleAnimalKind;
  /**
   * If true, retiredAt column is shown (default - true)
   */
  withRetiredAtColumn?: boolean;
}

const COW_NAME_TOOLTIP_MAX_WIDTH_PX = 264;

const LARGE_COLUMN_WIDTH_PX = 160;
const DATE_COLUMN_WIDTH_PX = 124;
const ENTITY_COLUMN_WIDTH_PX = 148;

export const InseminationsTable: React.FC<Props> = ({
  className,
  hashID,
  femaleAnimalKind,
  withRetiredAtColumn = false,
}) => {
  // This is coupling with reproduction, but we don't have other place with inseminations now,
  // so we should rework it, if we'll start using general inseminations query
  const { data, loading: isLoading } = useReproductionInseminationsQuery({
    variables: {
      hashID,
    },
  });

  const inseminations = data?.reproductionInseminations;

  const isHeifersTable = femaleAnimalKind === FemaleAnimalKind.Heifer;

  const columnConfigs: TableColumnConfig<InseminationFragment>[] = [
    {
      title: 'Номер животного',
      key: 'cowId',
      renderCellContent: ({ cow }) => (
        <Tooltip
          {...{
            content:
              'Нажмите, чтобы открыть карточку животного в новой вкладке',
            maxWidth: COW_NAME_TOOLTIP_MAX_WIDTH_PX,
          }}
        >
          <TextLink
            key={cow.id}
            {...{
              target: '_blank',
              to: '/$companyId/user/cows/$cowId',
              params: prev => ({
                ...prev,
                cowId: cow.id,
              }),
            }}
          >
            {getCowIdentifier(cow)}
          </TextLink>
        </Tooltip>
      ),
      width: LARGE_COLUMN_WIDTH_PX,
    },
    {
      title: isHeifersTable ? 'Возраст в днях' : 'День в доении',
      key: 'daysInMilk',
      renderCellContent: ({ daysInMilk }) => formatInt(daysInMilk),
      width: DATE_COLUMN_WIDTH_PX,
      columnClassName: 'text-right',
    },
    {
      title: 'Дата осеменения',
      key: 'happenedAt',
      renderCellContent: ({ happenedAt }) => formatDate(happenedAt),
      width: DATE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Результат осеменения',
      key: 'result',
      renderCellContent: ({ result }) => (
        <InseminationResultBadge inseminationResult={result} />
      ),
      width: LARGE_COLUMN_WIDTH_PX,
    },
    ...wrapConditionalArrayElement<TableColumnConfig<InseminationFragment>>(
      withRetiredAtColumn && {
        title: 'Дата выбытия',
        key: 'retiredAt',
        renderCellContent: ({ cow }) => formatDate(cow.retiredAt),
        width: DATE_COLUMN_WIDTH_PX,
      }
    ),
    {
      title: 'Бык',
      key: 'bull',
      renderCellContent: ({ bull }) => formatBull(bull, { prefix: '' }),
      width: ENTITY_COLUMN_WIDTH_PX,
    },
    {
      title: 'Техник',
      key: 'employee',
      renderCellContent: ({ employee }) =>
        formatEmployee(employee, { withFullName: false }),
      width: ENTITY_COLUMN_WIDTH_PX,
    },
    {
      title: 'Схема осеменения',
      key: 'scheme',
      renderCellContent: ({ scheme }) => scheme?.name,
      width: ENTITY_COLUMN_WIDTH_PX,
    },
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<InseminationFragment>
      {...{
        theme: TableThemes.smallSecondary,
        className: clsx(className, 'col-span-full'),
        items: inseminations,
        withBorder: true,
        withCustomScroll: false,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        isLoading,
      }}
    />
  );
};
