import React, { useCallback } from 'react';

import { ListItem } from '~/~legacy/types/keyValue';

import { MAutoComplete } from '~/shared/components/MAutoComplete';
import { MControlsPanel } from '~/shared/components/MControlsPanel';
import { MErrorMessage } from '~/shared/components/MErrorMessage';
import { MInlineEditor } from '~/shared/components/MInlineEditor';
import { MLoader } from '~/shared/components/MLoader';
import { SourceFieldKindEnum } from '~/shared/graphql';

import TOKENS from '~/styles/__generated__/tokens.json';

import { NodeFrame } from '../../../NodeFrame';
import { NewFieldNodeState } from '../NewFieldNode';

interface Props {
  items: ListItem<string>[];
  value: NewFieldNodeState;
  loading: boolean;
  errors: string[];

  onChange: (val: NewFieldNodeState) => void;
  submit: () => void;
  cancel: () => void;
}

export const NewFieldNodeUI: React.FC<Props> = ({
  value,
  items,
  errors,
  loading,
  onChange,
  submit,
  cancel,
}) => {
  const loader = loading ? <MLoader /> : null;
  const errorMsg =
    errors.length > 0 ? (
      <MErrorMessage>{errors.join(' ')}</MErrorMessage>
    ) : null;

  const typeChangeCallback = useCallback(
    (val: SourceFieldKindEnum) => {
      onChange({
        ...value,
        kind: val,
      });
    },
    [onChange, value]
  );

  const nameChangeCallback = useCallback(
    (name: string) => {
      onChange({
        ...value,
        name,
      });
    },
    [onChange, value]
  );

  const formSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submit();
      return false;
    },
    [submit]
  );

  return (
    <NodeFrame
      header={
        <>
          Поле{' '}
          <MInlineEditor
            name="name"
            value={value?.name}
            onUpdate={nameChangeCallback}
          />
        </>
      }
      className="my-8"
      headerStyle={{
        background: TOKENS.colorWarning400,
      }}
    >
      <form className="m-8" onSubmit={formSubmit}>
        <MAutoComplete
          name="kind"
          items={items}
          selectedValue={value?.kind}
          onChange={typeChangeCallback}
        />

        <MControlsPanel
          className="mt-8"
          cancel={cancel}
          canSubmit={Boolean(value)}
        />
        {loader}
        {errorMsg}
      </form>
    </NodeFrame>
  );
};
