import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type SourceFieldFragment = { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum };

export const SourceFieldFragmentDoc = gql`
    fragment SourceField on SourceField {
  __typename
  id
  name
  returnValueKind
}
    `;