import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { CowFragment } from '../gql/fragments/cow.graphql';
import {
  CowsQuery,
  CowsQueryVariables,
  useCowsQuery,
} from '../gql/queries/cows.graphql';

export const useCowsPaginatedQuery = makeUsePaginatedQuery<
  CowFragment,
  CowsQuery,
  CowsQueryVariables
>({
  useQuery: useCowsQuery,
  getItemsFromQueryData: data => data.cows.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.cows.pageInfo,
});
