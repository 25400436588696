import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../fragments/farm.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFarmMutationVariables = Types.Exact<{
  input: Types.CreateFarmInput;
}>;


export type CreateFarmMutation = { __typename?: 'Mutation', createFarm: { __typename: 'Farm', id: string, name: string, number: string } };


export const CreateFarmDocument = gql`
    mutation createFarm($input: CreateFarmInput!) {
  createFarm(input: $input) {
    ...Farm
  }
}
    ${FarmFragmentDoc}`;
export type CreateFarmMutationFn = Apollo.MutationFunction<CreateFarmMutation, CreateFarmMutationVariables>;

/**
 * __useCreateFarmMutation__
 *
 * To run a mutation, you first call `useCreateFarmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFarmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFarmMutation, { data, loading, error }] = useCreateFarmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFarmMutation(baseOptions?: Apollo.MutationHookOptions<CreateFarmMutation, CreateFarmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFarmMutation, CreateFarmMutationVariables>(CreateFarmDocument, options);
      }
export type CreateFarmMutationHookResult = ReturnType<typeof useCreateFarmMutation>;
export type CreateFarmMutationResult = Apollo.MutationResult<CreateFarmMutation>;
export type CreateFarmMutationOptions = Apollo.BaseMutationOptions<CreateFarmMutation, CreateFarmMutationVariables>;