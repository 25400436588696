import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BlueprintInputFragmentDoc } from '../../../blueprintValues/gql/fragments/blueprintInput.graphql';
export type BlueprintForCustomReportFragment = { __typename: 'Blueprint', id: string, name: string, description: string, inputs: Array<{ __typename: 'BlueprintInput', id: string, name: string, prompt: string, valueKinds: Array<Types.ValueKindEnum> }> };

export const BlueprintForCustomReportFragmentDoc = gql`
    fragment BlueprintForCustomReport on Blueprint {
  __typename
  id
  name
  description
  inputs {
    ...BlueprintInput
  }
}
    ${BlueprintInputFragmentDoc}`;