import R from 'ramda';
import { match, P } from 'ts-pattern';

const makeGetMetricColorClassName =
  ({
    greenClassName,
    yellowClassName,
    redClassName,
    neutralClassName,
  }: {
    greenClassName?: string;
    yellowClassName?: string;
    redClassName?: string;
    neutralClassName?: string;
  }) =>
  ({
    targetPercent,
    valuePercent,
    deviationThresholdPercent,
  }: {
    targetPercent: number | null | undefined;
    valuePercent: number | null | undefined;
    deviationThresholdPercent: number | null | undefined;
  }) =>
    !R.isNil(targetPercent) && !R.isNil(deviationThresholdPercent)
      ? match(valuePercent)
          .with(P.number.lt(deviationThresholdPercent), R.always(redClassName))
          .with(P.number.lt(targetPercent), R.always(yellowClassName))
          .with(P.number.gte(targetPercent), R.always(greenClassName))
          .otherwise(R.always(neutralClassName))
      : neutralClassName;

/**
 * Returns a correct color className to apply to metric text
 */
export const getMetricColorForegroundClassName = makeGetMetricColorClassName({
  greenClassName: 'text-success',
  yellowClassName: 'text-warning',
  redClassName: 'text-error',
  neutralClassName: 'text-soft',
});

/**
 * Returns a correct color className to apply to metric background in a table
 */
export const getMetricColorBackgroundClassName = makeGetMetricColorClassName({
  greenClassName: 'background-success-container-default',
  yellowClassName: 'background-warning-container-default',
  redClassName: 'background-error-container-default',
});
