import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DiseaseFragment = { __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> };

export const DiseaseFragmentDoc = gql`
    fragment Disease on Disease {
  __typename
  id
  name
  kind
  protocols {
    __typename
    id
  }
}
    `;