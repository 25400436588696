import React, { ReactNode } from 'react';

import { PressProps, usePress } from '@react-aria/interactions';
import { PressEvent } from '@react-types/shared';
import clsx from 'clsx';

import { Skeleton, useSkeletonContext } from '~/shared/components/Skeleton';
import { Tooltip, TooltipProps } from '~/shared/components/Tooltip';
import { mergeProps } from '~/shared/helpers/mergeProps';

import { SizeVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

import { IconVariants } from './__generated__/iconVariants';
import { ICON_COMPONENTS, RotateVariants } from './constants';
import styles from './index.module.scss';

export interface IconProps
  extends React.ComponentProps<'span'>,
    Record<`data-${string}`, any> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Name from icons list
   */
  variant: IconVariants;
  /**
   * Icon size, any react css size value
   */
  size?: SizeVariants | string | number;
  /**
   * Icon rotation in degrees or from rotate variants
   */
  rotate?: number | RotateVariants;
  /**
   * If true, click events won't fire and pointer cursor won't be used
   */
  isDisabled?: boolean;
  /**
   * If true, skeleton is not rendered, the icon is always shown
   */
  isStaticContent?: boolean;
  /**
   * Called, when the icon is pressed with mouse or keyboard
   */
  onPress?: PressProps['onPress'];
  /**
   * Props, passed to usePress
   */
  usePressProps?: PressProps;
  /**
   * If true, than press events are propagated
   */
  shouldContinuePropagation?: boolean;
  /**
   * Tooltip for icon
   */
  tooltip?: ReactNode;
  /**
   * Additional props for the tooltip
   */
  tooltipProps?: Partial<TooltipProps>;
  /**
   * Props, passed directly to the svg icon element
   */
  iconProps?: Record<string, any>;
}

const SKELETON_BLOCK_SIZE_PX = 12;

export const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      className,
      variant,

      size = SizeVariants.size24,
      rotate = RotateVariants.up,
      isDisabled = false,
      isStaticContent = false,

      onPress,
      usePressProps,
      shouldContinuePropagation,

      tooltip,
      tooltipProps,

      iconProps,

      color,

      ...htmlProps
    },
    ref
  ) => {
    const { renderWithSkeleton } = useSkeletonContext();

    const IconComponent = ICON_COMPONENTS[variant];

    const rotationValue = typeof rotate === 'number' ? `${rotate}deg` : rotate;
    const sizeValue = size in TOKENS ? TOKENS[size as SizeVariants] : size;

    const continuePropagationIfNeeded = (e: PressEvent) => {
      if (shouldContinuePropagation) {
        e.continuePropagation();
      }
    };

    const { pressProps } = usePress({
      isDisabled,
      ...mergeProps(
        usePressProps,
        { onPress },
        {
          onPress: continuePropagationIfNeeded,
          onPressStart: continuePropagationIfNeeded,
          onPressEnd: continuePropagationIfNeeded,
        }
      ),
    });

    const isInteractive = (onPress || htmlProps.onClick) && !isDisabled;

    return renderWithSkeleton(
      <Skeleton.Block
        {...{
          className,
          size: SKELETON_BLOCK_SIZE_PX,
        }}
      />,
      <Tooltip content={tooltip} isDisabled={!tooltip} {...tooltipProps}>
        <span
          {...mergeProps(
            {
              className: clsx(
                styles.root,
                className,
                isInteractive && !isDisabled && 'cursor-pointer'
              ),
              ref,
              tabIndex: isInteractive ? 0 : undefined,
            },
            onPress && pressProps,
            htmlProps
          )}
        >
          <span
            {...mergeProps({
              className: styles.innerRoot,
              style: {
                width: sizeValue,
                height: sizeValue,
                minWidth: sizeValue,
                minHeight: sizeValue,
                color,
                rotate: rotationValue,
              },
            })}
          >
            <IconComponent {...iconProps} />
          </span>
        </span>
      </Tooltip>,
      isStaticContent
    );
  }
);

export * from './constants';

export * from './__generated__/iconVariants';
