import React from 'react';

import { createFileRoute } from '@tanstack/react-router';
import R from 'ramda';

import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { MDASH, SPACED_INTERPUNCT } from '~/shared/constants';
import { formatNumber } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';

import { isCow } from '~/entities/cows';
import { GenealogyCowFragment } from '~/entities/cows/gql/fragments/genealogyCow.graphql';

import { useMilkDataTabContext } from '~/widgets/cowCardEvents';

import { RELATION_KINDS_DICT } from './constants';
import { formatCowMetrics } from './helpers';

const RELATION_KIND_COLUMN_WIDTH_PX = 148;
const REGISTRATION_NUMBER_COLUMN_WIDTH_PX = 156;
const NAME_AND_BREED_COLUMN_WIDTH_PX = 136;
const DATE_OF_BIRTH_COLUMN_WIDTH_PX = 104;
const WEIGHT_KILOGRAMS_COLUMN_WIDTH_PX = 72;

const RELATION_KIND_ITEMS = Object.keys(RELATION_KINDS_DICT);

export const Route = createFileRoute(
  '/$companyId/_layout/user/cows/$cowId/info/genealogy/'
)({
  wrapInSuspense: true,
  component: GenealogyCowTable,
});

function GenealogyCowTable() {
  const { cow: cowDetailed } = useMilkDataTabContext();

  const genealogyItems = cowDetailed?.genealogy ?? [];

  const columnConfigs: TableColumnConfig<GenealogyCowFragment>[] = [
    {
      title: 'Степень родства',
      key: 'relationKind',
      renderCellContent: cow => {
        const { animal } = cow;

        if (isCow(animal)) {
          return (
            <TextLink
              {...{
                to: '/$companyId/user/cows/$cowId',
                params: prev => ({
                  ...prev,
                  cowId: animal.id,
                }),
              }}
            >
              {RELATION_KINDS_DICT[cow.relationKind]}
            </TextLink>
          );
        }

        return RELATION_KINDS_DICT[cow.relationKind];
      },
      cellClassName: 'whitespace-nowrap',
      width: RELATION_KIND_COLUMN_WIDTH_PX,
    },
    {
      title: 'Международ. номер',
      key: 'registrationNumber',
      itemField: 'registrationNumber',
      width: REGISTRATION_NUMBER_COLUMN_WIDTH_PX,
    },
    {
      title: 'Кличка',
      key: 'name',
      itemField: 'name',
      width: NAME_AND_BREED_COLUMN_WIDTH_PX,
    },
    {
      title: 'Порода',
      key: 'breed',
      itemField: 'breed',
      width: NAME_AND_BREED_COLUMN_WIDTH_PX,
    },
    {
      title: 'Дата рожд.',
      key: 'dateOfBirth',
      renderCellContent: cow => formatDate(cow.dateOfBirth),
      width: DATE_OF_BIRTH_COLUMN_WIDTH_PX,
    },
    {
      title: (
        <>
          Макс. надой за лактацию
          <br />
          {`(${['Номер лакт.', 'Надой, кг', 'Жир, %', 'Белок, %'].join(SPACED_INTERPUNCT)})`}
        </>
      ),
      key: 'averageWeightKilograms',
      columnClassName: 'text-right',
      renderCellContent: cow => {
        const bestLactationMetrics = [
          formatCowMetrics(cow.bestLactationNumber, false),
          formatCowMetrics(cow.bestLactationMilkWeightKilograms, false),
          formatCowMetrics(cow.bestLactationMilkFatPercent),
          formatCowMetrics(cow.bestLactationMilkProteinPercent),
        ];

        return bestLactationMetrics.every(m => m === MDASH)
          ? null
          : bestLactationMetrics.join(SPACED_INTERPUNCT);
      },
      width: '1fr',
    },
    {
      title: (
        <>
          Пожизненный надой
          <br />
          {`(${['Надой, кг', 'Жир, %', 'Белок, %'].join(SPACED_INTERPUNCT)})`}
        </>
      ),
      key: 'expectedWeightKilograms',
      columnClassName: 'text-right',
      renderCellContent: cow => {
        const overallMilkMetrics = [
          formatCowMetrics(cow.overallMilkWeightKilograms, false),
          formatCowMetrics(cow.overallMilkFatPercent),
          formatCowMetrics(cow.overallMilkProteinPercent),
        ];

        return overallMilkMetrics.every(m => m === MDASH)
          ? null
          : overallMilkMetrics.join(SPACED_INTERPUNCT);
      },
      width: '1fr',
    },
    {
      title: 'Вес, кг',
      key: 'weightKilograms',
      columnClassName: 'text-right',
      renderCellContent: cow => formatNumber(cow.weightKilograms),
      width: WEIGHT_KILOGRAMS_COLUMN_WIDTH_PX,
    },
  ];

  return (
    <Table<GenealogyCowFragment>
      {...{
        className: 'min-w-full w-min',
        theme: TableThemes.tertiary,
        items: R.sortBy<GenealogyCowFragment>(item =>
          R.indexOf(item.relationKind, RELATION_KIND_ITEMS)
        )(genealogyItems),
        columnConfigs,
        getItemKey: R.prop('registrationNumber'),
        noItemsMessage: 'Нет данных о генеалогии',
      }}
    />
  );
}
