import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { BlueprintRunSettings, Dow } from '~/shared/graphql';

import { BlueprintDetailsEditModel } from '../../../../types';
import { WeekDaysSelector } from '../WeekDaysSelector';

interface Props {
  runSettings: BlueprintRunSettings;
  onChange: React.Dispatch<React.SetStateAction<BlueprintDetailsEditModel>>;
}

export const BlueprintDetailsRunSettingsScheduleWeekly: React.FC<Props> = ({
  onChange,
  runSettings,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const selectedDows = runSettings.dows.find(week => week) || [];

  const dowChanged = useCallback(
    (dows: Dow[]) => {
      onChange(prevState => {
        return {
          ...prevState,
          runSettings: {
            ...prevState.runSettings,
            dows: [dows],
          },
        };
      });
    },
    [onChange]
  );

  return (
    <>
      <p className="my-16">
        {t(IntegratorStrings.blueprintDetails.runSettings.repeatDays)}
      </p>
      <WeekDaysSelector selectedDows={selectedDows} onChange={dowChanged} />
    </>
  );
};
