import React, { useRef } from 'react';

import { useDateSegment } from '@react-aria/datepicker';
import { DateFieldState, DateSegment } from '@react-stately/datepicker';
import clsx from 'clsx';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Current state of the datepicker
   */
  state: DateFieldState;
  /**
   * Segment to render
   */
  segment: DateSegment;
}

export const DateTimeInputSegment: React.FC<Props> = ({
  className,
  state,
  segment,
}) => {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...{
        className: clsx(
          className,
          segment.isEditable && styles.editable,
          segment.isPlaceholder && 'text-muted'
        ),
        ...segmentProps,
        ref,
      }}
    >
      {segment.text}
    </div>
  );
};
