import { makeUseEnumSelectItems } from '~/shared/components/Select';

import { FILTERS_AND_GROUPINGS_MODES_DICT } from '../../../constants';
import { FiltersAndGroupingsModes } from '../../../types';

export const useFiltersAndGroupingsModeItems = makeUseEnumSelectItems(
  FiltersAndGroupingsModes,
  enumValue =>
    FILTERS_AND_GROUPINGS_MODES_DICT[enumValue as FiltersAndGroupingsModes]
);
