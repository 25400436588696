import { useApolloClient } from '@apollo/client';

import { match, P } from 'ts-pattern';

import { readCompanyFragment } from '~/entities/companies';

import { MoveCowsCompany } from './types';

export const useFormatMoveToCompanyTitle = (company: MoveCowsCompany) => {
  const client = useApolloClient();

  return match(company)
    .with(
      { toCompanyInn: P.string },
      ({ toCompanyInn }) => `ИНН ${toCompanyInn}`
    )
    .with({ toCompanyName: P.string }, ({ toCompanyName }) => toCompanyName)
    .with({ toCompanyID: P.string }, ({ toCompanyID }) => {
      const companyFragment = readCompanyFragment(client, toCompanyID);
      return companyFragment?.name ?? '';
    })
    .with(
      {
        toCompanyID: P.nullish,
        toCompanyName: P.nullish,
        toCompanyInn: P.nullish,
      },
      () => ''
    )
    .exhaustive();
};
