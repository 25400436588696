import React from 'react';

import clsx from 'clsx';

import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Label } from '~/shared/components/Label';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import { DATE_PERIOD_FORM_SCHEMA } from '../../constants';
import { formatDate, formatDateForBackend } from '../../helpers';
import { DatePeriod, DatePeriodPickerThemes } from '../../types';
import styles from './index.module.scss';

interface Props extends BaseFieldProps<DatePeriod> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Picker display theme
   */
  theme?: DatePeriodPickerThemes;
}

const DATE_PLACEHOLDER = 'дд.мм.гггг';

const DatePeriodPickerInner = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,

      theme = DatePeriodPickerThemes.dark,

      name,
      isRequired,
      hasError,

      value: valueProp,
      onValueChange,
      defaultValue = DATE_PERIOD_FORM_SCHEMA.getDefault(),

      label,
      labelProps,
      feedback,
      feedbackProps,
    }: Props,
    ref
  ) => {
    const [value, setValue] = useControllableState<DatePeriod>(
      valueProp,
      onValueChange,
      defaultValue
    );

    const valueSince = formatDate(value.interval.since);
    const valueTill = formatDate(value.interval.till);

    const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
      (e.target as HTMLInputElement).showPicker();
    };

    const renderDate = (date: string) => (
      <Typography
        variant={TypographyVariants.bodySmall}
        className={clsx(!date && 'text-muted')}
      >
        {date || DATE_PLACEHOLDER}
      </Typography>
    );

    return (
      <div className={clsx(className, styles.rootWrapper, styles[theme])}>
        {!!label && (
          <Label
            {...{
              className: 'mb-4',
              isRequired,
              ...labelProps,
            }}
          >
            {label}
          </Label>
        )}
        <div className={clsx(styles.root, hasError && styles.error)}>
          <div className={styles.input}>
            {renderDate(valueSince)}
            <input
              {...{
                ref,
                name: `${name}.since`,
                type: 'date',
                value: formatDateForBackend(value.interval.since, true),
                onClick: handleInputClick,
                onChange: e =>
                  setValue?.(current => ({
                    ...current,
                    interval: {
                      ...current.interval,
                      since: e.target.value,
                    },
                  })),
              }}
            />
          </div>

          <Typography
            variant={TypographyVariants.bodySmall}
            className={clsx((!valueSince || !valueTill) && 'text-muted')}
          >
            &ndash;
          </Typography>

          <div className={styles.input}>
            {renderDate(valueTill)}
            <input
              {...{
                name: `${name}.till`,
                type: 'date',
                value: formatDateForBackend(value.interval.till, true),
                onClick: handleInputClick,
                onChange: e =>
                  setValue?.(current => ({
                    ...current,
                    interval: {
                      ...current.interval,
                      till: e.target.value,
                    },
                  })),
              }}
            />
          </div>
        </div>
        {!!feedback && (
          <FieldFeedback
            className="mt-4"
            content={feedback}
            {...feedbackProps}
          />
        )}
      </div>
    );
  }
);

export const DatePeriodPicker = withOptionalFormController<Props, DatePeriod>({
  defaultValue: DATE_PERIOD_FORM_SCHEMA.getDefault(),
})(DatePeriodPickerInner);
