import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetReproductionSettingsMutationVariables = Types.Exact<{
  farmID: Types.Scalars['ID']['input'];
  input: Types.SetReproductionSettingsInput;
}>;


export type SetReproductionSettingsMutation = { __typename?: 'Mutation', setReproductionSettings?: any | null };


export const SetReproductionSettingsDocument = gql`
    mutation setReproductionSettings($farmID: ID!, $input: SetReproductionSettingsInput!) {
  setReproductionSettings(farmID: $farmID, input: $input)
}
    `;
export type SetReproductionSettingsMutationFn = Apollo.MutationFunction<SetReproductionSettingsMutation, SetReproductionSettingsMutationVariables>;

/**
 * __useSetReproductionSettingsMutation__
 *
 * To run a mutation, you first call `useSetReproductionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReproductionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReproductionSettingsMutation, { data, loading, error }] = useSetReproductionSettingsMutation({
 *   variables: {
 *      farmID: // value for 'farmID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReproductionSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetReproductionSettingsMutation, SetReproductionSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReproductionSettingsMutation, SetReproductionSettingsMutationVariables>(SetReproductionSettingsDocument, options);
      }
export type SetReproductionSettingsMutationHookResult = ReturnType<typeof useSetReproductionSettingsMutation>;
export type SetReproductionSettingsMutationResult = Apollo.MutationResult<SetReproductionSettingsMutation>;
export type SetReproductionSettingsMutationOptions = Apollo.BaseMutationOptions<SetReproductionSettingsMutation, SetReproductionSettingsMutationVariables>;