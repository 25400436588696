import { LactationState } from '@graphql-types';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { LACTATION_STATE_DICT } from '../constants';

export const useLactationStateSelect = makeUseEnumSelect(
  LactationState,
  enumValue => LACTATION_STATE_DICT[enumValue as LactationState]
);
