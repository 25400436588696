export const EnumStrings = {
  // need to have . at the end since it used as hierarchical key root
  bullState: 'bullState.',
  eventKinds: 'eventKinds.',
  calculationMethod: 'calculationMethod.',
  vitalityFilter: 'vitalityFilter.',
  monitorScheduleInterval: 'monitorScheduleInterval.',
  testMilkingFields: 'testMilkingFields.',
  milkingParlorKind: 'milkingParlorKind.',
};
