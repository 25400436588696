import { useCallback, useMemo } from 'react';

import {
  FetchMoreQueryOptions,
  NetworkStatus,
  QueryHookOptions,
} from '@apollo/client';

import { PageInfo } from '@graphql-types';
import R from 'ramda';

import { DEFAULT_PAGE_SIZE } from '~/shared/constants';

import {
  AnyFragment,
  PaginatedQueryVariables,
  UsePaginatedQuery,
} from '../../types';

/**
 * Props for fabric for making hooks for using paginated queries.
 */
export interface MakeUsePaginatedQueryProps<
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends PaginatedQueryVariables,
> {
  /**
   * Generated api hook for requesting paginated data
   */
  useQuery: UsePaginatedQuery<QueryData, QueryVariables>;
  /**
   * Getter for actual items from the query result
   */
  getItemsFromQueryData: (data: QueryData) => Fragment[];
  /**
   * Getter for pageInfo from the query result
   */
  getPageInfoFromQueryData: (
    data: QueryData
  ) => Omit<PageInfo, '__typename'> | undefined;
  /**
   * Number of items per page
   */
  pageSize?: number;
}

/**
 * Fabric to make hook, that uses gql paginated query
 * New hook has same api as given one, but fetchMore can be called without any options to receive next page
 * It also adds additional field "hasMore" based on received pageInfo to result
 */
export const makeUsePaginatedQuery =
  <
    Fragment extends AnyFragment,
    QueryData,
    QueryVariables extends PaginatedQueryVariables,
  >({
    useQuery,
    getItemsFromQueryData,
    getPageInfoFromQueryData,
    pageSize = DEFAULT_PAGE_SIZE,
  }: MakeUsePaginatedQueryProps<Fragment, QueryData, QueryVariables>) =>
  (options?: QueryHookOptions<QueryData, QueryVariables>) => {
    const isValidData = (data: QueryData | undefined): data is QueryData =>
      !!data && !R.isEmpty(data);

    const basicVariables = {
      first: pageSize,
      ...options?.variables,
    } as QueryVariables;

    const queryResult = useQuery({
      ...options,
      variables: basicVariables,
      notifyOnNetworkStatusChange: true,
    });

    const pageInfo = queryResult.data
      ? getPageInfoFromQueryData(queryResult.data)
      : undefined;
    const cursor = pageInfo?.endCursor;
    const items = useMemo(
      () =>
        isValidData(queryResult.data)
          ? getItemsFromQueryData(queryResult.data)
          : [],
      [queryResult.data]
    );

    const fetchMore = useCallback(
      (fetchMoreOptions?: FetchMoreQueryOptions<QueryVariables, QueryData>) => {
        return queryResult.fetchMore({
          variables: {
            ...basicVariables,
            after: cursor,
            ...fetchMoreOptions?.variables,
          },
        });
      },
      [JSON.stringify(options?.variables), cursor]
    );

    const isLoading = queryResult.loading;

    // Resolve async items, that might be used to fill up some default form values
    const itemsPromise = useMemo(() => {
      return new Promise<Fragment[]>(resolve => {
        const subscription = queryResult.observable.subscribe(
          observedResult => {
            if (isValidData(observedResult.data)) {
              resolve(getItemsFromQueryData(observedResult.data));
              subscription.unsubscribe();
            }
          }
        );
      });
    }, []);

    return {
      ...queryResult,
      isLoading,
      isFetchingMore: queryResult.networkStatus === NetworkStatus.fetchMore,
      pageInfo,
      items,
      itemsPromise,
      hasMore: !!pageInfo?.hasNextPage,
      fetchMore,
      // This is useful alias for passing props to infinite scroll
      onFetchMore: fetchMore,
    };
  };
