import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CompanyShortFragment = { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean };

export const CompanyShortFragmentDoc = gql`
    fragment CompanyShort on Company {
  __typename
  id
  name
  isLockedForWrite
}
    `;