import { COW_CHART_LINE_VALUE_TYPE_CONFIGS } from '../../constants';
import { CowChartLineValueTypes } from '../../types';

/**
 * Gets dataset props, wich depends on its value type
 */
export const getLineConfigByValueType = (valueType: CowChartLineValueTypes) => {
  const valueConfig = COW_CHART_LINE_VALUE_TYPE_CONFIGS[valueType];
  return {
    borderColor: valueConfig.color,
    backgroundColor: valueConfig.color,
  };
};
