import React, { ChangeEvent, useCallback } from 'react';

import { PenGroupShortInfo } from '~/~legacy/types/entities';
import { PenGroupSelector } from '~/~legacy/value-editors/editors';
import { ChangeValueCallbackType } from '~/~legacy/value-editors/types/valueEditorTypes';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { MFormField } from '~/shared/components/MFormField';
import { MInput } from '~/shared/components/MInput';
import { SelectThemes } from '~/shared/components/Select';
import { VitalityFilter } from '~/shared/graphql';

import { CowAsyncSelect } from '~/entities/cows';

import {
  CalfData,
  CalvingEditFunction,
  FreshActionCalvingData,
} from '../../reducer';
import { CalfsTable } from '../CalfsTable';
import styles from './index.module.scss';

interface Props {
  calving: FreshActionCalvingData;
  update: CalvingEditFunction;
  del: CalvingEditFunction;
}

export const ExpandedFreshActionCalvingForm: React.FC<Props> = ({
  calving,
  update,
}) => {
  const addCalf = useCallback(() => {
    update({
      ...calving,
      calfs: [
        ...calving.calfs,
        {
          tempId: Math.random().toString(),
        },
      ],
    });
  }, [calving, update]);

  const updateCalf = useCallback(
    (calf: CalfData) => {
      update({
        ...calving,
        calfs: calving.calfs.map(item =>
          item.tempId === calf.tempId ? calf : item
        ),
      });
    },
    [calving, update]
  );

  const delCalf = useCallback(
    (calf: CalfData) => {
      update({
        ...calving,
        calfs: calving.calfs.filter(item => item.tempId !== calf.tempId),
      });
    },
    [calving, update]
  );

  const calfsTable =
    calving.calfs.length === 0 ? null : (
      <CalfsTable del={delCalf} update={updateCalf} calfsData={calving.calfs} />
    );

  const penGroupChangeCallback: ChangeValueCallbackType<PenGroupShortInfo> =
    useCallback(
      penGroup => {
        update({
          ...calving,
          penGroup,
        });

        return Promise.resolve(true);
      },
      [calving, update]
    );

  const difficultyScoreChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const difficultyScore = Number(e.target.value);

      update({
        ...calving,
        difficultyScore,
      });
    },
    [calving, update]
  );

  const commentCahngeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const comment = e.target.value;

      update({
        ...calving,
        comment,
      });
    },
    [calving, update]
  );

  return (
    <>
      <div className={styles.form}>
        <CowAsyncSelect
          {...{
            queryOptions: {
              variables: {
                vitalityFilter: VitalityFilter.Alive,
              },
            },
            label: 'Номер коровы',
            name: 'cow',
            rawValue: calving.cow?.id,
            onValueChange: newValue => {
              // TODO replace AppCow with standart fragment
              const cow = newValue
                ? {
                    id: newValue.id,
                    identifier: newValue.identifier,
                    name: newValue.name,
                    penGroupName: newValue.penGroup.name,
                    penGroupId: newValue.penGroup.id,
                  }
                : undefined;
              update({
                ...calving,
                cow,
              });
            },
          }}
        />

        <MFormField
          title="Группа коровы"
          className={styles.group}
          editor={
            <PenGroupSelector
              key={`${calving.tempId}__penGroup`}
              name="penGroup"
              edit
              noForm
              hideControls
              onChange={penGroupChangeCallback}
              value={calving.penGroup}
              additionalArgs={{ selectTheme: SelectThemes.dark }}
            />
          }
        />

        <MFormField
          title="Степень тяжести отёла"
          subtitle="Необязательное поле"
          editor={
            <MInput
              key={`${calving.tempId}__difficultyScore`}
              name="difficultyScore"
              type="number"
              onChange={difficultyScoreChangeCallback}
              value={calving.difficultyScore || ''}
            />
          }
        />
        <MFormField
          title="Комментарий"
          subtitle="Необязательное поле"
          editor={
            <MInput
              key={`${calving.tempId}__comment`}
              name="comment"
              type="text"
              onChange={commentCahngeCallback}
              placeholder="напишите комментарий"
              value={calving.comment || ''}
            />
          }
        />
      </div>
      <hr className="my-16" />
      {calfsTable}

      <Button variant={ButtonVariants.secondary} onPress={addCalf}>
        Добавить телёнка
      </Button>
    </>
  );
};
