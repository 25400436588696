import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MilkingParlorIntervalFragment = { __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string };

export const MilkingParlorIntervalFragmentDoc = gql`
    fragment MilkingParlorInterval on MilkingParlorInterval {
  __typename
  id
  intervalStart
  intervalEnd
}
    `;