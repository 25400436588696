import React, { useCallback, useState } from 'react';

import clsx from 'clsx';

import { MPanel } from '~/shared/components/MPanel';

import { CalvingEditFunction, FreshActionCalvingData } from '../../reducer';
import { CollapsedFreshActionCalvingForm } from '../CollapsedFreshActionCalvingForm';
import { ExpandedFreshActionCalvingForm } from '../ExpandedFreshActionCalvingForm';
import { FreshActionCalvingFormControls } from '../FreshActionCalvingFormControls';
import styles from './index.module.scss';

interface Props {
  collapsed: boolean;
  calving: FreshActionCalvingData;
  update: CalvingEditFunction;
  del: CalvingEditFunction;
}

export const FreshActionCalvingForm: React.FC<Props> = ({
  collapsed,
  calving,
  update,
  del,
}) => {
  const [expanded, setExpanded] = useState(!collapsed);

  const toggle = useCallback(() => {
    setExpanded(val => !val);
  }, [setExpanded]);

  const delCallback = useCallback(() => {
    del(calving);
  }, [del, calving]);

  const form = expanded ? (
    <ExpandedFreshActionCalvingForm
      calving={calving}
      update={update}
      del={del}
    />
  ) : (
    <CollapsedFreshActionCalvingForm calving={calving} />
  );

  return (
    <MPanel className={clsx('my-16 p-16 default-control', styles.root)}>
      <FreshActionCalvingFormControls
        expanded={expanded}
        del={delCallback}
        toggle={toggle}
      />
      {form}
    </MPanel>
  );
};
