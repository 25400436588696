import React from 'react';

export const User = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 3.95833C8.96382 3.95833 8.12435 4.79779 8.12435 5.83333C8.12435 6.86886 8.96382 7.70833 9.99935 7.70833C11.0349 7.70833 11.8743 6.86886 11.8743 5.83333C11.8743 4.79779 11.0349 3.95833 9.99935 3.95833ZM6.87435 5.83333C6.87435 4.10744 8.27346 2.70833 9.99935 2.70833C11.7252 2.70833 13.1243 4.10744 13.1243 5.83333C13.1243 7.55922 11.7252 8.95833 9.99935 8.95833C8.27346 8.95833 6.87435 7.55922 6.87435 5.83333ZM6.25362 13.5872C7.20861 12.7557 8.47041 12.2917 9.99935 12.2917C12.9852 12.2917 15.2077 14.348 15.2077 16.7708V17.5C15.2077 17.8452 15.4875 18.125 15.8327 18.125C16.1779 18.125 16.4577 17.8452 16.4577 17.5V16.7708C16.4577 13.5061 13.5135 11.0417 9.99935 11.0417C8.19996 11.0417 6.63169 11.5954 5.42112 12.6546C5.41487 12.6601 5.40873 12.6657 5.40271 12.6714C4.30131 13.7154 3.54102 15.1844 3.54102 16.7708V17.5C3.54102 17.8452 3.82084 18.125 4.16602 18.125C4.51119 18.125 4.79102 17.8452 4.79102 17.5V16.7708C4.79102 15.5904 5.36022 14.4381 6.25362 13.5872Z"
      fill="currentColor"
    />
  </svg>
);
