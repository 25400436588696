import R from 'ramda';
import { match, P } from 'ts-pattern';

import { SourceFieldValueFragment } from '../gql/fragments/sourceFieldValue.graphql';

// Small helper for match expressions readability to avoid writing a lot of typeguards
const TN = <T extends SourceFieldValueFragment['__typename']>(
  __typename: T
) => ({
  __typename,
});

/**
 * Helper to get a source field value for using in an editable input
 */
export const getSourceFieldValueForEdit = (
  value: SourceFieldValueFragment | null | undefined
) => {
  return match(value)
    .with(
      TN('Disease'),
      TN('Injection'),
      TN('InseminationScheme'),
      TN('Protocol'),
      TN('UserEvent'),
      TN('Farm'),
      TN('PenGroup'),
      TN('Cow'),
      TN('Bull'),
      TN('Calving'),
      TN('Employee'),
      TN('Insemination'),
      TN('SemenDose'),
      R.prop('id')
    )
    .with(TN('DateHardcodedValue'), R.prop('dateValue'))
    .with(TN('DatetimeHardcodedValue'), R.prop('datetimeValue'))
    .with(TN('FloatHardcodedValue'), R.prop('floatValue'))
    .with(TN('IntHardcodedValue'), R.prop('intValue'))
    .with(TN('StrHardcodedValue'), R.prop('strValue'))
    .with(P.nullish, R.identity)
    .exhaustive();
};
