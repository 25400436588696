import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DefaultEventFragment = { __typename: 'DefaultEvent', name: string, kind: Types.EventKindEnum, shortcode: Types.DefaultEventEnum, description: string };

export const DefaultEventFragmentDoc = gql`
    fragment DefaultEvent on DefaultEvent {
  __typename
  name
  kind
  shortcode
  description
}
    `;