import { Plugin } from 'chart.js';
import R from 'ramda';

type LabelBox = Pick<DOMRect, 'x' | 'y'> & { w: number; h: number };

/**
 * Plugin to render lines from pie chart labels to segments
 */
export const PIE_CHART_LABELS_PLUGIN: Plugin<'pie'> = {
  id: 'pieChartLabels',
  afterDraw: chart => {
    const {
      ctx,

      chartArea,
    } = chart;
    const chartMiddlePoint = {
      x: (chartArea.left + chartArea.right) / 2,
      y: (chartArea.top + chartArea.bottom) / 2,
    };
    ctx.save();

    // Use as any, cause, here we rely on inner plugin structure,
    // that isn't typed, but we can get label positions there
    const datalabelsPluginData = (chart as any).$datalabels;

    const datalabelsPluginLabels: Record<string, any>[] = R.sortBy(
      R.prop('_index'),
      // eslint-disable-next-line no-underscore-dangle -- access datalabels plugin inner label representations
      datalabelsPluginData._labels
    );

    ctx.lineWidth = 1;

    datalabelsPluginLabels.forEach((label, labelIndex) => {
      if (
        // eslint-disable-next-line no-underscore-dangle -- check if pie chart renders this data
        !label._model ||
        // eslint-disable-next-line no-underscore-dangle -- check if datalabels plugin renders this label
        !label.$layout._visible ||
        // Check if data available
        Number.isNaN(label.$context.dataset.data[labelIndex])
      ) {
        return;
      }

      ctx.beginPath();

      // eslint-disable-next-line no-underscore-dangle -- access datalabels plugin label position info
      const labelBox: LabelBox = label.$layout._box._rect;
      const labelDataset = label.$context.dataset;

      ctx.strokeStyle = label.$context.active
        ? labelDataset.hoverBackgroundColor[labelIndex]
        : labelDataset.backgroundColor[labelIndex];

      const labelBoxRect = {
        left: labelBox.x,
        right: labelBox.x + labelBox.w,
        bottom: labelBox.y + labelBox.h,
      };

      const isRightToTheMiddle = labelBoxRect.left > chartMiddlePoint.x;

      // Draw line under label
      if (isRightToTheMiddle) {
        ctx.moveTo(labelBoxRect.right, labelBoxRect.bottom);
        ctx.lineTo(labelBoxRect.left, labelBoxRect.bottom);
      } else {
        ctx.moveTo(labelBoxRect.left, labelBoxRect.bottom);
        ctx.lineTo(labelBoxRect.right, labelBoxRect.bottom);
      }

      // eslint-disable-next-line no-underscore-dangle -- access chart.js arc element
      const { outerRadius: pieRadius, endAngle, startAngle } = label._el;

      const middleAngleDelta = (startAngle - endAngle) / 2;

      const endAngleNormalizedPoint = {
        x: pieRadius * Math.cos(endAngle),
        y: pieRadius * Math.sin(endAngle),
      };

      // Rotate point of end of the arc by the middle angle
      const angleSin = Math.sin(middleAngleDelta);
      const angleCos = Math.cos(middleAngleDelta);

      const arcMiddleAngleNormalizedPoint = {
        x:
          endAngleNormalizedPoint.x * angleCos -
          endAngleNormalizedPoint.y * angleSin,
        y:
          endAngleNormalizedPoint.x * angleSin +
          endAngleNormalizedPoint.y * angleCos,
      };

      // Draw connecting to the pie line
      ctx.lineTo(
        arcMiddleAngleNormalizedPoint.x + chartMiddlePoint.x,
        arcMiddleAngleNormalizedPoint.y + chartMiddlePoint.y
      );

      ctx.stroke();
    });

    ctx.restore();
  },
};
