import * as yup from 'yup';

/**
 * Employee form schema
 */
export const EMPLOYEE_SCHEMA = yup.object({
  id: yup.string().default('').required(), // ID! -- used for tempId also
  number: yup.string().default('').required(),
  firstName: yup.string().default(''),
  middleName: yup.string().default(''),
  lastName: yup.string().default('').required(),
  comment: yup.string().default(''),
});
