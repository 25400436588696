import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DatePeriodFragment = { __typename: 'DatePeriod', daysFromToday?: number | null, interval: { __typename?: 'DatePeriodInterval', since: string, till: string } };

export const DatePeriodFragmentDoc = gql`
    fragment DatePeriod on DatePeriod {
  __typename
  interval {
    since
    till
  }
  daysFromToday
}
    `;