import React from 'react';

import R from 'ramda';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { MilkingParlorFragment } from '../../../../gql/fragments/milkingParlor.graphql';
import { useDeleteMilkingParlorMutation } from '../../../../gql/mutations/deleteMilkingParlor.graphql';
import {
  MilkingParlorsDocument,
  MilkingParlorsQueryVariables,
} from '../../../../gql/queries/milkingParlors.graphql';
import { useGetMilkingParlorInfo } from '../../../../hooks';
import { useEditMilkingParlorModal } from '../../../../modals';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking parlor to render menu for
   */
  milkingParlor: MilkingParlorFragment;
  /**
   * Variables, used for parlors query
   */
  queryVariables: MilkingParlorsQueryVariables;
}

export const MilkingParlorCardMenu: React.FC<Props> = ({
  className,
  milkingParlor,
  queryVariables,
}) => {
  const getMilkingParlorInfo = useGetMilkingParlorInfo();

  const { open: openEditMilkingParlorModal } = useEditMilkingParlorModal();

  const [deleteMilkingParlor] = useDeleteMilkingParlorMutation();

  const confirmDelete = useConfirm();

  return (
    <ContextMenuButton
      {...{
        className,
        items: [
          {
            content: 'Редактировать',
            onPress: () => openEditMilkingParlorModal({ milkingParlor }),
          },
          {
            variant: MenuItemVariants.delimiter,
          },
          {
            variant: MenuItemVariants.destructive,
            content: 'Удалить',
            onPress: async () => {
              const isConfirmed = await confirmDelete({
                title: 'Удаление доильного зала',
                message: (
                  <div className="grid gap-12">
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Вы хотите удалить{' '}
                      <Typography variant={TypographyVariants.bodySmallStrong}>
                        {getMilkingParlorInfo(milkingParlor, true)}
                      </Typography>
                      ?
                    </Typography>
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Это действие невозможно отменить.
                    </Typography>
                  </div>
                ),
                isDelete: true,
              });
              if (!isConfirmed) return;

              deleteMilkingParlor({
                variables: {
                  id: milkingParlor.id,
                },
                optimisticResponse: { deleteMilkingParlor: null },
                update: R.juxt([
                  makeDeleteFragmentFromQuery({
                    typeName: 'MilkingParlor',
                    query: MilkingParlorsDocument,
                    variables: queryVariables,
                    queryName: 'milkingParlors',
                  })(milkingParlor.id),
                  makeDeleteQueriesByNameWithoutVariables(
                    'milkingParlors',
                    queryVariables
                  ),
                ]),
              });
            },
          },
        ],
      }}
    />
  );
};
