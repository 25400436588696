import React, { MouseEvent, useCallback } from 'react';

import './MOverlay.scss';

interface Props {
  click?: (e: MouseEvent<HTMLDivElement>) => void;
}

export const MOverlay: React.FC<Props> = props => {
  const { click } = props;

  // real crutch but workable UX
  const scrollCallback = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const event = e.nativeEvent as WheelEvent;

    const elements = document.elementsFromPoint(e.clientX, e.clientY);

    const container = elements.find(element => {
      const hasIndexOf = typeof element?.className?.indexOf !== 'undefined';
      return hasIndexOf
        ? element?.className?.indexOf('m-application-shell-container') > -1
        : false;
    });
    if (container) {
      container.scroll({
        top: (container.scrollTop += event.deltaY),
      });
    }
  }, []);

  return <div className="m-overlay" onWheel={scrollCallback} onClick={click} />;
};
