import { CowsCopyReasonEnum } from '@graphql-types';

/**
 * Cow move reason options
 */
export const MOVE_COWS_REASON_TAB_ITEMS = [
  {
    id: CowsCopyReasonEnum.Sell,
    name: 'Для продажи',
  },
  {
    id: CowsCopyReasonEnum.Move,
    name: 'На другую площадку',
  },
];

/**
 * Possible cows copy key active kinds
 */
export enum CowsCopyActiveKind {
  isActivated = 'isActivated',
  isArchived = 'isArchived',
}

/**
 * Cows copy key active kind items
 */
export const MOVE_COWS_ACTIVE_KEY_TAB_ITEMS = [
  {
    id: CowsCopyActiveKind.isActivated,
    name: 'Действующие',
  },
  {
    id: CowsCopyActiveKind.isArchived,
    name: 'Архивные',
  },
];
