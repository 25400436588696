import React, {
  ClipboardEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react';

import { ValueKindEnum } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { useSvgKeyPress } from '~/~legacy/hooks/useSvgKeyPress';
import { ReactComponent as TrashSVG } from '~/~legacy/icons/trash.svg';
import { BlueprintCycleInputField } from '~/~legacy/services/BlueprintExecutionService';
import { EditorsMap } from '~/~legacy/value-editors/editorsMap';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';

import { getAdditionalArgs } from '../../helpers';
import { MoveDirection } from '../../types';
import { CellCoordinates } from '../BlueprintForms/BlueprintForms.reducer';
import styles from './index.module.scss';

const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];

interface Props {
  del: () => void;
  onChange: (
    cycleInput: BlueprintCycleInputField,
    rowIndex: number,
    fieldIndex: number,
    val: any
  ) => void;
  paste: (
    cycleInput: BlueprintCycleInputField,
    rowIndex: number,
    fieldIndex: number,
    data: React.ClipboardEvent
  ) => void;
  onFocus: (point: CellCoordinates) => void;
  focusMoved: (point: CellCoordinates, direction: MoveDirection) => void;
  enterPressed: () => void;
  row: any[];
  cycleInput: BlueprintCycleInputField;
  rowIndex: number;
  focused?: boolean;
  activeCell?: CellCoordinates;
}

export const UserCycleInputRow: React.FC<Props> = ({
  del,
  row,
  rowIndex,
  cycleInput,
  onChange,
  paste,
  onFocus,
  focusMoved,
  activeCell,
  enterPressed,
}) => {
  const svgDelPress = useSvgKeyPress(del);

  const inputRefs = useRef<any[]>([]);

  useEffect(() => {
    if (!R.isNil(activeCell?.x) && activeCell?.y === rowIndex) {
      inputRefs.current[activeCell.x]?.focus();
    }
  }, [activeCell?.x, activeCell?.y]);

  return (
    <tr>
      {cycleInput.inputs.map((input, fieldIndex) => {
        const editor = EditorsMap[input.valueKind] || EditorsMap.Unknown;

        const hasFocus =
          activeCell?.x === fieldIndex && activeCell.y === rowIndex;

        const handleChange = (val: any) => {
          // plus one, because in the data 0 is tempId
          onChange(cycleInput, rowIndex, fieldIndex + 1, val);
          return Promise.resolve(true);
        };

        const handlePasteEvent: ClipboardEventHandler = e => {
          paste(cycleInput, rowIndex, fieldIndex + 1, e);
        };

        const handleKeyDown: KeyboardEventHandler = (
          e,
          additionalSelectArgs = undefined
        ) => {
          const { isOpen, openMenu, closeMenu } = additionalSelectArgs ?? {
            isOpen: false,
            openMenu: () => {},
            closeMenu: () => {},
          };
          if (!isOpen && allowedKeys.find(x => x === e.key)) {
            focusMoved(
              {
                x: fieldIndex,
                y: rowIndex,
              },
              e.key as MoveDirection
            );
            e.preventDefault();
          }

          if (e.key === 'Enter') {
            const isLastField = fieldIndex === cycleInput.inputs.length - 1;

            if (isOpen) {
              closeMenu();
            } else {
              openMenu();
            }

            if ((!additionalSelectArgs || isOpen) && isLastField) {
              // Reducer is not working with updates correctly, so we force this to execute in order
              setTimeout(() => {
                enterPressed();
                setTimeout(() => {
                  onFocus({
                    x: 0,
                    y: rowIndex + 1,
                  });
                }, 0);
              }, 0);
            }
          }
        };

        const handleCellClick = () => {
          onFocus({
            x: fieldIndex,
            y: rowIndex,
          });
        };

        const additionalArgs = {
          ...getAdditionalArgs(input.valueKind),
          selectTheme: SelectThemes.basic,
        };

        let component;
        if (input.valueKind === ValueKindEnum.Int) {
          component = (
            <Input
              key={hasFocus.toString()}
              {...{
                ref: node => {
                  if (node) {
                    inputRefs.current[fieldIndex] = node;
                  }
                },
                name: `${input.name}__${rowIndex}`,
                className: styles.input,
                variant: InputVariants.int,
                // We don't format cow number and now we don't have a way to distinct it from other int inputs
                withFormat: false,
                value: row[fieldIndex + 1],
                onValueChange: handleChange,
                theme: InputThemes.basic,
                htmlInputProps: {
                  onPaste: handlePasteEvent,
                  onKeyDown: handleKeyDown,
                  autoFocus: hasFocus,
                  onFocus: () => {
                    onFocus({
                      x: fieldIndex,
                      y: rowIndex,
                    });
                  },
                },
              }}
            />
          );
        } else {
          component = editor({
            // Hack for blueprint cycle inputs focus to work
            // TODO remove after complete rework of blueprint value inputs
            inputRef: (node: HTMLInputElement) => {
              if (node) {
                inputRefs.current[fieldIndex] = node;
              }
            },
            shouldOpenOnArrows: false,
            name: input.valueKind,
            edit: true,
            hideControls: true,
            noForm: true,
            noBorder: true,
            className: styles.input,

            onPaste: handlePasteEvent,
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            value: row[fieldIndex + 1],
            focused: hasFocus,

            additionalArgs,
          } as ValueEditorArgs<any>);
        }

        return (
          <td key={input.id} onClick={handleCellClick}>
            {component}
          </td>
        );
      })}
      <td className={clsx('px-16', styles.actions)}>
        <TrashSVG tabIndex={0} onClick={del} onKeyUp={svgDelPress} />
      </td>
    </tr>
  );
};
