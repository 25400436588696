import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventKindEnum } from '@graphql-types';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { makeUseEnumSelect } from '~/shared/components/Select';
import { mergeProps } from '~/shared/helpers/mergeProps';

import {
  InjectedStepperModalProps,
  ProgressBar,
  UseModalStepInterface,
  withStepperModal,
} from '~/services/modals';

import { EditUserEventForm } from '../../components';
import { EditDiseaseForm } from '../../components/EditDiseaseForm';
import { EditInjectionForm } from '../../components/EditInjectionForm';
import { EditInseminationSchemeForm } from '../../components/EditInseminationSchemeForm';
import { EditProtocolDaysUntilCheckForm } from '../../components/EditProtocolDaysUntilCheckForm';
import { EditProtocolForm } from '../../components/EditProtocolForm';
import { EditProtocolInjectionsForm } from '../../components/EditProtocolInjectionsForm';
import { EditProtocolTotalTreatmentCostForm } from '../../components/EditProtocolTotalTreatmentCostForm';
import { EventFragment } from '../../gql/fragments/event.graphql';
import { ProtocolFragment } from '../../gql/fragments/protocol.graphql';
import {
  isDisease,
  isInjection,
  isInseminationScheme,
  isProtocol,
  isUserEvent,
} from '../../helpers';
import {
  EditDiseaseFormType,
  useDiseaseForm,
} from '../../hooks/useDiseaseForm';
import {
  EditInjectionFormType,
  useInjectionForm,
} from '../../hooks/useInjectionForm';
import {
  EditInseminationSchemeFormType,
  useInseminationSchemeForm,
} from '../../hooks/useInseminationSchemeForm';
import {
  EditProtocolDaysUntilCheckFormType,
  useProtocolDaysUntilCheckForm,
} from '../../hooks/useProtocolDaysUntilCheckForm';
import { useProtocolForm } from '../../hooks/useProtocolForm';
import {
  EditProtocolInjectionsFormType,
  useProtocolInjectionsForm,
} from '../../hooks/useProtocolInjectionsForm';
import {
  EditProtocolTotalTreatmentCostFormType,
  useProtocolTotalTreatmentCostForm,
} from '../../hooks/useProtocolTotalTreatmentCostForm';
import {
  EditUserEventFormType,
  useUserEventForm,
} from '../../hooks/useUserEventForm';
import styles from './index.module.scss';

enum EditEventModalSteps {
  editEvent = 'editEvent',
  editProtocolInjections = 'editProtocolInjections',
  editProtocolDaysUntilCheck = 'editProtocolDaysUntilCheck',
  editProtocolTotalTreatmentCost = 'editProtocolTotalTreatmentCost',
}

interface EditEventModalFormTypes {
  editEvent:
    | EditDiseaseFormType
    | EditInjectionFormType
    | EditInseminationSchemeFormType
    | EditUserEventFormType;
  editProtocolInjections: EditProtocolInjectionsFormType;
  editProtocolDaysUntilCheck: EditProtocolDaysUntilCheckFormType;
  editProtocolTotalTreatmentCost: EditProtocolTotalTreatmentCostFormType;
}

interface EditEventModalSubmitTypes {
  editEvent: EventFragment | undefined;
  editProtocolInjections: ProtocolFragment | undefined;
  editProtocolDaysUntilCheck: ProtocolFragment | undefined;
  editProtocolTotalTreatmentCost: void;
}

type EditEventModalStepperState = EventFragment | undefined;

interface EditEventModalZeroStepWrapperProps {
  selectedEventKind?: EventKindEnum;
}

export interface EditEventModalProps
  extends InjectedStepperModalProps<
    EditEventModalProps,
    EditEventModalStepperState,
    EditEventModalZeroStepWrapperProps
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If passed, this is the event to edit, creating new otherwise
   */
  eventToEdit?: EventFragment;
}

const useEventKindsSelect = makeUseEnumSelect(
  EventKindEnum,
  EnumStrings.eventKinds,
  ['Default']
);

const EditEventModalInternal: React.FC<EditEventModalProps> = ({
  className,

  isFirstStep,
  stepNumber,
  stepsCount,

  stepperModalState: eventToEdit,
  StepperModalWrapperComponent,
  stepperModalWrapperProps,
}) => {
  const { t } = useTranslation(MaslovNamespaces.enums);

  const isEditing = !!eventToEdit;

  const [selectedEventKind, setSelectedEventKind] = useState<
    EventKindEnum | undefined
  >(eventToEdit?.kind);
  const { renderSelectElement: renderEventKindsSelectElement } =
    useEventKindsSelect({
      name: 'eventKind',
      className: 'mb-16',
      label: 'Тип события',
      isRequired: true,
      isDisabled: isEditing,
      rawValue: selectedEventKind,
      onValueChange: value =>
        setSelectedEventKind(value?.id as EventKindEnum | undefined),
    });
  return (
    <StepperModalWrapperComponent
      {...mergeProps(stepperModalWrapperProps, {
        selectedEventKind,
        modalProps: {
          className,
          contentClassName: isFirstStep ? styles.modalContent : undefined,
          title: isEditing
            ? `Редактирование события ${t(
                `${EnumStrings.eventKinds}${eventToEdit.kind}`
              )}`
            : 'Создание нового события',
          submitButtonProps: {
            children: isEditing ? 'Сохранить изменения' : 'Создать',
          },
        },
      })}
      key={`${selectedEventKind}_${stepperModalWrapperProps.key}`}
    >
      {stepElement => (
        <>
          {isFirstStep ? renderEventKindsSelectElement() : undefined}
          {selectedEventKind === EventKindEnum.Protocol && (
            <ProgressBar {...{ className: 'mb-16', stepsCount, stepNumber }} />
          )}

          {stepElement}
        </>
      )}
    </StepperModalWrapperComponent>
  );
};

export const EditEventModal = withStepperModal<
  EditEventModalProps,
  EditEventModalSteps,
  EditEventModalFormTypes,
  EditEventModalSubmitTypes,
  EditEventModalStepperState,
  EditEventModalZeroStepWrapperProps
>({
  stepsDict: EditEventModalSteps,
  getDefaultStepperState: props => props.eventToEdit,
  onNextStepSubmit: ({
    setStepperModalState,

    isLastStep,
    goToNextStep,
    close,

    form: editedProtocol,
  }) => {
    if (editedProtocol) {
      setStepperModalState(editedProtocol);
    }

    if (isLastStep) {
      close();
    } else {
      goToNextStep();
    }
  },
  stepHooks: {
    [EditEventModalSteps.editEvent]: ({
      stepperModalState: eventToEdit,
      handleNextStepSubmit,
      selectedEventKind,
      close,
    }) => {
      const isEditing = !!eventToEdit;

      const eventToMatch = eventToEdit ?? { kind: selectedEventKind };

      // Here we return conditional hooks for the first step,
      // so we should add selectedEventKind to StepperModalWrapperComponent key
      return match(eventToMatch)
        .with(P.when(isDisease), matchedEvent => {
          const { formProps, ...otherFormHookResults } = useDiseaseForm({
            disease: isEditing ? matchedEvent : undefined,
            onSubmit: close,
          });
          return {
            ...otherFormHookResults,
            stepElement: <EditDiseaseForm formProps={formProps} />,
          };
        })
        .with(P.when(isInjection), matchedEvent => {
          const { formProps, ...otherFormHookResults } = useInjectionForm({
            injection: isEditing ? matchedEvent : undefined,
            onSubmit: close,
          });
          return {
            ...otherFormHookResults,
            stepElement: <EditInjectionForm formProps={formProps} />,
          };
        })
        .with(P.when(isInseminationScheme), matchedEvent => {
          const { formProps, ...otherFormHookResults } =
            useInseminationSchemeForm({
              inseminationScheme: isEditing ? matchedEvent : undefined,
              onSubmit: close,
            });
          return {
            ...otherFormHookResults,
            stepElement: <EditInseminationSchemeForm formProps={formProps} />,
          };
        })
        .with(P.when(isUserEvent), matchedEvent => {
          const { formProps, ...otherFormHookResults } = useUserEventForm({
            userEvent: isEditing ? matchedEvent : undefined,
            onSubmit: close,
          });
          return {
            ...otherFormHookResults,
            stepElement: <EditUserEventForm formProps={formProps} />,
          };
        })
        .with(P.when(isProtocol), matchedEvent => {
          const { formProps, ...otherFormHookResults } = useProtocolForm({
            protocol: isEditing ? matchedEvent : undefined,
            onSubmit: handleNextStepSubmit,
          });
          return {
            ...otherFormHookResults,
            stepElement: <EditProtocolForm formProps={formProps} />,
          };
        })
        .otherwise(R.always(null)) as UseModalStepInterface<
        EditEventModalSteps.editEvent,
        EditEventModalSteps,
        EditEventModalFormTypes
      > | null;
    },

    [EditEventModalSteps.editProtocolInjections]: ({
      stepperModalState,
      handleNextStepSubmit,
    }) => {
      if (!isProtocol(stepperModalState)) {
        return null;
      }

      const { formProps, ...otherFormHookResults } = useProtocolInjectionsForm({
        protocol: stepperModalState,
        onSubmit: handleNextStepSubmit,
      });
      return {
        ...otherFormHookResults,
        stepElement: (
          <EditProtocolInjectionsForm
            protocol={stepperModalState}
            formProps={formProps}
          />
        ),
        modalProps: {
          title: 'Добавление инъекций по дням',
        },
      };
    },

    [EditEventModalSteps.editProtocolDaysUntilCheck]: ({
      stepperModalState,
      handleNextStepSubmit,
    }) => {
      if (!isProtocol(stepperModalState)) {
        return null;
      }

      const { formProps, ...otherFormHookResults } =
        useProtocolDaysUntilCheckForm({
          protocol: stepperModalState,
          onSubmit: handleNextStepSubmit,
        });
      return {
        ...otherFormHookResults,
        stepElement: <EditProtocolDaysUntilCheckForm formProps={formProps} />,
        modalProps: {
          title: 'День проверки животного',
        },
      };
    },

    [EditEventModalSteps.editProtocolTotalTreatmentCost]: ({
      stepperModalState,
      handleNextStepSubmit,
    }) => {
      if (!isProtocol(stepperModalState)) {
        return null;
      }

      const { formProps, ...otherFormHookResults } =
        useProtocolTotalTreatmentCostForm({
          protocol: stepperModalState,
          onSubmit: handleNextStepSubmit,
        });
      return {
        ...otherFormHookResults,
        stepElement: (
          <EditProtocolTotalTreatmentCostForm
            protocol={stepperModalState}
            formProps={formProps}
          />
        ),
        modalProps: {
          title: 'Стоимость инъекций',
          submitButtonProps: {
            children: 'Сохранить настройки',
          },
        },
      };
    },
  },
})(EditEventModalInternal);
