import React from 'react';

import clsx from 'clsx';

import { SkeletonBlock } from '../SkeletonBlock';
import styles from './index.module.scss';

const BAR_WIDTH_PERCENT = `20%`;

// We calculate responsive skeleton based on basic chart height from design
const BASE_SKELETON_HEIGHT_PX = 148;
const BASE_BAR_HEIGHTS_PX = [
  12, 20, 32, 48, 56, 72, 100, 132, 148, 148, 148, 148,
];

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
}

export const SkeletonBarChart: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(styles.root, 'full-height', className)}>
      {BASE_BAR_HEIGHTS_PX.map((barHeight, index) => (
        <SkeletonBlock
          key={`${barHeight}__${index}`}
          {...{
            className: 'full-height flex items-end',
            width: BAR_WIDTH_PERCENT,
            height: `${(barHeight / BASE_SKELETON_HEIGHT_PX) * 100}%`,
          }}
        />
      ))}
    </div>
  );
};
