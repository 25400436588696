import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportBlueprintSourceFieldValueFragmentDoc } from '../../../../entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';
import { CustomReportRowValueFragmentDoc } from './customReportRowValue.graphql';
export type CustomReportRowFragment = { __typename: 'CustomReportRow', blueprintSourceFieldValue?: { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null, values: Array<{ __typename: 'CustomReportRowValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null> };

export const CustomReportRowFragmentDoc = gql`
    fragment CustomReportRow on CustomReportRow {
  __typename
  blueprintSourceFieldValue {
    ...CustomReportBlueprintSourceFieldValue
  }
  values {
    ...CustomReportRowValue
  }
}
    ${CustomReportBlueprintSourceFieldValueFragmentDoc}
${CustomReportRowValueFragmentDoc}`;