import { getTmpId } from '~/shared/helpers/string';

import { SkeletonPlaceholder } from './types';

const skeletonSymbol = Symbol('skeleton');

/**
 * Single skeleton placeholder
 */
export const getSingleSkeletonPlaceholder = () => {
  const placeholder = { id: getTmpId('skeleton') } as SkeletonPlaceholder;
  placeholder[skeletonSymbol] = true;
  return placeholder;
};

/**
 * Helper to generate an array with passed count, containing undefined placeholders for rendering skeletons
 */
export const getSkeletonPlaceholders = (length: number) =>
  Array.from({ length }, getSingleSkeletonPlaceholder);

/**
 * Checks, if an item is a skeleton placeholder special object
 */
export const isSkeletonPlaceholder = (
  item: unknown
): item is SkeletonPlaceholder =>
  !!item && Object.getOwnPropertySymbols(item).some(s => s === skeletonSymbol);
