import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type EmployeeShortFragment = { __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string, number: string };

export const EmployeeShortFragmentDoc = gql`
    fragment EmployeeShort on Employee {
  __typename
  id
  firstName
  middleName
  lastName
  number
}
    `;