import React from 'react';

import { useApolloClient } from '@apollo/client';

import R from 'ramda';
import * as yup from 'yup';

import { Input } from '~/shared/components/Input';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { makeDeleteQueriesByNameWithoutVariables } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { RoleAsyncSelect } from '~/entities/roles';
import { readRoleFragment } from '~/entities/roles/helpers';

import formStyles from '~/styles/modules/form.module.scss';

import { ReproductionHdrAndPrReportFragment } from '../../gql/fragments/reproductionHdrAndPrReport.graphql';
import { useCreateReproductionHdrAndPrReportMutation } from '../../gql/mutations/createReproductionHdrAndPrReport.graphql';
import { useUpdateReproductionHdrAndPrReportMutation } from '../../gql/mutations/updateReproductionHdrAndPrReport.graphql';
import { ReproductionHdrAndPrReportsQueryVariables } from '../../gql/queries/reproductionHdrAndPrReports.graphql';
import { updateReproductionHdrAndPrReportFragment } from '../../helpers';

export interface EditReproductionHdrAndPrReportModalProps
  extends InjectedModalProps<EditReproductionHdrAndPrReportModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing HDR and PR report, if not passed, a new one is created
   */
  reproductionHdrAndPrReport?: ReproductionHdrAndPrReportFragment;
  /**
   * Gql HDR and PR report query variables
   */
  queryVariables: ReproductionHdrAndPrReportsQueryVariables;
}

const FORM_ID = 'EditReproductionHdrAndPrReportForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  roleIDs: yup.array(yup.string().default('')).default([]), // [ID]
});

type EditReproductionHdrAndPrReportFormType = InferSchemaWithDefaults<
  typeof SCHEMA
>;
type EditReproductionHdrAndPrReportFormTransformedType = InferValidatedSchema<
  typeof SCHEMA
>;

export const EditReproductionHdrAndPrReportModal: React.FC<
  EditReproductionHdrAndPrReportModalProps
> = ({ className, reproductionHdrAndPrReport, queryVariables, close }) => {
  const client = useApolloClient();

  const { sendSuccessToast } = useNotifications();

  const isEditing = !!reproductionHdrAndPrReport;

  const formContext = useForm<
    EditReproductionHdrAndPrReportFormType,
    EditReproductionHdrAndPrReportFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['name'],
        reproductionHdrAndPrReport ?? ({} as ReproductionHdrAndPrReportFragment)
      ),
      roleIDs: reproductionHdrAndPrReport?.roles.map(R.prop('id')),
    },
  });

  const [
    createReproductionHdrAndPrReport,
    { loading: isCreateReproductionHdrAndPrReportLoading },
  ] = useCreateReproductionHdrAndPrReportMutation();

  const [
    updateReproductionHdrAndPrReport,
    { loading: isUpdateReproductionHdrAndPrReportLoading },
  ] = useUpdateReproductionHdrAndPrReportMutation();

  const handleSubmit = async (
    form: EditReproductionHdrAndPrReportFormTransformedType
  ) => {
    if (isEditing) {
      await updateReproductionHdrAndPrReport({
        variables: {
          id: reproductionHdrAndPrReport.id,
          input: form,
        },
        optimisticResponse: { updateReproductionHdrAndPrReport: null },
        update: R.juxt([
          updateReproductionHdrAndPrReportFragment(
            reproductionHdrAndPrReport.id,
            draft => {
              draft.name = form.name;
              draft.roles = form.roleIDs
                .map(roleId => readRoleFragment(client, roleId))
                .filter(Boolean);
            }
          ),
          makeDeleteQueriesByNameWithoutVariables(
            'reproductionHdrAndPrReports',
            queryVariables
          ),
        ]),
      });
    } else {
      await createReproductionHdrAndPrReport({
        variables: {
          input: form,
        },
        refetchQueries: ['reproductionHdrAndPrReports'],
      });
    }
    sendSuccessToast(isEditing ? 'Отчёт отредактирован' : 'Отчёт создан');

    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing ? 'Редактирование отчёта' : 'Создание отчёта',
        submitButtonProps: {
          form: FORM_ID,
          isLoading:
            isCreateReproductionHdrAndPrReportLoading ||
            isUpdateReproductionHdrAndPrReportLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: formStyles.singleColumnForm,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Input
          {...{
            name: 'name',
            label: 'Название отчёта',
            placeholder: 'Введите название',
          }}
        />
        <RoleAsyncSelect
          {...{
            name: 'roleIDs',
            label: 'Пользователь отчёта',
            isMulti: true,
          }}
        />
      </Form>
    </Modal>
  );
};
