import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { InjectionFragment } from '../gql/fragments/injection.graphql';
import { useCreateInjectionMutation } from '../gql/mutations/createInjection.graphql';
import { useUpdateInjectionMutation } from '../gql/mutations/updateInjection.graphql';

/**
 * Props for editing an event with Injection kind
 */
interface Props {
  /**
   * If passed, this is the event to edit, creating new otherwise
   */
  injection?: InjectionFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (newInjection?: InjectionFragment) => void;
}

const FORM_ID = 'EditInjectionForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  daysMilkWithholdAfterLastThreatment: yup.number().default(null).required(),
  daysMeatWithholdAfterLastThreatment: yup.number().default(null).required(),
  cost: yup.number().nullable().default(null),
});

/**
 * Form for editing an event with Injection kind
 */
export type EditInjectionFormType = InferValidatedSchema<typeof SCHEMA>;

export const useInjectionForm = ({
  injection,
  onSubmit,
}: Props): UseModalStepFormInterface<EditInjectionFormType> => {
  const isEditing = !!injection;

  const [isLoading, setLoading] = useState(false);

  const [createInjection] = useCreateInjectionMutation();
  const [updateInjection] = useUpdateInjectionMutation();

  const formContext = useForm<EditInjectionFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        [
          'name',
          'daysMilkWithholdAfterLastThreatment',
          'daysMeatWithholdAfterLastThreatment',
          'cost',
        ],
        injection ?? ({} as InjectionFragment)
      ),
    },
  });

  const handleSubmit = async (input: EditInjectionFormType) => {
    setLoading(true);
    try {
      let newInjection: InjectionFragment | undefined;
      if (isEditing) {
        await updateInjection({
          variables: { id: injection.id, input },
          refetchQueries: ['events'],
        });
      } else {
        const res = await createInjection({
          variables: { input },
          refetchQueries: ['events'],
        });

        newInjection = res.data?.createInjection;
      }
      onSubmit?.(newInjection);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
