import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { TestMilkingFields } from '~/entities/testMilkings';

import TOKENS from '~/styles/__generated__/tokens.json';

import {
  CowChartLineDataSources,
  CowChartLineValueTypeConfig,
  CowChartLineValueTypes,
} from './types';

/**
 * Default
 */
export const COW_CHART_SETTINGS_SCHEMA = yup.object({
  approximationFractions: yup
    .array(oneOfEnum<TestMilkingFields>(TestMilkingFields))
    .default([]),
  milkFractions: yup
    .array(oneOfEnum<TestMilkingFields>(TestMilkingFields))
    .default([TestMilkingFields.weight]),
  testMilkFractions: yup
    .array(oneOfEnum<TestMilkingFields>(TestMilkingFields))
    .default([TestMilkingFields.weight]),
});

/**
 * Rendering configs for different cow chart line value types
 */
export const COW_CHART_LINE_VALUE_TYPE_CONFIGS: Record<
  CowChartLineValueTypes,
  CowChartLineValueTypeConfig
> = {
  [CowChartLineValueTypes.milkWeightKilograms]: {
    name: 'надой в кг',
    color: TOKENS.colorSuccessDefault,
  },
  [CowChartLineValueTypes.fatPercent]: {
    name: '% жирности',
    color: TOKENS.colorAccentDefault,
  },
  [CowChartLineValueTypes.proteinPercent]: {
    name: '% белка',
    color: TOKENS.colorStatus06Default,
  },
  [CowChartLineValueTypes.sccThousandsPerMl]: {
    name: 'сомат. кл',
    color: TOKENS.colorInfo700,
  },
};

/**
 * Data source names for rendering
 */
export const COW_CHART_LINE_DATA_SOURCE_NAMES: Record<
  CowChartLineDataSources,
  string
> = {
  [CowChartLineDataSources.milk]: 'доильный зал',
  [CowChartLineDataSources.testMilk]: 'контрольная дойка',
  [CowChartLineDataSources.predictedMilk]: 'аппрокс. кривая',
};
