import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { AnyRoute, useNavigate, useSearch } from '@tanstack/react-router';

import { capitalize } from '~/shared/helpers/string';
import {
  SerializableParam,
  SerializableState,
} from '~/shared/types/serialization';

type ExtractSearchParamsFromRoute<R extends AnyRoute> = Parameters<
  NonNullable<NonNullable<Parameters<R['useSearch']>[number]>['select']>
>[number];

type SearchParamsValues<T extends SerializableState> = {
  [K in keyof Required<T>]: T[K];
};

type SearchParamSetterKey<K> = `set${Capitalize<Extract<K, string>>}`;

type SearchParamsSetters<T extends SerializableState> = {
  [K in keyof Required<T> as SearchParamSetterKey<K>]: Dispatch<
    SetStateAction<T[K]>
  >;
};

type ExtractSerializableState<T extends SerializableState | AnyRoute> =
  T extends AnyRoute
    ? ExtractSearchParamsFromRoute<T>
    : T extends SerializableState
      ? T
      : never;

type UseSearchParamsStateInterface<T extends SerializableState | AnyRoute> =
  SearchParamsValues<ExtractSerializableState<T>> &
    SearchParamsSetters<ExtractSerializableState<T>>;

const isSetActionFunction = <T>(
  setter: SetStateAction<T>
): setter is (prevState: T) => T => {
  return typeof setter === 'function';
};

/**
 * Hook for using state, stored in the search part of the router
 */
export const useSearchParamsState = <
  T extends SerializableState | AnyRoute,
>(): UseSearchParamsStateInterface<T> => {
  const navigate = useNavigate();
  const searchParamsMatched: ExtractSerializableState<T> = useSearch({
    strict: false,
  });
  const [searchParams, setSearchParams] =
    useState<ExtractSerializableState<T>>(searchParamsMatched);

  const stateKeys = Object.keys(searchParams) as Extract<keyof T, string>[];

  const getSearchParamSetterKey = <K extends string>(key: K) =>
    `set${capitalize(key)}` as SearchParamSetterKey<typeof key>;

  const stateKeysHash = stateKeys.join('__');

  const searchParamsSetters = useMemo(
    () =>
      stateKeys.reduce(
        (acc, key) => {
          const setter: Dispatch<
            SetStateAction<ExtractSerializableState<T>[typeof key]>
          > = valueOrSetValue => {
            setSearchParams(currentParams => {
              let newValue: SerializableParam;

              if (
                isSetActionFunction<ExtractSerializableState<T>[typeof key]>(
                  valueOrSetValue
                )
              ) {
                const currentValue = currentParams[key];
                newValue = valueOrSetValue(currentValue);
              } else {
                newValue = valueOrSetValue;
              }

              return { ...currentParams, [key]: newValue };
            });
          };

          acc[getSearchParamSetterKey(key)] = setter as any;

          return acc;
        },
        {} as SearchParamsSetters<ExtractSerializableState<T>>
      ),
    [stateKeysHash]
  );

  useEffect(() => {
    navigate({
      search: searchParams,
      replace: true,
      resetScroll: false,
    });
  }, [searchParams]);

  return {
    ...searchParams,
    ...searchParamsSetters,
  };
};
