import React from 'react';

import { AppEmployee } from '~/~legacy/types/entities';
import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { EmployeeSelectorEdit } from './EmployeeSelectorEdit';
import { EmployeeSelectorView } from './EmployeeSelectorView';

export const EmployeeSelector: React.FC<
  ValueEditorArgs<AppEmployee>
> = props => {
  const Component = EditorHoC(EmployeeSelectorView, EmployeeSelectorEdit);

  return <Component {...props} />;
};
