import { getInputValue } from '~/~legacy/helpers/getInputValue';
import {
  BlueprintExecutionInfo,
  BlueprintExecutionResult,
  BlueprintExecutionService,
} from '~/~legacy/services/BlueprintExecutionService';
import { StandardService } from '~/~legacy/services/implementations/StandardService';

import { API_URL } from '~/shared/constants';
import {
  BlueprintLaunchCycleInput,
  BlueprintLaunchInput,
  BlueprintLaunchSpecialInput,
  GetBlueprintExecutionInfoQuery,
  GetBlueprintExecutionInfoQueryVariables,
  LaunchBlueprintMutation,
  LaunchBlueprintMutationVariables,
  NodeValueKindEnum,
} from '~/shared/graphql';

import {
  GetBlueprintExecutionInfoGQL,
  LaunchBlueprintGQL,
} from '~/services/gql/queries/blueprintExecution.gql';

import { BlueprintExecutionResponseConvertor } from './BlueprintExecutionResponseConvertor';

export class AppBlueprintExecutionService
  extends StandardService
  implements BlueprintExecutionService
{
  private convertor = new BlueprintExecutionResponseConvertor();

  getBlueprintExecutionInfo = async (
    blueprintId: string
  ): Promise<BlueprintExecutionInfo> => {
    if (!blueprintId) throw new Error('Blueprint Id must not be empty');

    const response = await this.http.gql<
      GetBlueprintExecutionInfoQuery,
      GetBlueprintExecutionInfoQueryVariables
    >(API_URL, {
      query: GetBlueprintExecutionInfoGQL,
      variables: {
        id: blueprintId,
      },
    });

    const result = this.convertor.fromBlueprintExecutionInfoResponse(
      response,
      blueprintId
    );
    return result;
  };

  async executeBlueprint(
    info: BlueprintExecutionInfo
  ): Promise<BlueprintExecutionResult> {
    const inputs: BlueprintLaunchInput[] = info.inputs
      .filter(x => !x.cycleInputId)
      .map(input => {
        const value = getInputValue(
          input.userInput,
          input.valueKind,
          NodeValueKindEnum.Hardcode
        );

        return {
          inputID: input.id || '',
          value,
        };
      });

    const cycleInputs: BlueprintLaunchCycleInput[] = [];

    info.cycleInputs.forEach(cycleInput => {
      cycleInput.userInput.forEach(dataItem => {
        // first element is tempId, so we shift it
        const noTempIdItems = dataItem.filter((item, index) => index > 0);
        cycleInputs.push({
          cycleInputID: cycleInput.id,
          inputs: noTempIdItems.map((content, index) => {
            return {
              inputID: cycleInput.inputs[index].id,
              value: getInputValue(
                content,
                cycleInput.inputs[index].valueKind,
                NodeValueKindEnum.Hardcode
              ),
            };
          }),
        });
      });
    });

    const specialInput: BlueprintLaunchSpecialInput | undefined =
      info.specialInput
        ? {
            value: {
              jsonValue: JSON.stringify(info.specialInput.convertedForApi),
            },
          }
        : undefined;

    const response = await this.http.gql<
      LaunchBlueprintMutation,
      LaunchBlueprintMutationVariables
    >(API_URL, {
      query: LaunchBlueprintGQL,
      variables: {
        id: info.id,
        inputs,
        cycleInputs,
        specialInput,
        runOn: info.runOn,
      },
    });

    const result = this.convertor.fromLaunchBlueprintResponse(response);
    return result;
  }
}
