import React, { PropsWithoutRef } from 'react';

interface WithVariantProps<Variant extends string> {
  variant?: Variant;
}

/**
 * Hoc to create a component with default props, applied depending on 'variant' prop
 */
export const withDefaultPropsByVariant =
  <Variant extends string, Props extends WithVariantProps<Variant>>(
    propsDict: Partial<Record<Variant, Partial<Props>>>,
    defaultVariant: Variant
  ) =>
  (Component: React.ComponentType<Props>) => {
    const WrappedComponent = React.forwardRef(
      (props: PropsWithoutRef<Props>, ref: any) => {
        const variant = props.variant ?? defaultVariant;
        const defaultProps = variant ? propsDict[variant] : undefined;

        return (
          <Component ref={ref} {...({ ...defaultProps, ...props } as Props)} />
        );
      }
    );

    WrappedComponent.displayName = `withDefaultPropsByVariant(${
      Component.displayName || Component.name || ''
    })`;

    return WrappedComponent;
  };
