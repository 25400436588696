import React, { useState } from 'react';

import R from 'ramda';

import { PenGroupShortInfo } from '~/~legacy/types/entities';
import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { SelectThemes } from '~/shared/components/Select';

import { PenGroupAsyncSelect } from '~/entities/penGroups';

export const PenGroupSelectorEdit: React.FC<
  ValueEditorStandardProps<PenGroupShortInfo>
> = props => {
  const selectTheme = props.additionalArgs?.selectTheme ?? SelectThemes.light;
  // We have some unintuitive fields change logic that can't provide correct value for props.
  // This state is an artifact from previous implementation and should be removed,
  // when we rework cow fields
  const [selectedGroup, setSelectedGroup] = useState<PenGroupShortInfo>();

  return (
    <PenGroupAsyncSelect
      {...{
        name: 'groupId',
        theme: selectTheme,
        placeholder: 'Выберите группу',
        rawValue: selectedGroup?.id ?? props.value?.id,
        onValueChange: newValue => {
          // TODO replace PenGroupShortInfo with standard fragment
          const group = newValue
            ? {
                id: newValue.id,
                name: newValue.name,
                identifier: newValue.identifier,
                farmId: newValue.farm.id,
              }
            : undefined;
          props.onChange?.(group);
          setSelectedGroup(group);
        },
        ...R.omit(
          [
            'name',
            'onValueChange',
            'onValueChangeDebounced',
            'value',
            'defaultValue',
          ],
          props
        ),
      }}
    />
  );
};
