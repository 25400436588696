import { useCallback, useState } from 'react';

/**
 * Reusing boolean state toggle logic
 */
export function useToggle(
  defaultValue?: boolean
): [boolean, (newValue?: boolean) => boolean] {
  const [value, setValue] = useState(!!defaultValue);

  const toggle: (newValue?: boolean) => boolean = useCallback(
    newValue => {
      let valueToSet = !value;
      if (newValue !== undefined) {
        valueToSet = newValue;
      }

      setValue(valueToSet);

      return valueToSet;
    },
    [value]
  );

  return [value, toggle];
}
