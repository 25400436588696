import React, { useMemo } from 'react';

import { BlueprintInputField } from '~/~legacy/services/BlueprintExecutionService';
import { EditorsMap } from '~/~legacy/value-editors/editorsMap';

import { getAdditionalArgs } from '../../helpers';
import styles from './index.module.scss';

interface Props {
  inputs: BlueprintInputField[];
  onChange: (input: BlueprintInputField, val: any) => void;
}

export const UserInputs: React.FC<Props> = ({ inputs, onChange }) => {
  const editors = useMemo(() => {
    return EditorsMap;
  }, []);

  const components = inputs
    .filter(x => !x.cycleInputId)
    .map(input => {
      const editor = editors[input.valueKind] || editors.Unknown;

      const onChangeCallback = (val: any) => {
        onChange(input, val);
        return Promise.resolve(true);
      };

      const additionalArgs = getAdditionalArgs(input.valueKind);

      const component = editor({
        name: input.valueKind,
        edit: true,
        hideControls: true,
        noForm: true,

        className: styles.input,
        onChange: onChangeCallback,
        value: input.userInput,
        label: input.prompt,

        additionalArgs,
      });

      return (
        <div key={input.id}>
          <div>{component}</div>
        </div>
      );
    });

  return <div className={styles.inputs}>{components}</div>;
};
