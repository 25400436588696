import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

/**
 * Props for a wrapper of a react-hook-form form provider
 */
export interface FormProps<
  TFieldValues extends FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues = TFieldValues,
> extends React.ComponentProps<'form'> {
  formContext: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
  children: ReactNode;
}

/**
 * Wrapper for react-hook-form forms that provides context and a form tag
 */
export const Form = <
  TFieldValues extends FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues = TFieldValues,
>({
  formContext,
  children,

  ...formProps
}: FormProps<TFieldValues, TContext, TTransformedValues>) => {
  return (
    <FormProvider {...formContext}>
      <form {...formProps}>{children}</form>
    </FormProvider>
  );
};
