import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useApolloClient } from '@apollo/client';

import { BlueprintRoleColor } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import {
  Button,
  ButtonProps,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { getTmpId, isTmpId } from '~/shared/helpers/string';

import { Callout, CalloutSizes } from '~/services/notifications';

import { ROLE_SCHEMA, useDeleteRoleModal } from '~/entities/roles';
import { readRoleDetailedFragment } from '~/entities/roles/helpers';

import panelStyles from '~/styles/modules/panel.module.scss';

import { EditCompanyFormType } from '../..';
import companyFieldArraysStyles from '../../companyFieldArrays.module.scss';
import { BlueprintRoleColorSelect } from '../BlueprintRoleColorSelect';
import { getNextRoleColor } from './helpers';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Active company id to load replacement roles for
   */
  companyId?: string;
}

export const EditRolesArrayFieldsForm: React.FC<Props> = ({
  className,
  companyId,
}) => {
  const formContext = useFormContext<EditCompanyFormType>();

  const { append: appendToRolesArray, remove: removeFromRolesArray } =
    useFieldArray({
      control: formContext.control,
      name: 'roles',
      keyName: 'uniqKey',
    });
  const roleArrayItemFields = formContext.watch('roles');

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    theme: ButtonThemes.accent,
    variant: ButtonVariants.secondary,
    onPress: () =>
      appendToRolesArray({
        ...ROLE_SCHEMA.getDefault(),
        id: getTmpId('role'),
        color: getNextRoleColor(
          (formContext.getValues('roles') ?? []).map<BlueprintRoleColor>(
            R.prop('color')
          )
        ),
      }),
    children: 'Добавить роль',
  } satisfies ButtonProps;

  const client = useApolloClient();

  const { open: openDeleteRoleModal } = useDeleteRoleModal();

  const hasRoleItems = !!roleArrayItemFields?.some(r => !r.shouldBeDeleted);

  const deletedRoles =
    roleArrayItemFields?.filter(roleFields => roleFields.shouldBeDeleted) ?? [];
  const deletedRoleNames = deletedRoles.map(R.prop('name')).join(', ');

  return (
    <div className={clsx(styles.root, className)}>
      {!!deletedRoles?.length && (
        <Callout
          {...{
            className: 'full-width',
            message: `Роль ${deletedRoleNames} удалена из списка. Полное удаление произойдёт после сохранения настроек`,
            size: CalloutSizes.small8,
            functionButtonProps: {
              children: 'Отмена',
              onPress: () => {
                formContext.setValue(
                  'roles',
                  formContext.getValues('roles')?.map(role => ({
                    ...role,
                    shouldBeDeleted: false,
                    migrateToRoleID: null,
                  }))
                );
              },
            },
          }}
        />
      )}
      {!hasRoleItems && (
        <div className={clsx('full-width p-24', panelStyles.panel)}>
          <DataBlockedMessage
            {...{
              className: 'p-24',
              message: 'Роли пока не добавлены',
              buttonProps: addButtonProps,
            }}
          />
        </div>
      )}
      {hasRoleItems && (
        <>
          <div className={companyFieldArraysStyles.tableHeader}>
            <Typography variant={TypographyVariants.descriptionLargeStrong}>
              Цвет
            </Typography>
            <Typography variant={TypographyVariants.descriptionLargeStrong}>
              Название
            </Typography>
          </div>
          {roleArrayItemFields?.map((roleFields, roleIndex) => {
            if (roleFields.shouldBeDeleted) return null;

            return (
              <div
                key={roleFields.id}
                className={companyFieldArraysStyles.tableRow}
              >
                <BlueprintRoleColorSelect
                  {...{
                    name: `roles.${roleIndex}.color`,
                  }}
                />
                <Input
                  {...{
                    name: `roles.${roleIndex}.name`,
                  }}
                />
                <FunctionButton
                  {...{
                    className: clsx(
                      'col-start-4',
                      companyFieldArraysStyles.deleteButton
                    ),
                    iconVariant: IconVariants.delete,
                    onPress: () => {
                      if (isTmpId(roleFields.id)) {
                        removeFromRolesArray(roleIndex);
                        return;
                      }

                      const roleFragment = readRoleDetailedFragment(
                        client,
                        roleFields.id
                      );

                      if (!roleFragment?.shouldBeMigratedOnDelete) {
                        formContext.setValue(
                          `roles.${roleIndex}.shouldBeDeleted`,
                          true
                        );
                        return;
                      }

                      openDeleteRoleModal({
                        role: roleFragment,
                        companyId,
                        onConfirmed: migrateToRoleID => {
                          formContext.setValue(`roles.${roleIndex}`, {
                            ...formContext.getValues(`roles.${roleIndex}`),
                            shouldBeDeleted: true,
                            migrateToRoleID,
                          });
                        },
                      });
                    },
                  }}
                />
              </div>
            );
          })}
          <Button
            {...{
              ...addButtonProps,
              size: ButtonSizes.small24,
            }}
          />
        </>
      )}
    </div>
  );
};
