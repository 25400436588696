import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLivestockForecastSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateLivestockForecastSettingInput;
}>;


export type UpdateLivestockForecastSettingMutation = { __typename?: 'Mutation', updateLivestockForecastSetting?: any | null };


export const UpdateLivestockForecastSettingDocument = gql`
    mutation updateLivestockForecastSetting($id: ID!, $input: UpdateLivestockForecastSettingInput!) {
  updateLivestockForecastSetting(id: $id, input: $input)
}
    `;
export type UpdateLivestockForecastSettingMutationFn = Apollo.MutationFunction<UpdateLivestockForecastSettingMutation, UpdateLivestockForecastSettingMutationVariables>;

/**
 * __useUpdateLivestockForecastSettingMutation__
 *
 * To run a mutation, you first call `useUpdateLivestockForecastSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLivestockForecastSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLivestockForecastSettingMutation, { data, loading, error }] = useUpdateLivestockForecastSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLivestockForecastSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLivestockForecastSettingMutation, UpdateLivestockForecastSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLivestockForecastSettingMutation, UpdateLivestockForecastSettingMutationVariables>(UpdateLivestockForecastSettingDocument, options);
      }
export type UpdateLivestockForecastSettingMutationHookResult = ReturnType<typeof useUpdateLivestockForecastSettingMutation>;
export type UpdateLivestockForecastSettingMutationResult = Apollo.MutationResult<UpdateLivestockForecastSettingMutation>;
export type UpdateLivestockForecastSettingMutationOptions = Apollo.BaseMutationOptions<UpdateLivestockForecastSettingMutation, UpdateLivestockForecastSettingMutationVariables>;