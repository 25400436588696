import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateCustomReportInput;
}>;


export type UpdateCustomReportMutation = { __typename?: 'Mutation', updateCustomReport?: any | null };


export const UpdateCustomReportDocument = gql`
    mutation updateCustomReport($id: ID!, $input: UpdateCustomReportInput!) {
  updateCustomReport(id: $id, input: $input)
}
    `;
export type UpdateCustomReportMutationFn = Apollo.MutationFunction<UpdateCustomReportMutation, UpdateCustomReportMutationVariables>;

/**
 * __useUpdateCustomReportMutation__
 *
 * To run a mutation, you first call `useUpdateCustomReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomReportMutation, { data, loading, error }] = useUpdateCustomReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomReportMutation, UpdateCustomReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomReportMutation, UpdateCustomReportMutationVariables>(UpdateCustomReportDocument, options);
      }
export type UpdateCustomReportMutationHookResult = ReturnType<typeof useUpdateCustomReportMutation>;
export type UpdateCustomReportMutationResult = Apollo.MutationResult<UpdateCustomReportMutation>;
export type UpdateCustomReportMutationOptions = Apollo.BaseMutationOptions<UpdateCustomReportMutation, UpdateCustomReportMutationVariables>;