import React, { useCallback, useMemo, useState } from 'react';

import R from 'ramda';
import { match } from 'ts-pattern';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { BlueprintsService } from '~/~legacy/services/BlueprintsService';
import { MaslovServices } from '~/~legacy/types/services';

import { RadioGroup } from '~/shared/components/RadioGroup';
import {
  ViewGraphKindEnum,
  ViewKindEnum,
  VitalityFilter,
} from '~/shared/graphql';
import { useObservable } from '~/shared/hooks/useObservable';

import {
  AppBlueprintViewTableSettings,
  BlueprintEditService,
  BlueprintGeneralSetings,
  BlueprintViewSetings,
} from '~/widgets/blueprintEdit';

import { ActionView } from './components/ActionView';
import { TableView } from './components/TableView';
import styles from './index.module.scss';

const VIEW_KIND_RADIO_ITEMS = [
  {
    id: ViewKindEnum.Table,
    name: 'Список',
  },
  {
    id: ViewKindEnum.Action,
    name: 'Ввод данных',
  },
];

const defaultViewSettings: BlueprintViewSetings = {
  kind: ViewKindEnum.Table,
  kindSettings: {
    graphSettings: {
      kind: ViewGraphKindEnum.HorizontalBars,
    },
    tableSettings: {
      fieldsIds: [],
      groupByFieldsIds: [],
    },
  },
};

const defaultGeneralSettings: BlueprintGeneralSetings = {
  vitalityFilter: VitalityFilter.Alive,
  orderedInputIDs: [],
};

interface Props {
  blueprintId: string;
}

export const ViewSection: React.FC<Props> = ({ blueprintId }) => {
  const bpSvc = useService<BlueprintsService>(MaslovServices.BlueprintsService);
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const [blueprintGeneralSettings, setBlueprintGeneralSettings] =
    useState<BlueprintGeneralSetings>(
      bpEditSvc.blueprint?.generalSettings ?? defaultGeneralSettings
    );
  const [blueprintViewSettings, setBlueprintViewSettings] =
    useState<BlueprintViewSetings>(
      bpEditSvc.blueprint?.viewSettings ?? defaultViewSettings
    );

  const memoizedUpdate = useMemo(() => {
    return bpEditSvc.updateViewSettings.bind(bpEditSvc);
  }, [bpEditSvc]);

  const memoizedUpdateBlueprint = useMemo(() => {
    return bpSvc.update.bind(bpSvc);
  }, [bpSvc]);

  const { reload: updateViewSettings } = useApiData(memoizedUpdate);

  const { reload: updateBlueprint } = useApiData(memoizedUpdateBlueprint);

  const masterSourceNodes = useObservable(
    bpEditSvc.blueprint?.masterSourceNodes$,
    []
  );

  const sourceNodes = useObservable(bpEditSvc.blueprint?.sourceNodes$, []);
  const inputNodes = useObservable(bpEditSvc.blueprint?.inputNodes$, []);

  const handleUpdateVitality = useCallback(
    async (value: VitalityFilter) => {
      const result = await updateBlueprint({
        id: blueprintId,
        input: {
          generalSettings: {
            ...blueprintGeneralSettings,
            vitalityFilter: value,
          },
        },
      });
      if (result.success) {
        setBlueprintGeneralSettings({
          ...blueprintGeneralSettings,
          vitalityFilter: value,
        });
      }
    },
    [blueprintGeneralSettings, updateBlueprint, blueprintId]
  );

  const handleKindChange = useCallback(async (kind: ViewKindEnum) => {
    const updatedSettings = {
      ...blueprintViewSettings,
      kind,
    };

    const result = await updateViewSettings(updatedSettings);
    if (result.success) {
      setBlueprintViewSettings(updatedSettings);
    }

    if (kind === ViewKindEnum.Action) {
      handleUpdateVitality(VitalityFilter.Alive);
    }
  }, []);

  const handleUpdateTableSettings = useCallback(
    async (tableSettings: AppBlueprintViewTableSettings) => {
      const updatedSettings: BlueprintViewSetings = {
        ...blueprintViewSettings,
        kindSettings: {
          ...blueprintViewSettings.kindSettings,
          tableSettings,
        },
      };

      const prevSetings = blueprintViewSettings;
      setBlueprintViewSettings(updatedSettings);
      const result = await updateViewSettings(updatedSettings);
      if (!result.success) {
        setBlueprintViewSettings(prevSetings);
      }
    },
    [blueprintViewSettings]
  );

  const handleUpdateInputsOrder = useCallback(
    async (fieldIds: string[]) => {
      const updatedSettings: BlueprintGeneralSetings = {
        ...blueprintGeneralSettings,
        orderedInputIDs: fieldIds,
      };

      const prevSetings = blueprintGeneralSettings;
      setBlueprintGeneralSettings(updatedSettings);
      const result = await updateBlueprint({
        id: blueprintId,
        input: {
          generalSettings: updatedSettings,
        },
      });
      if (!result.success) {
        setBlueprintGeneralSettings(prevSetings);
      }
    },
    [blueprintGeneralSettings]
  );

  return (
    <div className={styles.root}>
      <RadioGroup
        {...{
          className: 'mb-24',
          name: 'viewKind',
          value: blueprintViewSettings.kind,
          onValueChange: handleKindChange,
          items: VIEW_KIND_RADIO_ITEMS,
        }}
      />
      {match(blueprintViewSettings.kind)
        .with(ViewKindEnum.Table, () => (
          <TableView
            {...{
              className: 'full-width',
              masterSourceNodes,
              sourceNodes,

              viewSettings: blueprintViewSettings.kindSettings.tableSettings,
              onViewSettingsChange: handleUpdateTableSettings,

              vitality: blueprintGeneralSettings.vitalityFilter,
              onVitalityChange: handleUpdateVitality,
            }}
          />
        ))
        .with(ViewKindEnum.Action, () => (
          <ActionView
            {...{
              className: 'full-width',
              inputNodes,
              orderedInputIDs: blueprintGeneralSettings.orderedInputIDs,
              onInputsOrderChange: handleUpdateInputsOrder,
            }}
          />
        ))
        .otherwise(R.always(null))}
    </div>
  );
};
