import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { LivestockForecastPeriodFragmentDoc } from '../fragments/livestockForecastPeriod.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LivestockForecastPeriodsQueryVariables = Types.Exact<{
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type LivestockForecastPeriodsQuery = { __typename?: 'Query', livestockForecastPeriods: Array<{ __typename: 'LivestockForecastPeriod', updatedAt: string, farmIDs: Array<string>, since: string, till: string, dryStartCount: number, expectedNewbornHeifersCount: number, cowsStatusCount: { __typename?: 'CowsStatusCount', milking: number, allDry: number, total: number }, cowsMilking: { __typename?: 'CowsMilking', perHead: number, perHerd: number }, calvingCount: { __typename?: 'CalvingCount', heifers: number, cows: number, total: number }, expectedRetiredCount: { __typename?: 'ExpectedRetiredCount', firstLact: number, otherLact: number, total: number } }> };


export const LivestockForecastPeriodsDocument = gql`
    query livestockForecastPeriods($farmIDs: [ID!]) {
  livestockForecastPeriods(farmIDs: $farmIDs) {
    ...LivestockForecastPeriod
  }
}
    ${LivestockForecastPeriodFragmentDoc}`;

/**
 * __useLivestockForecastPeriodsQuery__
 *
 * To run a query within a React component, call `useLivestockForecastPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivestockForecastPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivestockForecastPeriodsQuery({
 *   variables: {
 *      farmIDs: // value for 'farmIDs'
 *   },
 * });
 */
export function useLivestockForecastPeriodsQuery(baseOptions?: Apollo.QueryHookOptions<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>(LivestockForecastPeriodsDocument, options);
      }
export function useLivestockForecastPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>(LivestockForecastPeriodsDocument, options);
        }
export function useLivestockForecastPeriodsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>(LivestockForecastPeriodsDocument, options);
        }
export type LivestockForecastPeriodsQueryHookResult = ReturnType<typeof useLivestockForecastPeriodsQuery>;
export type LivestockForecastPeriodsLazyQueryHookResult = ReturnType<typeof useLivestockForecastPeriodsLazyQuery>;
export type LivestockForecastPeriodsSuspenseQueryHookResult = ReturnType<typeof useLivestockForecastPeriodsSuspenseQuery>;
export type LivestockForecastPeriodsQueryResult = Apollo.QueryResult<LivestockForecastPeriodsQuery, LivestockForecastPeriodsQueryVariables>;