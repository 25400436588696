import React, { ReactNode } from 'react';

import styles from './index.module.scss';

interface Props {
  sectionName: string;
  children?: ReactNode;
  controls?: ReactNode;

  sectionType: 'source' | 'actions' | 'view';
}

export const BlueprintEditSection: React.FC<Props> = props => {
  const {
    children,

    sectionName,
    controls,
    sectionType,
  } = props;

  const sectionCmpnts = children ? (
    <div className={styles.sectionItems}>{children}</div>
  ) : null;

  const controlsCmpnts = controls ? (
    <div className={styles.controls}>{controls}</div>
  ) : null;

  return (
    <fieldset className={styles[sectionType]}>
      <legend>{sectionName}</legend>
      {sectionCmpnts}
      {controlsCmpnts}
    </fieldset>
  );
};
