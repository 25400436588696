import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowUserEventFragment = { __typename: 'CowUserEvent', id: string, userEvent: { __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum } };

export const CowUserEventFragmentDoc = gql`
    fragment CowUserEvent on CowUserEvent {
  __typename
  id
  userEvent {
    __typename
    id
    name
    kind
  }
}
    `;