import { ModalNames } from './__generated__/modalNames';
import { useModal } from './hooks';
import { AnyModalProps, InjectedModalProps } from './types';

/**
 * Helper for creating hooks for using different modals
 */
export const makeUseModal =
  <P extends AnyModalProps>(name: ModalNames) =>
  (props?: Omit<P, keyof InjectedModalProps<any>>) => {
    return useModal(name, props);
  };
