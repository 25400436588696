import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionCrReportFragmentDoc } from '../fragments/reproductionCrReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReproductionCrReportMutationVariables = Types.Exact<{
  input: Types.CreateReproductionCrReportInput;
}>;


export type CreateReproductionCrReportMutation = { __typename?: 'Mutation', createReproductionCrReport: { __typename: 'ReproductionCrReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } };


export const CreateReproductionCrReportDocument = gql`
    mutation createReproductionCrReport($input: CreateReproductionCrReportInput!) {
  createReproductionCrReport(input: $input) {
    ...ReproductionCrReport
  }
}
    ${ReproductionCrReportFragmentDoc}`;
export type CreateReproductionCrReportMutationFn = Apollo.MutationFunction<CreateReproductionCrReportMutation, CreateReproductionCrReportMutationVariables>;

/**
 * __useCreateReproductionCrReportMutation__
 *
 * To run a mutation, you first call `useCreateReproductionCrReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReproductionCrReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReproductionCrReportMutation, { data, loading, error }] = useCreateReproductionCrReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReproductionCrReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReproductionCrReportMutation, CreateReproductionCrReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReproductionCrReportMutation, CreateReproductionCrReportMutationVariables>(CreateReproductionCrReportDocument, options);
      }
export type CreateReproductionCrReportMutationHookResult = ReturnType<typeof useCreateReproductionCrReportMutation>;
export type CreateReproductionCrReportMutationResult = Apollo.MutationResult<CreateReproductionCrReportMutation>;
export type CreateReproductionCrReportMutationOptions = Apollo.BaseMutationOptions<CreateReproductionCrReportMutation, CreateReproductionCrReportMutationVariables>;