import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { TestMilkingUploadFragment } from '../gql/fragments/testMilkingUpload.graphql';
import {
  TestMilkingUploadsQuery,
  TestMilkingUploadsQueryVariables,
  useTestMilkingUploadsQuery,
} from '../gql/queries/testMilkingUploads.graphql';

export const useTestMilkingUploadsPaginatedQuery = makeUsePaginatedQuery<
  TestMilkingUploadFragment,
  TestMilkingUploadsQuery,
  TestMilkingUploadsQueryVariables
>({
  useQuery: useTestMilkingUploadsQuery,
  getItemsFromQueryData: data =>
    data.testMilkingUploads.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.testMilkingUploads.pageInfo,
});
