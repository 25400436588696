const MILK_ROUNDING_FACTOR = 1000;

/**
 * Helper to get milk value, cause we display it in thousands
 */
export const getMilkValue = <K extends string>(
  milkObject: Record<K, number> | undefined,
  field: K
) => {
  if (!milkObject) return 0;

  return milkObject[field] / MILK_ROUNDING_FACTOR;
};
