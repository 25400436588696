import React from 'react';

import styles from './index.module.scss';

export const BlueprintEditHeaderWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <section className={styles.root}>{children}</section>;
};

export { default as blueprintHeaderStyles } from './index.module.scss';
