import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSkeletonPlaceholders,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { DateFormats, formatDate } from '~/services/dateTime';
import { WithSearchParamsValidation } from '~/services/navigation';

import { FarmFilterSearchParams, useFarmsFilter } from '~/entities/farms';

import { PageHeader } from '~/features/layouts';

import {
  CalvingsAndHeadsChart,
  CowsAndMilkChart,
  LivestockForecastPeriodCard,
  LivestockForecastTable,
  useEditLivestockForecastSettingModal,
} from '~/widgets/livestockForecast';
import { useLivestockForecastMonthsQuery } from '~/widgets/livestockForecast/gql/queries/livestockForecastMonths.graphql';
import { useLivestockForecastPeriodsQuery } from '~/widgets/livestockForecast/gql/queries/livestockForecastPeriods.graphql';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

const PERIOD_CARDS_COUNT = 4;
const FORECAST_MONTHS_COUNT = 12;

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/livestock-forecast/'
)({
  wrapInSuspense: true,
  component: LivestockForecastPage,
  validateSearch: ({
    farmId,
  }: WithSearchParamsValidation<FarmFilterSearchParams>) => ({
    farmId: farmId ?? undefined,
  }),
});

function LivestockForecastPage() {
  const { farmId, renderFarmsSelectElement } = useFarmsFilter();

  const { open: openEditLivestockForecastSettingModal } =
    useEditLivestockForecastSettingModal();

  const queryVariables = {
    farmIDs: normalizeToArrayOrUndefined(farmId),
  };

  const {
    data: livestockForecastPeriodsData,
    loading: isLivestockForecastPeriodsLoading,
  } = useLivestockForecastPeriodsQuery({
    variables: queryVariables,
  });
  const livestockForecastPeriods =
    livestockForecastPeriodsData?.livestockForecastPeriods ??
    getSkeletonPlaceholders(PERIOD_CARDS_COUNT);

  const {
    data: livestockForecastMonthsData,
    loading: isLivestockForecastMonthsLoading,
  } = useLivestockForecastMonthsQuery({
    variables: queryVariables,
  });
  const livestockForecastMonths =
    livestockForecastMonthsData?.livestockForecastMonths ??
    getSkeletonPlaceholders(FORECAST_MONTHS_COUNT);

  const forecastUpdatedAt = livestockForecastPeriods.at(0)?.updatedAt;

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Прогноз поголовья',
          rightContent: (
            <>
              {!!forecastUpdatedAt && (
                <Typography
                  {...{
                    className: 'text-soft',
                    variant: TypographyVariants.bodySmall,
                  }}
                >
                  Составлен{' '}
                  {formatDate(forecastUpdatedAt, DateFormats.shortWithTime)}
                </Typography>
              )}
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  onPress: () => openEditLivestockForecastSettingModal(),
                  iconVariant: IconVariants.settings,
                }}
              >
                Настроить прогноз
              </Button>
            </>
          ),
        }}
      />
      {renderFarmsSelectElement({ className: 'default-control mb-24' })}
      <Skeleton isLoading={isLivestockForecastPeriodsLoading}>
        <div className={contentGridStyles.contentGridFrom1To2To4Items}>
          {livestockForecastPeriods.map((period, index) => (
            <LivestockForecastPeriodCard
              key={period?.since ?? index}
              {...{
                period,
              }}
            />
          ))}
        </div>
      </Skeleton>

      <Skeleton isLoading={isLivestockForecastMonthsLoading}>
        <div
          className={clsx('mt-32', contentGridStyles.contentGridFrom1To2Items)}
        >
          <CalvingsAndHeadsChart
            {...{
              livestockForecastMonths,
            }}
          />
          <CowsAndMilkChart
            {...{
              livestockForecastMonths,
            }}
          />
        </div>
      </Skeleton>

      <div className={clsx(panelStyles.largePanel, 'p-24 mt-32')}>
        <Typography
          {...{
            variant: TypographyVariants.bodyLargeStrong,
            tag: 'h2',
            className: 'mb-24',
          }}
        >
          Прогноз поголовья на 12 месяцев
        </Typography>

        <Skeleton isLoading={isLivestockForecastMonthsLoading}>
          <LivestockForecastTable
            {...{
              livestockForecastMonths,
            }}
          />
        </Skeleton>
      </div>
    </div>
  );
}
