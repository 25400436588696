import React from 'react';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useConfirm } from '~/services/modals';

import { MonitorEntryFragment } from '../../gql/fragments/monitorEntry.graphql';
import { useDeleteMonitorEntryMutation } from '../../gql/mutations/deleteMonitorEntry.graphql';
import { useEditMonitorEntryModal } from '../../modals';
import styles from './index.module.scss';

interface Props {
  /**
   * Monitor entry to render
   */
  monitorEntry: MonitorEntryFragment;
}

export const MonitorEntryTargetCell: React.FC<Props> = ({ monitorEntry }) => {
  const { open: openEditMonitorEntryModal } = useEditMonitorEntryModal();

  const [deleteMonitorEntry] = useDeleteMonitorEntryMutation();

  const confirmDelete = useConfirm();

  return (
    <>
      {monitorEntry.target}

      <div className={styles.iconContainer}>
        <ContextMenuButton
          {...{
            className: styles.icon,
            tooltip: 'Действия с показателем',
            items: [
              {
                content: 'Редактировать',
                onPress: () => {
                  openEditMonitorEntryModal({ monitorEntry });
                },
              },
              {
                variant: MenuItemVariants.destructive,
                content: 'Удалить',
                onPress: () => {
                  confirmDelete({
                    title: 'Удаление показателя',
                    message: (
                      <div className="grid gap-12">
                        <Typography
                          tag="p"
                          variant={TypographyVariants.bodySmall}
                        >
                          Вы хотите удалить показатель{' '}
                          <Typography
                            variant={TypographyVariants.bodySmallStrong}
                          >
                            {monitorEntry.name}
                          </Typography>
                          ?
                        </Typography>
                        <Typography
                          tag="p"
                          variant={TypographyVariants.bodySmall}
                        >
                          При удалении показателя, расчёты за все даты будут
                          удалены.
                        </Typography>
                        <Typography
                          tag="p"
                          variant={TypographyVariants.bodySmall}
                        >
                          Это действие невозможно отменить.
                        </Typography>
                      </div>
                    ),
                    isDelete: true,
                  }).then(isConfirmed => {
                    if (!isConfirmed) return;
                    deleteMonitorEntry({
                      variables: {
                        id: monitorEntry.id,
                      },
                      refetchQueries: ['monitor'],
                    });
                  });
                },
              },
            ],
          }}
        />
      </div>
    </>
  );
};
