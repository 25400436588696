import { ModalRegistration } from '~/services/modals';

import {
  EditReproductionCrReportModal,
  EditReproductionCrReportModalProps,
} from '.';

export const registration: ModalRegistration<EditReproductionCrReportModalProps> =
  {
    Component: EditReproductionCrReportModal,
  };
