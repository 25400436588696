import { makeUsePaginatedQuery } from '~/services/gql';

import { CompanyDetailedFragment } from '../gql/fragments/companyDetailed.graphql';
import {
  CompaniesDetailedQuery,
  CompaniesDetailedQueryVariables,
  useCompaniesDetailedQuery,
} from '../gql/queries/companiesDetailed.graphql';

export const useCompaniesDetailedPaginatedQuery = makeUsePaginatedQuery<
  CompanyDetailedFragment,
  CompaniesDetailedQuery,
  CompaniesDetailedQueryVariables
>({
  useQuery: useCompaniesDetailedQuery,
  getItemsFromQueryData: data => data.companies.nodes,
  getPageInfoFromQueryData: data => data.companies.pageInfo,
});
