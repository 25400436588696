import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { ProtocolFragment } from '../gql/fragments/protocol.graphql';
import { useCreateProtocolMutation } from '../gql/mutations/createProtocol.graphql';
import { useUpdateProtocolMutation } from '../gql/mutations/updateProtocol.graphql';
import { readProtocolFragment } from '../helpers';

/**
 * Props for editing an event with Protocol kind
 */
interface Props {
  /**
   * If passed, this is the protocol to edit, creating new otherwise
   */
  protocol?: ProtocolFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (editedProtocol?: ProtocolFragment) => void;
}

const FORM_ID = 'EditProtocolForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  expectedDaysOnProtocol: yup.number().nullable().default(null),
  shouldTransferToAnotherPenGroup: yup.boolean().default(false),
  penGroupID: yup.string().nullable().default(null), // ID
});

/**
 * Form for editing an event with Protocol kind
 */
export type EditProtocolFormType = InferValidatedSchema<typeof SCHEMA>;

const convertProtocolEntityToForm = (protocol?: ProtocolFragment) => ({
  ...R.pick(
    ['name', 'expectedDaysOnProtocol'],
    protocol ?? ({} as ProtocolFragment)
  ),
  penGroupID: protocol?.penGroup?.id,
});

export const useProtocolForm = ({
  protocol,
  onSubmit,
}: Props): UseModalStepFormInterface<EditProtocolFormType> => {
  const isEditing = !!protocol;

  const [isLoading, setLoading] = useState(false);

  const [createProtocol, { client }] = useCreateProtocolMutation();
  const [updateProtocol] = useUpdateProtocolMutation();

  const protocolEntityFormFields = convertProtocolEntityToForm(protocol);

  const formContext = useForm<EditProtocolFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...protocolEntityFormFields,
      shouldTransferToAnotherPenGroup: !!protocol?.penGroup?.id,
    },
  });

  const handleSubmit = async (input: EditProtocolFormType) => {
    setLoading(true);
    try {
      let editedProtocol = protocol;
      // Don't send fields if they don't change,
      // cause we have additional backend change logic
      const inputToSend = R.pickBy<EditProtocolFormType, EditProtocolFormType>(
        (value, key) => {
          if (key === 'shouldTransferToAnotherPenGroup') return false;
          return protocolEntityFormFields[key] !== value;
        },
        input
      );

      if (isEditing) {
        if (Object.values(inputToSend).length) {
          await updateProtocol({
            variables: { id: protocol.id, input: inputToSend },
            refetchQueries: ['events'],
            awaitRefetchQueries: true,
          });
        }

        editedProtocol = readProtocolFragment(client, protocol.id) ?? undefined;
      } else {
        const res = await createProtocol({
          variables: { input: inputToSend },
          refetchQueries: ['events'],
        });

        editedProtocol = res.data?.createProtocol;
      }
      onSubmit?.(editedProtocol);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
