import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowInjectionFragment = { __typename: 'CowInjection', id: string, injection: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum }, cowProtocol?: { __typename: 'CowProtocol', id: string, happenedAt: string, protocol: { __typename: 'Protocol', id: string, name: string, expectedDaysOnProtocol?: number | null } } | null };

export const CowInjectionFragmentDoc = gql`
    fragment CowInjection on CowInjection {
  __typename
  id
  injection {
    __typename
    id
    name
    kind
  }
  cowProtocol {
    __typename
    id
    happenedAt
    protocol {
      __typename
      id
      name
      expectedDaysOnProtocol
    }
  }
}
    `;