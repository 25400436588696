import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { ListItem } from '~/~legacy/types/keyValue';
import { MaslovServices } from '~/~legacy/types/services';

import {
  SourceFieldKindEnum,
  SourceFieldType,
  SourceSectionKindEnum,
} from '~/shared/graphql';

import { BlueprintEditService } from '../../../../services';
import { NewFieldNodeUI } from '../NewFieldNodeUI';

interface Props {
  sectionType: SourceSectionKindEnum;
  sectionNodeId: string;

  cancel: () => void;
}

export interface NewFieldNodeState {
  kind: SourceFieldKindEnum | undefined;
  name: string;
}

export const NewFieldNode: React.FC<Props> = ({
  cancel,
  sectionType,
  sectionNodeId,
}) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const [fields, setFields] = useState<SourceFieldType[]>([]);

  const [state, setState] = useState<NewFieldNodeState>({
    name: '',
    kind: undefined,
  });

  const {
    errors: creationErorrs,
    loading: creating,
    reload: createField,
  } = useApiData(bpEditSvc.createSourceField.bind(bpEditSvc));

  const { errors, loading, reload } = useApiData(
    bpEditSvc.getFieldsForSection.bind(bpEditSvc)
  );

  useEffect(() => {
    const load = async () => {
      const fieldsResult = await reload(sectionType);
      setFields(fieldsResult.data);
    };

    load();
  }, [setFields, sectionType]);

  const items = useMemo(() => {
    return fields.map(field => {
      const mappedItem: ListItem<string> = {
        value: field.kind,
        content: field.verboseName || field.kind,
        searchKey: field.kind + field.verboseName,
      };
      return mappedItem;
    });
  }, [fields]);

  const changeCllback = useCallback(
    (val: NewFieldNodeState) => {
      setState(val);
    },
    [setState]
  );

  const submitCallback = useCallback(async () => {
    if (state.kind) {
      const result = await createField({
        kind: state.kind,
        name: state.name,
        blueprintSourceSectionID: sectionNodeId,
      });

      if (result.success) {
        cancel();
      }
    }
  }, [state]);

  return (
    <NewFieldNodeUI
      cancel={cancel}
      items={items}
      errors={[...errors, ...creationErorrs]}
      loading={loading || creating}
      value={state}
      onChange={changeCllback}
      submit={submitCallback}
    />
  );
};
