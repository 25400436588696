import * as yup from 'yup';

import { ValidationStrings } from '~/~legacy/strings/validationStrings';

import { UUID_REGEXP } from '~/shared/constants';

/**
 * Yup schema for validating that it is one of ts enum values
 */
export const oneOfEnum = <T extends string>(enumObject: Record<string, T>) =>
  yup.mixed<T>().oneOf(Object.values(enumObject));

/**
 * Setup default localized messages for built in validations
 */
export const setupYupLocale = () => {
  yup.setLocale({
    mixed: {
      required: ValidationStrings.validation.mixed.required,
      notNull: ValidationStrings.validation.mixed.notNull,
      notType: ValidationStrings.validation.mixed.notType,
    },
    string: {
      email: ValidationStrings.validation.string.email,
      min: ({ min }) => ({
        string: ValidationStrings.validation.string.min,
        values: { min },
      }),
      max: ({ max }) => ({
        string: ValidationStrings.validation.string.max,
        values: { max },
      }),
    },
    number: {
      positive: ValidationStrings.validation.number.positive,
      min: ({ min }) => ({
        string: ValidationStrings.validation.number.min,
        values: { min },
      }),
      max: ({ max }) => ({
        string: ValidationStrings.validation.number.max,
        values: { max },
      }),
    },
    array: {
      min: ValidationStrings.validation.array.min,
    },
  });
};

declare module 'yup' {
  interface StringSchema {
    guid(): this;
  }
}

/**
 * Add custom validation methods with localized messages
 */
export const setupCustomYupMethods = () => {
  // Custom method, cause yup uuid validation doesn't support all versions of uuid.
  // Maybe we can remove this if https://github.com/jquense/yup/pull/1667 is released
  yup.addMethod(yup.string, 'guid', function () {
    return this.test('guid', (value, { createError, path }) => {
      return value?.match(UUID_REGEXP)
        ? true
        : createError({ message: 'Введите валидный GUID', path });
    });
  });
};
