import React from 'react';

export const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33333 3.33333C5.12419 3.33333 3.33333 5.12419 3.33333 7.33333C3.33333 9.54247 5.12419 11.3333 7.33333 11.3333C9.54247 11.3333 11.3333 9.54247 11.3333 7.33333C11.3333 5.12419 9.54247 3.33333 7.33333 3.33333ZM2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333C12.6667 8.5658 12.2486 9.70067 11.5466 10.6037L13.8047 12.8619C14.0651 13.1223 14.0651 13.5444 13.8047 13.8047C13.5444 14.0651 13.1223 14.0651 12.8619 13.8047L10.6037 11.5466C9.70067 12.2486 8.5658 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333Z"
      fill="currentColor"
    />
  </svg>
);
