import {
  makeUpdateFragment,
  makeUseFragmentFromCacheOrQuery,
} from '~/services/gql';

import {
  ReproductionCrReportFragment,
  ReproductionCrReportFragmentDoc,
} from './gql/fragments/reproductionCrReport.graphql';
import {
  ReproductionCrReportDetailedFragment,
  ReproductionCrReportDetailedFragmentDoc,
} from './gql/fragments/reproductionCrReportDetailed.graphql';
import {
  ReproductionCrReportsDetailedQuery,
  ReproductionCrReportsDetailedQueryVariables,
  useReproductionCrReportsDetailedQuery,
} from './gql/queries/reproductionCrReportsDetailed.graphql';

/**
 * Update ReproductionCrReport in the cache
 */
export const updateReproductionCrReportFragment =
  makeUpdateFragment<ReproductionCrReportFragment>({
    typeName: 'ReproductionCrReport',
    fragment: ReproductionCrReportFragmentDoc,
  });

/**
 * Hook for getting CR report fragment from cache or by query
 */
export const useReproductionCrReportDetailedFromCacheOrQuery =
  makeUseFragmentFromCacheOrQuery<
    ReproductionCrReportDetailedFragment,
    ReproductionCrReportsDetailedQuery,
    ReproductionCrReportsDetailedQueryVariables
  >({
    typeName: 'ReproductionCrReportDetailed',
    fragment: ReproductionCrReportDetailedFragmentDoc,
    useQuery: useReproductionCrReportsDetailedQuery,
    getItemFromQueryData: data => data.reproductionCrReports.edges.at(0)?.node,
  });
