import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type LivestockForecastPeriodFragment = { __typename: 'LivestockForecastPeriod', updatedAt: string, farmIDs: Array<string>, since: string, till: string, dryStartCount: number, expectedNewbornHeifersCount: number, cowsStatusCount: { __typename?: 'CowsStatusCount', milking: number, allDry: number, total: number }, cowsMilking: { __typename?: 'CowsMilking', perHead: number, perHerd: number }, calvingCount: { __typename?: 'CalvingCount', heifers: number, cows: number, total: number }, expectedRetiredCount: { __typename?: 'ExpectedRetiredCount', firstLact: number, otherLact: number, total: number } };

export const LivestockForecastPeriodFragmentDoc = gql`
    fragment LivestockForecastPeriod on LivestockForecastPeriod {
  __typename
  updatedAt
  farmIDs
  since
  till
  cowsStatusCount {
    milking
    allDry
    total
  }
  cowsMilking {
    perHead
    perHerd
  }
  calvingCount {
    heifers
    cows
    total
  }
  expectedRetiredCount {
    firstLact
    otherLact
    total
  }
  dryStartCount
  expectedNewbornHeifersCount
}
    `;