import { createFileRoute, Link } from '@tanstack/react-router';
import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSingleSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useBlueprintSourceFields } from '~/entities/blueprintSourceFields';
import {
  formatSourceFieldValue,
  useCustomReportDetailedFromCacheOrQuery,
} from '~/entities/customReports';

import { BlueprintLaunchResultTable } from '~/features/customReportLaunch';
import { useCustomReportBlueprintLaunchResultQuery } from '~/features/customReportLaunch/gql/queries/customReportBlueprintLaunchResult.graphql';
import { PageHeader } from '~/features/layouts';

import {
  CustomReportLunchDetailFilterForUrl,
  parseCustomReportLaunchDetailFiltersFromUrlSearch,
} from '~/widgets/customReportPivot';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/custom-reports/$customReportId/$launchHashId/detail'
)({
  component: CustomReportLaunchDetailPage,
  validateSearch: (searchParams: CustomReportLunchDetailFilterForUrl[]) =>
    searchParams,
});

function CustomReportLaunchDetailPage() {
  const { customReportId = '', launchHashId = '' } = Route.useParams();
  const search = Route.useSearch();

  const { fragment: customReportFragment, isLoading: isCustomReportLoading } =
    useCustomReportDetailedFromCacheOrQuery(customReportId);

  const customReportDetailedFilters =
    parseCustomReportLaunchDetailFiltersFromUrlSearch(search);

  const { data, loading: isCustomReportBlueprintLaunchLoading } =
    useCustomReportBlueprintLaunchResultQuery({
      variables: {
        id: customReportId,
        hashID: launchHashId,
        filters: customReportDetailedFilters,
      },
    });

  const hasDetailedFilters = !!customReportDetailedFilters.length;

  const blueprintLaunchResult =
    data?.customReportBlueprintLaunchResult ?? getSingleSkeletonPlaceholder();

  const { getSourceFieldById } = useBlueprintSourceFields(
    blueprintLaunchResult.columnSourceFields
  );

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          isLoading: !hasDetailedFilters && isCustomReportLoading,
          title: hasDetailedFilters
            ? 'Детали'
            : customReportFragment?.blueprint.name,
          backLinkProps: {
            from: Route.fullPath,
            to: '..',
          },
          rightContent: (
            <Link
              {...{
                target: '_blank',
                from: Route.fullPath,
                to: '/$companyId/integrator/blueprints/edit/$blueprintId',
                params: prev => ({
                  ...prev,
                  blueprintId: customReportFragment?.blueprint.id ?? '',
                }),
              }}
            >
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.edit,
                }}
              >
                Перейти к редактированию
              </Button>
            </Link>
          ),
        }}
      />

      <Skeleton isLoading={isCustomReportBlueprintLaunchLoading}>
        <div className={clsx(panelStyles.largePanel, 'p-24')}>
          {hasDetailedFilters && (
            <Typography
              {...{
                tag: 'h3',
                className: 'mb-24',
                variant: TypographyVariants.bodyLargeStrong,
              }}
            >
              {customReportDetailedFilters
                .map(
                  ({ blueprintSourceFieldID, blueprintSourceFieldValue }) =>
                    `${
                      getSourceFieldById(blueprintSourceFieldID)?.name ?? ''
                    } ${formatSourceFieldValue(blueprintSourceFieldValue)}`
                )
                .join(', ')}
            </Typography>
          )}
          <BlueprintLaunchResultTable
            blueprintLaunchResult={blueprintLaunchResult}
          />
        </div>
      </Skeleton>
    </div>
  );
}
