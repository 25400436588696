import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CustomReportRowValueFragment = { __typename: 'CustomReportRowValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null };

export const CustomReportRowValueFragmentDoc = gql`
    fragment CustomReportRowValue on CustomReportRowValue {
  __typename
  intValue
  floatValue
  strValue
  datetimeValue
}
    `;