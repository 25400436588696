import { Dow } from '@graphql-types';
import * as yup from 'yup';

import { ValidationStrings } from '~/~legacy/strings/validationStrings';

/**
 * Days of week display names
 */
export const DAYS_OF_WEEK_DICT: Record<Dow, string> = {
  [Dow.Mon]: 'Понедельник',
  [Dow.Tue]: 'Вторник',
  [Dow.Wed]: 'Среда',
  [Dow.Thu]: 'Четверг',
  [Dow.Fri]: 'Пятница',
  [Dow.Sat]: 'Суббота',
  [Dow.Sun]: 'Воскресенье',
};

/**
 * Days of week, sorted in the correct order
 */
export const DAYS_OF_WEEK = Object.values(Dow);

const DATE_SCHEMA_DEFAULT = {
  interval: {
    since: '',
    till: '',
  },
  daysFromToday: undefined,
};

/**
 * Form schema for a date period.
 */
export const DATE_PERIOD_FORM_SCHEMA = yup
  .object({
    interval: yup.object({
      since: yup.string().default(''), // Date
      till: yup.string().default(''), // Date
    }),
    daysFromToday: yup.number().notRequired(),
  })
  .default(DATE_SCHEMA_DEFAULT);

/**
 * Form schema for a required date period.
 */
export const REQUIRED_DATE_PERIOD_FORM_SCHEMA = yup
  .object({
    interval: yup.object({
      since: yup.string().required(), // Date!
      till: yup.string().required(), // Date!
    }),
    daysFromToday: yup.number().notRequired(),
  })
  .default(DATE_SCHEMA_DEFAULT)
  .test('required', (value, { createError, path }) => {
    return value.interval.since && value.interval.till
      ? true
      : createError({
          message: ValidationStrings.validation.mixed.required,
          path,
        });
  });
