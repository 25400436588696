import {
  CustomReportValueFormulaKindEnum,
  CustomReportValueViewKindEnum,
} from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

/**
 * Available value view kinds dict
 */
export const CUSTOM_REPORT_VALUE_VIEW_KIND_DICT: Record<
  CustomReportValueViewKindEnum,
  string
> = {
  [CustomReportValueViewKindEnum.Default]: 'По умолчанию',
  [CustomReportValueViewKindEnum.PercentByRow]: 'Процент от строки',
  [CustomReportValueViewKindEnum.PercentByColumn]: 'Процент от колонки',
  [CustomReportValueViewKindEnum.PercentByTotal]: 'Процент от общего итога',
};

/**
 * Available value formulas dict
 */
export const CUSTOM_REPORT_VALUE_FORMULA_KIND_DICT: Record<
  CustomReportValueFormulaKindEnum,
  string
> = {
  [CustomReportValueFormulaKindEnum.Sum]: 'Сумма',
  [CustomReportValueFormulaKindEnum.Average]: 'Среднее',
  [CustomReportValueFormulaKindEnum.Count]: 'Количество числ. элементов',
  [CustomReportValueFormulaKindEnum.Counta]: 'Количество всех элементов',
  [CustomReportValueFormulaKindEnum.CountUnique]: 'Количество уник. значений',
  [CustomReportValueFormulaKindEnum.Max]: 'Максимум',
  [CustomReportValueFormulaKindEnum.Min]: 'Минимум',
  [CustomReportValueFormulaKindEnum.Median]: 'Медиана',
};

/**
 * Form schema for pivot table value config
 */
export const PIVOT_TABLE_VALUE_SCHEMA = yup.object({
  formula: oneOfEnum(CustomReportValueFormulaKindEnum)
    .default(CustomReportValueFormulaKindEnum.Count)
    .required(),
  view: oneOfEnum(CustomReportValueViewKindEnum)
    .default(CustomReportValueViewKindEnum.Default)
    .required(),
  blueprintSourceFieldID: yup.string().required(), // ID!
});
