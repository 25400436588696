import React, { ReactNode } from 'react';

import clsx from 'clsx';

import {
  Typography,
  TypographyVariants,
} from '~/shared/components//Typography';
import { TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Tooltip, TooltipProps } from '~/shared/components/Tooltip';

import styles from './index.module.scss';

export interface LabelProps extends React.ComponentPropsWithoutRef<'label'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, label renders an asterisk to indicate required field
   */
  isRequired?: boolean;
  /**
   * If true, red error highlight is displayed
   */
  hasError?: boolean;
  /**
   * Label content
   */
  children: ReactNode;
  /**
   * Helper text for the label
   */
  tooltip?: ReactNode;
  /**
   * Additional props for the tooltip
   */
  tooltipProps?: Partial<TooltipProps>;
}

export const Label: React.FC<LabelProps> = ({
  className,
  isRequired,
  hasError,

  tooltip,
  tooltipProps,

  children,

  ...other
}) => {
  return (
    <Typography
      {...{
        tag: 'label',
        variant: TypographyVariants.descriptionLarge,
        className: clsx(className, styles.root, hasError && styles.error),
        skeletonSize: TextSkeletonSizes.large,
        skeletonProps: {
          className: 'py-2',
        },
        ...other,
      }}
    >
      {children}
      {isRequired && <span className={styles.asterisk}>&nbsp;*</span>}
      {!!tooltip && (
        <Tooltip
          {...{
            className: styles.tooltip,
            content: tooltip,
            ...tooltipProps,
          }}
        />
      )}
    </Typography>
  );
};
