import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type LivestockForecastMonthFragment = { __typename: 'LivestockForecastMonth', farmIDs: Array<string>, forecastAt: string, livestockTotal: number, milkPerHead: number, livestockCows: { __typename?: 'LivestockCows', milkingRate: number, total: number, firstLactation: { __typename?: 'LivestockCowsLactation', dry: number, milking: number, total: number }, otherLactations: { __typename?: 'LivestockCowsLactation', dry: number, milking: number, total: number } }, livestockHeifers: { __typename?: 'LivestockLiveMonthsPeriodsPlusTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, twelvePlusMonth: number, total: number }, livestockBulls: { __typename?: 'LivestockLiveMonthsPeriodsPlusTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, twelvePlusMonth: number, total: number }, calvings: { __typename?: 'LivestockCalvings', heifers: number, firstLactation: number, otherLactations: number, newbornHeifers: number, newbornBulls: number, total: number }, expectedDry: { __typename?: 'LivestockLactationPeriodsTotal', firstLactation: number, otherLactations: number, total: number }, expectedRetired: { __typename?: 'LivestockExpectedRetired', total: number, cows: { __typename?: 'LivestockLactationPeriodsTotal', firstLactation: number, otherLactations: number, total: number }, bulls: { __typename?: 'LivestockLiveMonthsPeriodsTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, total: number }, heifers: { __typename?: 'LivestockLiveMonthsPeriodsTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, total: number } }, milk: { __typename?: 'LivestockLactationPeriodsTotalMilk', firstLactation: number, otherLactations: number, total: number } };

export const LivestockForecastMonthFragmentDoc = gql`
    fragment LivestockForecastMonth on LivestockForecastMonth {
  __typename
  farmIDs
  forecastAt
  livestockTotal
  livestockCows {
    firstLactation {
      dry
      milking
      total
    }
    otherLactations {
      dry
      milking
      total
    }
    milkingRate
    total
  }
  livestockHeifers {
    zeroToTwoMonth
    threeToSixMonth
    sevenToTwelveMonth
    twelvePlusMonth
    total
  }
  livestockBulls {
    zeroToTwoMonth
    threeToSixMonth
    sevenToTwelveMonth
    twelvePlusMonth
    total
  }
  calvings {
    heifers
    firstLactation
    otherLactations
    newbornHeifers
    newbornBulls
    total
  }
  expectedDry {
    firstLactation
    otherLactations
    total
  }
  expectedRetired {
    cows {
      firstLactation
      otherLactations
      total
    }
    bulls {
      zeroToTwoMonth
      threeToSixMonth
      sevenToTwelveMonth
      total
    }
    heifers {
      zeroToTwoMonth
      threeToSixMonth
      sevenToTwelveMonth
      total
    }
    total
  }
  milkPerHead
  milk {
    firstLactation
    otherLactations
    total
  }
}
    `;