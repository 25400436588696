import React, { useEffect } from 'react';

import { createFileRoute, useRouterState } from '@tanstack/react-router';
import R from 'ramda';

import { AsyncList } from '~/shared/components/AsyncList';
import { Button } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { SelectThemes } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { WithSearchParamsValidation } from '~/services/navigation';

import {
  useCustomReportsPaginatedQuery,
  useEditCustomReportModal,
} from '~/entities/customReports';
import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';
import { useRoleSelect } from '~/entities/roles';

import { useCustomReportSettingsModal } from '~/features/customReportLaunch';
import { PageHeader } from '~/features/layouts';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';

import { CustomReportCard } from './components';

const CUSTOM_REPORT_CARDS_DEFAULT_COUNT = 4;

interface CustomReportsSearchParams {
  roleIDs: string[];
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/custom-reports/'
)({
  wrapInSuspense: true,
  component: CustomReportsPage,
  validateSearch: ({
    roleIDs,
  }: WithSearchParamsValidation<CustomReportsSearchParams>) => ({
    roleIDs: Array.isArray(roleIDs) ? roleIDs : [],
  }),
});

function CustomReportsPage() {
  const { roleIDs, setRoleIDs } = useSearchParamsState<typeof Route>();
  const routerState = useRouterState()?.redirect?.state;

  const customReportIdForModal =
    typeof routerState === 'object'
      ? (routerState?.data?.customReportId as string)
      : undefined;

  const { open: openCustomReportSettingsModal } =
    useCustomReportSettingsModal();

  const queryVariables = {
    roleIDs,
  };

  // Open a modal with custom report, if we have a param passed from redirection in the url
  useEffect(() => {
    if (customReportIdForModal) {
      openCustomReportSettingsModal({ customReportId: customReportIdForModal });
    }
  }, [customReportIdForModal]);

  const { open: openEditCustomReportModal } = useEditCustomReportModal();

  const createCustomReportElement = (
    <Button
      {...{
        iconVariant: IconVariants.plus,
        onPress: () =>
          openEditCustomReportModal({
            onSave: newCustomReport => {
              if (newCustomReport) {
                openCustomReportSettingsModal({
                  customReportId: newCustomReport.id,
                });
              }
            },
            queryVariables,
          }),
      }}
    >
      Создать отчёт
    </Button>
  );

  const { items: customReportItems, ...asyncProps } =
    useCustomReportsPaginatedQuery({
      variables: queryVariables,
    });

  const { renderSelectElement: renderRoleSelectElement } = useRoleSelect({
    selectProps: {
      isMulti: true,
      className: 'mb-24 default-control',
      name: 'roles',
      rawValue: roleIDs,
      theme: SelectThemes.light,
      onValueChange: newRoleIds => setRoleIDs(newRoleIds.map(R.prop('id'))),
      isUsedAsFilter: true,
    },
  });

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          title: 'Отчёты',
          rightContent:
            (!!customReportItems.length || asyncProps.isLoading) &&
            createCustomReportElement,
        }}
      />

      {renderRoleSelectElement()}

      <AsyncList<CustomReportFragment>
        {...{
          className: contentGridStyles.autoGridDynamicItems,
          wrapperTag: 'div',
          items: customReportItems,
          skeletonItemsCount: CUSTOM_REPORT_CARDS_DEFAULT_COUNT,
          noItemsMessage: (
            <div className="col-span-full py-96 grid place-items-center gap-16">
              <Typography variant={TypographyVariants.bodySmallStrong} tag="p">
                Отчёты пока не созданы
              </Typography>
              {createCustomReportElement}
            </div>
          ),
          renderItem: customReport => (
            <CustomReportCard
              key={customReport.id}
              {...{
                customReport,
                queryVariables,
              }}
            />
          ),
          ...asyncProps,
        }}
      />
    </div>
  );
}
