import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCustomReportChartSettingsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteCustomReportChartSettingsMutation = { __typename?: 'Mutation', deleteCustomReportChartSettings?: any | null };


export const DeleteCustomReportChartSettingsDocument = gql`
    mutation deleteCustomReportChartSettings($id: ID!) {
  deleteCustomReportChartSettings(id: $id)
}
    `;
export type DeleteCustomReportChartSettingsMutationFn = Apollo.MutationFunction<DeleteCustomReportChartSettingsMutation, DeleteCustomReportChartSettingsMutationVariables>;

/**
 * __useDeleteCustomReportChartSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteCustomReportChartSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomReportChartSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomReportChartSettingsMutation, { data, loading, error }] = useDeleteCustomReportChartSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomReportChartSettingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomReportChartSettingsMutation, DeleteCustomReportChartSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomReportChartSettingsMutation, DeleteCustomReportChartSettingsMutationVariables>(DeleteCustomReportChartSettingsDocument, options);
      }
export type DeleteCustomReportChartSettingsMutationHookResult = ReturnType<typeof useDeleteCustomReportChartSettingsMutation>;
export type DeleteCustomReportChartSettingsMutationResult = Apollo.MutationResult<DeleteCustomReportChartSettingsMutation>;
export type DeleteCustomReportChartSettingsMutationOptions = Apollo.BaseMutationOptions<DeleteCustomReportChartSettingsMutation, DeleteCustomReportChartSettingsMutationVariables>;