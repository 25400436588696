import React from 'react';

import clsx from 'clsx';

import {
  FunctionButton,
  FunctionButtonVariants,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSkeletonPlaceholders,
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
  TextSkeletonSizes,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import panelStyles from '~/styles/modules/panel.module.scss';

import { MilkingParlorFragment } from '../../gql/fragments/milkingParlor.graphql';
import { MilkingParlorsQueryVariables } from '../../gql/queries/milkingParlors.graphql';
import { useGetMilkingParlorInfo } from '../../hooks';
import { useEditMilkingParlorIntervalModal } from '../../modals';
import { MilkingParlorCardMenu } from './components/MilkingParlorCardMenu';
import { MilkingParlorInterval } from './components/MilkingParlorInterval';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking parlor to render
   */
  milkingParlor: MilkingParlorFragment | SkeletonPlaceholder;
  /**
   * Variables, used for parlors query
   */
  queryVariables: MilkingParlorsQueryVariables;
}

const MILKING_INTERVALS_DEFAULT_COUNT = 2;

export const MilkingParlorCard: React.FC<Props> = ({
  className,
  milkingParlor,
  queryVariables,
}) => {
  const { getSkeletonClassNames } = useSkeletonContext();

  const { open: openEditMilkingParlorIntervalModal } =
    useEditMilkingParlorIntervalModal();

  const getParlorInfo = useGetMilkingParlorInfo();

  const milkingIntervals =
    milkingParlor?.intervals ??
    getSkeletonPlaceholders(MILKING_INTERVALS_DEFAULT_COUNT);

  return (
    <div className={clsx('p-16', panelStyles.panel, className)}>
      <div className="flex gap-12 items-start mb-16">
        <div className={clsx('grid', getSkeletonClassNames('gap-8'))}>
          <Typography tag="h4" variant={TypographyVariants.bodyMediumStrong}>
            {milkingParlor?.name}
          </Typography>
          <Typography
            {...{
              variant: TypographyVariants.descriptionLarge,
              className: 'text-soft',
              skeletonSize: TextSkeletonSizes.large,
            }}
          >
            {!isSkeletonPlaceholder(milkingParlor) &&
              getParlorInfo(milkingParlor)}
          </Typography>
        </div>
        {!isSkeletonPlaceholder(milkingParlor) && (
          <MilkingParlorCardMenu
            {...{
              className: 'ml-a flex-none',
              milkingParlor,
              queryVariables,
            }}
          />
        )}
      </div>

      {!!milkingIntervals.length && (
        <div className="grid gap-8 mb-12">
          {milkingIntervals.map(interval => (
            <MilkingParlorInterval
              key={interval.id}
              {...{
                interval,
              }}
            />
          ))}
        </div>
      )}

      <FunctionButton
        {...{
          className: getSkeletonClassNames('mt-16'),
          variant: FunctionButtonVariants.primary,
          iconVariant: IconVariants.plus,
          onPress: () =>
            openEditMilkingParlorIntervalModal({
              milkingParlorID: milkingParlor?.id,
            }),
        }}
      >
        Добавить время доения
      </FunctionButton>
    </div>
  );
};
