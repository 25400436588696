import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCowsCopyKeyMutationVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
}>;


export type DeleteCowsCopyKeyMutation = { __typename?: 'Mutation', deleteCowsCopyKey?: any | null };


export const DeleteCowsCopyKeyDocument = gql`
    mutation deleteCowsCopyKey($key: String!) {
  deleteCowsCopyKey(key: $key)
}
    `;
export type DeleteCowsCopyKeyMutationFn = Apollo.MutationFunction<DeleteCowsCopyKeyMutation, DeleteCowsCopyKeyMutationVariables>;

/**
 * __useDeleteCowsCopyKeyMutation__
 *
 * To run a mutation, you first call `useDeleteCowsCopyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCowsCopyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCowsCopyKeyMutation, { data, loading, error }] = useDeleteCowsCopyKeyMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteCowsCopyKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCowsCopyKeyMutation, DeleteCowsCopyKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCowsCopyKeyMutation, DeleteCowsCopyKeyMutationVariables>(DeleteCowsCopyKeyDocument, options);
      }
export type DeleteCowsCopyKeyMutationHookResult = ReturnType<typeof useDeleteCowsCopyKeyMutation>;
export type DeleteCowsCopyKeyMutationResult = Apollo.MutationResult<DeleteCowsCopyKeyMutation>;
export type DeleteCowsCopyKeyMutationOptions = Apollo.BaseMutationOptions<DeleteCowsCopyKeyMutation, DeleteCowsCopyKeyMutationVariables>;