import { Button, ButtonProps } from '~/shared/components/Button';
import { ContextMenu, ContextMenuProps } from '~/shared/components/ContextMenu';
import {
  FunctionButton,
  FunctionButtonProps,
} from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { MenuItemType } from '~/shared/components/Menu';
import { mergeProps } from '~/shared/helpers/mergeProps';

export interface ContextMenuButtonProps<
  ItemType extends MenuItemType = MenuItemType,
> extends Omit<ContextMenuProps<ItemType>, 'children'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * enabling function button as children element (default - true)
   */
  isFunctionButton?: boolean;
  /**
   * Props for function button, by default renders dots icon
   */
  functionButtonProps?: Omit<Partial<FunctionButtonProps>, 'ref'>;
  /**
   * Props for classic button, by default renders dots icon
   */
  buttonProps?: Omit<Partial<ButtonProps>, 'ref'>;
}

export const ContextMenuButton = <
  ItemType extends MenuItemType = MenuItemType,
>({
  className,
  isDisabled = false,
  isFunctionButton = true,
  functionButtonProps,
  buttonProps,
  ...contextMenuProps
}: ContextMenuButtonProps<ItemType>) => {
  return (
    <ContextMenu {...contextMenuProps}>
      {({ isOpen, setIsOpen, setIsTooltipOpen }) => {
        const commonButtonProps = {
          className,
          iconVariant: IconVariants.dots,
          isDisabled,
          onPress: () => {
            setIsTooltipOpen(false);

            setIsOpen(current => !current);
          },
          onKeyDown: e => {
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              setIsOpen(true);
            }
          },
          iconProps: {
            'data-context-menu-is-open': isOpen,
          },
        } satisfies ButtonProps;

        return isFunctionButton ? (
          <FunctionButton
            {...mergeProps(commonButtonProps, functionButtonProps)}
          />
        ) : (
          <Button
            {...mergeProps(commonButtonProps, buttonProps, {
              isPressed: isOpen,
            })}
          />
        );
      }}
    </ContextMenu>
  );
};
