import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

type StateChangeHandler<T> = (newState: T) => void;

const isFunction = <S extends any>(
  item: SetStateAction<S>
): item is (prevState: S) => S => {
  return typeof item === 'function';
};

export function useControllableState<T>(
  controlledState: T | undefined,
  onValueChange: StateChangeHandler<T> | undefined,
  defaultState: T
): [T, Dispatch<SetStateAction<T>>];

export function useControllableState<T>(
  controlledState?: T,
  onValueChange?: StateChangeHandler<T>,
  defaultState?: T
): [T | undefined, Dispatch<SetStateAction<T>>];

export function useControllableState<T>(
  controlledState: T | undefined,
  onValueChange?: StateChangeHandler<T>,
  defaultState: T | undefined = controlledState
): [T | undefined, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(defaultState);

  const currentIsControlled = controlledState !== undefined;
  const isControlledRef = useRef(currentIsControlled);
  // Allow to switch from uncontrolled to controlled,
  // because we may have a situation, when we want to use undefined as controlled value
  // but not backwards
  if (currentIsControlled) {
    isControlledRef.current = currentIsControlled;
  }
  const isControlled = isControlledRef.current;

  const safeControlledState =
    controlledState === undefined ? defaultState : controlledState;

  const actualState = isControlled ? safeControlledState : state;
  const actualSetState: Dispatch<SetStateAction<T>> = useCallback(
    update => {
      const newState = isFunction(update) ? update(actualState as T) : update;

      onValueChange?.(newState);
      if (!isControlled) {
        setState(newState);
      }
    },
    [actualState, onValueChange]
  );

  return [actualState, actualSetState];
}
