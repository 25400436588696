import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../../entities/farms/gql/fragments/farm.graphql';
export type LivestockForecastSettingFragment = { __typename: 'LivestockForecastSetting', id: string, dryPeriod?: number | null, dryPeriodCalc: number, freshPeriod?: number | null, freshPeriodCalc: number, cowsVoluntaryWaitingPeriod?: number | null, cowsVoluntaryWaitingPeriodCalc: number, heifersVoluntaryWaitingPeriod?: number | null, heifersVoluntaryWaitingPeriodCalc: number, cowsPregnancyRate?: number | null, cowsPregnancyRateCalc: number, heifersPregnancyRate?: number | null, heifersPregnancyRateCalc: number, numberOfCyclesCalc: number, cowsLeftInFirstLactRate?: number | null, cowsLeftInFirstLactRateCalc: number, cowsLeftInOtherLactRate?: number | null, cowsLeftInOtherLactRateCalc: number, bullsLeftZeroToTwoMonthRate?: number | null, bullsLeftZeroToTwoMonthRateCalc: number, bullsLeftThreeToSixMonthRate?: number | null, bullsLeftThreeToSixMonthRateCalc: number, bullsLeftSevenToTwelveMonthRate?: number | null, bullsLeftSevenToTwelveMonthRateCalc: number, heifersLeftZeroToTwoMonthRate?: number | null, heifersLeftZeroToTwoMonthRateCalc: number, heifersLeftThreeToSixMonthRate?: number | null, heifersLeftThreeToSixMonthRateCalc: number, heifersLeftSevenToTwelveMonthRate?: number | null, heifersLeftSevenToTwelveMonthRateCalc: number, heifersInFirstLactRate?: number | null, heifersInFirstLactRateCalc: number, heifersInOtherLactRate?: number | null, heifersInOtherLactRateCalc: number, stillbirthsInFirstLactRate?: number | null, stillbirthsInFirstLactRateCalc: number, stillbirthsInOtherLactRate?: number | null, stillbirthsInOtherLactRateCalc: number, farm: { __typename: 'Farm', id: string, name: string, number: string } };

export const LivestockForecastSettingFragmentDoc = gql`
    fragment LivestockForecastSetting on LivestockForecastSetting {
  __typename
  id
  farm {
    ...Farm
  }
  dryPeriod
  dryPeriodCalc
  freshPeriod
  freshPeriodCalc
  cowsVoluntaryWaitingPeriod
  cowsVoluntaryWaitingPeriodCalc
  heifersVoluntaryWaitingPeriod
  heifersVoluntaryWaitingPeriodCalc
  cowsPregnancyRate
  cowsPregnancyRateCalc
  heifersPregnancyRate
  heifersPregnancyRateCalc
  numberOfCyclesCalc
  cowsLeftInFirstLactRate
  cowsLeftInFirstLactRateCalc
  cowsLeftInOtherLactRate
  cowsLeftInOtherLactRateCalc
  bullsLeftZeroToTwoMonthRate
  bullsLeftZeroToTwoMonthRateCalc
  bullsLeftThreeToSixMonthRate
  bullsLeftThreeToSixMonthRateCalc
  bullsLeftSevenToTwelveMonthRate
  bullsLeftSevenToTwelveMonthRateCalc
  heifersLeftZeroToTwoMonthRate
  heifersLeftZeroToTwoMonthRateCalc
  heifersLeftThreeToSixMonthRate
  heifersLeftThreeToSixMonthRateCalc
  heifersLeftSevenToTwelveMonthRate
  heifersLeftSevenToTwelveMonthRateCalc
  heifersInFirstLactRate
  heifersInFirstLactRateCalc
  heifersInOtherLactRate
  heifersInOtherLactRateCalc
  stillbirthsInFirstLactRate
  stillbirthsInFirstLactRateCalc
  stillbirthsInOtherLactRate
  stillbirthsInOtherLactRateCalc
}
    ${FarmFragmentDoc}`;