import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { SemenDoseFragment } from '../gql/fragments/semenDose.graphql';
import {
  SemenDosesQuery,
  SemenDosesQueryVariables,
  useSemenDosesQuery,
} from '../gql/queries/semenDoses.graphql';

export const useSemenDosesPaginatedQuery = makeUsePaginatedQuery<
  SemenDoseFragment,
  SemenDosesQuery,
  SemenDosesQueryVariables
>({
  useQuery: useSemenDosesQuery,
  getItemsFromQueryData: data => data.semenDoses.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.semenDoses.pageInfo,
});
