import React, { useEffect } from 'react';

import { createFileRoute } from '@tanstack/react-router';

import { useService } from '~/~legacy/hooks/useService';
import { useServiceInitialization } from '~/~legacy/hooks/useServiceInitialization';
import { AppBlueprintExecutionService } from '~/~legacy/services/AppBlueprintExecutionService';
import { HTTPTransport } from '~/~legacy/services/HttpTransport';
import { MaslovServices, StandardServices } from '~/~legacy/types/services';

import { Loader } from '~/shared/components/Loader';

import { WithSearchParamsValidation } from '~/services/navigation';

import { BlueprintsSearchParams } from '~/widgets/blueprintsList';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { BlueprintExecutor } from './components/BlueprintExecutor';

export const Route = createFileRoute(
  '/$companyId/_layout/user/blueprint/$blueprintId'
)({
  wrapInSuspense: true,
  component: BlueprintExecutionPage,
  validateSearch: ({
    search,
    viewKind,
    roleIds,
  }: WithSearchParamsValidation<BlueprintsSearchParams>) => ({
    roleIds: Array.isArray(roleIds) ? roleIds : [],
    viewKind: viewKind ?? undefined,
    search: search ?? '',
  }),
});

function BlueprintExecutionPage() {
  const { blueprintId } = Route.useParams();
  const navigate = Route.useNavigate();

  const http = useService<HTTPTransport>(StandardServices.Http);

  const { initService, initialized } = useServiceInitialization();

  useEffect(() => {
    const bpSvc = new AppBlueprintExecutionService(http);
    initService(MaslovServices.BlueprintExecutionService, bpSvc);
  }, []);

  const content =
    initialized && blueprintId ? (
      <BlueprintExecutor
        blueprintId={blueprintId}
        cancel={() =>
          navigate({
            to: '/$companyId/user/production-calendar',
          })
        }
      />
    ) : (
      <Loader className={layoutStyles.fullHeightContainer} />
    );

  return <>{content}</>;
}
