import React from 'react';

import { useApolloClient } from '@apollo/client';

import { BullState } from '@graphql-types';
import R from 'ramda';
import * as yup from 'yup';

import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { updateBullDetailedFragment } from '~/entities/bulls';
import { BullDetailedFragment } from '~/entities/bulls/gql/fragments/bullDetailed.graphql';
import { readFarmFragment, useFarmSelect } from '~/entities/farms';

import formStyles from '~/styles/modules/form.module.scss';

import { useCreateBullMutation } from '../../gql/mutations/createBull.graphql';
import { useUpdateBullMutation } from '../../gql/mutations/updateBull.graphql';

export interface EditBullModalProps
  extends InjectedModalProps<EditBullModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing bull, if not passed, a new one is created
   */
  bull?: BullDetailedFragment;
}

const FORM_ID = 'EditBullForm';

const SCHEMA = yup.object({
  registrationNumber: yup.string().default('').required(),
  farmID: yup.string().required(), // ID!
  name: yup.string().default(''),
  usdaNumber: yup.string().default(''),
  breed: yup.string().default(''),
  comment: yup.string().default(''),
});

type EditBullFormType = InferValidatedSchema<typeof SCHEMA>;

export const EditBullModal: React.FC<EditBullModalProps> = ({
  className,
  bull,
  close,
}) => {
  const isEditing = !!bull;

  const client = useApolloClient();

  const {
    itemsPromise: farmsItemsPromise,
    renderSelectElement: renderFarmsSelectElement,
  } = useFarmSelect({
    selectProps: {
      label: 'Ферма',
      name: 'farmID',
    },
  });

  const formContext = useForm<EditBullFormType>({
    schema: SCHEMA,
    defaultValues: () =>
      farmsItemsPromise.then(items => ({
        ...SCHEMA.getDefault(),
        ...R.pick(
          ['registrationNumber', 'name', 'usdaNumber', 'breed', 'comment'],
          bull ?? ({} as BullDetailedFragment)
        ),
        farmID: bull?.farm.id ?? items[0]?.id,
      })),
  });

  const [createBull, { loading: isCreateBullLoading }] =
    useCreateBullMutation();

  const [updateBull, { loading: isUpdateBullLoading }] =
    useUpdateBullMutation();

  const handleSubmit = async (form: EditBullFormType) => {
    if (isEditing) {
      await updateBull({
        variables: {
          id: bull.id,
          input: form,
        },
        update: updateBullDetailedFragment(bull.id, draft => {
          draft.registrationNumber = form.registrationNumber;
          draft.name = form.name;
          draft.usdaNumber = form.usdaNumber;
          draft.breed = form.breed;
          draft.comment = form.comment;
          const farmFragment = readFarmFragment(client, form.farmID);
          if (farmFragment) {
            draft.farm = farmFragment;
          }
        }),
      });
    } else {
      await createBull({
        variables: {
          input: {
            ...form,

            // TODO work out, what needs to be done with these fields,
            // now we don't use them, but they're required on backend
            fatherName: '',
            fatherUsdaNumber: '',
            motherName: '',
            motherUsdaNumber: '',
            numberOnPreviousFarm: '',
            retirementReason: null,
            state: BullState.Bull,
          },
        },
        refetchQueries: ['bullsDetailed'],
      });
    }
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing ? 'Редактирование быка' : 'Новый бык',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isCreateBullLoading || isUpdateBullLoading,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        formContext={formContext}
        id={FORM_ID}
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <Typography
          className={formStyles.sectionHeader}
          tag="h2"
          variant={TypographyVariants.bodyMediumStrong}
        >
          Обязательная информация
        </Typography>
        <div className="grid gap-16">
          <Input
            {...{
              name: 'registrationNumber',
              label: 'Регистрационный номер',
            }}
          />
          {renderFarmsSelectElement()}
        </div>
        <Typography
          className={formStyles.sectionHeader}
          tag="h2"
          variant={TypographyVariants.bodyMediumStrong}
        >
          Необязательная информация
        </Typography>
        <div className="grid gap-16">
          <Input
            {...{
              name: 'name',
              label: 'Кличка',
            }}
          />
          <Input
            {...{
              name: 'usdaNumber',
              label: 'Международный номер',
            }}
          />
          <Input
            {...{
              name: 'breed',
              label: 'Порода',
            }}
          />
          <Input
            {...{
              name: 'comment',
              label: 'Комментарий',
            }}
          />
        </div>
      </Form>
    </Modal>
  );
};
