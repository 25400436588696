import { useEffect, useRef } from 'react';
import { FieldValues } from 'react-hook-form';

import { useBlocker } from '@tanstack/react-router';
import R from 'ramda';

import { UseFormInterface } from '~/services/forms';

import {
  InferPossibleUntrackedFields,
  ReportCardBuilderSettingsFormProps,
} from '../types';

interface UseReportCardBuilderFormProps<
  ReportSettingsFormType extends FieldValues,
  UntrackedField extends
    InferPossibleUntrackedFields<ReportSettingsFormType> = '',
> extends Omit<
    ReportCardBuilderSettingsFormProps<ReportSettingsFormType, UntrackedField>,
    'onSettingsFormSave'
  > {
  /**
   * Context of the report form
   */
  formContext: UseFormInterface<ReportSettingsFormType>;
  /**
   * Default form values as initial value for requestedFormValuesRef
   */
  defaultValues: ReportSettingsFormType;
  /**
   * Array of fields, changes in which doesn't trigger the onSettingsFormChange event
   */
  untrackedFields?: UntrackedField[];
}

/**
 * Hook to use in combination with useReportCardBuilder
 * to create a report settings form with reaction to values change
 */
export const useReportCardBuilderForm = <
  ReportSettingsFormType extends FieldValues,
  UntrackedField extends
    InferPossibleUntrackedFields<ReportSettingsFormType> = '',
>({
  formContext,
  defaultValues,
  onSettingsFormChange,
  untrackedFields = [],
}: UseReportCardBuilderFormProps<ReportSettingsFormType, UntrackedField>) => {
  const formValues = formContext.watch();

  const requestedFormValuesRef = useRef(R.omit(untrackedFields, defaultValues));
  // Send requests, while editing form to actualize the chart
  useEffect(() => {
    const newChangedValues = R.omit(untrackedFields, formValues);
    // react-hook-form doesn't send correct references for changed fields, so we need custom state
    // maybe we should replace it with another library
    if (R.equals(newChangedValues, requestedFormValuesRef.current)) return;
    // Also because of form fields references we should save clone to later compare by values
    requestedFormValuesRef.current = R.clone(newChangedValues);
    onSettingsFormChange?.(requestedFormValuesRef.current);
  }, [formValues]);

  useBlocker({
    blockerFn: () =>
      window.confirm('Есть несохраненные изменения. Вы уверены?'),
    condition: formContext.formState.isDirty,
  });

  return {
    formValues,
  };
};
