import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ControlWrapper } from '~/~legacy/value-editors/components/ControlWrapper';
import { EditorsMap } from '~/~legacy/value-editors/editorsMap';
import { ChangeValueCallbackType } from '~/~legacy/value-editors/types/valueEditorTypes';
import { useControls } from '~/~legacy/value-editors/useControls';
import { useEditLogic } from '~/~legacy/value-editors/useEditLogic';

import { SelectThemes } from '~/shared/components/Select';
import {
  ArgumentValueInput,
  NodeValueKindEnum,
  ValueKindEnum,
} from '~/shared/graphql';

import { getValueToEdit } from '../../../../helpers/getValueToEdit';
import {
  BlueprintNodeType,
  ValueEditorKind,
  ValueInput,
} from '../../../../services';
import { NodeValueKindButtons } from '../../../NodeValueKindButtons';

interface Props {
  name: string;
  value: any;
  kind?: ValueEditorKind;
  valueKind?: NodeValueKindEnum | null | undefined;
  edit?: boolean;
  inputValue?: any;
  parentNodeId?: string;
  parentNodeType?: BlueprintNodeType;
  submit: (
    value: ArgumentValueInput,
    nodeValueKind: NodeValueKindEnum
  ) => Promise<boolean>;
  cancel?: () => void;
  onChange?: (
    value: ArgumentValueInput,
    nodeValueKind: NodeValueKindEnum
  ) => void;
}

export const ValueEditor: React.FC<Props> = React.memo(
  ({
    name,
    value,
    inputValue,
    kind,
    valueKind,
    edit: defaultEditing,
    parentNodeId,
    parentNodeType,
    submit,
    cancel,
    onChange,
  }) => {
    const [editing, setEditing] = useState(defaultEditing);
    const [currentValueKind, setCurrentValueKind] = useState(valueKind);
    const valueKindCahnge = useCallback(
      (newKind: NodeValueKindEnum | null) => {
        setCurrentValueKind(newKind);
      },
      [setCurrentValueKind]
    );

    useEffect(() => {
      // should this always be true ?
      setEditing(currentValueKind !== valueKind || defaultEditing);
    }, [setEditing, defaultEditing, valueKind, currentValueKind]);

    const valueToEdit = getValueToEdit(
      value,
      inputValue,
      kind,
      valueKind,
      currentValueKind
    );

    const editors = useMemo(() => {
      return EditorsMap;
    }, []);

    const selectionKind =
      currentValueKind === NodeValueKindEnum.Variable
        ? NodeValueKindEnum.Variable
        : kind || 'Unknown';

    const editor = editors[selectionKind] || editors.Unknown;

    const submitCallback: ChangeValueCallbackType<ValueInput> = useCallback(
      async (val: ValueInput) => {
        if (currentValueKind) {
          return submit(val, currentValueKind);
        }
        return false;
      },
      [submit, currentValueKind]
    );

    const changeCallback = useCallback(
      (val: any) => {
        if (onChange) {
          onChange(val, currentValueKind || NodeValueKindEnum.Hardcode);
        }
        return Promise.resolve(true);
      },
      [currentValueKind, onChange]
    );

    const hideControls = selectionKind === ValueKindEnum.Void;

    const nodeValueButtons = hideControls ? null : (
      <NodeValueKindButtons
        className="mb-8"
        value={valueKind}
        onChange={valueKindCahnge}
      />
    );

    const [isEditingInner, setEditingInner] = useState(editing);

    const toggleCallback = useCallback(() => {
      setEditingInner(val => !val);
    }, []);

    const { val, setVal, formSubmitCallback } = useEditLogic(
      valueToEdit,
      changeCallback,
      submitCallback,
      toggleCallback
    );

    const controls = useControls(hideControls, toggleCallback);

    const editorCmpnt = editor({
      name,
      submit: submitCallback,
      value: val,
      valueKind,
      edit: isEditingInner,
      parentNodeId,
      parentNodeType,
      returnValueKind: kind,
      cancel: () => {
        toggleCallback();
        cancel?.();
      },
      onChange: newVal => {
        setVal(newVal);
        return Promise.resolve(changeCallback(newVal));
      },
      additionalArgs: {
        selectTheme: SelectThemes.dark,
      },
    });

    return (
      <Suspense>
        {nodeValueButtons}

        {currentValueKind === NodeValueKindEnum.Variable && editorCmpnt}
        {currentValueKind !== NodeValueKindEnum.Variable && (
          <ControlWrapper isForm onSubmit={formSubmitCallback}>
            {editorCmpnt}
            {isEditingInner && controls}
          </ControlWrapper>
        )}
      </Suspense>
    );
  }
);
