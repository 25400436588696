import { NBSP } from '~/shared/constants';

/**
 * Format name like Iakovlev Nikolai Dmitrievich -> Iakovlev N. D.
 */
export const formatFullNameWithInitials = (
  user:
    | {
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      }
    | undefined
    | null,
  defaultName = '',
  delimiter: string = NBSP
): string => {
  if (!user) return defaultName;

  const { firstName, middleName, lastName } = user;

  // Return just the name, if we don't have a last name
  if (!lastName && !!firstName) {
    return firstName;
  }

  const initials = [firstName, middleName]
    .filter(Boolean)
    .reduce<string[]>((acc, item) => {
      const initialsWithDot = (item ?? '')[0]
        .split(' ')
        .filter(Boolean)
        .map(letter => `${letter}.`);

      return [...acc, ...initialsWithDot];
    }, [])
    .join('');

  const res = [lastName, initials].filter(Boolean).join(delimiter);

  if (!res) return defaultName;

  return res;
};

/**
 * format name to Firstname Lastname Middlename
 */
export const formatFullName = (
  user:
    | {
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
      }
    | null
    | undefined,
  defaultName = '',
  delimiter: string = NBSP
): string => {
  if (!user) return defaultName;

  const { firstName, middleName, lastName } = user;

  const res = [lastName, firstName, middleName].filter(Boolean).join(delimiter);

  if (!res) return defaultName;

  return res;
};

/**
 * format name to Firstname Lastname
 */
export const formatShortName = (
  user:
    | {
        firstName?: string | null;
        lastName?: string | null;
      }
    | null
    | undefined,
  defaultName = '',
  delimiter: string = NBSP
): string => {
  return formatFullName(
    { ...user, middleName: undefined },
    defaultName,
    delimiter
  );
};
