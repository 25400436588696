import { createContext, useContext } from 'react';

import { ModalNames } from './__generated__/modalNames';
import {
  ModalsContextType,
  ModalsState,
  SingleModalContextType,
} from './types';

/**
 * General context for all modals
 */
export const ModalsContext = createContext<ModalsContextType>({
  state: {} as ModalsState,
  openModal: () => {},
  closeModal: () => {},
  setModalStep: () => {},
});

/**
 * Hook for using general modals context
 */
export const useModalsContext = () => useContext(ModalsContext);

/**
 * Context for a single modal
 */
export const SingleModalContext = createContext<SingleModalContextType>({
  modalName: '' as ModalNames,
  transitionStyles: {},
});

/**
 * Hook for using single modal context
 */
export const useSingleModalContext = () => useContext(SingleModalContext);
