import { HALF_SPACE, MDASH, SPACED_INTERPUNCT } from '~/shared/constants';

import { DateFormats, formatDate } from '~/services/dateTime';
import { getNumberPartFromGlobalId } from '~/services/gql';

import { formatCow } from '~/entities/cows';

import { CalvingFragment } from './gql/fragments/calving.graphql';
import { CalvingFormatOptions } from './types';

/**
 * Returns calving formatted for rendering
 */
export const formatCalving = (
  calving?: CalvingFragment | null,
  { withId = true, withRestInfo = true }: CalvingFormatOptions = {}
) => {
  if (!calving) return MDASH;

  const id = getNumberPartFromGlobalId(calving.id);
  const cowIdentifier = formatCow(calving.cow, { withName: false });
  const formattedDate = formatDate(
    calving.happenedAt,
    DateFormats.shortWithTime
  );

  return [
    withId && `№${HALF_SPACE}${id}`,
    withRestInfo && cowIdentifier,
    withRestInfo && formattedDate,
  ]
    .filter(Boolean)
    .join(SPACED_INTERPUNCT);
};
