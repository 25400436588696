import * as Types from '../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportEventsCsvMutationVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  since?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  till?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  kinds?: Types.InputMaybe<Array<Types.EventKindEnum> | Types.EventKindEnum>;
  cowIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  eventIDs?: Types.InputMaybe<Array<Types.EventIdInput> | Types.EventIdInput>;
}>;


export type ExportEventsCsvMutation = { __typename?: 'Mutation', exportEventsCsv: { __typename?: 'File', downloadUrl: string, name: string, extension: string } };


export const ExportEventsCsvDocument = gql`
    mutation exportEventsCsv($search: String, $since: Date, $till: Date, $kinds: [EventKindEnum!], $cowIDs: [ID!], $eventIDs: [EventIDInput!]) {
  exportEventsCsv(
    search: $search
    since: $since
    till: $till
    kinds: $kinds
    cowIDs: $cowIDs
    eventIDs: $eventIDs
  ) {
    downloadUrl
    name
    extension
  }
}
    `;
export type ExportEventsCsvMutationFn = Apollo.MutationFunction<ExportEventsCsvMutation, ExportEventsCsvMutationVariables>;

/**
 * __useExportEventsCsvMutation__
 *
 * To run a mutation, you first call `useExportEventsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEventsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEventsCsvMutation, { data, loading, error }] = useExportEventsCsvMutation({
 *   variables: {
 *      search: // value for 'search'
 *      since: // value for 'since'
 *      till: // value for 'till'
 *      kinds: // value for 'kinds'
 *      cowIDs: // value for 'cowIDs'
 *      eventIDs: // value for 'eventIDs'
 *   },
 * });
 */
export function useExportEventsCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportEventsCsvMutation, ExportEventsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportEventsCsvMutation, ExportEventsCsvMutationVariables>(ExportEventsCsvDocument, options);
      }
export type ExportEventsCsvMutationHookResult = ReturnType<typeof useExportEventsCsvMutation>;
export type ExportEventsCsvMutationResult = Apollo.MutationResult<ExportEventsCsvMutation>;
export type ExportEventsCsvMutationOptions = Apollo.BaseMutationOptions<ExportEventsCsvMutation, ExportEventsCsvMutationVariables>;