import React from 'react';

import { createFileRoute } from '@tanstack/react-router';

import {
  CowCardEventsTable,
  useMilkDataTabContext,
} from '~/widgets/cowCardEvents';

export const Route = createFileRoute(
  '/$companyId/_layout/user/cows/$cowId/info/events/'
)({
  wrapInSuspense: true,
  component: EventsCowTable,
});

function EventsCowTable() {
  const { isCowLactationGraphsLoading, cowEventsTableEntries } =
    useMilkDataTabContext();

  return (
    <CowCardEventsTable
      {...{
        isLoading: isCowLactationGraphsLoading,
        cowEventsTableEntries,
      }}
    />
  );
}
