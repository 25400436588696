import { ParlorExportStatusFileTagFieldEnum } from '@graphql-types';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { TAG_FIELDS_DICT } from '~/widgets/milkingParlors/constants';

/**
 * Select for parlor tagField
 */
export const useTagFieldsSelect = makeUseEnumSelect(
  ParlorExportStatusFileTagFieldEnum,
  enumValue => TAG_FIELDS_DICT[enumValue as ParlorExportStatusFileTagFieldEnum]
);
