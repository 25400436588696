import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { MonitorGroupFragmentDoc } from '../../gql/fragments/monitorGroup.graphql';
import { useMonitorGroupsQuery } from '../../gql/queries/monitorGroups.graphql';

/**
 * Select for monitor group
 */
const useMonitorGroupSelect = makeUseAsyncSelect({
  typeName: 'MonitorGroup',
  fragment: MonitorGroupFragmentDoc,
  useQuery: useMonitorGroupsQuery,
  getItemsFromQueryData: data => data.monitorGroups.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.monitorGroups.pageInfo,
  selectProps: {
    placeholder: 'Выберите группу',
  },
});

export const MonitorGroupAsyncSelect = makeAsyncSelectFormComponent(
  useMonitorGroupSelect
);
