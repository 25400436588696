import React from 'react';

import { FreshActionCalvingData } from '../../reducer';
import { Gender } from '../../types';

interface Props {
  calvings: FreshActionCalvingData[];
}

export const PanelData: React.FC<Props> = ({ calvings }) => {
  const itemsToCount = calvings.filter(item => item.cow);

  const cows = itemsToCount.length;
  const calfs = itemsToCount.reduce(
    (acc, item) =>
      acc + item.calfs.filter(calf => calf.state || calf.gender).length,
    0
  );
  const female = itemsToCount.reduce(
    (acc, item) =>
      acc + item.calfs.filter(calf => calf.gender === Gender.female).length,
    0
  );
  const male = itemsToCount.reduce(
    (acc, item) =>
      acc + item.calfs.filter(calf => calf.gender === Gender.male).length,
    0
  );

  return (
    <p>
      Всего записано: коров - {cows}, телят - {calfs}, тёлочек - {female},
      бычков - {male}
    </p>
  );
};
