import React from 'react';

import * as yup from 'yup';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { downloadFile } from '~/shared/helpers/downloadFile';

import { CSV_FILE_ACCEPT, FilePicker } from '~/services/files';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal, ModalSizes } from '~/services/modals';

import { FarmIdSelect } from '~/entities/farms';

import { useLoadCowsCsvMutation } from '../../gql/mutations/loadCowsCsv.graphql';

export interface UploadCowsCsvModalProps
  extends InjectedModalProps<UploadCowsCsvModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
}

const FORM_ID = 'uploadCowsCsvForm';

const SCHEMA = yup.object({
  file: yup.mixed().required(), // Upload!
  farmID: yup.string().required(), // ID!
});

type UploadCowsCsvFormType = InferValidatedSchema<typeof SCHEMA>;

export const UploadCowsCsvModal: React.FC<UploadCowsCsvModalProps> = ({
  className,
  close,
}) => {
  const [loadCowsCsv, { loading: isLoading }] = useLoadCowsCsvMutation();

  const formContext = useForm<UploadCowsCsvFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = (fileInput: UploadCowsCsvFormType) => {
    loadCowsCsv({
      variables: {
        fileInput,
      },
    }).then(() => {
      close();
    });
  };

  return (
    <Modal
      {...{
        className,
        size: ModalSizes.medium950,
        title: 'Импорт данных из csv файла',
        submitButtonProps: {
          children: 'Загрузить данные',
          form: FORM_ID,
          isLoading,
        },
      }}
    >
      <Form
        formContext={formContext}
        id={FORM_ID}
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <div className="mb-16">
          <Typography variant={TypographyVariants.heading4}>
            1. Подготовьте файл для загрузки
          </Typography>
          <Typography
            variant={TypographyVariants.bodySmall}
            tag="p"
            className="my-8"
          >
            Скачайте шаблон таблицы, заполните и сохраните в формате .csv
          </Typography>
          <Button
            variant={ButtonVariants.secondary}
            onPress={() => {
              downloadFile(
                '/samples/cows.csv',
                'Шаблон csv файла с коровами',
                'csv'
              );
            }}
          >
            Скачать шаблон файла
          </Button>
        </div>
        <div className="mb-16">
          <Typography variant={TypographyVariants.heading4}>
            2. Выберите ферму
          </Typography>
          <FarmIdSelect
            key="farmID"
            {...{
              className: 'mt-8 m-maw',
              name: 'farmID',
            }}
          />
        </div>
        <div className="mb-16">
          <Typography variant={TypographyVariants.heading4} className="my-8">
            3. Загрузите файл
          </Typography>
          <FilePicker name="file" accept={CSV_FILE_ACCEPT} />
        </div>
      </Form>
    </Modal>
  );
};
