import { ApolloCache, DocumentNode } from '@apollo/client';

import { StrictTypedTypePolicies } from '../../__generated__/typedTypePolicies';
import { getFragmentDocumentName } from './getFragmentDocumentName';

/**
 * Props for fabric to make helpers for identifying fragments in the cache
 */
export interface MakeGetFragmentCacheOptionsProps {
  /**
   * Typename of the selectable fragment in the cache.
   * You can pass a function to determine a typename by id.
   * Typename should be the actual gql schema type of the fragment to get.
   * Apollo uses it to identify a fragment by the __typename field.
   */
  typeName: keyof StrictTypedTypePolicies | ((id: string) => string);
  /**
   * Gql document of the selectable fragment.
   * Apollo executes an actual cache query by creating a query operation with a fragment spread.
   * So this prop defines for which data will be received by the caller.
   */
  fragment: DocumentNode;
  /**
   * Some fragments doesn't have an id, so we need to pass a function to determine identify fields
   */
  getIdentifyFields?: (id: string) => Record<string, any>;
}

/**
 * Factory for making an options object used by apollo cache functions to identify fragments
 */
export const makeGetFragmentCacheOptions =
  ({
    typeName: typeNameProp,
    fragment,
    getIdentifyFields = id => ({ id }),
  }: MakeGetFragmentCacheOptionsProps) =>
  (cache: ApolloCache<unknown>, id: string) => {
    const identifyFields = getIdentifyFields(id);

    const typeName =
      typeof typeNameProp === 'function' ? typeNameProp(id) : typeNameProp;

    const normalizedId =
      cache.identify({ __typename: typeName, ...identifyFields }) ??
      `${typeName}:${id}`;

    const fragmentName = getFragmentDocumentName(fragment);

    return {
      fragment,
      fragmentName,
      id: normalizedId,
    };
  };
