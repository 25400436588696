import { makeUseModal, ModalNames } from '~/services/modals';

import { EditReproductionSettingsModalProps } from './EditReproductionSettingsModal';
import { EditReproductionTargetsModalProps } from './EditReproductionTargetsModal';

export const useEditReproductionSettingsModal =
  makeUseModal<EditReproductionSettingsModalProps>(
    ModalNames.editReproductionSettingsModal
  );

export const useEditReproductionTargetsModal =
  makeUseModal<EditReproductionTargetsModalProps>(
    ModalNames.editReproductionTargetsModal
  );
