import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { UserEventFragment } from '../gql/fragments/userEvent.graphql';
import { useCreateUserEventMutation } from '../gql/mutations/createUserEvent.graphql';
import { useUpdateUserEventMutation } from '../gql/mutations/updateUserEvent.graphql';

/**
 * Props for editing an event with UserEvent kind
 */
interface Props {
  /**
   * If passed, this is the event to edit, creating new otherwise
   */
  userEvent?: UserEventFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (newEvent?: UserEventFragment) => void;
}

const FORM_ID = 'EditUserEventForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
});

/**
 * Form for editing an event with UserEvent kind
 */
export type EditUserEventFormType = InferValidatedSchema<typeof SCHEMA>;

export const useUserEventForm = ({
  userEvent,
  onSubmit,
}: Props): UseModalStepFormInterface<EditUserEventFormType> => {
  const isEditing = !!userEvent;

  const [isLoading, setLoading] = useState(false);

  const [createUserEvent] = useCreateUserEventMutation();
  const [updateUserEvent] = useUpdateUserEventMutation();

  const formContext = useForm<EditUserEventFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(['name'], userEvent ?? ({} as UserEventFragment)),
    },
  });

  const handleSubmit = async (input: EditUserEventFormType) => {
    setLoading(true);
    try {
      let newEvent: UserEventFragment | undefined;
      if (isEditing) {
        await updateUserEvent({
          variables: { id: userEvent.id, input },
          refetchQueries: ['events'],
        });
      } else {
        const res = await createUserEvent({
          variables: { input },
          refetchQueries: ['events'],
        });

        newEvent = res.data?.createUserEvent;
      }
      onSubmit?.(newEvent);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
