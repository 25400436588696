import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { CustomReportFragment } from '../gql/fragments/customReport.graphql';
import {
  CustomReportsQuery,
  CustomReportsQueryVariables,
  useCustomReportsQuery,
} from '../gql/queries/customReports.graphql';

export const useCustomReportsPaginatedQuery = makeUsePaginatedQuery<
  CustomReportFragment,
  CustomReportsQuery,
  CustomReportsQueryVariables
>({
  useQuery: useCustomReportsQuery,
  getItemsFromQueryData: data => data.customReports.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.customReports.pageInfo,
});
