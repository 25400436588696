/**
 * Helper to get normalized value by list of allowed values
 */
export const normalizeValueByAllowedList = <T extends string>({
  value,
  allowedValues,
  defaultValue,
}: {
  value: T | undefined;
  allowedValues: T[];
  defaultValue: NoInfer<T>;
}) => (value && allowedValues.includes(value) ? value : defaultValue);
