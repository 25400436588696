import * as yup from 'yup';

/**
 * Farm form schema
 */
export const FARM_SCHEMA = yup.object({
  id: yup.string().default('').required(), // ID! -- used for tempId also
  number: yup.string().default(''),
  name: yup.string().default('').required(),
});
