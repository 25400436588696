import { SourceFieldKindEnum } from '@graphql-types';
import R from 'ramda';

import { updateCowDetailedFragment } from '~/entities/cows';
import { CowDetailedFragment } from '~/entities/cows/gql/fragments/cowDetailed.graphql';
import { CowFieldFragment } from '~/entities/cows/gql/fragments/cowField.graphql';

import { useSetPinnedCowFieldsMutation } from '../gql/mutations/setPinnedCowFields.graphql';
import { getCowFieldId, isCowStaticField } from '../helpers';

/**
 * Hook for using cow pinned fields logic
 */
export const useCowPinnedFields = (cow: CowDetailedFragment) => {
  const pinnedFields = cow.fields.filter(R.prop('isPinned'));

  const [setPinnedCowFields] = useSetPinnedCowFieldsMutation();

  const setCowFieldIsPinned = (
    fieldToPin: CowFieldFragment,
    isPinned: boolean
  ) => {
    const fieldToPinIndex = pinnedFields.findIndex(
      field => getCowFieldId(field) === getCowFieldId(fieldToPin)
    );
    const fieldToPinId = getCowFieldId(fieldToPin);

    let fieldsToPin = pinnedFields;
    if (isPinned && fieldToPinIndex < 0) {
      fieldsToPin = [...pinnedFields, fieldToPin];
    } else if (!isPinned && fieldToPinIndex >= 0) {
      fieldsToPin = fieldsToPin.toSpliced(fieldToPinIndex, 1);
    }

    setPinnedCowFields({
      variables: fieldsToPin.reduce(
        (acc, field) => {
          if (isCowStaticField(field)) {
            acc.kinds.push(field.kind);
          } else {
            acc.fieldIds.push(field.blueprintSourceField.id);
          }
          return acc;
        },
        {
          fieldIds: [] as string[],
          kinds: [] as SourceFieldKindEnum[],
        }
      ),
      optimisticResponse: { setPinnedCowFields: null },
      update: updateCowDetailedFragment(cow.id, draft => {
        const fieldToPinDraft = draft.fields.find(
          draftField => fieldToPinId === getCowFieldId(draftField)
        );

        if (fieldToPinDraft) {
          fieldToPinDraft.isPinned = isPinned;
        }
      }),
    });
  };

  return {
    pinnedFields,
    setCowFieldIsPinned,
  };
};
