import React, { useMemo } from 'react';

import { CustomReportChartKindEnum } from '@graphql-types';
import { ChartOptions, LinearScale } from 'chart.js';
import R from 'ramda';
import { match } from 'ts-pattern';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { NO_VALUE_MESSAGE } from '~/shared/constants';
import { formatNumber, formatWithPercent } from '~/shared/helpers/number';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { formatSourceFieldValue } from '~/entities/customReports';

import {
  AnyBasicChartDataPoints,
  AnyBasicChartType,
  AnyChartDatasetConfig,
  BarChart,
  BarChartDatasetConfig,
  CHART_COLOR_PROPS_ITERATOR,
  CHART_DATASET_COLORS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DataPointsTooltip,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
  getScaleOptions,
  LINEAR_Y_SCALE_OPTIONS,
  LineChart,
  LineChartDatasetConfig,
  PERCENT_LINEAR_Y_SCALE_OPTIONS,
  PieChart,
  PieChartDatasetConfig,
  ReactChartProps,
  ScatterChart,
  ScatterChartDatasetConfig,
} from '~/features/charts';
import { CustomReportChartFragment } from '~/features/customReportLaunch/gql/fragments/customReportChart.graphql';
import { CustomReportChartMultipleValuesFragment } from '~/features/customReportLaunch/gql/fragments/customReportChartMultipleValues.graphql';
import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';

import { isCustomReportChart } from '~/widgets/customReportCharts/helpers';

import { useCustomReportChartAxes } from '../../../../hooks';
import { CustomReportChartSettingsFormType } from '../../../../types';

interface Props {
  /**
   * Launch result to get blueprint source field values from nested columns that can be added to axes
   */
  customReportLaunchResult: CustomReportLaunchResultFragment;
  /**
   * Chart data to render
   */
  chartData:
    | CustomReportChartFragment
    | CustomReportChartMultipleValuesFragment
    | SkeletonPlaceholder;
  /**
   * Settings, used to configure the chart
   */
  chartSettings: Omit<CustomReportChartSettingsFormType, 'name'>;
}

const CHART_HEIGHT_PX = 376;

export const CustomReportValidChart: React.FC<Props> = ({
  customReportLaunchResult,
  chartData,
  chartSettings,
}) => {
  const isPercents =
    chartSettings.kind === CustomReportChartKindEnum.PercentBar;
  const isStackedBar =
    chartSettings.kind === CustomReportChartKindEnum.StackedBar;
  const isPie = chartSettings.kind === CustomReportChartKindEnum.Pie;
  const isScatter = chartSettings.kind === CustomReportChartKindEnum.Scatter;

  const isStackedChart = isStackedBar || isPercents;

  const { launchHeaderColumnName, getXAxisName, getYAxisName } =
    useCustomReportChartAxes({
      customReportLaunchResult,
    });
  const xAxisName = getXAxisName(chartSettings.xAxis);

  const xAxisLabels = useMemo(
    () =>
      chartData.xAxisLabels?.map(label => {
        const formattedValue = formatSourceFieldValue(label);
        return formattedValue === '' ? NO_VALUE_MESSAGE : formattedValue;
      }) ?? [],
    [chartData]
  );

  const datasets = useMemo(() => {
    // This is an array of datasets with arrays of sorted by x coordinate arrays of values for given x,
    const yAxisDatasets = isCustomReportChart(chartData)
      ? chartData.yAxisDatasets.map(dataset =>
          dataset.map(dataPoint => [dataPoint.value ?? NaN])
        )
      : (chartData.yAxisMultiValueDatasets?.map(dataset =>
          dataset.map(dataPoints =>
            dataPoints.map(dataPoint => dataPoint.value ?? NaN)
          )
        ) ?? []);

    const yAxisDatasetsSummedYValues = yAxisDatasets.map(dataset =>
      dataset.map(dataPoints =>
        R.sum(
          dataPoints.map(pointValue =>
            !pointValue || Number.isNaN(pointValue) ? 0 : pointValue
          )
        )
      )
    );
    const dataSumsByDataIndex = isPercents
      ? yAxisDatasetsSummedYValues.at(0)?.map((dataPoint, dataIndex) => {
          return R.sum(
            yAxisDatasetsSummedYValues.map(dataPoints => dataPoints[dataIndex])
          );
        })
      : undefined;

    return yAxisDatasets.map((dataset, datasetIndex) => {
      const axisSettings = chartSettings.yAxes.at(datasetIndex);

      const fullData = dataset.flatMap((dataPoints, xAxisLabelIndex) =>
        dataPoints.map(dataPoint => ({
          x: xAxisLabels[xAxisLabelIndex],
          y: dataPoint,
        }))
      );

      const dataSum = R.sum(
        fullData.map(dataPoint => {
          const yValue = dataPoint.y;
          return !yValue || Number.isNaN(yValue) ? 0 : yValue;
        })
      );

      return {
        key: datasetIndex.toString(),
        label: getYAxisName(chartSettings.yAxes.at(datasetIndex), false),
        ...CHART_COLOR_PROPS_ITERATOR.next(!datasetIndex).value,
        yAxisID: axisSettings?.withRightScale ? 'rightY' : 'leftY',
        isTogglable: !isPie,
        originalData: fullData,
        data: !isPercents
          ? fullData
          : fullData.map((dataPoint, dataIndex) => {
              if (Number.isNaN(dataPoint.y)) {
                return dataPoint;
              }
              return {
                ...dataPoint,
                y:
                  (dataPoint.y / (dataSumsByDataIndex?.at(dataIndex) ?? 1)) *
                  100,
              };
            }),
        dataSum,
        tooltipConfig: {
          renderLabel: point =>
            isScatter
              ? formatSourceFieldValue(chartData.xAxisLabels?.[point.dataIndex])
              : (point.dataset.label ?? ''),
          getMainValue: (point, currentDatasetConfig) => {
            let textToRender = point.formattedValue;
            if (
              typeof point.raw === 'number' ||
              typeof (point.raw as any)?.y === 'number'
            ) {
              const originalPoint =
                currentDatasetConfig.originalData[point.dataIndex];

              textToRender = formatNumber(originalPoint.y);

              if (isPie || isPercents) {
                const currentDataSum = isPercents
                  ? currentDatasetConfig.dataSumsByDataIndex?.at(
                      point.dataIndex
                    )
                  : currentDatasetConfig.dataSum;

                const percentValue =
                  (originalPoint.y / (currentDataSum ?? 1)) * 100;
                textToRender = `${textToRender} (${formatWithPercent(
                  percentValue
                )})`;
              }
            }

            return textToRender;
          },
        },
        dataSumsByDataIndex,
      } satisfies AnyChartDatasetConfig;
    });
  }, [chartData, chartSettings, getYAxisName]);

  const shouldDisplayLeftYAxis = chartSettings.yAxes.some(
    R.complement(R.prop('withRightScale'))
  );
  const shouldDisplayRightYAxis = chartSettings.yAxes.some(
    R.prop('withRightScale')
  );

  const yScaleOptions = isPercents
    ? PERCENT_LINEAR_Y_SCALE_OPTIONS
    : LINEAR_Y_SCALE_OPTIONS;

  const commonChartOptions = {
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: getXAxisName(chartSettings.xAxis),
        },
      },
      y: {
        // Hide default y scale, cause we have custom left and right scales
        display: false,
      },
      ...wrapConditionalObjectElement(
        shouldDisplayLeftYAxis && {
          leftY: getScaleOptions(yScaleOptions, {
            display: 'auto',
            stacked: isStackedChart,
          }),
        }
      ),
      ...wrapConditionalObjectElement(
        shouldDisplayRightYAxis && {
          rightY: getScaleOptions(yScaleOptions, {
            grid: {
              // We should display grid here, only if we don't display the left axis
              display: ((context: { scale: LinearScale }) => {
                // eslint-disable-next-line no-underscore-dangle -- access private chart.js scale method
                return !(context.scale.chart.scales.leftY as any)?._isVisible();
              }) as any, // Chart.js doesn't have typings for passing callback here, but it works
            },
            display: 'auto',
            position: 'right',
            stacked: isStackedChart,
          }),
        }
      ),
    },
    plugins: {
      zoom: {
        limits: {
          x: {
            min: 'original',
            max: 'original',
            minRange: CHART_X_SCALE_MIN_RANGE,
          },
          leftY: {
            min: 'original',
            max: 'original',
            minRange: CHART_Y_SCALE_MIN_RANGE,
          },
          rightY: {
            min: 'original',
            max: 'original',
            minRange: CHART_Y_SCALE_MIN_RANGE,
          },
        },
        zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
        pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
      },
    },
  } satisfies ChartOptions;

  const renderTooltip: ReactChartProps['renderTooltip'] = dataPoints => {
    const firstPointLabel = dataPoints.at(0)?.label ?? '';
    return (
      <DataPointsTooltip<AnyBasicChartType, AnyBasicChartDataPoints>
        {...{
          title: isScatter ? xAxisName : `${xAxisName} ${firstPointLabel}`,
          dataPoints,
          datasets,
          isPie,
        }}
      />
    );
  };

  const commonChartProps = {
    legendTitle: isPie ? xAxisName : launchHeaderColumnName,
    labels: xAxisLabels,
    legendClassName: 'mb-32',
    height: CHART_HEIGHT_PX,
    renderTooltip,
  };

  return match(chartSettings.kind)
    .with(
      CustomReportChartKindEnum.Bar,
      CustomReportChartKindEnum.StackedBar,
      CustomReportChartKindEnum.PercentBar,
      () => {
        return (
          <BarChart
            {...{
              ...commonChartProps,
              datasets: datasets as BarChartDatasetConfig[],
              chartOptions: {
                interaction: {
                  mode: 'index',
                },
                ...commonChartOptions,
              },
              isStacked: isStackedChart,
              isPercents,
            }}
          />
        );
      }
    )
    .with(CustomReportChartKindEnum.Scatter, () => {
      return (
        <ScatterChart
          {...{
            ...commonChartProps,
            datasets: datasets as ScatterChartDatasetConfig[],
            chartOptions: commonChartOptions,
          }}
        />
      );
    })
    .with(CustomReportChartKindEnum.Line, () => {
      return (
        <LineChart
          {...{
            ...commonChartProps,
            datasets: datasets as LineChartDatasetConfig[],
            chartOptions: {
              interaction: {
                mode: 'index',
              },
              ...commonChartOptions,
            },
          }}
        />
      );
    })
    .with(CustomReportChartKindEnum.Pie, () => {
      return (
        <PieChart
          {...{
            ...commonChartProps,
            datasets: datasets as PieChartDatasetConfig[],
            legendConfigs: xAxisLabels.map((label, labelIndex) => ({
              key: `${label}__${labelIndex}`,
              label,
              color: CHART_DATASET_COLORS_ITERATOR.next(!labelIndex).value,
            })),
            chartOptions: {
              plugins: {
                datalabels: {
                  formatter: (value, context) => {
                    const { dataIndex, datasetIndex } = context;
                    const currentChartConfig = datasets[datasetIndex];
                    const percentValue =
                      (value / currentChartConfig.dataSum) * 100;
                    return `${xAxisLabels[dataIndex]}: ${formatWithPercent(
                      percentValue
                    )}`;
                  },
                },
              },
            },
          }}
        />
      );
    })
    .exhaustive();
};
