import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { LivestockForecastMonthFragmentDoc } from '../fragments/livestockForecastMonth.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LivestockForecastMonthsQueryVariables = Types.Exact<{
  farmIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type LivestockForecastMonthsQuery = { __typename?: 'Query', livestockForecastMonths: Array<{ __typename: 'LivestockForecastMonth', farmIDs: Array<string>, forecastAt: string, livestockTotal: number, milkPerHead: number, livestockCows: { __typename?: 'LivestockCows', milkingRate: number, total: number, firstLactation: { __typename?: 'LivestockCowsLactation', dry: number, milking: number, total: number }, otherLactations: { __typename?: 'LivestockCowsLactation', dry: number, milking: number, total: number } }, livestockHeifers: { __typename?: 'LivestockLiveMonthsPeriodsPlusTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, twelvePlusMonth: number, total: number }, livestockBulls: { __typename?: 'LivestockLiveMonthsPeriodsPlusTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, twelvePlusMonth: number, total: number }, calvings: { __typename?: 'LivestockCalvings', heifers: number, firstLactation: number, otherLactations: number, newbornHeifers: number, newbornBulls: number, total: number }, expectedDry: { __typename?: 'LivestockLactationPeriodsTotal', firstLactation: number, otherLactations: number, total: number }, expectedRetired: { __typename?: 'LivestockExpectedRetired', total: number, cows: { __typename?: 'LivestockLactationPeriodsTotal', firstLactation: number, otherLactations: number, total: number }, bulls: { __typename?: 'LivestockLiveMonthsPeriodsTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, total: number }, heifers: { __typename?: 'LivestockLiveMonthsPeriodsTotal', zeroToTwoMonth: number, threeToSixMonth: number, sevenToTwelveMonth: number, total: number } }, milk: { __typename?: 'LivestockLactationPeriodsTotalMilk', firstLactation: number, otherLactations: number, total: number } }> };


export const LivestockForecastMonthsDocument = gql`
    query livestockForecastMonths($farmIDs: [ID!]) {
  livestockForecastMonths(farmIDs: $farmIDs) {
    ...LivestockForecastMonth
  }
}
    ${LivestockForecastMonthFragmentDoc}`;

/**
 * __useLivestockForecastMonthsQuery__
 *
 * To run a query within a React component, call `useLivestockForecastMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivestockForecastMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivestockForecastMonthsQuery({
 *   variables: {
 *      farmIDs: // value for 'farmIDs'
 *   },
 * });
 */
export function useLivestockForecastMonthsQuery(baseOptions?: Apollo.QueryHookOptions<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>(LivestockForecastMonthsDocument, options);
      }
export function useLivestockForecastMonthsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>(LivestockForecastMonthsDocument, options);
        }
export function useLivestockForecastMonthsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>(LivestockForecastMonthsDocument, options);
        }
export type LivestockForecastMonthsQueryHookResult = ReturnType<typeof useLivestockForecastMonthsQuery>;
export type LivestockForecastMonthsLazyQueryHookResult = ReturnType<typeof useLivestockForecastMonthsLazyQuery>;
export type LivestockForecastMonthsSuspenseQueryHookResult = ReturnType<typeof useLivestockForecastMonthsSuspenseQuery>;
export type LivestockForecastMonthsQueryResult = Apollo.QueryResult<LivestockForecastMonthsQuery, LivestockForecastMonthsQueryVariables>;