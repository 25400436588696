import {
  makeUpdateFragment,
  makeUseFragmentFromCacheOrQuery,
} from '~/services/gql';

import {
  CustomMilkingReportFragment,
  CustomMilkingReportFragmentDoc,
} from './gql/fragments/customMilkingReport.graphql';
import {
  CustomMilkingReportDetailedFragment,
  CustomMilkingReportDetailedFragmentDoc,
} from './gql/fragments/customMilkingReportDetailed.graphql';
import {
  CustomMilkingReportsDetailedQuery,
  CustomMilkingReportsDetailedQueryVariables,
  useCustomMilkingReportsDetailedQuery,
} from './gql/queries/customMilkingReportsDetailed.graphql';

/**
 * Update CustomMilkingReport in the cache
 */
export const updateCustomMilkingReportFragment =
  makeUpdateFragment<CustomMilkingReportFragment>({
    typeName: 'CustomMilkingReport',
    fragment: CustomMilkingReportFragmentDoc,
  });

/**
 * Hook for getting custom report fragment from cache or by query
 */
export const useCustomMilkingReportDetailedFromCacheOrQuery =
  makeUseFragmentFromCacheOrQuery<
    CustomMilkingReportDetailedFragment,
    CustomMilkingReportsDetailedQuery,
    CustomMilkingReportsDetailedQueryVariables
  >({
    typeName: 'CustomMilkingReportDetailed',
    fragment: CustomMilkingReportDetailedFragmentDoc,
    useQuery: useCustomMilkingReportsDetailedQuery,
    getItemFromQueryData: data => data.customMilkingReports.edges.at(0)?.node,
  });
