import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { BlueprintRunSettings, Dow } from '~/shared/graphql';

import { BlueprintDetailsEditModel } from '../../../../types';
import { WeekDaysSelector } from '../WeekDaysSelector';

interface Props {
  runSettings: BlueprintRunSettings;
  onChange: React.Dispatch<React.SetStateAction<BlueprintDetailsEditModel>>;
}

const fillArray = (current: Dow[][], itemsNumber: number): Dow[][] => {
  const newArray = [...current];
  while (newArray.length < itemsNumber) {
    newArray.push([]);
  }
  return newArray;
};

export const BlueprintDetailsRunSettingsScheduleBiWeekly: React.FC<Props> = ({
  runSettings,
  onChange,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const firstWeekDows = runSettings.dows[0] || [];
  const secondWeekDows = runSettings.dows[1] || [];

  const onChangeCallback = useCallback(
    (weekNumber: number, dow: Dow[]) => {
      const reinit = runSettings.dows.length < 2;

      const dowsToProcess = reinit
        ? fillArray(runSettings.dows, 2)
        : runSettings.dows;

      const updatedDows = dowsToProcess.map((week, i) => {
        return i === weekNumber ? dow : week;
      });

      onChange(prevState => {
        return {
          ...prevState,
          runSettings: {
            ...prevState.runSettings,
            dows: updatedDows,
          },
        };
      });
    },
    [onChange, runSettings]
  );

  return (
    <>
      <p className="my-16">
        {t(IntegratorStrings.blueprintDetails.runSettings.repeatDays)}
      </p>
      <WeekDaysSelector
        selectedDows={firstWeekDows}
        onChange={dow => {
          onChangeCallback(0, dow);
        }}
      />
      <WeekDaysSelector
        selectedDows={secondWeekDows}
        onChange={dow => {
          onChangeCallback(1, dow);
        }}
      />
    </>
  );
};
