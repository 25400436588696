import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ValueEditorArgs,
  ValueEditorStandardProps,
} from './types/valueEditorTypes';

type ComponentArgument<T = ValueEditorStandardProps> = React.FC<T>;

export const EditorHoC = <T extends ValueEditorStandardProps>(
  View: ComponentArgument<T>,
  Edit: ComponentArgument<T>,
  additionalArgs?: any
) => {
  return useMemo(() => {
    const Component: React.FC<ValueEditorArgs<any>> = props => {
      const { edit, cancel } = props;

      const [editing, setEditing] = useState(edit);

      useEffect(() => {
        setEditing(edit);
      }, [edit]);

      const toggelCallback = useCallback(() => {
        setEditing(val => !val);
      }, [setEditing]);

      const viewComponent = (
        <View
          {...props}
          {...additionalArgs}
          toggle={cancel || toggelCallback}
        />
      );
      const editComponent = (
        <Edit
          {...props}
          {...additionalArgs}
          toggle={cancel || toggelCallback}
        />
      );

      return editing ? editComponent : viewComponent;
    };
    return Component;
  }, [View, Edit, additionalArgs]);
};
