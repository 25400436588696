import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

import { MIN_SKELETON_SHOW_MS, SKELETON_SHOW_DELAY_MS } from '../constants';
import { getSingleSkeletonPlaceholder } from '../helpers';
import { SkeletonPlaceholder } from '../types';

/**
 * Hook is similar to useState, but it has a third returned value,
 * with a special setter for a skeleton state of the data,
 * which takes care of displaying it only after some amount of time
 * and delaying setting new state for a minimum timeout to avoid skeleton flashing
 */
export const useSkeletonableState = <S>(
  initialState: S | SkeletonPlaceholder | (() => S | SkeletonPlaceholder)
): [
  S | SkeletonPlaceholder,
  Dispatch<SetStateAction<S | SkeletonPlaceholder>>,
  () => void,
] => {
  const [actualState, setActualState] = useState(initialState);

  const skeletonStateTimeoutRef = useRef<NodeJS.Timeout>();
  const skeletonStateMinShowPromiseRef = useRef<Promise<void>>();

  const setState: Dispatch<SetStateAction<S | SkeletonPlaceholder>> =
    useCallback(async newStateOrSetState => {
      // Abort setting skeleton state
      clearTimeout(skeletonStateTimeoutRef.current);

      await skeletonStateMinShowPromiseRef.current;

      setActualState(newStateOrSetState);
    }, []);

  const setSkeletonState = useCallback(() => {
    // Remove previous timeout if any
    clearTimeout(skeletonStateTimeoutRef.current);

    // Set skeleton state only after a delay
    skeletonStateTimeoutRef.current = setTimeout(() => {
      setActualState(getSingleSkeletonPlaceholder());

      // Don't allow to set new state for a min show time to avoid flashing
      skeletonStateMinShowPromiseRef.current = new Promise(resolve =>
        setTimeout(() => {
          resolve();
          skeletonStateMinShowPromiseRef.current = undefined;
        }, MIN_SKELETON_SHOW_MS)
      );
    }, SKELETON_SHOW_DELAY_MS);
  }, []);

  return [actualState, setState, setSkeletonState];
};
