import { useMemo } from 'react';

import R from 'ramda';
import { match, P } from 'ts-pattern';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';

import { ReproductionCrDetailedRowsByEntityFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrDetailedRowsByEntity.graphql';

import { REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT } from '../constants';
import { formatReproductionCrValue } from '../helpers';
import { CrReportDisplayProps } from '../types';

interface UseReproductionCrReportXAxisProps extends CrReportDisplayProps {
  /**
   * If true, adds others to xAxisLabels
   */
  shouldShowOther?: boolean;
  /**
   * If true, adds total to xAxisLabels
   */
  shouldShowTotal?: boolean;
}

// Small helper for match expressions readability to avoid writing a lot of typeguards
const TN = <T extends ReproductionCrDetailedRowsByEntityFragment['__typename']>(
  __typename: T
) => ({
  __typename,
});

const getChartDataXAxisLabels = (
  chartData: ReproductionCrDetailedRowsByEntityFragment | SkeletonPlaceholder
) =>
  match(chartData)
    .with(P.when(isSkeletonPlaceholder), R.always([]))
    .with(TN('ReproductionCrRowsByBull'), ({ bullRowNames }) =>
      bullRowNames.map(rowName => formatReproductionCrValue(rowName, true))
    )
    .with(TN('ReproductionCrRowsByAnimalCycle'), ({ animalCycleRowNames }) =>
      animalCycleRowNames.map(rowName =>
        formatReproductionCrValue(rowName, true)
      )
    )
    .with(TN('ReproductionCrRowsByBullBreed'), ({ bullBreedRowNames }) =>
      bullBreedRowNames.map(rowName => formatReproductionCrValue(rowName, true))
    )
    .with(TN('ReproductionCrRowsByDate'), ({ dateRowNames }) =>
      dateRowNames.map(rowName => formatReproductionCrValue(rowName, true))
    )
    .with(TN('ReproductionCrRowsByDows'), ({ dowRowNames }) =>
      dowRowNames.map(rowName => formatReproductionCrValue(rowName, true))
    )
    .with(TN('ReproductionCrRowsByEmployee'), ({ employeeRowNames }) =>
      employeeRowNames.map(rowName => formatReproductionCrValue(rowName, true))
    )
    .with(
      TN('ReproductionCrRowsByInseminationNumber'),
      ({ inseminationNumberRowNames }) =>
        inseminationNumberRowNames.map(rowName =>
          formatReproductionCrValue(rowName, true)
        )
    )
    .with(
      TN('ReproductionCrRowsByInseminationScheme'),
      ({ inseminationSchemeRowNames }) =>
        inseminationSchemeRowNames.map(rowName =>
          formatReproductionCrValue(rowName, true)
        )
    )
    .with(
      TN('ReproductionCrRowsByIntervalBetweenInseminations'),
      ({ intervalBetweenInseminationsRowNames }) =>
        intervalBetweenInseminationsRowNames.map(rowName =>
          formatReproductionCrValue(rowName, true)
        )
    )
    .with(TN('ReproductionCrRowsByStudCode'), ({ studCodeRowNames }) =>
      studCodeRowNames.map(rowName => formatReproductionCrValue(rowName, true))
    )
    .exhaustive();

/**
 * Hook for managing x axis labels and table first column periods display
 */
export const useReproductionCrReportXAxis = ({
  chartData,
  historicChartData,
  xAxisMode,
  shouldShowOther = false,
  shouldShowTotal = false,
}: UseReproductionCrReportXAxisProps) => {
  const isComparison = !!historicChartData;

  const xAxisName = REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[xAxisMode];

  const getXAxisLabels = (
    data: ReproductionCrDetailedRowsByEntityFragment | SkeletonPlaceholder
  ) => [
    ...getChartDataXAxisLabels(data),
    ...wrapConditionalArrayElement(shouldShowOther && 'Прочие'),
    ...wrapConditionalArrayElement(shouldShowTotal && 'Всего'),
  ];

  const xAxisLabels = useMemo(() => getXAxisLabels(chartData), [chartData]);
  const historicXAxisLabels = useMemo(
    () => (historicChartData ? getXAxisLabels(historicChartData) : []),
    [historicChartData]
  );

  const getXAxisLabelByRowIndex = (
    rowIndex: number,
    shouldShowComparisonLabels = false
  ) => {
    if (!isComparison || !shouldShowComparisonLabels) {
      return xAxisLabels.at(rowIndex);
    }

    const normalizedRowIndex = Math.floor(rowIndex / 2);
    return rowIndex % 2
      ? historicXAxisLabels.at(normalizedRowIndex)
      : xAxisLabels.at(normalizedRowIndex);
  };

  return {
    xAxisName,
    xAxisLabels,
    getXAxisLabelByRowIndex,
  };
};
