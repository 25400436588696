import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetPinnedCowFieldsMutationVariables = Types.Exact<{
  kinds: Array<Types.SourceFieldKindEnum> | Types.SourceFieldKindEnum;
  fieldIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type SetPinnedCowFieldsMutation = { __typename?: 'Mutation', setPinnedCowFields?: any | null };


export const SetPinnedCowFieldsDocument = gql`
    mutation setPinnedCowFields($kinds: [SourceFieldKindEnum!]!, $fieldIds: [ID!]!) {
  setPinnedCowFields(kinds: $kinds, blueprintSourceFieldIDs: $fieldIds)
}
    `;
export type SetPinnedCowFieldsMutationFn = Apollo.MutationFunction<SetPinnedCowFieldsMutation, SetPinnedCowFieldsMutationVariables>;

/**
 * __useSetPinnedCowFieldsMutation__
 *
 * To run a mutation, you first call `useSetPinnedCowFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPinnedCowFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPinnedCowFieldsMutation, { data, loading, error }] = useSetPinnedCowFieldsMutation({
 *   variables: {
 *      kinds: // value for 'kinds'
 *      fieldIds: // value for 'fieldIds'
 *   },
 * });
 */
export function useSetPinnedCowFieldsMutation(baseOptions?: Apollo.MutationHookOptions<SetPinnedCowFieldsMutation, SetPinnedCowFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPinnedCowFieldsMutation, SetPinnedCowFieldsMutationVariables>(SetPinnedCowFieldsDocument, options);
      }
export type SetPinnedCowFieldsMutationHookResult = ReturnType<typeof useSetPinnedCowFieldsMutation>;
export type SetPinnedCowFieldsMutationResult = Apollo.MutationResult<SetPinnedCowFieldsMutation>;
export type SetPinnedCowFieldsMutationOptions = Apollo.BaseMutationOptions<SetPinnedCowFieldsMutation, SetPinnedCowFieldsMutationVariables>;