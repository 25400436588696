import React from 'react';

export const Update = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M1.875 10C1.875 5.48816 5.48816 1.875 10 1.875C12.4121 1.875 14.5589 2.92666 16.0416 4.58432V2.5C16.0416 2.15482 16.3215 1.875 16.6666 1.875C17.0118 1.875 17.2916 2.15482 17.2916 2.5V6.56913C17.2993 6.9156 17.0046 7.21549 16.6544 7.20833H12.5833C12.2381 7.20833 11.9583 6.92851 11.9583 6.58333C11.9583 6.23816 12.2381 5.95833 12.5833 5.95833H15.5475C14.2958 4.24272 12.2862 3.125 10 3.125C6.17851 3.125 3.125 6.17851 3.125 10C3.125 13.8215 6.17851 16.875 10 16.875C13.5084 16.875 16.4133 14.2042 16.7955 10.7643C16.8336 10.4212 17.1426 10.174 17.4857 10.2122C17.8288 10.2503 18.076 10.5593 18.0378 10.9024C17.5867 14.9624 14.1582 18.125 10 18.125C5.48816 18.125 1.875 14.5118 1.875 10Z"
      fill="currentColor"
    />
  </svg>
);
