import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { RunType } from '@graphql-types';

import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { BlueprintExecutionInfo } from '~/~legacy/services/BlueprintExecutionService';
import { AppEmployee } from '~/~legacy/types/entities';

import { MErrorMessage } from '~/shared/components/MErrorMessage';

import { useConfirm } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';

import { PageHeader } from '~/features/layouts';

import { BlueprintExecutionWrapper } from '../../../BlueprintExecutionWrapper';
import {
  FreshActionCalvingData,
  FreshActionInputActionTypes,
  freshActionInputsReducer,
  getInitialState,
  isCalvingEmpty,
} from '../../reducer';
import { BlueprintSpecialInputProps } from '../../types';
import { FreshActionCalvingFormsList } from '../FreshActionCalvingFormsList';
import { FreshActionInputCommonForm } from '../FreshActionInputCommonForm';
import { FreshActionInputPanel } from '../FreshActionInputPanel';
import { calvingActionToApiData } from './helpers';

export const FreshActionInput: React.FC<BlueprintSpecialInputProps> = props => {
  const { errors: executionErrors, start, executionInfo } = props;

  const [state, dispatch] = useReducer(
    freshActionInputsReducer,
    getInitialState(executionInfo.specialInput)
  );
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const actionButtonClickCallback = useCallback(() => {
    dispatch({
      type: FreshActionInputActionTypes.ActionClick,
    });
  }, [dispatch]);

  const updateCommonData = useCallback(
    (happenedAt?: string, employee?: AppEmployee) => {
      dispatch({
        type: FreshActionInputActionTypes.UpdateCommonData,
        data: {
          happenedAt,
          employee,
        },
      });
    },
    [dispatch]
  );

  const updateCalvingData = useCallback(
    (calving: FreshActionCalvingData) => {
      dispatch({
        type: FreshActionInputActionTypes.UpdateCalvingData,
        data: calving,
      });
    },
    [dispatch]
  );

  const confirmDelete = useConfirm();

  const deleteCalvingData = useCallback(
    async (calving: FreshActionCalvingData) => {
      const isConfirmed = await confirmDelete({
        isDelete: true,
        message: 'Хотите удалить запись?',
      });
      if (isConfirmed) {
        dispatch({
          type: FreshActionInputActionTypes.DeleteCalvingData,
          data: calving,
        });
      }
    },
    []
  );

  const addCalvingData = useCallback(() => {
    dispatch({
      type: FreshActionInputActionTypes.AddCalvingData,
    });
  }, [dispatch]);

  useEffect(() => {
    if (state.submissionDataKey && executionInfo.specialInput) {
      const data = {
        common: state.common,
        topCalving: state.topCalving,
        calvings: state.calvings,
      };

      const calvingsToSubmit = isCalvingEmpty(state.topCalving)
        ? state.calvings
        : [state.topCalving, ...state.calvings];

      const apiData = calvingActionToApiData(state.common, calvingsToSubmit);

      const execInfo: BlueprintExecutionInfo = {
        ...executionInfo,
        specialInput: {
          ...executionInfo.specialInput,
          data,
          convertedForApi: apiData,
        },
      };
      setSubmitLoading(true);
      start(execInfo).finally(() => setSubmitLoading(false));
    }
  }, [state.submissionDataKey]);

  const calvingForms = state.showFullForm ? (
    <FreshActionCalvingFormsList
      update={updateCalvingData}
      del={deleteCalvingData}
      add={addCalvingData}
      topCalving={state.topCalving}
      calvings={state.calvings}
      showAddButton={state.showAddCalvingButton}
    />
  ) : null;

  const panel = state.showFullForm ? (
    <FreshActionInputPanel
      next={actionButtonClickCallback}
      isSubmitLoading={isSubmitLoading}
      calvings={[state.topCalving, ...state.calvings]}
    />
  ) : null;

  const errors =
    state.errors.length === 0 ? null : (
      <MErrorMessage>
        {state.errors.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${item}${index}`}>
            {item}
            <br />
          </React.Fragment>
        ))}
      </MErrorMessage>
    );

  const { errorMessage } = useApiFeedback(executionErrors);
  const { urlCompanyId } = useArkaNavigation();

  return (
    <BlueprintExecutionWrapper panel={panel}>
      <PageHeader
        {...{
          title: executionInfo.name,
          backLinkProps: {
            to:
              executionInfo.runType === RunType.Schedule
                ? '/$companyId/user/production-calendar'
                : '/$companyId/user/incidents',
            params: {
              companyId: urlCompanyId,
            },
          },
        }}
      />
      {errorMessage}
      {errors}
      <FreshActionInputCommonForm
        employee={state.common.employee}
        happenedAt={state.common.happenedAt}
        update={updateCommonData}
      />
      {calvingForms}
    </BlueprintExecutionWrapper>
  );
};
