import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type DatetimeHardcodedValueFragment = { __typename: 'DatetimeHardcodedValue', datetimeValue: string };

export const DatetimeHardcodedValueFragmentDoc = gql`
    fragment DatetimeHardcodedValue on DatetimeHardcodedValue {
  __typename
  datetimeValue: value
}
    `;