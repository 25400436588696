import { MonitorLaunchCalculatingValueFragment } from '~/widgets/monitor/gql/fragments/monitorLaunchCalculatingValue.graphql';

/**
 * create placeholder mock for local apollo cache update when calculating monitor
 */
export const makeMonitorLaunchPlaceholder = (
  happenedAt: string
): MonitorLaunchCalculatingValueFragment => {
  return {
    __typename: 'MonitorLaunchCalculatingValue' as const,
    monitorLaunch: {
      __typename: 'MonitorLaunch' as const,
      id: 'placeholder_id',
      happenedAt,
    },
  };
};
