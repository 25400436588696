import React from 'react';

import { useRouter } from '@tanstack/react-router';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { ErrorPage } from '../ErrorPage';

const DESCRIPTION_ITEMS = [
  'В адресе есть ошибка или страница удалена.',
  'Если ошибка повторяется — сообщите об этом администратору',
];

export const NotFoundPage: React.FC = () => {
  const { basepath, navigate } = useRouter();

  return (
    <ErrorPage
      {...{
        title: 'Страница не найдена',
        errorCode: 404,
        description: DESCRIPTION_ITEMS.map(description => (
          <Typography key={description} variant={TypographyVariants.bodyMedium}>
            {description}
          </Typography>
        )),
        buttonProps: {
          children: 'Вернуться на главную страницу',
          onPress: () => navigate({ to: basepath }),
        },
      }}
    />
  );
};
