import { ModalRegistration } from '~/services/modals';

import {
  EditReproductionTargetsModal,
  EditReproductionTargetsModalProps,
} from '.';

export const registration: ModalRegistration<EditReproductionTargetsModalProps> =
  {
    Component: EditReproductionTargetsModal,
  };
