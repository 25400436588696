import React from 'react';

import { CustomReportChartAggFormulaKindEnum } from '@graphql-types';

import { Checkbox } from '~/shared/components/Checkbox';
import { makeUseEnumSelect, SelectThemes } from '~/shared/components/Select';

import { ReportCardBuilderFormArrayItem } from '~/features/reportCardBuilder';

import { CUSTOM_REPORT_CHART_AGG_FORMULA_KINDS_DICT } from '../../../../constants';
import { UseCustomReportChartAxesInterface } from '../../../../hooks';
import { CustomReportYAxisFormType } from '../../../../types';

const useCustomReportChartAggFormulaKindSelect = makeUseEnumSelect(
  CustomReportChartAggFormulaKindEnum,
  enumValue =>
    CUSTOM_REPORT_CHART_AGG_FORMULA_KINDS_DICT[
      enumValue as CustomReportChartAggFormulaKindEnum
    ]
);

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Field name prefix to use for form fields in array
   */
  fieldPrefix: `yAxes.${number}.`;
  /**
   * If true, aggregation formula select is displayed
   */
  shouldUseAggFormula: boolean;
  /**
   * If true, renders a checkbox for enabling second scale rendering
   */
  shouldAllowSecondScale: boolean;
  /**
   * Y axis config to render
   */
  yAxisConfig: CustomReportYAxisFormType;
  /**
   * Called, when user pressed delete button to remove the config
   */
  onDelete?: () => void;
  /**
   * Helpers to display custom report chart axes
   */
  useCustomReportChartAxesInterface: UseCustomReportChartAxesInterface;
}

export const YAxisConfigArrayItemCard: React.FC<Props> = ({
  className,
  fieldPrefix,
  yAxisConfig,
  shouldUseAggFormula,
  shouldAllowSecondScale,
  onDelete,
  useCustomReportChartAxesInterface: { getYAxisName },
}) => {
  const { renderSelectElement: renderAggFormulaKindSelectElement } =
    useCustomReportChartAggFormulaKindSelect({
      name: `${fieldPrefix}aggFormula`,
      label: 'Тип подсчёта',
      theme: SelectThemes.light,
    });

  return (
    <ReportCardBuilderFormArrayItem
      {...{
        className,
        title: getYAxisName(yAxisConfig),
        onDelete,
      }}
    >
      {shouldUseAggFormula && renderAggFormulaKindSelectElement()}
      {shouldAllowSecondScale && (
        <Checkbox
          {...{
            name: `${fieldPrefix}withRightScale`,
            label: 'Отобразить на шкале справа',
          }}
        />
      )}
    </ReportCardBuilderFormArrayItem>
  );
};
