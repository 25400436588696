export const API_URL = process.env.REACT_APP_API_URL ?? '';

export const LAYOUT_ROOT_ID = 'LayoutRoot';

export const DEFAULT_LOCALE = 'ru-RU';

export const CSV_SEPARATOR = ';';

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PRECISION = 2;

export const SEARCH_DEBOUNCE_MS = 700;

/**
 * Non breaking space symbol
 */
export const NBSP = '\u00A0';

/**
 * Non breaking hyphen symbol
 */
export const NON_BREAKING_HYPHEN = '\u2011';

/**
 * Mdash symbol
 */
export const MDASH = '\u2014';

/**
 * Used for words separation
 */
export const INTERPUNCT = '·';

/**
 * Interpunct symbol surrounded with spaces for joining words
 */
export const SPACED_INTERPUNCT = `${NBSP}${INTERPUNCT} `;

/**
 * Half space symbol
 */
export const HALF_SPACE = '\u202F';

/**
 * Regexp for uuid
 */
export const UUID_REGEXP =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

/**
 * Message to shown for missing values
 */
export const NO_VALUE_MESSAGE = 'Нет значения';

/**
 * Message to shown for values with empty string value
 */
export const NO_NAME_MESSAGE = 'Нет названия';

/**
 * Possible app localStorage keys
 */
export enum AppLocalStorageKeys {
  accessToken = 'Maslov.AccessToken',
  refreshToken = 'Maslov.RefreshToken',
  language = 'Maslov.Language',
  selectedCompanyId = 'Maslov.SelectedCompanyId',
  blueprintRunDates = 'Maslov.BlueprintRunDates',
  cowCardSectionIsOpen = 'Maslov.CowCardSectionIsOpen',
  selectedMilkingReportKinds = 'Maslov.SelectedMilkingReportKinds',
  navigationMenuState = 'Maslov.NavigationMenuState',
}
