import { CustomReportValueFormulaKindEnum } from '@graphql-types';
import R from 'ramda';

import { CustomReportBlueprintSourceFieldValueFragment } from '~/entities/customReports/gql/fragments/customReportBlueprintSourceFieldValue.graphql';

/**
 * Each source field value type has different formulas available for calculation in value configs
 */
export const AVAILABLE_FORMULAS_BY_VALUE_TYPES: Record<
  keyof Omit<CustomReportBlueprintSourceFieldValueFragment, '__typename'>,
  CustomReportValueFormulaKindEnum[]
> = {
  intValue: R.difference(Object.values(CustomReportValueFormulaKindEnum), [
    CustomReportValueFormulaKindEnum.Counta,
  ]),
  floatValue: R.difference(Object.values(CustomReportValueFormulaKindEnum), [
    CustomReportValueFormulaKindEnum.Counta,
  ]),
  strValue: [
    CustomReportValueFormulaKindEnum.Counta,
    CustomReportValueFormulaKindEnum.CountUnique,
  ],
  datetimeValue: [
    CustomReportValueFormulaKindEnum.Counta,
    CustomReportValueFormulaKindEnum.CountUnique,
    CustomReportValueFormulaKindEnum.Max,
    CustomReportValueFormulaKindEnum.Min,
  ],
};
