import React, { ReactNode } from 'react';

interface Props {
  isForm: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  children?: ReactNode;
}

export const ControlWrapper: React.FC<Props> = React.memo(props => {
  const { isForm, onSubmit, className, children } = props;

  return isForm ? (
    <form
      className={`position-relative ${className || ''}`}
      onSubmit={onSubmit}
    >
      {children}
      <input type="submit" style={{ display: 'none' }} />
    </form>
  ) : (
    (children as React.ReactElement)
  );
});
