import React, { ReactNode } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { PressProps, usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { Icon, IconProps, IconVariants } from '~/shared/components/Icon';
import {
  TextSkeletonSizes,
  useSkeletonContext,
} from '~/shared/components/Skeleton';
import { Tooltip, TooltipProps } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import styles from './index.module.scss';

/**
 * Possible function button variants
 */
export enum FunctionButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  failure = 'failure',
}

export interface FunctionButtonProps
  extends Omit<React.ComponentProps<'button'>, 'disabled'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Button display variant
   */
  variant?: FunctionButtonVariants;
  /**
   * If true, dark theme is used
   */
  isDark?: boolean;
  /**
   * Called, when the button is pressed with mouse or keyboard
   */
  onPress?: PressProps['onPress'];
  /**
   * If true, click events won't fire and pointer cursor won't be used
   */
  isDisabled?: boolean;
  /**
   * Icon variant to display
   */
  iconVariant?: IconVariants;
  /**
   * If true, icon is rendered on the right side of the label
   */
  isWithRightIcon?: boolean;
  /**
   * Additional props for icon
   */
  iconProps?: Partial<Omit<IconProps, 'ref' | 'tooltip' | 'tooltipProps'>>;
  /**
   * Tooltip for button
   */
  tooltip?: ReactNode;
  /**
   * Additional props for the tooltip
   */
  tooltipProps?: Partial<TooltipProps>;
}

export const FunctionButton = React.forwardRef<
  HTMLButtonElement,
  FunctionButtonProps
>(
  (
    {
      className,
      variant = FunctionButtonVariants.tertiary,
      isDark = false,
      onPress,

      isDisabled = false,

      iconVariant,
      isWithRightIcon = false,
      iconProps,

      tooltip,
      tooltipProps,

      children,

      ...other
    }: FunctionButtonProps,
    ref
  ) => {
    const { renderWithoutSkeleton } = useSkeletonContext();

    const { pressProps, isPressed } = usePress({
      isDisabled,
      onPress,
    });

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    const iconElement =
      iconVariant &&
      renderWithoutSkeleton(<Icon variant={iconVariant} {...iconProps} />);

    return (
      <Tooltip content={tooltip} isDisabled={!tooltip} {...tooltipProps}>
        <button
          {...{
            ref,
            className: clsx(
              styles.root,
              styles[variant],
              className,
              isDark ? styles.dark : styles.light,
              isPressed && styles.pressed,
              isFocusVisible && styles.focused,
              isDisabled && styles.disabled
            ),
            type: 'button',
            disabled: isDisabled,
            'data-focus-visible': isFocusVisible,
            ...mergeProps(pressProps, focusProps, other),
          }}
        >
          {!isWithRightIcon && iconElement}
          {!!children && (
            <Typography
              className="py-2"
              variant={TypographyVariants.bodySmallStrong}
              skeletonSize={TextSkeletonSizes.large}
            >
              {children}
            </Typography>
          )}
          {isWithRightIcon && iconElement}
        </button>
      </Tooltip>
    );
  }
);
