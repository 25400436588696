import React from 'react';

import { mergeProps } from '~/shared/helpers/mergeProps';

import { makeDeleteQueryByNameWithVariables } from '~/services/gql';
import {
  InjectedStepperModalProps,
  ProgressBar,
  StepperModalWrapperProps,
  withStepperModal,
} from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { useLoadTestMilkingCsvMutation } from '../../gql/mutations/loadTestMilkingCsv.graphql';
import { useLoadTestMilkingPartialResultsModal } from '..';
import { LoadTestMilkingInstruction } from './components/LoadTestMilkingInstruction';
import { ResolveTestMilkingConflictsForm } from './components/ResolveTestMilkingConflictsForm';
import { SelectTestMilkingFileForm } from './components/SelectTestMilkingFileForm';
import {
  RESOLVE_TEST_MILKING_CONFLICTS_FORM_SCHEMA,
  ResolveTestMilkingConflictsFormType,
  useResolveTestMilkingConflictsForm,
} from './hooks/useResolveTestMilkingConflictsForm';
import {
  SELECT_TEST_MILKING_FILE_FORM_SCHEMA,
  SelectTestMilkingFileFormType,
  useSelectTestMilkingFileForm,
} from './hooks/useSelectTestMilkingFileForm';

enum LoadTestMilkingCsvSteps {
  instruction = 'instruction',
  selectTestMilkingFile = 'selectTestMilkingFile',
  resolveConflicts = 'resolveConflicts',
}

interface LoadTestMilkingCsvFormTypes {
  instruction: void;
  selectTestMilkingFile: SelectTestMilkingFileFormType;
  resolveConflicts: ResolveTestMilkingConflictsFormType;
}

type LoadTestMilkingCsvStepperState = SelectTestMilkingFileFormType &
  ResolveTestMilkingConflictsFormType;

interface EditEventModalZeroStepWrapperProps {
  submitTestMilking: (fileInput: LoadTestMilkingCsvStepperState) => void;
}

export interface LoadTestMilkingCsvModalProps
  extends InjectedStepperModalProps<
    LoadTestMilkingCsvModalProps,
    LoadTestMilkingCsvStepperState,
    EditEventModalZeroStepWrapperProps
  > {
  /**
   * className applied to the root element
   */
  className?: string;
}

const LoadTestMilkingCsvModalInternal: React.FC<
  LoadTestMilkingCsvModalProps
> = ({
  className,

  stepsCount,
  stepNumber,
  close,

  StepperModalWrapperComponent,
  stepperModalWrapperProps,
}) => {
  const { sendSuccessToast, sendWarningToast } = useNotifications();

  const { open: openLoadTestMilkingPartialResultsModal } =
    useLoadTestMilkingPartialResultsModal();

  const [loadTestMilkingCsv, { loading: isLoading, client }] =
    useLoadTestMilkingCsvMutation();

  return (
    <StepperModalWrapperComponent
      {...mergeProps(stepperModalWrapperProps, {
        modalProps: {
          className,
          title: 'Загрузка результатов контрольной дойки',
          submitButtonProps: {
            children: 'Загрузить',
            isLoading,
          },
        },
        submitTestMilking: fileInput => {
          loadTestMilkingCsv({
            variables: {
              fileInput,
            },
            refetchQueries: ['testMilkingUploads'],
          })
            .then(({ data }) => {
              if (!data) return;

              // Refetch test milkings, if we already loaded them for this upload date
              makeDeleteQueryByNameWithVariables('testMilkings', {
                testMilkingUploadIDs: [data.loadTestMilkingCsv.id],
              })(client.cache);

              if (data.loadTestMilkingCsv.hasUploadIssues) {
                sendWarningToast({
                  message:
                    'Результаты контрольной дойки загружены не полностью',
                  functionButtonProps: {
                    children: 'Подробнее',
                    onPress: () => {
                      openLoadTestMilkingPartialResultsModal({
                        testMilkingUpload: data.loadTestMilkingCsv,
                      });
                    },
                  },
                });
              } else {
                sendSuccessToast('Результаты контрольной дойки загружены');
              }
            })
            .finally(() => {
              close();
            });
        },
      } satisfies Partial<
        StepperModalWrapperProps<
          LoadTestMilkingCsvModalProps,
          LoadTestMilkingCsvStepperState,
          EditEventModalZeroStepWrapperProps
        >
      >)}
    >
      {stepElement => (
        <>
          <ProgressBar {...{ className: 'mb-24', stepsCount, stepNumber }} />
          {stepElement}
        </>
      )}
    </StepperModalWrapperComponent>
  );
};

export const LoadTestMilkingCsvModal = withStepperModal<
  LoadTestMilkingCsvModalProps,
  LoadTestMilkingCsvSteps,
  LoadTestMilkingCsvFormTypes,
  LoadTestMilkingCsvFormTypes,
  LoadTestMilkingCsvStepperState,
  EditEventModalZeroStepWrapperProps
>({
  stepsDict: LoadTestMilkingCsvSteps,
  getDefaultStepperState: () => ({
    ...SELECT_TEST_MILKING_FILE_FORM_SCHEMA.getDefault(),
    ...RESOLVE_TEST_MILKING_CONFLICTS_FORM_SCHEMA.getDefault(),
  }),
  onNextStepSubmit: ({
    stepperModalState,
    setStepperModalState,

    isLastStep,
    goToNextStep,

    submitTestMilking,

    form: testMilkingStepForm,
  }) => {
    const newStepperState = {
      ...stepperModalState,
      ...testMilkingStepForm,
    };
    if (testMilkingStepForm) {
      setStepperModalState(newStepperState);
    }

    if (isLastStep) {
      submitTestMilking(newStepperState);
    } else {
      goToNextStep();
    }
  },
  stepHooks: {
    [LoadTestMilkingCsvSteps.instruction]: ({ handleNextStepSubmit }) => {
      return {
        stepElement: <LoadTestMilkingInstruction />,
        modalProps: {
          submitButtonProps: {
            onPress: () => handleNextStepSubmit(),
          },
        },
      };
    },

    [LoadTestMilkingCsvSteps.selectTestMilkingFile]: ({
      handleNextStepSubmit,
      stepperModalState,
    }) => {
      const { formProps, ...otherFormHookResults } =
        useSelectTestMilkingFileForm({
          onSubmit: handleNextStepSubmit,
          defaultValues: stepperModalState,
        });

      return {
        ...otherFormHookResults,
        stepElement: <SelectTestMilkingFileForm formProps={formProps} />,
      };
    },

    [LoadTestMilkingCsvSteps.resolveConflicts]: ({ handleNextStepSubmit }) => {
      const { formProps, ...otherFormHookResults } =
        useResolveTestMilkingConflictsForm({
          onSubmit: handleNextStepSubmit,
        });

      return {
        ...otherFormHookResults,
        stepElement: <ResolveTestMilkingConflictsForm formProps={formProps} />,
      };
    },
  },
})(LoadTestMilkingCsvModalInternal);
