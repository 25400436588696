import React, { useEffect } from 'react';

import * as yup from 'yup';

import { Input, InputProps, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { formatInt } from '~/shared/helpers/number';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { useFarmsFilter } from '~/entities/farms';

import formStyles from '~/styles/modules/form.module.scss';

import {
  DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS,
  DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS,
} from '../../constants';
import { useSetReproductionSettingsMutation } from '../../gql/mutations/setReproductionSettings.graphql';
import { updateFarmWithReproductionSettingsFragment } from '../../helpers';
import { useFarmWithReproductionSettingsFromCacheOrQuery } from '../../hooks';

export interface EditReproductionSettingsModalProps
  extends InjectedModalProps<EditReproductionSettingsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
}

const FORM_ID = 'EditReproductionSettingsForm';

const SCHEMA = yup.object({
  cowsVoluntaryWaitingPeriodDays: yup
    .number()
    .required()
    .default(DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS),
  heifersVoluntaryWaitingPeriodDays: yup
    .number()
    .required()
    .default(DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS),
});

type EditReproductionSettingsFormType = InferValidatedSchema<typeof SCHEMA>;

type SettingsFieldNames = keyof EditReproductionSettingsFormType;

export const EditReproductionSettingsModal: React.FC<
  EditReproductionSettingsModalProps
> = ({ className, close }) => {
  const { sendSuccessToast } = useNotifications();

  const { farmId, renderFarmsSelectElement } = useFarmsFilter(false);

  const {
    fragment: farmFragment,
    fragmentPromise: farmFragmentPromise,
    isLoading,
  } = useFarmWithReproductionSettingsFromCacheOrQuery(farmId);

  const formContext = useForm<EditReproductionSettingsFormType>({
    schema: SCHEMA,
    defaultValues: () =>
      farmFragmentPromise.then(fragment => ({
        ...SCHEMA.getDefault(),
        ...fragment?.reproductionSettings,
      })),
  });

  const [setReproductionSettings] = useSetReproductionSettingsMutation();

  const handleSubmit = (input: EditReproductionSettingsFormType) => {
    if (!farmId) return;

    setReproductionSettings({
      variables: {
        farmID: farmId,
        input,
      },
      optimisticResponse: { setReproductionSettings: null },
      update: updateFarmWithReproductionSettingsFragment(farmId, draft => {
        draft.reproductionSettings.cowsVoluntaryWaitingPeriodDays =
          input.cowsVoluntaryWaitingPeriodDays;
        draft.reproductionSettings.heifersVoluntaryWaitingPeriodDays =
          input.heifersVoluntaryWaitingPeriodDays;
      }),
    }).then(() => {
      sendSuccessToast('Настройки ПДО сохранены');
    });

    close();
  };

  const formValues = formContext.watch();

  // Reset form with new settings, when we select different farm
  useEffect(() => {
    if (!farmFragment) return;

    formContext.reset(farmFragment.reproductionSettings);
  }, [farmFragment]);

  const renderInput = (
    inputProps: InputProps & { name: SettingsFieldNames },
    defaultValue: number
  ) => {
    const inputValue = formValues[inputProps.name];

    const feedback =
      !inputValue || inputValue === defaultValue
        ? undefined
        : `Значение по умолчанию: ${formatInt(defaultValue)} дней`;

    return (
      <Input
        {...{
          ...inputProps,
          feedback,
        }}
      />
    );
  };

  return (
    <Modal
      {...{
        className,
        title: 'Настройки ПДО для страницы «Общее»',
        submitButtonProps: {
          form: FORM_ID,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: formStyles.singleColumnForm,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        {renderFarmsSelectElement({
          name: 'modalFarmId',
          className: 'full-width',
          theme: SelectThemes.dark,
          withFormContext: false,
        })}
        {renderInput(
          {
            name: 'cowsVoluntaryWaitingPeriodDays',
            label: 'Период добровольного ожидания у коров, дней',
            variant: InputVariants.int,
            isDisabled: isLoading,
          },
          DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS
        )}
        {renderInput(
          {
            name: 'heifersVoluntaryWaitingPeriodDays',
            label: 'Период добровольного ожидания у тёлок, дней',
            variant: InputVariants.int,
            isDisabled: isLoading,
          },
          DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS
        )}
      </Form>
    </Modal>
  );
};
