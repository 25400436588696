import React from 'react';

import { Button } from '~/shared/components/Button';

import { BlueprintFormsInfoPanel } from '../../../BlueprintFormsInfoPanel';
import { FreshActionCalvingData } from '../../reducer';
import { PanelData } from '../PanelData';

interface Props {
  next: () => void;
  isSubmitLoading?: boolean;
  calvings: FreshActionCalvingData[];
}

export const FreshActionInputPanel: React.FC<Props> = ({
  next,
  calvings,
  isSubmitLoading = false,
}) => {
  return (
    <BlueprintFormsInfoPanel>
      <PanelData calvings={calvings} />
      <Button isLoading={isSubmitLoading} onPress={next}>
        Завершить и сохранить записи
      </Button>
    </BlueprintFormsInfoPanel>
  );
};
