import { MDASH, SPACED_INTERPUNCT } from '~/shared/constants';

import { formatDate } from '~/services/dateTime';
import { makeUpdateFragment } from '~/services/gql';

import { formatBull } from '~/entities/bulls';

import {
  SemenDoseFragment,
  SemenDoseFragmentDoc,
} from './gql/fragments/semenDose.graphql';
import { SemenDoseFormatOptions } from './types';

/**
 * Returns semenDose formatted for rendering
 */
export const formatSemenDose = (
  semenDose?: SemenDoseFragment | null,
  { withMainInfo = true, withBull = true }: SemenDoseFormatOptions = {}
) => {
  if (!semenDose) return MDASH;

  const bullNumber = formatBull(semenDose.bull);
  const formattedDate = formatDate(semenDose.deliveryDate);

  return [
    withMainInfo && semenDose.studCode,
    withMainInfo && formattedDate,
    withBull && bullNumber,
  ]
    .filter(Boolean)
    .join(SPACED_INTERPUNCT);
};

/**
 * Update SemenDoseFragment in the cache
 */
export const updateSemenDoseFragment = makeUpdateFragment<SemenDoseFragment>({
  typeName: 'SemenDose',
  fragment: SemenDoseFragmentDoc,
});
