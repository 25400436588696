import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBlueprintMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteBlueprintMutation = { __typename?: 'Mutation', deleteBlueprint?: any | null };


export const DeleteBlueprintDocument = gql`
    mutation deleteBlueprint($id: ID!) {
  deleteBlueprint(id: $id)
}
    `;
export type DeleteBlueprintMutationFn = Apollo.MutationFunction<DeleteBlueprintMutation, DeleteBlueprintMutationVariables>;

/**
 * __useDeleteBlueprintMutation__
 *
 * To run a mutation, you first call `useDeleteBlueprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlueprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlueprintMutation, { data, loading, error }] = useDeleteBlueprintMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlueprintMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlueprintMutation, DeleteBlueprintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlueprintMutation, DeleteBlueprintMutationVariables>(DeleteBlueprintDocument, options);
      }
export type DeleteBlueprintMutationHookResult = ReturnType<typeof useDeleteBlueprintMutation>;
export type DeleteBlueprintMutationResult = Apollo.MutationResult<DeleteBlueprintMutation>;
export type DeleteBlueprintMutationOptions = Apollo.BaseMutationOptions<DeleteBlueprintMutation, DeleteBlueprintMutationVariables>;