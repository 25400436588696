import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { EventKindEnum } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Icon, IconVariants } from '~/shared/components/Icon';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { NBSP } from '~/shared/constants';

import { DateFormats, formatDate } from '~/services/dateTime';

import {
  isCowInjection,
  useConfirmRollbackEvent,
  useGetCowEventAdditionalInfo,
} from '~/entities/cowEvents';
import { getEventId } from '~/entities/events';
import { useRollbackEventMutation } from '~/entities/events/gql/mutations/rollbackEvent.graphql';

import {
  CowCardEventsTableEntry,
  CowCardEventsTableEntryEvent,
  CowCardEventsTableEntryLactationNumber,
} from '../../types';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, items for the table are still loading
   */
  isLoading?: boolean;
  /**
   * Table entries to display
   */
  cowEventsTableEntries: CowCardEventsTableEntry[];
}

const DATE_COLUMN_WIDTH_PX = 144;
const EVENT_COLUMN_WIDTH_PX = 182;

const isLactationNumberEntry = (
  item: CowCardEventsTableEntry
): item is CowCardEventsTableEntryLactationNumber => 'lactationNumber' in item;

export const CowCardEventsTable: React.FC<Props> = ({
  className,
  isLoading,
  cowEventsTableEntries,
}) => {
  const [rollbackEvent, { client }] = useRollbackEventMutation();

  const { t } = useTranslation(MaslovNamespaces.enums);

  const confirmRollback = useConfirmRollbackEvent();

  const handleRollbackEvent = useCallback(
    async (tableEntry: CowCardEventsTableEntryEvent) => {
      const hasSeveralEvents =
        !!tableEntry.groupedEvents && tableEntry.groupedEvents.length > 1;

      const isConfirmed = await confirmRollback(
        tableEntry.normalizedEvent,
        hasSeveralEvents
      );

      if (!isConfirmed) return;

      const eventIdsToRollback = hasSeveralEvents
        ? (tableEntry.groupedEvents ?? []).map(R.prop('id'))
        : [tableEntry.event.id];

      const promises = eventIdsToRollback.map(eventId =>
        rollbackEvent({
          variables: {
            id: eventId,
          },
        })
      );

      await Promise.all(promises);
      await client.refetchQueries({ include: ['cowLactationGraphs'] });
    },
    []
  );

  const getCowEventAdditionalInfo = useGetCowEventAdditionalInfo();

  const columnConfigs: TableColumnConfig<CowCardEventsTableEntry>[] = [
    {
      title: 'Дата',
      key: 'happenedAt',
      width: DATE_COLUMN_WIDTH_PX,
      cellClassName: item =>
        isLactationNumberEntry(item)
          ? 'background-gray-90 py-4'
          : 'whitespace-nowrap',
      cellTypographyProps: item => ({
        variant: isLactationNumberEntry(item)
          ? TypographyVariants.descriptionLarge
          : undefined,
      }),
      renderCellContent: item => {
        if (isLactationNumberEntry(item)) {
          return `Лактация${NBSP}${item.lactationNumber}`;
        }
        return formatDate(item.happenedAt, DateFormats.full);
      },
      getColSpan: item =>
        isLactationNumberEntry(item) ? columnConfigs.length : 0,
    },
    {
      title: 'Событие',
      width: EVENT_COLUMN_WIDTH_PX,
      key: 'event',
      renderCellContent: item => {
        if (isLactationNumberEntry(item)) return '';

        let displayedEventKind = item.normalizedEvent.kind;
        let displayedName = item.normalizedEvent.name;
        // For old injections we display them as injections,
        // but new ones are connected to a protocol, so we display them as protocol
        if (isCowInjection(item.event) && !!item.event.cowProtocol) {
          displayedEventKind = EventKindEnum.Protocol;
          displayedName = item.event.cowProtocol.protocol.name;
        }

        return (
          <div className={styles.event}>
            <div
              {...{
                className: styles.circle,
                style: {
                  backgroundColor: item.normalizedEvent.config.color,
                },
              }}
            />
            <Typography variant={TypographyVariants.bodySmall}>
              {displayedName}
            </Typography>
            <Typography
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              {t(`${EnumStrings.eventKinds}${displayedEventKind}`)}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Комментарий',
      key: 'comment',
      width: '1fr',
      renderCellContent: item => {
        if (isLactationNumberEntry(item) || item.isProtocolStop) return '';

        return item.event.comment;
      },
    },
    {
      title: 'Дополнительная информация',
      key: 'additionalInfo',
      width: '1fr',
      cellClassName: 'position-relative',
      renderCellContent: item => {
        if (isLactationNumberEntry(item)) return '';

        const infoElement = getCowEventAdditionalInfo(item.event, item);

        return (
          <Typography variant={TypographyVariants.bodySmall}>
            {infoElement}
            {item.isLastEvent && (
              <div className={clsx('text-muted', styles.deleteButton)}>
                <Icon
                  {...{
                    variant: IconVariants.delete,
                    onPress: () => handleRollbackEvent(item),
                  }}
                />
              </div>
            )}
          </Typography>
        );
      },
    },
  ];

  return (
    <Table<CowCardEventsTableEntry>
      {...{
        theme: TableThemes.tertiary,
        className: clsx(className, 'min-w-full w-min'),
        items: cowEventsTableEntries,
        columnConfigs,
        getItemKey: item =>
          isLactationNumberEntry(item)
            ? item.lactationNumber.toString()
            : `${item.lactationId}__${getEventId(item.event)}__${
                item.isProtocolStop
              }`,
        isLoading,
        noItemsMessage: 'Нет событий для отображения',
      }}
    />
  );
};
