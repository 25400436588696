import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type InjectionFragment = { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean };

export const InjectionFragmentDoc = gql`
    fragment Injection on Injection {
  __typename
  id
  name
  kind
  daysMilkWithholdAfterLastThreatment
  daysMeatWithholdAfterLastThreatment
  cost
  hasProtocol
}
    `;