import { GenealogyRelationKind } from '@graphql-types';

/**
 * Dict with possible relation kinds for genealogy
 */
export const RELATION_KINDS_DICT: Record<GenealogyRelationKind, string> = {
  [GenealogyRelationKind.Mother]: 'Мать (М)',
  [GenealogyRelationKind.Father]: 'Отец (О)',
  [GenealogyRelationKind.MotherOfFather]: 'Мать отца (МО)',
  [GenealogyRelationKind.MotherOfMother]: 'Мать матери (ММ)',
  [GenealogyRelationKind.FatherOfFather]: 'Отец отца (ОО)',
  [GenealogyRelationKind.FatherOfMother]: 'Отец матери (ОМ)',
};
