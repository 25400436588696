import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../penGroups/gql/fragments/penGroupShort.graphql';
export type CowFragment = { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number } };

export const CowFragmentDoc = gql`
    fragment Cow on Cow {
  __typename
  id
  identifier
  name
  state
  previousState
  dateOfBirth
  penGroup {
    ...PenGroupShort
  }
}
    ${PenGroupShortFragmentDoc}`;