import React from 'react';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';

import { SizeVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

import styles from './index.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Icon size, any react css size value
   */
  size?: SizeVariants;
  /**
   * Icon color (default is colorAccentSoft)
   */
  color?: string;
}

export const Loader = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      size = SizeVariants.size20,
      color = TOKENS.colorAccentSoft,
      ...other
    },
    ref
  ) => {
    return (
      <div
        {...{
          className: clsx(styles.root, className),
          ref,
          ...other,
        }}
      >
        <Icon
          {...{
            className: styles.spinner,
            variant: IconVariants.spinner,
            color,
            size,
          }}
        />
      </div>
    );
  }
);
