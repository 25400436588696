import { makeUseModal, ModalNames } from '~/services/modals';

import { LoadTestMilkingCsvModalProps } from './LoadTestMilkingCsvModal';
import { LoadTestMilkingPartialResultsModalProps } from './LoadTestMilkingPartialResultsModal';

export const useLoadTestMilkingCsvModal =
  makeUseModal<LoadTestMilkingCsvModalProps>(
    ModalNames.loadTestMilkingCsvModal
  );

export const useLoadTestMilkingPartialResultsModal =
  makeUseModal<LoadTestMilkingPartialResultsModalProps>(
    ModalNames.loadTestMilkingPartialResultsModal
  );
