import React, { useState } from 'react';

import { createFileRoute, useRouter } from '@tanstack/react-router';
import clsx from 'clsx';

import { BaseLayout } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { LoginStepAuthCodeForm } from './components/LoginStepAuthCodeForm';
import { LoginStepEmailForm } from './components/LoginStepEmailForm';
import styles from './index.module.scss';

export const Route = createFileRoute('/login/')({
  wrapInSuspense: true,
  component: LoginPageWrapper,
});

function LoginPage() {
  const { navigate, basepath } = useRouter();

  const [currentEmail, setCurrentEmail] = useState('');

  return (
    <div
      className={clsx(
        layoutStyles.fullHeightContainer,
        layoutStyles.outbreakContainer,
        'grid place-items-center'
      )}
    >
      <div className="flex flex-col items-center">
        {!currentEmail && (
          <LoginStepEmailForm
            {...{
              className: styles.form,
              onNextStep: setCurrentEmail,
            }}
          />
        )}
        {!!currentEmail && (
          <LoginStepAuthCodeForm
            {...{
              className: styles.form,
              email: currentEmail,
              onPrevStep: () => setCurrentEmail(''),
              onLogin: () =>
                // Should wrap in macrotask to let rerender component tree
                // with new context isAuthenticated value
                setTimeout(
                  () =>
                    navigate({
                      to: basepath,
                      state: { data: { redirectTo: '/select-company' } },
                    }),
                  0
                ),
            }}
          />
        )}
      </div>
    </div>
  );
}

function LoginPageWrapper() {
  return (
    <BaseLayout withMenu={false}>
      <LoginPage />
    </BaseLayout>
  );
}
