import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ExportJobFragmentDoc } from '../fragments/exportJob.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportCompanyToFileMutationVariables = Types.Exact<{
  input: Types.ExportFileInput;
}>;


export type ExportCompanyToFileMutation = { __typename?: 'Mutation', exportCompanyToFile: { __typename: 'ExportJob', id: string, status: Types.ExportJobStatus, file?: { __typename?: 'File', downloadUrl: string, name: string, extension: string } | null } };


export const ExportCompanyToFileDocument = gql`
    mutation exportCompanyToFile($input: ExportFileInput!) {
  exportCompanyToFile(input: $input) {
    ...ExportJob
  }
}
    ${ExportJobFragmentDoc}`;
export type ExportCompanyToFileMutationFn = Apollo.MutationFunction<ExportCompanyToFileMutation, ExportCompanyToFileMutationVariables>;

/**
 * __useExportCompanyToFileMutation__
 *
 * To run a mutation, you first call `useExportCompanyToFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCompanyToFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCompanyToFileMutation, { data, loading, error }] = useExportCompanyToFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCompanyToFileMutation(baseOptions?: Apollo.MutationHookOptions<ExportCompanyToFileMutation, ExportCompanyToFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCompanyToFileMutation, ExportCompanyToFileMutationVariables>(ExportCompanyToFileDocument, options);
      }
export type ExportCompanyToFileMutationHookResult = ReturnType<typeof useExportCompanyToFileMutation>;
export type ExportCompanyToFileMutationResult = Apollo.MutationResult<ExportCompanyToFileMutation>;
export type ExportCompanyToFileMutationOptions = Apollo.BaseMutationOptions<ExportCompanyToFileMutation, ExportCompanyToFileMutationVariables>;