import React from 'react';

import {
  BreedingValueTypeEnum,
  HerriotIntegrationEntityKindEnum,
  MarkingMeanEnum,
} from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';
import * as yup from 'yup';

import { Input } from '~/shared/components/Input';
import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { renderOrMdash } from '~/shared/helpers/render';
import { oneOfEnum } from '~/shared/helpers/yup';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { CustomAppHeaders, getNumberPartFromGlobalId } from '~/services/gql';
import { InjectedModalProps, Modal, useConfirm } from '~/services/modals';
import {
  Callout,
  CalloutSizes,
  NotificationVariants,
  useNotifications,
} from '~/services/notifications';

import formStyles from '~/styles/modules/form.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { HerriotIntegrationEntityAsyncSelect } from '../../components';
import { HerriotIntegrationSettingsFragment } from '../../gql/fragments/herriotIntegrationSettings.graphql';
import { useSetHerriotIntegrationSettingsMutation } from '../../gql/mutations/setHerriotIntegrationSettings.graphql';
import { useUnsetHerriotIntegrationSettingsMutation } from '../../gql/mutations/unsetHerriotIntegrationSettings.graphql';
import {
  readHerriotIntegrationEntityFragment,
  updateCompanyDetailedFragment,
} from '../../helpers';
import { useDetailedCompany } from '../../hooks';
import { AnyCompanyFragment } from '../../types';
import { BREEDING_VALUE_TYPES_DICT, MARKING_MEAN_DICT } from './constants';

export interface SetHerriotSettingsModalProps
  extends InjectedModalProps<SetHerriotSettingsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Company to edit herriot settings
   */
  company: AnyCompanyFragment;
}

const useBreedingValueTypeSelect = makeUseEnumSelect(
  BreedingValueTypeEnum,
  enumValue => BREEDING_VALUE_TYPES_DICT[enumValue as BreedingValueTypeEnum]
);
const BreedingValueTypeSelect = makeSelectComponentFromHook(
  useBreedingValueTypeSelect
);

const useMarkingMeanSelect = makeUseEnumSelect(
  MarkingMeanEnum,
  enumValue => MARKING_MEAN_DICT[enumValue as MarkingMeanEnum]
);
const MarkingMeanSelect = makeSelectComponentFromHook(useMarkingMeanSelect);

const FORM_ID = 'SetHerriotSettingsForm';

const SCHEMA = yup.object({
  supervisedObjectGuid: yup.string().default('').required().guid(),
  apiKey: yup.string().default('').required(),
  issuerID: yup.string().default('').required().guid(),
  apiLogin: yup.string().default('').required(),
  apiPassword: yup.string().default('').required(),
  initiatorLogin: yup.string().default('').required(),

  keepingPurposeGuid: yup.string().required().guid(),
  keepingTypeGuid: yup.string().required().guid(),
  breedingValueType: oneOfEnum(BreedingValueTypeEnum)
    .required()
    .default(BreedingValueTypeEnum.Breeding),
  markingMeans: oneOfEnum(MarkingMeanEnum)
    .required()
    .default(MarkingMeanEnum.Label),
  markingAttachmentLocationGuid: yup.string().required().guid(),
});

type SetHerriotSettingsFormType = InferValidatedSchema<typeof SCHEMA>;

export const SetHerriotSettingsModal: React.FC<
  SetHerriotSettingsModalProps
> = ({ className, company, close }) => {
  const { sendSuccessToast, sendNeutralToast } = useNotifications();

  const [
    setHerriotIntegrationSettings,
    { client, loading: isSetHerriotIntegrationSettingsLoading },
  ] = useSetHerriotIntegrationSettingsMutation();
  const [
    unsetHerriotIntegrationSettingsMutation,
    { loading: isUnsetHerriotIntegrationSettingsLoading },
  ] = useUnsetHerriotIntegrationSettingsMutation();

  const { isDetailedCompanyLoading, companyDetailed, companyDetailedPromise } =
    useDetailedCompany(company);

  const formContext = useForm<SetHerriotSettingsFormType>({
    schema: SCHEMA,

    defaultValues: () =>
      companyDetailedPromise.then(detailedCompany => ({
        ...SCHEMA.getDefault(),
        ...R.pick(
          [
            'supervisedObjectGuid',
            'apiKey',
            'issuerID',
            'apiLogin',
            'initiatorLogin',
            'breedingValueType',
            'markingMeans',
          ],
          detailedCompany?.herriotIntegrationSettings ??
            ({} as HerriotIntegrationSettingsFragment)
        ),
        keepingPurposeGuid:
          detailedCompany?.herriotIntegrationSettings?.keepingPurpose.guid ??
          '',
        keepingTypeGuid:
          detailedCompany?.herriotIntegrationSettings?.keepingType.guid ?? '',
        markingAttachmentLocationGuid:
          detailedCompany?.herriotIntegrationSettings?.markingAttachmentLocation
            .guid ?? '',
      })),
  });

  const handleSubmit = (input: SetHerriotSettingsFormType) => {
    setHerriotIntegrationSettings({
      variables: {
        companyID: company.id,
        input,
      },
      context: {
        headers: {
          [CustomAppHeaders.activeCompany]: getNumberPartFromGlobalId(
            company.id
          ),
        },
      },
      optimisticResponse: { setHerriotIntegrationSettings: null },
      update: updateCompanyDetailedFragment(company.id, draft => {
        draft.isHerriotIntegrationSet = true;

        const keepingPurpose = readHerriotIntegrationEntityFragment(
          client,
          input.keepingPurposeGuid
        );
        const keepingType = readHerriotIntegrationEntityFragment(
          client,
          input.keepingTypeGuid
        );
        const markingAttachmentLocation = readHerriotIntegrationEntityFragment(
          client,
          input.markingAttachmentLocationGuid
        );

        if (!keepingPurpose || !keepingType || !markingAttachmentLocation) {
          return;
        }

        draft.herriotIntegrationSettings = {
          __typename: 'HerriotIntegrationSettings',
          apiKey: input.apiKey,
          apiLogin: input.apiLogin,
          issuerID: input.issuerID,
          breedingValueType: input.breedingValueType,
          markingMeans: input.markingMeans,
          supervisedObjectGuid: input.supervisedObjectGuid,
          initiatorLogin: input.initiatorLogin,
          keepingPurpose,
          keepingType,
          markingAttachmentLocation,
        };
      }),
    }).then(() => {
      sendSuccessToast('Передача данных в систему Хорриот настроена');
      close();
    });
  };

  const confirmUnsetSettings = useConfirm();
  const unsetHerriotIntegrationSettings = () =>
    confirmUnsetSettings({
      title: 'Разрыв связи с системой Хорриот',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите разорвать связь с системой Хорриот? Автоматическая
            передача данных будет прекращена.
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Интеграцию можно настроить в любое время, нажав на иконку статуса
            связи с системой Хорриот на карточке компании
          </Typography>
        </div>
      ),
      isDelete: true,
    }).then(isConfirmed => {
      if (!isConfirmed) return;

      unsetHerriotIntegrationSettingsMutation({
        variables: {
          companyID: company.id,
        },
        context: {
          headers: {
            [CustomAppHeaders.activeCompany]: getNumberPartFromGlobalId(
              company.id
            ),
          },
        },
        optimisticResponse: { unsetHerriotIntegrationSettings: null },
        update: updateCompanyDetailedFragment(company.id, draft => {
          draft.isHerriotIntegrationSet = false;
          draft.herriotIntegrationSettings = null;
        }),
      });

      formContext.reset(SCHEMA.getDefault());
      sendNeutralToast('Передача данных в систему Хорриот прекращена ');
    });

  const renderIntegrationInfoBlock = (
    label: string,
    value: string | undefined
  ) =>
    renderOrMdash(
      !!value && (
        <div className="grid gap-2">
          <Typography
            className="text-soft"
            variant={TypographyVariants.bodySmall}
          >
            {label}
          </Typography>
          <Typography variant={TypographyVariants.bodyMedium}>
            {value}
          </Typography>
        </div>
      )
    );

  const isHerriotIntegrationSet = !!companyDetailed?.isHerriotIntegrationSet;
  const herriotIntegrationSettings =
    companyDetailed?.herriotIntegrationSettings;

  return (
    <Modal
      {...{
        className,
        title: 'Интеграция с системой Хорриот',
        shouldShowSubmitButton: !isHerriotIntegrationSet,
        submitButtonProps: {
          form: FORM_ID,
        },
        cancelButtonProps: {
          children: isHerriotIntegrationSet ? 'Закрыть' : undefined,
        },
        isLoading: isDetailedCompanyLoading,
        isRequireExplicitClosing: isHerriotIntegrationSet
          ? false
          : formContext.formState.isDirty,
        floatingFocusManagerProps: {
          disabled:
            isDetailedCompanyLoading ||
            isSetHerriotIntegrationSettingsLoading ||
            isUnsetHerriotIntegrationSettingsLoading,
        },
      }}
    >
      {companyDetailed && (
        <>
          {isHerriotIntegrationSet &&
            !isSetHerriotIntegrationSettingsLoading && (
              <>
                <Callout
                  {...{
                    className: 'mb-24',
                    message: 'Передача данных настроена',
                    size: CalloutSizes.small8,
                    variant: NotificationVariants.success,
                    functionButtonProps: {
                      children: 'Разорвать связь',
                      onPress: unsetHerriotIntegrationSettings,
                    },
                  }}
                />
                <div
                  className={clsx(panelStyles.darkPanel, 'grid gap-16 p-16')}
                >
                  <Typography
                    tag="h3"
                    variant={TypographyVariants.bodyMediumStrong}
                  >
                    Основная информация
                  </Typography>
                  {renderIntegrationInfoBlock(
                    'GUID подконтрольного объекта',
                    herriotIntegrationSettings?.supervisedObjectGuid
                  )}
                  {renderIntegrationInfoBlock(
                    'Идентификатор в реестре РСХН',
                    herriotIntegrationSettings?.issuerID
                  )}
                  {renderIntegrationInfoBlock(
                    'Логин ветврача',
                    herriotIntegrationSettings?.initiatorLogin
                  )}
                  {renderIntegrationInfoBlock(
                    'Тип средства маркировки',
                    renderOrMdash(
                      herriotIntegrationSettings?.markingMeans &&
                        MARKING_MEAN_DICT[
                          herriotIntegrationSettings.markingMeans
                        ]
                    )
                  )}
                  {renderIntegrationInfoBlock(
                    'Место нанесения маркировки',
                    herriotIntegrationSettings?.markingAttachmentLocation.name
                  )}
                </div>
              </>
            )}

          {(!isHerriotIntegrationSet ||
            isSetHerriotIntegrationSettingsLoading) && (
            <>
              <Callout
                size={CalloutSizes.small8}
                message="Передача данных в систему Хорриот не настроена. Чтобы это сделать, заполните все поля и нажмите «Подтвердить»"
              />
              <Form
                {...{
                  formContext,
                  id: FORM_ID,
                  onSubmit: formContext.handleSubmit(handleSubmit),
                }}
              >
                <div className="grid gap-16 my-24">
                  <Typography tag="h3" variant={TypographyVariants.heading4}>
                    Информация для подключения к API
                  </Typography>
                  <Input
                    {...{
                      name: 'supervisedObjectGuid',
                      label: 'GUID подконтрольного объекта',
                    }}
                  />
                  <Input
                    {...{
                      name: 'apiKey',
                      label: 'Ключ API',
                    }}
                  />
                  <Input
                    {...{
                      name: 'issuerID',
                      label: 'Идентификатор в реестре РСХН',
                    }}
                  />
                  <div className={formStyles.twoColumnForm}>
                    <Input
                      {...{
                        name: 'apiLogin',
                        label: 'Логин от API',
                      }}
                    />
                    <Input
                      {...{
                        name: 'apiPassword',
                        label: 'Пароль от API ',
                      }}
                    />
                  </div>
                  <Input
                    {...{
                      name: 'initiatorLogin',
                      label: 'Логин ветврача',
                    }}
                  />
                </div>
                <div className="grid gap-16">
                  <Typography tag="h3" variant={TypographyVariants.heading4}>
                    Информация о содержании животных и их маркировке
                  </Typography>
                  <HerriotIntegrationEntityAsyncSelect
                    {...{
                      name: 'keepingPurposeGuid',
                      label: 'Цель содержания',
                      queryOptions: {
                        variables: {
                          kinds: [
                            HerriotIntegrationEntityKindEnum.KeepingPurpose,
                          ],
                        },
                      },
                    }}
                  />
                  <HerriotIntegrationEntityAsyncSelect
                    {...{
                      name: 'keepingTypeGuid',
                      label: 'Тип содержания',
                      queryOptions: {
                        variables: {
                          kinds: [HerriotIntegrationEntityKindEnum.KeepingType],
                        },
                      },
                    }}
                  />
                  <BreedingValueTypeSelect
                    {...{
                      name: 'breedingValueType',
                      label: 'Племенная ценность',
                    }}
                  />
                  <MarkingMeanSelect
                    {...{
                      name: 'markingMeans',
                      label: 'Тип средства маркировки',
                    }}
                  />
                  <HerriotIntegrationEntityAsyncSelect
                    {...{
                      name: 'markingAttachmentLocationGuid',
                      label: 'Место нанесения маркировки',
                      queryOptions: {
                        variables: {
                          kinds: [
                            HerriotIntegrationEntityKindEnum.MarkingAttachmentLocation,
                          ],
                        },
                      },
                    }}
                  />
                </div>
              </Form>
            </>
          )}
        </>
      )}
    </Modal>
  );
};
