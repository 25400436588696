import React from 'react';

import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { MEditToggle } from '~/shared/components/MEditToggle';

export const StringView: React.FC<ValueEditorStandardProps<string>> = props => {
  const { toggle, value, hideControls, caption } = props;

  const controls = !hideControls ? <MEditToggle toggle={toggle} /> : null;

  return (
    <div className="m-editor">
      <div>
        <i>{caption}</i>&nbsp;{value}
      </div>

      {controls}
    </div>
  );
};
