import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionHdrAndPrReportFragmentDoc } from '../fragments/reproductionHdrAndPrReport.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReproductionHdrAndPrReportMutationVariables = Types.Exact<{
  input: Types.CreateReproductionHdrAndPrReportInput;
}>;


export type CreateReproductionHdrAndPrReportMutation = { __typename?: 'Mutation', createReproductionHdrAndPrReport: { __typename: 'ReproductionHdrAndPrReport', id: string, name: string, roles: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> } };


export const CreateReproductionHdrAndPrReportDocument = gql`
    mutation createReproductionHdrAndPrReport($input: CreateReproductionHdrAndPrReportInput!) {
  createReproductionHdrAndPrReport(input: $input) {
    ...ReproductionHdrAndPrReport
  }
}
    ${ReproductionHdrAndPrReportFragmentDoc}`;
export type CreateReproductionHdrAndPrReportMutationFn = Apollo.MutationFunction<CreateReproductionHdrAndPrReportMutation, CreateReproductionHdrAndPrReportMutationVariables>;

/**
 * __useCreateReproductionHdrAndPrReportMutation__
 *
 * To run a mutation, you first call `useCreateReproductionHdrAndPrReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReproductionHdrAndPrReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReproductionHdrAndPrReportMutation, { data, loading, error }] = useCreateReproductionHdrAndPrReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReproductionHdrAndPrReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReproductionHdrAndPrReportMutation, CreateReproductionHdrAndPrReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReproductionHdrAndPrReportMutation, CreateReproductionHdrAndPrReportMutationVariables>(CreateReproductionHdrAndPrReportDocument, options);
      }
export type CreateReproductionHdrAndPrReportMutationHookResult = ReturnType<typeof useCreateReproductionHdrAndPrReportMutation>;
export type CreateReproductionHdrAndPrReportMutationResult = Apollo.MutationResult<CreateReproductionHdrAndPrReportMutation>;
export type CreateReproductionHdrAndPrReportMutationOptions = Apollo.BaseMutationOptions<CreateReproductionHdrAndPrReportMutation, CreateReproductionHdrAndPrReportMutationVariables>;