import { BlueprintExecutionInfo } from '~/~legacy/services/BlueprintExecutionService';

export interface BlueprintSpecialInputProps {
  executionInfo: BlueprintExecutionInfo;
  errors: string[];
  start: (executionInfo: BlueprintExecutionInfo) => Promise<void>;
  close: () => void;
}

export enum Gender {
  male = 'male',
  female = 'female',
}
