import { BlueprintRoleColor } from '@graphql-types';
import R from 'ramda';

/**
 * Helper to get the next default the least used role color
 */
export const getNextRoleColor = (
  usedColors: BlueprintRoleColor[]
): BlueprintRoleColor => {
  const notUsedColors = Object.values(BlueprintRoleColor).filter(
    color => !usedColors.includes(color)
  );

  if (notUsedColors.length > 0) {
    return notUsedColors[0];
  }

  const groupedUsedColors = R.groupBy(R.identity<BlueprintRoleColor>)(
    usedColors
  );
  const reversedGroupUsedColorValues =
    Object.values(groupedUsedColors).reverse();

  const rarestUsedColorGroup = R.reduce<
    BlueprintRoleColor[],
    BlueprintRoleColor[]
  >(
    R.minBy(colorGroup => colorGroup.length),
    reversedGroupUsedColorValues[0],
    R.tail(reversedGroupUsedColorValues)
  );

  return rarestUsedColorGroup[0];
};
