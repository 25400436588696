import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SemenDoseFragmentDoc } from '../fragments/semenDose.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SemenDosesQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SemenDosesQuery = { __typename?: 'Query', semenDoses: { __typename?: 'SemenDoseConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'SemenDoseEdge', cursor: string, node: { __typename: 'SemenDose', id: string, deliveryDate?: string | null, studCode: string, dosesCount: number, batchNumber: string, bull: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } } }> } };


export const SemenDosesDocument = gql`
    query semenDoses($search: String, $first: Int, $after: String) {
  semenDoses(first: $first, search: $search, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...SemenDose
      }
    }
  }
}
    ${SemenDoseFragmentDoc}`;

/**
 * __useSemenDosesQuery__
 *
 * To run a query within a React component, call `useSemenDosesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSemenDosesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSemenDosesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSemenDosesQuery(baseOptions?: Apollo.QueryHookOptions<SemenDosesQuery, SemenDosesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SemenDosesQuery, SemenDosesQueryVariables>(SemenDosesDocument, options);
      }
export function useSemenDosesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SemenDosesQuery, SemenDosesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SemenDosesQuery, SemenDosesQueryVariables>(SemenDosesDocument, options);
        }
export function useSemenDosesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SemenDosesQuery, SemenDosesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SemenDosesQuery, SemenDosesQueryVariables>(SemenDosesDocument, options);
        }
export type SemenDosesQueryHookResult = ReturnType<typeof useSemenDosesQuery>;
export type SemenDosesLazyQueryHookResult = ReturnType<typeof useSemenDosesLazyQuery>;
export type SemenDosesSuspenseQueryHookResult = ReturnType<typeof useSemenDosesSuspenseQuery>;
export type SemenDosesQueryResult = Apollo.QueryResult<SemenDosesQuery, SemenDosesQueryVariables>;