import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type MilkingParlorFlowRateReportGroupedRowEntryFragment = { __typename: 'MilkingParlorFlowRateReportGroupedRowEntry', cowStallPerHour?: number | null, flowRate0To15Sec?: number | null, flowRate15To30Sec?: number | null, flowRate30To60Sec?: number | null, peakFlowRate?: number | null, milkWeight0To120Sec?: number | null, milkWeightPercent0To120Sec?: number | null };

export const MilkingParlorFlowRateReportGroupedRowEntryFragmentDoc = gql`
    fragment MilkingParlorFlowRateReportGroupedRowEntry on MilkingParlorFlowRateReportGroupedRowEntry {
  __typename
  cowStallPerHour
  flowRate0To15Sec
  flowRate15To30Sec
  flowRate30To60Sec
  peakFlowRate
  milkWeight0To120Sec
  milkWeightPercent0To120Sec
}
    `;