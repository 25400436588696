import React from 'react';

import R from 'ramda';

import { ButtonVariants } from '~/shared/components/Button';
import {
  isSkeletonPlaceholder,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { formatDateRange } from '~/services/dateTime';

import { ReproductionCrReportDetailedFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrReportDetailed.graphql';
import { useCalculateReproductionCrReportMutation } from '~/entities/reproductionCrReports/gql/mutations/calculateReproductionCrReport.graphql';
import { useSetReproductionCrReportSettingsMutation } from '~/entities/reproductionCrReports/gql/mutations/setReproductionCrReportSettings.graphql';

import {
  ReportCardBuilderCard,
  useReportCardBuilder,
} from '~/features/reportCardBuilder';

import {
  FEMALE_ANIMAL_KINDS_DICT,
  REPRODUCTION_CR_REPORT_SETTINGS_FORM_ID,
} from '../../constants';
import { mapReproductionCrReportSettingsToForm } from '../../helpers';
import { useFarmWithReproductionSettingsFromCacheOrQuery } from '../../hooks';
import { ReproductionCrReportSettingsFormType } from '../../types';
import { ReproductionCrReportData } from '../ReproductionCrReportData';
import { ReproductionCrReportSettingsForm } from '../ReproductionCrReportSettingsForm';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to take settings from
   */
  reproductionCrReport:
    | ReproductionCrReportDetailedFragment
    | SkeletonPlaceholder;
}

export const ReproductionCrReportCard: React.FC<Props> = ({
  className,
  reproductionCrReport,
}) => {
  const [
    setReproductionCrReportSettings,
    { loading: isSetReproductionCrReportSettingsLoading },
  ] = useSetReproductionCrReportSettingsMutation();

  const [
    calculateReproductionCrReport,
    { reset: resetCalculateReproductionCrReportMutation },
  ] = useCalculateReproductionCrReportMutation();

  const mapCustomReportSettingsFormToBackendInput = (
    settingsForm: ReproductionCrReportSettingsFormType
  ) => ({
    ...R.omit(['period'], settingsForm),
    ...settingsForm.period.interval,
  });

  const {
    currentReportData,

    initialSettings,
    requestedSettings,

    setCurrentReportData,

    customReportCardProps,
    customReportSettingsFormProps,
  } = useReportCardBuilder({
    reportDeps: [reproductionCrReport],

    getReportData: ([reproductionCrReportDep]) =>
      reproductionCrReportDep.calculatedReport,
    getReportSettings: ([reproductionCrReportDep]) =>
      reproductionCrReportDep.settings,
    mapReportSettingsToForm: mapReproductionCrReportSettingsToForm,

    emptyReportData: {
      __typename: 'ReproductionCrCalculatedReportEmpty',
      xAxisValues: [],
      groupByValues: [],
    },

    calculateReport: (settingsFormValues, mutationContext) =>
      calculateReproductionCrReport({
        variables: {
          id: reproductionCrReport.id,
          settings:
            mapCustomReportSettingsFormToBackendInput(settingsFormValues),
        },
        context: mutationContext,
      }).then(({ data }) => {
        if (data?.calculateReproductionCrReport.__typename) {
          setCurrentReportData(data.calculateReproductionCrReport);
        }
      }),

    setReportSettings: settingsForm =>
      setReproductionCrReportSettings({
        variables: {
          id: reproductionCrReport.id,
          input: mapCustomReportSettingsFormToBackendInput(settingsForm),
        },
      }),

    onCardEditCancel: resetCalculateReproductionCrReportMutation,
  });

  const { fragment: currentFarm } =
    useFarmWithReproductionSettingsFromCacheOrQuery(requestedSettings.farmID);

  const formattedFarm = currentFarm?.name;
  const formattedReportRange = formatDateRange(
    initialSettings?.since,
    initialSettings?.till
  );
  const formattedFemaleAnimalKind =
    initialSettings &&
    FEMALE_ANIMAL_KINDS_DICT[initialSettings.femaleAnimalKind];
  const reportTitle = [
    formattedFarm,
    formattedReportRange,
    formattedFemaleAnimalKind,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <ReportCardBuilderCard
      {...{
        className,
        title: reportTitle,
        updatingTitle: 'Настройки графика и таблицы',
        ...customReportCardProps,
        settingsButtonProps: {
          variant: ButtonVariants.primary,
          children: 'Настройки',
        },
        submitButtonProps: {
          form: REPRODUCTION_CR_REPORT_SETTINGS_FORM_ID,
          isLoading: isSetReproductionCrReportSettingsLoading,
        },
        renderEditingContent: () =>
          !isSkeletonPlaceholder(reproductionCrReport) && (
            <ReproductionCrReportSettingsForm
              {...{
                reproductionCrReport,
                currentReportData,
                ...customReportSettingsFormProps,
              }}
            />
          ),
      }}
    >
      <Skeleton isLoading={isSkeletonPlaceholder(currentReportData)}>
        <ReproductionCrReportData
          {...{
            reportData: currentReportData,
            requestedSettings,
          }}
        />
      </Skeleton>
    </ReportCardBuilderCard>
  );
};
