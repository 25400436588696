import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { PenGroupFragment } from '../gql/fragments/penGroup.graphql';
import {
  PenGroupsQuery,
  PenGroupsQueryVariables,
  usePenGroupsQuery,
} from '../gql/queries/penGroups.graphql';

export const usePenGroupsPaginatedQuery = makeUsePaginatedQuery<
  PenGroupFragment,
  PenGroupsQuery,
  PenGroupsQueryVariables
>({
  useQuery: usePenGroupsQuery,
  getItemsFromQueryData: data => data.penGroups.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.penGroups.pageInfo,
});
