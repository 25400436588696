import React from 'react';

import { createRouter } from '@tanstack/react-router';

import { Loader } from '~/shared/components/Loader';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { routeTree } from './__generated__/routeTree.gen';

export const router = createRouter({
  routeTree,
  notFoundMode: 'root',
  defaultPendingComponent: () => (
    <Loader className={layoutStyles.fullHeightContainer} />
  ),
  context: {
    auth: {
      isAuthenticated: false,
    },
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }

  interface HistoryState {
    data?: Record<string, unknown>;
  }
}
