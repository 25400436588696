import React from 'react';

import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { DateEdit } from './DateEdit';
import { DateView } from './DateView';

import './DateEditor.scss';

export const DateEditor: React.FC<ValueEditorArgs<string>> = props => {
  const Component = EditorHoC(DateView, DateEdit);

  return <Component {...props} />;
};
