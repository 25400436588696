import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type MilkingParlorIntervalDetailedFragment = { __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string, penGroups: { __typename?: 'MilkingParlorIntervalPenGroupsConnection', edges: Array<{ __typename?: 'MilkingParlorIntervalPenGroupEdge', milkingNumber?: number | null, node: { __typename: 'PenGroup', id: string, name: string, identifier: number } }> } };

export const MilkingParlorIntervalDetailedFragmentDoc = gql`
    fragment MilkingParlorIntervalDetailed on MilkingParlorInterval {
  __typename
  id
  intervalStart
  intervalEnd
  penGroups {
    edges {
      milkingNumber
      node {
        ...PenGroupShort
      }
    }
  }
}
    ${PenGroupShortFragmentDoc}`;