import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { ReproductionHdrAndPrReportFragment } from '../gql/fragments/reproductionHdrAndPrReport.graphql';
import {
  ReproductionHdrAndPrReportsQuery,
  ReproductionHdrAndPrReportsQueryVariables,
  useReproductionHdrAndPrReportsQuery,
} from '../gql/queries/reproductionHdrAndPrReports.graphql';

export const useReproductionHdrAndPrReportsPaginatedQuery =
  makeUsePaginatedQuery<
    ReproductionHdrAndPrReportFragment,
    ReproductionHdrAndPrReportsQuery,
    ReproductionHdrAndPrReportsQueryVariables
  >({
    useQuery: useReproductionHdrAndPrReportsQuery,
    getItemsFromQueryData: data =>
      data.reproductionHdrAndPrReports.edges.map(R.prop('node')),
    getPageInfoFromQueryData: data => data.reproductionHdrAndPrReports.pageInfo,
  });
