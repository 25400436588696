import React from 'react';

export const Calendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 0.25C15.9142 0.25 16.25 0.585786 16.25 1V2.25H17C19.6142 2.25 21.75 4.38579 21.75 7V17C21.75 19.6142 19.6142 21.75 17 21.75H7C4.38579 21.75 2.25 19.6142 2.25 17V7C2.25 4.38579 4.38579 2.25 7 2.25H7.75V1C7.75 0.585786 8.08579 0.25 8.5 0.25C8.91421 0.25 9.25 0.585786 9.25 1V2.25H14.75V1C14.75 0.585786 15.0858 0.25 15.5 0.25ZM7.75 3.75V5C7.75 5.41421 8.08579 5.75 8.5 5.75C8.91421 5.75 9.25 5.41421 9.25 5V3.75H14.75V5C14.75 5.41421 15.0858 5.75 15.5 5.75C15.9142 5.75 16.25 5.41421 16.25 5V3.75H17C18.7858 3.75 20.25 5.21421 20.25 7V8.25H3.75V7C3.75 5.21421 5.21421 3.75 7 3.75H7.75ZM20.25 9.75V17C20.25 18.7858 18.7858 20.25 17 20.25H7C5.21421 20.25 3.75 18.7858 3.75 17V9.75H20.25Z"
      fill="currentColor"
    />
  </svg>
);
