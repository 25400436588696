import * as Types from '../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CalendarDividerFragment = { __typename: 'CalendarDivider', date: string };

export const CalendarDividerFragmentDoc = gql`
    fragment CalendarDivider on CalendarDivider {
  __typename
  date
}
    `;