import React from 'react';

import { InseminationResult } from '@graphql-types';

import { Badge, BadgeSizes } from '~/shared/components/Badge';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import { INSEMINATION_RESULTS_DICT } from '../../constants';

const INSEMINATION_RESULT_BADGE_COLORS_DICT: Record<
  InseminationResult,
  ColorVariants
> = {
  [InseminationResult.Null]: ColorVariants.warning,
  [InseminationResult.Conceive]: ColorVariants.status02,
  [InseminationResult.Rebred]: ColorVariants.status02,
  [InseminationResult.Open]: ColorVariants.status02,
  [InseminationResult.Estimate]: ColorVariants.success,
  [InseminationResult.Preg]: ColorVariants.success,
  [InseminationResult.Abort]: ColorVariants.neutral,
};

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Insemination result to display
   */
  inseminationResult: InseminationResult;
}

export const InseminationResultBadge: React.FC<Props> = ({
  className,
  inseminationResult,
}) => {
  return (
    <Badge
      {...{
        className,
        size: BadgeSizes.small16,
        color: INSEMINATION_RESULT_BADGE_COLORS_DICT[inseminationResult],
      }}
    >
      {INSEMINATION_RESULTS_DICT[inseminationResult]}
    </Badge>
  );
};
