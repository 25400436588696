import React from 'react';

import clsx from 'clsx';

import { TypographyVariants } from '~/styles/__generated__/token-variants';

import { SkeletonBlock, SkeletonDimensionsProps } from '../SkeletonBlock';

/**
 * Available skeleton text sizes
 */
export enum TextSkeletonSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

// TODO remove, when we work out all typography sizes and remove the old ones
const DUMMY_SIZE = 0;
const DUMMY_SIZES_DICT = {
  [TextSkeletonSizes.small]: DUMMY_SIZE,
  [TextSkeletonSizes.medium]: DUMMY_SIZE,
  [TextSkeletonSizes.large]: DUMMY_SIZE,
};

const SKELETON_TEXT_HEIGHT: Record<TypographyVariants, number> = {
  [TypographyVariants.bodyMedium]: DUMMY_SIZE,
  [TypographyVariants.bodyLarge]: DUMMY_SIZE,
  [TypographyVariants.descriptionMedium]: DUMMY_SIZE,
  [TypographyVariants.descriptionMediumStrong]: DUMMY_SIZE,
  [TypographyVariants.descriptionSmall]: DUMMY_SIZE,
  [TypographyVariants.descriptionSmallStrong]: DUMMY_SIZE,
  [TypographyVariants.displayLarge]: DUMMY_SIZE,
  [TypographyVariants.displayLargeStrong]: DUMMY_SIZE,
  [TypographyVariants.displayMedium]: DUMMY_SIZE,
  [TypographyVariants.displayMediumStrong]: DUMMY_SIZE,
  [TypographyVariants.displaySmall]: DUMMY_SIZE,
  [TypographyVariants.displaySmallStrong]: DUMMY_SIZE,
  [TypographyVariants.heading1]: DUMMY_SIZE,
  [TypographyVariants.heading3]: DUMMY_SIZE,
  [TypographyVariants.heading4]: DUMMY_SIZE,
  [TypographyVariants.heading5]: DUMMY_SIZE,

  [TypographyVariants.heading2]: 20,
  [TypographyVariants.bodySmall]: 12,
  [TypographyVariants.bodySmallStrong]: 16,
  [TypographyVariants.bodyMediumStrong]: 20,
  [TypographyVariants.bodyLargeStrong]: 24,
  [TypographyVariants.descriptionLarge]: 8,
  [TypographyVariants.descriptionLargeStrong]: 8,
};

const SKELETON_TEXT_WIDTH: Record<
  TypographyVariants,
  Record<TextSkeletonSizes, number>
> = {
  [TypographyVariants.bodyMedium]: DUMMY_SIZES_DICT,
  [TypographyVariants.bodyLarge]: DUMMY_SIZES_DICT,
  [TypographyVariants.descriptionMedium]: DUMMY_SIZES_DICT,
  [TypographyVariants.descriptionMediumStrong]: DUMMY_SIZES_DICT,
  [TypographyVariants.descriptionSmall]: DUMMY_SIZES_DICT,
  [TypographyVariants.descriptionSmallStrong]: DUMMY_SIZES_DICT,
  [TypographyVariants.displayLarge]: DUMMY_SIZES_DICT,
  [TypographyVariants.displayLargeStrong]: DUMMY_SIZES_DICT,
  [TypographyVariants.displayMedium]: DUMMY_SIZES_DICT,
  [TypographyVariants.displayMediumStrong]: DUMMY_SIZES_DICT,
  [TypographyVariants.displaySmallStrong]: DUMMY_SIZES_DICT,
  [TypographyVariants.displaySmall]: DUMMY_SIZES_DICT,
  [TypographyVariants.heading1]: DUMMY_SIZES_DICT,
  [TypographyVariants.heading3]: DUMMY_SIZES_DICT,
  [TypographyVariants.heading4]: DUMMY_SIZES_DICT,
  [TypographyVariants.heading5]: DUMMY_SIZES_DICT,

  [TypographyVariants.heading2]: {
    [TextSkeletonSizes.small]: DUMMY_SIZE,
    [TextSkeletonSizes.medium]: 230,
    [TextSkeletonSizes.large]: DUMMY_SIZE,
  },
  [TypographyVariants.bodySmall]: {
    [TextSkeletonSizes.small]: 36,
    [TextSkeletonSizes.medium]: 72,
    [TextSkeletonSizes.large]: 320,
  },
  [TypographyVariants.bodySmallStrong]: {
    [TextSkeletonSizes.small]: 48,
    [TextSkeletonSizes.medium]: 100,
    [TextSkeletonSizes.large]: 180,
  },
  [TypographyVariants.bodyMediumStrong]: {
    [TextSkeletonSizes.small]: DUMMY_SIZE,
    [TextSkeletonSizes.medium]: 180,
    [TextSkeletonSizes.large]: DUMMY_SIZE,
  },
  [TypographyVariants.bodyLargeStrong]: {
    [TextSkeletonSizes.small]: DUMMY_SIZE,
    [TextSkeletonSizes.medium]: 180,
    [TextSkeletonSizes.large]: DUMMY_SIZE,
  },
  [TypographyVariants.descriptionLarge]: {
    [TextSkeletonSizes.small]: 36,
    [TextSkeletonSizes.medium]: 72,
    [TextSkeletonSizes.large]: 124,
  },
  [TypographyVariants.descriptionLargeStrong]: {
    [TextSkeletonSizes.small]: 48,
    [TextSkeletonSizes.medium]: 120,
    [TextSkeletonSizes.large]: 180,
  },
};

export interface SkeletonTextProps extends Partial<SkeletonDimensionsProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Typography defines the height of the skeleton and the selection of width values for available sizes
   */
  typographyVariant: TypographyVariants;
  /**
   * Each typography skeleton can have different width, depending of the text, it should represent
   */
  size?: TextSkeletonSizes;
}

export const SkeletonText: React.FC<SkeletonTextProps> = ({
  className,
  typographyVariant,
  size = TextSkeletonSizes.medium,
  height: heightProp,
  width: widthProp,
}) => {
  const width = widthProp ?? SKELETON_TEXT_WIDTH[typographyVariant][size];
  const height = heightProp ?? SKELETON_TEXT_HEIGHT[typographyVariant];

  return (
    <SkeletonBlock
      {...{
        className: clsx('inline-block', className),
        width,
        height,
      }}
    />
  );
};
