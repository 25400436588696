import {
  BlueprintCycleInputField,
  BlueprintExecutionInfo,
  BlueprintInputField,
} from '~/~legacy/services/BlueprintExecutionService';

import { PartialRecord } from '~/shared/types/utility';

import { MoveDirection } from '../../types';
import { getEntity, getNewRow } from './entitiesFactory';

export interface CellCoordinates {
  x: number;
  y: number;
}

interface BlueprintFormsState {
  executionInfo: BlueprintExecutionInfo;
  activeCell?: CellCoordinates;
}

export enum BlueprintFormsActionTypes {
  Reinit = 'Reinit',
  ChangeInput = 'ChangeInput',
  ChangeCycleInput = 'ChangeCycleInput',
  AddCycleInputRow = 'AddCycleInputRow',
  DelCycleInputRow = 'DelCycleInputRow',
  PasteColumn = 'PasteColumn',
  MoveFocus = 'MoveFocus',
}

interface BlueprintFormsAction<T> {
  type: BlueprintFormsActionTypes;
  data?: T;
}

type BlueprintInputsActionFunction<T> = (
  state: BlueprintFormsState,
  action: BlueprintFormsAction<T>
) => BlueprintFormsState;

export interface FocusChange {
  cell?: CellCoordinates;
  direction: MoveDirection;
  cycleInput: BlueprintCycleInputField;
}

export function getBlueprintFormsInintialState(
  executionInfo: BlueprintExecutionInfo
): BlueprintFormsState {
  return {
    executionInfo,
  };
}

const changeInputReaction: BlueprintInputsActionFunction<{
  input: BlueprintInputField;
  val: any;
}> = (state, action) => {
  if (!action.data)
    throw new Error('Не найден пользовательский ввод для обновления значений');

  const inputs = state.executionInfo.inputs.map(x =>
    x.id === action.data?.input.id
      ? {
          ...action.data?.input,
          userInput: action.data?.val,
        }
      : x
  );

  const newState: BlueprintFormsState = {
    ...state,
    executionInfo: {
      ...state.executionInfo,
      inputs,
    },
  };

  return newState;
};

const addCycleInputReaction: BlueprintInputsActionFunction<
  BlueprintCycleInputField
> = (state, action) => {
  if (!action.data)
    throw new Error('Циклический ввод для добавления значений не задан');

  const input = action.data;
  const updatedInput: BlueprintCycleInputField = {
    ...input,
    userInput: [...input.userInput, getNewRow(input.inputs.length)],
  };

  const cycleInputs = state.executionInfo.cycleInputs.map(x =>
    x.id === input.id ? updatedInput : x
  );

  const newState: BlueprintFormsState = {
    ...state,
    executionInfo: {
      ...state.executionInfo,
      cycleInputs,
    },
    activeCell: {
      x: 0,
      y: updatedInput.userInput.length - 1,
    },
  };
  return newState;
};

const delCycleInputReaction: BlueprintInputsActionFunction<{
  cycleInput: BlueprintCycleInputField;
  index: number;
}> = (state, action) => {
  if (!action.data)
    throw new Error('Циклический ввод для добавления значений не задан');

  const input = action.data?.cycleInput;
  input.userInput.splice(action.data.index, 1);

  const updatedInput: BlueprintCycleInputField = {
    ...input,
    userInput: [...input.userInput],
  };

  const cycleInputs = state.executionInfo.cycleInputs.map(x =>
    x.id === input.id ? updatedInput : x
  );

  const newState: BlueprintFormsState = {
    executionInfo: {
      ...state.executionInfo,
      cycleInputs,
    },
  };

  return newState;
};

const changeCycleInputReaction: BlueprintInputsActionFunction<{
  cycleInput: BlueprintCycleInputField;
  rowIndex: number;
  fieldIndex: number;
  val: any;
}> = (state, action) => {
  if (!action.data)
    throw new Error('Циклический ввод для добавления значений не задан');

  const input = action.data.cycleInput;
  const updatedRow = input.userInput[action.data.rowIndex];
  updatedRow[action.data.fieldIndex] = action.data.val;

  const updatedInput: BlueprintCycleInputField = {
    ...input,
    userInput: input.userInput.map((x, rowIndex) =>
      rowIndex === action.data?.rowIndex ? updatedRow : x
    ),
  };

  const cycleInputs = state.executionInfo.cycleInputs.map(x =>
    x.id === input.id ? updatedInput : x
  );

  const newState: BlueprintFormsState = {
    ...state,
    executionInfo: {
      ...state.executionInfo,
      cycleInputs,
    },
  };

  return newState;
};

const pasteDataColumnReaction: BlueprintInputsActionFunction<{
  cycleInput: BlueprintCycleInputField;
  rowIndex: number;
  fieldIndex: number;
  data: string[];
}> = (state, action) => {
  if (!action.data) throw new Error('Не были переданы данные для обновление');

  const { cycleInput, rowIndex, fieldIndex, data } = action.data;

  const untouchedUserInputs = cycleInput.userInput.filter(
    (x, i) => i < rowIndex
  );
  const newUserInputs = data.map((item, index) => {
    const indexOfDataRow = rowIndex + index;

    const row =
      cycleInput.userInput.length > indexOfDataRow
        ? cycleInput.userInput[indexOfDataRow]
        : getNewRow(cycleInput.inputs.length);

    const input = cycleInput.inputs[fieldIndex - 1];

    row[fieldIndex] = getEntity(item, input);

    return row;
  });

  const restUserInputs = cycleInput.userInput.filter(
    (x, i) => i >= rowIndex + data.length
  );

  const updatedInput: BlueprintCycleInputField = {
    ...cycleInput,
    userInput: [...untouchedUserInputs, ...newUserInputs, ...restUserInputs],
  };

  const cycleInputs = state.executionInfo.cycleInputs.map(x =>
    x.id === cycleInput.id ? updatedInput : x
  );

  const newState: BlueprintFormsState = {
    ...state,
    executionInfo: {
      ...state.executionInfo,
      cycleInputs,
    },
  };

  return newState;
};

const moveFocusReaction: BlueprintInputsActionFunction<FocusChange> = (
  state,
  data
) => {
  if (!data.data) {
    return state;
  }

  const { cycleInput, direction, cell } = data.data;

  const maxY = cycleInput.userInput.length - 1;
  const maxX = cycleInput.inputs.length - 1;

  const gettersMap: Record<
    MoveDirection,
    (current: CellCoordinates) => CellCoordinates | undefined
  > = {
    [MoveDirection.none]: node => {
      return node;
    },
    [MoveDirection.down]: node => {
      const y = node.y + 1;

      return {
        x: node.x,
        y: y > maxY ? maxY : y,
      };
    },
    [MoveDirection.up]: node => {
      const y = node.y - 1;

      return {
        x: node.x,
        y: y > 0 ? y : 0,
      };
    },
    [MoveDirection.left]: node => {
      const x = node.x - 1;
      return {
        x: x > 0 ? x : 0,
        y: node.y,
      };
    },
    [MoveDirection.right]: node => {
      const x = node.x + 1;

      return {
        y: node.y,
        x: x > maxX ? maxX : x,
      };
    },
  };

  const getterFunc = gettersMap[direction || MoveDirection.none];

  if (direction === MoveDirection.none) {
    return {
      executionInfo: state.executionInfo,
      activeCell: cell,
    };
  }

  if (cell) {
    const newCell = getterFunc(cell);

    return {
      executionInfo: state.executionInfo,
      activeCell: newCell,
    };
  }

  return state;
};

const defaultReaction: BlueprintInputsActionFunction<any> = state => {
  return state;
};

const actionFunctionsMap: PartialRecord<
  BlueprintFormsActionTypes,
  BlueprintInputsActionFunction<any>
> = {
  [BlueprintFormsActionTypes.ChangeInput]: changeInputReaction,
  [BlueprintFormsActionTypes.AddCycleInputRow]: addCycleInputReaction,
  [BlueprintFormsActionTypes.DelCycleInputRow]: delCycleInputReaction,
  [BlueprintFormsActionTypes.ChangeCycleInput]: changeCycleInputReaction,
  [BlueprintFormsActionTypes.PasteColumn]: pasteDataColumnReaction,
  [BlueprintFormsActionTypes.MoveFocus]: moveFocusReaction,
};

export function blueprintFormsReducer(
  state: BlueprintFormsState,
  action: BlueprintFormsAction<any>
): BlueprintFormsState {
  const reaction = actionFunctionsMap[action.type] || defaultReaction;
  const newState = reaction(state, action);
  return newState;
}
