import React from 'react';

export const Delete = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.75 11C14.75 10.5858 14.4142 10.25 14 10.25C13.5858 10.25 13.25 10.5858 13.25 11V16C13.25 16.4142 13.5858 16.75 14 16.75C14.4142 16.75 14.75 16.4142 14.75 16V11Z"
      fill="currentColor"
    />
    <path
      d="M10 10.25C10.4142 10.25 10.75 10.5858 10.75 11V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V11C9.25 10.5858 9.58579 10.25 10 10.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.023 2.30035C15.9591 2.42621 16.5738 3.04089 16.6996 3.97699C16.8001 4.72406 16.75 5.49737 16.75 6.24999H21C21.4142 6.24999 21.75 6.58577 21.75 6.99999C21.75 7.4142 21.4142 7.74999 21 7.74999H19.7362C19.5231 11.2314 19.5791 14.7757 18.7276 18.1819C18.1478 20.5011 16.359 21.75 14.5 21.75H9.5C7.64095 21.75 5.85219 20.5011 5.27239 18.1819C4.42461 14.7908 4.47631 11.2221 4.26376 7.74999H3C2.58579 7.74999 2.25 7.4142 2.25 6.99999C2.25 6.58577 2.58579 6.24999 3 6.24999H7.25C7.25 5.49737 7.19992 4.72406 7.30036 3.97699C7.42622 3.04089 8.0409 2.42621 8.977 2.30035C10.8752 2.04515 13.1248 2.04515 15.023 2.30035ZM5.76657 7.74999C5.97228 11.1103 5.90549 14.5296 6.72761 17.8181C7.14781 19.4989 8.35905 20.25 9.5 20.25H14.5C15.641 20.25 16.8522 19.4989 17.2724 17.8181C18.0945 14.5296 18.0277 11.1103 18.2334 7.74999H5.76657ZM15.25 6.24999H8.75C8.75 5.56527 8.69565 4.8562 8.78699 4.17686C8.82746 3.87582 8.87583 3.82745 9.17687 3.78698C10.9668 3.54633 13.0476 3.54826 14.8231 3.78698C15.1242 3.82745 15.1725 3.87582 15.213 4.17686C15.3043 4.8562 15.25 5.56527 15.25 6.24999Z"
      fill="currentColor"
    />
  </svg>
);
