import R, { Ord } from 'ramda';

import { Falsy } from '~/shared/types/utility';

/**
 * Helps to construct conditional arrays like this: [item1, condition && item2, item3].filter(item => !!item)
 */
export const wrapConditionalArrayElement = <T extends any>(
  element: T | Falsy,
  isPassingValueOrFunc: boolean | ((element: T | Falsy) => boolean) = el => !!el
) => {
  let isPassing = isPassingValueOrFunc;
  if (typeof isPassing === 'function') {
    isPassing = isPassing(element);
  }
  if (isPassing) {
    return [element] as T[];
  }
  return [] as T[];
};

/**
 * Gets maximum value in a list by a predicate, returns undefined if list is empty
 */
export const maxByInList = <T>(getValue: (element: T) => Ord, list: T[]) =>
  list.length
    ? R.reduce<T, T>(R.maxBy<T>(getValue) as (element: T) => T, list[0], list)
    : undefined;

/**
 * Gets minimum value in a list by a predicate, returns undefined if list is empty
 */
export const minByInList = <T>(getValue: (element: T) => Ord, list: T[]) =>
  list.length
    ? R.reduce<T, T>(R.minBy<T>(getValue) as (element: T) => T, list[0], list)
    : undefined;
