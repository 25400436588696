import { IconVariants } from '~/shared/components/Icon';

import { NotificationVariants } from './types';

/**
 * NotificationVariants to IconVariants mapping
 */
export const NOTIFICATION_ICON_VARIANTS_DICT: Record<
  NotificationVariants,
  IconVariants
> = {
  [NotificationVariants.info]: IconVariants.infoCircleFilled,
  [NotificationVariants.success]: IconVariants.checkCircleFilled,
  [NotificationVariants.warning]: IconVariants.warningCircleFilled,
  [NotificationVariants.error]: IconVariants.cancelCircleFilled,
  [NotificationVariants.neutral]: IconVariants.chevronRightCircleFilled,
};
