import React from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { Input, InputVariants } from '~/shared/components/Input';
import { Typography } from '~/shared/components/Typography';
import { oneOfEnum } from '~/shared/helpers/yup';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { useFarmSelect } from '~/entities/farms';
import { PenGroupFragment } from '~/entities/penGroups/gql/fragments/penGroup.graphql';
import { useCreatePenGroupMutation } from '~/entities/penGroups/gql/mutations/createPenGroup.graphql';
import { useUpdatePenGroupMutation } from '~/entities/penGroups/gql/mutations/updatePenGroup.graphql';

import { TypographyVariants } from '~/styles/__generated__/token-variants';
import formStyles from '~/styles/modules/form.module.scss';

import {
  extractPenGroupFieldsFromObject,
  getPenGroupFieldsFromAttributes,
} from '../../helpers';
import { usePenGroupAttributesSelect } from '../../hooks';
import { PenGroupAttributeFields } from '../../types';
import { updatePenGroupDetailedFragment } from './helpers';

export interface EditPenGroupModalProps
  extends InjectedModalProps<EditPenGroupModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing pen group form, if not passed, a new one is created
   */
  penGroup?: PenGroupFragment;
}

const FORM_ID = 'EditPenGroupFormForm';

const SCHEMA = yup.object({
  farmID: yup.string().required(), // ID!
  identifier: yup.number().default(null).required(), // ID!
  name: yup.string().required(),
  penGroupAttribute: yup
    .array(
      oneOfEnum<PenGroupAttributeFields>(PenGroupAttributeFields).required()
    )
    .default([]),
  capacity: yup.number().default(null).nullable(),
});

type EditPenGroupFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type EditPenGroupFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

export const EditPenGroupFormModal: React.FC<EditPenGroupModalProps> = ({
  className,
  penGroup,
  close,
}) => {
  const isEditing = !!penGroup;

  const { renderSelectElement: renderFarmsSelectElement } = useFarmSelect({
    selectProps: {
      label: 'Ферма',
      name: 'farmID',
    },
  });

  const formContext = useForm<
    EditPenGroupFormType,
    EditPenGroupFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['identifier', 'name', 'capacity'],
        penGroup ?? ({} as PenGroupFragment)
      ),
      farmID: penGroup?.farm?.id,
      penGroupAttribute: extractPenGroupFieldsFromObject(penGroup),
    },
  });

  const [createPenGroupForm, { loading: isCreatePenGroupFormLoading }] =
    useCreatePenGroupMutation();
  const [updatePenGroupForm, { loading: isUpdatePenGroupFormLoading }] =
    useUpdatePenGroupMutation();

  const handleSubmit = async (form: EditPenGroupFormTransformedType) => {
    const { penGroupAttribute, ...formValues } = form;
    const penGroupAttributeFields = getPenGroupFieldsFromAttributes(
      penGroupAttribute,
      false
    );

    const input = { ...penGroupAttributeFields, ...formValues };

    if (isEditing) {
      await updatePenGroupForm({
        optimisticResponse: { updatePenGroup: null },
        update: updatePenGroupDetailedFragment(penGroup.id, draft => {
          draft.name = form.name;
          draft.identifier = form.identifier;
          draft.capacity = form.capacity;
          draft.farm.id = form.farmID;

          (
            Object.keys(penGroupAttributeFields) as PenGroupAttributeFields[]
          ).forEach(attributeKey => {
            draft[attributeKey] = penGroupAttributeFields[attributeKey];
          });
        }),
        variables: {
          id: penGroup.id,
          input,
        },
      });
    } else {
      await createPenGroupForm({
        variables: {
          input,
        },
        refetchQueries: ['penGroupsDetailed'],
      });
    }
    close();
  };

  const { renderSelectElement: renderPenGroupAttributesSelectElement } =
    usePenGroupAttributesSelect({
      name: 'penGroupAttribute',
      label: 'Признак группы',
      isMulti: true,
      placeholder: 'Выберите признаки группы',
    });

  return (
    <Modal
      {...{
        className,
        title: isEditing ? 'Редактирование группы' : 'Создание группы',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isCreatePenGroupFormLoading || isUpdatePenGroupFormLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
          className: formStyles.singleColumnForm,
        }}
      >
        <Typography
          {...{
            tag: 'h4',
            variant: TypographyVariants.heading4,
          }}
        >
          Обязательная информация
        </Typography>
        {renderFarmsSelectElement()}
        <Input
          {...{
            label: 'Номер группы',
            name: 'identifier',
            variant: InputVariants.int,
            placeholder: 'Введите номер',
          }}
        />
        <Input
          {...{
            label: 'Название группы',
            name: 'name',
            placeholder: 'Введите название',
          }}
        />
        <Typography
          {...{
            className: 'mt-8',
            tag: 'h4',
            variant: TypographyVariants.heading4,
          }}
        >
          Дополнительная информация
        </Typography>
        {renderPenGroupAttributesSelectElement()}
        <Input
          {...{
            label: 'Вместимость группы',
            name: 'capacity',
            variant: InputVariants.int,
            placeholder: 'Введите число голов',
          }}
        />
      </Form>
    </Modal>
  );
};
