import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import { useApolloClient } from '@apollo/client';

import { EventKindEnum } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useNotifications } from '~/services/notifications';

import {
  readDiseaseFragment,
  useEventsPaginatedQuery,
} from '~/entities/events';

import formStyles from '~/styles/modules/form.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { MoveCowCard } from '../../../../components';
import { useAddDiseaseIdsToListModal } from '../../../index';
import stepsStyles from '../../steps.module.scss';
import { MoveCowsFormType } from '../../types';
import { ContentItems } from '../ContentItems';

const MAX_EVENTS_TO_LOAD = 1000;

export const ChooseDiseasesStep: React.FC = () => {
  const { sendNeutralToast } = useNotifications();

  const formContext = useFormContext<MoveCowsFormType>();
  const client = useApolloClient();

  const shouldRefetchDiseases =
    !formContext.getFieldState('diseaseIDs').isDirty;

  const diseaseIDs = formContext.watch('diseaseIDs');
  const cowIDs = formContext.watch('cowIDs');

  const diseases = diseaseIDs
    .map(diseaseID => readDiseaseFragment(client, diseaseID))
    .filter(Boolean);

  const { open: openAddDiseaseIdsToListModal } = useAddDiseaseIdsToListModal();

  const queryOptions = {
    variables: {
      cowIDs,
      withDefault: false,
      kinds: [EventKindEnum.Disease],
    },
  };

  const {
    items: diseaseItems,
    hasMore,
    fetchMore,
    isLoading,
  } = useEventsPaginatedQuery({
    variables: {
      first: MAX_EVENTS_TO_LOAD,
      // variable structure is the same as event select has. It's for cache performance
      search: '',
      ...queryOptions.variables,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: async data => {
      if (hasMore) {
        await fetchMore();
        return;
      }
      if (shouldRefetchDiseases) {
        formContext.setValue(
          'diseaseIDs',
          data.events.edges.map(R.path(['node', 'id'])) as string[],
          {
            shouldDirty: true,
          }
        );
      }
    },
  });

  return (
    <div
      {...{
        className: clsx(
          panelStyles.largeDarkPanel,
          formStyles.singleColumnForm,
          'p-16'
        ),
      }}
    >
      <Typography variant={TypographyVariants.bodyMediumStrong}>
        Передача пользовательских заболеваний
      </Typography>
      <Typography
        {...{
          className: stepsStyles.descriptionBlock,
          variant: TypographyVariants.bodySmall,
        }}
      >
        По умолчанию выбраны все пользовательские заболевания животных для
        передачи на другую площадку. Вы можете полностью очистить выбор или
        удалить по одному.
      </Typography>
      <ContentItems
        {...{
          title: 'Список заболеваний',
          onClear: () => {
            formContext.setValue('diseaseIDs', []);
          },
          amount: `${diseaseIDs.length} из ${diseaseItems.length}`,
          addButtonProps: {
            onPress: () => {
              openAddDiseaseIdsToListModal({
                queryOptions,
                defaultValues: diseaseIDs,
                onSubmit: newDiseases => {
                  formContext.setValue('diseaseIDs', newDiseases, {
                    shouldDirty: true,
                  });
                },
              });
            },
            children: 'Добавить заболевания',
          },
          dataBlockedMessageProps: {
            isLoading,
            loadingMessage: 'Загрузка пользовательских заболеваний',
            message: 'Пользовательские заболевания пока не добавлены',
          },
          itemsToRender: diseases?.map((disease, index) => (
            <MoveCowCard
              key={disease.id}
              {...{
                index: index + 1,
                contentItems: [
                  <Fragment key={disease.id}>{disease.name}</Fragment>,
                ],
                onDelete: () => {
                  formContext.setValue(
                    'diseaseIDs',
                    diseaseIDs.filter(diseaseId => diseaseId !== disease.id)
                  );
                  sendNeutralToast('Заболевание удалено');
                },
              }}
            />
          )),
        }}
      />
    </div>
  );
};
