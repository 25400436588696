import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshAuthTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type RefreshAuthTokenMutation = { __typename?: 'Mutation', refreshAuthToken: { __typename?: 'TokenResponse', accessToken: string, refreshToken: string } };


export const RefreshAuthTokenDocument = gql`
    mutation refreshAuthToken($token: String!) {
  refreshAuthToken(token: $token) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshAuthTokenMutationFn = Apollo.MutationFunction<RefreshAuthTokenMutation, RefreshAuthTokenMutationVariables>;

/**
 * __useRefreshAuthTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthTokenMutation, { data, loading, error }] = useRefreshAuthTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAuthTokenMutation, RefreshAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAuthTokenMutation, RefreshAuthTokenMutationVariables>(RefreshAuthTokenDocument, options);
      }
export type RefreshAuthTokenMutationHookResult = ReturnType<typeof useRefreshAuthTokenMutation>;
export type RefreshAuthTokenMutationResult = Apollo.MutationResult<RefreshAuthTokenMutation>;
export type RefreshAuthTokenMutationOptions = Apollo.BaseMutationOptions<RefreshAuthTokenMutation, RefreshAuthTokenMutationVariables>;