import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import R from 'ramda';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';

import { formatDate } from '~/services/dateTime';
import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';

import {
  CowNormalizedEvent,
  getNormalizedCowEvent,
  useConfirmRollbackEvent,
  useCowEventsPaginatedQuery,
  useGetCowEventAdditionalInfo,
} from '~/entities/cowEvents';
import {
  CowEventsDocument,
  CowEventsQueryVariables,
} from '~/entities/cowEvents/gql/queries/cowEvents.graphql';
import { getCowIdentifier } from '~/entities/cows';
import { useRollbackEventMutation } from '~/entities/events/gql/mutations/rollbackEvent.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, the table is displayed as nested in the another one
   * date column is not displayed, and table is rendered with smaller theme
   */
  isNested?: boolean;
  /**
   * Query variables for the cow events query
   */
  queryVariables?: CowEventsQueryVariables;
}

type CowRow = CowNormalizedEvent | SkeletonPlaceholder;

const DATE_COLUMN_WIDTH_PX = 112;
const IDENTIFIER_COLUMN_WIDTH_PX = 160;
const LACTATION_COLUMN_WIDTH_PX = 80;
const KIND_NESTED_COLUMN_WIDTH_PX = 140;
const KIND_NOT_NESTED_COLUMN_WIDTH_PX = 160;

const NAME_NOT_NESTED_COLUMN_WIDTH_PX = 184;
const NAME_NESTED_COLUMN_WIDTH_PX = 280;

const COW_EVENT_ROWS_SKELETONS_COUNT = 8;

export const CowEventsTable = React.forwardRef<HTMLTableElement, Props>(
  ({ className, isNested = false, queryVariables = {} }, ref) => {
    const { t } = useTranslation(MaslovNamespaces.enums);

    const { items: cowEvents, ...asyncProps } = useCowEventsPaginatedQuery({
      variables: queryVariables,
    });

    const normalizedCowEvents = useMemo(
      () => cowEvents.map(getNormalizedCowEvent),
      [cowEvents]
    );

    const getCowEventAdditionalInfo = useGetCowEventAdditionalInfo();

    const columnConfigs: TableColumnConfig<CowRow>[] = [
      ...wrapConditionalArrayElement<TableColumnConfig<CowRow>>(
        !isNested && {
          title: 'Дата',
          key: 'date',
          renderCellContent: event => {
            if (isSkeletonPlaceholder(event)) return null;

            return formatDate(event.originalCowEvent.happenedAt);
          },
          width: DATE_COLUMN_WIDTH_PX,
        }
      ),
      {
        title: 'Номер животного',
        key: 'identifier',
        renderCellContent: event => {
          if (isSkeletonPlaceholder(event)) return null;

          const {
            originalCowEvent: { cow },
          } = event;

          return (
            <TextLink
              {...{
                to: '/$companyId/user/cows/$cowId',
                params: prev => ({
                  ...prev,
                  cowId: cow.id,
                }),
              }}
            >
              {getCowIdentifier(cow)}
            </TextLink>
          );
        },
        width: IDENTIFIER_COLUMN_WIDTH_PX,
      },
      {
        title: 'Лактация',
        key: 'lactationNumber',
        itemField: 'lactationNumber',
        width: LACTATION_COLUMN_WIDTH_PX,
      },
      {
        title: 'Тип события',
        key: 'kind',
        renderCellContent: event => t(`${EnumStrings.eventKinds}${event.kind}`),
        width: isNested
          ? KIND_NESTED_COLUMN_WIDTH_PX
          : KIND_NOT_NESTED_COLUMN_WIDTH_PX,
      },
      {
        title: 'Событие',
        key: 'name',
        itemField: 'name',
        width: isNested
          ? NAME_NESTED_COLUMN_WIDTH_PX
          : NAME_NOT_NESTED_COLUMN_WIDTH_PX,
      },
      {
        title: 'Дополнительная информация',
        key: 'additionalInfo',
        renderCellContent: event => {
          if (isSkeletonPlaceholder(event)) return null;

          return getCowEventAdditionalInfo(event.originalCowEvent);
        },
        width: 'minmax(15ch, 1fr)',
      },
    ];

    const [rollbackEvent] = useRollbackEventMutation();
    const confirmRollback = useConfirmRollbackEvent();

    const handleDeleteCowEvent = async (event: CowNormalizedEvent) => {
      const isConfirmed = await confirmRollback(event);

      if (!isConfirmed) return;

      const eventIdToDelete = event.originalCowEvent.id;

      rollbackEvent({
        variables: {
          id: eventIdToDelete,
        },
        optimisticResponse: { rollbackEvent: null },
        update: R.juxt([
          makeDeleteFragmentFromQuery({
            typeName: event.originalCowEvent.__typename,
            query: CowEventsDocument,
            variables: queryVariables,
            queryName: 'cowEvents',
          })(eventIdToDelete),
          makeDeleteQueriesByNameWithoutVariables('cowEvents', queryVariables),
        ]),
      });
    };
    return (
      <Table<CowRow>
        {...{
          ref,
          theme: isNested
            ? TableThemes.smallSecondary
            : TableThemes.largeSecondary,
          className: clsx(
            className,
            isNested ? 'col-span-full' : 'min-w-full w-min'
          ),
          items: normalizedCowEvents,
          getItemKey: event => {
            if (!isSkeletonPlaceholder(event)) {
              return event.originalCowEvent.id;
            }
            return event.id;
          },
          withBorder: isNested,
          withCustomScroll: !isNested,
          columnConfigs,
          noItemsMessage: 'Нет данных для отображения',
          noSearchItemsMessage: 'События не найдены',
          skeletonItemsCount: COW_EVENT_ROWS_SKELETONS_COUNT,
          renderItemActions: event => {
            if (isSkeletonPlaceholder(event)) return null;

            return (
              <FunctionButton
                {...{
                  iconVariant: IconVariants.delete,
                  onPress: () => handleDeleteCowEvent(event),
                }}
              />
            );
          },
          ...asyncProps,
        }}
      />
    );
  }
);
