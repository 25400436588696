import {
  makeUpdateFragment,
  makeUseFragmentFromCacheOrQuery,
} from '~/services/gql';

import {
  ReproductionHdrAndPrReportFragment,
  ReproductionHdrAndPrReportFragmentDoc,
} from './gql/fragments/reproductionHdrAndPrReport.graphql';
import {
  ReproductionHdrAndPrReportDetailedFragment,
  ReproductionHdrAndPrReportDetailedFragmentDoc,
} from './gql/fragments/reproductionHdrAndPrReportDetailed.graphql';
import {
  ReproductionHdrAndPrReportsDetailedQuery,
  ReproductionHdrAndPrReportsDetailedQueryVariables,
  useReproductionHdrAndPrReportsDetailedQuery,
} from './gql/queries/reproductionHdrAndPrReportsDetailed.graphql';

/**
 * Update ReproductionHdrAndPrReport in the cache
 */
export const updateReproductionHdrAndPrReportFragment =
  makeUpdateFragment<ReproductionHdrAndPrReportFragment>({
    typeName: 'ReproductionHdrAndPrReport',
    fragment: ReproductionHdrAndPrReportFragmentDoc,
  });

/**
 * Hook for getting HDR and PR report fragment from cache or by query
 */
export const useReproductionHdrAndPrReportDetailedFromCacheOrQuery =
  makeUseFragmentFromCacheOrQuery<
    ReproductionHdrAndPrReportDetailedFragment,
    ReproductionHdrAndPrReportsDetailedQuery,
    ReproductionHdrAndPrReportsDetailedQueryVariables
  >({
    typeName: 'ReproductionHdrAndPrReportDetailed',
    fragment: ReproductionHdrAndPrReportDetailedFragmentDoc,
    useQuery: useReproductionHdrAndPrReportsDetailedQuery,
    getItemFromQueryData: data =>
      data.reproductionHdrAndPrReports.edges.at(0)?.node,
  });
