import React, { Suspense, useEffect, useState } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';

import { FloatingTree } from '@floating-ui/react';
import { RouterProvider } from '@tanstack/react-router';

import {
  IoCContainerType,
  ServicesContext,
} from '~/~legacy/components/ServicesContext';

import { Loader } from '~/shared/components/Loader';
import { API_URL } from '~/shared/constants';

import { useAuth } from '~/services/auth';
import { makeApolloClient } from '~/services/gql';
import {
  getNotificationPropsFromGQLError,
  NotificationKinds,
  NotificationsContainer,
  NotificationsContext,
  useCreateNotificationsContext,
} from '~/services/notifications';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { initIoC } from '../../config';
import { router } from '../../routing';
import { getSelectedCompanyId } from './helpers';

const TanStackRouterDevtools =
  process.env.REACT_APP_SHOULD_SHOW_ROUTER_DEVTOOLS === 'true'
    ? React.lazy(() =>
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        }))
      )
    : () => null;

export const ChicherinApp: React.FC = () => {
  const gqlAuthProvider = useAuth();

  const notificationsContext = useCreateNotificationsContext();

  const [isInitialized, setInitialized] = useState(false);
  const [iocContainer, setIocContainer] = useState<IoCContainerType>({});
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const container = initIoC(gqlAuthProvider, getSelectedCompanyId);
    setIocContainer(container);

    const client = makeApolloClient({
      apolloUri: API_URL,
      gqlAuthProvider,
      getSelectedCompanyId,
      onError: error => {
        notificationsContext.sendNotification(
          NotificationKinds.alert,
          getNotificationPropsFromGQLError(error)
        );
      },
    });

    setApolloClient(client);
    gqlAuthProvider.provideApolloClient(client);

    setInitialized(true);
  }, []);

  const loader = <Loader className={layoutStyles.fullHeightContainer} />;

  return isInitialized && apolloClient ? (
    <Suspense fallback={loader}>
      <ServicesContext.Provider value={iocContainer}>
        <ApolloProvider client={apolloClient}>
          <FloatingTree>
            <NotificationsContext.Provider value={notificationsContext}>
              <RouterProvider
                {...{
                  router,
                  context: {
                    auth: {
                      isAuthenticated: gqlAuthProvider.isAuthenticated,
                    },
                  },
                }}
              />
              <NotificationsContainer />
              <Suspense>
                <TanStackRouterDevtools
                  {...{
                    router,
                    position: 'bottom-right',
                  }}
                />
              </Suspense>
            </NotificationsContext.Provider>
          </FloatingTree>
        </ApolloProvider>
      </ServicesContext.Provider>
    </Suspense>
  ) : (
    loader
  );
};
