import * as Types from '../../../../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBullMutationVariables = Types.Exact<{
  input: Types.CreateBullInput;
}>;


export type CreateBullMutation = { __typename?: 'Mutation', createBull: { __typename?: 'Bull', id: string, name: string } };


export const CreateBullDocument = gql`
    mutation createBull($input: CreateBullInput!) {
  createBull(input: $input) {
    id
    name
  }
}
    `;
export type CreateBullMutationFn = Apollo.MutationFunction<CreateBullMutation, CreateBullMutationVariables>;

/**
 * __useCreateBullMutation__
 *
 * To run a mutation, you first call `useCreateBullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBullMutation, { data, loading, error }] = useCreateBullMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBullMutation(baseOptions?: Apollo.MutationHookOptions<CreateBullMutation, CreateBullMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBullMutation, CreateBullMutationVariables>(CreateBullDocument, options);
      }
export type CreateBullMutationHookResult = ReturnType<typeof useCreateBullMutation>;
export type CreateBullMutationResult = Apollo.MutationResult<CreateBullMutation>;
export type CreateBullMutationOptions = Apollo.BaseMutationOptions<CreateBullMutation, CreateBullMutationVariables>;