import { AnyRoute, redirect } from '@tanstack/react-router';
import {
  AnyContext,
  BeforeLoadContextOptions,
} from '@tanstack/react-router/dist/esm/route';
import { Constrain } from '@tanstack/react-router/dist/esm/utils';

import { AnyRouteItem } from '~/shared/types/utility';

interface MakeBeforeLoadRedirectProps {
  to: AnyRouteItem;
  from: AnyRouteItem;
}

type MakeBeforeLoadRedirectInterface<T extends AnyContext> = Constrain<
  AnyContext,
  (
    ctx: BeforeLoadContextOptions<
      AnyRoute,
      undefined,
      T,
      AnyContext,
      AnyContext
    >
  ) => any
>;

/**
 * Fabric to make a redirect beforeLoad function to pass in a createFileRoute
 */
export const makeBeforeLoadRedirect =
  <T extends AnyContext>({
    to,
    from: fromPathname,
  }: MakeBeforeLoadRedirectProps): MakeBeforeLoadRedirectInterface<T> =>
  ({ matches, location }): void => {
    const matchedRoute = matches.find(
      m => m.pathname.replace(/\/$/, '') === location.pathname
    );

    if (matchedRoute?.fullPath === fromPathname) {
      throw redirect({
        to,
        from: fromPathname,
        params: true,
      });
    }
  };
