import React from 'react';

import clsx from 'clsx';

import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useArkaNavigation } from '~/services/navigation';

import { ReproductionHdrAndPrReportFragment } from '~/entities/reproductionHdrAndPrReports/gql/fragments/reproductionHdrAndPrReport.graphql';
import { ReproductionHdrAndPrReportsQueryVariables } from '~/entities/reproductionHdrAndPrReports/gql/queries/reproductionHdrAndPrReports.graphql';
import { RoleBadges } from '~/entities/roles';

import panelStyles from '~/styles/modules/panel.module.scss';

import { ReproductionHdrAndPrReportCardMenu } from '../ReproductionHdrAndPrReportCardMenu';
import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render
   */
  reproductionHdrAndPrReport:
    | ReproductionHdrAndPrReportFragment
    | SkeletonPlaceholder;
  /**
   * Gql custom reports query variables
   */
  queryVariables: ReproductionHdrAndPrReportsQueryVariables;
}

export const ReproductionHdrAndPrReportCard: React.FC<Props> = ({
  className,
  reproductionHdrAndPrReport,
  queryVariables,
}) => {
  const { navigate, urlCompanyId } = useArkaNavigation();

  return (
    <div
      {...{
        className: clsx(styles.root, panelStyles.panel, className),
        onClick: () => {
          if (isSkeletonPlaceholder(reproductionHdrAndPrReport)) return;

          navigate({
            to: '/$companyId/user/analytics/reproduction/hdr-and-pr/$reproductionHdrAndPrReportId',
            params: {
              reproductionHdrAndPrReportId: reproductionHdrAndPrReport.id,
              companyId: urlCompanyId,
            },
          });
        },
      }}
    >
      <div className="flex items-start">
        <Typography
          {...{
            className: 'mr-12',
            variant: TypographyVariants.bodyMediumStrong,
          }}
        >
          {reproductionHdrAndPrReport.name}
        </Typography>
        {!isSkeletonPlaceholder(reproductionHdrAndPrReport) && (
          <ReproductionHdrAndPrReportCardMenu
            {...{
              className: 'ml-a',
              reproductionHdrAndPrReport,
              queryVariables,
            }}
          />
        )}
      </div>
      <div className="mt-a flex">
        <RoleBadges roles={reproductionHdrAndPrReport.roles} />
      </div>
    </div>
  );
};
