import React from 'react';

import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { VoidEdit } from './VoidEdit';
import { VoidView } from './VoidView';

export const VoidEditor: React.FC<ValueEditorArgs<void>> = props => {
  const Component = EditorHoC(VoidView, VoidEdit);

  return <Component {...props} />;
};
