import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milking-parlors/$farmId/'
)({
  loader: () =>
    redirect({
      to: '/$companyId/user/entities/milking-parlors/$farmId/schedule',
      from: Route.fullPath,
    }),
});
