import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import {
  AppBlueprint,
  BlueprintsService,
} from '~/~legacy/services/BlueprintsService';
import { MaslovServices } from '~/~legacy/types/services';

import { RunType, ScheduleInterval, VitalityFilter } from '~/shared/graphql';

import { useArkaNavigation } from '~/services/navigation';

import { BlueprintDetailsEditModel } from '../../types';
import { BlueprintsDetailsUI } from './components/BlueprintsDetailsUI';

interface Props {
  blueprintId: string;
  runType?: RunType | null;
}

export const BlueprintDetails: React.FC<Props> = props => {
  const { blueprintId, runType } = props;

  const isNew = blueprintId === 'new';

  const defaultBlueprintDetailsEditModel: BlueprintDetailsEditModel =
    useMemo(() => {
      return {
        description: '',
        name: '',
        id: '',
        isTemplate: false,
        runSettings: {
          runType: runType || RunType.Schedule,
          dows: [],
          repeatInterval: 1,
          interval: ScheduleInterval.Daily,
        },
        template: undefined,
        roles: [],
        isForIntegratorOnly: false,
        priority: 1,
      };
    }, []);

  const blueprintsSvc = useService<BlueprintsService>(
    MaslovServices.BlueprintsService
  );

  const { navigate, urlCompanyId } = useArkaNavigation();

  const memoizedCreate = useMemo(() => {
    return blueprintsSvc?.create?.bind(blueprintsSvc);
  }, [blueprintsSvc]);

  const memoizedGetBlueprintById = useMemo(() => {
    return blueprintsSvc?.getBlueprintById?.bind(blueprintsSvc);
  }, [blueprintsSvc]);

  const memoizedUpdate = useMemo(() => {
    return blueprintsSvc?.update?.bind(blueprintsSvc);
  }, [blueprintsSvc]);

  const [blueprint, setBlueprint] = useState<BlueprintDetailsEditModel>({
    ...defaultBlueprintDetailsEditModel,
  });

  const {
    errors: createErrors,
    loading: createLoading,
    reload: createBlueprint,
  } = useApiData(memoizedCreate);

  const {
    errors: readErrors,
    loading: readLoading,
    reload: readBlueprint,
  } = useApiData(memoizedGetBlueprintById);

  const {
    errors: updateErrors,
    loading: updateLoading,
    reload: updateBlueprint,
  } = useApiData(memoizedUpdate);

  useEffect(() => {
    const reload = async () => {
      const result = await readBlueprint(blueprintId);
      setBlueprint({
        ...result.data,
        template: '',
      });
    };

    if (!isNew) {
      reload();
    }
  }, [blueprintId, isNew]);

  const errors = [...createErrors, ...updateErrors, ...readErrors];
  const loading = createLoading || updateLoading || readLoading;

  const navigateToEdit = useCallback((id: string) => {
    navigate({
      to: '/$companyId/integrator/blueprints/edit/$blueprintId',
      params: { companyId: urlCompanyId, blueprintId: id },
    });
  }, []);

  const client = useApolloClient();
  // For now we just remove all the info from apollo cache, cause we don't use apollo in requests here yet
  const resetCacheAfterBlueprintUpdate = () => {
    client.cache.evict({ fieldName: 'blueprints' });
    client.cache.evict({ fieldName: 'calendarItems' });
    client.cache.gc();
  };

  const createCallback = useCallback(
    async (blueprintData: BlueprintDetailsEditModel) => {
      const result = await createBlueprint({
        name: blueprintData.name,
        description: blueprintData.description,
        isTemplate: false,
        runSettings: blueprintData.runSettings,
        templateID: blueprintData.template,
        blueprintRoleIDs: blueprintData.roles.map(role => role.id),
        generalSettings: {
          vitalityFilter: VitalityFilter.Alive,
        },
        isForIntegratorOnly: blueprintData.isForIntegratorOnly,
        priority: blueprintData.priority,
      });

      if (result.success) {
        navigateToEdit(result.data.id);
        resetCacheAfterBlueprintUpdate();
      }
    },
    [navigateToEdit]
  );

  const updateCallback = useCallback(async (blueprintData: AppBlueprint) => {
    await updateBlueprint({
      id: blueprintId,
      input: {
        name: blueprintData.name,
        description: blueprintData.description,
        isTemplate: blueprintData.isTemplate,
        blueprintRoleIDs: blueprintData.roles.map(role => role.id),
        runSettings: blueprintData.runSettings,
        isForIntegratorOnly: blueprintData.isForIntegratorOnly,
        priority: blueprintData.priority,
      },
    });

    resetCacheAfterBlueprintUpdate();

    navigateToEdit(blueprintId);
  }, []);

  const submitHandler = useCallback(
    (blueprintData: BlueprintDetailsEditModel) => {
      const action = isNew ? createCallback : updateCallback;

      action(blueprintData);
    },
    [createCallback, updateCallback]
  );

  return (
    <BlueprintsDetailsUI
      new={isNew}
      loading={loading}
      errors={errors}
      blueprint={blueprint}
      submit={submitHandler}
    />
  );
};
