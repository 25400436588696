import React from 'react';

import clsx from 'clsx';

import {
  SkeletonPlaceholder,
  TextSkeletonSizes,
} from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { formatNumber, formatWithPercent } from '~/shared/helpers/number';

import { formatPenGroup } from '~/entities/penGroups';

import { MilkingParlorFlowRateReportGroupedRowEntryFragment } from '../../gql/fragments/milkingParlorFlowRateReportGroupedRowEntry.graphql';
import { MilkingParlorFlowRateReportRowEntryFragment } from '../../gql/fragments/milkingParlorFlowRateReportRowEntry.graphql';

type TableRow =
  | MilkingParlorFlowRateReportRowEntryFragment
  | MilkingParlorFlowRateReportGroupedRowEntryFragment
  | SkeletonPlaceholder;

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Milking report rows to display
   */
  milkingParlorFlowRateReportRows: TableRow[];
}

const isGroupedReportRow = (
  row: TableRow
): row is MilkingParlorFlowRateReportGroupedRowEntryFragment =>
  row.__typename === 'MilkingParlorFlowRateReportGroupedRowEntry';

const PEN_GROUP_COLUMN_WIDTH_PX = 156;
const OTHER_COLUMN_WIDTH_PX = 132;

const TABLE_FLOAT_CELL_PRECISION = 1;

const BASE_REPORT_COLUMN_PROPS = {
  columnClassName: 'text-right',
  cellTypographyProps: {
    skeletonProps: {
      size: TextSkeletonSizes.small,
    },
  },
  width: OTHER_COLUMN_WIDTH_PX,
};

export const MilkingFlowRateReportTable: React.FC<Props> = ({
  className,
  milkingParlorFlowRateReportRows,
}) => {
  const columnConfigs: TableColumnConfig<TableRow>[] = [
    {
      key: 'penGroup',
      title: 'Группа',
      renderCellContent: row => {
        if (isGroupedReportRow(row)) {
          return 'Итого';
        }
        return formatPenGroup(row.penGroup);
      },
      width: PEN_GROUP_COLUMN_WIDTH_PX,
    },
    {
      key: 'cowStallPerHour',
      title: 'Оборот стада, коров/аппарат в час',
      renderCellContent: row =>
        formatNumber(row.cowStallPerHour, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'flowRate0To15Sec',
      title: 'Скорость потока от 0 до 15 сек., кг/мин.',
      renderCellContent: row =>
        formatNumber(row.flowRate0To15Sec, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'flowRate15To30Sec',
      title: 'Скорость потока от 15 до 30 сек., кг/мин.',
      renderCellContent: row =>
        formatNumber(row.flowRate15To30Sec, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'flowRate30To60Sec',
      title: 'Скорость потока от 30 до 60 сек., кг/мин.',
      renderCellContent: row =>
        formatNumber(row.flowRate30To60Sec, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'peakFlowRate',
      title: 'Пиковая скорость потока, кг/мин.',
      renderCellContent: row =>
        formatNumber(row.peakFlowRate, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkWeight0To120Sec',
      title: 'Надой за первые 2 мин., кг',
      renderCellContent: row =>
        formatNumber(row.milkWeight0To120Sec, TABLE_FLOAT_CELL_PRECISION),
      ...BASE_REPORT_COLUMN_PROPS,
    },
    {
      key: 'milkWeightPercent0To120Sec',
      title: 'Процент молока за первые 2 мин.',
      renderCellContent: row =>
        formatWithPercent(
          row.milkWeightPercent0To120Sec,
          TABLE_FLOAT_CELL_PRECISION
        ),
      ...BASE_REPORT_COLUMN_PROPS,
    },
  ];

  return (
    <Table<TableRow>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        getItemKey: (item, index) =>
          isGroupedReportRow(item) ? 'total' : (item.penGroup?.id ?? index),
        items: milkingParlorFlowRateReportRows,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
      }}
    />
  );
};
