import React from 'react';

import { lightFormat } from 'date-fns';

import { StandardView } from '~/~legacy/value-editors/components/StandardView';
import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

export const DateView: React.FC<ValueEditorStandardProps<string>> = props => {
  const { caption, hideControls, toggle, value } = props;

  const date = value
    ? lightFormat(new Date(value), 'yyyy-MM-dd')
    : 'Date is not set';
  return (
    <StandardView
      caption={caption}
      hideControls={hideControls}
      toggle={toggle}
      value={date}
    />
  );
};
