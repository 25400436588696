import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintEditService,
  BlueprintFilterGroupNode,
  BlueprintNodeType,
  BlueprintUnionNode,
} from '../../services';
import { FilterGroupNode } from '../FilterGroupNode';
import { UnionNodeError } from './components/UnionNodeError';
import { UnionNodeUI } from './components/UnionNodeUI';

interface Props {
  union: BlueprintUnionNode;
  filterGroup?: BlueprintFilterGroupNode;
}

export const UnionNode: React.FC<Props> = props => {
  const { union, filterGroup } = props;

  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const memoizedUpdate = useMemo(() => {
    return bpEditSvc.updateUnion.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    errors: updateErrors,
    loading: updating,
    reload: updateUnion,
  } = useApiData(memoizedUpdate);

  const update = useCallback(
    (name: string) => {
      updateUnion({
        id: union.id,
        name,
      });
    },
    [updateUnion, union.id]
  );

  const memoizedDel = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc]);

  const { errors: delErrors, loading: deleting } = useApiData(memoizedDel);

  const del = useCallback(() => {
    memoizedDel({
      nodeId: union.id,
      nodeType: BlueprintNodeType.Union,
    });
  }, [union.id]);

  const errors = useMemo(() => {
    return [...delErrors, ...updateErrors];
  }, [delErrors, updateErrors]);

  const loading = useMemo(() => {
    return updating || deleting;
  }, [updating, deleting]);

  const content = filterGroup ? (
    <FilterGroupNode filterGroup={filterGroup} />
  ) : (
    <UnionNodeError />
  );

  return (
    <UnionNodeUI
      name={union.name}
      update={update}
      del={del}
      errors={errors}
      loading={loading}
    >
      {content}
    </UnionNodeUI>
  );
};
