import { DependencyList, useMemo } from 'react';

import { useLocalStorage } from '~/shared/hooks/useLocalStorage';

const DEBUG_KEY = '_debug';

export const useDebugProps = (
  getDebugInfo: () => any,
  deps: DependencyList
) => {
  const [isDebugState] = useLocalStorage(DEBUG_KEY, false);

  return useMemo(() => {
    if (!isDebugState) {
      return undefined;
    }
    const stringBody = JSON.stringify(getDebugInfo(), undefined, '  ');
    return {
      title: stringBody,
    };
  }, [...deps, isDebugState]);
};
