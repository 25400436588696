import React from 'react';

import clsx from 'clsx';
import * as yup from 'yup';

import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Input } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useAuth } from '~/services/auth';
import { useRequestAuthCodeMutation } from '~/services/auth/gql/mutations/requestAuthCode.graphql';
import { useValidateAuthCodeMutation } from '~/services/auth/gql/mutations/validateAuthCode.graphql';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';

import panelStyles from '~/styles/modules/panel.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Email, used to request the auth code
   */
  email: string;
  /**
   * Called, when the user choose to enter a different email
   */
  onPrevStep: () => void;
  /**
   * Called, when the user logged in successfully
   */
  onLogin: () => void;
}

const FORM_ID = 'LoginStepAuthCodeForm';

const SCHEMA = yup.object({
  authCode: yup.string().default(''),
});

type LoginStepAuthCodeFormType = InferValidatedSchema<typeof SCHEMA>;

export const LoginStepAuthCodeForm: React.FC<Props> = ({
  className,
  email,
  onPrevStep,
  onLogin,
}) => {
  const [requestAuthCode] = useRequestAuthCodeMutation();
  const [validateAuthCode] = useValidateAuthCodeMutation();

  const { login } = useAuth();

  const formContext = useForm<LoginStepAuthCodeFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = (form: LoginStepAuthCodeFormType) => {
    validateAuthCode({
      variables: {
        email,
        code: form.authCode,
      },
    }).then(({ data }) => {
      if (!data) return;

      login(data.validateAuthCode);
      onLogin();
    });
  };

  return (
    <Form
      {...{
        className: clsx(panelStyles.panel, 'grid gap-16 p-24', className),
        formContext,
        id: FORM_ID,
        onSubmit: formContext.handleSubmit(handleSubmit),
      }}
    >
      <Typography variant={TypographyVariants.bodySmall}>
        Проверьте почту, пожалуйста — мы отправили код для входа на адрес{' '}
        {email}
      </Typography>

      <Input
        {...{
          name: 'authCode',
          label: 'Введите код',
        }}
      />

      <div className="flex justify-between">
        <Button
          {...{
            variant: ButtonVariants.ghost,
            size: ButtonSizes.small24,
            onPress: () => requestAuthCode({ variables: { email } }),
          }}
        >
          Прислать код ещё раз
        </Button>

        <Button
          {...{
            className: 'text-muted',
            variant: ButtonVariants.ghost,
            size: ButtonSizes.small24,
            onPress: onPrevStep,
          }}
        >
          другой email
        </Button>
      </div>

      <Button type="submit">Подтвердить</Button>
    </Form>
  );
};
