import React from 'react';

import {
  FemaleAnimalKind,
  ReproductionHdrAndPrXAxisMode,
  ReproductionHdrAndPrYAxisMetric,
  VitalityFilter,
} from '@graphql-types';
import clsx from 'clsx';

import { Checkbox } from '~/shared/components/Checkbox';
import { Input, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { formatInt } from '~/shared/helpers/number';

import { DatePeriodPicker } from '~/services/dateTime';
import { Form } from '~/services/forms';

import { CowAsyncSelect } from '~/entities/cows';
import { FarmAsyncSelect } from '~/entities/farms';

import {
  CompareToPreviousPeriodModeSelect,
  LactationGroupNumberSelect,
} from '~/features/milkingAnalytics';
import {
  ReportCardBuilderFormArrayItem,
  ReportCardBuilderFormSection,
} from '~/features/reportCardBuilder';

import contentGriStyles from '~/styles/modules/contentGrid.module.scss';

import {
  DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS,
  DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS,
  REPRODUCTION_HDR_AND_PR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT,
  REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_ID,
  REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS,
} from '../../constants';
import { ReproductionHdrAndPrReportFiltersAndGroupingMenuItems } from '../../types';
import { FemaleAnimalKindSelect } from '../FemaleAnimalKindSelect';
import { ReproductionCrReportChartKindSelect } from '../ReproductionCrReportChartKindSelect';
import { ReproductionYAxisModeSelect } from '../ReproductionYAxisModeSelect';
import { ReproductionHdrAndPrXAxisModeSelect } from './components/ReproductionHdrAndPrReportXAxisModeSelect';
import { useReproductionHdrAndPrReportSettingsForm } from './hooks/useReproductionHdrAndPrReportSettingsForm';
import { ReproductionHdrAndPrReportSettingsFormProps } from './types';

export const ReproductionHdrAndPrReportSettingsForm: React.FC<
  ReproductionHdrAndPrReportSettingsFormProps
> = props => {
  const { className, onSettingsFormSave } = props;

  const {
    formContext,
    formValues,

    availableForAddingYAxisMetricsItems,
    addToYAxisMetricsArray,
    removeFromYAxisMetricsArray,

    availableForFiltersAndGroupingItems,
  } = useReproductionHdrAndPrReportSettingsForm(props);

  const isCows = formValues.femaleAnimalKind === FemaleAnimalKind.Cow;

  const defaultVoluntaryWaitingPeriodDays = isCows
    ? DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS
    : DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS;
  const isDefaultVoluntaryWaitingPeriod =
    formValues.voluntaryWaitingPeriodDays === defaultVoluntaryWaitingPeriodDays;
  const voluntaryWaitingPeriodInputFeedback = isDefaultVoluntaryWaitingPeriod
    ? undefined
    : `Значение по умолчанию: ${formatInt(defaultVoluntaryWaitingPeriodDays)} дней`;

  const isAddingYAxisMetricsDisabled =
    !availableForAddingYAxisMetricsItems.length ||
    formValues.yAxisShouldCompareToPreviousPeriod;

  const addingMetricsDisabledTooltip =
    formValues.yAxisShouldCompareToPreviousPeriod
      ? 'Добавление недоступно, так как выбран режим сравнения с предыдущим периодом'
      : 'Добавление недоступно, так как все показатели для отображения уже выбраны';

  return (
    <Form
      {...{
        className: clsx(
          'items-start',
          contentGriStyles.autoGridStaticItems,
          className
        ),
        formContext,
        id: REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_ID,
        onSubmit: formContext.handleSubmit(onSettingsFormSave),
      }}
    >
      <ReportCardBuilderFormSection title="Основное">
        <FarmAsyncSelect
          {...{
            name: 'farmID',
            label: 'Ферма',
          }}
        />
        <DatePeriodPicker
          {...{
            name: 'period',
            label: 'Период',
          }}
        />
        <FemaleAnimalKindSelect
          {...{
            label: 'Животные',
            name: 'femaleAnimalKind',
            onValueChange: newValue => {
              if (isDefaultVoluntaryWaitingPeriod) {
                formContext.setValue(
                  'voluntaryWaitingPeriodDays',
                  (newValue?.id as FemaleAnimalKind) === FemaleAnimalKind.Cow
                    ? DEFAULT_COWS_VOLUNTARY_WAITING_PERIOD_DAYS
                    : DEFAULT_HEIFERS_VOLUNTARY_WAITING_PERIOD_DAYS
                );
              }
            },
          }}
        />
        <Input
          key={formValues.femaleAnimalKind}
          {...{
            name: 'voluntaryWaitingPeriodDays',
            label: isCows
              ? 'Период добровольного ожидания у коров, дней'
              : 'Период добровольного ожидания у тёлок, дней',
            variant: InputVariants.int,
            feedback: voluntaryWaitingPeriodInputFeedback,
            shouldChangeValueWithDebounce: true,
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection title="Ось X">
        <ReproductionHdrAndPrXAxisModeSelect
          {...{
            label: 'Режим отображения',
            name: 'xAxisMode',
            additionalProps: {
              femaleAnimalKind: formValues.femaleAnimalKind,
            },
            onValueChange: newValue => {
              if (newValue?.id === ReproductionHdrAndPrXAxisMode.Date) {
                formContext.setValue(
                  'yAxisMetrics',
                  formValues.yAxisMetrics.filter(
                    metric =>
                      metric !== ReproductionHdrAndPrYAxisMetric.NotPregnant
                  )
                );
              }
            },
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection<
        (typeof availableForAddingYAxisMetricsItems)[number]
      >
        {...{
          title: 'Ось Y',
          withChildrenCount: true,
          childrenCount: formValues.yAxisMetrics.length,
          noChildrenMessage: 'Список значений оси Y пока пуст',
          contextMenuButtonProps: {
            items: availableForAddingYAxisMetricsItems,
            isDisabled: isAddingYAxisMetricsDisabled,
            tooltip: isAddingYAxisMetricsDisabled
              ? addingMetricsDisabledTooltip
              : undefined,
            menuProps: {
              onItemPress: addToYAxisMetricsArray,
            },
          },
        }}
      >
        {formValues.yAxisMetrics.map((yAxisMetric, index) => (
          <ReportCardBuilderFormArrayItem
            key={yAxisMetric}
            {...{
              title:
                REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric]
                  .label,
              onDelete: () => {
                removeFromYAxisMetricsArray(index);
              },
            }}
          >
            {REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric]
              .withComparison && (
              <>
                <Checkbox
                  {...{
                    name: 'yAxisShouldCompareToPreviousPeriod',
                    label: 'Сравнить с предыдущим периодом',
                    isDisabled: formValues.yAxisMetrics.length > 1,
                    disabledTooltip:
                      'Сравнение недоступно, когда на ось Y добавлено более одного показателя',
                  }}
                />
                {formValues.yAxisShouldCompareToPreviousPeriod && (
                  <>
                    <CompareToPreviousPeriodModeSelect
                      {...{
                        name: 'yAxisCompareToPreviousPeriodMode',
                        label: 'Режим сравнения',
                        theme: SelectThemes.light,
                      }}
                    />
                    <ReproductionCrReportChartKindSelect
                      {...{
                        label: 'Тип диаграммы',
                        name: 'yAxisCompareToPreviousPeriodChartKind',
                        theme: SelectThemes.light,
                      }}
                    />
                  </>
                )}
              </>
            )}
          </ReportCardBuilderFormArrayItem>
        ))}
        <ReproductionYAxisModeSelect
          {...{
            name: 'yAxisMode',
            label: 'Шкала',
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection<
        (typeof availableForFiltersAndGroupingItems)[number]
      >
        {...{
          title: 'Фильтры',
          noChildrenMessage: 'Список фильтров пока пуст',
          contextMenuButtonProps: {
            items: availableForFiltersAndGroupingItems,
            isDisabled: !availableForFiltersAndGroupingItems.length,
            tooltip: !availableForFiltersAndGroupingItems.length
              ? 'Добавление недоступно, так как все фильтры уже выбраны'
              : undefined,
          },
        }}
      >
        {!!formValues.lactationGroupNumbers && (
          <ReportCardBuilderFormArrayItem
            {...{
              title:
                REPRODUCTION_HDR_AND_PR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[
                  ReproductionHdrAndPrReportFiltersAndGroupingMenuItems
                    .filterLactations
                ],
              onDelete: () =>
                formContext.setValue('lactationGroupNumbers', null),
            }}
          >
            <LactationGroupNumberSelect
              {...{
                name: 'lactationGroupNumbers',
                label: 'Группа лактации',
                isMulti: true,
                theme: SelectThemes.light,
              }}
            />
          </ReportCardBuilderFormArrayItem>
        )}

        {!!formValues.cowIDs && (
          <ReportCardBuilderFormArrayItem
            {...{
              title:
                REPRODUCTION_HDR_AND_PR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[
                  ReproductionHdrAndPrReportFiltersAndGroupingMenuItems
                    .filterAnimals
                ],
              onDelete: () => formContext.setValue('cowIDs', null),
            }}
          >
            <CowAsyncSelect
              {...{
                queryOptions: {
                  variables: {
                    vitalityFilter: VitalityFilter.Alive,
                  },
                },
                label: 'Номер животного',
                name: 'cowIDs',
                isMulti: true,
                theme: SelectThemes.light,
              }}
            />
          </ReportCardBuilderFormArrayItem>
        )}
      </ReportCardBuilderFormSection>
    </Form>
  );
};
