import {
  BlueprintLaunchInputValueInput,
  BullRetirementReason,
  CowRetirementReason,
  CowState,
  Dow,
  EventKindEnum,
  LactationState,
  ValueKindEnum,
  VitalityFilter,
} from '@graphql-types';
import R from 'ramda';
import { match } from 'ts-pattern';
import * as yup from 'yup';

import {
  BaseInputProps,
  Input,
  InputVariants,
} from '~/shared/components/Input';
import { Select, SelectBaseProps } from '~/shared/components/Select';
import { oneOfEnum } from '~/shared/helpers/yup';
import { BaseFieldProps } from '~/shared/types/controls';
import { PartialExcept, ValueOf } from '~/shared/types/utility';

import {
  DateInput,
  DateInputProps,
  DowSelect,
  formatDateForBackend,
} from '~/services/dateTime';

import { BullAsyncSelect, BullRetirementReasonSelect } from '~/entities/bulls';
import { CalvingAsyncSelect } from '~/entities/calvings';
import {
  CowAsyncSelect,
  CowRetirementReasonSelect,
  CowStateSelect,
  LactationStateSelect,
} from '~/entities/cows';
import { EmployeeAsyncSelect } from '~/entities/employees';
import { EventAsyncSelect } from '~/entities/events';
import { FarmAsyncSelect } from '~/entities/farms';
import { InseminationAsyncSelect } from '~/entities/inseminations';
import { PenGroupAsyncSelect } from '~/entities/penGroups';
import { SemenDoseAsyncSelect } from '~/entities/semenDoses';

/**
 * Helper to get form schema for an input with a given value kind
 */
export const getValidationsByValueKind = (valueKind: ValueKindEnum) =>
  match(valueKind)
    .with(
      ValueKindEnum.Bool,
      R.always(yup.number().oneOf([0, 1]).default(null))
    )
    .with(
      ValueKindEnum.BullId,
      ValueKindEnum.CalvingId,
      ValueKindEnum.CowId,
      ValueKindEnum.DiseaseId,
      ValueKindEnum.EmployeeId,
      ValueKindEnum.EventId,
      ValueKindEnum.FarmId,
      ValueKindEnum.InjectionId,
      ValueKindEnum.InseminationId,
      ValueKindEnum.InseminationSchemeId,
      ValueKindEnum.PenGroupId,
      ValueKindEnum.ProtocolId,
      ValueKindEnum.SemenDoseBatchId,
      ValueKindEnum.UserEventId,
      R.always(yup.string())
    ) // ID
    .with(
      ValueKindEnum.CowIds,
      ValueKindEnum.EventIds,
      R.always(yup.array(yup.string().required()))
    ) // [ID]
    .with(
      ValueKindEnum.BullRetirementReason,
      R.always(oneOfEnum(BullRetirementReason).default(null))
    )
    .with(
      ValueKindEnum.CowRetirementReason,
      R.always(oneOfEnum(CowRetirementReason).default(null))
    )
    .with(ValueKindEnum.CowState, R.always(oneOfEnum(CowState).default(null)))
    .with(
      ValueKindEnum.LactationState,
      R.always(oneOfEnum(LactationState).default(null))
    )
    .with(ValueKindEnum.Date, R.always(yup.string().default(''))) // Date!
    .with(ValueKindEnum.Dow, R.always(oneOfEnum(Dow).default(null)))
    .with(
      ValueKindEnum.Float,
      ValueKindEnum.Int,
      ValueKindEnum.Numeric,
      ValueKindEnum.Index,
      ValueKindEnum.LactationIndex,
      R.always(yup.number().default(null))
    )
    .with(ValueKindEnum.Text, R.always(yup.string()))
    // Some value, so if we apply required, validation will pass, we don't send it to backend anyway
    .with(ValueKindEnum.Void, R.always(yup.string().default('void')))
    .exhaustive();

/**
 * Helper to get backend input container for a given value kind and a raw input value
 */
export const getBackendInputByValueKind = (
  valueKind: ValueKindEnum,
  inputValue: ValueOf<BlueprintLaunchInputValueInput>,
  // TODO remove, when we switch from datetimeValue to dateValue in blueprints and custom reports
  shouldUseDeprecatedDate = false
): BlueprintLaunchInputValueInput =>
  match(valueKind)
    .with(
      ValueKindEnum.BullId,
      ValueKindEnum.CalvingId,
      ValueKindEnum.CowId,
      ValueKindEnum.DiseaseId,
      ValueKindEnum.EmployeeId,
      ValueKindEnum.FarmId,
      ValueKindEnum.InjectionId,
      ValueKindEnum.InseminationId,
      ValueKindEnum.InseminationSchemeId,
      ValueKindEnum.PenGroupId,
      ValueKindEnum.ProtocolId,
      ValueKindEnum.SemenDoseBatchId,
      ValueKindEnum.UserEventId,
      () => ({ idValue: inputValue?.toString() })
    )
    .with(ValueKindEnum.CowIds, ValueKindEnum.EventIds, () =>
      Array.isArray(inputValue) ? { idsValue: inputValue } : {}
    )
    .with(
      // We use shortcodes for default events, so we send EventId as string
      ValueKindEnum.EventId,
      ValueKindEnum.BullRetirementReason,
      ValueKindEnum.CowRetirementReason,
      ValueKindEnum.CowState,
      ValueKindEnum.LactationState,
      ValueKindEnum.Dow,
      ValueKindEnum.Text,
      () => ({ strValue: inputValue?.toString() })
    )
    .with(
      ValueKindEnum.Bool,
      ValueKindEnum.Int,
      ValueKindEnum.Index,
      ValueKindEnum.LactationIndex,
      () => (typeof inputValue === 'number' ? { intValue: inputValue } : {})
    )
    .with(ValueKindEnum.Float, ValueKindEnum.Numeric, () =>
      typeof inputValue === 'number'
        ? {
            floatValue: inputValue,
          }
        : {}
    )
    .with(ValueKindEnum.Date, () => ({
      [shouldUseDeprecatedDate ? 'datetimeValue' : 'dateValue']:
        formatDateForBackend(inputValue?.toString(), true) || null,
    }))
    .with(ValueKindEnum.Void, R.always({}))
    .exhaustive();

/**
 * Helper to render a corresponding control for a given value kind
 */
export const renderInputByValueKind = (
  valueKind: ValueKindEnum,
  generalProps: PartialExcept<BaseFieldProps<any>, 'name'>,
  {
    inputProps,
    selectProps,
    dateInputProps,
  }: {
    inputProps?: Partial<BaseInputProps>;
    selectProps?: Omit<Partial<SelectBaseProps<any>>, 'onKeyDown'>;
    dateInputProps?: Partial<DateInputProps>;
  } = {}
) => {
  const inputGeneralProps = { ...generalProps, ...inputProps };

  const selectGeneralProps = {
    ...generalProps,
    ...selectProps,
  };

  const dateInputGeneralProps = { ...generalProps, ...dateInputProps };
  return (
    match(valueKind)
      // Entities
      .with(ValueKindEnum.DiseaseId, () => (
        <EventAsyncSelect
          {...{
            ...selectGeneralProps,
            placeholder: 'Выберите болезнь',
            queryOptions: {
              variables: {
                kinds: [EventKindEnum.Disease],
              },
            },
          }}
        />
      ))
      .with(ValueKindEnum.InjectionId, () => (
        <EventAsyncSelect
          {...{
            ...selectGeneralProps,
            placeholder: 'Выберите инъекцию',
            queryOptions: {
              variables: {
                kinds: [EventKindEnum.Injection],
              },
            },
          }}
        />
      ))
      .with(ValueKindEnum.EventId, ValueKindEnum.EventIds, matchedKind => (
        <EventAsyncSelect
          {...{
            ...selectGeneralProps,
            isMulti: matchedKind === ValueKindEnum.EventIds,
          }}
        />
      ))
      .with(ValueKindEnum.InseminationSchemeId, () => (
        <EventAsyncSelect
          {...{
            ...selectGeneralProps,
            placeholder: 'Выберите схему осеменения',
            queryOptions: {
              variables: {
                kinds: [EventKindEnum.InseminationScheme],
              },
            },
          }}
        />
      ))
      .with(ValueKindEnum.ProtocolId, () => (
        <EventAsyncSelect
          {...{
            ...selectGeneralProps,
            placeholder: 'Выберите протокол',
            queryOptions: {
              variables: {
                kinds: [EventKindEnum.Protocol],
              },
            },
          }}
        />
      ))
      .with(ValueKindEnum.UserEventId, () => (
        <EventAsyncSelect
          {...{
            ...selectGeneralProps,
            queryOptions: {
              variables: {
                kinds: [EventKindEnum.User],
              },
            },
          }}
        />
      ))
      .with(ValueKindEnum.InseminationId, () => (
        <InseminationAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.PenGroupId, () => (
        <PenGroupAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.SemenDoseBatchId, () => (
        <SemenDoseAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.EmployeeId, () => (
        <EmployeeAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.CalvingId, () => (
        <CalvingAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.BullId, () => (
        <BullAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.FarmId, () => (
        <FarmAsyncSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.CowId, ValueKindEnum.CowIds, matchedKind => (
        <CowAsyncSelect
          {...{
            ...selectGeneralProps,
            isMulti: matchedKind === ValueKindEnum.CowIds,
            queryOptions: {
              variables: {
                vitalityFilter: VitalityFilter.Alive,
              },
            },
          }}
        />
      ))

      // Enums
      .with(ValueKindEnum.LactationState, () => (
        <LactationStateSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.CowRetirementReason, () => (
        <CowRetirementReasonSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.BullRetirementReason, () => (
        <BullRetirementReasonSelect {...selectGeneralProps} />
      ))
      .with(ValueKindEnum.Dow, () => <DowSelect {...selectGeneralProps} />)
      .with(ValueKindEnum.CowState, () => (
        <CowStateSelect {...selectGeneralProps} />
      ))

      // Basic inputs
      .with(ValueKindEnum.Date, () => <DateInput {...dateInputGeneralProps} />)
      .with(ValueKindEnum.Float, ValueKindEnum.Numeric, () => (
        <Input
          {...{
            ...inputGeneralProps,
            variant: InputVariants.float,
          }}
        />
      ))
      .with(
        ValueKindEnum.Index,
        ValueKindEnum.Int,
        ValueKindEnum.LactationIndex,
        () => (
          <Input
            {...{
              ...inputGeneralProps,
              variant: InputVariants.int,
            }}
          />
        )
      )
      .with(ValueKindEnum.Text, () => <Input {...inputGeneralProps} />)
      .with(ValueKindEnum.Bool, () => (
        <Select
          {...{
            ...selectGeneralProps,
            items: [
              {
                id: 1,
                name: 'Да',
              },
              {
                id: 0,
                name: 'Нет',
              },
            ],
          }}
        />
      ))
      .with(ValueKindEnum.Void, R.always(null))
      .exhaustive()
  );
};
