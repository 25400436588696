/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
import { AppLocalStorageKeys } from '~/shared/constants';

import {
  CHANGE_FARM_ERROR_CODE,
  CustomAppHeaders,
  getNumberPartFromGlobalId,
  GqlAuthProvider,
} from '~/services/gql';

interface GraphQL<T> {
  query: string;
  variables?: T;
}

export interface HTTPTransport {
  get<T = any>(url: string, options?: any): Promise<T>;
  post<T = any>(url: string, data: any, options?: any): Promise<T>;
  gql<T = any, F = any>(
    url: string,
    data: GraphQL<F>,
    options?: any
  ): Promise<T>;
  put<T = any>(url: string, data: any, options?: any): Promise<T>;
  patch<T = any>(url: string, data: any, options?: any): Promise<T>;
  delete<T = any>(url: string, options?: any): Promise<T>;

  set401Callback: (callback: () => void) => void;
  // removeCallback:()=>void
}

enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
}

enum HttpResponseType {
  json = 'json',
  blob = 'blob',
}

export class FetchHTTPTransport implements HTTPTransport {
  private commonOptions: any;

  // TODO: add type for the callback or think about system of callbacks
  private unauthCallback: any = () => {};

  private gqlAuthProvider: GqlAuthProvider;

  private readonly getSelectedCompanyId: () => string;

  constructor(
    gqlAuthProvider: GqlAuthProvider,
    getSelectedCompanyId: () => string,
    options?: any
  ) {
    this.commonOptions = options || {};
    this.gqlAuthProvider = gqlAuthProvider;
    this.getSelectedCompanyId = getSelectedCompanyId;
  }

  private async makeRequest(
    method: HTTPMethod,
    url: string,
    options: any
  ): Promise<any> {
    let isSuccess = false;
    while (!isSuccess) {
      const { commonOptions } = this;

      const authToken =
        localStorage.getItem(AppLocalStorageKeys.accessToken) ?? '';
      const authHeader = authToken
        ? {
            Authorization: `Bearer ${authToken}`,
          }
        : {};

      const companyId = this.getSelectedCompanyId();
      const companyHeader = companyId
        ? {
            [CustomAppHeaders.activeCompany]:
              getNumberPartFromGlobalId(companyId),
          }
        : {};

      const headers = {
        ...authHeader,
        ...companyHeader,
        ...commonOptions.headers,
        ...options.headers,
      };

      const response = await fetch(url, {
        method,

        ...commonOptions,
        ...options,
        headers,
      });

      const result =
        options.responseType === HttpResponseType.blob
          ? await response.blob()
          : await response.json();

      // if user is not authorized
      if (response.status === 401) {
        localStorage.removeItem(AppLocalStorageKeys.accessToken);
        this.unauthCallback();
        isSuccess = true;
      }

      if (response.status === 419) {
        await this.gqlAuthProvider.refreshToken();
        continue;
      }

      if (!response.ok) {
        throw result;
      }

      isSuccess = response.ok;

      return result;
    }
  }

  get<T = any>(url: string, options: any = {}): Promise<T> {
    return this.makeRequest(HTTPMethod.GET, url, options);
  }

  post<T = any>(url: string, data: any, options: any = {}): Promise<T> {
    // for sending files use options object to set body to multipart form data and appropriate headers

    return this.makeRequest(HTTPMethod.POST, url, {
      body: JSON.stringify(data),
      ...options,
    });
  }

  async gql<T = any>(url: string, data: any, options: any = {}): Promise<T> {
    // for sending files use options object to set body to multipart form data and appropriate headers

    const response = await this.makeRequest(HTTPMethod.POST, url, {
      body: JSON.stringify(data),
      ...options,
      headers: {
        ...options?.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (Array.isArray(response.errors)) {
      // throw response;

      console.error(response.errors);

      const keys = response.data ? Object.keys(response.data) : [];
      const oneKey = keys.length === 1;
      const emptyKey = oneKey ? response.data[keys[0]] === null : false;

      if (
        response.errors.some(
          (err: any) => err.extensions?.code === CHANGE_FARM_ERROR_CODE
        ) ||
        !response.data ||
        emptyKey
      ) {
        throw response;
      }
    }

    return response.data;
  }

  put<T = any>(url: string, data: any, options: any = {}): Promise<T> {
    return this.makeRequest(HTTPMethod.PUT, url, {
      body: JSON.stringify(data),
      ...options,
    });
  }

  patch<T = any>(url: string, data: any, options: any = {}): Promise<T> {
    return this.makeRequest(HTTPMethod.PATCH, url, {
      body: JSON.stringify(data),
      ...options,
    });
  }

  delete<T = any>(url: string, options: any = {}): Promise<T> {
    return this.makeRequest(HTTPMethod.DELETE, url, options);
  }

  set401Callback(callback: any) {
    const emptyFunc = () => {};
    this.unauthCallback = callback || emptyFunc;
  }
}
