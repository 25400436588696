import React from 'react';
import { useTranslation } from 'react-i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { MErrorMessage } from '~/shared/components/MErrorMessage';

export const UnionNodeError: React.FC = () => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  return (
    <MErrorMessage>
      {t(IntegratorStrings.blueprintEdit.nodes.unionNode.errorMessage)}
    </MErrorMessage>
  );
};
