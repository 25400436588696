import React from 'react';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';

import { useConfirm } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';

interface Props {
  blueprint: BlueprintFragment;
  className?: string;
  onDelete?: () => void;
}

export const BlueprintCardMenu: React.FC<Props> = ({
  blueprint,
  className,
  onDelete,
}) => {
  const confirmDelete = useConfirm();

  const { navigate, urlCompanyId } = useArkaNavigation();

  return (
    <ContextMenuButton
      {...{
        className,
        tooltip: 'Действия с командой',
        items: [
          {
            content: 'Запустить',
            onPress: () =>
              navigate({
                to: '/$companyId/user/blueprint/$blueprintId',
                params: {
                  blueprintId: blueprint.id,
                  companyId: urlCompanyId,
                },
              }),
          },
          {
            variant: MenuItemVariants.delimiter,
          },
          {
            content: 'Настройки',
            onPress: () =>
              navigate({
                to: '/$companyId/integrator/blueprints/$blueprintId',
                params: {
                  blueprintId: blueprint.id,
                  companyId: urlCompanyId,
                },
              }),
          },
          {
            content: 'Редактировать',
            onPress: () =>
              navigate({
                to: '/$companyId/integrator/blueprints/edit/$blueprintId',
                params: {
                  blueprintId: blueprint.id,
                  companyId: urlCompanyId,
                },
              }),
          },
          onDelete
            ? {
                variant: MenuItemVariants.destructive,
                content: 'Удалить',
                onPress: async () => {
                  const isConfirmed = await confirmDelete({
                    message: `Удалить блюпринт [${blueprint.name}]?`,
                  });
                  if (isConfirmed) {
                    onDelete();
                  }
                },
              }
            : null,
        ].filter(Boolean),
      }}
    />
  );
};
