import React, { ReactNode } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  getSkeletonPlaceholders,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { formatDateForBackend } from '~/services/dateTime';
import { WithSearchParamsValidation } from '~/services/navigation';

import { FarmFilterSearchParams } from '~/entities/farms';

import { MilkingParlorChart } from '~/widgets/milkingParlorChart';
import { useMilkingParlorScheduleReportEntriesQuery } from '~/widgets/milkingParlorChart/gql/queries/milkingParlorScheduleReportEntries.graphql';
import {
  MilkingFlowRateReportTable,
  MilkingGeneralReportTable,
  MilkingMeterFunctionReportTable,
  MilkingMilkingMistakesReportTable,
  MilkingMixedMilkingReportTable,
} from '~/widgets/milkingParlorReports';
import { useMilkingParlorFlowRateReportRowsQuery } from '~/widgets/milkingParlorReports/gql/queries/milkingParlorFlowRateReportRows.graphql';
import { useMilkingParlorGeneralReportRowsQuery } from '~/widgets/milkingParlorReports/gql/queries/milkingParlorGeneralReportRows.graphql';
import { useMilkingParlorMeterFunctionReportRowsQuery } from '~/widgets/milkingParlorReports/gql/queries/milkingParlorMeterFunctionReportRows.graphql';
import { useMilkingParlorMilkingMistakesReportRowsQuery } from '~/widgets/milkingParlorReports/gql/queries/milkingParlorMilkingMistakesReportRows.graphql';
import { useMilkingParlorMixedMilkingReportRowsQuery } from '~/widgets/milkingParlorReports/gql/queries/milkingParlorMixedMilkingReportRows.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import {
  MILKING_REPORT_KINDS_DICT,
  MilkingReportFiltersSearchParams,
  MilkingReportKinds,
  useMilkingReportsFilters,
} from './hooks/useMilkingReportsFilters';

const MILKING_PARLOR_REPORT_ROWS_DEFAULT_COUNT = 8;

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/milking/by-milking-parlor/'
)({
  wrapInSuspense: true,
  component: AnalyticsMilkingByMikingParlorPage,
  validateSearch: ({
    milkingDate,
    milkingParlorId,
    milkingNumber,
    farmId,
  }: WithSearchParamsValidation<
    MilkingReportFiltersSearchParams & FarmFilterSearchParams
  >) => {
    const defaultDate = formatDateForBackend(dayjs(), true);

    return {
      milkingDate: milkingDate ?? defaultDate,
      milkingParlorId: milkingParlorId ?? undefined,
      milkingNumber: milkingNumber ?? undefined,
      farmId: farmId ?? null,
    };
  },
});

function AnalyticsMilkingByMikingParlorPage() {
  const navigate = Route.useNavigate();

  const {
    isMilkingParlorsLoading,
    milkingParlorId,
    selectedReportKinds,
    reportsVariables,
    shouldSkipQueries,
    filterElements,
  } = useMilkingReportsFilters();

  // General report
  const {
    data: milkingParlorGeneralReportRowsQueryData,
    loading: isMilkingParlorGeneralReportLoading,
  } = useMilkingParlorGeneralReportRowsQuery({
    variables: reportsVariables,
    skip:
      shouldSkipQueries ||
      !selectedReportKinds.includes(MilkingReportKinds.general),
  });

  const milkingParlorGeneralReportRows =
    milkingParlorGeneralReportRowsQueryData?.milkingParlorGeneralReportRows ??
    getSkeletonPlaceholders(MILKING_PARLOR_REPORT_ROWS_DEFAULT_COUNT);

  // Mixed milking report
  const {
    data: milkingParlorMixedMilkingReportRowsQueryData,
    loading: isMilkingParlorMixedMilkingReportLoading,
  } = useMilkingParlorMixedMilkingReportRowsQuery({
    variables: reportsVariables,
    skip:
      shouldSkipQueries ||
      !selectedReportKinds.includes(MilkingReportKinds.mixedMilking),
  });

  const milkingParlorMixedMilkingReportRows =
    milkingParlorMixedMilkingReportRowsQueryData?.milkingParlorMixedMilkingReportRows ??
    getSkeletonPlaceholders(MILKING_PARLOR_REPORT_ROWS_DEFAULT_COUNT);

  // Flow rate report
  const {
    data: milkingParlorFlowRateReportRowsQueryData,
    loading: isMilkingParlorFlowRateReportLoading,
  } = useMilkingParlorFlowRateReportRowsQuery({
    variables: reportsVariables,
    skip:
      shouldSkipQueries ||
      !selectedReportKinds.includes(MilkingReportKinds.flowRate),
  });

  const milkingParlorFlowRateReportRows =
    milkingParlorFlowRateReportRowsQueryData?.milkingParlorFlowRateReportRows ??
    getSkeletonPlaceholders(MILKING_PARLOR_REPORT_ROWS_DEFAULT_COUNT);

  // Milking mistakes report
  const {
    data: milkingParlorMilkingMistakesReportRowsQueryData,
    loading: isMilkingParlorMilkingMistakesReportLoading,
  } = useMilkingParlorMilkingMistakesReportRowsQuery({
    variables: reportsVariables,
    skip:
      shouldSkipQueries ||
      !selectedReportKinds.includes(MilkingReportKinds.milkingMistakes),
  });

  const milkingParlorMilkingMistakesReportRows =
    milkingParlorMilkingMistakesReportRowsQueryData?.milkingParlorMilkingMistakesReportRows ??
    getSkeletonPlaceholders(MILKING_PARLOR_REPORT_ROWS_DEFAULT_COUNT);

  // Meter function report
  const {
    data: milkingParlorMeterFunctionReportRowsQueryData,
    loading: isMilkingParlorMeterFunctionReportLoading,
  } = useMilkingParlorMeterFunctionReportRowsQuery({
    variables: reportsVariables,
    skip:
      shouldSkipQueries ||
      !selectedReportKinds.includes(MilkingReportKinds.meterFunction),
  });

  const milkingParlorMeterFunctionReportRows =
    milkingParlorMeterFunctionReportRowsQueryData?.milkingParlorMeterFunctionReportRows ??
    getSkeletonPlaceholders(MILKING_PARLOR_REPORT_ROWS_DEFAULT_COUNT);

  // Milking parlor schedule report
  const {
    data: milkingParlorScheduleReportEntriesQueryData,
    loading: isMilkingParlorScheduleReportLoading,
  } = useMilkingParlorScheduleReportEntriesQuery({
    variables: reportsVariables,
    skip:
      shouldSkipQueries ||
      !selectedReportKinds.includes(MilkingReportKinds.milkingParlorChart),
  });

  const milkingParlorScheduleReportEntries =
    milkingParlorScheduleReportEntriesQueryData?.milkingParlorScheduleReportEntries ??
    [];

  const renderTableReport = (
    kind: MilkingReportKinds,
    isLoading: boolean,
    tableElement: ReactNode
  ) => {
    if (!selectedReportKinds.includes(kind)) {
      return null;
    }

    return (
      <div className={clsx(panelStyles.largePanel, 'p-24 mt-24')}>
        <Typography
          {...{
            tag: 'h2',
            variant: TypographyVariants.bodyLargeStrong,
            className: 'mb-24',
          }}
        >
          {MILKING_REPORT_KINDS_DICT[kind]}
        </Typography>
        <Skeleton isLoading={isLoading || shouldSkipQueries}>
          {tableElement}
        </Skeleton>
      </div>
    );
  };

  return (
    <>
      {!milkingParlorId && !isMilkingParlorsLoading && (
        <div
          className={clsx(
            layoutStyles.fillLeftoverHeightContainer,
            layoutStyles.limitedContainer,
            panelStyles.panel,
            'grid place-items-center'
          )}
        >
          <div className="grid place-items-center">
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message:
                  'Отчёты по залам доступны только на фермах с доильными залами',
                description:
                  'Если на вашей ферме есть доильные залы — перейдите к их настройке',
                buttonProps: {
                  onPress: () => {
                    navigate({
                      to: '/$companyId/user/entities/milking-parlors',
                    });
                  },
                  children: 'Перейти к доильным залам',
                },
              }}
            />
          </div>
        </div>
      )}
      {(!!milkingParlorId || isMilkingParlorsLoading) && (
        <div className={layoutStyles.limitedContainer}>
          <div className="flex gap-16">{filterElements}</div>

          {renderTableReport(
            MilkingReportKinds.general,
            isMilkingParlorGeneralReportLoading,
            <MilkingGeneralReportTable
              milkingParlorGeneralReportRows={milkingParlorGeneralReportRows}
            />
          )}

          {renderTableReport(
            MilkingReportKinds.mixedMilking,
            isMilkingParlorMixedMilkingReportLoading,
            <MilkingMixedMilkingReportTable
              milkingParlorMixedMilkingReportRows={
                milkingParlorMixedMilkingReportRows
              }
            />
          )}

          {renderTableReport(
            MilkingReportKinds.flowRate,
            isMilkingParlorFlowRateReportLoading,
            <MilkingFlowRateReportTable
              milkingParlorFlowRateReportRows={milkingParlorFlowRateReportRows}
            />
          )}

          {renderTableReport(
            MilkingReportKinds.milkingMistakes,
            isMilkingParlorMilkingMistakesReportLoading,
            <MilkingMilkingMistakesReportTable
              milkingParlorMilkingMistakesReportRows={
                milkingParlorMilkingMistakesReportRows
              }
            />
          )}

          {renderTableReport(
            MilkingReportKinds.meterFunction,
            isMilkingParlorMeterFunctionReportLoading,
            <MilkingMeterFunctionReportTable
              milkingParlorMeterFunctionReportRows={
                milkingParlorMeterFunctionReportRows
              }
            />
          )}

          {selectedReportKinds.includes(
            MilkingReportKinds.milkingParlorChart
          ) && (
            <Skeleton
              isLoading={
                isMilkingParlorScheduleReportLoading || shouldSkipQueries
              }
            >
              <MilkingParlorChart
                key={JSON.stringify(reportsVariables)}
                {...{
                  className: 'mt-24',
                  milkingParlorScheduleReportEntries,
                }}
              />
            </Skeleton>
          )}
        </div>
      )}
    </>
  );
}
