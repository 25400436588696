import React from 'react';

import clsx from 'clsx';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';

import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';

import panelStyles from '~/styles/modules/panel.module.scss';

import { isValidCustomReportChart } from '../../helpers';
import {
  CustomReportChartData,
  CustomReportChartSettingsFormType,
} from '../../types';
import { CustomReportValidChart } from './components/CustomReportValidChart';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Launch result to get blueprint source field values from nested columns that can be added to axes
   */
  customReportLaunchResult: CustomReportLaunchResultFragment;
  /**
   * Chart data to render
   */
  chartData: CustomReportChartData | null | SkeletonPlaceholder;
  /**
   * Settings, used to configure the chart
   */
  chartSettings: Omit<CustomReportChartSettingsFormType, 'name'>;
}

export const CustomReportChart: React.FC<Props> = ({
  className,
  customReportLaunchResult,
  chartData,
  chartSettings,
}) => {
  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
      {!isSkeletonPlaceholder(chartData) &&
        !isValidCustomReportChart(chartData) && (
          <DataBlockedMessage
            {...{
              className: 'p-24',
              message: match(chartData)
                .with(
                  P.union(null, {
                    __typename: 'CustomReportChartEmptyChart',
                  }),
                  R.always('Нет данных для отображения')
                )
                .with(
                  { __typename: 'CustomReportChartDirty' },
                  R.always(
                    'Недостаточно данных для отображения, проверьте настройки диаграммы'
                  )
                )
                .with(
                  { __typename: 'CustomReportChartEmptyReport' },
                  R.always(
                    'Недостаточно данных для отображения, проверьте настройки сводной таблицы'
                  )
                )
                .exhaustive(),
            }}
          />
        )}

      {(isSkeletonPlaceholder(chartData) ||
        isValidCustomReportChart(chartData)) && (
        <CustomReportValidChart
          key={chartSettings.kind}
          {...{
            customReportLaunchResult,
            chartData,
            chartSettings,
          }}
        />
      )}
    </div>
  );
};
