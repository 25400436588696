import React, { ReactNode } from 'react';

import clsx from 'clsx';

import {
  Button,
  ButtonPropsWithoutRef,
  ButtonVariants,
} from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Empty state message
   */
  message: ReactNode;
  /**
   * Props, passed to the button
   */
  buttonProps?: ButtonPropsWithoutRef;
}

export const EmptyState: React.FC<Props> = ({
  className,
  message,
  buttonProps,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <Typography variant={TypographyVariants.heading4}>{message}</Typography>
      {buttonProps && (
        <Button
          {...{
            className: 'mt-24',
            variant: ButtonVariants.secondary,
            ...buttonProps,
          }}
        />
      )}
    </div>
  );
};
