import R from 'ramda';
import { match, P } from 'ts-pattern';

import { formatInt, formatNumber } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';
import {
  makeUpdateFragment,
  makeUseFragmentFromCacheOrQuery,
} from '~/services/gql';

import {
  CustomReportFragment,
  CustomReportFragmentDoc,
} from './gql/fragments/customReport.graphql';
import { CustomReportBlueprintSourceFieldValueFragment } from './gql/fragments/customReportBlueprintSourceFieldValue.graphql';
import {
  CustomReportChartSettingsFragment,
  CustomReportChartSettingsFragmentDoc,
} from './gql/fragments/customReportChartSettings.graphql';
import {
  CustomReportDetailedFragment,
  CustomReportDetailedFragmentDoc,
} from './gql/fragments/customReportDetailed.graphql';
import { CustomReportSettingsValueFragment } from './gql/fragments/customReportSettingsValue.graphql';
import {
  CustomReportsDetailedQuery,
  CustomReportsDetailedQueryVariables,
  useCustomReportsDetailedQuery,
} from './gql/queries/customReportsDetailed.graphql';
import { AnyCustomReportSettingConfig } from './types';

/**
 * Checks, if a custom report pivot table config is a value config
 */
export const isCustomReportSettingsValue = (
  customReportSettingConfig: AnyCustomReportSettingConfig
): customReportSettingConfig is CustomReportSettingsValueFragment =>
  customReportSettingConfig.__typename === 'CustomReportSettingsValue';

/**
 * Update CustomReport in the cache
 */
export const updateCustomReportFragment =
  makeUpdateFragment<CustomReportFragment>({
    typeName: 'CustomReport',
    fragment: CustomReportFragmentDoc,
  });

/**
 * Update CustomReportDetailed in the cache
 */
export const updateCustomReportDetailedFragment =
  makeUpdateFragment<CustomReportDetailedFragment>({
    typeName: 'CustomReport',
    fragment: CustomReportDetailedFragmentDoc,
  });

/**
 * Update CustomReportChartSettings in the cache
 */
export const updateCustomReportChartSettingsFragment =
  makeUpdateFragment<CustomReportChartSettingsFragment>({
    typeName: 'CustomReportChartSettings',
    fragment: CustomReportChartSettingsFragmentDoc,
  });

/**
 * Hook for getting custom report fragment from cache or by query
 */
export const useCustomReportDetailedFromCacheOrQuery =
  makeUseFragmentFromCacheOrQuery<
    CustomReportDetailedFragment,
    CustomReportsDetailedQuery,
    CustomReportsDetailedQueryVariables
  >({
    typeName: 'CustomReportDetailed',
    fragment: CustomReportDetailedFragmentDoc,
    useQuery: useCustomReportsDetailedQuery,
    getItemFromQueryData: data => data.customReports.edges.at(0)?.node,
  });

/**
 * Formats source field value for display.
 * Now we only have sourceFieldValue type for custom report, but later we should make it more generic
 */
export const formatSourceFieldValue = (
  sourceFieldValue:
    | Omit<CustomReportBlueprintSourceFieldValueFragment, '__typename'>
    | null
    | undefined
) =>
  match(sourceFieldValue)
    .with({ intValue: P.not(P.nullish) }, ({ intValue }) => formatInt(intValue))
    .with({ floatValue: P.not(P.nullish) }, ({ floatValue }) =>
      formatNumber(floatValue)
    )
    .with({ datetimeValue: P.not(P.nullish) }, ({ datetimeValue }) =>
      formatDate(datetimeValue)
    )
    .with({ strValue: P.not(P.nullish) }, ({ strValue }) => strValue)
    .otherwise(R.always(''));
