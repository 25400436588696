import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Button } from '~/shared/components/Button';

import { useArkaNavigation } from '~/services/navigation';

import {
  BlueprintEditHeaderWrapper,
  BlueprintGeneralSetings,
  blueprintHeaderStyles,
} from '~/widgets/blueprintEdit';

import styles from './index.module.scss';

interface Props {
  blueprintName: string;
  blueprintId: string;

  canRun: boolean;

  generalSettings?: BlueprintGeneralSetings;
  run: () => void;
}

export const BlueprintEditHeader: React.FC<Props> = ({
  blueprintName,
  blueprintId,
  canRun,
  run,
}) => {
  const { urlCompanyId } = useArkaNavigation();
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  return (
    <BlueprintEditHeaderWrapper>
      <div className={blueprintHeaderStyles.link}>
        <Link
          {...{
            to: '/$companyId/integrator/blueprints/$blueprintId',
            params: {
              blueprintId,
              companyId: urlCompanyId,
            },
          }}
        >
          {blueprintName}
        </Link>
      </div>
      <Button className={styles.button} onPress={run} isDisabled={!canRun}>
        {t(IntegratorStrings.blueprintEdit.headerRunCaption)}
      </Button>
    </BlueprintEditHeaderWrapper>
  );
};
