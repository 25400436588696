import { MilkingMistakeKindsEnum } from '@graphql-types';

import TOKENS from '~/styles/__generated__/tokens.json';

import { MilkingParlorChartMistakeKinds } from './types';

/**
 * Text for unidentified cow, that is used in datasets and tooltip
 */
export const UNIDENTIFIED_COW_TEXT = 'Неидент. животное';

/**
 * Display values for milking mistakes
 */
export const MILKING_MISTAKES_DICT: Partial<
  Record<MilkingMistakeKindsEnum, string>
> &
  Record<MilkingParlorChartMistakeKinds, string> = {
  [MilkingMistakeKindsEnum.Reattach]: 'Переподкл. аппарата',
  [MilkingMistakeKindsEnum.LateRehang]: 'Позднее переподкл.',
};

// Default precision for flow rate display in tooltips
export const FLOW_RATE_FORMAT_PRECISION = 1;

// Milking parlor chart height
export const CHART_HEIGHT_PX = 592;

// Background color for mistakes dataset
export const MISTAKES_DATASET_BACKGROUND_COLOR = TOKENS.colorBgSurface1;

// Border color for mistakes dataset
export const MISTAKES_DATASET_BORDER_COLOR = TOKENS.colorNeutralDefault;

// Background color for unidentified cows dataset
export const UNIDENTIFIED_DATASET_COLOR = TOKENS.colorNeutralDefault;
export const UNIDENTIFIED_DATASET_HOVER_COLOR = TOKENS.colorNeutralHover;

// Minimum zoom range for time axis in milliseconds
export const MIN_TIME_ZOOM_RANGE_MS = 1000 * 60 * 60 * 2;

// Minimum zoom range for category axis with stall numbers
export const MIN_STALL_ZOOM_RANGE_COUNT = 10;

// Chart time scale should have some paddings, so we don't render lines on the edge
export const CHART_TIME_AXIS_PADDING_MS = 1000 * 60 * 3;
