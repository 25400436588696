import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import dayjs from 'dayjs';

import { AppLocalStorageKeys } from '~/shared/constants';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { useLocalStorage } from '~/shared/hooks/useLocalStorage';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import { formatDateForBackend } from '~/services/dateTime';

import { BlueprintsSearchParams } from '~/widgets/blueprintsList';

interface BlueprintRunDates {
  selectedDate: string;
  dateOfSelection: string;
}

/**
 * Hook for using blueprint filtering fields
 */
export const useBlueprintFilters = () => {
  const {
    roleIds,
    setRoleIds,
    viewKind,
    setViewKind,
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
  } = useSearchParamsState<BlueprintsSearchParams>();

  const { search, setSearch } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  const today = formatDateForBackend(dayjs().startOf('day'));

  const defaultBlueprintDates = {
    selectedDate: today,
    dateOfSelection: today,
  };

  const [blueprintRunDates, setBlueprintRunDates] =
    useLocalStorage<BlueprintRunDates>(
      AppLocalStorageKeys.blueprintRunDates,
      defaultBlueprintDates
    );

  const setBlueprintsDate: Dispatch<SetStateAction<string>> = useCallback(
    dateOrGetDate => {
      const newDate =
        dateOrGetDate instanceof Function
          ? dateOrGetDate(blueprintRunDates.selectedDate)
          : dateOrGetDate;

      setBlueprintRunDates({
        selectedDate: newDate,
        dateOfSelection: today,
      });
    },
    [blueprintRunDates]
  );

  // Reset selected blueprints date, if current date is different from the date blueprints date was set
  useEffect(() => {
    if (dayjs(blueprintRunDates.dateOfSelection).diff(dayjs(), 'days') === 0) {
      return;
    }
    setBlueprintRunDates(defaultBlueprintDates);
  }, [blueprintRunDates.dateOfSelection]);

  return {
    roleIds,
    setRoleIds,
    viewKind,
    setViewKind,
    search,
    debouncedSearch,
    setSearch,
    blueprintsDate: blueprintRunDates.selectedDate,
    setBlueprintsDate,
  };
};

export type UseBlueprintFiltersInterface = ReturnType<
  typeof useBlueprintFilters
>;
