import React from 'react';

import { ActionKindEnum } from '@graphql-types';

import { FreshActionInput } from './components/FreshActionInput';
import { BlueprintSpecialInputProps } from './types';

const SPECIAL_INPUTS_DICT: Partial<
  Record<ActionKindEnum, React.FC<BlueprintSpecialInputProps>>
> = {
  [ActionKindEnum.CreateDefaultEventFresh]: FreshActionInput,
};

export const BlueprintSpecialInput: React.FC<BlueprintSpecialInputProps> = ({
  errors,
  start,
  close,

  executionInfo,
}) => {
  const { actionType } = executionInfo.specialInput ?? {};
  const Component = actionType ? SPECIAL_INPUTS_DICT[actionType] : undefined;
  return Component ? (
    <Component
      errors={errors}
      executionInfo={executionInfo}
      close={close}
      start={start}
    />
  ) : null;
};
