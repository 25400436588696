import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { mergeProps } from '~/shared/helpers/mergeProps';
import { AnyEnum } from '~/shared/types/utility';

import { Select } from '..';
import {
  PartialSelectProps,
  PartialSelectPropsWithName,
  SelectItemWithId,
} from '../types';

/**
 * Fabric to make a hook, that returns an array of items with translations
 * to use in selects or other similar components, like radio buttons
 */
export const makeUseEnumSelectItems =
  <T extends AnyEnum, AdditionalSelectProps extends object = object>(
    enumObject: T,
    getLabelOrTranslationPrefix:
      | string
      | ((
          enumValue: keyof T,
          additionalSelectProps: AdditionalSelectProps
        ) => string),
    omittedKeys: (keyof T)[] = []
  ) =>
  (
    additionalSelectProps: AdditionalSelectProps = {} as AdditionalSelectProps
  ) => {
    const { t } = useTranslation(MaslovNamespaces.enums);

    return useMemo(
      () =>
        Object.entries(enumObject)
          .filter(([enumKey]) => !omittedKeys.includes(enumKey))
          .map(([, enumValue]) => {
            const translatedName =
              typeof getLabelOrTranslationPrefix === 'function'
                ? getLabelOrTranslationPrefix(enumValue, additionalSelectProps)
                : t([`${getLabelOrTranslationPrefix}${enumValue}`, enumValue]);

            return {
              id: enumValue as keyof T,
              name: translatedName,
            };
          }),
      [...Object.values(additionalSelectProps)]
    );
  };

/**
 * Fabric to make a hook for using dropdown, that returns dropdown with localized items
 */
export const makeUseEnumSelect = <
  T extends AnyEnum,
  AdditionalSelectProps extends object = object,
>(
  enumObject: T,
  getLabelOrTranslationPrefix:
    | string
    | ((
        enumValue: keyof T,
        additionalSelectProps: AdditionalSelectProps
      ) => string),
  omittedKeys: (keyof T)[] = [],
  selectProps: PartialSelectProps = {}
) => {
  const useEnumSelectItems = makeUseEnumSelectItems(
    enumObject,
    getLabelOrTranslationPrefix,
    omittedKeys
  );
  return (
    innerSelectProps: PartialSelectPropsWithName<SelectItemWithId<keyof T>>,
    additionalSelectProps: AdditionalSelectProps = {} as AdditionalSelectProps
  ) => {
    const items = useEnumSelectItems(additionalSelectProps);

    return {
      items,
      renderSelectElement: (
        renderSelectProps: PartialSelectProps<SelectItemWithId<keyof T>> = {}
      ) => (
        <Select<(typeof items)[number]>
          {...mergeProps(
            { items },
            selectProps,
            innerSelectProps,
            renderSelectProps
          )}
        />
      ),
    };
  };
};
