import R from 'ramda';

import {
  QueryFieldsWithPolicies,
  typePolicies,
} from '../initializeApollo/typePolicies';

/**
 * Get query name stored in the cache
 */
export const getQueryCacheName = (
  queryName: QueryFieldsWithPolicies,
  variables?: Record<string, any> | null
) => {
  if (!variables) return queryName;

  // Not safe decision, but for now we assume, that we use only string arrays for keyArgs
  // Will break, if function is used
  const currentField = typePolicies.Query.fields[queryName];
  const currentFieldKeyArgs = (
    'keyArgs' in currentField ? currentField.keyArgs : []
  ) as string[];

  const filteredVariables = R.pick(currentFieldKeyArgs, variables);

  const key = JSON.stringify(filteredVariables);

  return `${queryName}:${key}`;
};
