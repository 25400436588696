import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatISO } from 'date-fns';
import { TFunction } from 'i18next';

import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { ListItem } from '~/~legacy/types/keyValue';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { MDropDown } from '~/shared/components/MDropDown';
import { MInput } from '~/shared/components/MInput';
import { BlueprintRunSettings, ScheduleInterval } from '~/shared/graphql';

import { BlueprintDetailsEditModel } from '../../../../types';
import styles from './index.module.scss';

const getIntervalItems: (
  t: TFunction<[MaslovNamespaces], any>
) => ListItem[] = t => {
  return [
    {
      value: ScheduleInterval.Daily,
      content: t(IntegratorStrings.blueprintDetails.runSettings.day),
    },
    {
      value: ScheduleInterval.Weekly,
      content: t(IntegratorStrings.blueprintDetails.runSettings.week),
    },
    {
      value: ScheduleInterval.BiWeekly,
      content: t(IntegratorStrings.blueprintDetails.runSettings.twoWweeks),
    },
    {
      value: ScheduleInterval.Monthly,
      content: t(IntegratorStrings.blueprintDetails.runSettings.month),
    },
    {
      value: ScheduleInterval.Quarterly,
      content: t(IntegratorStrings.blueprintDetails.runSettings.quater),
    },
    {
      value: ScheduleInterval.Yearly,
      content: t(IntegratorStrings.blueprintDetails.runSettings.year),
    },
  ];
};

interface Props {
  runSettings: BlueprintRunSettings;
  onChange: React.Dispatch<React.SetStateAction<BlueprintDetailsEditModel>>;
}

export const BlueprintDetailsRunSettingsScheduleBasicForm: React.FC<Props> = ({
  runSettings,
  onChange,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const showInterval = runSettings.interval === ScheduleInterval.Daily;

  const intervalChangeCallback = useCallback(
    (val: ScheduleInterval) => {
      onChange(prevState => {
        return {
          ...prevState,
          runSettings: {
            ...prevState.runSettings,
            interval: val,
            repeatInterval:
              val === ScheduleInterval.Daily
                ? prevState.runSettings.repeatInterval
                : 1,
          },
        };
      });
    },
    [onChange]
  );

  const repeatIntervalChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(prevState => {
        return {
          ...prevState,
          runSettings: {
            ...prevState.runSettings,
            repeatInterval: Number(e.target.value),
          },
        };
      });
    },
    [onChange]
  );

  const startOnChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(prevState => {
        return {
          ...prevState,
          runSettings: {
            ...prevState.runSettings,
            startOn: e.target.value,
          },
        };
      });
    },
    [onChange]
  );

  const intervalNumberCmpnt = showInterval ? (
    <>
      <MInput
        name="repeatInterval"
        value={runSettings.repeatInterval}
        onChange={repeatIntervalChangeCallback}
        className={styles.input}
        type="number"
      />
      &nbsp;
    </>
  ) : null;

  const intervalItems = useMemo(() => getIntervalItems(t), [t]);

  const intervalSelectCmpnt = (
    <MDropDown
      name="interval"
      className={styles.select}
      items={intervalItems}
      selectedValue={runSettings.interval}
      onChange={intervalChangeCallback}
    />
  );

  const formattedDate = runSettings.startOn
    ? formatISO(new Date(runSettings.startOn), { representation: 'date' })
    : '';

  return (
    <>
      <p className={styles.caption}>
        {t(IntegratorStrings.blueprintDetails.runSettings.repeatCaption)}
      </p>
      <div className={styles.textString}>
        {t(IntegratorStrings.blueprintDetails.runSettings.repeatWithInterval)}{' '}
        &nbsp;{intervalNumberCmpnt}
        &nbsp; {intervalSelectCmpnt}
      </div>
      <div className={styles.textString}>
        {t(IntegratorStrings.blueprintDetails.runSettings.startDate)}
        <MInput
          name="date"
          type="date"
          value={formattedDate}
          onChange={startOnChangeCallback}
        />
      </div>
    </>
  );
};
