import { ChartMeta, ScatterController, UpdateMode } from 'chart.js';

import {
  MILKING_INTERVAL_BAR_HEIGHT_PX,
  MilkingParlorIntervalElement,
  MISTAKE_INTERVAL_BAR_HEIGHT_PX,
} from './milkingParlorIntervalElement';
import {
  MilkingParlorChartDataPoint,
  MilkingParlorChartDataset,
} from './types';

export class MilkingParlorController extends ScatterController {
  static id = 'milkingParlor';

  static defaults = {
    dataElementType: 'milkingParlorInterval',
  };

  parseObjectData(
    meta: ChartMeta<
      'milkingParlor',
      MilkingParlorIntervalElement,
      MilkingParlorIntervalElement
    >,
    data: MilkingParlorChartDataPoint[],
    start: number,
    count: number
  ) {
    const { xScale, yScale } = meta;

    if (!xScale || !yScale) {
      return data;
    }

    return data.slice(start, count).map((item, index) => {
      const actualIndex = index + start;
      return {
        x: xScale.parse(item.happenedAt, actualIndex),
        y: yScale.parse(item.stallNumber, actualIndex),
        endedAt: xScale.parse(item.endedAt, actualIndex),
      };
    });
  }

  updateElements(
    elements: MilkingParlorIntervalElement[],
    start: number,
    count: number,
    mode: UpdateMode
  ) {
    super.updateElements(elements, start, count, mode);

    const dataset = this.getDataset() as unknown as MilkingParlorChartDataset;

    const scaleX = this.getScaleForId('x');
    if (!scaleX) return;

    elements.forEach((element, index) => {
      const currentDatasetItem = dataset.data?.[
        index
      ] as unknown as MilkingParlorChartDataPoint;

      const endedAt = scaleX.getPixelForValue(
        scaleX.parse(currentDatasetItem.endedAt, 0) as number
      );

      this.updateElement(
        element,
        index,
        {
          endedAt,
          height: dataset.isMistake
            ? MISTAKE_INTERVAL_BAR_HEIGHT_PX
            : MILKING_INTERVAL_BAR_HEIGHT_PX,
        },
        mode
      );
    });
  }
}
