import React, { ReactNode } from 'react';

import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';

import {
  Button,
  ButtonPropsWithoutRef,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { useAccordion, UseAccordionProps } from '~/shared/hooks/useAccordion';

import styles from './index.module.scss';

interface Props extends UseAccordionProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * className, applied to the wrapper of the accordion's title
   */
  titleWrapperClassName?: string;
  /**
   * className, applied to the wrapper of the accordion's children
   */
  contentWrapperClassName?: string;

  /**
   * Title of the accordion
   */
  title?: ReactNode;
  /**
   * If passed, renders a button to the right of the title
   */
  buttonProps?: Partial<ButtonPropsWithoutRef>;
  /**
   * If true, shows additional button (default to !!buttonProps)
   */
  withButton?: boolean;
}

export const ExpandableTitle: React.FC<Props> = ({
  className,
  titleWrapperClassName,
  contentWrapperClassName,

  title,
  buttonProps,
  withButton = !!buttonProps,

  children,
  ...useAccordionProps
}) => {
  const {
    isOpen,
    shouldRenderContent,

    childrenContainerRef,

    openButtonProps,
    childrenWrapperProps,
  } = useAccordion(useAccordionProps);

  // :focus-visible is not working with usePress correctly, so we use react-aria solution
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <div className={className}>
      <div className="flex items-center">
        <div
          {...{
            className: clsx(
              styles.titleWrapper,
              isFocusVisible && styles.focused,
              titleWrapperClassName
            ),
            ...mergeProps(openButtonProps, focusProps),
          }}
        >
          <Icon
            {...{
              className: 'mr-4',
              variant: IconVariants.chevronDown,
              rotate: isOpen ? RotateVariants.up : RotateVariants.left,
            }}
          />
          <Typography className="pr-4" variant={TypographyVariants.heading4}>
            {title}
          </Typography>
        </div>
        {withButton && (
          <Button
            {...{
              className: 'ml-a',
              size: ButtonSizes.small24,
              variant: ButtonVariants.secondary,
              theme: ButtonThemes.accent,
              ...buttonProps,
            }}
          />
        )}
      </div>
      <div
        {...{
          className: styles.children,
          ...childrenWrapperProps,
        }}
      >
        <div ref={childrenContainerRef}>
          {shouldRenderContent && (
            <div className={clsx('mt-16', contentWrapperClassName)}>
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
