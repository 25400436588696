import React from 'react';
import { useFieldArray } from 'react-hook-form';

import clsx from 'clsx';
import R from 'ramda';

import {
  Button,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Select } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { getTmpId, isTmpId } from '~/shared/helpers/string';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import formStyles from '~/styles/modules/form.module.scss';

import { ProtocolFragment } from '../../gql/fragments/protocol.graphql';
import { EditProtocolInjectionsFormType } from '../../hooks/useProtocolInjectionsForm';
import { ProtocolInjectionFormRow } from './components/ProtocolInjectionFormRow';

interface Props
  extends Pick<
    UseModalStepFormInterface<EditProtocolInjectionsFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Protocol to edit
   */
  protocol: ProtocolFragment;
}

export const EditProtocolInjectionsForm: React.FC<Props> = ({
  className,
  protocol,
  formProps,
}) => {
  const { formContext } = formProps;

  const injectionDaysRangeBorder = (protocol.expectedDaysOnProtocol ?? 0) + 1;

  const selectedDaysItems = R.range(1, injectionDaysRangeBorder).map(day => ({
    id: day,
    name: day.toString(),
  }));

  const {
    fields: protocolInjections,
    append: appendInjectionDay,
    remove: removeInjectionDay,
    update: updateInjectionDay,
  } = useFieldArray({
    control: formContext.control,
    name: 'protocolInjections',
    keyName: 'uniqKey',
  });

  const protocolInjectionsForRender = R.sortBy(
    R.prop('dayNumber'),
    protocolInjections.filter(d => !d.isDeleted)
  );

  const selectedDays = R.uniq(
    protocolInjectionsForRender.map(R.prop('dayNumber'))
  );

  const protocolInjectionsGrouped = R.groupBy(
    d => d.dayNumber.toString(),
    protocolInjectionsForRender
  );

  return (
    <Form
      {...{
        className: clsx(formStyles.singleColumnForm, className),
        ...formProps,
      }}
    >
      <Select<(typeof selectedDaysItems)[number]>
        {...{
          name: 'selectedDays',
          label: 'Выберите дни, когда будут ставиться инъекции',
          feedback: 'Необязательное поле',
          items: selectedDaysItems,
          isMulti: true,
          rawValue: selectedDays,
          onValueChange: newSelectedDays => {
            const injectionsToDelete = protocolInjectionsForRender.filter(
              protocolInjection => {
                return !newSelectedDays.some(
                  day => day.id === protocolInjection.dayNumber
                );
              }
            );

            const updatedInjections = protocolInjections
              .map(protocolInjection => ({
                ...protocolInjection,
                isDeleted:
                  protocolInjection.isDeleted ||
                  injectionsToDelete.some(i => i.id === protocolInjection.id),
              }))
              // Remove tmp days, cause we don't need to store them
              .filter(day => !(day.isDeleted && isTmpId(day.id)));

            const injectionsToAdd = newSelectedDays
              .filter(day => {
                return !protocolInjectionsForRender.some(
                  d => d.dayNumber === day.id
                );
              })
              .map(day => ({
                id: getTmpId('protocolInjection'),
                comment: '',
                injectionID: '',
                dayNumber: day.id,
                isDeleted: false,
              }));

            formContext.setValue('protocolInjections', [
              ...updatedInjections,
              ...injectionsToAdd,
            ]);
          },
        }}
      />
      {selectedDays.map(day => {
        const currentDayInjections = protocolInjectionsGrouped[day] ?? [];
        return (
          <div key={day}>
            <Typography
              variant={TypographyVariants.heading5}
              className="mb-4"
              tag="h3"
            >
              {day} день протокола
            </Typography>

            {currentDayInjections.map(injection => {
              const currentInjectionIndex = protocolInjections.findIndex(
                i => i.id === injection.id
              );

              const inputPrefix =
                `protocolInjections.${currentInjectionIndex}.` as const;

              return (
                <ProtocolInjectionFormRow
                  key={injection.id}
                  {...{
                    inputPrefix,
                    onRemove: () => {
                      if (isTmpId(injection.id)) {
                        removeInjectionDay(currentInjectionIndex);
                      } else {
                        updateInjectionDay(currentInjectionIndex, {
                          ...injection,
                          isDeleted: true,
                        });
                      }
                    },
                  }}
                />
              );
            })}

            <Button
              {...{
                variant: ButtonVariants.ghost,
                theme: ButtonThemes.neutral,
                size: ButtonSizes.small24,
                iconVariant: IconVariants.plus,
                onPress: () => {
                  appendInjectionDay({
                    id: getTmpId('protocolInjection'),
                    comment: '',
                    injectionID: '',
                    dayNumber: day,
                    isDeleted: false,
                  });
                },
              }}
            >
              Добавить инъекцию
            </Button>
          </div>
        );
      })}
    </Form>
  );
};
