import { Dow } from '@graphql-types';

import { makeSelectComponentFromHook } from '~/shared/components/Select';
import { makeUseEnumSelect } from '~/shared/components/Select';

import { DAYS_OF_WEEK_DICT } from '../../constants';

/**
 * Select for days of week
 */
const useDowSelect = makeUseEnumSelect(
  Dow,
  enumValue => DAYS_OF_WEEK_DICT[enumValue as Dow]
);

export const DowSelect = makeSelectComponentFromHook(useDowSelect);
