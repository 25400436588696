import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { InseminationShortFragmentDoc } from '../../gql/fragments/inseminationShort.graphql';
import { useInseminationsShortQuery } from '../../gql/queries/inseminationsShort.graphql';
import { formatInsemination } from '../../helpers';

/**
 * Select for an insemination
 */
export const useInseminationSelect = makeUseAsyncSelect({
  typeName: 'Insemination',
  fragment: InseminationShortFragmentDoc,
  useQuery: useInseminationsShortQuery,
  getItemsFromQueryData: data => data.inseminations.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.inseminations.pageInfo,

  selectProps: {
    placeholder: 'Введите осеменение',
    getItemValue: item => item.id,
    getItemText: item => formatInsemination(item, { withBull: false }),
    getItemDescription: item =>
      formatInsemination(item, { withMainInfo: false }),
  },
});

export const InseminationAsyncSelect = makeAsyncSelectFormComponent(
  useInseminationSelect
);
