import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RollbackEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RollbackEventMutation = { __typename?: 'Mutation', rollbackEvent?: any | null };


export const RollbackEventDocument = gql`
    mutation rollbackEvent($id: ID!) {
  rollbackEvent(id: $id)
}
    `;
export type RollbackEventMutationFn = Apollo.MutationFunction<RollbackEventMutation, RollbackEventMutationVariables>;

/**
 * __useRollbackEventMutation__
 *
 * To run a mutation, you first call `useRollbackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollbackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollbackEventMutation, { data, loading, error }] = useRollbackEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRollbackEventMutation(baseOptions?: Apollo.MutationHookOptions<RollbackEventMutation, RollbackEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RollbackEventMutation, RollbackEventMutationVariables>(RollbackEventDocument, options);
      }
export type RollbackEventMutationHookResult = ReturnType<typeof useRollbackEventMutation>;
export type RollbackEventMutationResult = Apollo.MutationResult<RollbackEventMutation>;
export type RollbackEventMutationOptions = Apollo.BaseMutationOptions<RollbackEventMutation, RollbackEventMutationVariables>;