import React from 'react';

import R from 'ramda';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { useConfirm } from '~/services/modals';

import { useEditReproductionCrReportModal } from '~/entities/reproductionCrReports';
import { ReproductionCrReportFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrReport.graphql';
import { useDeleteReproductionCrReportMutation } from '~/entities/reproductionCrReports/gql/mutations/deleteReproductionCrReport.graphql';
import {
  ReproductionCrReportsDocument,
  ReproductionCrReportsQueryVariables,
} from '~/entities/reproductionCrReports/gql/queries/reproductionCrReports.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report to render menu for
   */
  reproductionCrReport: ReproductionCrReportFragment;
  /**
   * Gql custom reports query variables
   */
  queryVariables: ReproductionCrReportsQueryVariables;
}

export const ReproductionCrReportCardMenu: React.FC<Props> = ({
  reproductionCrReport,
  className,
  queryVariables,
}) => {
  const [deleteReproductionCrReport] = useDeleteReproductionCrReportMutation();

  const confirmDelete = useConfirm();

  const { open: openEditReproductionCrReportModal } =
    useEditReproductionCrReportModal();

  return (
    <ContextMenuButton
      {...{
        className,
        tooltip: 'Действия с отчётом',
        items: [
          {
            content: 'Редактировать свойства отчёта',
            onPress: () =>
              openEditReproductionCrReportModal({
                reproductionCrReport,
                queryVariables,
              }),
          },
          {
            variant: MenuItemVariants.destructive,
            content: 'Удалить',
            onPress: () => {
              confirmDelete({
                title: 'Удаление отчёта',
                message: (
                  <div className="grid gap-12">
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Вы хотите удалить отчёт{' '}
                      <Typography variant={TypographyVariants.bodySmallStrong}>
                        {reproductionCrReport.name}
                      </Typography>
                      ?
                    </Typography>
                    <Typography tag="p" variant={TypographyVariants.bodySmall}>
                      Это действие невозможно отменить.
                    </Typography>
                  </div>
                ),
                isDelete: true,
              }).then(isConfirmed => {
                if (!isConfirmed) return;
                deleteReproductionCrReport({
                  variables: {
                    id: reproductionCrReport.id,
                  },
                  optimisticResponse: { deleteReproductionCrReport: null },
                  update: R.juxt([
                    makeDeleteFragmentFromQuery({
                      typeName: 'ReproductionCrReport',
                      query: ReproductionCrReportsDocument,
                      queryName: 'reproductionCrReports',
                      variables: queryVariables,
                    })(reproductionCrReport.id),
                    makeDeleteQueriesByNameWithoutVariables(
                      'reproductionCrReports',
                      queryVariables
                    ),
                  ]),
                });
              });
            },
          },
        ],
      }}
    />
  );
};
