import React, { useMemo } from 'react';

import {
  ReproductionCrReportChartKind,
  ReproductionYAxisMode,
} from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { ColorShades } from '~/shared/helpers/color';
import { Falsy } from '~/shared/types/utility';

import { DatePeriod, formatDateRange } from '~/services/dateTime';

import { ReproductionCrDetailedRowsByEntityFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrDetailedRowsByEntity.graphql';

import {
  BarChart,
  BarChartDatasetConfig,
  CHART_COLOR_PROPS_ITERATOR,
  DataPointsTooltip,
  getChartOptions,
} from '~/features/charts';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import panelStyles from '~/styles/modules/panel.module.scss';

import { REPRODUCTION_CHART_OPTIONS } from '../../constants';
import { useReproductionCrReportXAxis } from '../../hooks';
import { CrReportDisplayProps } from '../../types';

interface Props extends CrReportDisplayProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Actual period for comparison mode
   */
  actualPeriod?: DatePeriod;
  /**
   * Historic period for comparison mode
   */
  historicPeriod?: DatePeriod;
  /**
   * Mode for y axis
   */
  yAxisMode?: ReproductionYAxisMode;
  /**
   * Chart kind to use
   */
  chartKind?: ReproductionCrReportChartKind;
  /**
   * If true, adds additional x point to the chart with others inseminations
   */
  shouldShowOther?: boolean;
}

const CHART_HEIGHT_PX = 412;

export const ReproductionCrChart: React.FC<Props> = ({
  className,
  chartData,
  historicChartData,
  actualPeriod,
  historicPeriod,
  xAxisMode,
  yAxisMode = ReproductionYAxisMode.Percent_100,
  chartKind = ReproductionCrReportChartKind.Bar,
  shouldShowOther: shouldShowOtherProp = false,
}) => {
  const isComparison =
    !!historicChartData && !!actualPeriod && !!historicPeriod;
  const isBar = chartKind === ReproductionCrReportChartKind.Bar;

  const shouldShowOther = shouldShowOtherProp && !!chartData.other;
  const { xAxisName, xAxisLabels, getXAxisLabelByRowIndex } =
    useReproductionCrReportXAxis({
      chartData,
      historicChartData,
      xAxisMode,
      shouldShowOther,
    });

  const { datasets, chartOptions } = useMemo(() => {
    const makeGetCrDataPoints =
      (fieldName: 'cr' | 'uncheckedInseminationsPercent') =>
      (
        data: ReproductionCrDetailedRowsByEntityFragment | SkeletonPlaceholder
      ) => [
        ...(data.rows?.map(row => row[fieldName] ?? NaN) ?? []),
        ...wrapConditionalArrayElement(
          shouldShowOther && (data.other?.[fieldName] ?? NaN),
          R.complement(R.isNil)
        ),
      ];

    const getCrDataPoints = makeGetCrDataPoints('cr');
    const getUncheckedInseminationsDataPoints = makeGetCrDataPoints(
      'uncheckedInseminationsPercent'
    );

    const datasetsInternal = (
      [
        {
          key: 'cr',
          type: isBar ? 'bar' : 'line',
          label: isComparison
            ? `Текущий период ${formatDateRange(actualPeriod.interval.since, actualPeriod.interval.till)}`
            : 'Оплодотворяемость (CR)',
          ...CHART_COLOR_PROPS_ITERATOR.next(ColorVariants.success).value,
          isTogglable: true,
          data: getCrDataPoints(chartData),
          stack: 'actual',
        },
        isBar && {
          key: 'uncheckedInseminationsPercent',
          label: isComparison
            ? 'Непроверенные осеменения (текущий период)'
            : 'Непроверенные осеменения',
          ...CHART_COLOR_PROPS_ITERATOR.next(
            isComparison
              ? [ColorVariants.success, ColorShades.muted]
              : ColorVariants.accent
          ).value,
          isTogglable: true,
          stack: 'actual',
          data: getUncheckedInseminationsDataPoints(chartData),
        },
        isComparison && {
          key: 'historicCr',
          type: isBar ? 'bar' : 'line',
          label: `Период сравнения ${formatDateRange(historicPeriod.interval.since, historicPeriod.interval.till)}`,
          ...CHART_COLOR_PROPS_ITERATOR.next(ColorVariants.info).value,
          isTogglable: true,
          data: getCrDataPoints(historicChartData),
          stack: 'historic',
        },
        isComparison &&
          isBar && {
            key: 'historicUncheckedInseminationsPercent',
            label: 'Непроверенные осеменения (период сравнения)',
            ...CHART_COLOR_PROPS_ITERATOR.next([
              ColorVariants.info,
              ColorShades.muted,
            ]).value,
            isTogglable: true,
            stack: 'historic',
            data: getUncheckedInseminationsDataPoints(historicChartData),
          },
      ] satisfies (BarChartDatasetConfig | Falsy)[]
    ).filter(Boolean);

    return {
      datasets: datasetsInternal,
      chartOptions: getChartOptions(REPRODUCTION_CHART_OPTIONS, {
        scales: {
          x: {
            title: {
              text: xAxisName,
            },
          },
          y: {
            max:
              yAxisMode === ReproductionYAxisMode.Percent_100 ? 100 : undefined,
          },
        },
      }),
    };
  }, [chartData, yAxisMode]);

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-16', className)}>
      <BarChart
        {...{
          datasets,
          labels: xAxisLabels,
          legendClassName: 'mb-32',
          height: CHART_HEIGHT_PX,
          renderTooltip: dataPoints => {
            const dataPointXAxisLabelIndex = dataPoints.at(0)?.parsed.x;
            if (R.isNil(dataPointXAxisLabelIndex)) {
              return null;
            }
            let xAxisLabel = getXAxisLabelByRowIndex(dataPointXAxisLabelIndex);
            if (R.isNil(xAxisLabel)) {
              return null;
            }
            if (Array.isArray(xAxisLabel)) {
              xAxisLabel = xAxisLabel.join(' ');
            }
            return (
              <DataPointsTooltip
                {...{
                  title: `${xAxisName} ${xAxisLabel}`,
                  dataPoints,
                  datasets,
                  mainValueMeasurementUnit: '%',
                }}
              />
            );
          },
          chartOptions,
          isStacked: true,
          isPercents: true,
        }}
      />
    </div>
  );
};
