import React from 'react';

import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { FloatEdit } from './FloatEdit';
import { FloatView } from './FloatView';

import './FloatEditor.scss';

export const FloatEditor: React.FC<ValueEditorArgs<number | string>> =
  React.memo(props => {
    const Component = EditorHoC(FloatView, FloatEdit);

    return <Component {...props} />;
  });
