import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type RoleDetailedFragment = { __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor, shouldBeMigratedOnDelete: boolean };

export const RoleDetailedFragmentDoc = gql`
    fragment RoleDetailed on BlueprintRole {
  __typename
  id
  name
  color
  shouldBeMigratedOnDelete
}
    `;