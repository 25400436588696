import { ModalRegistration } from '~/services/modals';

import {
  LoadTestMilkingPartialResultsModal,
  LoadTestMilkingPartialResultsModalProps,
} from '.';

export const registration: ModalRegistration<LoadTestMilkingPartialResultsModalProps> =
  {
    Component: LoadTestMilkingPartialResultsModal,
  };
