import { TFunction } from 'i18next';

import { SpecialConstants } from '~/~legacy/types/specialConstants';

import { NodeValueKindEnum } from '~/shared/graphql';

import { ValueEditorKind } from '~/widgets/blueprintEdit';

import { defaultRenderGetter, renderKindsMap } from './inputToValueMap';

export function getValueToRender(
  value: any,
  inputValue: any,
  kind?: ValueEditorKind,
  valueKind?: NodeValueKindEnum | null,
  t?: TFunction
) {
  const useValueItself =
    typeof inputValue !== 'object' && typeof inputValue !== 'boolean';

  if (inputValue !== undefined && useValueItself) {
    return inputValue;
  }

  if (valueKind === NodeValueKindEnum.Variable) {
    return value?.name;
  }

  const selectionKind = kind || SpecialConstants.Unknown;
  const getter = renderKindsMap[selectionKind] || defaultRenderGetter;
  return getter(value, t);
}
