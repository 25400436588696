import React, { ReactNode } from 'react';

import clsx from 'clsx';

import layoutStyles from '~/styles/modules/layout.module.scss';

interface Props extends React.PropsWithChildren {
  panel?: ReactNode;
}

export const BlueprintExecutionWrapper: React.FC<Props> = ({
  panel,
  children,
}) => {
  return (
    <>
      <div className={layoutStyles.limitedContainer}>{children}</div>
      <div
        className={clsx(
          layoutStyles.outbreakContainer,
          layoutStyles.bottomStickyContainer
        )}
      >
        {panel}
      </div>
    </>
  );
};
