import { PointElement } from 'chart.js';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

export const MILKING_INTERVAL_BAR_HEIGHT_PX = 4;
export const MISTAKE_INTERVAL_BAR_HEIGHT_PX = 6;

const getIntervalBoundingBox = (interval: MilkingParlorIntervalElement) => {
  // If interval is too small, we should draw it as a circle
  const width = Math.max(interval.endedAt - interval.x, interval.height);

  return {
    top: interval.y - interval.height,
    bottom: interval.y + interval.height,
    left: interval.x,
    right: interval.x + width,
    width,
    height: interval.height,
  };
};

const isInRange = (
  interval: MilkingParlorIntervalElement,
  x: number | null,
  y: number | null
) => {
  const skipX = x === null;
  const skipY = y === null;

  const { top, bottom, left, right } = getIntervalBoundingBox(interval);

  return (
    (skipX || (x >= left && x <= right)) && (skipY || (y >= top && y <= bottom))
  );
};

export class MilkingParlorIntervalElement extends PointElement {
  static id = 'milkingParlorInterval' as const;

  public endedAt = 0;

  public height: number = MILKING_INTERVAL_BAR_HEIGHT_PX;

  inRange(mouseX: number, mouseY: number) {
    return isInRange(this, mouseX, mouseY);
  }

  inXRange(mouseX: number) {
    return isInRange(this, mouseX, null);
  }

  inYRange(mouseY: number) {
    return isInRange(this, null, mouseY);
  }

  getCenterPoint() {
    const { top, bottom, left, right } = getIntervalBoundingBox(this);
    return {
      x: (left + right) / 2,
      y: (top + bottom) / 2,
    };
  }

  tooltipPosition() {
    const centerPoint = this.getCenterPoint();
    return { ...centerPoint, y: centerPoint.y - this.height / 2 };
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.save();

    ctx.lineWidth = this.options.borderWidth;
    ctx.strokeStyle = this.options.borderColor;
    ctx.fillStyle = this.options.backgroundColor;

    const { left, top, width, height } = getIntervalBoundingBox(this);

    ctx.beginPath();
    ctx.roundRect(
      left,
      top + this.height / 2,
      width,
      height,
      NUMBER_TOKENS.borderRadius4
    );
    ctx.fill();
    ctx.stroke();

    ctx.restore();
  }
}
