import React from 'react';

import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { MInput, MInputProps } from '~/shared/components/MInput';

export const DateEdit: React.FC<ValueEditorStandardProps<string>> = props => {
  const {
    value,
    onChange,
    onBlur,
    onFocus,
    noForm,
    className,
    noBorder,
    onPaste,
    onKeyDown,
    isDisabled,
    placeholder,
    ...other
  } = props;

  return (
    <MInput
      {...(other as MInputProps)}
      isDisabled={isDisabled}
      type="date"
      className={noForm ? className : ''}
      placeholder={placeholder || 'Введите дату'}
      onChange={e => onChange?.(e.target.value)}
      onFocus={onFocus}
      noBorder={noBorder}
      onBlur={onBlur}
      onPaste={onPaste}
      onKeyDown={onKeyDown}
      value={value || ''}
    />
  );
};
