import React, {
  CSSProperties,
  ElementType,
  Fragment,
  ReactNode,
  useLayoutEffect,
} from 'react';
import { InViewHookResponse } from 'react-intersection-observer';

import R from 'ramda';

import { Loader } from '~/shared/components/Loader';
import {
  getSkeletonPlaceholders,
  Skeleton,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import { mergeRefs } from '~/shared/helpers/mergeProps';
import {
  useInfiniteScroll,
  UseInfiniteScrollProps,
} from '~/shared/hooks/useInfiniteScroll';

export interface AsyncListProps<
  Item,
  // Generally we should always render AsyncList with skeletons,
  // but this argument can be set to false to implicitly disable type check for rendering with with skeletons
  // WARNING: you should not pass skeletonItemsCount, if you disable skeletons,
  // otherwise you'll get runtime skeletons without type check.
  // Tried to use discriminated union to solve this, but got lots of problems with extending this interface
  WithSkeletonItems extends boolean = true,
  ItemToRender = WithSkeletonItems extends true
    ? Item | SkeletonPlaceholder
    : Item,
> extends UseInfiniteScrollProps,
    React.PropsWithChildren {
  /**
   * className applied to the root element (if any)
   */
  className?: string;
  /**
   * Css styles, applied to the root element
   */
  style?: CSSProperties | undefined;

  /**
   * Items to render in list
   */
  items: Item[];
  /**
   * If passed, renders skeleton placeholders, while loading initial items
   */
  skeletonItemsCount?: number;
  /**
   * Render prop for rendering single item in the list
   */
  renderItem: (
    item: ItemToRender,
    index: number,
    array: ItemToRender[]
  ) => ReactNode;
  /**
   * Tag name to render (React.Fragment is used by default)
   */
  wrapperTag?: ElementType;
  /**
   * Additional props for the wrapper tag
   */
  wrapperTagProps?: Omit<React.HTMLProps<any>, 'ref'>;
  /**
   * If true, it indicates, that it is loading next items.
   */
  isFetchingMore?: boolean;

  /**
   * Message, displayed, when there are no items in the list.
   * This prop is required, cause each list should have its uniq message
   */
  noItemsMessage: ReactNode;
  /**
   * If true, no items message is replaced with noSearchItemsMessage
   */
  isSearchActive?: boolean;
  /**
   * Message, displayed, when there are no items in the list, but user has active search
   */
  noSearchItemsMessage?: ReactNode;
  /**
   * Additional description, when no search items are found (this )
   */
  noSearchItemsDescription?: ReactNode;
  /**
   * Filters are always rendered before the list,
   * but if we're in the case of no loaded items and not active search
   * we don't display them, cause there is no point in searching through none items
   */
  filtersElement?: ReactNode;
  /**
   * Element to render, when filters are hidden.
   * TODO: it is used as a hack for rendering LayoutStateReset, see comments there
   */
  noFiltersElement?: ReactNode;
  /**
   * Render prop for rendering loader that is displayed on loading next items
   * and should contain a ref for infinite scroll algorithm to work
   */
  renderLoader?: (sentryRef?: InViewHookResponse['ref']) => ReactNode;
  /**
   * You can pass this render prop to renders a common wrapper for noItemsMessage and noSearchItemsMessage
   */
  renderNoItemsMessageWrapper?: (noItemsMessage: ReactNode) => ReactNode;
  /**
   * If true, the inner div is used for tracking infinite scroll,
   * otherwise, default document.window is used, or you can pass root prop for useInfiniteScroll
   */
  withInnerRootRef?: boolean;
  /**
   * If true, items message is wrapped in the same tag, as items. Wrapped in React.Fragment otherwise (default - true)
   */
  shouldWrapNoItemsMessage?: boolean;
}

const renderDefaultLoader = (sentryRef?: InViewHookResponse['ref']) => (
  <Loader className="col-span-full" ref={sentryRef} />
);

const AsyncListInternal = <Item extends any>(
  {
    className,
    style,

    items,
    skeletonItemsCount,
    renderItem,
    wrapperTag: WrapperTagProp = Fragment,
    wrapperTagProps: wrapperTagPropsProp,
    isFetchingMore,

    noItemsMessage,
    noSearchItemsMessage = noItemsMessage,
    isSearchActive = false,
    renderLoader = renderDefaultLoader,
    renderNoItemsMessageWrapper = R.identity,
    filtersElement,
    noFiltersElement,

    withInnerRootRef = false,
    shouldWrapNoItemsMessage = true,

    children,

    ...infiniteScrollProps
  }: AsyncListProps<Item>,
  ref: React.Ref<HTMLElement>
) => {
  const { rootRef, sentryRef } = useInfiniteScroll(infiniteScrollProps);

  const { isLoading, hasMore } = infiniteScrollProps;

  // Prevent scroll sticking to the bottom border
  // and continuously calling onFetchMore, when scrolling too fast
  useLayoutEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollTop -= 1;
    }
  }, [items.length]);

  const noItemsMessageToDisplay = isSearchActive
    ? noSearchItemsMessage
    : noItemsMessage;

  const isSkeletonLoading =
    !!skeletonItemsCount && isLoading && !isFetchingMore && !items.length;

  const itemsToRender = isSkeletonLoading
    ? getSkeletonPlaceholders(skeletonItemsCount)
    : items;

  const isShowNoItemsMessage = !itemsToRender.length && !isLoading;

  let WrapperTag = WrapperTagProp;
  let wrapperTagProps: React.ComponentProps<typeof WrapperTag> = {
    ref: mergeRefs(ref, withInnerRootRef ? rootRef : undefined),
    className,
    style,
    ...wrapperTagPropsProp,
  };
  if (!shouldWrapNoItemsMessage && isShowNoItemsMessage) {
    WrapperTag = Fragment;
    wrapperTagProps = {};
  }

  return (
    <>
      {!isShowNoItemsMessage || isSearchActive
        ? filtersElement
        : noFiltersElement}
      <Skeleton withDelay isLoading={isSkeletonLoading}>
        <WrapperTag {...wrapperTagProps}>
          {isShowNoItemsMessage &&
            renderNoItemsMessageWrapper(noItemsMessageToDisplay)}

          {itemsToRender.map(renderItem)}

          {!isSkeletonLoading &&
            isLoading &&
            !isFetchingMore &&
            renderLoader?.()}
          {(isFetchingMore || hasMore) && renderLoader?.(sentryRef)}

          {children}
        </WrapperTag>
      </Skeleton>
    </>
  );
};

// Workaround for typing generic HOC
type RenderAsyncList = <
  Item extends any,
  WithSkeletonItems extends boolean = true,
>(
  props: AsyncListProps<Item, WithSkeletonItems>
) => React.ReactElement;

export const AsyncList = React.forwardRef<HTMLElement, AsyncListProps<any>>(
  AsyncListInternal
) as RenderAsyncList;
