import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import { BlueprintEditService, BlueprintInputNode } from '../../services';
import { NewInputNodeUI } from './components/NewInputNodeUI';

interface Props {
  close: () => void;
  cycleInputId?: string;
}

export const NewInputNode: React.FC<Props> = ({ close, cycleInputId }) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const createInputFunc = useMemo(() => {
    return bpEditSvc.createInputNode.bind(bpEditSvc);
  }, [bpEditSvc.createInputNode]);

  const { errors, loading, reload: createInput } = useApiData(createInputFunc);

  const submit = useCallback(
    async (input: BlueprintInputNode) => {
      const result = await createInput({
        ...input,
        cycleInputId,
      });
      if (result.success) {
        close();
      }
    },
    [createInput]
  );

  return (
    <NewInputNodeUI
      errors={errors}
      loading={loading}
      cancel={close}
      submit={submit}
    />
  );
};
