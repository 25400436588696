import { ViewKindEnum } from '@graphql-types';

/**
 * Possible blueprint view kinds
 */
export const VIEW_KINDS_DICT: Record<ViewKindEnum, string> = {
  [ViewKindEnum.Table]: 'Список',
  [ViewKindEnum.Action]: 'Ввод данных',
  [ViewKindEnum.Graph]: 'График',
};
