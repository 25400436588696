import React from 'react';

import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { StringEdit } from './StringEdit';
import { StringView } from './StringView';

export const StringEditor: React.FC<ValueEditorArgs<string>> = props => {
  const Component = EditorHoC(StringView, StringEdit);

  return <Component {...props} />;
};
