import React from 'react';

import { useSvgKeyPress } from '~/~legacy/hooks/useSvgKeyPress';
import { ReactComponent as Pencil } from '~/~legacy/icons/pencil.svg';

import './MEditToggle.scss';

interface Props {
  toggle: () => void;
}

export const MEditToggle: React.FC<Props> = props => {
  const { toggle } = props;

  const onKey = useSvgKeyPress<HTMLDivElement>(toggle);

  return (
    <div
      onKeyDown={onKey}
      tabIndex={0}
      onClick={toggle}
      className="m-edit-toggle"
    >
      <Pencil />
    </div>
  );
};
