import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type EmployeeFragment = { __typename: 'Employee', id: string, comment: string, number: string, firstName: string, middleName: string, lastName: string, company: { __typename: 'Company', id: string } };

export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  __typename
  id
  comment
  number
  firstName
  middleName
  lastName
  company {
    __typename
    id
  }
}
    `;