import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../../entities/farms/gql/fragments/farm.graphql';
export type FarmWithReproductionSettingsFragment = { __typename: 'Farm', id: string, name: string, number: string, reproductionSettings: { __typename?: 'ReproductionSettings', cowsVoluntaryWaitingPeriodDays: number, heifersVoluntaryWaitingPeriodDays: number }, reproductionTargets: { __typename?: 'ReproductionTargets', hdrCowsTargetPercent: number, hdrCowsDeviationThresholdPercent: number, hdrHeifersTargetPercent: number, hdrHeifersDeviationThresholdPercent: number, prCowsTargetPercent: number, prCowsDeviationThresholdPercent: number, prHeifersTargetPercent: number, prHeifersDeviationThresholdPercent: number, crCowsTargetPercent: number, crCowsDeviationThresholdPercent: number, crHeifersTargetPercent: number, crHeifersDeviationThresholdPercent: number } };

export const FarmWithReproductionSettingsFragmentDoc = gql`
    fragment FarmWithReproductionSettings on Farm {
  ...Farm
  reproductionSettings {
    cowsVoluntaryWaitingPeriodDays
    heifersVoluntaryWaitingPeriodDays
  }
  reproductionTargets {
    hdrCowsTargetPercent
    hdrCowsDeviationThresholdPercent
    hdrHeifersTargetPercent
    hdrHeifersDeviationThresholdPercent
    prCowsTargetPercent
    prCowsDeviationThresholdPercent
    prHeifersTargetPercent
    prHeifersDeviationThresholdPercent
    crCowsTargetPercent
    crCowsDeviationThresholdPercent
    crHeifersTargetPercent
    crHeifersDeviationThresholdPercent
  }
}
    ${FarmFragmentDoc}`;