import React from 'react';

export const HerriotConnected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16.4973 8.43832C16.8074 8.71296 16.8361 9.18697 16.5614 9.49704L11.2472 15.497C10.9542 15.8279 10.4217 15.8293 10.1269 15.5L7.44122 12.5C7.16493 12.1914 7.19114 11.7173 7.49976 11.441C7.80837 11.1647 8.28252 11.1909 8.5588 11.4995L10.6827 13.872L15.4386 8.50249C15.7132 8.19241 16.1872 8.16368 16.4973 8.43832Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6855 1.78329L10.6869 1.78264C11.501 1.40708 12.4991 1.40709 13.3132 1.78266L13.3145 1.78329L20.3145 4.98394C21.1228 5.35736 21.7488 6.13114 21.75 7.04813L21.75 16.9514C21.7488 17.8683 21.1228 18.6421 20.3145 19.0155L13.3145 22.2162L13.3132 22.2168C12.4991 22.5924 11.501 22.5924 10.6869 22.2169L10.6855 22.2162L3.68813 19.0168L3.68546 19.0155C2.87667 18.6419 2.25118 17.8678 2.25 16.9504V7.04968C2.25118 6.132 2.87636 5.35773 3.68546 4.98395L3.68813 4.98272L10.6855 1.78329ZM19.6866 6.34617C20.0836 6.53002 20.2497 6.82172 20.25 7.04813L20.25 16.9494C20.2497 17.1758 20.0836 17.4695 19.6866 17.6533L19.6855 17.6538L12.6881 20.8533L12.6855 20.8545C12.2841 21.0399 11.7159 21.0399 11.3145 20.8545L11.3119 20.8533L4.31454 17.6538L4.31336 17.6533C3.91655 17.4695 3.75045 17.1761 3.75 16.9498L3.75 7.04968C3.75045 6.82337 3.91655 6.52996 4.31336 6.34621L4.31454 6.34566L11.3119 3.14623L11.3145 3.145C11.7159 2.95959 12.2841 2.95959 12.6855 3.145L12.6881 3.14623L19.6855 6.34566L19.6866 6.34617Z"
      fill="currentColor"
    />
  </svg>
);
