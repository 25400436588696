import { makeUseModal, ModalNames } from '~/services/modals';

import { ApplyCutCodeModalProps } from './ApplyCutCodeModal';
import { EditMilkingParlorIntervalModalProps } from './EditMilkingParlorIntervalModal';
import { EditMilkingParlorModalProps } from './EditMilkingParlorModal';
import { EditMilkingStartModalProps } from './EditMilkingStartModal';

export const useEditMilkingParlorModal =
  makeUseModal<EditMilkingParlorModalProps>(ModalNames.editMilkingParlorModal);

export const useEditMilkingParlorIntervalModal =
  makeUseModal<EditMilkingParlorIntervalModalProps>(
    ModalNames.editMilkingParlorIntervalModal
  );

export const useEditMilkingStartModal =
  makeUseModal<EditMilkingStartModalProps>(ModalNames.editMilkingStartModal);

export const useApplyCutCodeModal = makeUseModal<ApplyCutCodeModalProps>(
  ModalNames.applyCutCodeModal
);
