import React from 'react';

import { BlueprintRoleColor } from '@graphql-types';

import { ColoredDot } from '~/shared/components/ColoredDot';
import { ColorShades, getColorTokenValue } from '~/shared/helpers/color';

import { ROLE_COLOR_CONFIGS } from '~/entities/roles';

interface Props {
  /**
   * value of BlueprintRoleColor
   */
  value: BlueprintRoleColor;
  /**
   * Should use text with the dot
   */
  withText?: boolean;
}

export const RoleColor: React.FC<Props> = ({ value, withText = false }) => {
  const { colorVariant, title } = ROLE_COLOR_CONFIGS[value];

  return (
    <div className="flex gap-8 items-center">
      <ColoredDot
        color={getColorTokenValue(colorVariant, ColorShades.default)}
      />
      {withText && <div>{title}</div>}
    </div>
  );
};
