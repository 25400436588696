import { ReactNode } from 'react';

import { Subject } from 'rxjs';

import { FunctionButtonProps } from '~/shared/components/FunctionButton';

import { AlertProps, CalloutProps, ToastProps } from './components';

/**
 * Possible notification component kinds
 */
export enum NotificationKinds {
  callout = 'callout',
  alert = 'alert',
  toast = 'toast',
}

/**
 * Possible notification importance variants
 */
export enum NotificationVariants {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
  neutral = 'neutral',
}

type PartialFunctionButtonProps = Omit<Partial<FunctionButtonProps>, 'ref'>;

/**
 * Common props for any notification component
 */
export interface NotificationProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Variant of notification to display
   */
  variant?: NotificationVariants;
  /**
   * Message to display
   */
  message?: ReactNode;
  /**
   * If true, renders a close button
   */
  withCloseButton?: boolean;
  /**
   * Called, when the notification close button is pressed
   */
  onClose?: () => void;
  /**
   * If passed, adds buttons to notification
   */
  functionButtonProps?:
    | PartialFunctionButtonProps
    | PartialFunctionButtonProps[];
}

/**
 * Gets props type for a given notification kind
 */
export type NotificationPropsByKind<Kind extends NotificationKinds> =
  Kind extends NotificationKinds.callout
    ? CalloutProps
    : Kind extends NotificationKinds.alert
      ? AlertProps
      : ToastProps;

/**
 * Data for passing into notificationsSubject$ to display different notification kinds
 */
export interface NotificationConfig<
  Kind extends NotificationKinds = NotificationKinds,
> {
  id: string;
  kind: Kind;
  props: NotificationPropsByKind<Kind>;
}

/**
 * Type for NotificationsContextProvider
 */
export interface NotificationsContextType {
  sendNotification: <Kind extends NotificationKinds>(
    kind: NotificationKinds,
    props: NotificationPropsByKind<Kind>
  ) => void;
  notificationsSubject$: Subject<NotificationConfig>;
}
