import { match, P } from 'ts-pattern';

import { EventFragment } from '../gql/fragments/event.graphql';
import { useDeleteDiseaseMutation } from '../gql/mutations/deleteDisease.graphql';
import { useDeleteInjectionMutation } from '../gql/mutations/deleteInjection.graphql';
import { useDeleteInseminationSchemeMutation } from '../gql/mutations/deleteInseminationScheme.graphql';
import { useDeleteProtocolMutation } from '../gql/mutations/deleteProtocol.graphql';
import { useDeleteUserEventMutation } from '../gql/mutations/deleteUserEvent.graphql';
import {
  isDefaultEvent,
  isDisease,
  isInjection,
  isInseminationScheme,
  isProtocol,
  isUserEvent,
} from '../helpers';

export const useDeleteEvent = () => {
  const [deleteDisease] = useDeleteDiseaseMutation();
  const [deleteInjection] = useDeleteInjectionMutation();
  const [deleteInseminationScheme] = useDeleteInseminationSchemeMutation();
  const [deleteProtocol] = useDeleteProtocolMutation();
  const [deleteUserEvent] = useDeleteUserEventMutation();

  return (event: EventFragment) => {
    if (isDefaultEvent(event)) return;

    const queryOptions = {
      variables: { id: event.id },
      refetchQueries: ['events'],
    };

    match(event)
      .with(P.when(isDisease), () => deleteDisease(queryOptions))
      .with(P.when(isInjection), () => deleteInjection(queryOptions))
      .with(P.when(isInseminationScheme), () =>
        deleteInseminationScheme(queryOptions)
      )
      .with(P.when(isProtocol), () => deleteProtocol(queryOptions))
      .with(P.when(isUserEvent), () => deleteUserEvent(queryOptions))
      .exhaustive();
  };
};
