import React from 'react';

export const Syringe = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.558 1.22456C14.8021 0.980484 15.1978 0.980484 15.4419 1.22456L18.7752 4.5579C19.0193 4.80197 19.0193 5.1977 18.7752 5.44178C18.5312 5.68586 18.1354 5.68586 17.8914 5.44178L16.6666 4.21705L15.0505 5.83317L17.1086 7.89123C17.3526 8.13531 17.3526 8.53103 17.1086 8.77511C16.8645 9.01919 16.4688 9.01919 16.2247 8.77511L15.8333 8.3837L7.69186 16.5251C6.70088 17.5161 5.18764 17.6145 4.1682 16.7155L2.10857 18.7751C1.86449 19.0192 1.46876 19.0192 1.22468 18.7751C0.980607 18.531 0.980607 18.1353 1.22468 17.8912L3.29167 15.8242C2.41452 14.7652 2.44379 13.2468 3.48551 12.297L11.6161 4.16648L11.2247 3.77511C10.9806 3.53103 10.9806 3.13531 11.2247 2.89123C11.4688 2.64715 11.8645 2.64715 12.1086 2.89123L14.1666 4.94929L15.7827 3.33317L14.558 2.10845C14.3139 1.86437 14.3139 1.46864 14.558 1.22456ZM14.9494 7.49982L10.8333 11.6159L8.38382 9.16648L12.4999 5.05037L14.9494 7.49982ZM6.80798 15.6412L9.94939 12.4998L7.49994 10.0504L4.35853 13.1918C3.74984 13.8005 4.06506 14.8528 4.60857 15.3912C5.22132 16.0098 6.11344 16.3358 6.80798 15.6412Z"
      fill="currentColor"
    />
  </svg>
);
