import { CowFieldFragment } from '~/entities/cows/gql/fragments/cowField.graphql';

/**
 * Checks, if the cow field is static (so it has no blueprintSourceField)
 */
export const isCowStaticField = (
  field: CowFieldFragment
): field is Exclude<CowFieldFragment, { __typename: 'CowCalculatedField' }> =>
  field.__typename === 'CowStaticField';

/**
 * Gets a unique identifier for different cow field types
 */
export const getCowFieldId = (field: CowFieldFragment) => {
  if (isCowStaticField(field)) {
    return field.kind;
  }

  return field.blueprintSourceField.id;
};
