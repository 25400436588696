import R from 'ramda';

import { makeUseAsyncSelect } from '~/services/gql';

import { MilkingParlorShortFragmentDoc } from '../../gql/fragments/milkingParlorShort.graphql';
import { useMilkingParlorsShortQuery } from '../../gql/queries/milkingParlorsShort.graphql';

/**
 * Select for milkingParlor id
 */
export const useMilkingParlorsSelect = makeUseAsyncSelect({
  typeName: 'MilkingParlor',
  fragment: MilkingParlorShortFragmentDoc,
  useQuery: useMilkingParlorsShortQuery,
  getItemsFromQueryData: data => data.milkingParlors.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.milkingParlors.pageInfo,
  selectProps: {
    label: 'Доильный зал',
  },
});
