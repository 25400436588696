import React from 'react';

import {
  BlueprintEditHeaderWrapper,
  blueprintHeaderStyles,
} from '~/widgets/blueprintEdit';

interface Props {
  blueprintName: string;
}

export const MasterBlueprintEditHeader: React.FC<Props> = props => {
  const { blueprintName } = props;
  return (
    <BlueprintEditHeaderWrapper>
      <div className={blueprintHeaderStyles.link}>
        <p>{blueprintName}</p>
      </div>
    </BlueprintEditHeaderWrapper>
  );
};
