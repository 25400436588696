import React from 'react';

export const Company = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9.44959 6.59997C9.44959 6.18576 9.78537 5.84997 10.1996 5.84997H13.7996C14.2138 5.84997 14.5496 6.18576 14.5496 6.59997C14.5496 7.01418 14.2138 7.34997 13.7996 7.34997H10.1996C9.78537 7.34997 9.44959 7.01418 9.44959 6.59997Z"
      fill="currentColor"
    />
    <path
      d="M10.1996 9.45001C9.78537 9.45001 9.44959 9.78579 9.44959 10.2C9.44959 10.6142 9.78537 10.95 10.1996 10.95H13.7996C14.2138 10.95 14.5496 10.6142 14.5496 10.2C14.5496 9.78579 14.2138 9.45001 13.7996 9.45001H10.1996Z"
      fill="currentColor"
    />
    <path
      d="M9.44959 13.8C9.44959 13.3858 9.78537 13.05 10.1996 13.05H13.7996C14.2138 13.05 14.5496 13.3858 14.5496 13.8C14.5496 14.2142 14.2138 14.55 13.7996 14.55H10.1996C9.78537 14.55 9.44959 14.2142 9.44959 13.8Z"
      fill="currentColor"
    />
    <path
      d="M10.1996 16.65C9.78537 16.65 9.44959 16.9858 9.44959 17.4C9.44959 17.8142 9.78537 18.15 10.1996 18.15H13.7996C14.2138 18.15 14.5496 17.8142 14.5496 17.4C14.5496 16.9858 14.2138 16.65 13.7996 16.65H10.1996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.15 8.55L18.15 4.77482C18.1501 4.54641 18.1503 4.21674 18.0657 3.8755C17.8847 3.11908 17.2725 2.51084 16.5153 2.33421C16.1819 2.24961 15.8518 2.24983 15.6273 2.24998L8.37768 2.24999C8.14538 2.2499 7.8266 2.24978 7.47854 2.33356C6.7207 2.51366 6.11106 3.12641 5.93419 3.88464C5.84959 4.21804 5.84981 4.54813 5.84996 4.7727L5.84998 11.25H4.79968C3.28608 11.25 2.20004 12.2754 2.24968 13.8128V19.2C2.24968 20.6042 3.39547 21.75 4.79968 21.75L19.1998 21.75C20.5944 21.75 21.7498 20.5946 21.7498 19.2V11.1C21.7498 9.58645 20.7243 8.50036 19.187 8.55H18.15ZM15.6 3.74999H8.39998C7.63383 3.74999 7.34998 4.03405 7.34998 4.79999V20.25H16.6498V9.3L16.65 9.28275V4.79999C16.65 4.03459 16.3657 3.74999 15.6 3.74999ZM18.15 20.25V10.05H19.1998C19.9109 10.05 20.2498 10.3761 20.2498 11.1V19.2C20.2498 19.7662 19.766 20.25 19.1998 20.25H18.15ZM3.74968 13.8V19.2C3.74968 19.7758 4.2239 20.25 4.79968 20.25H5.84968V12.75H4.79968C4.07568 12.75 3.74968 13.0889 3.74968 13.8Z"
      fill="currentColor"
    />
  </svg>
);
