import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BullForDeletionFragmentDoc } from '../fragments/BullForDeletion.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BullsForDeletionQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type BullsForDeletionQuery = { __typename?: 'Query', bulls: { __typename?: 'BullConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'BullEdge', cursor: string, node: { __typename: 'Bull', id: string, shouldBeMigratedOnDelete: boolean } }> } };


export const BullsForDeletionDocument = gql`
    query bullsForDeletion($ids: [ID!]) {
  bulls(ids: $ids) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...BullForDeletion
      }
    }
  }
}
    ${BullForDeletionFragmentDoc}`;

/**
 * __useBullsForDeletionQuery__
 *
 * To run a query within a React component, call `useBullsForDeletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBullsForDeletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBullsForDeletionQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBullsForDeletionQuery(baseOptions?: Apollo.QueryHookOptions<BullsForDeletionQuery, BullsForDeletionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BullsForDeletionQuery, BullsForDeletionQueryVariables>(BullsForDeletionDocument, options);
      }
export function useBullsForDeletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BullsForDeletionQuery, BullsForDeletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BullsForDeletionQuery, BullsForDeletionQueryVariables>(BullsForDeletionDocument, options);
        }
export function useBullsForDeletionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BullsForDeletionQuery, BullsForDeletionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BullsForDeletionQuery, BullsForDeletionQueryVariables>(BullsForDeletionDocument, options);
        }
export type BullsForDeletionQueryHookResult = ReturnType<typeof useBullsForDeletionQuery>;
export type BullsForDeletionLazyQueryHookResult = ReturnType<typeof useBullsForDeletionLazyQuery>;
export type BullsForDeletionSuspenseQueryHookResult = ReturnType<typeof useBullsForDeletionSuspenseQuery>;
export type BullsForDeletionQueryResult = Apollo.QueryResult<BullsForDeletionQuery, BullsForDeletionQueryVariables>;