import React from 'react';

import { Button, ButtonVariants } from '~/shared/components/Button';

import { CalvingEditFunction, FreshActionCalvingData } from '../../reducer';
import { FreshActionCalvingForm } from '../FreshActionCalvingForm';

interface Props {
  showAddButton: boolean;
  topCalving: FreshActionCalvingData;
  calvings: FreshActionCalvingData[];
  update: CalvingEditFunction;
  del: CalvingEditFunction;
  add: () => void;
}

export const FreshActionCalvingFormsList: React.FC<Props> = ({
  showAddButton,
  topCalving,
  calvings,
  update,
  del,
  add,
}) => {
  const addButton = showAddButton ? (
    <div key="add-button" className="full-width p-16 m-centered-content">
      <Button variant={ButtonVariants.secondary} onPress={add}>
        Добавить корову
      </Button>
    </div>
  ) : null;

  const calvingForms = calvings.map(calving => (
    <FreshActionCalvingForm
      update={update}
      del={del}
      key={calving.tempId}
      calving={calving}
      collapsed
    />
  ));

  return (
    <>
      {calvingForms}
      <FreshActionCalvingForm
        update={update}
        del={del}
        collapsed={false}
        calving={topCalving}
      />
      {addButton}
    </>
  );
};
