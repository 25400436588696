import React, { ReactNode, useMemo } from 'react';

import {
  BlueprintExecutionInfo,
  BlueprintExecutionResult,
} from '~/~legacy/services/BlueprintExecutionService';

import { EmptyExecutionResult } from './components/EmptyExecutionResult';
import { TableExecutionResult } from './components/TableExecutionResult';

// probably replace it with gql schema type
enum ExecutionResultType {
  Empty = 'Empty',
  Table = 'Table',
  Chart = 'Chart',
}

interface Props {
  executionInfo: BlueprintExecutionInfo;
  executionResult: BlueprintExecutionResult;
  cancel: () => void;
}

export const BlueprintExecutionResults: React.FC<Props> = props => {
  const { executionResult, executionInfo, cancel } = props;

  const resultsMap = useMemo(() => {
    return {
      [ExecutionResultType.Empty]: () => (
        <EmptyExecutionResult executionInfo={executionInfo} cancel={cancel} />
      ),
      [ExecutionResultType.Table]: () => (
        <TableExecutionResult
          executionInfo={executionInfo}
          results={executionResult}
          cancel={cancel}
        />
      ),
    } as Record<ExecutionResultType, () => ReactNode>;
  }, [executionResult, executionInfo]);

  const resultType =
    executionResult?.rows.length === 0
      ? ExecutionResultType.Empty
      : ExecutionResultType.Table;

  const getElement = resultsMap[resultType];
  const element = getElement();

  return <>{element}</>;
};
