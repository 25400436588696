import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SourceFieldFragmentDoc } from '../../../blueprintSourceFields/gql/fragments/sourceField.graphql';
import { CustomReportSettingsSortByFragmentDoc } from './customReportSettingsSortBy.graphql';
export type CustomReportSettingsRowFragment = { __typename: 'CustomReportSettingsRow', order: Types.CustomReportOrderEnum, withTotal: boolean, blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum }, sortBy: { __typename: 'CustomReportSettingsSortBy', kind: Types.CustomReportSortByKindEnum, sortingValue?: { __typename?: 'CustomReportSettingsSortBySortingValue', valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } }, blueprintSourceFieldValue?: { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } | null } | null } };

export const CustomReportSettingsRowFragmentDoc = gql`
    fragment CustomReportSettingsRow on CustomReportSettingsRow {
  __typename
  blueprintSourceField {
    ...SourceField
  }
  order
  sortBy {
    ...CustomReportSettingsSortBy
  }
  withTotal
}
    ${SourceFieldFragmentDoc}
${CustomReportSettingsSortByFragmentDoc}`;