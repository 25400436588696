export function gql(query: TemplateStringsArray, ...rest: any[]) {
  if (rest.length > 0) {
    const result = [query.raw[0]];

    for (let i = 0; i < rest.length; i += 1) {
      result.push(rest[i]);
      result.push(query.raw[i + 1]);
    }
    const str = result.join('');
    return str;
  }

  // be sure that there are no template variables in the passed string!!!
  return query.join('');
}
