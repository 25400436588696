import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestAuthCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['Email']['input'];
}>;


export type RequestAuthCodeMutation = { __typename?: 'Mutation', requestAuthCode?: any | null };


export const RequestAuthCodeDocument = gql`
    mutation requestAuthCode($email: Email!) {
  requestAuthCode(email: $email)
}
    `;
export type RequestAuthCodeMutationFn = Apollo.MutationFunction<RequestAuthCodeMutation, RequestAuthCodeMutationVariables>;

/**
 * __useRequestAuthCodeMutation__
 *
 * To run a mutation, you first call `useRequestAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuthCodeMutation, { data, loading, error }] = useRequestAuthCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<RequestAuthCodeMutation, RequestAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAuthCodeMutation, RequestAuthCodeMutationVariables>(RequestAuthCodeDocument, options);
      }
export type RequestAuthCodeMutationHookResult = ReturnType<typeof useRequestAuthCodeMutation>;
export type RequestAuthCodeMutationResult = Apollo.MutationResult<RequestAuthCodeMutation>;
export type RequestAuthCodeMutationOptions = Apollo.BaseMutationOptions<RequestAuthCodeMutation, RequestAuthCodeMutationVariables>;