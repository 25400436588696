import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { useFarmSelect } from '~/entities/farms';

interface Props {
  /**
   * defaultValues for identifierMappings form
   */
  defaultValues: IdentifierMappingsFormType;
  /**
   * Called, when the form is submitted
   */
  onSubmit: (form: IdentifierMappingsFormType) => void;
}

const FORM_ID = 'IdentifierMappingsForm';

const IDENTIFIER_MAPPING_ITEM_SCHEMA = yup
  .object({
    originIdentifier: yup.number().required(), // ID!
    targetIdentifier: yup.number().required(), // ID!
  })
  .required();

const SCHEMA = yup.object({
  arriveDate: yup.string().default('').required(), // DateTime!
  penGroupID: yup.string().default('').required(), // ID!
  farmID: yup.string().required(), // ID!
  identifierMappings: yup.array(IDENTIFIER_MAPPING_ITEM_SCHEMA).default([]),
});

/**
 * Form for editing identifier mapping items
 */
export type IdentifierMappingsFormType = InferValidatedSchema<typeof SCHEMA>;

export const useIdentifierMappingsForm = ({
  defaultValues,
  onSubmit: handleSubmit,
}: Props): UseModalStepFormInterface<IdentifierMappingsFormType> => {
  const { itemsPromise: farmsItemsPromise } = useFarmSelect({
    selectProps: { name: 'farmID' },
  });

  const formContext = useForm<IdentifierMappingsFormType>({
    schema: SCHEMA,
    defaultValues: () =>
      farmsItemsPromise.then(items => ({
        ...SCHEMA.getDefault(),
        ...defaultValues,
        farmID: defaultValues.farmID || items[0]?.id,
      })),
  });

  return {
    formContext,
    formId: FORM_ID,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
