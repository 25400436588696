import { useControllableState } from '~/shared/hooks/useControllableState';
import { useDebouncedValue } from '~/shared/hooks/useDebouncedValue';

const SEARCH_DEBOUNCE_MS = 500;

interface UseDebouncedSearchProps {
  search?: string;
  defaultSearch?: string;
  onSearchChange?: (value: string) => void;
  onDebouncedSearchChange?: (value: string) => void;
}

/**
 * Hook for creating a string search state with also handing it's debounced value
 */
export const useDebouncedSearch = ({
  search: searchProp,
  defaultSearch = '',
  onSearchChange,
  onDebouncedSearchChange,
}: UseDebouncedSearchProps = {}) => {
  const [search, setSearch] = useControllableState(
    searchProp,
    onSearchChange,
    defaultSearch
  );
  const debouncedSearch = useDebouncedValue(
    search,
    SEARCH_DEBOUNCE_MS,
    onDebouncedSearchChange
  );

  const isSearchActive = !!debouncedSearch;

  return { search, setSearch, debouncedSearch, isSearchActive };
};
