import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MonitorScheduleFragmentDoc } from '../fragments/monitorSchedule.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanyMonitorScheduleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CompanyMonitorScheduleQuery = { __typename?: 'Query', company?: { __typename: 'Company', id: string, monitorSchedule?: { __typename: 'MonitorSchedule', interval: Types.MonitorScheduleInterval, startOn: string, dows?: Array<Array<Types.Dow>> | null } | null } | null };


export const CompanyMonitorScheduleDocument = gql`
    query companyMonitorSchedule($id: ID!) {
  company(id: $id) {
    __typename
    id
    monitorSchedule {
      ...MonitorSchedule
    }
  }
}
    ${MonitorScheduleFragmentDoc}`;

/**
 * __useCompanyMonitorScheduleQuery__
 *
 * To run a query within a React component, call `useCompanyMonitorScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMonitorScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMonitorScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyMonitorScheduleQuery(baseOptions: Apollo.QueryHookOptions<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables> & ({ variables: CompanyMonitorScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables>(CompanyMonitorScheduleDocument, options);
      }
export function useCompanyMonitorScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables>(CompanyMonitorScheduleDocument, options);
        }
export function useCompanyMonitorScheduleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables>(CompanyMonitorScheduleDocument, options);
        }
export type CompanyMonitorScheduleQueryHookResult = ReturnType<typeof useCompanyMonitorScheduleQuery>;
export type CompanyMonitorScheduleLazyQueryHookResult = ReturnType<typeof useCompanyMonitorScheduleLazyQuery>;
export type CompanyMonitorScheduleSuspenseQueryHookResult = ReturnType<typeof useCompanyMonitorScheduleSuspenseQuery>;
export type CompanyMonitorScheduleQueryResult = Apollo.QueryResult<CompanyMonitorScheduleQuery, CompanyMonitorScheduleQueryVariables>;