import React, { useState } from 'react';

import { Dow, MonitorScheduleInterval } from '@graphql-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { produce } from 'immer';
import R from 'ramda';
import * as yup from 'yup';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { Checkbox } from '~/shared/components/Checkbox';
import { FeedbackVariants } from '~/shared/components/FieldFeedback';
import { makeUseEnumSelect } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { capitalize } from '~/shared/helpers/string';
import { oneOfEnum } from '~/shared/helpers/yup';
import { useAccordion } from '~/shared/hooks/useAccordion';

import { DateInput } from '~/services/dateTime';
import { DAYS_OF_WEEK, formatDateForBackend } from '~/services/dateTime';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { MonitorScheduleFragment } from '../../gql/fragments/monitorSchedule.graphql';
import { useSetMonitorScheduleMutation } from '../../gql/mutations/setMonitorSchedule.graphql';
import { useUnsetMonitorScheduleMutation } from '../../gql/mutations/usetMonitorSchedule.graphql';
import styles from './index.module.scss';

const useMonitorScheduleIntervalSelect = makeUseEnumSelect(
  MonitorScheduleInterval,
  EnumStrings.monitorScheduleInterval
);

export interface MonitorScheduleSettingsModalProps
  extends InjectedModalProps<MonitorScheduleSettingsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Current monitor schedule settings (if any)
   */
  monitorSettings?: MonitorScheduleFragment | null;
}

const FORM_ID = 'MonitorScheduleSettingsForm';

const DEFAULT_DOWS = [[], []];

const ENABLED_SCHEMA = yup.object({
  interval: oneOfEnum(MonitorScheduleInterval).default(
    MonitorScheduleInterval.Monthly
  ),
  dows: yup
    .array(yup.array(oneOfEnum(Dow).required()).required())
    .default(DEFAULT_DOWS),
  startOn: yup.string().default('').required(), // DateTime!
});

const DISABLED_SCHEMA = yup.object();

type MonitorScheduleSettingsFormType =
  | InferValidatedSchema<typeof ENABLED_SCHEMA>
  | InferValidatedSchema<typeof DISABLED_SCHEMA>;

export const MonitorScheduleSettingsModal: React.FC<
  MonitorScheduleSettingsModalProps
> = ({ className, monitorSettings, close }) => {
  const [isEnabled, setEnabled] = useState(!!monitorSettings);

  const formContext = useForm<MonitorScheduleSettingsFormType>({
    schema: isEnabled ? ENABLED_SCHEMA : DISABLED_SCHEMA,
    defaultValues: {
      ...ENABLED_SCHEMA.getDefault(),
      ...R.omit(['__typename'], monitorSettings as MonitorScheduleFragment),
    },
  });
  const interval = formContext.watch('interval');
  const dows = formContext.watch('dows');

  const [setMonitorScheduleMutation, { loading: isSetMonitorScheduleLoading }] =
    useSetMonitorScheduleMutation();
  const [
    unsetMonitorScheduleMutation,
    { loading: isUnsetMonitorScheduleLoading },
  ] = useUnsetMonitorScheduleMutation();

  const handleSubmit = async (form: MonitorScheduleSettingsFormType) => {
    if (!isEnabled) {
      await unsetMonitorScheduleMutation({
        refetchQueries: ['companyMonitorSchedule'],
      });
    } else if ('interval' in form) {
      await setMonitorScheduleMutation({
        variables: {
          input: { ...form, startOn: formatDateForBackend(form.startOn) },
        },
        refetchQueries: ['companyMonitorSchedule'],
      });
    }

    close();
  };

  const isLoading =
    isSetMonitorScheduleLoading || isUnsetMonitorScheduleLoading;

  const { renderSelectElement: renderMonitorScheduleIntervalSelectElement } =
    useMonitorScheduleIntervalSelect({
      name: 'interval',
      className: 'my-16',
      label: 'Повторять с интервалом',
      onValueChange: newInterval => {
        const dowsToSet =
          newInterval &&
          (newInterval.id as MonitorScheduleInterval) ===
            monitorSettings?.interval
            ? (monitorSettings?.dows ?? DEFAULT_DOWS)
            : DEFAULT_DOWS;
        formContext.setValue('dows', dowsToSet);
      },
    });

  const {
    shouldRenderContent,

    childrenContainerRef,

    childrenWrapperProps,
  } = useAccordion({
    isOpen: isEnabled,
    shouldUnmountClosedChildren: true,
  });

  const renderDows = (weekIndex: number) => {
    return (
      <div className={clsx('flex gap-16', weekIndex ? 'mt-12' : 'mt-8')}>
        {DAYS_OF_WEEK.map((dow, dayIndex) => {
          const localeDayIndex = (dayIndex + 1) % DAYS_OF_WEEK.length;
          const weekDayLabel = dayjs().day(localeDayIndex).format('dd');
          return (
            <Checkbox
              key={dow}
              {...{
                name: `${weekIndex}_${dow}`,
                label: capitalize(weekDayLabel),
                withFormContext: false,
                value: dows[weekIndex]?.includes(dow),
                onValueChange: isSelected => {
                  formContext.setValue(
                    'dows',
                    produce(dows, draftDows => {
                      if (isSelected) {
                        draftDows[weekIndex]?.push(dow);
                      } else {
                        draftDows[weekIndex] = draftDows[weekIndex]?.filter(
                          d => d !== dow
                        );
                      }
                    })
                  );
                },
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      {...{
        className,
        title: 'Настройки авторасчёта',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Сохранить',
          isLoading,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Checkbox
          {...{
            name: 'isEnabled',
            withFormContext: false,
            label: 'Авторасчёт включён',
            value: isEnabled,
            onValueChange: setEnabled,
          }}
        />
        <div
          {...{ ...childrenWrapperProps, className: styles.accordionChildren }}
        >
          <div ref={childrenContainerRef}>
            {shouldRenderContent && (
              <>
                {renderMonitorScheduleIntervalSelectElement()}
                {interval !== MonitorScheduleInterval.Monthly && (
                  <>
                    <Typography
                      variant={TypographyVariants.descriptionLarge}
                      tag="label"
                      className="text-soft"
                    >
                      Дни для запуска
                    </Typography>
                    {renderDows(0)}
                    {interval === MonitorScheduleInterval.BiWeekly &&
                      renderDows(1)}
                  </>
                )}
                <DateInput
                  {...{
                    name: 'startOn',
                    className: 'mt-16',
                    label: 'Дата начала расчёта',
                    feedback: 'Расчёт начнётся в 23:59 по московскому времени',
                    feedbackProps: {
                      variant: FeedbackVariants.neutral,
                    },
                  }}
                />
              </>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
};
