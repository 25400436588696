import React, { ReactNode } from 'react';

import styles from './index.module.scss';

interface Props {
  children: ReactNode;
}

export const BlueprintEditCanvas: React.FC<Props> = props => {
  const { children } = props;

  return <div className={styles.root}>{children}</div>;
};
