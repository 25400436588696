import React, { useMemo } from 'react';

import {
  ReproductionCrReportChartKind,
  ReproductionHdrAndPrYAxisMetric,
  ReproductionYAxisMode,
} from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { wrapConditionalArrayElement } from '~/shared/helpers/array';

import { DatePeriod, formatDateRange } from '~/services/dateTime';

import {
  BarChart,
  BarChartDatasetConfig,
  CHART_COLOR_PROPS_ITERATOR,
  DataPointsTooltip,
  getChartOptions,
} from '~/features/charts';

import { ColorVariants } from '~/styles/__generated__/token-variants';
import panelStyles from '~/styles/modules/panel.module.scss';

import {
  REPRODUCTION_CHART_OPTIONS,
  REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS,
} from '../../constants';
import { useReproductionHdrAndPrReportXAxis } from '../../hooks/useReproductionHdrAndPrReportXAxis';
import { HdrAndPrReportDisplayProps } from '../../types';

interface Props extends HdrAndPrReportDisplayProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Actual period for comparison mode
   */
  actualPeriod?: DatePeriod;
  /**
   * Historic period for comparison mode
   */
  historicPeriod?: DatePeriod;
  /**
   * Chart kind to use
   */
  chartKind?: ReproductionCrReportChartKind;
  /**
   * Mode for y axis
   */
  yAxisMode?: ReproductionYAxisMode;
}

const CHART_HEIGHT_PX = 412;

export const ReproductionHdrAndPrChart: React.FC<Props> = ({
  className,
  reportRows,
  actualPeriod,
  historicReportRows,
  historicPeriod,
  yAxisMode = ReproductionYAxisMode.Percent_100,
  xAxisMode,
  yAxisMetrics,
  chartKind = ReproductionCrReportChartKind.Bar,
  femaleAnimalKind,
}) => {
  const isComparison =
    !!historicReportRows && !!actualPeriod && !!historicPeriod;
  const isBar = chartKind === ReproductionCrReportChartKind.Bar;

  const { xAxisName, xAxisLabels, getXAxisLabelByRowIndex } =
    useReproductionHdrAndPrReportXAxis({
      reportRows,
      historicReportRows,
      xAxisMode,
      femaleAnimalKind,
    });

  const { datasets, chartOptions } = useMemo(() => {
    const getShortComparableDatasetName = (
      yAxisMetric: ReproductionHdrAndPrYAxisMetric
    ) => {
      const metricConfig =
        REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric];
      return metricConfig.field.toUpperCase();
    };

    const metricsDatasets = yAxisMetrics.map(yAxisMetric => {
      const metricConfig =
        REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[yAxisMetric];
      return {
        key: yAxisMetric,
        label: isComparison
          ? `${getShortComparableDatasetName(yAxisMetric)}, текущий период ${formatDateRange(
              actualPeriod.interval.since,
              actualPeriod.interval.till
            )}`
          : metricConfig.label,
        type: isBar ? metricConfig.chartType : 'line',
        isTogglable: true,
        ...CHART_COLOR_PROPS_ITERATOR.next(metricConfig.color).value,
        data: reportRows.map(row => row[metricConfig.field] ?? NaN),
      } satisfies BarChartDatasetConfig;
    });

    const firstMetric = yAxisMetrics.at(0);

    const datasetsInternal = [
      ...metricsDatasets,
      ...wrapConditionalArrayElement(
        isComparison &&
          firstMetric &&
          ({
            key: 'comparison',
            label: `${getShortComparableDatasetName(firstMetric)}, период сравнения ${formatDateRange(
              historicPeriod.interval.since,
              historicPeriod.interval.till
            )}`,
            type: isBar ? 'bar' : 'line',
            isTogglable: true,
            ...CHART_COLOR_PROPS_ITERATOR.next(ColorVariants.info).value,
            data: historicReportRows.rows.map(
              row =>
                row[
                  REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS[firstMetric]
                    .field
                ] ?? NaN
            ),
          } satisfies BarChartDatasetConfig)
      ),
    ];

    return {
      datasets: datasetsInternal,
      chartOptions: getChartOptions(REPRODUCTION_CHART_OPTIONS, {
        scales: {
          x: {
            title: {
              text: xAxisName,
            },
          },
          y: {
            max:
              yAxisMode === ReproductionYAxisMode.Percent_100 ? 100 : undefined,
          },
        },
      }),
    };
  }, [reportRows, yAxisMode]);

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-16', className)}>
      <BarChart
        {...{
          datasets,
          labels: xAxisLabels,
          legendClassName: 'mb-32',
          height: CHART_HEIGHT_PX,
          renderTooltip: dataPoints => {
            const dateIndex = dataPoints.at(0)?.parsed.x;
            if (R.isNil(dateIndex)) {
              return null;
            }
            let xAxisLabel = getXAxisLabelByRowIndex(dateIndex);
            if (R.isNil(xAxisLabel)) {
              return null;
            }
            if (Array.isArray(xAxisLabel)) {
              xAxisLabel = xAxisLabel.join(' ');
            }
            return (
              <DataPointsTooltip
                {...{
                  title: `${xAxisName} ${xAxisLabel}`,
                  dataPoints,
                  datasets,
                  mainValueMeasurementUnit: '%',
                }}
              />
            );
          },
          chartOptions,
          isPercents: true,
        }}
      />
    </div>
  );
};
