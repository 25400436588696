import React, { useRef } from 'react';

import { parseTime, Time } from '@internationalized/date';
import { AriaTimeFieldProps, useTimeField } from '@react-aria/datepicker';
import { useTimeFieldState } from '@react-stately/datepicker';
import clsx from 'clsx';
import R from 'ramda';

import { DateTimeInputSegment } from '~/shared/components/DateTimeInputSegment';
import { FieldFeedback } from '~/shared/components/FieldFeedback';
import { Icon, IconVariants } from '~/shared/components/Icon';
import { Label } from '~/shared/components/Label';
import { DEFAULT_LOCALE } from '~/shared/constants';
import { mergeRefs } from '~/shared/helpers/mergeProps';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import styles from './index.module.scss';

interface Props extends BaseFieldProps<string | null> {
  /**
   * className applied to the root element
   */
  className?: string;
}

const TimeInputInner = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      className,

      isDisabled,
      isRequired,

      value: valueProp,
      defaultValue: defaultValueProp,
      onValueChange,

      label,
      labelProps,
      hasError,
      feedback,
      feedbackProps,

      ...other
    }: Props,
    ref
  ) => {
    const submitButtonRef = useRef<React.ElementRef<'button'>>(null);
    const inputRef = useRef<React.ElementRef<'input'>>(null);

    const parseTimeProp = (val: string | null | undefined) => {
      if (R.isNil(val)) return val;
      if (!val) return null;

      return parseTime(val);
    };

    const value = parseTimeProp(valueProp);
    const defaultValue = parseTimeProp(defaultValueProp);

    const [innerValue, setInnerValue] = useControllableState<Time | null>(
      value,
      newValue => onValueChange?.(newValue?.toString() ?? null),
      defaultValue
    );

    const dateFieldVendorProps: AriaTimeFieldProps<Time> = {
      ...other,
      name,
      label,
      value: innerValue,
      onChange: setInnerValue,
      onKeyDown: e => {
        if (e.key === 'Enter') {
          submitButtonRef.current?.click();
        }
      },
    };

    const state = useTimeFieldState({
      locale: DEFAULT_LOCALE,
      ...dateFieldVendorProps,
    });

    const innerRef = useRef<React.ElementRef<'div'>>(null);
    const { labelProps: labelPropsVendor, fieldProps: fieldPropsVendor } =
      useTimeField(dateFieldVendorProps, state, innerRef);

    return (
      <div
        className={clsx(styles.root, className, {
          [styles.disabled]: isDisabled,
          [styles.error]: hasError,
        })}
      >
        {!!label && (
          <Label
            {...{
              ...labelProps,
              ...labelPropsVendor,
              isRequired,
            }}
          >
            {label}
          </Label>
        )}
        <div
          {...{
            className: styles.inputContainer,
          }}
        >
          <div
            {...{
              ...fieldPropsVendor,
              className: 'flex items-center flex-1',
              ref: mergeRefs(ref, innerRef),
            }}
          >
            {state.segments.map((segment, index) => (
              <DateTimeInputSegment
                key={index}
                {...{
                  segment,
                  state,
                }}
              />
            ))}
          </div>
          <Icon
            {...{
              variant: IconVariants.clock,
              onPress: () => {
                inputRef.current?.showPicker();
              },
              className: 'text-muted',
            }}
          />
          <button hidden type="submit" ref={submitButtonRef} />
          <input
            {...{
              name: `${name}__picker`,
              ref: inputRef,
              type: 'time',
              tabIndex: -1,
              disabled: isDisabled,
              className: styles.input,
              onChange: e => {
                const inputValue = e.target.value;
                const valueToSet = inputValue ? parseTime(inputValue) : null;
                setInnerValue(valueToSet);
              },
              value: innerValue?.toString() ?? '',
            }}
          />
        </div>
        {!!feedback && <FieldFeedback content={feedback} {...feedbackProps} />}
      </div>
    );
  }
);

export const TimeInput = withOptionalFormController<Props, string | null>({
  defaultValue: null,
})(TimeInputInner);
