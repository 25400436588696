/**
 * Basic date formats, used across the system
 */
export enum DateFormats {
  short = 'short',
  shortWithWeekDay = 'shortWithWeekDay',
  onlyYear = 'onlyYear',
  onlyMonthShort = 'onlyMonthShort',
  onlyMonth = 'onlyMonth',
  monthAndYear = 'monthAndYear',
  dayAndMonth = 'dayAndMonth',
  dayAndMonthShort = 'dayAndMonthShort',
  dayAndMonthTwoDigit = 'dayAndMonthTwoDigit',
  full = 'full',
  fullWithTime = 'fullWithTime',
  shortWithTime = 'shortWithTime',
  onlyTime = 'onlyTime',
  duration = 'duration',
  durationWithSeconds = 'durationWithSeconds',
}

/**
 * Additional formats for date range, when we format since and till differently
 */
export enum DateRangeFormats {
  monthsAndSingleYear = 'monthsAndSingleYear',
}

/**
 * Second argument of formatting function may accept an enum format of custom Intl.DateTimeFormatOptions
 */
export type DateFormatOptions = DateFormats | Intl.DateTimeFormatOptions;

/**
 * Special container for a duration with backend support for dynamic dates
 */
export interface DatePeriod {
  interval: {
    since?: string;
    till?: string;
  };
  daysFromToday?: number | null;
}

/**
 * Possible date range picker display variants
 */
export enum DatePeriodPickerThemes {
  light = 'light',
  dark = 'dark',
}
