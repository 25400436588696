import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type MilkingParlorFlowRateReportRowEntryFragment = { __typename: 'MilkingParlorFlowRateReportRowEntry', cowStallPerHour?: number | null, flowRate0To15Sec?: number | null, flowRate15To30Sec?: number | null, flowRate30To60Sec?: number | null, peakFlowRate?: number | null, milkWeight0To120Sec?: number | null, milkWeightPercent0To120Sec?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: number } | null };

export const MilkingParlorFlowRateReportRowEntryFragmentDoc = gql`
    fragment MilkingParlorFlowRateReportRowEntry on MilkingParlorFlowRateReportRowEntry {
  __typename
  penGroup {
    ...PenGroupShort
  }
  cowStallPerHour
  flowRate0To15Sec
  flowRate15To30Sec
  flowRate30To60Sec
  peakFlowRate
  milkWeight0To120Sec
  milkWeightPercent0To120Sec
}
    ${PenGroupShortFragmentDoc}`;