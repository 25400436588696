import { useMemo } from 'react';

import { BlueprintLaunchInputValueInput } from '@graphql-types';
import * as yup from 'yup';

import { capitalize } from '~/shared/helpers/string';
import { BaseFieldProps } from '~/shared/types/controls';
import { ValueOf } from '~/shared/types/utility';

import {
  getBackendInputByValueKind,
  getValidationsByValueKind,
  renderInputByValueKind,
} from '~/entities/blueprintSourceFields';

import { BlueprintInputFragment } from '../gql/fragments/blueprintInput.graphql';

/**
 * Hook for rendering and validating blueprint inputs
 */
export const useBlueprintInputs = (
  blueprintInputsProp: BlueprintInputFragment[] = []
) => {
  // TODO For now we only can work with a single valueKind (and it should always be so)
  // maybe we should rework backend api later
  const blueprintInputs = useMemo(
    () => blueprintInputsProp.filter(input => input.valueKinds.length === 1),
    [blueprintInputsProp]
  );

  const getInputById = (inputId: string) =>
    blueprintInputs.find(input => input.id === inputId);

  const getBlueprintValueInputByInputId = (
    inputId: string,
    inputValue: ValueOf<BlueprintLaunchInputValueInput>
  ) => {
    const input = getInputById(inputId);
    if (!input) {
      return {};
    }

    return getBackendInputByValueKind(input.valueKinds[0], inputValue, true);
  };

  const blueprintFormSchema = useMemo(
    () =>
      yup.object({
        ...Object.fromEntries(
          blueprintInputs.map(input => [
            input.id,
            getValidationsByValueKind(input.valueKinds[0]).required(),
          ])
        ),
      }),
    [blueprintInputs]
  );

  const renderBlueprintInput = (
    blueprintInput: BlueprintInputFragment,
    additionalProps?: Partial<BaseFieldProps<any>>
  ) => {
    const generalInputProps = {
      name: blueprintInput.id,
      label: capitalize(blueprintInput.prompt || blueprintInput.name),
      ...additionalProps,
    };

    return renderInputByValueKind(
      blueprintInput.valueKinds[0],
      generalInputProps
    );
  };

  return {
    blueprintInputs,
    getBlueprintValueInputByInputId,
    blueprintFormSchema,
    renderBlueprintInput,
  };
};
