import { useCallback } from 'react';

import { SourceFieldFragment } from '../gql/fragments/sourceField.graphql';

/**
 * Hook for reusing source fields logic
 */
export const useBlueprintSourceFields = (
  sourceFields: SourceFieldFragment[] = []
) => {
  const getSourceFieldById = useCallback(
    (id: string | undefined) => sourceFields.find(f => f.id === id),
    [sourceFields]
  );

  return {
    getSourceFieldById,
  };
};

/**
 * Return type for the hook with source fields logic
 */
export type UseBlueprintSourceFieldsInterface = ReturnType<
  typeof useBlueprintSourceFields
>;
