import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DismissTestMilkingUploadNotFoundCowsMutationVariables = Types.Exact<{
  testMilkingUploadID: Types.Scalars['ID']['input'];
}>;


export type DismissTestMilkingUploadNotFoundCowsMutation = { __typename?: 'Mutation', dismissTestMilkingUploadNotFoundCows?: any | null };


export const DismissTestMilkingUploadNotFoundCowsDocument = gql`
    mutation dismissTestMilkingUploadNotFoundCows($testMilkingUploadID: ID!) {
  dismissTestMilkingUploadNotFoundCows(testMilkingUploadID: $testMilkingUploadID)
}
    `;
export type DismissTestMilkingUploadNotFoundCowsMutationFn = Apollo.MutationFunction<DismissTestMilkingUploadNotFoundCowsMutation, DismissTestMilkingUploadNotFoundCowsMutationVariables>;

/**
 * __useDismissTestMilkingUploadNotFoundCowsMutation__
 *
 * To run a mutation, you first call `useDismissTestMilkingUploadNotFoundCowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissTestMilkingUploadNotFoundCowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissTestMilkingUploadNotFoundCowsMutation, { data, loading, error }] = useDismissTestMilkingUploadNotFoundCowsMutation({
 *   variables: {
 *      testMilkingUploadID: // value for 'testMilkingUploadID'
 *   },
 * });
 */
export function useDismissTestMilkingUploadNotFoundCowsMutation(baseOptions?: Apollo.MutationHookOptions<DismissTestMilkingUploadNotFoundCowsMutation, DismissTestMilkingUploadNotFoundCowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissTestMilkingUploadNotFoundCowsMutation, DismissTestMilkingUploadNotFoundCowsMutationVariables>(DismissTestMilkingUploadNotFoundCowsDocument, options);
      }
export type DismissTestMilkingUploadNotFoundCowsMutationHookResult = ReturnType<typeof useDismissTestMilkingUploadNotFoundCowsMutation>;
export type DismissTestMilkingUploadNotFoundCowsMutationResult = Apollo.MutationResult<DismissTestMilkingUploadNotFoundCowsMutation>;
export type DismissTestMilkingUploadNotFoundCowsMutationOptions = Apollo.BaseMutationOptions<DismissTestMilkingUploadNotFoundCowsMutation, DismissTestMilkingUploadNotFoundCowsMutationVariables>;