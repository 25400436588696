import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SomaticCellsReportSettingsHistoricValueFragmentDoc } from './SomaticCellsReportSettingsHistoricValue.graphql';
import { BlueprintShortFragmentDoc } from '../../../../entities/blueprints/gql/fragments/blueprintShort.graphql';
export type SomaticCellsReportSettingsFragment = { __typename: 'SomaticCellsReportSettings', since?: string | null, till?: string | null, sccThousandsPerMl?: number | null, filters: Array<{ __typename: 'SomaticCellsReportSettingsHistoricFilter', kind?: Types.MilkingHistoricFilterKind | null, value?: { __typename: 'SomaticCellsReportSettingsHistoricValueLactationNumbers', lactationNumbers?: Array<number> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers', lactationGroupNumbers?: Array<Types.LactationGroupNumber> | null } | { __typename: 'SomaticCellsReportSettingsHistoricValuePenGroups', penGroups: Array<{ __typename: 'PenGroup', id: string, name: string, identifier: number }> } | null }>, blueprint?: { __typename: 'Blueprint', id: string, name: string, description: string } | null };

export const SomaticCellsReportSettingsFragmentDoc = gql`
    fragment SomaticCellsReportSettings on SomaticCellsReportSettings {
  __typename
  since
  till
  sccThousandsPerMl
  filters {
    ... on SomaticCellsReportSettingsHistoricFilter {
      __typename
      kind
      value {
        ...SomaticCellsReportSettingsHistoricValue
      }
    }
  }
  blueprint {
    ...BlueprintShort
  }
}
    ${SomaticCellsReportSettingsHistoricValueFragmentDoc}
${BlueprintShortFragmentDoc}`;