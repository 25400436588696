import { useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import { Loader } from '~/shared/components/Loader';
import { MErrorMessage } from '~/shared/components/MErrorMessage';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';

import layoutStyles from '~/styles/modules/layout.module.scss';

export interface ApiFeedbackProps {
  errors: string[];
  loading: boolean;
}

export function useApiFeedback(
  errors: (string | ApolloError | undefined)[] | ApolloError | undefined,
  loading?: boolean,
  errorClass?: string
) {
  const loader = useMemo(() => {
    return loading ? (
      <Loader className={layoutStyles.fullHeightContainer} />
    ) : null;
  }, [loading]);

  const errorMessage = useMemo(() => {
    const errorsArray = normalizeValuesToArray(errors);
    return errorsArray
      ?.map(item => {
        let errorString = item;
        if (typeof errorString !== 'string') {
          errorString = errorString?.message;
        }
        if (!errorString) return null;

        return (
          <MErrorMessage className={errorClass} key={errorString}>
            {errorString}
          </MErrorMessage>
        );
      })
      .filter(Boolean);
  }, [errors]);

  return {
    loader,
    errorMessage,
  };
}
