import React from 'react';

import { PenGroupShortInfo } from '~/~legacy/types/entities';
import { EditorHoC } from '~/~legacy/value-editors/EditorHoC';
import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { PenGroupSelectorEdit } from './PenGroupSelectorEdit';
import { PenGroupSelectorView } from './PenGroupSelectorView';

interface Props extends ValueEditorArgs<PenGroupShortInfo> {
  rawValue?: string;
}

export const PenGroupSelector: React.FC<Props> = props => {
  const Component = EditorHoC(PenGroupSelectorView, PenGroupSelectorEdit);

  return <Component {...props} />;
};
