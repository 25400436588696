import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetReproductionTargetsMutationVariables = Types.Exact<{
  farmID: Types.Scalars['ID']['input'];
  input: Types.SetReproductionTargetsInput;
}>;


export type SetReproductionTargetsMutation = { __typename?: 'Mutation', setReproductionTargets?: any | null };


export const SetReproductionTargetsDocument = gql`
    mutation setReproductionTargets($farmID: ID!, $input: SetReproductionTargetsInput!) {
  setReproductionTargets(farmID: $farmID, input: $input)
}
    `;
export type SetReproductionTargetsMutationFn = Apollo.MutationFunction<SetReproductionTargetsMutation, SetReproductionTargetsMutationVariables>;

/**
 * __useSetReproductionTargetsMutation__
 *
 * To run a mutation, you first call `useSetReproductionTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReproductionTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReproductionTargetsMutation, { data, loading, error }] = useSetReproductionTargetsMutation({
 *   variables: {
 *      farmID: // value for 'farmID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReproductionTargetsMutation(baseOptions?: Apollo.MutationHookOptions<SetReproductionTargetsMutation, SetReproductionTargetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReproductionTargetsMutation, SetReproductionTargetsMutationVariables>(SetReproductionTargetsDocument, options);
      }
export type SetReproductionTargetsMutationHookResult = ReturnType<typeof useSetReproductionTargetsMutation>;
export type SetReproductionTargetsMutationResult = Apollo.MutationResult<SetReproductionTargetsMutation>;
export type SetReproductionTargetsMutationOptions = Apollo.BaseMutationOptions<SetReproductionTargetsMutation, SetReproductionTargetsMutationVariables>;