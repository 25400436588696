import { ReproductionCrDateStep } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { REPRODUCTION_CR_DATE_STEP_DICT } from '../../constants';

const useReproductionCrDateStepSelect = makeUseEnumSelect(
  ReproductionCrDateStep,
  enumValue =>
    REPRODUCTION_CR_DATE_STEP_DICT[enumValue as ReproductionCrDateStep]
);

export const ReproductionCrDateStepSelect = makeSelectComponentFromHook(
  useReproductionCrDateStepSelect
);
