import { TestMilkingUploadResolveConflictsStrategy } from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

interface Props {
  /**
   * Called, when the form is submitted
   */
  onSubmit: (form: ResolveTestMilkingConflictsFormType) => void;
}

const FORM_ID = 'ResolveTestMilkingConflictsForm';

export const RESOLVE_TEST_MILKING_CONFLICTS_FORM_SCHEMA = yup.object({
  resolveConflictsStrategy: oneOfEnum(TestMilkingUploadResolveConflictsStrategy)
    .default(TestMilkingUploadResolveConflictsStrategy.Sum)
    .required(),
});

/**
 * Form for selecting test milking uploads conflicts resolve strategy
 */
export type ResolveTestMilkingConflictsFormType = InferValidatedSchema<
  typeof RESOLVE_TEST_MILKING_CONFLICTS_FORM_SCHEMA
>;

export const useResolveTestMilkingConflictsForm = ({
  onSubmit: handleSubmit,
}: Props): UseModalStepFormInterface<ResolveTestMilkingConflictsFormType> => {
  const formContext = useForm<ResolveTestMilkingConflictsFormType>({
    schema: RESOLVE_TEST_MILKING_CONFLICTS_FORM_SCHEMA,
    defaultValues: RESOLVE_TEST_MILKING_CONFLICTS_FORM_SCHEMA.getDefault(),
  });

  return {
    formId: FORM_ID,
    formContext,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
