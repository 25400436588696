import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportChartSettingsFragmentDoc } from '../../../../entities/customReports/gql/fragments/customReportChartSettings.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomReportChartSettingsMutationVariables = Types.Exact<{
  input: Types.CreateCustomReportChartSettingsInput;
}>;


export type CreateCustomReportChartSettingsMutation = { __typename?: 'Mutation', createCustomReportChartSettings: { __typename: 'CustomReportChartSettings', id: string, name: string, kind: Types.CustomReportChartKindEnum, xAxis?: { __typename: 'CustomReportChartValueAxis', valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } } } | { __typename: 'CustomReportChartFieldAxis', blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } } | null, yAxes: Array<{ __typename: 'CustomReportChartAggValueAxis', aggFormula?: Types.CustomReportChartAggFormulaKindEnum | null, withRightScale: boolean, valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string, returnValueKind: Types.ValueKindEnum } } } | { __typename: 'CustomReportChartFieldValueAxis', aggFormula?: Types.CustomReportChartAggFormulaKindEnum | null, withRightScale: boolean, blueprintSourceFieldValue: { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } }> } };


export const CreateCustomReportChartSettingsDocument = gql`
    mutation createCustomReportChartSettings($input: CreateCustomReportChartSettingsInput!) {
  createCustomReportChartSettings(input: $input) {
    ...CustomReportChartSettings
  }
}
    ${CustomReportChartSettingsFragmentDoc}`;
export type CreateCustomReportChartSettingsMutationFn = Apollo.MutationFunction<CreateCustomReportChartSettingsMutation, CreateCustomReportChartSettingsMutationVariables>;

/**
 * __useCreateCustomReportChartSettingsMutation__
 *
 * To run a mutation, you first call `useCreateCustomReportChartSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomReportChartSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomReportChartSettingsMutation, { data, loading, error }] = useCreateCustomReportChartSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomReportChartSettingsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomReportChartSettingsMutation, CreateCustomReportChartSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomReportChartSettingsMutation, CreateCustomReportChartSettingsMutationVariables>(CreateCustomReportChartSettingsDocument, options);
      }
export type CreateCustomReportChartSettingsMutationHookResult = ReturnType<typeof useCreateCustomReportChartSettingsMutation>;
export type CreateCustomReportChartSettingsMutationResult = Apollo.MutationResult<CreateCustomReportChartSettingsMutation>;
export type CreateCustomReportChartSettingsMutationOptions = Apollo.BaseMutationOptions<CreateCustomReportChartSettingsMutation, CreateCustomReportChartSettingsMutationVariables>;