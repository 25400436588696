import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadDcImportArchiveMutationVariables = Types.Exact<{
  fileInput: Types.DcImportFileInput;
}>;


export type LoadDcImportArchiveMutation = { __typename?: 'Mutation', loadDCImportArchive?: any | null };


export const LoadDcImportArchiveDocument = gql`
    mutation loadDCImportArchive($fileInput: DCImportFileInput!) {
  loadDCImportArchive(fileInput: $fileInput)
}
    `;
export type LoadDcImportArchiveMutationFn = Apollo.MutationFunction<LoadDcImportArchiveMutation, LoadDcImportArchiveMutationVariables>;

/**
 * __useLoadDcImportArchiveMutation__
 *
 * To run a mutation, you first call `useLoadDcImportArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadDcImportArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadDcImportArchiveMutation, { data, loading, error }] = useLoadDcImportArchiveMutation({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *   },
 * });
 */
export function useLoadDcImportArchiveMutation(baseOptions?: Apollo.MutationHookOptions<LoadDcImportArchiveMutation, LoadDcImportArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadDcImportArchiveMutation, LoadDcImportArchiveMutationVariables>(LoadDcImportArchiveDocument, options);
      }
export type LoadDcImportArchiveMutationHookResult = ReturnType<typeof useLoadDcImportArchiveMutation>;
export type LoadDcImportArchiveMutationResult = Apollo.MutationResult<LoadDcImportArchiveMutation>;
export type LoadDcImportArchiveMutationOptions = Apollo.BaseMutationOptions<LoadDcImportArchiveMutation, LoadDcImportArchiveMutationVariables>;