import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { ReactComponent as TimesSVG } from '~/~legacy/icons/times.svg';

import styles from './index.module.scss';

interface Props {
  title?: ReactNode;
  style?: React.CSSProperties | undefined;
  className?: string;
  hideDelete?: boolean;
  delete: () => void;
}

export const NodeHeader: React.FC<Props> = ({
  delete: delCallback,
  title,
  style,
  className,
  hideDelete,
}) => {
  const delIcon = hideDelete ? null : (
    <div onClick={delCallback} className={styles.action}>
      <TimesSVG />
    </div>
  );

  return (
    <div style={style} className={clsx(styles.root, className)}>
      {title}
      {delIcon}
    </div>
  );
};
