import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ReproductionMetricFragment = { __typename: 'ReproductionMetric', targetPercent?: number | null, valuePercent?: number | null, deltaPercent?: number | null, deviationThresholdPercent?: number | null, calculatedOn: string };

export const ReproductionMetricFragmentDoc = gql`
    fragment ReproductionMetric on ReproductionMetric {
  __typename
  targetPercent
  valuePercent
  deltaPercent
  deviationThresholdPercent
  calculatedOn
}
    `;