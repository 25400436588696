import * as yup from 'yup';

/**
 * User form schema
 */
export const USER_SCHEMA = yup.object({
  id: yup.string().default('').required(), // ID! -- used for tempId also
  firstName: yup.string().default(''),
  lastName: yup.string().default(''),
  middleName: yup.string().default(''),
  email: yup.string().email().required().default(''),
  blueprintRoleIDs: yup.array(yup.string().default('')).default([]), // [ID]
  farmIDs: yup.array(yup.string().default('').required()).default([]), // [ID!]
});
