import { CustomMilkingReportDataSource } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_DATA_SOURCES_DICT } from '../../../../constants';

const useCustomMilkingReportDataSourceSelect = makeUseEnumSelect(
  CustomMilkingReportDataSource,
  enumValue =>
    CUSTOM_MILKING_REPORT_DATA_SOURCES_DICT[
      enumValue as CustomMilkingReportDataSource
    ]
);

export const CustomMilkingReportDataSourceSelect = makeSelectComponentFromHook(
  useCustomMilkingReportDataSourceSelect
);
