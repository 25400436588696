import React from 'react';

import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import panelStyles from '~/styles/modules/panel.module.scss';

import { isSomaticCellsReportDataEmpty } from '../../helpers';
import { SomaticCellsReportDataType } from '../../types';
import { SomaticCellsReportChart, SomaticCellsReportTable } from './components';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Chart data to render
   */
  reportData: SomaticCellsReportDataType | SkeletonPlaceholder;
}

export const SomaticCellsReportData: React.FC<Props> = React.memo(
  ({ className, reportData }) => {
    return (
      <div className={clsx('flex flex-col gap-24', className)}>
        {isSomaticCellsReportDataEmpty(reportData) && (
          <div className={clsx(panelStyles.borderedPanel, 'p-24', className)}>
            <DataBlockedMessage
              {...{
                className: 'p-24',
                message: 'Нет данных для отображения отчёта',
              }}
            />
          </div>
        )}

        {!isSomaticCellsReportDataEmpty(reportData) && (
          <>
            <SomaticCellsReportChart reportData={reportData} />
            <SomaticCellsReportTable reportData={reportData} />
          </>
        )}
      </div>
    );
  }
);
