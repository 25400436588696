import React from 'react';

import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { DateFormats, formatDate } from '~/services/dateTime';

interface Props<T> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * String time of the fetched report
   */
  createdAt?: string;
  /**
   * Callback to refetch the report
   */
  refetch: () => Promise<T>;
}

export const UpdateReportButton = <T,>({
  className,
  createdAt,
  refetch,
}: Props<T>) => {
  const [isLoading, setLoading] = React.useState(false);

  return (
    <div className={clsx(className, 'flex items-center gap-16')}>
      <Typography className="text-soft" variant={TypographyVariants.bodySmall}>
        Составлен по данным от&nbsp;
        {formatDate(createdAt, DateFormats.shortWithTime)}
      </Typography>
      <Button
        {...{
          iconVariant: IconVariants.update,
          variant: ButtonVariants.secondary,
          children: 'Обновить отчёт',
          isLoading,
          onPress: () => {
            setLoading(true);
            refetch().finally(() => {
              setLoading(false);
            });
          },
        }}
      />
    </div>
  );
};
