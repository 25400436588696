import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApiFeedbackProps,
  useApiFeedback,
} from '~/~legacy/hooks/useApiFeedback';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { Button } from '~/shared/components/Button';
import { MDropDown } from '~/shared/components/MDropDown';
import { MenuItem, MMenu } from '~/shared/components/MMenu';
import { LogicOperator } from '~/shared/graphql';

import TOKENS from '~/styles/__generated__/tokens.json';

import { getEditElement } from '../../../../helpers/getEditElement';
import {
  BlueprintActionNode,
  BlueprintFilterGroupNode,
  BlueprintNodeType,
} from '../../../../services';
import { ActionNode } from '../../../ActionNode';
import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';
import { FilterGroupNode } from '../..';
import { FilterConditionNode } from '../FilterConditionNode';
import styles from './index.module.scss';

interface FilterGroupNodeUIState {
  opened: boolean;
  addBlockType: BlueprintNodeType;
}

interface Props extends ApiFeedbackProps {
  filterGroup: BlueprintFilterGroupNode;
  topLevel?: boolean;

  nestedFilterGroups: BlueprintFilterGroupNode[];
  nestedActions: BlueprintActionNode[];

  delete: () => void;
  update: (filterGroup: BlueprintFilterGroupNode) => void;
}

export const FilterGroupNodeUI: React.FC<Props> = ({
  filterGroup,
  topLevel,
  delete: deleteFunc,
  update,
  errors,
  loading,
  nestedActions,
  nestedFilterGroups,
}) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const [state, setState] = useState<FilterGroupNodeUIState>({
    opened: false,
    addBlockType: BlueprintNodeType.None,
  });

  const toggleMenu = useCallback(() => {
    setState(prev => {
      return {
        ...prev,
        opened: !prev.opened,
      };
    });
  }, [setState]);

  const cancelCallback = useCallback(() => {
    setState(prev => {
      return {
        ...prev,
        opened: false,
        addBlockType: BlueprintNodeType.None,
      };
    });
  }, [setState]);

  const newBlock = getEditElement(
    state.addBlockType,
    cancelCallback,
    filterGroup.id
  );

  const conditions: ReactElement[] = filterGroup.filters.map(
    filterCondition => (
      <FilterConditionNode
        filterCondition={filterCondition}
        key={filterCondition.id}
      />
    )
  );
  const filterGroups: ReactElement[] = nestedFilterGroups.map(nestedGroup => (
    <FilterGroupNode
      filterGroup={nestedGroup}
      topLevel={false}
      key={nestedGroup.id}
    />
  ));
  const actions: ReactElement[] = nestedActions.map(nestedAction => (
    <ActionNode action={nestedAction} key={nestedAction.id} />
  ));

  const newBlockSectionMap = {
    [BlueprintNodeType.FilterCondition]: () => {
      conditions.push(newBlock);
    },
    [BlueprintNodeType.FilterGroup]: () => {
      filterGroups.push(newBlock);
    },
    [BlueprintNodeType.Action]: () => {
      actions.push(newBlock);
    },
  } as Record<BlueprintNodeType, () => void>;

  const emptyAction = () => {};
  const placeNewBlockToSection =
    newBlockSectionMap[state.addBlockType] || emptyAction;
  placeNewBlockToSection();

  const addNewBlock = useCallback(
    (tpye: BlueprintNodeType) => {
      setState(prev => {
        return {
          ...prev,
          addBlockType: tpye,
          opened: false,
        };
      });
    },
    [setState]
  );

  const logicOperationChange = useCallback((logicalOperator: LogicOperator) => {
    update({
      ...filterGroup,
      logicalOperator,
    });
  }, []);

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        content: t(IntegratorStrings.blueprintEdit.nodes.filterNode.addFilter),
        action: () => {
          addNewBlock(BlueprintNodeType.FilterCondition);
        },
      },
      {
        content: t(
          IntegratorStrings.blueprintEdit.nodes.filterNode.addFilterGroup
        ),
        action: () => {
          addNewBlock(BlueprintNodeType.FilterGroup);
        },
      },
    ];

    if (topLevel) {
      items.push({
        content: t(IntegratorStrings.blueprintEdit.nodes.filterNode.addAction),
        action: () => {
          addNewBlock(BlueprintNodeType.Action);
        },
      });
    }

    return items;
  }, [topLevel, addNewBlock, t]);

  const menu = state.opened ? (
    <MMenu className="inline-block" items={menuItems} />
  ) : null;

  return (
    <NodeFrame
      header={
        <NodeHeader
          title={t(IntegratorStrings.blueprintEdit.nodes.filterNode.title)}
          delete={deleteFunc}
        />
      }
      headerStyle={{
        background: TOKENS.colorStatus04_150,
      }}
      contentClass={styles.content}
    >
      <div className={styles.operator}>
        <MDropDown
          name="logicalOperator"
          noReset
          items={[
            {
              value: LogicOperator.And,
              content: LogicOperator.And,
            },
            {
              value: LogicOperator.Or,
              content: LogicOperator.Or,
            },
          ]}
          onChange={logicOperationChange}
          selectedValue={filterGroup.logicalOperator}
        />
      </div>
      <div className={styles.children}>
        {conditions}
        {filterGroups}
        {actions}

        {errorMessage}
        {loader}

        {menu}

        <Button className="mt-8 block" onPress={toggleMenu}>
          {t(IntegratorStrings.blueprintEdit.nodes.filterNode.addBlockButton)}
        </Button>
      </div>
    </NodeFrame>
  );
};
