import { CustomMilkingReportXAxisStep } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

import { CUSTOM_MILKING_REPORT_X_AXIS_STEPS_DICT } from '../../../../constants';

const useCustomMilkingReportXAxisStepSelect = makeUseEnumSelect(
  CustomMilkingReportXAxisStep,
  enumValue =>
    CUSTOM_MILKING_REPORT_X_AXIS_STEPS_DICT[
      enumValue as CustomMilkingReportXAxisStep
    ]
);

export const CustomMilkingReportXAxisStepSelect = makeSelectComponentFromHook(
  useCustomMilkingReportXAxisStepSelect
);
