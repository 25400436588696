import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type MilkingParlorGeneralReportRowEntryFragment = { __typename: 'MilkingParlorGeneralReportRowEntry', notIdentifiedCount: number, penGroup: { __typename: 'PenGroup', id: string, name: string, identifier: number }, cows: { __typename?: 'MlkParlorGeneralReportCows', total: number, perHour: number }, milk: { __typename?: 'MlkParlorGeneralReportMilk', total: number, perHour: number, perCows: number, avgFlow: number }, milkingTime: { __typename?: 'MlkParlorGeneralReportMilkingTime', durationSec: number, avgSec: number, start: string, stop: string } };

export const MilkingParlorGeneralReportRowEntryFragmentDoc = gql`
    fragment MilkingParlorGeneralReportRowEntry on MilkingParlorGeneralReportRowEntry {
  __typename
  penGroup {
    ...PenGroupShort
  }
  cows {
    total
    perHour
  }
  milk {
    total
    perHour
    perCows
    avgFlow
  }
  milkingTime {
    durationSec
    avgSec
    start
    stop
  }
  notIdentifiedCount
}
    ${PenGroupShortFragmentDoc}`;