import { ModalRegistration } from '~/services/modals';

import {
  DownloadCowEventsCsvModal,
  DownloadCowEventsCsvModalProps,
} from './index';

export const registration: ModalRegistration<DownloadCowEventsCsvModalProps> = {
  Component: DownloadCowEventsCsvModal,
};
