import React, { useCallback, useState } from 'react';

import { ControlWrapper } from '~/~legacy/value-editors/components/ControlWrapper';
import { StringEditor } from '~/~legacy/value-editors/editors/StringEditor';
import { useControls } from '~/~legacy/value-editors/useControls';
import { useEditLogic } from '~/~legacy/value-editors/useEditLogic';

interface Props {
  name: string;
  update: (name?: string) => Promise<boolean>;
}

export const FieldNodeName: React.FC<Props> = ({ name, update }) => {
  const [isEditingInner, setEditingInner] = useState(false);

  const toggleCallback = useCallback(() => {
    setEditingInner(val => !val);
  }, []);

  const { val, setVal, formSubmitCallback } = useEditLogic(
    name,
    () => Promise.resolve(true),
    update,
    toggleCallback
  );

  const controls = useControls(false, toggleCallback);

  return (
    <div className="m-8">
      <ControlWrapper isForm onSubmit={formSubmitCallback}>
        <StringEditor
          {...{
            name: 'name',
            value: val,
            onChange: newVal => {
              setVal(newVal);
              return Promise.resolve(true);
            },
            caption: 'Name',
            edit: isEditingInner,
            cancel: () => {
              toggleCallback();
            },
          }}
        />
        {isEditingInner && controls}
      </ControlWrapper>
    </div>
  );
};
