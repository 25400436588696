import React from 'react';

export const ArrowDownRightTurn = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M10.9988 15.15C7.01359 15.1494 3.75 11.8854 3.75 7.9L3.75 3C3.75 2.58579 3.41421 2.25 3 2.25C2.58579 2.25 2.25 2.58579 2.25 3V7.9C2.25 12.7142 6.18579 16.65 11 16.65H19.1892L15.3696 20.4697C15.0767 20.7626 15.0767 21.2374 15.3696 21.5303C15.6625 21.8232 16.1373 21.8232 16.4302 21.5303L21.5223 16.4382C21.8138 16.1555 21.8217 15.6667 21.5355 15.3748L16.4355 10.1748C16.1454 9.87912 15.6706 9.87451 15.3748 10.1645C15.0791 10.4546 15.0745 10.9294 15.3645 11.2252L19.2139 15.15H10.9988Z"
      fill="currentColor"
    />
  </svg>
);
