import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../fragments/farm.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FarmsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FarmsQuery = { __typename?: 'Query', farms: { __typename?: 'FarmConnection', pageInfo: { __typename?: 'BeriaPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'FarmEdge', cursor: string, node: { __typename: 'Farm', id: string, name: string, number: string } }> } };


export const FarmsDocument = gql`
    query farms {
  farms {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Farm
      }
    }
  }
}
    ${FarmFragmentDoc}`;

/**
 * __useFarmsQuery__
 *
 * To run a query within a React component, call `useFarmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFarmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFarmsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFarmsQuery(baseOptions?: Apollo.QueryHookOptions<FarmsQuery, FarmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FarmsQuery, FarmsQueryVariables>(FarmsDocument, options);
      }
export function useFarmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FarmsQuery, FarmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FarmsQuery, FarmsQueryVariables>(FarmsDocument, options);
        }
export function useFarmsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FarmsQuery, FarmsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FarmsQuery, FarmsQueryVariables>(FarmsDocument, options);
        }
export type FarmsQueryHookResult = ReturnType<typeof useFarmsQuery>;
export type FarmsLazyQueryHookResult = ReturnType<typeof useFarmsLazyQuery>;
export type FarmsSuspenseQueryHookResult = ReturnType<typeof useFarmsSuspenseQuery>;
export type FarmsQueryResult = Apollo.QueryResult<FarmsQuery, FarmsQueryVariables>;