import React from 'react';

export const ArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 2.25C11.7966 2.25 11.6135 2.32586 11.4697 2.46967L6.46967 7.46967C6.17678 7.76256 6.17678 8.23744 6.46967 8.53033C6.76256 8.82322 7.23744 8.82322 7.53033 8.53033L11.25 4.81066V21C11.25 21.4142 11.5858 21.75 12 21.75C12.4142 21.75 12.75 21.4142 12.75 21V4.81066L16.4697 8.53033C16.7626 8.82322 17.2374 8.82322 17.5303 8.53033C17.8232 8.23744 17.8232 7.76256 17.5303 7.46967L12.5303 2.46967C12.3865 2.32586 12.2034 2.25 12 2.25Z"
      fill="currentColor"
    />
  </svg>
);
