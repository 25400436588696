import React, { ReactNode, useRef } from 'react';

import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Skeleton, useSkeletonContext } from '~/shared/components/Skeleton';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import { SizeVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

import styles from './index.module.scss';

interface Props extends BaseFieldProps<boolean> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Additional props for the root label component
   */
  htmlProps?: React.ComponentProps<'label'>;
  /**
   * Size of the checkbox
   */
  size?: SizeVariants;
  /**
   * If passed, checkbox has custom color
   */
  color?: string;
  /**
   * If passed, checkbox has custom hover color (default to color)
   */
  hoverColor?: string;
  /**
   * If passed, checkbox has custom pressed color (default to hoverColor)
   */
  pressedColor?: string;
  /**
   * If passed, renders a tooltip for the disabled state of the checkbox
   */
  disabledTooltip?: ReactNode;
}

const SKELETON_DOT_SIZE_PX = 12;

const CheckboxInner = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      name,

      value,
      defaultValue = false,
      onValueChange,

      label,

      isDisabled = false,

      size = SizeVariants.size20,
      color,
      hoverColor = color,
      pressedColor = hoverColor,

      disabledTooltip,

      htmlProps,

      ...other
    }: Props,
    forwardedRef
  ) => {
    const { renderWithSkeleton, getSkeletonClassNames } = useSkeletonContext();

    const innerRef = useRef<HTMLInputElement>(null);

    const [isSelected, setSelected] = useControllableState(
      value,
      newValue => onValueChange?.(!!newValue),
      defaultValue
    );

    const { inputProps, isPressed, labelProps } = useCheckbox(
      {
        name,
        'aria-label': name,
        isDisabled,

        ...other,
      },
      {
        isSelected,
        setSelected,
        toggle: () => {
          setSelected(state => !state);
        },
      },
      innerRef
    );

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
      <Tooltip
        {...{
          content: disabledTooltip,
          isDisabled: !isDisabled,
          withPointerCursor: false,
        }}
      >
        {setPositionReference => (
          <label
            {...{
              htmlFor: inputProps.id,
              className: clsx(
                className,
                styles.root,
                !!color && styles.colored,
                {
                  [styles.selected]: isSelected,
                  [styles.focused]: isFocusVisible,
                  [styles.pressed]: isPressed,
                  [styles.disabled]: isDisabled,
                },
                getSkeletonClassNames('cursor-default')
              ),
              ...mergeProps(labelProps, htmlProps),
              style: {
                '--checkbox-color-default': color,
                '--checkbox-color-hover': hoverColor,
                '--checkbox-color-pressed': pressedColor,
                '--checkbox-size': TOKENS[size],
              } as React.CSSProperties,
            }}
          >
            <VisuallyHidden>
              <input
                {...{
                  ...mergeProps(inputProps, focusProps),
                  // useCheckbox ignores the tabIndex prop
                  tabIndex: other.tabIndex,
                  ref: mergeRefs(forwardedRef, innerRef),
                }}
              />
            </VisuallyHidden>
            {renderWithSkeleton(
              <Skeleton.Block
                {...{
                  isRound: true,
                  size: SKELETON_DOT_SIZE_PX,
                }}
              />,
              <div className={styles.border} ref={setPositionReference}>
                <Icon
                  {...{
                    variant: IconVariants.check,
                    className: styles.icon,
                    size,
                  }}
                />
              </div>
            )}

            {!!label && (
              <Typography variant={TypographyVariants.descriptionLarge}>
                {label}
              </Typography>
            )}
          </label>
        )}
      </Tooltip>
    );
  }
);

export const Checkbox = withOptionalFormController<Props, boolean>({
  defaultValue: false,
})(CheckboxInner);
