import { useCallback } from 'react';

const SUBMIT_KEYS: string[] = ['Enter', ' '];

export function useSvgKeyPress<T = SVGElement>(action: () => void) {
  const pressCallback = useCallback(
    (e: React.KeyboardEvent<T>) => {
      const allowedKeys = SUBMIT_KEYS;
      if (allowedKeys.find(x => x === e.key)) {
        action();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [action]
  );

  return pressCallback;
}
