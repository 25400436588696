import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from './cowShort.graphql';
export type GenealogyCowFragment = { __typename: 'GenealogyCow', relationKind: Types.GenealogyRelationKind, registrationNumber: string, name: string, breed: string, dateOfBirth?: string | null, weightKilograms?: number | null, overallMilkWeightKilograms?: number | null, overallMilkFatPercent?: number | null, overallMilkProteinPercent?: number | null, bestLactationNumber?: number | null, bestLactationMilkWeightKilograms?: number | null, bestLactationMilkFatPercent?: number | null, bestLactationMilkProteinPercent?: number | null, animal?: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } | { __typename?: 'Bull' } | null };

export const GenealogyCowFragmentDoc = gql`
    fragment GenealogyCow on GenealogyCow {
  __typename
  relationKind
  registrationNumber
  name
  breed
  dateOfBirth
  weightKilograms
  overallMilkWeightKilograms
  overallMilkFatPercent
  overallMilkProteinPercent
  bestLactationNumber
  bestLactationMilkWeightKilograms
  bestLactationMilkFatPercent
  bestLactationMilkProteinPercent
  animal {
    ...CowShort
  }
}
    ${CowShortFragmentDoc}`;