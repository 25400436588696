import {
  AppBlueprint,
  AppBlueprintsModel,
  BlueprintsService,
  TemplateResponse,
} from '~/~legacy/services/BlueprintsService';

import { API_URL } from '~/shared/constants';
import {
  CreateBlueprintInput,
  CreateBlueprintMutation,
  CreateBlueprintMutationVariables,
  DeleteBlueprintMutation,
  DeleteBlueprintMutationVariables,
  GetBlueprintByIdQuery,
  GetBlueprintsQuery,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  RunType,
  UpdateBlueprintMutation,
  UpdateBlueprintMutationVariables,
} from '~/shared/graphql';

import {
  CreateBlueprintGQL,
  DeleteBlueprintGQL,
  GetBlueprintByIdGQL,
  GetBlueprintsListGQL,
  GetTemplatesGQL,
  UpdateBlueprintGQL,
} from '~/services/gql/queries/blueprints.gql';

import { BlueprintsResponseConverter } from './BlueprintsResponseConverter';
import { StandardService } from './StandardService';

export class AppBlueprintsService
  extends StandardService
  implements BlueprintsService
{
  private readonly converter: BlueprintsResponseConverter =
    new BlueprintsResponseConverter();

  async getBlueprints(): Promise<AppBlueprintsModel> {
    const response = await this.http.gql<GetBlueprintsQuery>(API_URL, {
      query: GetBlueprintsListGQL,
    });

    return this.converter.convertFromBlueprintsResponse(response);
  }

  async getBlueprintById(id: string): Promise<AppBlueprint> {
    const response = await this.http.gql<GetBlueprintByIdQuery>(API_URL, {
      query: GetBlueprintByIdGQL,
      variables: {
        id,
      },
    });

    return this.converter.convertFromGetBlueprintByIdResponse(response, id);
  }

  async create(input: CreateBlueprintInput): Promise<AppBlueprint> {
    const incidentSettings = {
      runType: RunType.Incident,
    };

    const runSettings =
      input.runSettings.runType === RunType.Incident
        ? incidentSettings
        : input.runSettings;

    const processedInput: CreateBlueprintInput = {
      ...input,
      runSettings,
    };

    const response = await this.http.gql<
      CreateBlueprintMutation,
      CreateBlueprintMutationVariables
    >(API_URL, {
      query: CreateBlueprintGQL,
      variables: { input: processedInput },
    });

    return this.converter.convertFromCreateBlueprintResponse(response);
  }

  async update(request: UpdateBlueprintMutationVariables): Promise<void> {
    await this.http.gql<
      UpdateBlueprintMutation,
      UpdateBlueprintMutationVariables
    >(API_URL, {
      query: UpdateBlueprintGQL,
      variables: {
        ...request,
      },
    });
  }

  async delete(id: string): Promise<void> {
    await this.http.gql<
      DeleteBlueprintMutation,
      DeleteBlueprintMutationVariables
    >(API_URL, {
      query: DeleteBlueprintGQL,
      variables: {
        id,
      },
    });
  }

  async getTemplates(
    params: GetTemplatesQueryVariables,
    abortSignal?: AbortSignal
  ): Promise<TemplateResponse> {
    const response = await this.http.gql<
      GetTemplatesQuery,
      GetTemplatesQueryVariables
    >(
      API_URL,
      {
        query: GetTemplatesGQL,
        variables: {
          ...params,
        },
      },
      {
        signal: abortSignal,
      }
    );

    const result = this.converter.convertFromTemplatesResponse(response);

    return result;
  }
}
