import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintActionNode,
  BlueprintEditService,
  BlueprintNodeType,
} from '../../services';
import { ActionNodeUI } from './components/ActionNodeUI';

interface Props {
  action: BlueprintActionNode;
}

export const ActionNode: React.FC<Props> = ({ action }) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const memoizedDel = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc.deleteNode]);

  const {
    errors: delErorrs,
    loading: delLoading,
    reload: delFunc,
  } = useApiData(memoizedDel);

  const deleteCallback = useCallback(async () => {
    await delFunc({
      nodeId: action.id || '',
      nodeType: BlueprintNodeType.Action,
    });
  }, [delFunc]);

  return (
    <ActionNodeUI
      action={action}
      delete={deleteCallback}
      errors={delErorrs}
      loading={delLoading}
    />
  );
};
