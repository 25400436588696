import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowShortFragmentDoc } from '../../../../entities/cows/gql/fragments/cowShort.graphql';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type MilkingParlorScheduleReportStallEntryFragment = { __typename: 'MilkingParlorScheduleReportStallEntry', stallNumber: number, milkings: Array<{ __typename?: 'ParlorStallEntryMilkingType', happenedAt: string, durationSec: number, endedAt: string, weightKilograms: number, deviationWeightKilograms: number, flowRate: number, cow?: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: number } | null, mistakes: Array<{ __typename?: 'ParlorStallEntryMistakeType', kind: Types.MilkingMistakeKindsEnum, happenedAt: string, durationSec: number, endedAt: string }> }> };

export const MilkingParlorScheduleReportStallEntryFragmentDoc = gql`
    fragment MilkingParlorScheduleReportStallEntry on MilkingParlorScheduleReportStallEntry {
  __typename
  stallNumber
  milkings {
    happenedAt
    durationSec
    endedAt
    weightKilograms
    deviationWeightKilograms
    flowRate
    cow {
      ...CowShort
    }
    penGroup {
      ...PenGroupShort
    }
    mistakes {
      kind
      happenedAt
      durationSec
      endedAt
    }
  }
}
    ${CowShortFragmentDoc}
${PenGroupShortFragmentDoc}`;