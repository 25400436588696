import { ChartDataset } from 'chart.js';

import NUMBER_TOKENS from '~/styles/__generated__/number-tokens.json';

export const THICK_LINE_BORDER_WIDTH_PX = NUMBER_TOKENS.borderWidth2;
export const EVENT_BUBBLE_RADIUS_PX = NUMBER_TOKENS.size8;

const LINE_DATASET_PROPS = {
  type: 'line',
  yAxisID: 'rightScale',
  pointRadius: 0,
  pointStyle: false,
} satisfies Partial<ChartDataset>;

/**
 * Solid line dataset basic props
 */
export const BASIC_LINE_DATASET_PROPS = {
  ...LINE_DATASET_PROPS,
  borderWidth: 1,
} satisfies Partial<ChartDataset>;

/**
 * Dashed line dataset basic props
 */
export const DASHED_LINE_DATASET_PROPS = {
  ...LINE_DATASET_PROPS,
  borderWidth: THICK_LINE_BORDER_WIDTH_PX,
  borderDash: [2, 3],
} satisfies Partial<ChartDataset>;
