import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalculateMonitorMutationVariables = Types.Exact<{
  runOn?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type CalculateMonitorMutation = { __typename?: 'Mutation', calculateMonitor?: any | null };


export const CalculateMonitorDocument = gql`
    mutation calculateMonitor($runOn: Date) {
  calculateMonitor(runOn: $runOn)
}
    `;
export type CalculateMonitorMutationFn = Apollo.MutationFunction<CalculateMonitorMutation, CalculateMonitorMutationVariables>;

/**
 * __useCalculateMonitorMutation__
 *
 * To run a mutation, you first call `useCalculateMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateMonitorMutation, { data, loading, error }] = useCalculateMonitorMutation({
 *   variables: {
 *      runOn: // value for 'runOn'
 *   },
 * });
 */
export function useCalculateMonitorMutation(baseOptions?: Apollo.MutationHookOptions<CalculateMonitorMutation, CalculateMonitorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateMonitorMutation, CalculateMonitorMutationVariables>(CalculateMonitorDocument, options);
      }
export type CalculateMonitorMutationHookResult = ReturnType<typeof useCalculateMonitorMutation>;
export type CalculateMonitorMutationResult = Apollo.MutationResult<CalculateMonitorMutation>;
export type CalculateMonitorMutationOptions = Apollo.BaseMutationOptions<CalculateMonitorMutation, CalculateMonitorMutationVariables>;