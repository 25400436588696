import { PenGroup } from '@graphql-types';

import { HALF_SPACE, INTERPUNCT, MDASH } from '~/shared/constants';

import { getNumberPartFromGlobalId, makeReadFragment } from '~/services/gql';

import {
  PenGroupFragment,
  PenGroupFragmentDoc,
} from './gql/fragments/penGroup.graphql';
import { PenGroupFormatOptions } from './types';

/**
 * Returns normalized pen group identifier
 */
export const getPenGroupIdentifier = (
  group?: Partial<Pick<PenGroup, 'identifier' | 'id'>> | null
) => group?.identifier || getNumberPartFromGlobalId(group?.id);

/**
 * Returns pen group formatted for rendering
 */
export const formatPenGroup = (
  penGroup:
    | (Partial<Pick<PenGroup, 'identifier' | 'id'>> & { name?: string })
    | null
    | undefined,
  { prefix = '' }: PenGroupFormatOptions = {}
) => {
  if (!penGroup) return MDASH;

  const prefixWithSpace = prefix ? `${prefix} ` : '';
  return `${prefixWithSpace}№${HALF_SPACE}${getPenGroupIdentifier(
    penGroup
  )} ${INTERPUNCT} ${penGroup.name}`;
};

/**
 * Reads penGroup fragment from cache by id
 */
export const readPenGroupFragment = makeReadFragment<PenGroupFragment>({
  typeName: 'PenGroup',
  fragment: PenGroupFragmentDoc,
});
