import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { Icon, IconVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableThemes,
} from '~/shared/components/Table';
import { Typography } from '~/shared/components/Typography';
import { formatInt } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';

import {
  SizeVariants,
  TypographyVariants,
} from '~/styles/__generated__/token-variants';

import { SomaticCellsReportChartFragment } from '../../../../gql/fragments/somaticCellsReportChart.graphql';
import { DATASET_ITEM_FIELD_ITEMS } from './constants';
import {
  SomaticCellsReportCellData,
  SomaticCellsReportColumnConfig,
  SomaticCellsReportRowConfig,
} from './types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Report data to render as table
   */
  reportData: SomaticCellsReportChartFragment | SkeletonPlaceholder;
}

const TITLE_COLUMN_WIDTH_PX = 240;

export const SomaticCellsReportTable: React.FC<Props> = ({
  className,
  reportData,
}) => {
  const columnConfigs: SomaticCellsReportColumnConfig[] = [
    {
      key: 'title',
      title: 'Показатели',
      renderCellContent: R.prop('firstColumnContent'),
      width: TITLE_COLUMN_WIDTH_PX,
      isSticky: true,
    },
    ...(reportData.dates ?? []).map<SomaticCellsReportColumnConfig>(
      (date, columnIndex) => ({
        key: `${date}__${columnIndex}`,
        title: formatDate(date),
        columnClassName: 'text-right',
        renderCellContent: row => row.renderCellContent?.({ columnIndex }),
      })
    ),
    FILLER_COLUMN_CONFIG,
  ];

  const reportDatasets: SomaticCellsReportRowConfig[] =
    DATASET_ITEM_FIELD_ITEMS.map(({ itemField, title }, rowId) => {
      const shouldShowCowsCount = R.isNil(title);

      return {
        id: rowId.toString(),
        firstColumnContent: shouldShowCowsCount ? (
          <div className="flex items-center text-soft">
            <Icon
              {...{
                tooltip: 'Число животных',
                variant: IconVariants.cow,
                size: SizeVariants.size20,
              }}
            />
          </div>
        ) : (
          <Typography variant={TypographyVariants.bodySmall}>
            {title}
          </Typography>
        ),
        rowClassName: shouldShowCowsCount
          ? 'background-neutral-opaque-container-soft'
          : undefined,
        renderCellContent: ({ columnIndex }) => {
          if (isSkeletonPlaceholder(reportData)) {
            return null;
          }
          return formatInt(reportData[itemField][columnIndex]);
        },
      };
    });

  return (
    <Table<SomaticCellsReportRowConfig, SomaticCellsReportCellData>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: reportDatasets,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        getRowClassName: R.prop('rowClassName'),
        shouldHideLargeData: true,
      }}
    />
  );
};
