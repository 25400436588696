import React from 'react';

export const InfoCircleFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 12C21.75 17.4142 17.4142 21.75 12 21.75C6.58579 21.75 2.25 17.4142 2.25 12C2.25 6.58579 6.58579 2.25 12 2.25C17.4142 2.25 21.75 6.58579 21.75 12ZM12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C11.4 7 11 7.4 11 8C11 8.6 11.4 9 12 9ZM12.75 11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V11Z"
      fill="currentColor"
    />
  </svg>
);
