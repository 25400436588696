import React from 'react';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { Form, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { TestMilkingFields } from '~/entities/testMilkings';

import {
  COW_CHART_SETTINGS_SCHEMA,
  CowChartSettings,
} from '~/widgets/cowChart';

import formStyles from '~/styles/modules/form.module.scss';

const useTestMilkingFieldsSelect = makeUseEnumSelect(
  TestMilkingFields,
  EnumStrings.testMilkingFields
);

const FORM_ID = 'CowChartSettingsForm';

export interface CowChartSettingsModalProps
  extends InjectedModalProps<CowChartSettingsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Default selected settings
   */
  defaultSettings?: CowChartSettings;
  /**
   * Called, when the form is submitted
   */
  onSave?: (newSettings: CowChartSettings) => void;
}

export const CowChartSettingsModal: React.FC<CowChartSettingsModalProps> = ({
  className,
  defaultSettings,
  onSave,
  close,
}) => {
  const formContext = useForm<CowChartSettings>({
    schema: COW_CHART_SETTINGS_SCHEMA,
    defaultValues: {
      ...COW_CHART_SETTINGS_SCHEMA.getDefault(),
      ...defaultSettings,
    },
  });

  const handleSubmit = (form: CowChartSettings) => {
    onSave?.(form);
    close();
  };

  const {
    renderSelectElement: renderApproximationFractionsSelectElement,
    items: afSelectItems,
  } = useTestMilkingFieldsSelect({
    name: 'approximationFractions',
    isMulti: true,
    label: 'Аппроксимированная кривая',
    placeholder: 'Выберите характеристику',
  });

  const fractionsItemsWithoutSomaticCells = afSelectItems.filter(
    item => item.id !== TestMilkingFields.somaticCells
  );

  const { renderSelectElement: renderMilkFractionsSelectElement } =
    useTestMilkingFieldsSelect({
      name: 'milkFractions',
      isMulti: true,
      label: 'Характеристики молока',
      placeholder: 'Выберите характеристику',
    });

  const { renderSelectElement: renderTestMilkFractionsSelectElement } =
    useTestMilkingFieldsSelect({
      name: 'testMilkFractions',
      isMulti: true,
      label: 'Характеристики молока в контрольных дойках',
      placeholder: 'Выберите характеристику',
    });

  return (
    <Modal
      {...{
        className,
        title: 'Настройки лактационного графика',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Применить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          className: formStyles.singleColumnForm,
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        {renderApproximationFractionsSelectElement({
          items: fractionsItemsWithoutSomaticCells,
        })}
        {renderMilkFractionsSelectElement({
          items: fractionsItemsWithoutSomaticCells,
        })}
        {renderTestMilkFractionsSelectElement()}
      </Form>
    </Modal>
  );
};
