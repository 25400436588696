import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSemenDoseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateSemenDoseInput;
}>;


export type UpdateSemenDoseMutation = { __typename?: 'Mutation', updateSemenDose?: any | null };


export const UpdateSemenDoseDocument = gql`
    mutation updateSemenDose($id: ID!, $input: UpdateSemenDoseInput!) {
  updateSemenDose(id: $id, input: $input)
}
    `;
export type UpdateSemenDoseMutationFn = Apollo.MutationFunction<UpdateSemenDoseMutation, UpdateSemenDoseMutationVariables>;

/**
 * __useUpdateSemenDoseMutation__
 *
 * To run a mutation, you first call `useUpdateSemenDoseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSemenDoseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSemenDoseMutation, { data, loading, error }] = useUpdateSemenDoseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSemenDoseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSemenDoseMutation, UpdateSemenDoseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSemenDoseMutation, UpdateSemenDoseMutationVariables>(UpdateSemenDoseDocument, options);
      }
export type UpdateSemenDoseMutationHookResult = ReturnType<typeof useUpdateSemenDoseMutation>;
export type UpdateSemenDoseMutationResult = Apollo.MutationResult<UpdateSemenDoseMutation>;
export type UpdateSemenDoseMutationOptions = Apollo.BaseMutationOptions<UpdateSemenDoseMutation, UpdateSemenDoseMutationVariables>;