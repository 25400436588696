import { useCallback, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';

import { useNotifications } from '~/services/notifications';

export interface OperationResult<T> {
  data: T;
  success: boolean;
  messages: string[];
}

function getErrorMessagesFromResponse(
  exp: any,
  sendErrorAlert: (props: { message: string; title: string }) => void
): string[] {
  const result = [] as string[];

  const rules = [
    // graphql errors with extensions
    {
      condition: (res: any) =>
        Array.isArray(res.errors) && res.errors.find((x: any) => x.extensions),

      action: (res: any) => {
        // show first error as notification
        const error = res.errors.find((x: any) => x.extensions);

        sendErrorAlert({
          message: error.message,
          title: error.extensions.title,
        });

        // push errors in array as usual
        res.errors.forEach((err: any) => {
          // each error contains a message
          result.push(`${err.path?.join(' ') || ''} ${err.message}`);
        });
      },
    },

    // graphql validation errors
    {
      condition: (res: any) => Array.isArray(res.errors),

      action: (res: any) => {
        res.errors.forEach((err: any) => {
          // each error contains a message
          result.push(`${err.path?.join(' ') || ''} ${err.message}`);
        });
      },
    },

    // message from error
    {
      condition: (res: any) => Boolean(res.error),
      action: (res: any) => {
        result.push(res.error);
      },
    },
    // fetch or any other kind of exception
    // message from exception
    {
      condition: (res: any) => Boolean(res.message),
      action: (res: any) => {
        result.push(res.message);
      },
    },
    // message from response
    {
      condition: (res: any) => Boolean(res.detail),
      action: (res: any) => {
        result.push(res.detail);
      },
    },
    // default message
    {
      condition: () => true,
      action: () => {
        result.push('Something went wrong');
      },
    },
  ];

  // first matсh stops cycle
  const itemToExecute = rules.find(rule => rule.condition(exp));
  itemToExecute?.action(exp);

  return result;
}

/**
 *
 * @param getData - data retreving function, !!!  do not forget to bind it to the context if it uses 'this' inside
 * @param defaultData
 * @returns object with reload function and state data
 */
export function useApiData<P, T>(
  getData: (params: P, abortSignal?: AbortSignal) => Promise<T>,
  defaultData?: T
) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<typeof defaultData>(defaultData);

  const abortControllerRef = useRef(new AbortController());

  // it breaks idea of dependancy isolation and in general bad idea
  // but since it all would be replaced with apollo, keep it that way

  const { sendErrorAlert } = useNotifications();

  const cancel = useCallback(() => {
    abortControllerRef.current.abort();
  }, []);

  const reload = useCallback(
    async (params: P) => {
      try {
        abortControllerRef.current = new AbortController();
        setLoading(true);
        const newData = await getData(
          params,
          abortControllerRef.current.signal
        );
        setData(newData);
        setErrors([]);
        return {
          data: newData,
          success: true,
          messages: [],
        } as OperationResult<T>;
      } catch (exp: any) {
        const canceled = exp.code === 20;

        const errMessages = canceled
          ? []
          : getErrorMessagesFromResponse(exp, sendErrorAlert);

        setErrors(errMessages);

        Sentry.captureException(exp);

        return {
          data: exp.data || data,
          success: false,
          messages: errMessages,
        } as OperationResult<T>;
      } finally {
        setLoading(false);
      }
    },
    [getData]
  );

  return { reload, cancel, loading, errors, data };
}
