import { ReproductionCrReportChartKind } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

/**
 * Dict with possible chart kinds, used in reproduction CR
 */
const REPRODUCTION_CR_REPORT_CHART_KIND_DICT: Record<
  ReproductionCrReportChartKind,
  string
> = {
  [ReproductionCrReportChartKind.Bar]: 'Столбчатая',
  [ReproductionCrReportChartKind.Line]: 'Линейная',
};

const useReproductionCrReportChartKindSelect = makeUseEnumSelect(
  ReproductionCrReportChartKind,
  enumValue =>
    REPRODUCTION_CR_REPORT_CHART_KIND_DICT[
      enumValue as ReproductionCrReportChartKind
    ]
);

export const ReproductionCrReportChartKindSelect = makeSelectComponentFromHook(
  useReproductionCrReportChartKindSelect
);
