import React from 'react';

export const Analytics = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20 19H18C17.4 19 17 18.4 17 17.5C17 16.6 17.4 16 18 16H20C20.6 16 21 16.6 21 17.5C21 18.25 20.5 19 20 19Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13 19H11C10.4 19 10 18.5333 10 17.8333V6.16667C10 5.46667 10.4 5 11 5H13C13.6 5 14 5.46667 14 6.16667V17.8333C14 18.4167 13.5 19 13 19Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6 19H4C3.4 19 3 18.5333 3 17.8333V13.1667C3 12.4667 3.4 12 4 12H6C6.6 12 7 12.4667 7 13.1667V17.8333C7 18.4167 6.6 19 6 19Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
