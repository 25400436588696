import React, { useEffect, useState } from 'react';

import { RadioGroup, RadioGroupVariants } from '~/shared/components/RadioGroup';
import { makeUseEnumSelectItems } from '~/shared/components/Select';

import { InjectedModalProps } from '~/services/modals';

import { SelexExportModalForm } from './components/SelexExportModalForm';
import { SelexImportModalForm } from './components/SelexImportModalForm';

export interface SelexIntegrationModalProps
  extends InjectedModalProps<SelexIntegrationModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * ID of the company to setup selex integration
   */
  companyID: string;
  /**
   * Called, when an import modal starts async company update by writing to company's isLockedForWrite
   */
  onPolledCompanyUpdateStarted?: () => void;
}

enum SelexIntegrationKind {
  import = 'import',
  export = 'export',
}

const SELEX_INTEGRATION_KINDS_DICT: Record<SelexIntegrationKind, string> = {
  [SelexIntegrationKind.import]: 'Импорт',
  [SelexIntegrationKind.export]: 'Экспорт',
};

const useSelexIntegrationKindItems = makeUseEnumSelectItems(
  SelexIntegrationKind,
  enumValue => SELEX_INTEGRATION_KINDS_DICT[enumValue as SelexIntegrationKind]
);

export const SelexIntegrationModal: React.FC<SelexIntegrationModalProps> = ({
  className,
  onPolledCompanyUpdateStarted,
  ...injectedModalProps
}) => {
  const selexIntegrationKindItems = useSelexIntegrationKindItems();
  const [selectedIntegrationKind, setSelectedIntegrationKind] = useState(
    SelexIntegrationKind.import
  );

  // Avoid modal flickering, when switching RadioGroup
  const [isFirstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  const integrationKindRadioGroupElement = (
    <RadioGroup
      {...{
        name: 'selexIntegrationKind',
        className: 'mb-24',
        variant: RadioGroupVariants.segmented,
        items: selexIntegrationKindItems,
        value: selectedIntegrationKind,
        onValueChange: newValue =>
          setSelectedIntegrationKind(newValue as SelexIntegrationKind),
      }}
    />
  );

  const commonModalProps = {
    className,
    title: 'Обмен данными с Селэкс',
    children: integrationKindRadioGroupElement,
    withOpenAnimation: isFirstRender,
    ...injectedModalProps,
  };

  return selectedIntegrationKind === SelexIntegrationKind.import ? (
    <SelexImportModalForm
      {...{ ...commonModalProps, onPolledCompanyUpdateStarted }}
    />
  ) : (
    <SelexExportModalForm {...commonModalProps} />
  );
};
