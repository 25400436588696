import { EventDTO } from '~/~legacy/services/EventsService';

import {
  ArgumentValueInput,
  BlueprintLaunchInputValueInput,
  DefaultEvent,
  FilterValueInput,
  NodeValueKindEnum,
  ValueKindEnum,
} from '~/shared/graphql';

import { ValueEditorKind } from '~/widgets/blueprintEdit';

export function getInputValue(
  value: any,
  kind: ValueEditorKind,
  valueKind: NodeValueKindEnum = NodeValueKindEnum.Hardcode
): ArgumentValueInput | FilterValueInput | BlueprintLaunchInputValueInput {
  const getBoolToIntVal = (val: any): ArgumentValueInput => {
    return {
      intValue: typeof val === 'boolean' ? Number(val) : undefined,
    };
  };

  const getIntVal = (val: any): ArgumentValueInput => {
    return {
      intValue: val ? Number(val) : val,
    };
  };

  const getFloatVal = (val: any): ArgumentValueInput => {
    return {
      floatValue: val ? Number(val) : val,
    };
  };

  const getStrVal = (val: any): ArgumentValueInput => {
    return {
      strValue: val ?? '',
    };
  };

  const getDateVal = (val: any): ArgumentValueInput => {
    return {
      datetimeValue: !val ? undefined : new Date(val).toISOString(),
    };
  };

  const getIdVal = (val: any): ArgumentValueInput => {
    const idValue = val?.id || val || null;

    const matchPattern = /^[a-zA-Z]+_\d+$/.test(idValue);

    if (!matchPattern && idValue !== null) {
      throw new Error('Id сущности не заполнен или имеет неверный формат');
    }

    return {
      idValue,
    };
  };

  // types are wrong, but nothing to do with this
  const getEventVal = (val: EventDTO) => {
    return {
      strValue: val.id || (val as DefaultEvent).shortcode,
    };
  };

  const valuesMap = {
    [ValueKindEnum.Bool]: getBoolToIntVal,
    [ValueKindEnum.Date]: getDateVal,
    [ValueKindEnum.Int]: getIntVal,
    [ValueKindEnum.Float]: getFloatVal,
    [ValueKindEnum.Numeric]: getFloatVal,
    [ValueKindEnum.LactationIndex]: getIntVal,
    [ValueKindEnum.Index]: getIntVal,
    [ValueKindEnum.PenGroupId]: getIdVal,
    [ValueKindEnum.InjectionId]: getIdVal,
    [ValueKindEnum.DiseaseId]: getIdVal,
    [ValueKindEnum.ProtocolId]: getIdVal,
    [ValueKindEnum.EventId]: getEventVal,
    [ValueKindEnum.CowId]: getIdVal,
    [ValueKindEnum.CalvingId]: getIdVal,
    [ValueKindEnum.FarmId]: getIdVal,
    [ValueKindEnum.EmployeeId]: getIdVal,
    [ValueKindEnum.BullId]: getIdVal,
    [ValueKindEnum.SemenDoseBatchId]: getIdVal,
    [ValueKindEnum.InseminationSchemeId]: getIdVal,
    [ValueKindEnum.InseminationId]: getIdVal,
    [ValueKindEnum.UserEventId]: getIdVal,
  } as Record<ValueEditorKind, (val: any) => ArgumentValueInput>;

  const getter =
    valueKind === NodeValueKindEnum.Variable
      ? getIdVal
      : valuesMap[kind] || getStrVal;

  return getter(value);
}
