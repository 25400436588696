import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateAuthCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['Email']['input'];
  code: Types.Scalars['String']['input'];
}>;


export type ValidateAuthCodeMutation = { __typename?: 'Mutation', validateAuthCode: { __typename?: 'TokenResponse', refreshToken: string, accessToken: string } };


export const ValidateAuthCodeDocument = gql`
    mutation validateAuthCode($email: Email!, $code: String!) {
  validateAuthCode(email: $email, code: $code) {
    refreshToken
    accessToken
  }
}
    `;
export type ValidateAuthCodeMutationFn = Apollo.MutationFunction<ValidateAuthCodeMutation, ValidateAuthCodeMutationVariables>;

/**
 * __useValidateAuthCodeMutation__
 *
 * To run a mutation, you first call `useValidateAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAuthCodeMutation, { data, loading, error }] = useValidateAuthCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAuthCodeMutation, ValidateAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateAuthCodeMutation, ValidateAuthCodeMutationVariables>(ValidateAuthCodeDocument, options);
      }
export type ValidateAuthCodeMutationHookResult = ReturnType<typeof useValidateAuthCodeMutation>;
export type ValidateAuthCodeMutationResult = Apollo.MutationResult<ValidateAuthCodeMutation>;
export type ValidateAuthCodeMutationOptions = Apollo.BaseMutationOptions<ValidateAuthCodeMutation, ValidateAuthCodeMutationVariables>;