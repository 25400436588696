import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type InseminationSchemeFragment = { __typename: 'InseminationScheme', id: string, name: string, kind: Types.EventKindEnum, code: string, description: string };

export const InseminationSchemeFragmentDoc = gql`
    fragment InseminationScheme on InseminationScheme {
  __typename
  id
  name
  kind
  code
  description
}
    `;