export const IntegratorStrings = {
  blueprintDetails: {
    breadCrumbs: {
      new: 'blueprintDetails.breadCrumbs.new',
      existing: 'blueprintDetails.breadCrumbs.existing',
      calendar: 'blueprintDetails.breadCrumbs.calendar',
    },
    detailsForm: {
      cardNameTitle: 'blueprintDetails.etailsForm.cardNameTitle',
      cardNamePlaceholder: 'blueprintDetails.etailsForm.cardNamePlaceholder',
      cardDescription: 'blueprintDetails.etailsForm.cardDescription',
      cardPurpose: 'blueprintDetails.etailsForm.cardPurpose',
      assignee: 'blueprintDetails.etailsForm.assignee',
    },
    assignedRoleDropdown: {
      selectAssignee: 'blueprintDetails.assignedRoleDropdown.selectAssignee',
      addAnotherRole: 'blueprintDetails.assignedRoleDropdown.addAnotherRole',
      placeholderRoleName:
        'blueprintDetails.assignedRoleDropdown.placeholderRoleName',
      placeholderColor:
        'blueprintDetails.assignedRoleDropdown.placeholderColor',
    },
    runSettings: {
      title: 'blueprintDetails.runSettings.title',
      subtitle: 'blueprintDetails.runSettings.subtitle',
      incident: 'blueprintDetails.runSettings.incident',
      schedule: 'blueprintDetails.runSettings.schedule',

      day: 'blueprintDetails.runSettings.day',
      week: 'blueprintDetails.runSettings.week',
      twoWweeks: 'blueprintDetails.runSettings.twoWweeks',
      month: 'blueprintDetails.runSettings.month',
      quater: 'blueprintDetails.runSettings.quater',
      year: 'blueprintDetails.runSettings.year',
      repeatCaption: 'blueprintDetails.runSettings.repeatCaption',
      repeatWithInterval: 'blueprintDetails.runSettings.repeatWithInterval',
      startDate: 'blueprintDetails.runSettings.startDate',
      repeatDays: 'blueprintDetails.runSettings.repeatDays',

      mon: 'blueprintDetails.runSettings.mon',
      tue: 'blueprintDetails.runSettings.tue',
      wed: 'blueprintDetails.runSettings.wed',
      thu: 'blueprintDetails.runSettings.thu',
      fri: 'blueprintDetails.runSettings.fri',
      sat: 'blueprintDetails.runSettings.sat',
      sun: 'blueprintDetails.runSettings.sun',
    },
    continueButton: 'blueprintDetails.continueButton',
  },
  blueprintEdit: {
    masterViewSectionName: 'blueprintEdit.masterViewSectionName',
    headerRunCaption: 'blueprintEdit.headerRunCaption',
    headerVitalityCaption: 'blueprintEdit.headerVitalityCaption',

    vitalitySelector: {
      alive: 'blueprintEdit.vitalitySelector.alive',
      dead: 'blueprintEdit.vitalitySelector.dead',
      all: 'blueprintEdit.vitalitySelector.all',
    },

    valueKindButtons: {
      hardcode: 'blueprintEdit.valueKindButtons.hardcode',
      variable: 'blueprintEdit.valueKindButtons.variable',
    },

    nodes: {
      sectionNode: {
        title: 'blueprintEdit.nodes.sectionNode.title',
      },

      userInputNode: {
        title: 'blueprintEdit.nodes.userInputNode.title',
        variableName: 'blueprintEdit.nodes.userInputNode.variableName',
        prompt: 'blueprintEdit.nodes.userInputNode.prompt',
      },

      cycleInputNode: {
        title: 'blueprintEdit.nodes.cycleInputNode.title',
        addInputButton: 'blueprintEdit.nodes.cycleInputNode.addInputButton',

        creationError: 'blueprintEdit.nodes.cycleInputNode.creationError',
        retry: 'blueprintEdit.nodes.cycleInputNode.retry',
        cancel: 'blueprintEdit.nodes.cycleInputNode.cancel',
      },

      agumentNode: {
        required: 'blueprintEdit.nodes.agumentNode.required',
      },

      actionNode: {
        title: 'blueprintEdit.nodes.actionNode.title',
        unknownArgument: 'blueprintEdit.nodes.actionNode.unknownArgument',
      },

      filterNode: {
        title: 'blueprintEdit.nodes.filterNode.title',
        selectField: 'blueprintEdit.nodes.filterNode.selectField',
        valueToCompare: 'blueprintEdit.nodes.filterNode.valueToCompare',
        field: 'blueprintEdit.nodes.filterNode.field',
        comparisonOperator: 'blueprintEdit.nodes.filterNode.comparisonOperator',
        comparison: {
          in: 'blueprintEdit.nodes.filterNode.comparison.in',
          notIn: 'blueprintEdit.nodes.filterNode.comparison.notIn',
          isNull: 'blueprintEdit.nodes.filterNode.comparison.isNull',
          notNull: 'blueprintEdit.nodes.filterNode.comparison.notNull',
        },

        addFilter: 'blueprintEdit.nodes.filterNode.addFilter',
        addFilterGroup: 'blueprintEdit.nodes.filterNode.addFilterGroup',
        addAction: 'blueprintEdit.nodes.filterNode.addAction',
        addBlockButton: 'blueprintEdit.nodes.filterNode.addBlockButton',

        groupCreationError: 'blueprintEdit.nodes.filterNode.groupCreationError',
        retry: 'blueprintEdit.nodes.filterNode.retry',
        cancel: 'blueprintEdit.nodes.filterNode.cancel',
      },

      unionNode: {
        title: 'blueprintEdit.nodes.unionNode.title',
        titlePlaceholder: 'blueprintEdit.nodes.unionNode.titlePlaceholder',
        errorMessage: 'blueprintEdit.nodes.unionNode.errorMessage',
      },
    },

    source: {
      caption: 'blueprintEdit.source.caption',
      sectionButton: 'blueprintEdit.source.sectionButton',
      userInputButton: 'blueprintEdit.source.userInputButton',
      cycleInputButton: 'blueprintEdit.source.cycleInputButton',
      addFieldButton: 'blueprintEdit.source.addFieldButton',
    },

    view: {
      table: {
        columns: 'blueprintEdit.view.table.columns',
        masterFields: 'blueprintEdit.view.table.masterFields',
        groupBy: 'blueprintEdit.view.table.groupBy',
        toRender: 'blueprintEdit.view.table.toRender',
      },
    },

    serviceMessages: {},
  },
} as const;
