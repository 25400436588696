import { downloadFile } from '~/shared/helpers/downloadFile';

import { CowEventsQueryVariables } from '~/entities/cowEvents/gql/queries/cowEvents.graphql';

import { useExportEventsCsvMutation } from './gql/mutations/exportEventsCsv.graphql';

/**
 * Hook for reusing export events to csv logic
 */
export const useExportEventsCsv = () => {
  const [exportEventsCsvMutation, { loading: isExportCsvLoading }] =
    useExportEventsCsvMutation();

  const exportEventsCsv = (queryVariables: CowEventsQueryVariables) =>
    exportEventsCsvMutation({
      variables: queryVariables,
    }).then(({ data }) => {
      const file = data?.exportEventsCsv;

      if (file) {
        downloadFile(file.downloadUrl, file.name, file.extension);
      }
    });

  return {
    exportEventsCsv,
    isExportCsvLoading,
  };
};
