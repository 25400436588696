import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';

import { Falsy } from '~/shared/types/utility';

/**
 * Some forms have switches between different field types.
 * When using such a form, we use a pattern, where we set default fields to initial entity values.
 * With that, we can set field to some nullish value, when it's not needed to be sent to backend,
 * but if we switch it back on, we don't lose the information
 */
export const emptySwitchableFormFields = <TFieldValues extends FieldValues>(
  formContext: UseFormReturn<TFieldValues>,
  shouldSwitchFields: boolean,
  switchFieldsEmptyValues: Partial<Record<FieldPath<TFieldValues>, Falsy>>,
  switchBackFieldsEmptyValues?: Partial<Record<FieldPath<TFieldValues>, Falsy>>
) => {
  (
    Object.entries(switchFieldsEmptyValues) as [
      FieldPath<TFieldValues>,
      Falsy,
    ][]
  ).forEach(([fieldName, fieldEmptyValue]) => {
    if (shouldSwitchFields) {
      formContext.resetField(fieldName);
    } else {
      // Save current value as default, so we can switch it back
      formContext.resetField(fieldName, {
        defaultValue: formContext.getValues(fieldName),
      });
      formContext.setValue(
        fieldName,
        fieldEmptyValue as PathValue<TFieldValues, Path<TFieldValues>>
      );
    }
  });
  if (switchBackFieldsEmptyValues) {
    emptySwitchableFormFields(
      formContext,
      !shouldSwitchFields,
      switchBackFieldsEmptyValues
    );
  }
};
