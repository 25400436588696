import { ConceptionRateParameterEnum } from '@graphql-types';

import { SkeletonPlaceholder } from '~/shared/components/Skeleton';

import { InferValidatedSchema } from '~/services/forms';

import { ReproductionCrDetailedRowsByEntityFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrDetailedRowsByEntity.graphql';
import { CalculateReproductionCrReportMutation } from '~/entities/reproductionCrReports/gql/mutations/calculateReproductionCrReport.graphql';

import {
  REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA,
  REPRODUCTION_CR_VALUE_INPUT_FORM_SCHEMA,
} from '../constants';

/**
 * Form type for reproduction CR report settings
 */
export type ReproductionCrReportSettingsFormType = InferValidatedSchema<
  typeof REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA
>;

/**
 * Form type for reproduction CR grouping value
 */
export type ReproductionCrValueInputFormType = InferValidatedSchema<
  typeof REPRODUCTION_CR_VALUE_INPUT_FORM_SCHEMA
>;

/**
 * Possible CR report mutation results
 */
export type ReproductionCrReportDataType =
  CalculateReproductionCrReportMutation['calculateReproductionCrReport'];

/**
 * Items for adding filters and grouping
 */
export enum ReproductionCrReportFiltersAndGroupingMenuItems {
  filterLactations = 'filterLactations',
  filterAnimals = 'filterAnimals',
  grouping = 'grouping',
}

/**
 * Common props for CR table and chart x axis labels
 */
export interface CrReportDisplayProps {
  /**
   * Data to display on the chart
   */
  chartData: ReproductionCrDetailedRowsByEntityFragment | SkeletonPlaceholder;
  /**
   * If passed, draws chart and table in period comparison mode
   */
  historicChartData?: ReproductionCrDetailedRowsByEntityFragment;
  /**
   * Mode for x axis
   */
  xAxisMode: ConceptionRateParameterEnum;
}
