import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import {
  makeSelectComponentFromHook,
  PartialSelectProps,
} from '~/shared/components/Select';

import {
  AnyFragment,
  DefaultIdInput,
  SearchablePaginatedQueryVariables,
  UseAsyncSelectProps,
} from '~/services/gql';

type AsyncSelectFormComponentProps<
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends SearchablePaginatedQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
> = UseAsyncSelectProps<
  Fragment,
  QueryData,
  QueryVariables,
  IdInput
>['selectProps'] &
  Omit<
    UseAsyncSelectProps<Fragment, QueryData, QueryVariables, IdInput>,
    'selectProps'
  >;

/**
 * Fabric for creating a connected to form AsyncSelect component,
 * using an async select hook from makeUseAsyncSelect
 */
export const makeAsyncSelectFormComponent = <
  Fragment extends AnyFragment,
  QueryData,
  QueryVariables extends SearchablePaginatedQueryVariables<IdInput>,
  IdInput = DefaultIdInput,
>(
  useAsyncSelect: (
    props: UseAsyncSelectProps<Fragment, QueryData, QueryVariables, IdInput>
  ) => {
    renderSelectElement: (
      innerProps?: PartialSelectProps<Fragment>
    ) => React.JSX.Element;
  }
) => {
  const SelectComponent = makeSelectComponentFromHook(useAsyncSelect);

  return ({
    queryOptions,
    onFirstLoad,
    ...selectProps
  }: AsyncSelectFormComponentProps<
    Fragment,
    QueryData,
    QueryVariables,
    IdInput
  >) => {
    // react-hook-form expects that context is always there,
    // but we use this workaround to allow the use of inputs without forms
    const formContext: UseFormReturn | null = useFormContext();

    return (
      <SelectComponent
        {...{
          selectProps: {
            ...selectProps,
            // This is needed for correct items display, see TODO comments in makeUseAsyncSelect
            rawValue:
              selectProps.rawValue ?? formContext?.watch(selectProps.name),
          },
          queryOptions,
          onFirstLoad,
        }}
      />
    );
  };
};
