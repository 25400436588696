import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePenGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeletePenGroupMutation = { __typename?: 'Mutation', deletePenGroup?: any | null };


export const DeletePenGroupDocument = gql`
    mutation deletePenGroup($id: ID!) {
  deletePenGroup(id: $id)
}
    `;
export type DeletePenGroupMutationFn = Apollo.MutationFunction<DeletePenGroupMutation, DeletePenGroupMutationVariables>;

/**
 * __useDeletePenGroupMutation__
 *
 * To run a mutation, you first call `useDeletePenGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePenGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePenGroupMutation, { data, loading, error }] = useDeletePenGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePenGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeletePenGroupMutation, DeletePenGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePenGroupMutation, DeletePenGroupMutationVariables>(DeletePenGroupDocument, options);
      }
export type DeletePenGroupMutationHookResult = ReturnType<typeof useDeletePenGroupMutation>;
export type DeletePenGroupMutationResult = Apollo.MutationResult<DeletePenGroupMutation>;
export type DeletePenGroupMutationOptions = Apollo.BaseMutationOptions<DeletePenGroupMutation, DeletePenGroupMutationVariables>;