import { ModalRegistration } from '~/services/modals';

import {
  EditReproductionSettingsModal,
  EditReproductionSettingsModalProps,
} from '.';

export const registration: ModalRegistration<EditReproductionSettingsModalProps> =
  {
    Component: EditReproductionSettingsModal,
  };
