import { ModalRegistration } from '~/services/modals';

import {
  EditLivestockForecastSettingModal,
  EditLivestockForecastSettingModalProps,
} from '.';

export const registration: ModalRegistration<EditLivestockForecastSettingModalProps> =
  {
    Component: EditLivestockForecastSettingModal,
  };
