import React from 'react';

import {
  Navigate,
  Outlet,
  useLocation,
  useRouter,
} from '@tanstack/react-router';

import { useArkaNavigation } from '~/services/navigation';

export const NavigateFromRootOutlet: React.FC = () => {
  const { urlCompanyId } = useArkaNavigation();
  const { basepath } = useRouter();
  const location = useLocation();

  const getLocationPropsToNavigate = () => {
    if (location.state.data?.redirectTo) {
      return { to: location.state.data?.redirectTo as '.' };
    }

    if (urlCompanyId) {
      return {
        to: '/$companyId/user/production-calendar',
        params: { companyId: urlCompanyId },
      } as const;
    }

    return { to: '/select-company' } as const;
  };

  return (
    <>
      {location.pathname === basepath && (
        <Navigate {...getLocationPropsToNavigate()} />
      )}
      <Outlet />
    </>
  );
};
