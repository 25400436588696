import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmFragmentDoc } from '../../../farms/gql/fragments/farm.graphql';
export type BullDetailedFragment = { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, comment: string, category: string, bullState: Types.BullState, farm: { __typename: 'Farm', id: string, name: string, number: string } };

export const BullDetailedFragmentDoc = gql`
    fragment BullDetailed on Bull {
  __typename
  id
  name
  registrationNumber
  breed
  usdaNumber
  comment
  farm {
    ...Farm
  }
  category
  bullState: state
}
    ${FarmFragmentDoc}`;