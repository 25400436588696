import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { EmployeeFragmentDoc } from '../../gql/fragments/employee.graphql';
import { useEmployeesQuery } from '../../gql/queries/employees.graphql';
import { formatEmployee } from '../../helpers';

/**
 * Select for an employee
 */
export const useEmployeeSelect = makeUseAsyncSelect({
  typeName: 'Employee',
  fragment: EmployeeFragmentDoc,
  useQuery: useEmployeesQuery,
  getItemsFromQueryData: data => data.employees.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.employees.pageInfo,

  selectProps: {
    placeholder: 'Введите имя',
    getItemValue: item => item.id,
    getItemText: item => formatEmployee(item, { withNumber: false }),
    getItemDescription: item => formatEmployee(item, { withName: false }),
  },
});

export const EmployeeAsyncSelect =
  makeAsyncSelectFormComponent(useEmployeeSelect);
