import React, { ReactElement } from 'react';

import { Badge } from '~/shared/components/Badge';
import {
  Button,
  ButtonPropsWithoutRef,
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import {
  DataBlockedMessage,
  DataBlockedMessageProps,
} from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { Callout, NotificationVariants } from '~/services/notifications';

import styles from './index.module.scss';

interface Props {
  /**
   * Title of block
   */
  title: string;
  /**
   * Amount of itemsToRender in comparison with total amount
   */
  amount: string | number;
  /**
   * Props for button for adding new items
   */
  addButtonProps: Partial<ButtonPropsWithoutRef>;
  /**
   * Array of items to render in block
   */
  itemsToRender: ReactElement[];
  /**
   * Handler to delete all the items
   */
  onClear: () => void;
  /**
   * Optional error message for DataBlockedMessage element
   */
  errorMessage?: string;
  /**
   * Props for empty dataBlockedMessage component
   */
  dataBlockedMessageProps: Pick<
    DataBlockedMessageProps,
    'isLoading' | 'loadingMessage' | 'message'
  >;
}

export const ContentItems: React.FC<Props> = ({
  title,
  amount,
  addButtonProps,
  itemsToRender,
  dataBlockedMessageProps,
  errorMessage,
  onClear: handleClear,
}) => {
  const hasItemsToRender = !!itemsToRender?.length;

  return (
    <div className="flex flex-col gap-12">
      <div className="flex justify-between">
        <div className="flex gap-8">
          <Typography variant={TypographyVariants.bodySmallStrong}>
            {title}
          </Typography>
          <Badge isPill>{amount}</Badge>
          {hasItemsToRender && (
            <Button
              {...{
                iconVariant: IconVariants.cancel,
                variant: ButtonVariants.ghost,
                size: ButtonSizes.small24,
                onPress: handleClear,
              }}
            >
              Очистить
            </Button>
          )}
        </div>
        {hasItemsToRender && (
          <Button
            {...{
              iconVariant: IconVariants.plus,
              variant: ButtonVariants.secondary,
              theme: ButtonThemes.accent,
              size: ButtonSizes.small24,
              ...addButtonProps,
            }}
          />
        )}
      </div>

      {hasItemsToRender ? (
        itemsToRender
      ) : (
        <div className="flex flex-col gap-24">
          {!!errorMessage && (
            <Callout
              {...{
                message: errorMessage,
                variant: NotificationVariants.error,
              }}
            />
          )}
          <div {...{ className: styles.blockedMessage }}>
            <DataBlockedMessage
              {...{
                buttonProps: {
                  iconVariant: IconVariants.plus,
                  variant: ButtonVariants.secondary,
                  theme: ButtonThemes.accent,
                  ...addButtonProps,
                },
                className: 'p-24',
                ...dataBlockedMessageProps,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
