import { BubbleController } from 'chart.js';

function hexToRgbA(hex: string, alpha: string) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ','
    )},${alpha})`;
  }
  throw new Error('Bad Hex');
}

export class CowEventsSpecialController extends BubbleController {
  static id = 'cowEventsSpecial';

  draw(): void {
    super.draw();
    const meta = this.getMeta();
    const dataset = meta.data;

    const scale = this.getScaleForId('y');

    const { ctx } = this.chart;
    ctx.save();

    dataset.forEach(item => {
      const fillStyle = hexToRgbA(item.options.backgroundColor, '0.1');
      ctx.fillStyle = fillStyle;
      ctx.fillRect(
        item.x - item.options.radius,
        scale?.top || 0,
        item.options.radius * 2,
        item.y
      );
    });

    ctx.restore();
  }
}
