import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDiseaseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateDiseaseInput;
}>;


export type UpdateDiseaseMutation = { __typename?: 'Mutation', updateDisease?: any | null };


export const UpdateDiseaseDocument = gql`
    mutation updateDisease($id: ID!, $input: UpdateDiseaseInput!) {
  updateDisease(id: $id, input: $input)
}
    `;
export type UpdateDiseaseMutationFn = Apollo.MutationFunction<UpdateDiseaseMutation, UpdateDiseaseMutationVariables>;

/**
 * __useUpdateDiseaseMutation__
 *
 * To run a mutation, you first call `useUpdateDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiseaseMutation, { data, loading, error }] = useUpdateDiseaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiseaseMutation, UpdateDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiseaseMutation, UpdateDiseaseMutationVariables>(UpdateDiseaseDocument, options);
      }
export type UpdateDiseaseMutationHookResult = ReturnType<typeof useUpdateDiseaseMutation>;
export type UpdateDiseaseMutationResult = Apollo.MutationResult<UpdateDiseaseMutation>;
export type UpdateDiseaseMutationOptions = Apollo.BaseMutationOptions<UpdateDiseaseMutation, UpdateDiseaseMutationVariables>;