import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { MappingType } from './types';

/**
 * Schema for mapping item element
 */
export const MAPPING_ITEM_SCHEMA = yup
  .object({
    originID: yup.string().required(), // ID!
    targetID: yup
      .string()
      .nullable()
      .default(null)
      .when(['mappingType'], ([mappingType], schema) => {
        if (mappingType === MappingType.equally) {
          return schema.required();
        }
        return schema;
      }), // ID!
    mappingType: oneOfEnum<MappingType>(MappingType).default(
      MappingType.equally
    ),
  })
  .required();
