import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { FarmWithMilkingParlorSettingsFragmentDoc } from './farmWithMilkingParlorSettings.graphql';
import { MilkingParlorIntervalDetailedFragmentDoc } from './milkingParlorIntervalDetailed.graphql';
export type MilkingParlorDetailedFragment = { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, farm: { __typename: 'Farm', id: string, name: string, number: string, milkingParlorSettings?: { __typename?: 'MilkingParlorFarmSetting', firstMilkingPerDayStartOn: string } | null }, intervals: Array<{ __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string, penGroups: { __typename?: 'MilkingParlorIntervalPenGroupsConnection', edges: Array<{ __typename?: 'MilkingParlorIntervalPenGroupEdge', milkingNumber?: number | null, node: { __typename: 'PenGroup', id: string, name: string, identifier: number } }> } }> };

export const MilkingParlorDetailedFragmentDoc = gql`
    fragment MilkingParlorDetailed on MilkingParlor {
  __typename
  id
  name
  farm {
    ...FarmWithMilkingParlorSettings
  }
  manufacturer
  kind
  capacity
  capacityStart
  capacityEnd
  sortingGatesCount
  connectionInfo
  intervals {
    ...MilkingParlorIntervalDetailed
  }
}
    ${FarmWithMilkingParlorSettingsFragmentDoc}
${MilkingParlorIntervalDetailedFragmentDoc}`;