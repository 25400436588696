import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { MilkingParlorFragment } from '../gql/fragments/milkingParlor.graphql';
import {
  MilkingParlorsQuery,
  MilkingParlorsQueryVariables,
  useMilkingParlorsQuery,
} from '../gql/queries/milkingParlors.graphql';

export const useMilkingParlorsPaginatedQuery = makeUsePaginatedQuery<
  MilkingParlorFragment,
  MilkingParlorsQuery,
  MilkingParlorsQueryVariables
>({
  useQuery: useMilkingParlorsQuery,
  getItemsFromQueryData: data => data.milkingParlors.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.milkingParlors.pageInfo,
});
