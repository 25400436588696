import React, { useCallback, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import TOKENS from '~/styles/__generated__/tokens.json';

import {
  BlueprintEditService,
  BlueprintNodeType,
  BlueprintSourceSectionField,
} from '../../../../services';
import { ArgumentNode } from '../../../ArgumentNode';
import { NodeFrame } from '../../../NodeFrame';
import { NodeHeader } from '../../../NodeHeader';
import { FieldNodeName } from '../FieldNodeName';

interface Props {
  field: BlueprintSourceSectionField;
}

export const FieldNode: React.FC<Props> = ({ field }) => {
  const bpEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const memoizedDelete = useMemo(() => {
    return bpEditSvc.deleteNode.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    loading: deleting,
    errors: deleteErrors,
    reload: deleteFunc,
  } = useApiData(memoizedDelete);

  const deleteCallback = useCallback(() => {
    deleteFunc({
      nodeId: field.id,
      nodeType: BlueprintNodeType.Field,
    });
  }, []);

  const memoizedUpdate = useMemo(() => {
    return bpEditSvc.updateSourceField.bind(bpEditSvc);
  }, [bpEditSvc]);

  const {
    errors: updateErrors,
    loading: updateing,
    reload: update,
  } = useApiData(memoizedUpdate);

  const updateCallback = useCallback(
    async (name?: string) => {
      const result = await update({
        ...field,
        name: name || '',
      });
      return result.success;
    },
    [field]
  );

  const { errorMessage, loader } = useApiFeedback(
    [...deleteErrors, ...updateErrors],
    deleting || updateing
  );

  const argumentsCmpnts = field.arguments.map(argument => (
    <ArgumentNode
      parentNodeType={BlueprintNodeType.Field}
      key={argument.id}
      argument={argument}
    />
  ));

  return (
    <NodeFrame
      header={
        <NodeHeader
          title={field.verboseName || field.kind}
          delete={deleteCallback}
        />
      }
      className="my-8"
      headerStyle={{
        background: TOKENS.colorWarning400,
      }}
    >
      {errorMessage}
      {loader}

      <FieldNodeName name={field.name} update={updateCallback} />

      <div className="m-8">{argumentsCmpnts}</div>
    </NodeFrame>
  );
};
