import { makeUpdateFragment } from '~/services/gql';

import {
  PenGroupDetailedFragment,
  PenGroupDetailedFragmentDoc,
} from '~/entities/penGroups/gql/fragments/penGroupDetailed.graphql';

/**
 * Update PenGroupDetailed in the cache
 */
export const updatePenGroupDetailedFragment =
  makeUpdateFragment<PenGroupDetailedFragment>({
    typeName: 'PenGroup',
    fragment: PenGroupDetailedFragmentDoc,
  });
