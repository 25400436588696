import React from 'react';

import { ConceptionRateParameterEnum, VitalityFilter } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { Checkbox } from '~/shared/components/Checkbox';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';

import { DatePeriodPicker } from '~/services/dateTime';
import { Form } from '~/services/forms';

import { CowAsyncSelect } from '~/entities/cows';
import { FarmAsyncSelect } from '~/entities/farms';

import {
  CompareToPreviousPeriodModeSelect,
  LactationGroupNumberSelect,
} from '~/features/milkingAnalytics';
import {
  ReportCardBuilderFormArrayItem,
  ReportCardBuilderFormSection,
} from '~/features/reportCardBuilder';

import contentGriStyles from '~/styles/modules/contentGrid.module.scss';

import {
  REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT,
  REPRODUCTION_CR_REPORT_SETTINGS_FORM_ID,
} from '../../constants';
import { isReproductionCrReportDataEmpty } from '../../helpers';
import { ReproductionCrReportFiltersAndGroupingMenuItems } from '../../types';
import { FemaleAnimalKindSelect } from '../FemaleAnimalKindSelect';
import { useReproductionConceptionRateParameterSelect } from '../ReproductionConceptionRateParameterSelect';
import { ReproductionCrDateStepSelect } from '../ReproductionCrDateStepSelect';
import { ReproductionCrReportChartKindSelect } from '../ReproductionCrReportChartKindSelect';
import { ReproductionYAxisModeSelect } from '../ReproductionYAxisModeSelect';
import { ReproductionCrValueSelect } from './components/ReproductionCrValueSelect';
import { useReproductionCrReportSettingsForm } from './hooks/useReproductionCrReportSettingsForm';
import { ReproductionCrReportSettingsFormProps } from './types';

const ADDING_DISABLED_TOOLTIP = 'Добавление недоступно';

export const ReproductionCrReportSettingsForm: React.FC<
  ReproductionCrReportSettingsFormProps
> = props => {
  const { className, onSettingsFormSave, currentReportData } = props;

  const {
    formContext,
    formValues,

    availableForFiltersAndGroupingItems,
  } = useReproductionCrReportSettingsForm(props);

  const isByDate = formValues.xAxisMode === ConceptionRateParameterEnum.Date;

  const {
    items: reproductionCRParameterXAxisModeItems,
    renderSelectElement: renderReproductionCRParameterXAxisModeSelectElement,
  } = useReproductionConceptionRateParameterSelect({
    label: 'Режим отображения',
    name: 'xAxisMode',
    onValueChange: () => {
      formContext.resetField('xAxisValues');
    },
  });

  const {
    items: reproductionCRParameterGroupByItems,
    renderSelectElement: renderReproductionCRParameterGroupBySelectElement,
  } = useReproductionConceptionRateParameterSelect({
    label: 'Параметр группировки',
    name: 'groupBy',
    onValueChange: () => {
      formContext.setValue('groupByValues', []);
    },
    theme: SelectThemes.light,
  });

  return (
    <Form
      {...{
        className: clsx(
          'items-start',
          contentGriStyles.autoGridStaticItems,
          className
        ),
        formContext,
        id: REPRODUCTION_CR_REPORT_SETTINGS_FORM_ID,
        onSubmit: formContext.handleSubmit(onSettingsFormSave),
      }}
    >
      <ReportCardBuilderFormSection title="Основное">
        <FarmAsyncSelect
          {...{
            name: 'farmID',
            label: 'Ферма',
          }}
        />
        <DatePeriodPicker
          {...{
            name: 'period',
            label: 'Период',
          }}
        />
        <FemaleAnimalKindSelect
          {...{
            label: 'Животные',
            name: 'femaleAnimalKind',
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection title="Ось X">
        {renderReproductionCRParameterXAxisModeSelectElement({
          items: reproductionCRParameterXAxisModeItems.filter(
            item =>
              (item.id as ConceptionRateParameterEnum) !== formValues.groupBy
          ),
        })}
        {isByDate && (
          <ReproductionCrDateStepSelect
            {...{
              label: 'Временной шаг',
              name: 'xAxisStep',
            }}
          />
        )}
        <ReproductionCrValueSelect
          {...{
            name: 'xAxisValues',
            label: 'Значение',
            reproductionCrValues: !isReproductionCrReportDataEmpty(
              currentReportData
            )
              ? (currentReportData?.xAxisValues ?? [])
              : [],
            asyncProps: {
              isLoading: isSkeletonPlaceholder(currentReportData),
            },
          }}
        />
        <ReportCardBuilderFormArrayItem title="Прочие">
          <Input
            {...{
              name: 'xAxisOtherFromTotalThresholdPercent',
              label: 'Перемещать в «Прочие», если «% от Всего» <',
              variant: InputVariants.int,
              shouldChangeValueWithDebounce: true,
              theme: InputThemes.light,
            }}
          />
          {!R.isNil(formValues.xAxisOtherFromTotalThresholdPercent) && (
            <Checkbox
              {...{
                name: 'xAxisShouldShowOther',
                label: 'Отображать «Прочие» на диаграмме',
              }}
            />
          )}
        </ReportCardBuilderFormArrayItem>
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection title="Ось Y">
        <ReportCardBuilderFormArrayItem title="Оплодотворяемость (CR)">
          <Checkbox
            {...{
              name: 'yAxisShouldCompareToPreviousPeriod',
              label: 'Сравнить с предыдущим периодом',
              isDisabled: !!formValues.groupBy,
              disabledTooltip:
                'Сравнение недоступно, если добавлен параметр группировки',
            }}
          />
          {formValues.yAxisShouldCompareToPreviousPeriod && (
            <>
              <CompareToPreviousPeriodModeSelect
                {...{
                  name: 'yAxisCompareToPreviousPeriodMode',
                  label: 'Режим сравнения',
                  theme: SelectThemes.light,
                }}
              />
              <ReproductionCrReportChartKindSelect
                {...{
                  label: 'Тип диаграммы',
                  name: 'yAxisCompareToPreviousPeriodChartKind',
                  theme: SelectThemes.light,
                }}
              />
            </>
          )}
        </ReportCardBuilderFormArrayItem>

        <ReproductionYAxisModeSelect
          {...{
            name: 'yAxisMode',
            label: 'Шкала',
          }}
        />
      </ReportCardBuilderFormSection>

      <ReportCardBuilderFormSection<
        (typeof availableForFiltersAndGroupingItems)[number]
      >
        {...{
          title: 'Фильтры и группировка',
          noChildrenMessage: 'Фильтры и группировка пока не добавлены',
          contextMenuButtonProps: {
            items: availableForFiltersAndGroupingItems,
            isDisabled: !availableForFiltersAndGroupingItems.length,
            tooltip: !availableForFiltersAndGroupingItems.length
              ? ADDING_DISABLED_TOOLTIP
              : undefined,
          },
        }}
      >
        {!!formValues.lactationGroupNumbers && (
          <ReportCardBuilderFormArrayItem
            {...{
              title:
                REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[
                  ReproductionCrReportFiltersAndGroupingMenuItems
                    .filterLactations
                ],
              onDelete: () =>
                formContext.setValue('lactationGroupNumbers', null),
            }}
          >
            <LactationGroupNumberSelect
              {...{
                name: 'lactationGroupNumbers',
                label: 'Группа лактации',
                isMulti: true,
                theme: SelectThemes.light,
              }}
            />
          </ReportCardBuilderFormArrayItem>
        )}

        {!!formValues.cowIDs && (
          <ReportCardBuilderFormArrayItem
            {...{
              title:
                REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[
                  ReproductionCrReportFiltersAndGroupingMenuItems.filterAnimals
                ],
              onDelete: () => formContext.setValue('cowIDs', null),
            }}
          >
            <CowAsyncSelect
              {...{
                queryOptions: {
                  variables: {
                    vitalityFilter: VitalityFilter.Alive,
                  },
                },
                label: 'Номер животного',
                name: 'cowIDs',
                isMulti: true,
                theme: SelectThemes.light,
              }}
            />
          </ReportCardBuilderFormArrayItem>
        )}

        {formValues.groupBy && (
          <ReportCardBuilderFormArrayItem
            {...{
              title:
                REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT[
                  ReproductionCrReportFiltersAndGroupingMenuItems.grouping
                ],
              onDelete: () => formContext.setValue('groupBy', null),
            }}
          >
            {renderReproductionCRParameterGroupBySelectElement({
              items: reproductionCRParameterGroupByItems.filter(
                item =>
                  (item.id as ConceptionRateParameterEnum) !==
                  formValues.xAxisMode
              ),
            })}
            {formValues.groupBy === ConceptionRateParameterEnum.Date && (
              <ReproductionCrDateStepSelect
                {...{
                  label: 'Временной шаг',
                  name: 'groupByStep',
                  theme: SelectThemes.light,
                }}
              />
            )}
            <ReproductionCrValueSelect
              {...{
                name: 'groupByValues',
                label: 'Значение',
                reproductionCrValues: !isReproductionCrReportDataEmpty(
                  currentReportData
                )
                  ? (currentReportData?.groupByValues ?? [])
                  : [],
                asyncProps: {
                  isLoading: isSkeletonPlaceholder(currentReportData),
                },
                theme: SelectThemes.light,
              }}
            />
            <ReproductionCrReportChartKindSelect
              {...{
                label: 'Тип диаграммы',
                name: 'groupByChartKind',
                theme: SelectThemes.light,
              }}
            />
          </ReportCardBuilderFormArrayItem>
        )}
      </ReportCardBuilderFormSection>
    </Form>
  );
};
