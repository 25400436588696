import R from 'ramda';

/**
 * Takes a value or an array and always return an array
 */
export function normalizeValuesToArray<T extends any>(
  value: T | T[] | undefined
): NonNullable<T>[] | undefined;
export function normalizeValuesToArray<T extends any>(
  value: T | T[]
): NonNullable<T>[];
export function normalizeValuesToArray<T extends any>(
  value: T | T[] | undefined
): NonNullable<T>[] | undefined {
  if (value === undefined) return undefined;

  return (Array.isArray(value) ? value : [value]) as NonNullable<T>[];
}

/**
 * Prepare value for backend array filters
 */
export const normalizeToArrayOrUndefined = <T extends any>(value?: T | T[]) => {
  if (R.isNil(value) || value === '') return undefined;

  const arr = normalizeValuesToArray(value);

  return arr?.length ? arr : undefined;
};

/**
 * Takes a value and returns undefined if it is falsy or itself otherwise
 */
export const normalizeToValueOrUndefined = <T extends any>(value?: T) =>
  value || undefined;
