import { makeUpdateFragment } from '~/services/gql';

import {
  TestMilkingUploadFragment,
  TestMilkingUploadFragmentDoc,
} from './gql/fragments/testMilkingUpload.graphql';

/**
 * Update TestMilkingUploadFragment in the cache
 */
export const updateTestMilkingUploadFragment =
  makeUpdateFragment<TestMilkingUploadFragment>({
    typeName: 'TestMilkingUpload',
    fragment: TestMilkingUploadFragmentDoc,
  });
