import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type PenGroupFragment = { __typename: 'PenGroup', id: string, name: string, identifier: number, isMilking: boolean, isDry: boolean, isHospital: boolean, isCalf: boolean, isArtificialInsemination: boolean, isWithBull: boolean, isUser: boolean, capacity?: number | null, occupied: number, occupiedPercent?: number | null, farm: { __typename: 'Farm', id: string } };

export const PenGroupFragmentDoc = gql`
    fragment PenGroup on PenGroup {
  __typename
  id
  name
  identifier
  isMilking
  isDry
  isHospital
  isCalf
  isArtificialInsemination
  isWithBull
  isUser
  capacity
  occupied
  occupiedPercent
  farm {
    __typename
    id
  }
}
    `;