import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InjectionFragmentDoc } from '../fragments/injection.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInjectionMutationVariables = Types.Exact<{
  input: Types.CreateInjectionInput;
}>;


export type CreateInjectionMutation = { __typename?: 'Mutation', createInjection: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } };


export const CreateInjectionDocument = gql`
    mutation createInjection($input: CreateInjectionInput!) {
  createInjection(input: $input) {
    ...Injection
  }
}
    ${InjectionFragmentDoc}`;
export type CreateInjectionMutationFn = Apollo.MutationFunction<CreateInjectionMutation, CreateInjectionMutationVariables>;

/**
 * __useCreateInjectionMutation__
 *
 * To run a mutation, you first call `useCreateInjectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInjectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInjectionMutation, { data, loading, error }] = useCreateInjectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInjectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInjectionMutation, CreateInjectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInjectionMutation, CreateInjectionMutationVariables>(CreateInjectionDocument, options);
      }
export type CreateInjectionMutationHookResult = ReturnType<typeof useCreateInjectionMutation>;
export type CreateInjectionMutationResult = Apollo.MutationResult<CreateInjectionMutation>;
export type CreateInjectionMutationOptions = Apollo.BaseMutationOptions<CreateInjectionMutation, CreateInjectionMutationVariables>;