import { useCallback, useRef } from 'react';

/**
 * Hook that provides a callback to print the content of the contentRef inside the containerRef.
 * Other elements in the container will be hidden
 */
export const usePrint = <
  ContainerElement extends Element = HTMLDivElement,
  ContentElement extends Element = HTMLTableElement,
>() => {
  const containerRef = useRef<ContainerElement>(null);
  const contentRef = useRef<ContentElement>(null);

  const handlePrint = useCallback(() => {
    const root = window.document.getElementById('root');
    const className = root?.className || '';
    if (root) {
      root.className = `m-print`;
      root.style.height = '0';
      root.style.minHeight = '0';
    }

    if (contentRef.current) {
      window.document.body.append(contentRef.current);
    }

    window.print();

    if (root) {
      root.className = className;
      root.style.height = '';
      root.style.minHeight = '';
    }

    if (containerRef.current && contentRef.current) {
      containerRef.current.append(contentRef.current);
    }
  }, []);

  return {
    contentRef,
    containerRef,
    handlePrint,
  };
};
