import { Bull } from '@graphql-types';

import { HALF_SPACE, MDASH, NBSP, SPACED_INTERPUNCT } from '~/shared/constants';

import {
  getNumberPartFromGlobalId,
  makeReadFragment,
  makeUpdateFragment,
} from '~/services/gql';

import { BullFragment, BullFragmentDoc } from './gql/fragments/bull.graphql';
import {
  BullDetailedFragment,
  BullDetailedFragmentDoc,
} from './gql/fragments/bullDetailed.graphql';
import { BullFormatOptions } from './types';

/**
 * Returns normalized bull identifier
 */
const getBullIdentifier = (bull?: Pick<Bull, 'id' | 'registrationNumber'>) =>
  bull?.registrationNumber ?? getNumberPartFromGlobalId(bull?.id);

/**
 * Returns bull identifier formatted for rendering
 */
export const formatBull = (
  bull?: Pick<Bull, 'id' | 'registrationNumber' | 'name'> | null,
  { withNumberSign = true, prefix = 'Бык' }: BullFormatOptions = {}
) => {
  if (!bull) return MDASH;

  const prefixWithSpace = prefix && `${prefix} `;
  const numberSign = withNumberSign && `№${HALF_SPACE}`;
  const bullIdentifier = getBullIdentifier(bull);
  const bullNameInterpunct = bull.name && bullIdentifier && SPACED_INTERPUNCT;
  return (
    [prefixWithSpace, numberSign, bullIdentifier, bullNameInterpunct, bull.name]
      .filter(Boolean)
      .join('') || `Нет${NBSP}номера и${NBSP}клички`
  );
};

/**
 * Reads bull fragment from cache by id
 */
export const readBullFragment = makeReadFragment<BullFragment>({
  typeName: 'Bull',
  fragment: BullFragmentDoc,
});

/**
 * Update BullDetailedFragment in the cache
 */
export const updateBullDetailedFragment =
  makeUpdateFragment<BullDetailedFragment>({
    typeName: 'Bull',
    fragment: BullDetailedFragmentDoc,
  });
