import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import { useApolloClient } from '@apollo/client';

import { EventKindEnum } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useNotifications } from '~/services/notifications';

import {
  isUserEvent,
  readUserEventFragment,
  useEventsPaginatedQuery,
} from '~/entities/events';

import formStyles from '~/styles/modules/form.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { MoveCowCard } from '../../../../components';
import { useAddEventIdsToListModal } from '../../../index';
import stepsStyles from '../../steps.module.scss';
import { MoveCowsFormType } from '../../types';
import { ContentItems } from '../ContentItems';

const MAX_EVENTS_TO_LOAD = 1000;

export const ChooseEventsStep: React.FC = () => {
  const { sendNeutralToast } = useNotifications();
  const client = useApolloClient();

  const formContext = useFormContext<MoveCowsFormType>();
  const shouldRefetchEvents =
    !formContext.getFieldState('userEventIDs').isDirty;

  const eventIDs = formContext.watch('userEventIDs');
  const cowIDs = formContext.watch('cowIDs');

  const events = eventIDs
    .map(eventID => readUserEventFragment(client, eventID))
    .filter(Boolean);
  const { open: openAddEventIdsToListModal } = useAddEventIdsToListModal();

  const queryOptions = {
    variables: {
      kinds: [EventKindEnum.User],
      cowIDs,
    },
  };

  const {
    items: eventItems,
    hasMore,
    fetchMore,
    isLoading,
  } = useEventsPaginatedQuery({
    variables: {
      first: MAX_EVENTS_TO_LOAD,
      // variable structure is the same as event select has. It's for cache performance
      search: '',
      ...queryOptions.variables,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: async data => {
      if (hasMore) {
        await fetchMore();
        return;
      }
      if (shouldRefetchEvents) {
        formContext.setValue(
          'userEventIDs',
          data.events.edges
            .map(e => (isUserEvent(e.node) ? e.node.id : null))
            .filter(R.is(String)),
          {
            shouldDirty: true,
          }
        );
      }
    },
  });

  return (
    <div
      {...{
        className: clsx(
          panelStyles.largeDarkPanel,
          formStyles.singleColumnForm,
          'p-16'
        ),
      }}
    >
      <Typography variant={TypographyVariants.bodyMediumStrong}>
        Передача пользовательских событий
      </Typography>
      <Typography
        {...{
          className: stepsStyles.descriptionBlock,
          variant: TypographyVariants.bodySmall,
        }}
      >
        По умолчанию выбраны все пользовательские события животных для передачи
        на другую площадку. Вы можете полностью очистить выбор или удалить по
        одному.
      </Typography>
      <ContentItems
        {...{
          title: 'Список пользовательских событий',
          onClear: () => formContext.setValue('userEventIDs', []),
          amount: `${events.length} из ${eventItems.length}`,
          addButtonProps: {
            onPress: () => {
              openAddEventIdsToListModal({
                queryOptions,
                defaultValues: eventIDs,
                onSubmit: newEventIds =>
                  formContext.setValue('userEventIDs', newEventIds, {
                    shouldDirty: true,
                  }),
              });
            },
            children: 'Добавить события',
          },
          dataBlockedMessageProps: {
            isLoading,
            loadingMessage: 'Загрузка пользовательских событий',
            message: 'Пользовательские события пока не добавлены',
          },
          itemsToRender: events.map((event, index) => (
            <MoveCowCard
              key={event.id}
              {...{
                contentItems: [
                  <Fragment key={event.id}>{event.name}</Fragment>,
                ],
                index: index + 1,
                onDelete: () => {
                  formContext.setValue(
                    'userEventIDs',
                    eventIDs.filter(id => id !== event.id)
                  );
                  sendNeutralToast('Событие удалено');
                },
              }}
            />
          )),
        }}
      />
    </div>
  );
};
