import { BullRetirementReason } from '@graphql-types';
import R from 'ramda';

import { makeUseEnumSelect } from '~/shared/components/Select';

// TODO this is actually not used and an artifact on the backend
// It should be removed after blueprints rework
export const useBullRetirementReasonSelect = makeUseEnumSelect(
  BullRetirementReason,
  R.identity
);
