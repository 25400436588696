import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MilkingParlorShortFragmentDoc } from '../../../../entities/milkingParlors/gql/fragments/milkingParlorShort.graphql';
import { PenGroupShortFragmentDoc } from '../../../../entities/penGroups/gql/fragments/penGroupShort.graphql';
export type MilkingParlorIntervalWithMilkingParlorFragment = { __typename: 'MilkingParlorInterval', id: string, intervalStart: string, intervalEnd: string, milkingParlor: { __typename: 'MilkingParlor', id: string, name: string, manufacturer: Types.MilkingParlorManufacturerEnum, kind: Types.MilkingParlorKindEnum, capacity: number, capacityStart: number, capacityEnd: number, sortingGatesCount: number, connectionInfo: string, farm: { __typename: 'Farm', id: string, name: string, number: string } }, penGroups: { __typename?: 'MilkingParlorIntervalPenGroupsConnection', edges: Array<{ __typename?: 'MilkingParlorIntervalPenGroupEdge', node: { __typename: 'PenGroup', id: string, name: string, identifier: number } }> } };

export const MilkingParlorIntervalWithMilkingParlorFragmentDoc = gql`
    fragment MilkingParlorIntervalWithMilkingParlor on MilkingParlorInterval {
  __typename
  id
  intervalStart
  intervalEnd
  milkingParlor {
    ...MilkingParlorShort
  }
  penGroups {
    edges {
      node {
        ...PenGroupShort
      }
    }
  }
}
    ${MilkingParlorShortFragmentDoc}
${PenGroupShortFragmentDoc}`;