import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { makeUseEnumSelect } from '~/shared/components/Select';

import { TimeZone } from '~/entities/companies';

import { TIME_ZONES_DICT } from '../constants';

dayjs.extend(utc);
dayjs.extend(timezone);

const getUtcOffset = (tz: string) => dayjs().tz(tz).utcOffset();

const isValidTimeZone = (timeZone: string) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch {
    return false;
  }
};

/**
 * Select for time zone
 */
export const useTimeZoneSelect = makeUseEnumSelect<Record<TimeZone, TimeZone>>(
  Object.fromEntries(
    Object.keys(TIME_ZONES_DICT)
      .filter(isValidTimeZone)
      .sort((a, b) => {
        const offsetDiff = getUtcOffset(a) - getUtcOffset(b);
        if (offsetDiff === 0) {
          return TIME_ZONES_DICT[a as TimeZone].localeCompare(
            TIME_ZONES_DICT[b as TimeZone]
          );
        }
        return offsetDiff;
      })
      .map(timeZone => [timeZone, timeZone])
  ) as Record<TimeZone, TimeZone>,
  timeZone => {
    const utcOffset = dayjs().tz(timeZone).format('Z');

    return `(GMT${utcOffset}) ${TIME_ZONES_DICT[timeZone]}`;
  }
);
