import R from 'ramda';

import { AnyFragment } from '../types';

/**
 * Omits __typename field from a fragment, useful for mapping simple fragments to forms
 */
export const omitTypename = R.omit(['__typename']) as <T extends AnyFragment>(
  fragment: T
) => Omit<T, '__typename'>;
