import React from 'react';

export const Spinner = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.9933 3.75102C12.4078 3.75102 12.7439 3.41501 12.7439 3.00051C12.7439 2.58602 12.4078 2.25 11.9933 2.25C8.089 2.25 4.4735 4.6658 2.97937 8.27294C1.48524 11.8801 2.33356 16.1449 5.09435 18.9057C7.85514 21.6664 12.1199 22.5148 15.7271 21.0206C19.3342 19.5265 21.75 15.911 21.75 12.0067C21.75 11.5922 21.414 11.2561 20.9995 11.2561C20.585 11.2561 20.249 11.5922 20.249 12.0067C20.249 15.3103 18.2048 18.3696 15.1526 19.6339C12.1004 20.8981 8.49178 20.1803 6.15573 17.8443C3.81968 15.5082 3.10187 11.8996 4.36614 8.84736C5.6304 5.79516 8.68967 3.75102 11.9933 3.75102Z"
      fill="currentColor"
    />
  </svg>
);
