import React, { ReactElement, useMemo } from 'react';

import {
  DataRow,
  ExecutionResultRow,
  ExecutionResultRowTypes,
  GroupedRow,
} from '~/~legacy/services/BlueprintExecutionService';

import { GroupedTabelRow } from './components/GroupedTabelRow';
import { TableRow } from './components/TableRow';

export const useTableResultComponentsMap = (totalColumns: number) => {
  return useMemo(() => {
    return {
      [ExecutionResultRowTypes.BlueprintLaunchGroupedRow]: (
        row: GroupedRow,
        level
      ) => (
        <GroupedTabelRow
          key={row.groupValue + row.groupKey}
          row={row}
          totalColumns={totalColumns}
          level={level}
        />
      ),
      [ExecutionResultRowTypes.BlueprintLaunchDataRow]: (
        row: DataRow,
        level
      ) => <TableRow key={row.row} row={row} level={level} />,
    } as Record<
      ExecutionResultRowTypes,
      (row: ExecutionResultRow, level?: number) => ReactElement
    >;
  }, [totalColumns]);
};
