import { DefaultEventEnum, EventKindEnum } from '@graphql-types';

import TOKENS from '~/styles/__generated__/tokens.json';

import { CowEventCategories, CowEventConfig } from './types';

/**
 * Rendering configs for different cow card events
 */
export const COW_EVENT_CATEGORY_CONFIGS: Record<
  CowEventCategories,
  CowEventConfig
> = {
  [CowEventCategories.insemination]: {
    name: 'осеменение',
    color: TOKENS.colorAccentDefault,
    shortcodes: [DefaultEventEnum.Bred],
  },
  [CowEventCategories.disease]: {
    name: 'болезнь',
    color: TOKENS.colorErrorDefault,
    kinds: [EventKindEnum.Disease],
  },
  [CowEventCategories.dry]: {
    name: 'сухостой',
    color: TOKENS.colorStatus06Default,
    shortcodes: [DefaultEventEnum.Dry],
  },
  [CowEventCategories.death]: {
    name: 'выбытие',
    color: TOKENS.colorNeutral700,
    shortcodes: [DefaultEventEnum.Died],
  },
  [CowEventCategories.calving]: {
    name: 'отёл',
    color: TOKENS.colorSuccessDefault,
    shortcodes: [DefaultEventEnum.Fresh],
  },
  [CowEventCategories.other]: {
    name: 'другие события',
    color: TOKENS.colorInfoSoft,
  },
  [CowEventCategories.protocol]: {
    name: 'протокол',
    color: TOKENS.colorStatus05Muted,
    kinds: [EventKindEnum.Protocol, EventKindEnum.Injection],
  },
};
