import React, { ReactNode } from 'react';

import styles from './index.module.scss';

interface Props {
  children: ReactNode;
}

export const BlueprintFormsInfoPanel: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
