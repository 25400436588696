export function downloadFile(
  href: string,
  filename: string,
  fileExtension: string
) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute('href', href);
  link.setAttribute('download', `${filename}.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
