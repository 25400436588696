import React from 'react';

import { MBackdrop } from '../MBackdrop';

import './MLoader.scss';

interface Props {
  width?: string;
  height?: string;
  fixed?: boolean;
}

export const MLoader: React.FC<Props> = props => {
  const { height, width, fixed } = props;

  return (
    <MBackdrop fixed={fixed}>
      <div
        className="m-spinner"
        style={{
          height,
          width,
        }}
      />
    </MBackdrop>
  );
};
