import React from 'react';

import R from 'ramda';

import {
  makeUseEnumSelect,
  Select,
  SelectThemes,
} from '~/shared/components/Select';
import { AppLocalStorageKeys } from '~/shared/constants';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';
import { useLocalStorage } from '~/shared/hooks/useLocalStorage';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';
import { SerializableSearchParams } from '~/shared/types/serialization';

import {
  DateSwitcher,
  DateSwitcherThemes,
  formatDateForBackend,
} from '~/services/dateTime';

import { useFarmsFilter } from '~/entities/farms';
import {
  MAX_MILKINGS_PER_DAY_COUNT,
  useMilkingParlorsSelect,
} from '~/entities/milkingParlors';

const DEFAULT_MILKING_NUMBER = 1;

const MILKING_NUMBER_SELECT_ITEMS = [
  ...R.range(1, MAX_MILKINGS_PER_DAY_COUNT + 1).map(num => ({
    id: num,
    name: num,
  })),
];

export enum MilkingReportKinds {
  general = 'general',
  mixedMilking = 'mixedMilking',
  flowRate = 'flowRate',
  milkingMistakes = 'milkingMistakes',
  meterFunction = 'meterFunction',
  milkingParlorChart = 'milkingParlorChart',
}

export const MILKING_REPORT_KINDS_DICT: Record<MilkingReportKinds, string> = {
  [MilkingReportKinds.general]: 'Отчёт по доению',
  [MilkingReportKinds.mixedMilking]: 'Перепутанные животные',
  [MilkingReportKinds.flowRate]: 'Скорость и качество доения',
  [MilkingReportKinds.milkingMistakes]: 'Ошибки доярок',
  [MilkingReportKinds.meterFunction]: 'Проблемные аппараты',
  [MilkingReportKinds.milkingParlorChart]: 'График доильного зала',
};

const useMilkingReportKindSelect = makeUseEnumSelect(
  MilkingReportKinds,
  enumValue => MILKING_REPORT_KINDS_DICT[enumValue as MilkingReportKinds]
);

/**
 * Milking report filters search params type
 */
export interface MilkingReportFiltersSearchParams
  extends SerializableSearchParams {
  milkingDate: string;
  milkingParlorId: string | undefined;
  milkingNumber: string | undefined;
}

/**
 * Hook for all milking reports page filters
 */
export const useMilkingReportsFilters = () => {
  const {
    milkingDate,
    setMilkingDate,
    milkingParlorId: milkingParlorIdParam,
    setMilkingParlorId,
    milkingNumber: milkingNumberParam,
    setMilkingNumber,
  } = useSearchParamsState<MilkingReportFiltersSearchParams>();

  const [selectedReportKinds, setSelectedReportKinds] = useLocalStorage(
    AppLocalStorageKeys.selectedMilkingReportKinds,
    Object.values(MilkingReportKinds)
  );

  const milkingNumber = +(milkingNumberParam || DEFAULT_MILKING_NUMBER);

  const { farmId, renderFarmsSelectElement } = useFarmsFilter(false);

  const {
    items: milkingParlorSelectItems,
    renderSelectElement: renderMilkingParlorsSelectElement,
    isLoading: isMilkingParlorsLoading,
  } = useMilkingParlorsSelect({
    selectProps: {
      name: 'milkingParlor',
      theme: SelectThemes.light,
      className: 'default-control',
      onValueChange: newValue => setMilkingParlorId(newValue?.id),
    },
    onFirstLoad: items => setMilkingParlorId(items[0].id),
  });

  const { renderSelectElement: renderMilkingReportKindSelectElement } =
    useMilkingReportKindSelect({
      name: 'milkingReportKinds',
      label: 'Отчёты',
      theme: SelectThemes.light,
      className: 'default-control',
      isMulti: true,
      rawValue: selectedReportKinds,
      onValueChange: newValue =>
        setSelectedReportKinds(
          newValue.map(R.prop('id')) as MilkingReportKinds[]
        ),
    });

  // TODO make more general solution for async select auto-select
  const milkingParlorId =
    milkingParlorIdParam ?? milkingParlorSelectItems.at(0)?.id;

  const reportsVariables = {
    milkingDate,
    farmIDs: normalizeToArrayOrUndefined(farmId),
    milkingParlorIDs: normalizeToArrayOrUndefined(milkingParlorId),
    milkingNumbers: normalizeToArrayOrUndefined(milkingNumber),
  };

  const shouldSkipQueries = !farmId || !milkingParlorId;

  const filterElements = (
    <>
      <DateSwitcher
        {...{
          name: 'milkingDate',
          className: 'default-control',
          label: 'Дата',
          value: milkingDate,
          theme: DateSwitcherThemes.light,
          onValueChange: newDate =>
            setMilkingDate(formatDateForBackend(newDate, true)),
        }}
      />
      {renderFarmsSelectElement({
        className: 'default-control',
        rawValue: farmId,
      })}
      {renderMilkingParlorsSelectElement({
        rawValue: milkingParlorId,
      })}
      <Select<(typeof MILKING_NUMBER_SELECT_ITEMS)[number]>
        {...{
          name: 'milkingNumber',
          className: 'default-control',
          label: 'Номер доения',
          theme: SelectThemes.light,
          items: MILKING_NUMBER_SELECT_ITEMS,
          rawValue: milkingNumber,
          onValueChange: newValue => setMilkingNumber(newValue?.id.toString()),
        }}
      />
      {renderMilkingReportKindSelectElement()}
    </>
  );

  return {
    isMilkingParlorsLoading,
    milkingParlorId,
    selectedReportKinds,
    reportsVariables,
    shouldSkipQueries,
    filterElements,
  };
};
