import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ExportJobFragment = { __typename: 'ExportJob', id: string, status: Types.ExportJobStatus, file?: { __typename?: 'File', downloadUrl: string, name: string, extension: string } | null };

export const ExportJobFragmentDoc = gql`
    fragment ExportJob on ExportJob {
  __typename
  id
  file {
    downloadUrl
    name
    extension
  }
  status
}
    `;