import React, { useEffect } from 'react';

import R from 'ramda';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import {
  PartialSelectProps,
  PartialSelectPropsWithName,
} from '~/shared/components/Select';
import { ColorShades, getColorTokenValue } from '~/shared/helpers/color';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { useMyUser } from '~/services/auth';
import {
  RoleFragment,
  RoleFragmentDoc,
} from '~/services/auth/gql/fragments/role.graphql';
import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect, UseAsyncSelectProps } from '~/services/gql';
import { useArkaNavigation } from '~/services/navigation';

import { ROLE_COLOR_CONFIGS } from '../../constants';
import {
  RolesQuery,
  RolesQueryVariables,
  useRolesQuery,
} from '../../gql/queries/roles.graphql';

/**
 * Select for a role
 */
const useRoleSelectInternal = makeUseAsyncSelect({
  typeName: 'BlueprintRole',
  fragment: RoleFragmentDoc,
  useQuery: useRolesQuery,
  getItemsFromQueryData: data => data.roles.nodes,
  getPageInfoFromQueryData: R.always(undefined),

  selectProps: {
    label: 'Роль',
    placeholder: 'Выберите роль',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
    renderItemText: item => {
      if (!item) return null;

      const { colorVariant } = ROLE_COLOR_CONFIGS[item.color];

      return (
        <div className="flex items-center">
          <ColoredDot
            {...{
              className: 'mr-8',
              color: getColorTokenValue(colorVariant, ColorShades.default),
              size: ColoredDotSizes.medium12,
            }}
          />
          {item.name}
        </div>
      );
    },
  },
});

type UseRoleSelectProps = {
  selectProps: PartialSelectPropsWithName<RoleFragment> & {
    /**
     * If true, has additional effect of setting default values
     */
    isUsedAsFilter?: boolean;
  };
} & Pick<
  UseAsyncSelectProps<RoleFragment, RolesQuery, RolesQueryVariables>,
  'onFirstLoad' | 'queryOptions'
>;

export const useRoleSelect = ({
  selectProps: { isUsedAsFilter = false, ...selectProps },
  ...props
}: UseRoleSelectProps) => {
  const { getRolesByCompanyId } = useMyUser();
  const { selectedCompanyId } = useArkaNavigation();

  const myRoles = getRolesByCompanyId(selectedCompanyId);

  const {
    renderSelectElement: renderRoleSelectElement,
    items: roleItems,
    itemsPromise: roleItemsPromise,
    ...roleSelectInternalResult
  } = useRoleSelectInternal({
    selectProps: {
      ...wrapConditionalObjectElement(
        !!myRoles.length && {
          items: myRoles,
        }
      ),
      ...selectProps,
    },
    ...props,
  });

  // Setting default role value after fetching all the roles
  useEffect(() => {
    if (!isUsedAsFilter) return;

    const setRoles = (roles: RoleFragment[]) => {
      if (selectProps.isMulti) {
        selectProps.onValueChange?.(roles);
      } else {
        selectProps.onValueChange?.(roles[0]);
      }
    };

    const isEmptyOrNull = R.either(R.isEmpty, R.isNil);

    if (
      isEmptyOrNull(selectProps.rawValue) &&
      isEmptyOrNull(selectProps.value)
    ) {
      setRoles(myRoles);
    }
  }, [isUsedAsFilter]);

  return {
    renderSelectElement: (
      selectElementProps?: PartialSelectProps<RoleFragment>
    ) =>
      renderRoleSelectElement({
        isDisabled:
          isUsedAsFilter &&
          (myRoles.length === 1 || (!myRoles.length && roleItems.length === 1)),

        ...selectElementProps,
      }),
    items: roleItems,
    itemsPromise: roleItemsPromise,
    ...roleSelectInternalResult,
  };
};

export const RoleAsyncSelect = makeAsyncSelectFormComponent(useRoleSelect);
