import { ChartOptions } from 'chart.js';

import { formatWithPercent } from '~/shared/helpers/number';

import { formatDate } from '~/services/dateTime';

import TOKENS from '~/styles/__generated__/tokens.json';

import { CowChartData, CowChartEventData } from './types';

/**
 * Generates a chart.js config for rendering the cow chart
 */
export const getCowChartOptions = (
  shouldDisplaySomaticCellsAxis = false
): ChartOptions => ({
  animation: false,
  plugins: {
    datalabels: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      filter: item => item?.dataset.type !== 'todayBarSpecial',
      callbacks: {
        title: items => {
          const firstItem = items[0];
          const itemData = firstItem?.raw as CowChartData;

          if (firstItem?.dataset.type === 'cowEventsSpecial') {
            return undefined;
          }

          return itemData?.happenedAt
            ? `${formatDate(itemData.happenedAt)} / ${
                itemData.x
              } день в доении\nНомер лактации - ${itemData.lactationNumber}`
            : '';
        },
        labelPointStyle: () => ({
          pointStyle: 'circle',
          rotation: 0,
        }),
        label: context => {
          if (context?.dataset.type !== 'cowEventsSpecial') {
            return undefined;
          }

          const itemData = context.raw as CowChartEventData;
          const { event } = itemData;
          const date = formatDate(itemData.happenedAt);

          return `${event?.name} ${date}`;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      type: 'linear',
      bounds: 'ticks',
      suggestedMin: 0,
      min: 0,
    },
    y: {
      min: 0,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      title: {
        text: 'надой',
      },
      grace: '0%',
    },
    rightScale: {
      min: 0,
      type: 'linear',
      position: 'right',
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: TOKENS.colorAccentDefault,
        callback: value => formatWithPercent(value),
      },
    },
    rightScc: {
      display: shouldDisplaySomaticCellsAxis,
      min: 0,
      type: 'linear',
      position: 'right',
      grid: {
        display: false,
      },
      ticks: {
        color: 'blue',
        callback: value => `${value} тыс/мл`,
      },
    },
  },
});
