import { makeReadFragment, makeUpdateFragment } from '~/services/gql';

import {
  CompanyFragment,
  CompanyFragmentDoc,
} from './gql/fragments/company.graphql';
import {
  CompanyDetailedFragment,
  CompanyDetailedFragmentDoc,
} from './gql/fragments/companyDetailed.graphql';
import {
  CompanyShortFragment,
  CompanyShortFragmentDoc,
} from './gql/fragments/companyShort.graphql';
import {
  HerriotIntegrationEntityFragment,
  HerriotIntegrationEntityFragmentDoc,
} from './gql/fragments/herriotIntegrationEntity.graphql';
import { AnyCompanyFragment } from './types';

/**
 * Update CompanyShortFragment in the cache
 */
export const updateCompanyShortFragment =
  makeUpdateFragment<CompanyShortFragment>({
    typeName: 'Company',
    fragment: CompanyShortFragmentDoc,
  });

/**
 * Update CompanyDetailedFragment in the cache
 */
export const updateCompanyDetailedFragment =
  makeUpdateFragment<CompanyDetailedFragment>({
    typeName: 'Company',
    fragment: CompanyDetailedFragmentDoc,
  });

/**
 * Update CompanyFragment in the cache
 */
export const updateCompanyFragment = makeUpdateFragment<CompanyFragment>({
  typeName: 'Company',
  fragment: CompanyFragmentDoc,
});

/**
 * Checks, if a company fragment is detailed company
 */
export const isCompanyDetailed = (
  company: AnyCompanyFragment | undefined
): company is CompanyDetailedFragment => !!company && 'users' in company;

/**
 * Reads company fragment from cache by id
 */
export const readCompanyFragment = makeReadFragment<CompanyFragment>({
  typeName: 'Company',
  fragment: CompanyFragmentDoc,
});

/**
 * Reads herriot integration entity fragment from cache by id
 */
export const readHerriotIntegrationEntityFragment =
  makeReadFragment<HerriotIntegrationEntityFragment>({
    typeName: 'HerriotIntegrationEntity',
    fragment: HerriotIntegrationEntityFragmentDoc,
    getIdentifyFields: guid => ({ guid }),
  });
