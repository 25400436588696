/**
 * Convert options item to any value
 */
const makeItemToAnyValueFromPropOr =
  <T = undefined>(
    prop: string,
    defaultValue?: T,
    shouldConvertToString = false
  ) =>
  <I extends string | Record<string, unknown>>(item: I | null) => {
    let value;
    if (item && typeof item === 'object') {
      value = (item as any)[prop];
    } else {
      value = item;
    }

    if (shouldConvertToString && value) {
      value = value.toString();
    }

    return value === undefined ? defaultValue : value;
  };

/**
 * Default getter for an item value
 */
export const defaultGetItemValue = makeItemToAnyValueFromPropOr('id');

/**
 * Default getter for an item text
 */
export const defaultGetItemText = makeItemToAnyValueFromPropOr(
  'name',
  '',
  true
);

/**
 * Default getter for an item description
 */
export const defaultGetItemDescription = makeItemToAnyValueFromPropOr(
  'description',
  ''
);
