import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InjectionFragmentDoc } from './injection.graphql';
export type ProtocolInjectionFragment = { __typename: 'ProtocolInjection', id: string, comment: string, dayNumber: number, injection?: { __typename: 'Injection', id: string, name: string, kind: Types.EventKindEnum, daysMilkWithholdAfterLastThreatment: number, daysMeatWithholdAfterLastThreatment: number, cost?: number | null, hasProtocol: boolean } | null };

export const ProtocolInjectionFragmentDoc = gql`
    fragment ProtocolInjection on ProtocolInjection {
  __typename
  id
  comment
  dayNumber
  injection {
    ...Injection
  }
}
    ${InjectionFragmentDoc}`;