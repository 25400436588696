import React, { useCallback, useMemo, useState } from 'react';

import { ListItem } from '~/~legacy/types/keyValue';
import { useEditorSubmit } from '~/~legacy/value-editors/useEditorSubmit';

import { MAutoComplete } from '~/shared/components/MAutoComplete';
import { MControlsPanel } from '~/shared/components/MControlsPanel';
import { ValueKindEnum } from '~/shared/graphql';
import { PartialRecord } from '~/shared/types/utility';

import {
  BlueprintSourceSectionField,
  ValueEditorKind,
} from '~/widgets/blueprintEdit';

import { VariableEditProps } from './VariableEditor';

import './VariableEditor.scss';

type FieldFilterFunctionType = (field: BlueprintSourceSectionField) => boolean;
type FieldFilterFunctionGetterType = (
  expectedValueKind: ValueEditorKind
) => FieldFilterFunctionType;

const getDefaultFilter: FieldFilterFunctionGetterType =
  expectedValueKind => field =>
    field.returnValueKind === expectedValueKind;

const filterGettersMap: PartialRecord<
  ValueEditorKind,
  FieldFilterFunctionGetterType
> = {
  [ValueKindEnum.Numeric]: () => {
    return field =>
      Boolean(
        [ValueKindEnum.Numeric, ValueKindEnum.Int, ValueKindEnum.Float].find(
          x => x === field.returnValueKind
        )
      );
  },
  Unknown: getDefaultFilter,
};

export const VariableEdit: React.FC<VariableEditProps<string>> = props => {
  const {
    name,
    toggle,
    value,
    submit,
    inputs,
    fields,
    masterFields,
    parentNodeId,
    expectedValueKind,
    isMaster,
  } = props;

  const [val, setVal] = useState(value);

  const filterFn = useMemo(() => {
    const getFilter = filterGettersMap[expectedValueKind] || getDefaultFilter;
    return getFilter(expectedValueKind);
  }, [expectedValueKind]);

  const items: ListItem<string>[] = useMemo(() => {
    const inputVars = inputs.map(item => {
      const listItem: ListItem<string> = {
        value: item.id,
        content: item.cycleInputId ? (
          <span className="m-variable-editor-cycle-input-item">
            {item.name}
          </span>
        ) : (
          item.name
        ),
        searchKey: item.name,
      };
      return listItem;
    });

    const fieldVars = fields
      .filter(field => field.id !== parentNodeId)
      .filter(filterFn)
      .map(item => {
        const listItem: ListItem<string> = {
          value: item.id,
          content: (
            <span className="m-variable-editor-field-item">{item.name}</span>
          ),
          searchKey: item.name,
        };
        return listItem;
      });

    const masterFieldVars = !isMaster
      ? masterFields
          .filter(field => field.id !== parentNodeId)
          .filter(filterFn)
          .map(item => {
            const listItem: ListItem<string> = {
              value: item.id,
              content: (
                <span className="m-variable-editor-master-field-item">
                  {item.name}
                </span>
              ),
              searchKey: item.name,
            };
            return listItem;
          })
      : [];

    return [...inputVars, ...fieldVars, ...masterFieldVars];
  }, [inputs, fields, masterFields]);

  const submitCallback = useEditorSubmit(submit, toggle, val);

  const valueChangeCallback = useCallback(
    (inputId: string) => {
      setVal(inputId);
    },
    [setVal]
  );

  return (
    <form onSubmit={submitCallback}>
      <MAutoComplete
        name={name}
        items={items}
        selectedValue={val}
        placeholder="Переменная"
        onChange={valueChangeCallback}
      />

      <MControlsPanel className="my-8" canSubmit cancel={toggle} />
    </form>
  );
};
