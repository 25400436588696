import React, { useEffect, useMemo } from 'react';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { useService } from '~/~legacy/hooks/useService';
import { MaslovServices } from '~/~legacy/types/services';

import {
  BlueprintEditCanvas,
  BlueprintEditService,
  SourceSection,
} from '~/widgets/blueprintEdit';

import { MasterBlueprintEditHeader } from '../MasterBlueprintEditHeader';
import { MasterViewSection } from '../MasterViewSection';

interface Props {
  blueprintId: string;
}

export const MasterBlueprintEdit: React.FC<Props> = props => {
  const { blueprintId } = props;

  const bulueprintEditSvc = useService<BlueprintEditService>(
    MaslovServices.BlueprintEditService
  );

  const memoizedInit = useMemo(() => {
    return bulueprintEditSvc.initEditing.bind(bulueprintEditSvc);
  }, [bulueprintEditSvc.initEditing]);

  const { data, loading, errors, reload } = useApiData(memoizedInit);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  useEffect(() => {
    const loadData = async () => {
      await reload(blueprintId);
    };

    loadData();
  }, []);

  const content =
    errors.length === 0 ? (
      <>
        <MasterBlueprintEditHeader blueprintName={data?.name || ''} />
        <BlueprintEditCanvas>
          <SourceSection masterPrint />
          <MasterViewSection />
        </BlueprintEditCanvas>
      </>
    ) : (
      errorMessage
    );

  return loading ? loader : <>{content}</>;
};
