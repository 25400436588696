import React from 'react';

import R from 'ramda';

import { AppEmployee } from '~/~legacy/types/entities';
import { ValueEditorStandardProps } from '~/~legacy/value-editors/types/valueEditorTypes';

import { SelectThemes } from '~/shared/components/Select';

import { useEmployeeSelect } from '~/entities/employees';

export const EmployeeSelectorEdit: React.FC<
  ValueEditorStandardProps<AppEmployee>
> = props => {
  const selectTheme = props.additionalArgs?.selectTheme ?? SelectThemes.light;

  const { items, renderSelectElement: renderEmployeesSelectElement } =
    useEmployeeSelect({
      selectProps: {
        theme: selectTheme,
        rawValue: props.value?.id,
        onValueChange: newValue => {
          // TODO replace AppEmployee with standard fragment
          const employee = newValue
            ? {
                id: newValue.id,
                comment: newValue.comment,
                number: newValue.number,
                firstName: newValue.firstName,
                middleName: newValue.middleName,
                lastName: newValue.lastName,
              }
            : undefined;
          props.onChange?.(employee);
        },
        ...R.omit(
          ['onValueChange', 'onValueChangeDebounced', 'value', 'defaultValue'],
          props
        ),
      },
    });

  return renderEmployeesSelectElement({
    value: items.find(item => item.id === props.value?.id),
  });
};
