import React, { useMemo, useState } from 'react';

import R from 'ramda';

import { EnumStrings } from '~/~legacy/strings/enumStrings';

import { ExpandableTitle } from '~/shared/components/ExpandableTitle';
import { Input, InputVariants } from '~/shared/components/Input';
import { makeUseEnumSelect, Select } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { VitalityFilter } from '~/shared/graphql';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import {
  AppBlueprintViewTableSettings,
  BlueprintSourceSection,
} from '~/widgets/blueprintEdit';

import { BlueprintOrderableEntity } from '../BlueprintOrderableEntity';
import { SortableBlueprintOrderableEntitiesList } from '../SortableBlueprintOrderableEntitiesList';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;

  masterSourceNodes: BlueprintSourceSection[];
  sourceNodes: BlueprintSourceSection[];

  viewSettings: AppBlueprintViewTableSettings;
  onViewSettingsChange: (viewSettings: AppBlueprintViewTableSettings) => void;

  vitality?: VitalityFilter;
  onVitalityChange: (vitality: VitalityFilter) => void;
}

const useVitalityFilterSelect = makeUseEnumSelect(
  VitalityFilter,
  EnumStrings.vitalityFilter
);

export const TableView: React.FC<Props> = ({
  className,

  masterSourceNodes,
  sourceNodes,

  viewSettings,
  onViewSettingsChange,

  vitality,
  onVitalityChange,
}) => {
  // Group by
  const groupBySelectItems = useMemo(() => {
    return [...masterSourceNodes, ...sourceNodes].flatMap(section =>
      section.fields.map(field => ({
        id: field.id,
        name: field.name,
      }))
    );
  }, [masterSourceNodes, sourceNodes]);

  // Vitality filter
  const { renderSelectElement: renderVitalityFilterSelectElement } =
    useVitalityFilterSelect({
      className: 'mt-16 mb-24',
      label: 'Отображать',
      name: 'vitalityFilter',
      rawValue: vitality,
      onValueChange: newVitality =>
        onVitalityChange(newVitality?.id as VitalityFilter),
    });

  // Make flat fields list for easier searching and conditions
  const flatMasterFields = useMemo(
    () => masterSourceNodes.flatMap(R.prop('fields')),
    [masterSourceNodes]
  );
  const flatFields = useMemo(
    () => sourceNodes.flatMap(R.prop('fields')),
    [sourceNodes]
  );

  const selectedFields = useMemo(
    () =>
      viewSettings.fieldsIds
        .map(fieldId => {
          const field = [...flatMasterFields, ...flatFields].find(
            f => f.id === fieldId
          );
          if (!field) return undefined;
          return field;
        })
        .filter(Boolean),
    [viewSettings.fieldsIds, flatFields, flatMasterFields]
  );
  const notSelectedFields = flatFields.filter(
    f => !viewSettings.fieldsIds.includes(f.id)
  );

  const toggleFieldById = (fieldId: string, isSelected: boolean) => {
    const newFieldIds = isSelected
      ? [...viewSettings.fieldsIds, fieldId]
      : viewSettings.fieldsIds.filter(id => id !== fieldId);

    onViewSettingsChange({
      groupByFieldsIds: viewSettings.groupByFieldsIds,
      fieldsIds: newFieldIds,
    });
  };

  // Search logic for master fields
  const [masterFieldSearch, setMasterFieldSearch] = useState('');
  const isSearchInProgress = masterFieldSearch !== '';

  const filteredMasterSourceNodes = useMemo(() => {
    return masterSourceNodes.reduce<BlueprintSourceSection[]>(
      (acc, section) => {
        const filteredFields = section.fields.filter(f =>
          f.name.toLowerCase().includes(masterFieldSearch.toLowerCase())
        );

        if (filteredFields.length) {
          const filteredSection = {
            ...section,
            fields: filteredFields,
          };
          acc.push(filteredSection);
        }

        return acc;
      },
      []
    );
  }, [masterSourceNodes, masterFieldSearch]);

  return (
    <div className={className}>
      <Select<(typeof groupBySelectItems)[number]>
        {...{
          name: 'groupBy',
          label: 'Группировать по',
          placeholder: 'Выберите поле',
          items: groupBySelectItems,
          rawValue: viewSettings.groupByFieldsIds.at(0),
          isClearable: true,
          onValueChange: newValue => {
            onViewSettingsChange({
              ...viewSettings,
              groupByFieldsIds: normalizeToArrayOrUndefined(newValue?.id) ?? [],
            });
          },
        }}
      />

      {renderVitalityFilterSelectElement()}

      <Typography variant={TypographyVariants.heading4} className="mb-8">
        Поля в списке
      </Typography>

      {!viewSettings.fieldsIds.length && !notSelectedFields.length && (
        <Typography
          variant={TypographyVariants.bodySmall}
          className="text-muted"
        >
          Здесь появятся выбранные шаблонные поля
        </Typography>
      )}
      <div className="flex flex-col">
        <SortableBlueprintOrderableEntitiesList
          {...{
            entities: selectedFields,
            onEntitySelectionChange: toggleFieldById,
            onEntitiesOrderChange: fieldsIds =>
              onViewSettingsChange({
                ...viewSettings,
                fieldsIds,
              }),
          }}
        />
        {notSelectedFields.map((field, index) => (
          <BlueprintOrderableEntity
            key={`${field.id}_${index}`}
            {...{
              entity: field,
              isSelected: false,
              onSelectedChange: newIsSelected => {
                toggleFieldById(field.id, newIsSelected);
              },
            }}
          />
        ))}
      </div>

      <ExpandableTitle
        {...{
          className: 'mt-24',
          title: 'Выбрать шаблонные поля',
        }}
      >
        <Input
          {...{
            name: 'search',
            className: 'mb-16',
            placeholder: 'Поиск',
            value: masterFieldSearch,
            onValueChange: setMasterFieldSearch,
            variant: InputVariants.search,
          }}
        />
        <div className="flex flex-col">
          {isSearchInProgress && !filteredMasterSourceNodes.length && (
            <Typography
              variant={TypographyVariants.bodySmall}
              className="text-muted"
            >
              Поле не найдено
            </Typography>
          )}
          {filteredMasterSourceNodes.map(section => {
            return (
              <React.Fragment key={section.id}>
                <Typography variant={TypographyVariants.heading5} tag="h4">
                  {section.verboseName}
                </Typography>
                {section.fields.map(field => (
                  <BlueprintOrderableEntity
                    key={field.id}
                    {...{
                      entity: field,
                      isSelected: viewSettings.fieldsIds.includes(field.id),
                      onSelectedChange: newIsSelected => {
                        toggleFieldById(field.id, newIsSelected);
                      },
                    }}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </ExpandableTitle>
    </div>
  );
};
