import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Pencil } from '~/~legacy/icons/pencil.svg';
import { IntegratorStrings } from '~/~legacy/strings/integratorStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { BlueprintInputNode } from '../../../../services';
import styles from './index.module.scss';

interface Props {
  input: BlueprintInputNode;

  toggle: () => void;
}

export const InputNodeViewForm: React.FC<Props> = ({ input, toggle }) => {
  const { t } = useTranslation([MaslovNamespaces.integrator]);

  return (
    <div className={styles.root}>
      <p>
        <i>
          {t(IntegratorStrings.blueprintEdit.nodes.userInputNode.variableName)}
        </i>
        : &nbsp;{input.name}
      </p>
      <div className="my-8" />
      <p>
        <i>{t(IntegratorStrings.blueprintEdit.nodes.userInputNode.prompt)}</i>
        :&nbsp;{input.prompt}
      </p>

      <div onClick={toggle} className={styles.icon}>
        <Pencil />
      </div>
    </div>
  );
};
