import React, { ReactNode } from 'react';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

interface Props {
  editor: ReactNode;
  title?: ReactNode;
  subtitle?: string;
  className?: string;
}

export const MFormField: React.FC<Props> = ({
  editor,
  subtitle,
  title,
  className,
}) => {
  const subtitleContent = subtitle ? (
    <Typography
      variant={TypographyVariants.descriptionLarge}
      className="mb-4 text-muted"
    >
      {subtitle}
    </Typography>
  ) : null;

  return (
    <div className={`m-form-field ${className || ''}`}>
      {!!title && (
        <Typography
          variant={TypographyVariants.descriptionLarge}
          className="mb-4 text-muted"
          tag="div"
        >
          {title}
        </Typography>
      )}
      <div>{editor}</div>
      {subtitleContent}
    </div>
  );
};
