import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { PenGroupFragmentDoc } from '../../gql/fragments/penGroup.graphql';
import { usePenGroupsQuery } from '../../gql/queries/penGroups.graphql';
import { formatPenGroup } from '../../helpers';

/**
 * Select for pen group id
 */
const usePenGroupSelect = makeUseAsyncSelect({
  typeName: 'PenGroup',
  fragment: PenGroupFragmentDoc,
  useQuery: usePenGroupsQuery,
  getItemsFromQueryData: data => data.penGroups.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.penGroups.pageInfo,
  selectProps: {
    placeholder: 'Выберите группу',
    getItemText: item => formatPenGroup(item),
  },
});

export const PenGroupAsyncSelect =
  makeAsyncSelectFormComponent(usePenGroupSelect);
